import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class StyleGuide {
  constructor(options = {
    name: 'Untitled',
    enabled: true,
    minLines: 1,
    maxLines: 4,
    maxChars: 32,
    maxDuration: 6,
    minDuration: 0.2,
    minCps: 0,
    maxCps: 9999,
    minWpm: 0,
    maxWpm: 9999,
    minWordsPerLine: 1,
    maxWordsPerLine: 10,
    minEventGap: 0,
    maxEventGap: 6,
    overlap: true,
    illegalChars: false,
    hyphenSpace: false,
    hasUnderscore: false,
    periods: false,
    missingSpeaker: false,
    useEllipses: false,
    spellNumbers: false,
    spellNumbersAtStart: false,
    netflixGlyphs: false,
    partialItalics: false,
    fullItalics: false,
    partialBold: false,
    fullBold: false,
    partialUnderline: false,
    fullUnderline: false,
    repeatWords: false,
    fitSubtitles: false,
    //Fit Subtitles To One Row
    leadingTrailingSpace: false,
    whitespace: false,
    blankLines: false,
    positionTopLeft: false,
    positionTopCenter: false,
    positionTopRight: false,
    positionCenterLeft: false,
    positionCenter: false,
    positionCenterRight: false,
    positionBottomLeft: false,
    positionBottomCenter: false,
    positionBottomRight: false,
    positionYOffset: false,
    positionXOffset: false,
    /* Approvals */
    approvalPassed: false,
    approvalFailed: false,
    approvalNotSet: false,
    /* Metadata */
    notes: false,
    tags: false,
    forced: false
  }) {
    this.id = options.id || uuidv4();
    this.name = options.name || 'Untitled Style Guide';
    this.enabled = options.enabled, this.minLines = options.minLines || 1;
    this.maxLines = options.maxLines || 4;
    this.maxChars = options.maxChars || 32;
    this.maxDuration = options.maxDuration || 6;
    this.minDuration = options.minDuration || 0.2;
    this.minCps = options.minCps;
    this.maxCps = options.maxCps;
    this.minWpm = options.minWpm;
    this.maxWpm = options.maxWpm;
    this.minWordsPerLine = options.minWordsPerLine || 1, this.maxWordsPerLine = options.maxWordsPerLine || 10, this.minEventGap = options.minEventGap || 0;
    this.maxEventGap = options.maxEventGap || 6;
    this.overlap = options.overlap || true;
    this.illegalChars = options.illegalChars;
    this.hyphenSpace = options.hyphenSpace;
    this.hasUnderscore = options.hasUnderscore;
    this.periods = options.periods;
    this.missingSpeaker = options.missingSpeaker;
    this.useEllipses = options.useEllipses;
    this.spellNumbers = options.spellNumbers;
    this.spellNumbersAtStart = options.spellNumbersAtStart;
    this.netflixGlyphs = options.netflixGlyphs;
    this.partialItalics = options.partialItalics;
    this.fullItalics = options.fullItalics;
    this.partialBold = options.partialBold;
    this.fullBold = options.fullBold;
    this.partialUnderline = options.partialUnderline;
    this.fullUnderline = options.fullUnderline;
    this.repeatWords = options.repeatWords;
    this.fitSubtitles = options.fitSubtitles; //Fit Subtitles To One Row
    this.leadingTrailingSpace = options.leadingTrailingSpace;
    this.whitespace = options.whitespace;
    this.blankLines = options.blankLines;
    this.positionTopLeft = options.positionTopLeft;
    this.positionTopCenter = options.positionTopCenter;
    this.positionTopRight = options.positionTopRight;
    this.positionCenterLeft = options.positionCenterLeft;
    this.positionCenter = options.positionCenter;
    this.positionCenterRight = options.positionCenterRight;
    this.positionBottomLeft = options.positionBottomLeft;
    this.positionBottomCenter = options.positionBottomCenter;
    this.positionBottomRight = options.positionBottomRight;
    this.positionYOffset = options.positionYOffset;
    this.positionXOffset = options.positionXOffset;
    /* Approvals */
    this.approvalPassed = options.approvalPassed;
    this.approvalFailed = options.approvalFailed;
    this.approvalNotSet = options.approvalNotSet;
    /* Metadata */
    this.notes = options.notes;
    this.tags = options.tags;
    this.forced = options.forced;
  }
});