import _Papa from "papaparse";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _stripTags from "../functions/quill/stripTags.js";
import _Event from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const stripTags = _stripTags;
const convertToHtml = _convertToHtml;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const Papa = _Papa;
export default {
  decode: async function (input, options) {
    let events = [],
      decodingOptions = getFormatOptions(options.formatOptions),
      startTime,
      endTime;

    //Get which column the user selected for caption data
    const startIndex = decodingOptions['Start Time'].charCodeAt(0) - 65;
    const endIndex = decodingOptions['End Time'].charCodeAt(0) - 65;
    const dialogIndex = decodingOptions['Text'].charCodeAt(0) - 65;

    //Converts csv string data to json objects
    let parser = Papa.parse(input, {
      skipEmptyLines: true
    });
    for (let i = 0; i < parser.data.length; i++) {
      try {
        let ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.10
        });
        switch (decodingOptions['Start Time Format']) {
          case 'SMPTE Timecode (HH:MM:SS:FF)':
            startTime = tcLib.tcToSec(parser.data[i][startIndex], options.frameRate);
            break;
          case 'MS Timecode (HH:MM:SS.MSS)':
            startTime = tcLib.tcMsToSec(parser.data[i][startIndex].replace(',', '.'));
            break;
          case 'Seconds (SS)':
            startTime = parseFloat(parser.data[i][startIndex]);
            break;
          default:
            startTime = tcLib.tcToSec(parser.data[i][startIndex] + ":00", options.frameRate);
        }
        switch (decodingOptions['End Time Format']) {
          case 'SMPTE Timecode (HH:MM:SS:FF)':
            endTime = tcLib.tcToSec(parser.data[i][endIndex], options.frameRate);
            break;
          case 'MS Timecode (HH:MM:SS.MSS)':
            endTime = tcLib.tcMsToSec(parser.data[i][endIndex].replace(',', '.'));
            break;
          case 'Seconds (SS)':
            endTime = parseFloat(parser.data[i][endIndex]);
            break;
          default:
            endTime = tcLib.tcToSec(parser.data[i][endIndex] + ":00", options.frameRate);
        }

        //start time and end time will return with timecodes if it contained them
        if (startTime !== "" && endTime !== "") {
          ccEvent.start = startTime;
          ccEvent.end = endTime;
          ccEvent.text = convertToHtml(stripTags(parser.data[i][dialogIndex]));
          events.push(ccEvent);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    return events;
  },
  encode: async function (eventGroup, options) {
    let output = Papa.unparse(JSON.stringify(eventGroup.events.map(event => {
      return {
        id: event.id,
        start: event.start,
        end: event.end,
        startSmpte: tcLib.secToTc(Math.max(0, event.start), options.frameRate),
        endSmpte: tcLib.secToTc(Math.max(0, event.end), options.frameRate),
        frameRate: options.frameRate,
        xPos: event.xPos,
        yPos: event.yPos,
        xOffset: event.xOffset,
        yOffset: event.yOffset
      };
    })));
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};