export default (function cleanText(str, options = {}) {
  const {
    removeWhitespace = true,
    removeSpecialChars = true,
    allowedChars = '',
    toLowerCase = false
  } = options;
  let cleanedStr = str;
  if (removeWhitespace) {
    cleanedStr = cleanedStr.replace(/\s+/g, '');
  }
  if (removeSpecialChars) {
    // Remove all non-alphanumeric characters, including Unicode
    const regex = new RegExp(`[^a-zA-Z0-9${allowedChars}]`, 'gu');
    cleanedStr = cleanedStr.replace(regex, '');
  }
  if (toLowerCase) {
    cleanedStr = cleanedStr.toLowerCase();
  }
  return cleanedStr;
});