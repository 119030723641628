export default (function textBreak(text, maxLineLength = 50) {
  let result = '';
  let currentLine = '';
  let currentLineLength = 0;
  let inTag = false;
  let currentWord = '';
  let currentWordLength = 0;
  function addWordToLine() {
    if (currentLineLength + currentWordLength > maxLineLength && currentLineLength > 0) {
      result += currentLine + '\n';
      currentLine = currentWord;
      currentLineLength = currentWordLength;
    } else {
      currentLine += currentWord;
      currentLineLength += currentWordLength;
    }
    currentWord = '';
    currentWordLength = 0;
  }
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (char === '<') {
      addWordToLine();
      inTag = true;
      currentLine += char;
    } else if (char === '>') {
      inTag = false;
      currentLine += char;
    } else if (inTag) {
      currentLine += char;
    } else if (char === ' ' || char === '\n') {
      currentWord += char;
      addWordToLine();
    } else {
      currentWord += char;
      currentWordLength++;
    }
  }

  // Add any remaining content
  addWordToLine();
  return result + currentLine;
});