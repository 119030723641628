import { encode as _encodeHtml } from "html-entities";
const encodeHtml = _encodeHtml;
function encodeHTMLEntities(str) {
  var encodedStr = str.replace(/<p[^>]*>(.*?)<\/p>/g, function (match, g1) {
    return match.replace(g1, encodeHTML(g1));
  });
  return encodedStr.replace(/&lt;em&gt;/gmi, "<em>").replace(/&lt;\/em&gt;/gmi, "</em>").replace(/&lt;u&gt;/gmi, "<u>").replace(/&lt;\/u&gt;/gmi, "</u>").replace(/&lt;b&gt;/gmi, "<b>").replace(/&lt;\/b&gt;/gmi, "</b>");
}
function encodeHTML(text) {
  var htmlEntities = {
    '<': '&lt;',
    '>': '&gt;'
  };
  return text.replace(/[<>&'"]/g, function (match) {
    return htmlEntities[match] || match;
  });
}
export default (function escapeHtmlEntities(html) {
  //console.log("Befores:", html);
  html = encodeHtml(html).replace(/&lt;/gmi, "<").replace(/&gt;/gmi, ">");
  html = encodeHTMLEntities(html);
  //console.log("After:", html);
  return html;
});