import _dict from "../dict/708.js";
import _tsLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tsLib = _tsLib;
const dict = _dict;
const inDict = key => Object.keys(dict.cmds).filter(v => v == key) > 0;
const convert = key => dict.cmds[key];
export default {
  decode: function (input, options) {
    let lines = input.split("\n");
    let events = [];
    lines.forEach((line, i) => {
      if (line.startsWith("CAPTION")) {
        let e = new Event();
        // Get Time
        let h = line.split(",")[3];
        let m = line.split(",")[4];
        let s = line.split(",")[5];
        let f = line.split(",")[6];
        let timecode = tsLib.tcToSec(`${h}:${m}:${s}:${f}`, options.frameRate, options.dropFrame);
        if (events.length > 0) events[events.length - 1].end = timecode;
        e.start = timecode;

        // Get Text
        let dataRegex = /^DATA,\d\d,\d,(.*)/g;
        let data = dataRegex.exec(lines[i + 1]);
        if (data && data.length > 0) {
          data[1].split(",");
          let string = data[1].split(",").map(hex => dict.cmds[hex.slice(-2)]).join('');
          e.text = string;
        }
        events.push(e);
      }
    });
    console.log(events);
    return events;
  },
  encode: function () {
    throw new Error("This profile [MacCaption Project] is not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};