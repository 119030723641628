export default (async function calculateDrift(events) {
  //calculate the average drift multiplier of the events based on their oStart and start times
  let totalDriftMultiplier = 0;
  let totalEvents = 0;
  events.forEach(ev => {
    if (ev.start && ev.oStart) {
      totalDriftMultiplier += ev.start / ev.oStart;
      totalEvents++;
    }
  });
  let driftMultiplier = totalDriftMultiplier / totalEvents;
  console.log(`Average Drift Multiplier: ${driftMultiplier.toFixed(4)}`);
  return driftMultiplier.toFixed(4);
});