export default (function combineSpanTags(text) {
  // Helper function to parse attributes
  function parseAttributes(attrString) {
    const attrs = {};
    const regex = /(\w+(?::\w+)?)=(['"])((?:(?!\2).)*)\2/g;
    let match;
    while ((match = regex.exec(attrString)) !== null) {
      attrs[match[1]] = match[3];
    }
    return attrs;
  }

  // Helper function to stringify attributes
  function stringifyAttributes(attrs) {
    return Object.entries(attrs).map(([key, value]) => `${key}='${value}'`).join(' ');
  }

  // Combine consecutive spans
  let result = text.replace(/<span([^>]*)>((?:<span[^>]*>)*)([\s\S]*?)(<\/span>)*<\/span>/g, (match, attr1, nestedOpens, content, nestedCloses) => {
    const attributes = parseAttributes(attr1);

    // Combine attributes from nested spans
    nestedOpens.match(/<span([^>]*)>/g)?.forEach(nestedSpan => {
      const nestedAttrs = parseAttributes(nestedSpan.slice(5, -1));
      Object.assign(attributes, nestedAttrs);
    });

    // Remove nested spans from content
    content = content.replace(/<\/?span[^>]*>/g, '');

    // If content is empty, return an empty string (removing the span)
    if (!content.trim()) {
      return '';
    }

    // Reconstruct the span with combined attributes
    return `<span ${stringifyAttributes(attributes)}>${content}</span>`;
  });

  // Remove any remaining empty spans
  result = result.replace(/<span[^>]*>\s*<\/span>/g, '');
  return result;
});