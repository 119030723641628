<script>
    import { onMount, onDestroy } from "svelte";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { editorState } from "@app/store/editorStore.js";
    import WaveSurfer from "wavesurfer.js";
    import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
    import Minimap from "wavesurfer.js/dist/plugins/minimap.esm.js";
    import { slide } from "svelte/transition";
    import { quintOut } from "svelte/easing";

    //CC-LIB
    import { v4 as uuidv4 } from "uuid";
    import throttle from "just-throttle";
    import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
    import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
    import ccEvent from "@app/external/cc-lib/dist/classes/event.js";
    import { decode } from "html-entities";
    export let timelineHeight = 125;

    const heightDelta = 50;
    let syncingEvents = false;
    let syncingMiniMapEvents = false;
    let decodingAudio = false;
    let showMarkers = true;

    function createElementFromHTML(htmlString) {
        var div = document.createElement("div");
        div.innerHTML = htmlString.trim();
        // Change this to div.childNodes to support multiple top-level nodes.
        return div;
    }

    function getEventsToRender(options) {
        let eventsToRender = options.events.filter(
            (event) =>
                event.start !== false &&
                event.start >= options.minStartTime &&
                event.start <= options.maxEndTime,
        );

        return eventsToRender;
    }

    function toggleMarkers(){
        showMarkers = !showMarkers;
        syncRegionsWithMarkers();
    }

    onMount(async () => {
        try {
            console.log("Timeline Mounted");
            decodingAudio = true;
            let peakFile;
            console.log($projectState.media);
            if ($projectState.media.peaksPath) {
                let peakRes = await fetch($projectState.media.peaksPath);
                peakFile = await peakRes.json();
                if (!peakFile.data){
                    peakFile = {
                        data : peakFile
                    }
                }                
            } else if (($projectState.media.storage === "Cloud Storage" || $projectState.media.storage === "Local Storage") && $projectState.media.useFallback){
                console.log("Using Fallback Peaks")
                peakFile = {
                    data : [0,0]
                }
            }

            window.ws = WaveSurfer.create({
                container: "#Timeline",
                waveColor: "#0fbc8c",
                progressColor: "#0e6049",
                cursorColor: "#FFF700",
                height: timelineHeight - heightDelta,
                minPxPerSec: 100,
                media: document.querySelector("video"),
                peaks: peakFile ? peakFile.data : undefined,
                duration: undefined,
                dragToSeek: !$editorState.drawEvents,
                hideScrollbar: false,
                autoCenter: true,
                autoScroll: true,
                interact: true,
                barWidth: 2,
                barGap: 1,
                barRadius: 2,
                plugins: [],
            });

            ws.on("click", (x, y) => {
                $editorState.editing = false;
                document.activeElement && document.activeElement.blur();
            });

            ws.on("decode", () => {
                console.log("Audio decoded");
                decodingAudio = false;
            });

            ws.on("error", (msg) => {
                console.log("ERROR IN WAVEFORM COMPONENT");
                console.log(msg);
                $projectState.media.useFallback = true;
            });

            //on timeupdate call syncRegionsWithEvents, syncRegionsWithMarkers, and syncRegionsWithCompareEvents. Throttle the call to prevent too many calls in a short period of time.
            ws.on("seeking", () => {
                setTimeout(() => {
                    syncRegionsWithEvents();
                }, 50);
                setTimeout(() => {
                    syncRegionsWithMarkers();
                }, 200);
                setTimeout(() => {
                    syncRegionsWithCompareEvents();
                }, 750);
                setTimeout(() => {
                    syncMiniMapRegionsWithEvents();
                }, 1000);
            });

            ws.on("scroll", () => {
                setTimeout(() => {
                    syncRegionsWithEvents();
                }, 50);
                setTimeout(() => {
                    syncRegionsWithMarkers();
                }, 200);
                setTimeout(() => {
                    syncRegionsWithCompareEvents();
                }, 750);
                setTimeout(() => {
                    syncMiniMapRegionsWithEvents();
                }, 1000);
            });

            ws.on(
                "timeupdate",
                throttle(
                    () => {
                        syncRegionsWithEvents();
                        if ($projectState.mode !== "timing") {
                            setTimeout(() => {
                                syncRegionsWithMarkers();
                            }, 3000);
                            setTimeout(() => {
                                syncRegionsWithCompareEvents();
                            }, 2000);
                            setTimeout(() => {
                                syncMiniMapRegionsWithEvents();
                            }, 2500);
                        }
                    },
                    5000,
                    { trailing: true },
                ),
            );

            // REGIONS PLUGIN
            window.wsRegions = ws.registerPlugin(RegionsPlugin.create());

            /* We need to make this optional based on the editor settings. */
            if ($editorState.drawEvents){
                wsRegions.enableDragSelection({
                    id: uuidv4(),
                    content: "",
                    drag: true,
                    resize: true,
                    color: "rgb(13,110,253, 0.2)",
                });
            }
            
            wsRegions.on("region-created", (region) => {
                if (!region.drag) {
                    return;
                }
                setTimeout(() => {
                    //console.log("Region Created!", region);
                    //Check if the region exists in the eventGroupState and add it if it doesn't
                    if ($eventGroupState[$projectState.selected]) {
                        if (
                            !$eventGroupState[
                                $projectState.selected
                            ].events.find((event) => event.id === region.id)
                        ) {
                            $eventGroupState[
                                $projectState.selected
                            ].events.push(
                                new ccEvent({
                                    id: region.id,
                                    start: region.start,
                                    end: region.end,
                                    text: "",
                                }),
                            );

                            $eventGroupState[$projectState.selected].selected =
                                [
                                    $eventGroupState[$projectState.selected]
                                        .events.length - 1,
                                ];

                            let selectedEvents = $eventGroupState[
                                $projectState.selected
                            ].events.filter((ev, eventIndex) => {
                                return (
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected.indexOf(eventIndex) > -1
                                );
                            });

                            $eventGroupState[$projectState.selected] =
                                orderByTime(
                                    $eventGroupState[$projectState.selected],
                                );

                            //Re-select events based on their Ids
                            $eventGroupState[$projectState.selected].selected =
                                $eventGroupState[$projectState.selected].events
                                    .map((ev, eventIndex) => {
                                        return selectedEvents
                                            .map((selectedEvent) => {
                                                return selectedEvent.id;
                                            })
                                            .indexOf(ev.id) > -1
                                            ? eventIndex
                                            : null;
                                    })
                                    .filter((ev) => {
                                        return ev !== null;
                                    });

                            historyState.insert({
                                name: "insert event", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        }
                    }
                }, 100);
            });

            wsRegions.on("region-updated", (region) => {
                //console.log("Region Updated!");
                let eventIndex = $eventGroupState[
                    $projectState.selected
                ].events.findIndex((event) => event.id === region.id);

                if ($eventGroupState[
                    $projectState.selected
                ].selected.indexOf(eventIndex) === -1) {
                    $eventGroupState[$projectState.selected].selected = [
                        eventIndex,
                    ];
                }

                if ($editorState.snapToShotChanges && showMarkers) {
                    //Snap Region Start/End to the closest shot change marker if within 0.2s. Only the Start or end should be snapped - Not both (depending on which is closer to the marker)
                    if (
                        $markerState.selected === 0 &&
                        $markerState.lists[0] &&
                        $markerState.lists[0].markers.length > 0
                    ) {
                        let closestMarkerToStart =
                            $markerState.lists[0].markers.reduce(
                                (prev, curr) => {
                                    return Math.abs(curr.time - region.start) <
                                        Math.abs(prev.time - region.start)
                                        ? curr
                                        : prev;
                                },
                            );

                        let closestMarkerToEnd =
                            $markerState.lists[0].markers.reduce(
                                (prev, curr) => {
                                    return Math.abs(curr.time - region.end) <
                                        Math.abs(prev.time - region.end)
                                        ? curr
                                        : prev;
                                },
                            );

                        if (closestMarkerToStart.id === closestMarkerToEnd.id) {
                            if (
                                Math.abs(
                                    closestMarkerToStart.time - region.start,
                                ) < 0.2
                            ) {
                                region.start = closestMarkerToStart.time;
                                region.setOptions({
                                    start: closestMarkerToStart.time,
                                });
                            } else {
                                if (
                                    Math.abs(
                                        closestMarkerToEnd.time - region.end,
                                    ) < 0.2
                                ) {
                                    region.end = closestMarkerToEnd.time;
                                    region.setOptions({
                                        end: closestMarkerToEnd.time,
                                    });
                                }
                            }
                        } else {
                            if (
                                Math.abs(
                                    closestMarkerToStart.time - region.start,
                                ) < 0.2
                            ) {
                                region.start = closestMarkerToStart.time;
                                region.setOptions({
                                    start: closestMarkerToStart.time,
                                });
                            }

                            if (
                                Math.abs(closestMarkerToEnd.time - region.end) <
                                0.2
                            ) {
                                region.end = closestMarkerToEnd.time;
                                region.setOptions({
                                    end: closestMarkerToEnd.time,
                                });
                            }
                        }
                    }
                }

                if (eventIndex > -1) {
                    let startDelta = region.start - $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].start; 
                    let endDelta = region.end - $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].end;

                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].start = region.start;
                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].end = region.end;

                    if (startDelta !== 0 && endDelta !== 0) {
                        $eventGroupState[$projectState.selected].selected.forEach(evIndex =>{
                            if (eventIndex !== evIndex){
                                $eventGroupState[$projectState.selected].events[evIndex].start += startDelta;
                                $eventGroupState[$projectState.selected].events[evIndex].end += endDelta;
                            }
                        });
                    }

                    let selectedEvents = $eventGroupState[
                        $projectState.selected
                    ].events.filter((ev, eventIndex) => {
                        return (
                            $eventGroupState[
                                $projectState.selected
                            ].selected.indexOf(eventIndex) > -1
                        );
                    });

                    $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);

                    //Fix event overlaps. If the event before has an end and it's greater than the start of the current event, set the end of the current event to the start of the next event
                    if (eventIndex > 0) {
                        if (
                            $eventGroupState[$projectState.selected].events[
                                eventIndex - 1
                            ].end >
                            $eventGroupState[$projectState.selected].events[
                                eventIndex
                            ].start
                        ) {
                            $eventGroupState[$projectState.selected].events[
                                eventIndex - 1
                            ].end =
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].start;
                        }
                    }

                    if (
                        eventIndex <
                        $eventGroupState[$projectState.selected].events.length -
                            1
                    ) {
                        if (
                            $eventGroupState[$projectState.selected].events[
                                eventIndex + 1
                            ].start <
                            $eventGroupState[$projectState.selected].events[
                                eventIndex
                            ].end
                        ) {
                            $eventGroupState[$projectState.selected].events[
                                eventIndex + 1
                            ].start =
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].end;
                        }
                    }

                    //Re-select events based on their Ids
                    $eventGroupState[$projectState.selected].selected =
                        $eventGroupState[$projectState.selected].events
                            .map((ev, eventIndex) => {
                                return selectedEvents
                                    .map((selectedEvent) => {
                                        return selectedEvent.id;
                                    })
                                    .indexOf(ev.id) > -1
                                    ? eventIndex
                                    : null;
                            })
                            .filter((ev) => {
                                return ev !== null;
                            });

                    historyState.insert({
                        name: "timecode updated", //action name
                        eventGroup: $projectState.selected,
                        snapshots: [
                            {
                                store: "eventGroupState",
                                value: JSON.stringify($eventGroupState),
                            },
                        ],
                    });
                }
            });

            wsRegions.on("region-clicked", (region, clickEvent) => {
                clickEvent.preventDefault();
                clickEvent.stopPropagation();

                let eventIndex = $eventGroupState[
                    $projectState.selected
                ].events.findIndex((event) => event.id === region.id);

                $editorState.editing = false;
                document.activeElement && document.activeElement.blur();

                if (eventIndex > -1) {
                    try {
                        //Scroll to event in event list
                        document
                            .getElementById("EventList")
                            .scrollTo(0, eventIndex * 230);

                        if (clickEvent.ctrlKey || clickEvent.metaKey) {
                            if (
                                $eventGroupState[
                                    $projectState.selected
                                ].selected.indexOf(eventIndex) > -1
                            ) {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = $eventGroupState[
                                    $projectState.selected
                                ].selected.filter((event) => {
                                    return event !== eventIndex;
                                });
                            } else {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [
                                    ...$eventGroupState[$projectState.selected]
                                        .selected,
                                    eventIndex,
                                ];
                            }
                        } else if (
                            clickEvent.shiftKey &&
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                        ) {
                            let indexOfLastSelectedEvent =
                                $eventGroupState[$projectState.selected]
                                    .selected[0];
                            if (eventIndex > indexOfLastSelectedEvent) {
                                while (indexOfLastSelectedEvent < eventIndex) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected = [
                                        ...$eventGroupState[
                                            $projectState.selected
                                        ].selected,
                                        indexOfLastSelectedEvent + 1,
                                    ];
                                    indexOfLastSelectedEvent++;
                                }
                            } else {
                                while (indexOfLastSelectedEvent > eventIndex) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected = [
                                        ...$eventGroupState[
                                            $projectState.selected
                                        ].selected,
                                        indexOfLastSelectedEvent - 1,
                                    ];
                                    indexOfLastSelectedEvent--;
                                }
                            }
                        } else {
                            $eventGroupState[$projectState.selected].selected =
                                [eventIndex];
                        }

                        $eventGroupState[$projectState.selected].selected =
                            $eventGroupState[
                                $projectState.selected
                            ].selected.filter((ev, eventIndex, selectedEv) => {
                                return selectedEv.indexOf(ev) === eventIndex;
                            });

                        historyState.insert({
                            name: "select event(s)", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    } catch (error) {
                        console.log(
                            "Error selecting event in timeline. " +
                                error.message,
                        );
                        console.error(error);
                    }
                }
            });

            wsRegions.on("region-double-clicked", (region, clickEvent) =>{
                clickEvent.preventDefault();
                clickEvent.stopPropagation();
                player.currentTime = region.start;

                $editorState.editing = false;
                document.activeElement && document.activeElement.blur();
            });

            // MINIMAP PLUGIN
            window.wsMinimap = ws.registerPlugin(
                Minimap.create({
                    height: 30,
                    waveColor: "#fff",
                    cursorColor : "#85820f",
                    progressColor: "#333",
                    insertPosition : "beforebegin",
                    plugins: [RegionsPlugin.create()],
                }),
            );
        } catch (error) {
            console.log(error);
            console.log("Error mounting timeline. " + error.message);
        }
    });

    onDestroy(() => {
        try {
            wsRegions.destroy();
            wsMinimap.destroy();
            window.wsMinimap = null;
            window.wsRegions = null;
            ws.setMediaElement(null);
        } catch (err) {
            console.log("Unlink Media Element");
        } finally {
            try {
                ws.unAll();
                ws.destroy();
                window.ws = null;
            } catch (err) {
                console.log("Error destroying wavesurfer. " + err.message);
                window.ws = null;
            }
        }
    });

    function zoomIn(e) {
        ws.zoom(Math.min(1000, ws.options.minPxPerSec * 1.5));
        e.target.blur();
        setTimeout(() => {
            syncRegionsWithEvents();
        }, 250);
        setTimeout(() => {
            syncRegionsWithMarkers();
        }, 1500);
        setTimeout(() => {
            syncRegionsWithCompareEvents();
        }, 3000);
        setTimeout(() => {
            syncMiniMapRegionsWithEvents();
        }, 2000);
    }

    function zoomOut(e) {
        ws.zoom(Math.max(5, ws.options.minPxPerSec / 1.5));
        e.target.blur();
        setTimeout(() => {
            syncRegionsWithEvents();
        }, 250);
        setTimeout(() => {
            syncRegionsWithMarkers();
        }, 1500);
        setTimeout(() => {
            syncRegionsWithCompareEvents();
        }, 3000);
        setTimeout(() => {
            syncMiniMapRegionsWithEvents();
        }, 2000);
    }

    function checkEventForErrors(
        index,
        events,
        maxCps,
        maxWpm,
        maxDuration,
        minDuration,
        maxChars,
        maxLines,
        cps,
        wpm,
        lineInfo,
        duration,
    ) {
        let overlaps = events.find((event, i, eventList) => {
            return (
                index !== i &&
                ((event.start > eventList[index].start &&
                    event.start < eventList[index].end) ||
                    (event.end < eventList[index].end &&
                        event.end > eventList[index].start))
            );
        });

        if (
            overlaps ||
            wpm > maxWpm ||
            cps > maxCps ||
            duration > maxDuration ||
            duration < minDuration ||
            lineInfo.some((el) => el > maxChars) ||
            lineInfo.length > maxLines
        ) {
            return true;
        }

        return false;
    }

    //syncRegionsWithEvents will need to compare the events in the Event Group and the regions in the timeline and update the regions based on the events that have changed. This will be called whenever the Event Group is updated. *The throttle function is used to prevent the function from being called too many times in a short period of time.
    const syncRegionsWithEvents = throttle(
        () => {
            //console.log("SYNCING EVENTS CALLED");
            if (
                syncingEvents ||
                !$eventGroupState[$projectState.selected] ||
                !window.ws ||
                !window.wsRegions
            ) {
                if (
                    !$eventGroupState[$projectState.selected] &&
                    window.wsRegions
                ) {
                    wsRegions.clearRegions();
                }

                return;
            }

            try {
                syncingEvents = true;
                let regions = wsRegions.getRegions();
                let scrollPosition = ws.getScroll();
                let minStartTime = scrollPosition / ws.options.minPxPerSec - 10;
                let maxEndTime =
                    (scrollPosition + window.innerWidth) /
                        ws.options.minPxPerSec +
                    10;

                let eventsToRender = getEventsToRender({
                    events: $eventGroupState[$projectState.selected].events,
                    minStartTime: minStartTime,
                    maxEndTime: maxEndTime,
                }).slice(0, 50);

                regions.forEach((region) => {
                    if (
                        region.resize &&
                        !eventsToRender.find((event) => event.id === region.id)
                    ) {
                        region.remove();
                    }
                });

                //Check for Event updates
                eventsToRender.forEach((event, index, events) => {
                    let contentTemplate = ``;
                    let isError = false;
                    let eventIndex;

                    eventIndex = $eventGroupState[
                        $projectState.selected
                    ].events.findIndex((ev) => ev.id === event.id);
                    let plainText = convertToPlainText(event.text);
                    let duration = (event.end - event.start).toFixed(2);
                    let lineInfo = [];
                    plainText.split("\n").forEach((line, lineIndex) => {
                        contentTemplate += `<p style="margin: 0; color: ${$editorState.timelineFontColor ? `${$editorState.timelineFontColor}`: 'var(--bs-light)'}; font-size: ${$editorState.timelineFontSize ? `${$editorState.timelineFontSize}px`: '0.75vw'};">${lineIndex === 0 ? `<span style="background: var(--bs-dark); color: var(--bs-light); padding: 3px; border-radius: 2px; font-weight: bold; font-size: 0.75vw;">${eventIndex + 1}</span> ` : ``}${line}</p>`;
                        lineInfo.push(decode(line).length);
                    });

                    if (!isNaN(event.start)) {
                        /* Total Characters */
                        let totalChars = lineInfo.reduce(
                            (partialSum, a) => partialSum + a,
                            0,
                        );
                        /* Total Words */
                        let totalWords = plainText.split(/\s/gm).length;
                        /* wpm */
                        let wpm = ((totalWords / duration) * 60).toFixed(2);
                        /* cps */
                        let cps = (totalChars / duration).toFixed(2);

                        isError = checkEventForErrors(
                            index,
                            events,
                            $eventGroupState[$projectState.selected].maxCps,
                            $eventGroupState[$projectState.selected].maxWpm,
                            $eventGroupState[$projectState.selected]
                                .maxDuration,
                            $eventGroupState[$projectState.selected]
                                .minDuration,
                            $eventGroupState[$projectState.selected].maxChars,
                            $eventGroupState[$projectState.selected].maxLines,
                            cps,
                            wpm,
                            lineInfo,
                            duration,
                        );
                    }

                    //Check if the region exists in the timeline. If it exists, then we compare the start time, end time, and content. If we find a difference then we update the region. If it doesn't exist, we add the region to the timeline.
                    let region = regions.find(
                        (region) => region.id === event.id && region.resize,
                    );

                    if (region) {
                        if (
                            region.end !== event.end ||
                            region.start !== event.start ||
                            !region.content ||
                            region.content.innerHTML !== contentTemplate ||
                            isError !== (region.color === "#e74c3c80") ||
                            $eventGroupState[
                                $projectState.selected
                            ].selected.indexOf(eventIndex) >
                                -1 !==
                                (region.color === "#bc832480") ||
                            ($eventGroupState[$projectState.compare] &&
                                region.channelIdx !== 1)
                        ) {
                            //Then update main display:
                            if (
                                (region.channelIdx === 1 &&
                                    !$eventGroupState[$projectState.compare]) ||
                                (region.channelIdx !== 1 &&
                                    $eventGroupState[$projectState.compare])
                            ) {
                                region.remove();
                                wsRegions.addRegion({
                                    channelIdx: $eventGroupState[
                                        $projectState.compare
                                    ]
                                        ? 1
                                        : null,
                                    id: event.id,
                                    start: parseFloat(event.start).toFixed(4),
                                    end: parseFloat(event.end).toFixed(4),
                                    drag: true,
                                    resize: true,
                                    content:
                                        createElementFromHTML(contentTemplate),
                                    color: isError
                                        ? "#e74c3c80"
                                        : $eventGroupState[
                                                $projectState.selected
                                            ].selected.indexOf(eventIndex) > -1
                                          ? "#bc832480"
                                          : "#0d6efd33",
                                });
                            } else {
                                region.setOptions({
                                    id: event.id,
                                    start: event.start,
                                    end: event.end,
                                    content:
                                        createElementFromHTML(contentTemplate),
                                    color: isError
                                        ? "#e74c3c80"
                                        : $eventGroupState[
                                                $projectState.selected
                                            ].selected.indexOf(eventIndex) > -1
                                          ? "#bc832480"
                                          : "#0d6efd33",
                                });
                            }
                        }
                    } else {
                        //Update the main display
                        wsRegions.addRegion({
                            channelIdx: $eventGroupState[$projectState.compare]
                                ? 1
                                : null,
                            id: event.id,
                            start: parseFloat(event.start).toFixed(4),
                            end:
                                parseFloat(event.end).toFixed(5) ??
                                parseFloat(event.start).toFixed(4) + 0.00005,
                            drag: true,
                            resize: true,
                            content: createElementFromHTML(contentTemplate),
                            color: isError
                                ? "#e74c3c80"
                                : $eventGroupState[
                                        $projectState.selected
                                    ].selected.indexOf(eventIndex) > -1
                                  ? "#bc832480"
                                  : "#0d6efd33",
                        });
                    }
                });
            } catch (err) {
                console.log(
                    "Error syncing events with timeline. " + err.message,
                );
            } finally {
                syncingEvents = false;
            }
        },
        50,
        {
            trailing: true,
        },
    );

    const syncRegionsWithMarkers = throttle(
        () => {
            //console.log("SYNCING MARKERS CALLED");
            if (
                !$markerState || isNaN($markerState.selected) ||
                !$markerState.lists[$markerState.selected] ||
                !window.ws ||
                !window.wsRegions ||
                $projectState.mode === "timing"
            ) {
                return;
            }

            let regions = wsRegions.getRegions();
            let scrollPosition = ws.getScroll();
            let minStartTime = scrollPosition / ws.options.minPxPerSec - 10;
            let maxEndTime =
                (scrollPosition + window.innerWidth) / ws.options.minPxPerSec +
                10;

            let markersToRender = showMarkers ? $markerState.lists[
                $markerState.selected
            ].markers.filter(
                (marker) =>
                    marker.time >= minStartTime && marker.time <= maxEndTime,
            ) : [];

            regions.forEach((region) => {
                if (
                    !region.resize &&
                    region.channelIdx === -1 &&
                    !markersToRender.find((marker) => marker.id === region.id)
                ) {
                    region.remove();
                }
            });

            markersToRender.forEach((marker) => {
                //Check if the region exists in the timeline. If it exists, then we compare the start time, end time, and content. If we find a difference then we update the region. If it doesn't exist, we add the region to the timeline.
                let region = regions.find((region) => region.id === marker.id);
                if (region) {
                    if (
                        region.start !== marker.time ||
                        (!region.content && marker.comment) ||
                        (marker.comment === "" && region.content) ||
                        (region.content &&
                            region.content.innerHTML !== marker.comment)
                    ) {
                        region.remove();
                        wsRegions.addRegion({
                            id: marker.id,
                            start: parseFloat(marker.time).toFixed(5),
                            drag: false,
                            resize: false,
                            content: marker.comment,
                            color: $markerState.lists[$markerState.selected]
                                .color,
                        });
                    }
                } else {
                    wsRegions.addRegion({
                        id: marker.id,
                        start: parseFloat(marker.time).toFixed(5),
                        drag: false,
                        resize: false,
                        content: marker.comment,
                        color: $markerState.lists[$markerState.selected].color,
                    });
                }
            });
        },
        200,
        {
            trailing: true,
        },
    );

    const syncRegionsWithCompareEvents = throttle(
        () => {
            //console.log("SYNCING COMPARE EVENTS CALLED");
            if (
                !$eventGroupState[$projectState.compare] ||
                !window.ws ||
                !wsRegions ||
                $projectState.compare === false ||
                !$eventGroupState[$projectState.selected] ||
                $projectState.mode === "timing"
            ) {
                return;
            }

            let regions = wsRegions.getRegions();
            let scrollPosition = ws.getScroll();
            let minStartTime = scrollPosition / ws.options.minPxPerSec - 10;
            let maxEndTime =
                (scrollPosition + window.innerWidth) / ws.options.minPxPerSec +
                10;

            let eventsToRender = getEventsToRender({
                events: $eventGroupState[$projectState.compare].events,
                minStartTime: minStartTime,
                maxEndTime: maxEndTime,
            }).slice(0, 50);

            regions.forEach((region) => {
                if (
                    region.channelIdx === 0 &&
                    !$eventGroupState[$projectState.compare].events.find(
                        (event) => event.id === region.id,
                    )
                ) {
                    //console.log("Removing Region", region);
                    region.remove();
                }
            });

            //Check for Event updates
            eventsToRender.forEach((event) => {
                let contentTemplate = ``;

                convertToPlainText(event.text)
                    .split("\n")
                    .forEach((line) => {
                        contentTemplate += `<p style="margin: 0;">${line}</p>`;
                    });

                //Check if the region exists in the timeline. If it exists, then we compare the start time, end time, and content. If we find a difference then we update the region. If it doesn't exist, we add the region to the timeline.
                let region = regions.find(
                    (region) =>
                        region.channelIdx === 0 && region.id === event.id,
                );
                if (region) {
                    if (
                        region.end !== event.end ||
                        region.start !== event.start ||
                        region.content.innerHTML !== contentTemplate
                    ) {
                        region.setOptions({
                            start: event.start,
                            end: event.end,
                            content: createElementFromHTML(contentTemplate),
                        });
                    }
                } else {
                    wsRegions.addRegion({
                        channelIdx: 0,
                        id: event.id,
                        start: parseFloat(event.start).toFixed(5),
                        end: parseFloat(event.end).toFixed(5),
                        drag: false,
                        resize: false,
                        content: createElementFromHTML(contentTemplate),
                        color: "#909da980",
                    });
                }
            });
        },
        100,
        {
            leading: true,
        },
    );

    const syncMiniMapRegionsWithEvents = throttle(
        () => {
            if (
                syncingMiniMapEvents ||
                !$eventGroupState[$projectState.selected] ||
                !window.wsMinimap ||
                !wsMinimap.miniWavesurfer ||
                wsMinimap.miniWavesurfer.plugins.length === 0 ||
                $projectState.mode === "timing"
            ) {
                return;
            }

            try {
                syncingMiniMapEvents = true;
                //console.log("SYNCING MINIMAP EVENTS CALLED");
                let miniMapRegions =
                    wsMinimap.miniWavesurfer.plugins[0].getRegions();

                //Check for delete events and remove them
                miniMapRegions.forEach((miniMapRegion) => {
                    if (
                        !$eventGroupState[$projectState.selected].events.find(
                            (event) => event.id === miniMapRegion.id,
                        )
                    ) {
                        miniMapRegion.remove();
                    }
                });

                //Check for Event updates
                let maxErrors = 50;
                let numberOfErrors = 0;
                $eventGroupState[$projectState.selected].events.forEach(
                    (event, index, events) => {
                        if (numberOfErrors > maxErrors) {
                            return;
                        }

                        let plainText = convertToPlainText(event.text);
                        let duration = (event.end - event.start).toFixed(2);
                        let lineInfo = [];
                        plainText.split("\n").forEach((line) => {
                            lineInfo.push(decode(line).length);
                        });

                        let isError = false;

                        if (!isNaN(event.start)) {
                            /* Total Characters */
                            let totalChars = lineInfo.reduce(
                                (partialSum, a) => partialSum + a,
                                0,
                            );
                            /* Total Words */
                            let totalWords = plainText.split(/\s/gm).length;
                            /* wpm */
                            let wpm = ((totalWords / duration) * 60).toFixed(2);
                            /* cps */
                            let cps = (totalChars / duration).toFixed(2);

                            isError = checkEventForErrors(
                                index,
                                events,
                                $eventGroupState[$projectState.selected].maxCps,
                                $eventGroupState[$projectState.selected].maxWpm,
                                $eventGroupState[$projectState.selected]
                                    .maxDuration,
                                $eventGroupState[$projectState.selected]
                                    .minDuration,
                                $eventGroupState[$projectState.selected]
                                    .maxChars,
                                $eventGroupState[$projectState.selected]
                                    .maxLines,
                                cps,
                                wpm,
                                lineInfo,
                                duration,
                            );
                        }

                        if (isError) {
                            numberOfErrors++;
                        }

                        //Check if the region exists in the timeline. If it exists, then we compare the start time, end time, and content. If we find a difference then we update the region. If it doesn't exist, we add the region to the timeline.
                        let miniMapRegion = miniMapRegions.find(
                            (region) => region.id === event.id,
                        );

                        if (miniMapRegion) {
                            if (
                                miniMapRegion.end !== event.end ||
                                miniMapRegion.start !== event.start ||
                                !isError
                            ) {
                                //Update minimap first. If there is a miniMapRegion already created than we just need to update it. Other wise we need to create a new region.
                                if (miniMapRegion) {
                                    if (
                                        isError &&
                                        miniMapRegion.start !== event.start
                                    ) {
                                        miniMapRegion.setOptions({
                                            id: event.id,
                                            start: event.start,
                                            drag: false,
                                            resize: false,
                                            color: "#e74c3c80",
                                        });
                                    } else if (!isError) {
                                        miniMapRegion.remove();
                                    }
                                } else if (isError) {
                                    wsMinimap.miniWavesurfer.plugins[0].addRegion(
                                        {
                                            id: event.id,
                                            start: event.start,
                                            drag: false,
                                            resize: false,
                                            color: "#e74c3c80",
                                        },
                                    );
                                }
                            }
                        } else if (isError) {
                            //Update minimap first:
                            wsMinimap.miniWavesurfer.plugins[0].addRegion({
                                id: event.id,
                                start: event.start,
                                drag: false,
                                resize: false,
                                color: "#e74c3c80",
                            });
                        }
                    },
                );
            } catch (err) {
                console.log(
                    "Error syncing events with timeline. " + err.message,
                );
                console.error(err);
            } finally {
                syncingMiniMapEvents = false;
            }
        },
        1000,
        {
            leading: true,
        },
    );

    function updateWindowHeight() {
        //console.log("Updating Window Height...");
        if (window.ws) {
            ws.setOptions({
                height: timelineHeight - heightDelta,
            });
        }
    }

    $: updateWindowHeight(timelineHeight);
    $: syncRegionsWithEvents($eventGroupState[$projectState.selected]);
    $: syncRegionsWithMarkers($markerState && !isNaN($markerState.selected) ? $markerState.lists[$markerState.selected] : false);
    $: syncRegionsWithCompareEvents($projectState.compare);
</script>

<div
    id="Timeline"
    transition:slide={{ duration: 300, easing: quintOut, axis: "y" }}
>
    {#if decodingAudio}
        <p class="float-start">Generating Waveform...</p>
    {/if}

    <div
        class="btn-group btn-group-sm m-2 shadow"
        role="group"
        aria-label="Basic example"
    >
        <button
            type="button"
            class="btn btn-secondary"
            title="Zoom Out"
            on:click={(e) => zoomOut(e)}><i class="bi bi-zoom-out"></i></button
        >
        <button
            type="button"
            class="btn btn-secondary"
            title="Zoom In"
            on:click={(e) => zoomIn(e)}><i class="bi bi-zoom-in"></i></button
        >
        <button
            type="button"
            class="btn btn-{showMarkers ? 'primary' : 'secondary'}"
            title="Show/Hide Markers"
            on:click={() => toggleMarkers()}><i class="bi bi-geo-alt{showMarkers ? '-fill' : ''}"></i></button
        >
    </div>
</div>

<style>
    .btn-group {
        position: fixed;
        right: 0;
        z-index: 999;
    }
</style>
