import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import _alignments from "../dict/alignments.js";
import _convertStylestoStyleMap from "../functions/special/convertStylestoStyleMap.js";
import _convertRegionsToPositionMap from "../functions/special/convertRegionsToPositionMap.js";
import _replaceLineEndings from "../functions/quill/replaceLineEndings.js";
import _flexbox from "../dict/flexbox.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _xmlFormat from "xml-formatter";
import { encode as _htmlEncode } from "html-entities";
import { decode as _htmlDecode } from "html-entities";
import _JsonFind from "json-find";
import _eol from "eol";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const eol = _eol;
const JsonFind = _JsonFind;
const htmlDecode = _htmlDecode;
const htmlEncode = _htmlEncode;
const xmlFormat = _xmlFormat; //Encode
const XMLParser = _XMLParser; //Decode
const flexbox = _flexbox;
const replaceLineEndings = _replaceLineEndings;
const convertRegionsToPositionMap = _convertRegionsToPositionMap;
const convertStylestoStyleMap = _convertStylestoStyleMap;
const alignments = _alignments;
const ttmlFunc = _ttmlFunc;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let jsonInput = xmlToJson.parse(input);
    let regions = JsonFind(jsonInput).findValues("region") || {
      region: []
    };
    let styles = JsonFind(jsonInput).findValues("style") || {
      style: []
    };
    if (!Array.isArray(regions.region)) {
      regions.region = [regions.region];
    }
    if (!Array.isArray(styles.style)) {
      styles.style = [styles.style];
    }
    let positionMap = convertRegionsToPositionMap(regions.region, options.window);
    let styleMap = convertStylestoStyleMap(styles.style);
    if (jsonInput.tt.body.div.p.length === undefined) {
      jsonInput.tt.body.div.p = [jsonInput.tt.body.div.p];
    }
    jsonInput.tt.body.div.p.forEach(paragraph => {
      ccEvent = new Event();
      ccEvent.start = tcLib.tcMsToSec(paragraph["@_begin"]);
      ccEvent.end = tcLib.tcMsToSec(paragraph["@_end"]);
      // console.log(paragraph["#text"])
      try {
        if (paragraph["#text"]) {
          ccEvent.text = convertToHtml(ttmlFunc.multiLine.formatText(paragraph["#text"].replace(/<br \/>|<br\/>|<br>/gim, "\n")));
        }
      } catch (err) {
        ccEvent.text = convertToHtml(paragraph["#text"].replace(/<br \/>|<br\/>|<br>/gim, "\n"));
      }
      if (paragraph["@_region"]) {
        ccEvent = {
          ...ccEvent,
          ...positionMap[paragraph["@_region"]]
        };
      } else {
        ccEvent.xPos = "center";
        ccEvent.yPos = "end";
        ccEvent.xOffset = 0;
        ccEvent.yOffset = options.window.height * -0.1;
        ccEvent.alignment = "center";
      }
      if (paragraph["@_tts:textAlign"] && alignments.includes(paragraph["@_tts:textAlign"])) {
        ccEvent.alignment = paragraph["@_tts:textAlign"];
      } else if (paragraph["textAlign"] && alignments.includes(paragraph["textAlign"])) {
        ccEvent.alignment = paragraph["textAlign"];
      } else if (paragraph["@_style"] && styleMap[paragraph["@_style"]] && styleMap[paragraph["@_style"]]["@_tts:textAlign"] && alignments.includes(styleMap[paragraph["@_style"]]["@_tts:textAlign"])) {
        ccEvent.alignment = styleMap[paragraph["@_style"]]["@_tts:textAlign"];
      }
      events.push(ccEvent);
    });

    // console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroup, options) {
    let output = eol.after(`<?xml version="1.0" encoding="utf-8"?>`);
    output += eol.after(`<tt xmlns="http://www.w3.org/ns/ttml" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>
        <ttm:title>Untitled</ttm:title>
      </metadata>`);
    output += eol.after(`<styling>
        <style tts:fontStyle="normal" tts:fontWeight="normal" xml:id="s1" tts:color="white" tts:fontFamily="Arial" tts:fontSize="100%"></style>
      </styling>`);
    output += eol.after(`<layout>
      <region tts:extent="80% 40%" tts:origin="10% 10%" tts:displayAlign="before" xml:id="top" />
      <region tts:extent="80% 40%" tts:origin="10% 30%" tts:displayAlign="after" xml:id="center" />
      <region tts:extent="80% 40%" tts:origin="10% 50%" tts:displayAlign="after" xml:id="bottom" />
    </layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div style="s1" xml:id="d1">`);
    eventGroup.events.forEach((event, index) => {
      let id = index + 1;
      let start = tcLib.secToTcMs(event.start);
      let end = tcLib.secToTcMs(event.end);
      let text = replaceLineEndings(htmlEncode(convertToPlainText(event.text)), "<br/>");
      let region = flexbox.flexMap.vertical[event.yPos];
      output += eol.after(`<p xml:id="p${id}" begin="${start}" end="${end}" region="${region}" tts:textAlign="${event.alignment}">${text}</p>`);
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += eol.after(`</tt>`);
    return xmlFormat(output);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};