<script>
import {
    environment
} from '@app/store/envStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    uiState
} from '@app/store/uiStore.js';
import {authState} from '@app/store/authStore.js';
import Styles from './Styles.svelte'
import FindAndReplace from './FindAndReplace.svelte'
import SpellCheck from './SpellCheck.svelte'
import StyleGuide from './StyleGuide.svelte'
import TimingAndSync from './TimingAndSync.svelte'
import EventTemplates from './EventTemplates.svelte'
import Voices from './Voices.svelte'
import Speakers from './Speakers.svelte'
import Tags from './Tags.svelte'
import Markers from './Markers.svelte'
import Notes from './Notes.svelte'
import VideoFilters from './VideoFilters.svelte'

$uiState.selected = "styles";

function updateSelectedTool(tool) {
    $uiState.selected = tool;
    if ($uiState.selected === "timingAndSync") {
        $projectState.mode = "timing";
    } else {
        $projectState.mode = "edit";
    }
}
</script>

<div class="row g-0 mt-2" id="QuickToolsWrapper">
    <div id="QuickTools">
        <div>
            <ul id="QuickToolsNav" class="nav nav-tabs">
                <li class="nav-item">
                    <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'styles' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("styles")}>Styles</button>
                </li>
                {#if $uiState.quickTools.search}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'findAndReplace' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("findAndReplace")}>Search & Replace</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.spellCheck}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'spellCheck' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("spellCheck")}>Spell Check</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.timing}
                    <li class="nav-item">
                        <button id="timingSyncTab" type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'timingAndSync' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("timingAndSync")}>Timing & Sync</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.qc}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'qcAndReview' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("qcAndReview")}>QC & Review</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.speakers}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'speakers' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("speakers")}>Speakers</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.tags}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'tags' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("tags")}>Tags</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.markers}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'markers' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("markers")}>Markers</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.notes}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'notes' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("notes")}>Notes</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.videoFilters}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'videoFilters' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("videoFilters")}>Video Filters</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.templates}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'eventTemplates' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("eventTemplates")}>Event Templates</button>
                    </li>
                {/if}
                {#if $authState.ad && $uiState.quickTools.voices && $environment.online}
                    <li class="nav-item">
                        <button type="button" class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'voices' ? 'active bg-light text-dark border-light' : ''}" on:click={() => updateSelectedTool("voices")}>Voices</button>
                    </li>
                {/if}
            </ul>
            <div class="tab-content p-2 border border-top-0 border-secondary rounded-bottom">
                {#if $uiState.selected === "qcAndReview" && $uiState.quickTools.qc}
                <StyleGuide></StyleGuide>
                {:else if $uiState.selected === "findAndReplace" && $uiState.quickTools.search}
                <FindAndReplace></FindAndReplace>
                {:else if $uiState.selected === "spellCheck"}
                <SpellCheck/>
                {:else if $uiState.selected === "timingAndSync" && $uiState.quickTools.timing}
                <TimingAndSync></TimingAndSync>
                {:else if $uiState.selected === "eventTemplates" && $uiState.quickTools.templates}
                <EventTemplates></EventTemplates>
                {:else if $uiState.selected === "speakers" && $uiState.quickTools.speakers}
                <Speakers></Speakers>
                {:else if $uiState.selected === "tags" && $uiState.quickTools.tags}
                <Tags></Tags>
                {:else if $uiState.selected === "markers" && $uiState.quickTools.markers}
                <Markers></Markers>
                {:else if $uiState.selected === "notes" && $uiState.quickTools.notes}
                <Notes></Notes>
                {:else if $uiState.selected === "videoFilters" && $uiState.quickTools.videoFilters}
                <VideoFilters></VideoFilters>
                {:else if $uiState.selected === "voices" && $authState.ad && $uiState.quickTools.voices && $environment.online}
                <Voices></Voices>
                {:else}
                <Styles></Styles>                
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
#QuickToolsNav {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: -webkit-box;
    will-change: transform;
}
</style>
