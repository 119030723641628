<script>
    import {environment} from '@app/store/envStore.js';
	import { fade } from "svelte/transition";
	import { modalState } from "@app/store/modalStore.js";
	import { toast } from '@zerodevx/svelte-toast';
	import firebase from "@app/configs/firebase.js";
	import db from "@app/configs/firestore.js";
	import _StyleGuide from "@app/external/cc-lib/dist/classes/styleGuide.js";

	let selectedGuide,
		styleGuides = [],
		userId = $environment.online ? firebase.auth().currentUser.uid : null,
		homeRef = $environment.online ? db.collection("users").doc(userId).collection("guides") : null,
		gettingStyleGuides = getStyleGuides();

	function getStyleGuides() {
        styleGuides = [];
        //Refactor code to use local storage when environment.online is false
        if ($environment.online){
            return homeRef
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					styleGuides = [...styleGuides, doc.data()];
				});

				selectedGuide = styleGuides[0];
			})

			.catch((error) => {
				console.log("Error getting style guides: ", error);
				toast.push(error.message, {classes: ['toast-danger']});
			});
        } else {
            styleGuides = JSON.parse(localStorage.getItem("cc-style-guides")) || [];
            selectedGuide = styleGuides.length > 0 ? styleGuides[0] : null;
        }		
	}

	function createStyleGuide() {
		let newStyleGuide = new _StyleGuide();
		let styleGuideObject = JSON.parse(JSON.stringify(newStyleGuide));
        //Refactor to use Local Storage isntead of firebase when environment.online is false 
        if ($environment.online){
            homeRef
			.doc(newStyleGuide.id)
			.set(styleGuideObject)
			.then(() => {
				toast.push("New style guide created", {classes: ['toast-success']});

				styleGuides = [...styleGuides, newStyleGuide];
				selectedGuide = styleGuides[styleGuides.length - 1];
			})
			.catch((error) => {
				console.log("Error writing new guide: ", error);
				toast.push(error.message, {classes: ['toast-danger']});
			});
        } else {
            styleGuides = [...styleGuides, newStyleGuide];
            selectedGuide = styleGuides[styleGuides.length - 1];
            localStorage.setItem("cc-style-guides", JSON.stringify(styleGuides));
            toast.push("New style guide created", {classes: ['toast-success']});
        }
	}

	function removeStyleGuide(id) {
        // Refactor code to use Local Storage instead of firebase when environment.online is false
        if ($environment.online){
            homeRef
			.doc(id)
			.delete()
			.then(() => {
				toast.push("Style guide removed successfully", {classes: ['toast-success']});
			})
			.catch((e) => {
				console.log(e.message, e);
				toast.push("Failed to delete style guide: " + e.message, {classes: ['toast-danger']});
			}).finally(() =>{
                gettingStyleGuides = getStyleGuides();
            });
        } else {
            let index = styleGuides.findIndex(styleGuide => {
                return styleGuide.id === id;
            });

            styleGuides.splice(index, 1);
            localStorage.setItem("cc-style-guides", JSON.stringify(styleGuides));
            toast.push("Style guide removed successfully", {classes: ['toast-success']});
            gettingStyleGuides = getStyleGuides();
        }		
	}

	function selectGuide(guide) {
		selectedGuide = guide;
	}

	function toggleGuide(guide) {
        guide.enabled = !guide.enabled;
        
        if (guide.id === selectedGuide.id){
            selectedGuide.enabled = guide.enabled;
        }

        saveGuide(guide);
    }

	function saveGuide(guide) {
        if (!guide){
            return;
        }

		let styleGuideObject = JSON.parse(JSON.stringify(guide));

        // Refactor to use local storage instead of firebase when environment.online is false
        if ($environment.online){
            homeRef
			.doc(guide.id)
			.update(styleGuideObject)
			.then(() => {
                let index = styleGuides.findIndex(styleGuide =>{
                    return styleGuide.id === guide.id
                });

                styleGuides[index] = guide;

				toast.push("Style guide changes saved", {classes: ['toast-success']});
			})
			.catch((error) => {
				console.log("Error saving guide: ", error);
				toast.push(error.message, {classes: ['toast-danger']});

                gettingStyleGuides = getStyleGuides();
			});
        } else {
            let index = styleGuides.findIndex(styleGuide =>{
                return styleGuide.id === guide.id
            });

            styleGuides[index] = guide;
            localStorage.setItem("cc-style-guides", JSON.stringify(styleGuides));
            toast.push("Style guide changes saved", {classes: ['toast-success']});
        }
            
		
	}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'styleGuideManager' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="StyleGuideManageModal">
	<div class="modal-dialog modal-xl modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Style Guide Manager</h4>
				<button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-3 border-end border-secondary">
						<div class="d-flex justify-content-end mb-2">
							<button type="button" class="btn btn-primary btn-sm me-2" title="New List" on:click={createStyleGuide}><i class="bi bi-plus-lg" /> New</button>

							<button type="button" class="btn btn-danger btn-sm {selectedGuide ? '' : 'disabled'}" title="Remove List" on:click={() => removeStyleGuide(selectedGuide.id)}><i class="bi bi-x-lg" /> Remove</button>
						</div>
						<ul class="nav nav-pills flex-column">
							{#await gettingStyleGuides}
								<p>Loading guides... please wait...</p>
							{:then}
								{#each styleGuides as guide (guide.id)}
									<li class="nav-item">
										<a class="nav-link {selectedGuide && selectedGuide.id === guide.id ? 'active' : ''}" href="#!/" on:click={() => selectGuide(guide)}>{guide.name} <input class="form-check-input float-end" type="checkbox" checked={guide.enabled} on:change={toggleGuide(guide)} title="Enable/Disable" /></a>
									</li>
								{:else}
									<p class="text-muted small text-center my-5">Please create a new guide to start</p>
								{/each}
							{/await}
						</ul>
					</div>
					<div id="StyleGuideEditSection" class="col">
						{#if selectedGuide}
							<form on:submit|preventDefault={saveGuide(selectedGuide)}>
								<p class="lead text-warning">General Settings</p>
								<div class="mb-3 form-check form-switch">
									<input type="checkbox" class="form-check-input" id="enableCheck" bind:checked={selectedGuide.enabled} />
									<label class="form-check-label" for="enableCheck"> {selectedGuide.enabled ? "Visible" : "Hidden"}</label>
								</div>
								<div class="mb-3">
									<label class="form-label" for="Project Name">Style Guide Name</label>
									<input class="form-control" type="text" required bind:value={selectedGuide.name} />
								</div>
								<div class="row">
									<div class="mb-3 col-12">
										<label class="form-label" for="Max Characters per Event">Total Characters</label>
										<input class="form-control form-control-sm" type="number" min="1" max="9999" step="1" bind:value={selectedGuide.totalMaxChars} />
									</div>
									<div class="mb-3 col-12">
										<label class="form-label" for="Max Characters per Line">Maximum Characters/Line</label>
										<input class="form-control form-control-sm" type="number" min="1" max="50" step="1" bind:value={selectedGuide.maxChars} />
									</div>
									<div class="mb-3 col-6">
										<label class="form-label" for="Min Event Lines">Minimum Event Lines</label>
										<input class="form-control form-control-sm" type="number" min="1" max="5" step="1" bind:value={selectedGuide.minLines} />
									</div>
									<div class="mb-3 col-6">
										<label class="form-label" for="Max Event Lines">Maximum Event Lines</label>
										<input class="form-control form-control-sm" type="number" min="1" max="5" step="1" bind:value={selectedGuide.maxLines} />
									</div>
									<div class="mb-3 col-6">
										<label class="form-label" for="Min Words Per Line">Minimum Words/Line</label>
										<input class="form-control form-control-sm" type="number" min="0" max="99" step="1" bind:value={selectedGuide.minWordsPerLine} />
									</div>
									<div class="mb-3 col-6">
										<label class="form-label" for="Max Words Per Line">Maximum Words/Line</label>
										<input class="form-control form-control-sm" type="number" min="0" max="99" step="1" bind:value={selectedGuide.maxWordsPerLine} />
									</div>
								</div>
								<div class="row">
									<div class="col-6">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="overlapCheck" bind:checked={selectedGuide.overlap} />
											<label class="form-check-label" for="overlapCheck">Detect Overlap <i class="bi bi-info-circle" title="Check that event times do not overlap with another event(s)." /></label>
										</div>
									</div>
									<div class="col-6">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="illegalCharsCheck" bind:checked={selectedGuide.illegalChars} />
											<label class="form-check-label" for="illegalCharsCheck">Detect Illegal Chararacters (608)</label>
										</div>
									</div>
									<div class="col-6">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="missingSpeakerCheck" bind:checked={selectedGuide.missingSpeaker} />
											<label class="form-check-label" for="missingSpeakerCheck">Missing Speaker Assignment</label>
										</div>
									</div>
									<div class="col-6">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="netflixGlyphsCheck" bind:checked={selectedGuide.netflixGlyphs} />
											<label class="form-check-label" for="netflixGlyphsCheck">Detect Illegal Characters (Netflix)</label>
										</div>
									</div>
								</div>
								<p class="lead text-warning">Event Duration</p>
								<div class="row">
									<div class="mb-3 col">
										<label class="form-label" for="Minimum duration in seconds">Minimum Event Duration (in seconds)</label>
										<input class="form-control form-control-sm" type="number" min="0.01" max="25" step="0.01" bind:value={selectedGuide.minDuration} />
									</div>
									<div class="mb-3 col">
										<label class="form-label" for="Maximum duration in seconds">Maximum Event Duration (in seconds)</label>
										<input class="form-control form-control-sm" type="number" min="0.01" max="25" step="0.01" bind:value={selectedGuide.maxDuration} />
									</div>
								</div>
								<p class="lead text-warning">Characters Per Second</p>
								<div class="row">
									<div class="mb-3 col">
										<label class="form-label" for="Minimum CPS">Minimum Characters Per Second (CPS)</label>
										<input class="form-control form-control-sm" type="number" min="1" step="1" bind:value={selectedGuide.minCps} />
									</div>
									<div class="mb-3 col">
										<label class="form-label" for="Maximum CPS">Maximum Characters Per Second (CPS)</label>
										<input class="form-control form-control-sm" type="number" min="1" step="1" bind:value={selectedGuide.maxCps} />
									</div>
								</div>
								<p class="lead text-warning">Words Per Minute</p>
								<div class="row">
									<div class="mb-3 col">
										<label class="form-label" for="Minimum WPM">Minimum Words Per Minute (WPM)</label>
										<input class="form-control form-control-sm" type="number" min="1" step="1" bind:value={selectedGuide.minWpm} />
									</div>
									<div class="mb-3 col">
										<label class="form-label" for="Maximum WPM">Maximum Words Per Minute (WPM)</label>
										<input class="form-control form-control-sm" type="number" min="1" step="1" bind:value={selectedGuide.maxWpm} />
									</div>
								</div>
								<p class="lead text-warning">Event Gap <i class="bi bi-info-circle" title="The minimum and maximum duration in frames between events" /></p>
								<div class="row">
									<div class="mb-3 col">
										<label class="form-label" for="Minimum Event Gap">Minimum Event Gap</label>
										<input class="form-control form-control-sm" type="number" min="0" step="1" bind:value={selectedGuide.minEventGap} />
									</div>
									<div class="mb-3 col">
										<label class="form-label" for="Maximum Event Gap">Maximum Event Gap</label>
										<input class="form-control form-control-sm" type="number" min="0" step="1" bind:value={selectedGuide.maxEventGap} />
									</div>
								</div>
								<p class="lead text-warning">Approvals</p>
								<div class="row">
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="approvalPassedCheck" bind:checked={selectedGuide.approvalPassed} />
											<label class="form-check-label" for="approvalPassedCheck">Approval Passed</label>
										</div>
									</div>

									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="approvalFailedCheck" bind:checked={selectedGuide.approvalFailed} />
											<label class="form-check-label" for="approvalFailedCheck">Approval Failed</label>
										</div>
									</div>

									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="approvalNotSetCheck" bind:checked={selectedGuide.approvalNotSet} />
											<label class="form-check-label" for="approvalNotSetCheck">Approval Not Set</label>
										</div>
									</div>
								</div>
								<p class="lead text-warning">Metadata</p>
								<div class="row">
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="notesCheck" bind:checked={selectedGuide.notes} />
											<label class="form-check-label" for="notesCheck"> Notes</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="replyCheck" bind:checked={selectedGuide.reply} />
											<label class="form-check-label" for="replyCheck"> Reply</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="notesDoneCheck" bind:checked={selectedGuide.notesDone} />
											<label class="form-check-label" for="notesDoneCheck"> Notes Incomplete</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="replyDoneCheck" bind:checked={selectedGuide.replyDone} />
											<label class="form-check-label" for="replyDoneCheck"> Reply Incomplete</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="tagsCheck" bind:checked={selectedGuide.tags} />
											<label class="form-check-label" for="tagsCheck"> Tags</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="forcedSubtitleCheck" bind:checked={selectedGuide.forced} />
											<label class="form-check-label" for="forcedSubtitleCheck"> Forced Subtitle</label>
										</div>
									</div>
								</div>
								<p class="lead text-warning">Formatting</p>
								<div class="row">
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="partialBoldCheck" bind:checked={selectedGuide.partialBold} />
											<label class="form-check-label" for="partialBoldCheck">Partial Bold <i class="bi bi-info-circle" title="Check for Events where some words or lines are bolded" /></label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="fullBoldCheck" bind:checked={selectedGuide.fullBold} />
											<label class="form-check-label" for="fullBoldCheck">Bold <i class="bi bi-info-circle" title="Check for Events where all lines are bolded" /></label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="partialUnderlineCheck" bind:checked={selectedGuide.partialUnderline} />
											<label class="form-check-label" for="partialUnderlineCheck">Partial Underline <i class="bi bi-info-circle" title="Check for Events where some words or lines are underlined" /></label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="fullUnderlineCheck" bind:checked={selectedGuide.fullUnderline} />
											<label class="form-check-label" for="fullUnderlineCheck">Underline <i class="bi bi-info-circle" title="Check for Events where all lines are underlined" /></label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="partialItalicsCheck" bind:checked={selectedGuide.partialItalics} />
											<label class="form-check-label" for="partialItalicsCheck">Partial Italics <i class="bi bi-info-circle" title="Check for Events where some words or lines are italicized" /></label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="fullItalicsCheck" bind:checked={selectedGuide.fullItalics} />
											<label class="form-check-label" for="fullItalicsCheck">Italics <i class="bi bi-info-circle" title="Check for Events where all lines are italicized" /></label>
										</div>
									</div>
								</div>
								<p class="lead text-warning">Position</p>
								<div class="row">
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionTopLeftCheck" bind:checked={selectedGuide.positionTopLeft} />
											<label class="form-check-label" for="positionTopLeftCheck">Top Left</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionTopCenterCheck" bind:checked={selectedGuide.positionTopCenter} />
											<label class="form-check-label" for="positionTopCenterCheck">Top Center</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionTopRightCheck" bind:checked={selectedGuide.positionTopRight} />
											<label class="form-check-label" for="positionTopRightCheck">Top Right</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionCenterLeftCheck" bind:checked={selectedGuide.positionCenterLeft} />
											<label class="form-check-label" for="positionCenterLeftCheck">Center Left</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionCenterCheck" bind:checked={selectedGuide.positionCenter} />
											<label class="form-check-label" for="positionCenterCheck">Center</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionCenterRightCheck" bind:checked={selectedGuide.positionCenterRight} />
											<label class="form-check-label" for="positionCenterRightCheck">Center Right</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionBottomLeftCheck" bind:checked={selectedGuide.positionBottomLeft} />
											<label class="form-check-label" for="positionBottomLeftCheck">Bottom Left</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionBottomCenterCheck" bind:checked={selectedGuide.positionBottomCenter} />
											<label class="form-check-label" for="positionBottomCenterCheck">Bottom Center</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionBottomRightCheck" bind:checked={selectedGuide.positionBottomRight} />
											<label class="form-check-label" for="positionBottomRightCheck">Bottom Right</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionXOffsetCheck" bind:checked={selectedGuide.positionXOffset} />
											<label class="form-check-label" for="positionXOffsetCheck">Horizontal (X) Offset <i class="bi bi-info-circle" title="Check for Events where the horizontal offset is set." /></label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="positionYOffsetCheck" bind:checked={selectedGuide.positionYOffset} />
											<label class="form-check-label" for="positionYOffsetCheck">Vertical (Y) Offset <i class="bi bi-info-circle" title="Check for Events where the vertical offset is set" /></label>
										</div>
									</div>
								</div>
								<p class="lead text-warning">Text</p>
								<div class="row">
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="hyphenSpaceCheck" bind:checked={selectedGuide.hyphenSpace} />
											<label class="form-check-label" for="hyphenSpaceCheck">Hyphen With Space</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="hasUnderscoreCheck" bind:checked={selectedGuide.hasUnderscore} />
											<label class="form-check-label" for="hasUnderscoreCheck">Underscore _</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="periodsCheck" bind:checked={selectedGuide.periods} />
											<label class="form-check-label" for="periodsCheck">2 or 4+ dots</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="useEllipsesCheck" bind:checked={selectedGuide.useEllipses} />
											<label class="form-check-label" for="useEllipsesCheck">Missing Ellipses</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="spellNumbersCheck" bind:checked={selectedGuide.spellNumbers} />
											<label class="form-check-label" for="spellNumbersCheck">Spell #1...10</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="spellNumbersAtStartCheck" bind:checked={selectedGuide.spellNumbersAtStart} />
											<label class="form-check-label" for="spellNumbersAtStartCheck">Spell Numbers At Start Of Line</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="repeatWordsCheck" bind:checked={selectedGuide.repeatWords} />
											<label class="form-check-label" for="repeatWordsCheck">Repeat Words</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="fitSubtitlesCheck" bind:checked={selectedGuide.fitSubtitles} />
											<label class="form-check-label" for="fitSubtitlesCheck">Fit Single Line</label>
										</div>
									</div>
								</div>
								<p class="lead text-warning">Whitespace</p>
								<div class="row">
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="leadingTrailingSpaceCheck" bind:checked={selectedGuide.leadingTrailingSpace} />
											<label class="form-check-label" for="leadingTrailingSpaceCheck">Leading & Trailing Spaces</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="whitespaceCheck" bind:checked={selectedGuide.whitespace} />
											<label class="form-check-label" for="whitespaceCheck">Whitespace</label>
										</div>
									</div>
									<div class="col-4">
										<div class="mb-3 form-check form-switch">
											<input type="checkbox" class="form-check-input" id="blankLinesCheck" bind:checked={selectedGuide.blankLines} />
											<label class="form-check-label" for="blankLinesCheck">Blank Lines</label>
										</div>
									</div>
								</div>
								<hr />
							</form>
						{:else}
							<p class="text-center text-muted lead my-5">Please select a list from the menu on the left</p>
						{/if}
					</div>
				</div>
			</div>

			<div class="modal-footer">
				{#if selectedGuide}
					<button type="button" class="btn btn-primary" on:click={saveGuide(selectedGuide)} disabled={!selectedGuide.name || selectedGuide.name === "none"}>Save Changes</button>
				{:else}
					<button type="button" class="btn btn-light" on:click={modalState.hideModal}>Close</button>
				{/if}
			</div>
		</div>
	</div>
</div>

<style>
	#StyleGuideEditSection {
		max-height: 70vh;
		overflow-y: auto;
	}
</style>
