import _stripTags from "../functions/quill/stripTags.js";
import _autoFormatSimple from "../functions/utility/autoFormatSimple.js";
import _sccLookup from "../dict/608.js";
import _sccFunc from "../functions/profiles/scenerist.js";
import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
/* 
    Title Safe Info:
    http://www.indefilms.net/html/title_safe_area.html
    SCC INFO:
    -We work on a grid that is 0-31 across and 0-14 down (32x15)
    -There is a fixed offset of 10% (x) and 10% (y). This is due to the caption area being 90% of the frames width and 90% of the frames height.
    -The fixed offset is only applied to decode. For encode we try and fit it to the best location.
    -The fixed offset is applied during post-process decode.
*/
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const eol = _eol;
const sccFunc = _sccFunc;
const sccLookup = _sccLookup;
const autoFormatSimple = _autoFormatSimple;
const stripTags = _stripTags;
export default {
  decode: function (input, options) {
    let events = [],
      displays = [];
    let decodingOptions = getFormatOptions(options.formatOptions);
    let buffer = new sccFunc.Display(),
      screen = false,
      clock,
      displayStyle = false,
      sccCode,
      fileLineTc,
      addOne = false;
    buffer.insertLine();

    /* Split File lines and filter anything that doesn't have a timecode */
    let fileLines = input.split("\n").filter(fileLine => {
      return /^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g.test(fileLine.split("\t")[0]);
    });

    //Frame Rate scan:
    let highestFrameValue = 0;
    let dropFrameFlag = false;
    let channel = decodingOptions["Channel"] || "ch01";
    fileLines.forEach((fileLine, index, lines) => {
      fileLineTc = fileLine.split("\t")[0];
      //get the last two digits of the smpte timecode (e.g. 01:00:00:24 -> 24 or 01:00:00;24 -> 24)
      let frameValue = fileLineTc.split(":").pop().split(";").pop();
      if (parseInt(frameValue) === Math.round(options.frameRate)) {
        //Update the timecode in the file line to match the options.frameRate-1. We must be specific to only change the frame value and not any other part of the timecode. The frame value is followed by a tab character (e.g 01:14:12:04\t)
        lines[index] = fileLine.replace(/(?<=\d{2}[;:]\d{2}[;:]\d{2}[;:])\d\d\b/, (Math.round(options.frameRate) - 1).toString());
        //console.log(lines[index]);
      } else if (parseInt(frameValue) > highestFrameValue) {
        highestFrameValue = parseInt(frameValue);
      }

      //if the timecode contains a ; then it is drop frame
      if (fileLineTc.includes(";")) {
        dropFrameFlag = true;
      }
    });
    if (highestFrameValue > options.frameRate) {
      // set options.frameRate to closest supported frameRate based on the highest frame value found in the file. Supported frame rates include 23.976, 24, 25, 29.97, 30, 50, 59.94, 60 
      if (highestFrameValue <= 24) {
        options.dropFrame = false;
        options.frameRate = 24;
      } else if (highestFrameValue <= 25) {
        options.dropFrame = false;
        options.frameRate = 25;
      } else if (highestFrameValue <= 30) {
        if (dropFrameFlag) {
          options.dropFrame = true;
          options.frameRate = 29.97;
        } else {
          options.dropFrame = false;
          options.frameRate = 30;
        }
      } else if (highestFrameValue <= 50) {
        options.dropFrame = false;
        options.frameRate = 50;
      } else if (highestFrameValue <= 60) {
        if (dropFrameFlag) {
          options.dropFrame = true;
          options.frameRate = 59.94;
        } else {
          options.dropFrame = false;
          options.frameRate = 60;
        }
      }
      console.log(`Frame rate set to ${options.frameRate} based on the highest frame value (${highestFrameValue}) found in the file.`);
    }
    fileLines.forEach(fileLine => {
      fileLineTc = fileLine.split("\t")[0];
      clock = tcLib.createTc(fileLineTc, options.frameRate, options.dropFrame);

      /* Get all caption codes */
      let captionCodes = fileLine.split("\t")[1].trim().split(" ");
      captionCodes.forEach(function (code, index, codes) {
        //console.log(`${code} added at tc: ${clock.toString()}`)
        if (code.length !== 4) {
          throw new Error("SCC decode error at timecode: " + clock.toString() + "\nCommand length is not 4: " + code);
        }
        if (sccLookup[channel][code] && code !== codes[index + 1] || sccLookup.specialChars.indexOf(sccLookup[channel][code]) > -1) {
          sccCode = sccLookup[channel][code];
          if (sccCode === "{RESUME LOADING}") {
            //console.log(`Resume loading added at tc: ${clock.toString()}`)
            displayStyle = "popOn";
            if (buffer.lines.length > 0 && buffer.lines[0].text !== "") {
              buffer = new sccFunc.Display();
              buffer.insertLine();
            }

            //console.log(`Resume loading added at tc: ${clock.toString()}`)
          } else if (sccCode === "{PAINT ON}") {
            displayStyle = "paintOn";
            screen = new sccFunc.Display({
              style: "Paint-On",
              start: tcLib.tcToSec(clock.toString(), options.frameRate)
            });
            screen.insertLine();
          } else if (sccCode === "{ROLLUP2}") {
            displayStyle = "rollUp2";

            /* Add outcode for the top line of event */
            if (displays.length > 0 && displays[displays.length - 1].style === "Roll-Up 2" && !displays[displays.length - 1].end) {
              displays[displays.length - 1].end = tcLib.tcToSec(clock.toString(), options.frameRate);
            }
            if (screen) {
              displays.push(screen);
            }
            screen = new sccFunc.Display({
              style: "Roll-Up 2",
              start: tcLib.tcToSec(clock.toString(), options.frameRate)
            });
            screen.insertLine({
              yPos: 13
            });
          } else if (sccCode === "{ROLLUP3}") {
            displayStyle = "rollUp3";

            /* Add outcode for the top line of event */
            if (displays.length > 1 && displays[displays.length - 2].style === "Roll-Up 3" && !displays[displays.length - 2].end) {
              displays[displays.length - 2].end = tcLib.tcToSec(clock.toString(), options.frameRate);
            }
            if (screen) {
              displays.push(screen);
            }
            screen = new sccFunc.Display({
              style: "Roll-Up 3",
              start: tcLib.tcToSec(clock.toString(), options.frameRate)
            });
            screen.insertLine({
              yPos: 12
            });
          } else if (sccCode === "{ROLLUP4}") {
            displayStyle = "rollUp4";

            /* Add outcode for the top line of event */
            if (displays.length > 2 && displays[displays.length - 3].style === "Roll-Up 4" && !displays[displays.length - 3].end) {
              displays[displays.length - 3].end = tcLib.tcToSec(clock.toString(), options.frameRate);
            }
            if (screen) {
              displays.push(screen);
            }
            screen = new sccFunc.Display({
              style: "Roll-Up 4",
              start: tcLib.tcToSec(clock.toString(), options.frameRate)
            });
            screen.insertLine({
              yPos: 11
            });
          } else if (sccCode === "{TEXT MSG}") {
            /* Not Supported */
          } else if (sccCode === "{RESUME TEXT MSG}") {
            /* Not Supported */
          } else if (sccCode === "{CLEAR BUFFER}") {
            //console.log(`Clear buffer added at tc: ${clock.toString()}`)
            buffer = new sccFunc.Display();
            buffer.insertLine();
          } else if (sccCode === "{CLEAR DISPLAY}") {
            //console.log(screen);
            if (screen && !screen.end) {
              screen.end = tcLib.tcToSec(clock.toString(), options.frameRate);

              //console.log(`Clear Screen added at tc: ${clock.toString()}`)

              displays.push(screen);
              screen = new sccFunc.Display({
                style: screen.style,
                start: tcLib.tcToSec(clock.toString(), options.frameRate)
              });
              screen.insertLine({
                yPos: 11
              });
            }
            displays.forEach((display, index, displaysArray) => {
              if (["Roll-Up 2", "Roll-Up 3", "Roll-Up 4", "Paint-On"].indexOf(display.style) > -1 && !display.end) {
                displaysArray[index].end = tcLib.tcToSec(clock.toString(), options.frameRate);
              }
            });
          } else if (sccCode === "{DISPLAY BUFFER}") {
            //console.log(`Display Screen added at tc: ${clock.toString()}${JSON.stringify(buffer.lines)}`)
            /* Clear what's on the screen if there is something still being shown */
            if (screen && !screen.end) {
              screen.end = tcLib.tcToSec(clock.toString(), options.frameRate);
              displays.push(screen);
            }
            buffer.start = tcLib.tcToSec(clock.toString(), options.frameRate);
            screen = buffer;
            buffer = new sccFunc.Display();
            buffer.insertLine();
          } else if (sccCode === "{ITALICS}") {
            //console.log(`Italics added at: ${clock.toString()}`)
            if (displayStyle === "popOn") {
              if (buffer.lines[buffer.lines.length - 1].text.length > 0 && !buffer.lines[buffer.lines.length - 1].text.endsWith(' ')) {
                buffer.lines[buffer.lines.length - 1].text += " ";
              }
              buffer.lines[buffer.lines.length - 1].text += "<em>";
            } else if (screen) {
              if (screen.lines[screen.lines.length - 1].text.length > 0 && !screen.lines[screen.lines.length - 1].text.endsWith(' ')) {
                screen.lines[screen.lines.length - 1].text += " ";
              }
              screen.lines[screen.lines.length - 1].text += "<em>";
            }
          } else if (sccCode === "{UNDERLINE}") {
            if (displayStyle === "popOn") {
              if (buffer.lines[buffer.lines.length - 1].text.length > 0 && !buffer.lines[buffer.lines.length - 1].text.endsWith(' ')) {
                buffer.lines[buffer.lines.length - 1].text += " ";
              }
              buffer.lines[buffer.lines.length - 1].text += "<u>";
            } else {
              if (screen.lines[screen.lines.length - 1].text.length > 0 && !screen.lines[screen.lines.length - 1].text.endsWith(' ')) {
                screen.lines[screen.lines.length - 1].text += " ";
              }
              screen.lines[screen.lines.length - 1].text += "<u>";
            }
          } else if (sccCode === "{ITALICS_UNDERLINE}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].text += "<em><u>";
            } else {
              screen.lines[screen.lines.length - 1].text += "<em><u>";
            }
          } else if (/{\d\d_\d\d}{ITALICS_UNDERLINE}/.test(sccCode)) {
            let xPos = parseInt(sccCode.substring(4, 6));
            let yPos = parseInt(sccCode.substring(1, 3));
            if (displayStyle === "popOn") {
              buffer.insertLine({
                text: "<em><u>",
                xPos: xPos,
                yPos: yPos
              });
            } else {
              screen.insertLine({
                text: "<em><u>",
                xPos: xPos,
                yPos: yPos
              });
            }
          } else if (/{\d\d_\d\d}{ITALICS}/.test(sccCode)) {
            let xPos = parseInt(sccCode.substring(4, 6));
            let yPos = parseInt(sccCode.substring(1, 3));
            if (displayStyle === "popOn") {
              buffer.insertLine({
                text: "<em>",
                xPos: xPos,
                yPos: yPos
              });
            } else if (screen) {
              screen.insertLine({
                text: "<em>",
                xPos: xPos,
                yPos: yPos
              });
            }
          } else if (/{\d\d_\d\d}{UNDERLINE}/.test(sccCode)) {
            let xPos = parseInt(sccCode.substring(4, 6));
            let yPos = parseInt(sccCode.substring(1, 3));
            if (displayStyle === "popOn") {
              buffer.insertLine({
                text: "<u>",
                xPos: xPos,
                yPos: yPos
              });
            } else {
              screen.insertLine({
                text: "<u>",
                xPos: xPos,
                yPos: yPos
              });
            }
          } else if (/{\d\d_\d\d}/.test(sccCode)) {
            let xPos = parseInt(sccCode.substring(4, 6));
            let yPos = parseInt(sccCode.substring(1, 3));
            if (displayStyle === "popOn") {
              buffer.insertLine({
                xPos: xPos,
                yPos: yPos
              });
            } else if (screen) {
              screen.insertLine({
                xPos: xPos,
                yPos: yPos
              });
            }
          } else if (sccCode.includes("TAB")) {
            let xOffset = parseInt(sccCode.split("TAB")[1]);
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].xPos += xOffset;
            } else if (screen) {
              screen.lines[screen.lines.length - 1].xPos += xOffset;
            }
          } else if (sccCode.includes("{COLOR:")) {
            let color = sccCode.split(":")[1].split(";")[0].split("}")[0];
            let underline = sccCode.includes("UNDERLINE");
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].color = sccFunc.colorMapping[color];
              underline ? buffer.lines[buffer.lines.length - 1].text += "<u>" : null;
              if (sccCode === "{COLOR:WHITE}" && codes[index + 1] && sccLookup[channel][codes[index + 1]] !== " ") {
                if (buffer.lines[buffer.lines.length - 1].text.includes("<em>") && !buffer.lines[buffer.lines.length - 1].text.includes("</em>")) {
                  buffer.lines[buffer.lines.length - 1].text += "</em>";
                }
                buffer.lines[buffer.lines.length - 1].text += " ";
              }
            } else {
              screen.lines[screen.lines.length - 1].color = sccFunc.colorMapping[color];
              underline ? screen.lines[screen.lines.length - 1].text += "<u>" : null;
              if (sccCode === "{COLOR:WHITE}" && codes[index + 1] && sccLookup[channel][codes[index + 1]] !== " ") {
                screen.lines[screen.lines.length - 1].text += " ";
              }
            }
          } else if (sccCode.includes("{BACKGROUND:")) {
            let bgColor = sccCode.split(":")[1].split(";")[0].split("}")[0];
            let opacity = 1;
            if (sccCode.includes("TRANSPARENT") && bgColor != "NONE") {
              opacity = 0.5;
            } else if (bgColor === "NONE") {
              opacity = 0.0;
            }
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].background = sccFunc.colorMapping[bgColor];
              buffer.lines[buffer.lines.length - 1].opacity = opacity;
            } else {
              screen.lines[screen.lines.length - 1].background = sccFunc.colorMapping[bgColor];
              screen.lines[screen.lines.length - 1].opacity = opacity;
            }
          } else if (sccCode === "{NEW LINE}") {
            if (displayStyle === "popOn") {
              if (buffer.lines.length > 0 && buffer.lines[buffer.lines.length - 1].text) {
                buffer.insertLine();
              }
            } else if (displayStyle === 'rollUp2' || displayStyle === 'rollUp3' || displayStyle === 'rollUp4') {
              if (screen && screen.lines[screen.lines.length - 1].text) {
                /* Add outcode for the top line of event */
                if (displayStyle === 'rollUp2') {
                  if (displays.length > 0 && displays[displays.length - 1].style === "Roll-Up 2" && !displays[displays.length - 1].end) {
                    displays[displays.length - 1].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                  }
                  if (screen) {
                    displays.push(screen);
                  }
                  screen = new sccFunc.Display({
                    style: "Roll-Up 2",
                    start: tcLib.tcToSec(clock.toString(), options.frameRate)
                  });
                  screen.insertLine({
                    yPos: 13
                  });
                } else if (displayStyle === 'rollUp3') {
                  if (displays.length > 1 && displays[displays.length - 2].style === "Roll-Up 3" && displays[displays.length - 2].end) {
                    displays[displays.length - 2].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                  }
                  if (screen) {
                    displays.push(screen);
                  }
                  screen = new sccFunc.Display({
                    style: "Roll-Up 3",
                    start: tcLib.tcToSec(clock.toString(), options.frameRate)
                  });
                  screen.insertLine({
                    yPos: 12
                  });
                } else if (displayStyle === 'rollUp4') {
                  if (displays.length > 2 && displays[displays.length - 3].style === "Roll-Up 4" && !displays[displays.length - 3].end) {
                    displays[displays.length - 3].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                  }
                  if (screen) {
                    displays.push(screen);
                  }
                  screen = new sccFunc.Display({
                    style: "Roll-Up 4",
                    start: tcLib.tcToSec(clock.toString(), options.frameRate)
                  });
                  screen.insertLine({
                    yPos: 11
                  });
                }
              }
            } else if (displayStyle === 'paintOn') {
              if (screen) {
                screen.insertLine({
                  style: "Paint-On"
                });
              }
            }
          } else if (sccCode === "{BACKSPACE}") {
            if (displayStyle === "popOn") {
              if (buffer.lines && buffer.lines[buffer.lines.length - 1].text && !buffer.lines[buffer.lines.length - 1].text.endsWith('>')) {
                buffer.lines[buffer.lines.length - 1].text = buffer.lines[buffer.lines.length - 1].text.slice(0, -1);
              }
            } else {
              screen.lines[screen.lines.length - 1].text = screen.lines[buffer.lines.length - 1].text.slice(0, -1);
            }
          } else if (sccCode === "{DELETE ROW}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].text = "";
              buffer.lines[buffer.lines.length - 1].yPos = 0;
            } else {
              screen.lines[screen.lines.length - 1].text = "";
              screen.lines[screen.lines.length - 1].yPos = 0;
            }
          } else if (sccCode !== "{FILLER}") {
            if (displayStyle === "popOn") {
              buffer.lines[buffer.lines.length - 1].text += sccCode;
            } else if (screen) {
              screen.lines[screen.lines.length - 1].text += sccCode;
            }
          }

          // Add 1 frame for each byte (or hex group);
          //console.log(`Adding 1 frame to tc: ${clock.toString()}`)
          /* control codes are only worth 0.5 frames */
          if (addOne) {
            //console.log(clock);
            //console.log(`Before tc: ${clock.toString()} (${clock.frameCount})`);
            clock.add(1);
            //console.log(`After tc: ${clock.toString()} (${clock.frameCount})`);
            addOne = false;
          } else {
            addOne = true;
          }

          //console.log(`Added 1 frame to tc: ${clock.toString()}`)
        } else if (!sccLookup[channel][code]) {
          if (addOne) {
            clock.add(1);
            addOne = false;
          }
          [code.substring(0, 2), code.substring(2, 4)].filter(sccCode => {
            return sccLookup[channel][sccCode] !== undefined && sccLookup[channel][sccCode] !== "{FILLER}";
          }).forEach(sccCode => {
            if (displayStyle === "popOn") {
              if (buffer.lines.length === 0) {
                buffer.insertLine();
              }
              buffer.lines[buffer.lines.length - 1].text += sccLookup[channel][sccCode];
            } else if (screen.lines) {
              screen.lines[screen.lines.length - 1].text += sccLookup[channel][sccCode];
            }
          });

          // Add 1 frame for each byte (or hex group);
          clock.add(1);
        }
      });
    });

    //console.log(JSON.stringify(displays, null, 4));
    displays.forEach(display => {
      events.push(sccFunc.decodeDisplay(display, options.window));
    });

    //console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroups, options) {
    let output = "Scenarist_SCC V1.0",
      encodingOptions = getFormatOptions(options.formatOptions),
      channels = [],
      processing = true,
      eventGroupChannelMappings,
      timecodeOption = "auto",
      vChipCmds,
      contentAdvisoryCmds,
      programNameCmds,
      programLengthCmds,
      clock;

    //The ccChannelStatus manages the status of each channel so that we know when clear/and start commands need to be sent/updated.
    let ccChannelStatus = [{
      id: "ch01",
      screen: false,
      buffer: false
    }, {
      id: "ch02",
      screen: false,
      buffer: false
    }, {
      id: "ch03",
      screen: false,
      buffer: false
    }, {
      id: "ch04",
      screen: false,
      buffer: false
    }];

    /* console.log(JSON.stringify(eventGroups, null, 4));
    console.log(JSON.stringify(options, null, 4)); */

    if (encodingOptions["Program Information"] && encodingOptions["Program Information"].enable) {
      if (encodingOptions["Program Information"].programName) {
        //Program Name & maybe description later on
        programNameCmds = sccFunc.encodeProgramName(encodingOptions["Program Information"].programName);
        programNameCmds = sccFunc.formatEncodedCmds(programNameCmds);
        /* Program Length */
        programLengthCmds = sccFunc.encodeProgramLength(encodingOptions["Program Information"].programLengthHours, encodingOptions["Program Information"].programLengthMinutes);
        programLengthCmds = sccFunc.formatEncodedCmds(programLengthCmds);
        /* Keyword Groups */
        contentAdvisoryCmds = sccFunc.encodeProgramType([encodingOptions["Program Information"].basicKeywordGroup, ...encodingOptions["Program Information"].detailKeywordGroup]);
        contentAdvisoryCmds = sccFunc.formatEncodedCmds(contentAdvisoryCmds);

        //console.log("Program Name Commands:", programNameCmds);
        //console.log("Porprogram Length Commands:", programLengthCmds);
        //console.log("Content Advisory Commands:", contentAdvisoryCmds); 
      }
    }
    if (encodingOptions["V-Chip Information"] && encodingOptions["V-Chip Information"].enable) {
      vChipCmds = sccFunc.encodeVChipInfo(encodingOptions["V-Chip Information"].type, encodingOptions["V-Chip Information"].rating, encodingOptions["V-Chip Information"].content);
      vChipCmds = sccFunc.formatEncodedCmds(vChipCmds);
      //console.log("VChip Commands:", vChipCmds);
    }
    if (encodingOptions["Event Group Channel Mappings"]) {
      eventGroupChannelMappings = encodingOptions["Event Group Channel Mappings"];
      eventGroupChannelMappings = {
        "cc1": !isNaN(eventGroupChannelMappings["cc1"]) || eventGroupChannelMappings["cc1"] === 0 ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc1"]])) : undefined,
        "cc2": eventGroupChannelMappings["cc2"] || eventGroupChannelMappings["cc2"] === 0 ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc2"]])) : undefined,
        "cc3": eventGroupChannelMappings["cc3"] || eventGroupChannelMappings["cc3"] === 0 ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc3"]])) : undefined,
        "cc4": eventGroupChannelMappings["cc4"] || eventGroupChannelMappings["cc4"] === 0 ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc4"]])) : undefined
      };
    } else {
      eventGroupChannelMappings = {
        "cc1": eventGroups[0]
      };
    }
    if (encodingOptions["Timecode Format"]) {
      timecodeOption = encodingOptions["Timecode Format"].toLowerCase();
    }
    if (options.incode && options.incode !== "00:00:00:00" && options.incode !== "00:00:00;00") {
      try {
        clock = tcLib.createTc(options.incode, options.frameRate, options.dropFrame);
      } catch (err) {
        throw new Error(err.message);
      }
    } else {
      clock = tcLib.createTc(tcLib.secToTc(eventGroups[0].events[0].start, options.frameRate), options.frameRate, options.dropFrame);
      try {
        clock.subtract(300);
      } catch (e) {
        clock = tcLib.createTc("00:00:00:01", options.frameRate, options.dropFrame);
      }
    }
    Object.keys(eventGroupChannelMappings).forEach(ch => {
      if (!eventGroupChannelMappings[ch]) {
        return;
      }
      let channel = ch.replace("cc", "ch0");
      channels.push(channel);
      eventGroupChannelMappings[ch].events.forEach((event, index, events) => {
        events[index].processed = false;
        events[index].channel = channel;
        events[index].startFrame = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].endFrame = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].eventDetails = sccFunc.getEventDetails(event, options.window);
        events[index].encodedText = sccFunc.encodeEvent(events[index].eventDetails, channel, options.window);
        events[index].encodedTextString = sccFunc.formatEncodedCmds(events[index].encodedText);
        events[index].encodeTime = sccFunc.calculateEncodeTime(events[index].encodedTextString);
        events[index].encodeStartTime = event.style === "Pop-On" ? events[index].startFrame - (events[index].encodeTime + 120) : events[index].startFrame;
      });
    });
    output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t";
    channels.forEach(ch => {
      output += sccFunc.getCodeByCmd(sccLookup[ch], "{CLEAR DISPLAY}") + " " + sccFunc.getCodeByCmd(sccLookup[ch], "{CLEAR BUFFER}") + " ";
      clock.add(2);
    });
    if (vChipCmds) {
      output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + vChipCmds;
      clock.add(vChipCmds.split(" ").length + 2);
    }
    if (contentAdvisoryCmds) {
      output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + contentAdvisoryCmds;
      clock.add(contentAdvisoryCmds.split(" ").length + 2);
    }
    let eventsToProcess, displayOrClearCommands;
    while (processing) {
      //Get display or clear commands based on the ccChannelStatus and the current clock time.
      displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
      if (displayOrClearCommands.scc.length > 0) {
        output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
        clock.add(4);
      }
      eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, clock.frameCount);
      if (eventsToProcess.length > 0) {
        let increaseClock = true;
        eventsToProcess.forEach((event, index, events) => {
          let channelIndex = parseInt(event.channel.charAt(event.channel.length - 1)) - 1;

          //check to see if there is an event in the buffer for this channel already. And if so, return.
          if (event.style === "Pop-On" && ccChannelStatus[channelIndex].buffer) {
            return;
          } else {
            increaseClock = false;
          }

          //console.log("writing to channel: ", event.channel, "("+channelIndex+") at time: ", tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption));
          output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t";
          event.encodedTextString.split(" ").forEach((cmd, index, cmds) => {
            output += cmd + " ";
            clock.add(1);

            /* Do we want to interrupt encoding an Event to show or hide what's on screen? */
            displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
            if (displayOrClearCommands.scc.length > 0) {
              output += displayOrClearCommands.scc.join(" ") + " " + cmds[0] + " ";
              clock.add(displayOrClearCommands.scc.length + 2);
            }
          });
          if (event.style === "Pop-On" && event.startFrame <= clock.frameCount) {
            output += sccFunc.getCodeByCmd(sccLookup[event.channel], "{DISPLAY BUFFER}") + " " + sccFunc.getCodeByCmd(sccLookup[event.channel], "{CLEAR BUFFER}") + " ";
            clock.add(4);
            //console.log("Adding Event to Screen");
            ccChannelStatus[channelIndex].screen = event;
          } else if (event.style === "Pop-On") {
            //console.log("adding event to buffer");
            ccChannelStatus[channelIndex].buffer = event;
          } else {
            ccChannelStatus[channelIndex].screen = event;
          }
          events[index].processed = true;
        });
        if (increaseClock) {
          clock.add(1);
        }
      } else {
        //Insert XDS Data if possible:
        if (vChipCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + vChipCmds.split(" ").length + 2);
          //console.log(eventsToProcess.length);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            //console.log("EMBEDDING VCHIP INFO");
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + vChipCmds;
            clock.add(vChipCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        if (contentAdvisoryCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + contentAdvisoryCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + contentAdvisoryCmds;
            clock.add(contentAdvisoryCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        if (programNameCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programNameCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + programNameCmds;
            clock.add(programNameCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        if (programLengthCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programLengthCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + programLengthCmds;
            clock.add(programLengthCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        clock.add(1);
      }

      /* Figure out if we need to close the loop */
      let eventsToStillProcess = sccFunc.getNumberOfEventsToProcess(eventGroupChannelMappings);
      if (eventsToStillProcess === 0) {
        if (sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
          processing = true;
        } else {
          processing = false;
        }
      }
    }
    if (encodingOptions["Line Endings"]) {
      if (encodingOptions["Line Endings"].toLowerCase() === "windows") {
        output = eol.crlf(output);
      } else if (encodingOptions["Line Endings"].toLowerCase() === "macintosh") {
        output = eol.cr(output);
      }
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup, options) {
      eventGroup.events.forEach((event, index, events) => {
        /* console.log("---------");
        console.log("BEFORE:");
        console.log(event.text);  */
        if (!sccFunc.verifyFormatting(event, options.window)) {
          /* Debug */
          //console.log("--------------");
          //console.log(event.text);
          events[index].text = autoFormatSimple(event.text);
          events[index].xPos = "center";
          events[index].yPos = "end";
          events[index].xOffset = options.window.xOffset;
          events[index].yOffset = options.window.yOffset;
        }
        sccLookup.specialCharsFilter.forEach(specialChar => {
          let regexPattern = new RegExp(`\\` + specialChar, "g");
          events[index].text = event.text.replace(regexPattern, sccLookup.specialCharacterReplacement[specialChar] + specialChar);
        });
        events[index].text = sccFunc.duplicateMusicNotes(events[index].text);

        /* console.log("AFTER:");
        console.log(events[index].text);  */
      });
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.toLowerCase().replace(/ +/g, " ").trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      /* 
          We decoded the SCC using the 32x15 grid but now we need to center that grid over the window. There is a fixed offset of 30% (x) and 10% (y). This is due to the caption area being 70% of the frames width and 90% of the frames height. 
      */
      /* if (options.jobInfo.target_profile !== "scenerist" && options.jobInfo.target_profile !== "closedCaptionProject") {
          eventGroup = convertToPopOn(eventGroup, 2, 32);
      } */

      if (eventGroup.events.length > 0 && eventGroup.events[0].style === "Pop-On" && stripTags(eventGroup.events[0].text).trim() == "") {
        eventGroup.events.shift();
      }
      eventGroup.events.forEach((event, index, events) => {
        // console.log("---------");
        // console.log("BEFORE:");
        // console.log(event.text);        
        sccLookup.specialCharsFilter.forEach(specialChar => {
          let regexPattern = new RegExp(`.(?<!>)\\` + specialChar, "g");
          events[index].text = event.text.replace(regexPattern, specialChar);
        });
        events[index].text = sccFunc.replaceMusicNotes(events[index].text);

        // console.log("AFTER:");
        // console.log(events[index].text); 
      });
      //console.log(JSON.stringify(eventGroup, null, 4));
      return eventGroup;
    }
  }
};