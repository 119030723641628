import _escapeHtmlEntities from "../events/escapeHtmlEntities.js";
import { decode as _decodeHtml } from "html-entities";
import _convertToHtml from "../quill/convertToHtml.js";
import _convertToPlainText from "../quill/convertToPlainText.js";
import _flexbox from "../../dict/flexbox.js";
import _Event from "../../classes/event.js";
import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const flexbox = _flexbox;
const convertToPlainText = _convertToPlainText;
const convertToHtml = _convertToHtml;
const decodeHtml = _decodeHtml;
const escapeHtmlEntities = _escapeHtmlEntities;
export default {
  frameRateMap: {
    "23.976": "24",
    "24": "24",
    "25": "25",
    "29.97": "30",
    "30": "30",
    "50": "50",
    "59.94": "60",
    "60": "60"
  },
  frameRateMultiplierMap: {
    "23.976": "999 1000",
    "24": "1 1",
    "25": "1 1",
    "29.97": "999 1000",
    "30": "1 1",
    "50": "1 1",
    "59.94": "999 1000",
    "60": "1 1"
  },
  decodeCaptionBlock: function (captionBlock, frameRate, dropFrame, tcSource) {
    let offset = false,
      events = [];
    if (captionBlock['@_begin']) {
      offset = captionBlock['@_begin'];
    } else {
      offset = false;
    }
    if (Array.isArray(captionBlock.p)) {
      captionBlock.p.forEach(captionEvent => {
        events.push(this.decodeCaptionEvent(captionEvent, frameRate, dropFrame, offset, tcSource));
      });
    } else {
      let captionEvent = captionBlock.p;
      events.push(this.decodeCaptionEvent(captionEvent, frameRate, dropFrame, offset, tcSource));
    }
    return events;
  },
  decodeCaptionEvent: function (captionEvent, frameRate, dropFrame, offset, tcSource) {
    //console.log(captionEvent);
    let start = tcLib.createTc(captionEvent["@_begin"], frameRate, dropFrame);
    let end = tcLib.createTc(captionEvent["@_end"], frameRate, dropFrame);
    if (offset) {
      if (offset.charAt(0) == "-") {
        start.subtract(offset.substring(1));
        end.subtract(offset.substring(1));
      } else {
        start.add(offset);
        end.add(offset);
      }
    }

    //console.log(start.toString(),end.toString());
    let text = this.decodeCaptionText(captionEvent["#text"]);
    let region = flexbox.alignmentMap[captionEvent["@_region"]] || "end";
    return new Event({
      start: tcSource === "media" ? tcLib.tcToSec(start.toString(), frameRate) : tcLib.tcClockToSec(start.toString(), frameRate),
      end: tcSource === "media" ? tcLib.tcToSec(end.toString(), frameRate) : tcLib.tcClockToSec(end.toString(), frameRate),
      yPos: region,
      text: text
    });
  },
  decodeCaptionText: function (text) {
    if (!text) {
      return "";
    }
    text = convertToPlainText(text.replace(/<br\s*\/?>/g, "\n")).replace(/ +/g, " ").replace(/\n +/g, "\n");
    return convertToHtml(decodeHtml(text));
  },
  convertToItt: function (html) {
    return escapeHtmlEntities(html).replace(/(<em><u>)+/gmi, "<span style='italic'>").replace(/(<\/em><\/u>)+/gmi, "</span>").replace(/(<em>)+/gmi, "<span style='italic'>").replace(/(<\/em>)+/gmi, "</span>").replace(/(<u>)+/gmi, "<span style='underline'>").replace(/(<\/u>)+/gmi, "</span>").replace(/(<\/span>)+/g, "</span>");
  }
};