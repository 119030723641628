<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    editorState
} from "@app/store/editorStore.js";
import insertEv from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';

function insertEvent() {
    try {
        if (!$eventGroupState[$projectState.selected]){
            return;
        }
        
        let eventOptions;
        let eventIndex = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0] + 1 : null;
        
        if ($eventGroupState[$projectState.selected].events.length > 0){
            try {
                let eventTemplate = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].events.length-1];

                eventOptions = {
                    ...eventOptions,
                    alignment : eventTemplate.alignment, 
                    xPos : eventTemplate.xPos,
                    yPos : eventTemplate.yPos,
                    xOffset : eventTemplate.xOffset,
                    yOffset : eventTemplate.yOffset,
                    vertical : eventTemplate.vertical,
                    voice : eventTemplate.voice,
                    speakingStyle : eventTemplate.speakingStyle,
                    style : eventTemplate.style,
                    rate : eventTemplate.rate,
                    start: $editorState.insertStartOnInsert ? player.currentTime : false,
                }     
            } catch(err){
                console.log(err);
            }
        }
        
        $eventGroupState[$projectState.selected] = insertEv($eventGroupState[$projectState.selected], eventOptions, eventIndex);

        $eventGroupState[$projectState.selected].selected = eventIndex !== null ? [eventIndex] : [$eventGroupState[$projectState.selected].events.length - 1];

        /* Store in History */
        historyState.insert({
            name : "insert event", //action name
            eventGroup : $projectState.selected,
            snapshots : [
                {
                    store : "eventGroupState",
                    value : JSON.stringify($eventGroupState)
                }
            ]
        });
    } catch(err){
        console.log(err, err.message);
    }
}
</script>
<li class="nav-item" title="Insert Event">
    <a class="nav-link" href="#!/" on:click={insertEvent}><i class="bi bi-plus-circle"></i></a>
</li>
