<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { fade } from 'svelte/transition';
let selected = 0, hide = localStorage.getItem('cc-hide-v24') === "true" ? true : false;
const slides = [
    {
        title: "Release 1.8.24",        
        description: "2024-06-14",
        lead : "",
        cards : [
            {
                title : "Paste Settings",
                sub : "Choose how text is formated when pasted",
                description : "When pasting text from external programs, Closed Caption Creator can now automatically format the text to match the current line settings. This feature can be enabled or disabled in the Options menu.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/pasteOptions.jpg'
            },   
            {
                title : "New Timeline",
                sub : "Better performance and new features",
                description : "The new Timeline component is now available with new features including snap to markers, and overlap protection. The Timeline has also been optimized for better performance when working with large projects.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/timeline.jpg'
            },                    
            {
                title : "Multi-Langauge Exports",
                sub : "Export multiple languages in a single file",
                description : "Pro and Enterprise users can now take advantage of the new Scenerist and MacCaption Advanced profiles. The profiles contain options to embed multiple languages on CC1-4 and services 1-6 in a single file.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/multilanguage.jpg'
            },    
        ]
    }
];

function hideModal(){
    if (hide){
        localStorage.setItem('cc-hide-v24', 'true');
    } else {
        localStorage.setItem('cc-hide-v24', 'false');
    }
    
    modalState.showModal('welcome');    
}
</script>

<div class="modal fade {$modalState === 'tips' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TipsModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">What's New</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={() => hideModal()}></button>
            </div>
            <div class="modal-body">
                <p class="text-secondary m-0">{slides[selected].description}</p>
                <h4>{slides[selected].title}</h4>
                
                <p class="lead">{slides[selected].lead}</p>
                <div class="container">
                    <div class="row">
                        {#each slides[selected].cards as card}
                        <div class="col-4 mb-4" transition:fade="{{delay: 250, duration: 300}}">
                            <div class="card text-dark bg-light shadow border-0">
                                {#if card.image}
                                    <img src="{card.image}" class="card-img-top" alt='Help Top'>
                                {/if}
                                <div class="card-body">
                                    <h4 class="fw-bold card-title">{card.title}</h4>
                                    <h6 class="fw-bold card-subtitle mb-2">{card.sub}</h6>
                                    <p class="card-text">{card.description}</p>
                                    {#if card.link}
                                        <button class="btn btn-outline-primary w-100" type="button" on:click={()=>{isNaN(card.buttonLink) ? modalState.showModal(card.buttonLink) : selected = card.buttonLink}}>{card.buttonText}</button>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="p-3 border-top border-light">
                <div class="float-start">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="hideCheck" bind:checked={hide}>
                        <label class="form-check-label" for="hideCheck">Hide on startup</label>
                    </div>
                </div>                
                    <div class="float-end">                          
                        <button type="button" class="btn btn-primary me-2" on:click="{() => hideModal()}">Close</button>
                        <a class="btn btn-outline-dark" href="https://www.closedcaptioncreator.com/releases.html" target="_blank" rel="noreferrer">Learn More</a>
                    </div>                
            </div>
        </div>
    </div>
</div>
