export default {
  source: {
    "af": "Afrikaans",
    "sq": "Albanian",
    "am": "Amharic",
    "ar": "Arabic",
    "hy": "Armenian",
    "as": "Assamese",
    "az": "Azerbaijani (Latin)",
    "bn": "Bangla",
    "ba": "Bashkir",
    "eu": "Basque",
    "bho": "Bhojpuri",
    "brx": "Bodo",
    "bs": "Bosnian (Latin)",
    "bg": "Bulgarian",
    "yue": "Cantonese (Traditional)",
    "ca": "Catalan",
    "lzh": "Chinese (Literary)",
    "zh-Hans": "Chinese Simplified",
    "zh-Hant": "Chinese Traditional",
    "sn": "chiShona",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "prs": "Dari",
    "dv": "Divehi",
    "doi": "Dogri",
    "nl": "Dutch",
    "en": "English",
    "et": "Estonian",
    "fo": "Faroese",
    "fj": "Fijian",
    "fil": "Filipino",
    "fi": "Finnish",
    "fr": "French",
    "fr-ca": "French (Canada)",
    "gl": "Galician",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gu": "Gujarati",
    "ht": "Haitian Creole",
    "ha": "Hausa",
    "he": "Hebrew",
    "hi": "Hindi",
    "mww": "Hmong Daw (Latin)",
    "hu": "Hungarian",
    "is": "Icelandic",
    "ig": "Igbo",
    "id": "Indonesian",
    "ikt": "Inuinnaqtun",
    "iu": "Inuktitut",
    "iu-Latn": "Inuktitut (Latin)",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "kn": "Kannada",
    "ks": "Kashmiri",
    "kk": "Kazakh",
    "km": "Khmer",
    "rw": "Kinyarwanda",
    "tlh-Latn": "Klingon",
    "tlh-Piqd": "Klingon (plqaD)",
    "gom": "Konkani",
    "ko": "Korean",
    "ku": "Kurdish (Central)",
    "kmr": "Kurdish (Northern)",
    "ky": "Kyrgyz (Cyrillic)",
    "lo": "Lao",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "ln": "Lingala",
    "dsb": "Lower Sorbian",
    "lug": "Luganda",
    "mk": "Macedonian",
    "mai": "Maithili",
    "mg": "Malagasy",
    "ms": "Malay (Latin)",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mi": "Maori",
    "mr": "Marathi",
    "mn-Cyrl": "Mongolian (Cyrillic)",
    "mn-Mong": "Mongolian (Traditional)",
    "my": "Myanmar",
    "ne": "Nepali",
    "nb": "Norwegian",
    "nya": "Nyanja",
    "or": "Odia",
    "ps": "Pashto",
    "fa": "Persian",
    "pl": "Polish",
    "pt": "Portuguese (Brazil)",
    "pt-pt": "Portuguese (Portugal)",
    "pa": "Punjabi",
    "otq": "Queretaro Otomi",
    "ro": "Romanian",
    "run": "Rundi",
    "ru": "Russian",
    "sm": "Samoan (Latin)",
    "sr-Cyrl": "Serbian (Cyrillic)",
    "sr-Latn": "Serbian (Latin)",
    "st": "Sesotho",
    "nso": "Sesotho sa Leboa",
    "tn": "Setswana",
    "sd": "Sindhi",
    "si": "Sinhala",
    "sk": "Slovak",
    "sl": "Slovenian",
    "so": "Somali (Arabic)",
    "es": "Spanish",
    "sw": "Swahili (Latin)",
    "sv": "Swedish",
    "ty": "Tahitian",
    "ta": "Tamil",
    "tt": "Tatar (Latin)",
    "te": "Telugu",
    "th": "Thai",
    "bo": "Tibetan",
    "ti": "Tigrinya",
    "to": "Tongan",
    "tr": "Turkish",
    "tk": "Turkmen (Latin)",
    "uk": "Ukrainian",
    "hsb": "Upper Sorbian",
    "ur": "Urdu",
    "ug": "Uyghur (Arabic)",
    "uz": "Uzbek (Latin)",
    "vi": "Vietnamese",
    "cy": "Welsh",
    "xh": "Xhosa",
    "yo": "Yoruba",
    "yua": "Yucatec Maya",
    "zu": "Zulu"
  },
  target: {
    "af": "Afrikaans",
    "sq": "Albanian",
    "am": "Amharic",
    "ar": "Arabic",
    "hy": "Armenian",
    "as": "Assamese",
    "az": "Azerbaijani (Latin)",
    "bn": "Bangla",
    "ba": "Bashkir",
    "eu": "Basque",
    "bho": "Bhojpuri",
    "brx": "Bodo",
    "bs": "Bosnian (Latin)",
    "bg": "Bulgarian",
    "yue": "Cantonese (Traditional)",
    "ca": "Catalan",
    "lzh": "Chinese (Literary)",
    "zh-Hans": "Chinese Simplified",
    "zh-Hant": "Chinese Traditional",
    "sn": "chiShona",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "prs": "Dari",
    "dv": "Divehi",
    "doi": "Dogri",
    "nl": "Dutch",
    "en": "English",
    "et": "Estonian",
    "fo": "Faroese",
    "fj": "Fijian",
    "fil": "Filipino",
    "fi": "Finnish",
    "fr": "French",
    "fr-ca": "French (Canada)",
    "gl": "Galician",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gu": "Gujarati",
    "ht": "Haitian Creole",
    "ha": "Hausa",
    "he": "Hebrew",
    "hi": "Hindi",
    "mww": "Hmong Daw (Latin)",
    "hu": "Hungarian",
    "is": "Icelandic",
    "ig": "Igbo",
    "id": "Indonesian",
    "ikt": "Inuinnaqtun",
    "iu": "Inuktitut",
    "iu-Latn": "Inuktitut (Latin)",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "kn": "Kannada",
    "ks": "Kashmiri",
    "kk": "Kazakh",
    "km": "Khmer",
    "rw": "Kinyarwanda",
    "tlh-Latn": "Klingon",
    "tlh-Piqd": "Klingon (plqaD)",
    "gom": "Konkani",
    "ko": "Korean",
    "ku": "Kurdish (Central)",
    "kmr": "Kurdish (Northern)",
    "ky": "Kyrgyz (Cyrillic)",
    "lo": "Lao",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "ln": "Lingala",
    "dsb": "Lower Sorbian",
    "lug": "Luganda",
    "mk": "Macedonian",
    "mai": "Maithili",
    "mg": "Malagasy",
    "ms": "Malay (Latin)",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mi": "Maori",
    "mr": "Marathi",
    "mn-Cyrl": "Mongolian (Cyrillic)",
    "mn-Mong": "Mongolian (Traditional)",
    "my": "Myanmar",
    "ne": "Nepali",
    "nb": "Norwegian",
    "nya": "Nyanja",
    "or": "Odia",
    "ps": "Pashto",
    "fa": "Persian",
    "pl": "Polish",
    "pt": "Portuguese (Brazil)",
    "pt-pt": "Portuguese (Portugal)",
    "pa": "Punjabi",
    "otq": "Queretaro Otomi",
    "ro": "Romanian",
    "run": "Rundi",
    "ru": "Russian",
    "sm": "Samoan (Latin)",
    "sr-Cyrl": "Serbian (Cyrillic)",
    "sr-Latn": "Serbian (Latin)",
    "st": "Sesotho",
    "nso": "Sesotho sa Leboa",
    "tn": "Setswana",
    "sd": "Sindhi",
    "si": "Sinhala",
    "sk": "Slovak",
    "sl": "Slovenian",
    "so": "Somali (Arabic)",
    "es": "Spanish",
    "sw": "Swahili (Latin)",
    "sv": "Swedish",
    "ty": "Tahitian",
    "ta": "Tamil",
    "tt": "Tatar (Latin)",
    "te": "Telugu",
    "th": "Thai",
    "bo": "Tibetan",
    "ti": "Tigrinya",
    "to": "Tongan",
    "tr": "Turkish",
    "tk": "Turkmen (Latin)",
    "uk": "Ukrainian",
    "hsb": "Upper Sorbian",
    "ur": "Urdu",
    "ug": "Uyghur (Arabic)",
    "uz": "Uzbek (Latin)",
    "vi": "Vietnamese",
    "cy": "Welsh",
    "xh": "Xhosa",
    "yo": "Yoruba",
    "yua": "Yucatec Maya",
    "zu": "Zulu"
  }
};