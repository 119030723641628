<script>
    import { modalState } from "@app/store/modalStore.js";
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { fade } from "svelte/transition";
    import { projectState } from "@app/store/projectStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { onMount } from "svelte";
    import axios from "axios";
    import Swal from "sweetalert2";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";

    /* CC LIB */
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Project from "@app/external/cc-lib/dist/classes/project.js";
    import decode from "@app/external/cc-lib/dist/functions/decode.js";
    import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
    import loadMedia from "@app/external/cc-lib/dist/functions/projects/loadMedia.js";
    import frameRatesDict from "@app/external/cc-lib/dist/dict/frameRates.js";
    import encodeHtmlEntities from "@app/external/cc-lib/dist/functions/quill/encodeHtmlEntities.js";
    import _Marker from "@app/external/cc-lib/dist/classes/marker.js";
    import { v4 as uuidv4 } from "uuid";
    import randomHexColor from 'random-hex-color';

    let progress = 0,
        statusMsg = "Starting import...",
        description,
        manifestFileExists = false,
        audioExtractionComplete = true;

    async function updateStatus(statusInfo) {
        console.log(statusInfo.msg);
        progress = statusInfo.progress;
        statusMsg = statusInfo.msg;
    }

    async function getFileContents(url) {
        let fileRes = await axios.get(url);
        return fileRes.data;
    }

    function resetProjectState() {
        $projectState.selected = false;
        $eventGroupState = [];
        $speakerState = [];
        $markerState = {
            selected: 0,
            lists: [
                {
                    id: "0",
                    name: "Shot Changes",
                    color: "#E74C3C",
                    markers: [],
                },
            ],
        };
        $metadataState = [];
        playerState.updateDuration(false);
        $uiState.timeline = false;
    }

    onMount(async () => {
        try {
            let frameRate = 24,
                dropFrame = false,
                incode = 0,
                projectName;

            await updateStatus({
                progress: 10,
                msg: "Starting work order import... Please wait.",
            });

            /* Reset Project */
            resetProjectState();

            await updateStatus({
                progress: 15,
                msg: "Parsing work order description",
            });

            console.info(
                "DEBUG: Parsing URL params from URL: " + window.location.href);
            let urlString = window.location.href;
            let url = new URL(urlString);
            description = JSON.parse(url.searchParams.get("description"));
            console.info("DEBUG: DESCRIPTION: " + JSON.stringify(description));

            await updateStatus({
                progress: 15,
                msg: "Loading project info...",
            });

            projectName = description.projectName || "Untitled";

            if (
                description.frameRate &&
                frameRatesDict.frameRates.indexOf(
                    parseFloat(description.frameRate),
                ) > -1
            ) {
                frameRate = parseFloat(description.frameRate);
                dropFrame = frameRatesDict.dropFrameMap[frameRate];
            }

            if (description.incode) {
                incode = tcLib.tcToSec(
                    description.incode,
                    frameRate,
                    $projectState.dropFrame,
                );
            }

            await updateStatus({
                progress: 30,
                msg: "Creating project...",
            });

            $projectState = new _Project({
                name: projectName,
                frameRate: frameRate,
                dropFrame: dropFrame,
                incode: incode,
                media: {},
                eventGroups: [],
                username: firebase.auth().currentUser.email,
                ownerId: firebase.auth().currentUser.uid,
                selected: false,
            });

            await updateStatus({
                progress: 30,
                msg: "Importing tracks...",
            });

            /* Import Captions */
            let playerWidth =
                document.getElementById("PlayerWrapper").clientWidth;
            let playerHeight =
                document.getElementById("PlayerWrapper").clientHeight;

            if (description.tracks && description.tracks.length > 0) {
                for (let i = 0; i<description.tracks.length; i++){
                    let track = description.tracks[i];
                    await updateStatus({
                        progress: 30,
                        msg: `Importing track ${i + 1}`,
                    });

                    $eventGroupState = [
                        ...$eventGroupState,
                        new _EventGroup({
                            type: track.type,
                            name: track.displayName,
                            language: track.language,
                            rtl: track.rtl,
                            maxChars: track.maxChars,
                            maxCps: track.maxCps,
                            maxWpm: track.maxWpm,
                            maxLines: track.maxLines,
                            overlap: track.overlap,
                            illegalChars: track.illegalChars,
                        }),
                    ];

                    track.id = $eventGroupState[$eventGroupState.length - 1].id;

                    let options = new defaults.options({
                        profile: track.importOptions.profile || "subRip",
                        incode: description.incode,
                        formatOptions: track.importOptions.options || [],
                        frameRate: track.importOptions.frameRate || frameRate,
                        dropFrame:
                            frameRatesDict.dropFrameMap[
                                track.importOptions.frameRate || frameRate
                            ],
                        window: {
                            width: playerWidth,
                            height: playerHeight,
                            xOffset: ($styleState.xPadding / 100) * playerWidth,
                            yOffset:
                                ($styleState.yPadding / 100) * playerHeight,
                        },
                    });

                    let fileContents = await getFileContents(
                        track.importOptions.url,
                    );

                    let decodedEvGroup = await decode(fileContents, options);
                    decodedEvGroup.events = decodedEvGroup.events.map(
                        (event) => {
                            event.text = encodeHtmlEntities(event.text);
                            return event;
                        },
                    );

                    if (decodedEvGroup.events.every(ev => ev.style === "Pop-On")) {
                        decodedEvGroup.events = decodedEvGroup.events.filter((event) => {
                            return event.text.replace(/(<([^>]+)>)/gi, "") || event.notes || event.reply;
                        });
                    }
                    
                    $eventGroupState[$eventGroupState.length - 1].events = decodedEvGroup.events;
                }

                $projectState.selected = 0;
            }

            /* Import Media */
            await updateStatus({
                progress: 75,
                msg: "Loading media...",
            });

            if (description.mediaUrl) {
                $projectState = loadMedia($projectState, {
                    projectName: $projectState.name,
                    storage: "Cloud Storage",
                    mediaUrl: description.mediaUrl,
                    incode: 0,
                    frameRate: $projectState.frameRate,
                    dropFrame: $projectState.dropFrame,
                    aspectRatio: "16:9",
                });

                let source = {};

                switch ($projectState.media.storage) {
                    case "Vimeo":
                        source.src = $projectState.media.path;
                        source.provider = "vimeo";
                        player.source = {
                            type: "video",
                            sources: [source],
                        };

                        audioExtractionComplete = true;
                        manifestFileExists = true;
                        break;
                    case "YouTube":
                        source.src = $projectState.media.path;
                        source.provider = "youtube";
                        player.source = {
                            type: "video",
                            sources: [source],
                        };

                        audioExtractionComplete = true;
                        manifestFileExists = true;
                        break;
                    case "HLS Manifest":
                        audioExtractionComplete = true;
                        loadHlsStream($projectState.media.path);
                        break;
                    default:
                        /* Cloud Storage*/
                        source.src = $projectState.media.path;
                        source.type = "video/mp4";

                        //Get file size of url
                        let fileSize = await getFileSize(
                            $projectState.media.path,
                        );

                        if (fileSize) {
                            const sizeInBytes = parseInt(fileSize);
                            const sizeInKilobytes = sizeInBytes / 1024;
                            const sizeInMegabytes = sizeInKilobytes / 1024;
                            if (sizeInMegabytes < 512) {
                                $projectState.media.useFallback = false;
                            }
                        }

                        audioExtractionComplete = true;
                        manifestFileExists = true;

                        player.source = {
                            type: "video",
                            sources: [source],
                        };

                        break;                    
                }
            }

            $externalDataState = {
                platform: "Work Order",
                projectName: projectName,
                frameRate: frameRate,
                dropFrame: dropFrame,
                mediaUrl: description.mediaUrl,
                thumbnailUrl: description.thumbnailUrl,
                transcripts: description.resources ? description.resources.filter(r => r.type === "transcript") : [],
                rawDescription: JSON.stringify(description),
            };

            await updateStatus({
                progress: 100,
                msg: "Done",
            });

            if (description.importAlertSuccess) {
                await Swal.fire(description.importAlertSuccess);
            }

            toast.push(`Work import completed successfully`, {
                classes: ["toast-success"],
            });
            
            closeModal();
        } catch (err) {
            console.info(err, err.message);
            if (description && description.importAlertError) {
                await Swal.fire(description.importAlertError);
            }

            toast.push(`External Work Order Import Failed. ${err.message}`, {
                classes: ["toast-danger"],
            });

            manifestFileExists = true;
            closeModal();
        } 
    });

    async function getFileSize() {
        try {
            const response = await fetch($projectState.media.path, {
                method: "HEAD",
            });
            const fileSize = response.headers.get("content-length");
            return fileSize;
        } catch (err){
            return false;
        }    
    }

    function loadHlsStream(mediaPath) {
        const hls = new Hls();
        const videoTag = document.querySelector("video");

        hls.loadSource(mediaPath);
        hls.attachMedia(videoTag);

        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            console.log("video and hls.js are now bound together !");
        });

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            console.log(
                "manifest loaded, found " +
                    data.levels.length +
                    " quality level",
            );

            manifestFileExists = true;
            closeModal();
        });
    }

    async function parseResources(resources){
        for (let i = 0; i < resources.length; i++){
            let resource = resources[i];
            if (resource.type === "waveformInfo"){
                $projectState.media.peaksPath = resource.url;
                $projectState.media.useFallback = false;
            } else if (resource.type === "markers"){
                try {
                    let markerListRes = await fetch(resource.url);
                    let markerList = await markerListRes.json();
                    if (resource.listName === "Shot Changes"){
                        $markerState.selected = 0;
                    } else {
                        console.log("Creating new marker list");
                        $markerState.lists = [...$markerState.lists, {
                            id: uuidv4(),
                            name: resource.listName || "Unknown",
                            color: resource.color || "#DDDDDD",
                            markers: []
                        }];

                        $markerState.selected = $markerState.lists.length-1;
                    }

                    markerList.forEach(marker => {
                        let time = marker.time;
                        if (resource.tc_format && resource.tc_format === "smpte"){
                            time = tcLib.tcToSec(marker.time, $projectState.frameRate, $projectState.dropFrame);                            
                        } else if (resource.tc_format && resource.tc_format === "frames"){
                            time = tcLib.framesToSec(marker.time, $projectState.frameRate, $projectState.dropFrame);
                        } else if (resource.tc_format && resource.tc_format === "tcMs"){
                            time = tcLib.tcMsToSec(marker.time);
                        } else if (resource.tc_format && resource.tc_format === "seconds"){
                            time = marker.time;
                        } else {
                            time = tcLib.parseTcToSec(marker.time, $projectState.frameRate, $projectState.dropFrame);
                        }

                        $markerState.lists[$markerState.selected].markers.push(
                            new _Marker({
                                time : time,
                                comment : marker.comment || ""
                            })
                        );
                        
                        $markerState.lists[$markerState.selected].markers.forEach((m, i, ms) =>{
                            ms[i].comment = m.comment.toString();
                        });
                    });

                } catch(err){
                    console.error("Error parsing markers", err);
                }
            } else if (resource.type === "speakers"){
                try {
                    let speakerRes = await fetch(resource.url);
                    let speakers = await speakerRes.json();
                    speakers.forEach(speaker => {
                        $speakerState = [...$speakerState, {
                            id: uuidv4(),
                            name: speaker,
                            colour: randomHexColor(),
                        }];
                    });
                } catch(err){
                    console.error("Error parsing metadata", err);
                }
            }
        }
    }

    async function closeModal() {
        console.log(
            "Close Modal Called",
            audioExtractionComplete,
            manifestFileExists,
        );
        if (audioExtractionComplete && manifestFileExists) {
            try {
                if (description && description.resources) {
                    await parseResources(description.resources);
                }
            } catch (err) {
                console.error("Error parsing resources", err);
            } 
            
            statusMsg = "Project import complete...";
            progress = 100;

            setTimeout(() => {
                $uiState.timeline = true;
                modalState.hideModal();
            }, 2500);
        }
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'workOrderImport' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="LoadWorkOrderModal"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Work Order Import</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                />
            </div>
            <div class="modal-body">
                <p><i class="bi bi-exclamation-diamond-fill" /> {statusMsg}</p>
                <div class="progress">
                    <div
                        class="progress-bar bg-primary progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style="width: {progress}%;"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        {progress}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
