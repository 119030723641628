<script>
import {
    authState
} from "@app/store/authStore.js";
import {
    modalState
} from "@app/store/modalStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    styleState
} from "@app/store/styleStore.js";
import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
import removeTextlessEvents from "@app/external/cc-lib/dist/functions/eventGroups/removeTextlessEvents.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    fade
} from "svelte/transition";
import {
    BarLoader
} from "svelte-loading-spinners";
import {
    v4 as uuidv4
} from "uuid";
/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import storage from '@app/configs/storage.js';
import "firebase/functions";
/* Language Settings */
import googleLanguages from "@app/external/cc-lib/dist/providers/googleTranslate/languages.js";
import deepLLanguages from "@app/external/cc-lib/dist/providers/deepL/languages.js";
import modernMtLanguages from "@app/external/cc-lib/dist/providers/modernMt/languages.js";
import openAiLanguages from "@app/external/cc-lib/dist/providers/openAi/languages.js";
import geminiProLanguages from "@app/external/cc-lib/dist/providers/gemini/languages.js";
import anthropicLanguages from "@app/external/cc-lib/dist/providers/anthropic/languages.js";
import azureLanguages from "@app/external/cc-lib/dist/providers/azure/languages.js";
import amazonLanguages from "@app/external/cc-lib/dist/providers/amazon/languages.js";

let translationDefaults = JSON.parse(localStorage.getItem("cc-translation-defaults")) || {};
let provider = translationDefaults.provider || "Modern MT",
    displayName = $eventGroupState[$projectState.selected].name + " (Translation)",
    srcLanguage = translationDefaults.srcLanguage || "en",
    trgLanguage = translationDefaults.trgLanguage || "fr",
    rtl = $eventGroupState[$projectState.selected].rtl ? true : false,
    formatting = translationDefaults.formatting ?? true,
    waiting = false,
    statusMsg = "",
    progress = 0,
    eventGroupIndex = $projectState.selected,
    teamId = $authState.team ? $authState.team.id : null,
    teamOwner = $authState.team ? $authState.team.owner : null,
    teamOwnerId = $authState.team ? $authState.team.ownerId : null,
    userId = firebase.auth().currentUser.uid,
    userEmail = firebase.auth().currentUser.email,
    homeRef = $authState.team ? db.collection("teams").doc(teamId).collection("jobs") : db.collection("users").doc(userId).collection("jobs"),
    storageRef = storage.ref(),
    uploadBasePath = $authState.team ? "teams/" + teamId + "/eventGroups/" : "users/" + userId + "/eventGroups/";

let jobInfo = {
    id: uuidv4(),
    teamId: teamId,
    owner: teamOwner,
    ownerId: teamOwnerId,
    projectId: $projectState.id,
    projectName: $projectState.name,
    submittedBy: userEmail,
    userId: userId,
    progress: 50,
    chars: 0, //characters
    cost: 0,
    type: "translation",
    statusMsg: "Queued",
    status: "Submitted",
    createdOn: null,
    updatedOn: null,
    completedOn: null,
    deleted: false,
    config: {}
};

async function translate() {
    try {
        waiting = true;
        console.log("Preparing translation job...");
        statusMsg = "Preparing translation job...";
        progress = 5;
        toast.push("Starting translation... please wait.", {
            classes: ["toast-info"]
        });

        localStorage.setItem(
            "cc-translation-defaults",
            JSON.stringify({
                provider: provider,
                srcLanguage: srcLanguage,
                trgLanguage: trgLanguage,
                formatting: formatting
            })
        );

        //Set srcLanguageDisplay and trgLanguageDisplay based on the selected service provider and source/target languages
        let srcLanguageDisplay = "",
            trgLanguageDisplay = "";

        if (provider === "Google Translate") {
            srcLanguageDisplay = googleLanguages.source[srcLanguage];
            trgLanguageDisplay = googleLanguages.target[trgLanguage];
        } else if (provider === "Modern MT") {
            srcLanguageDisplay = modernMtLanguages.source[srcLanguage];
            trgLanguageDisplay = modernMtLanguages.target[trgLanguage];
        } else if (provider === "OpenAi") {
            srcLanguageDisplay = openAiLanguages.source[srcLanguage];
            trgLanguageDisplay = openAiLanguages.target[trgLanguage];
        } else if (provider === "Gemini Pro"){
            srcLanguageDisplay = geminiProLanguages.source[srcLanguage];
            trgLanguageDisplay = geminiProLanguages.target[trgLanguage];
        } else if (provider === "Azure AI Translator"){
            srcLanguageDisplay = azureLanguages.source[srcLanguage];
            trgLanguageDisplay = azureLanguages.target[trgLanguage];
        } else if (provider === "Amazon Translate"){
            srcLanguageDisplay = amazonLanguages.source[srcLanguage];
            trgLanguageDisplay = amazonLanguages.target[trgLanguage];
        } else if (provider === "Anthropic"){
            srcLanguageDisplay = anthropicLanguages.source[srcLanguage];
            trgLanguageDisplay = anthropicLanguages.target[trgLanguage];
        } else {
            srcLanguageDisplay = deepLLanguages.source[srcLanguage];
            trgLanguageDisplay = deepLLanguages.target[trgLanguage];
        }

        //Create a copy of the source Event Group and remove any empty events:
        let copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));
        copyOfEventGroup = removeTextlessEvents(copyOfEventGroup);
        /* Upload Event Group File*/
        console.log("Uploading source Event Group for translation");
        statusMsg = "Uploading source Event Group for translation";
        progress = 15;
        await storageRef.child(uploadBasePath + jobInfo.id + ".json").putString(JSON.stringify(copyOfEventGroup));
        /* Register Translation Job */
        console.log("Registering translation job");
        statusMsg = "Registering translation job";
        progress = 30;
        jobInfo = {
            ...jobInfo,
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            config : {
                displayName : displayName,
                linkedGroup : $eventGroupState[eventGroupIndex].id,
                provider: provider,
                srcLanguage: srcLanguage,
                trgLanguage: trgLanguage,
                srcLanguageDisplay: srcLanguageDisplay,
                trgLanguageDisplay: trgLanguageDisplay,
                originalFontFamily: $styleState.fontFamily,
                rtl : rtl,
                formatting : formatting
            }
        }

        console.log(JSON.stringify(jobInfo, null, 4));
        await homeRef.doc(jobInfo.id).set(jobInfo); //Register Job

        /* Trigger Translation Job */
        statusMsg = "Starting translation job";
        progress = 50;
        await firebase.functions().httpsCallable("v8TranslateProviderV5")(jobInfo.id); //Trigger job to run

        toast.push("Translation started successfully. Please view Translation Import dashboard for more information. (AI Tools -> Translation Import)", {
            classes: ["toast-info"]
        });

        modalState.showModal('aiTranslationImport');
    } catch (err) {
        console.log(err);
        errorHandler(err.message);
    }        
}

function errorHandler(msg) {
    console.log(msg);
    waiting = false;

    toast.push("Failed to start translation job. Please try again later.", {
        classes: ["toast-danger"]
    });

    modalState.showModal('aiTranslationImport');
}

function updateRtlOption() {
    if (["ar", "arc", "ckb", "dv", "fa", "ha", "he", "khw", "ks", "ps", "sd", "ur", "uz-AF", "yi"].indexOf(trgLanguage) > -1) {
        rtl = true;
    } else {
        rtl = false;
    }
}

function resetLanguages() {
    srcLanguage = "en";
    trgLanguage = "fr";
}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'automaticTranslation' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AutoTranslateModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Automatic Translation</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault={translate}>
                    <div class="mb-3">
                        <label class="form-label" for="Service provider">Service Provider</label>
                        <select class="form-select" bind:value={provider} on:change={resetLanguages} disabled={waiting}>
                            <option>Amazon Translate</option>
                            <option>Anthropic</option>
                            <option>Azure AI Translator</option>
                            <option>DeepL</option>
                            <option>Gemini Pro</option>
                            <option>Google Translate</option> 
                            <option>Modern MT</option>
                            <option>OpenAi</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="Linked group">Source Group</label>
                        <select class="form-select" bind:value={eventGroupIndex} disabled={waiting}>
                            {#each $eventGroupState as eventGroup, index (index)}
                                <option value={index} selected={index === $projectState.selected}>{eventGroup.name}</option>
                            {/each}
                        </select>
                    </div>
                    {#if provider === "Google Translate"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(googleLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(googleLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else if provider === "Anthropic"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(anthropicLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(anthropicLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else if provider === "Azure AI Translator"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(azureLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(azureLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else if provider === "Amazon Translate"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(amazonLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(amazonLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else if provider === "Modern MT"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(modernMtLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(modernMtLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else if provider === "Gemini Pro"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(geminiProLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(geminiProLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else if provider === "OpenAi"}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(openAiLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(openAiLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {:else}
                    <!-- Source Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Source Language">Source Language</label>
                        <select class="form-select" bind:value={srcLanguage} disabled={waiting}>
                            {#each Object.entries(deepLLanguages.source) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- Target Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Target Language">Target Language</label>
                        <select class="form-select" bind:value={trgLanguage} on:change={updateRtlOption} disabled={waiting}>
                            {#each Object.entries(deepLLanguages.target) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {/if}

                    <hr />
                    <p class="text-muted small">
                        <i class="bi bi-info-circle" /> A new event group will be created for the translation.<br /> The following settings will be applied to the new group.
                    </p>
                    <div class="mb-3">
                        <label class="form-label" for="display name">Display Name</label>
                        <input class="form-control" required placeholder="Display Name" type="text" bind:value={displayName} disabled={waiting} />
                    </div>
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="rtlSwitchTranslation" bind:checked={rtl} disabled={waiting} />
                        <label class="form-check-label" for="rtlSwitchTranslation">Right-To-Left</label>
                    </div>
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="formattingSwitch" bind:checked={formatting} disabled={waiting} />
                        <label class="form-check-label" for="formattingSwitch">Include Formatting</label>
                    </div>
                </form>
                <button class="btn btn-primary float-end" type="button" disabled={waiting || !srcLanguage || !trgLanguage} on:click={translate}>Submit Job</button>
                {#if waiting}
                <div class="mt-3">
                    <BarLoader size="150" color="#1eb4b2" unit="px" duration="3s" />
                    <p class="small text-muted">
                        {statusMsg} ({progress}%)
                    </p>
                </div>
                {/if}
            </div>
        </div>
    </div>
</div>
