<script>
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { styleState } from "@app/store/styleStore.js";
    import { authState } from "@app/store/authStore.js";
    import { fade } from "svelte/transition";
    import { saveAs } from "file-saver";
    import Swal from "sweetalert2";
    import throttle from "just-throttle";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import "firebase/compat/functions";

    /* CC LIB */
    import encode from "@app/external/cc-lib/dist/functions/encode.js";
    import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
    import encodings from "@app/external/cc-lib/dist/dict/encodings.js";
    import formats from "@app/external/cc-lib/dist/lib/formats.js";
    import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import offset from "@app/external/cc-lib/dist/functions/events/tcOffset.js";
    import tcMultiply from "@app/external/cc-lib/dist/functions/special/tcMultiply.js";
    import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
    import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";
    import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
    import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
    import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
    import reverseRtlStartEnd from "@app/external/cc-lib/dist/functions/utility/reverseRtlStartEnd.js";
    import wrapStyledTextWithSpan from "@app/external/cc-lib/dist/functions/quill/wrapStyledTextWithSpan.js";
    import contentAdvisoryInformation from "@app/external/cc-lib/dist/dict/contentAdvisoryInformation.js";
    import vChipRatings from "@app/external/cc-lib/dist/dict/vChipRatings.js";
    let subtitleExportDefaults =
        JSON.parse(localStorage.getItem("cc-subtitle-export-defaults")) || {};

    let eventGroup = getSelectedEventGroup([
            "subtitle",
            "translation",
            "audio description",
            "transcription",
        ]),
        trgExt = subtitleExportDefaults.trgExt || "srt",
        trgProfile =
            subtitleExportDefaults.trgProfile || "SubRip Video Subtitle Script",
        trgFrameRate = $projectState.frameRate,
        trgDropFrame = $projectState.dropFrame ? "true" : "false",
        dropFrameConvert = false,
        eventListElement = document.getElementById("EventList"),
        tcOffset =
            tcLib.secToTc(
                $projectState.incode,
                $projectState.frameRate,
                $projectState.dropFrame,
            ) || "00:00:00:00",
        tcOffsetType = "add",
        tcMultiplier = 1,
        showOptions = false,
        fileEncoding = defaults.encoding,
        fns = "include",
        reverseRtlStartEndOption = false,
        ignoreAll = false,
        cc1EncodeTarget,
        cc2EncodeTarget = "None",
        cc3EncodeTarget = "None",
        cc4EncodeTarget = "None",
        programAEncodeTarget,
        programBEncodeTarget = "None",
        programCEncodeTarget = "None",
        programDEncodeTarget = "None",
        programEEncodeTarget = "None",
        programFEncodeTarget = "None",
        showProgramInfoOptions = false,
        show608Options = true,
        show708Options = true,
        enableProgramInfo = false,
        programLengthHours = 0,
        programLengthMinutes = 0,
        programName = "",
        basicKeywordGroup = "education",
        showVChipOptions = false,
        enableVChipInfo = false,
        detailKeywordGroup = [],
        vChipType = "TV Parental Guideline",
        vChipRating = "N/A",
        vChipContent = [];

    if ($authState.status === "in_trial") {
        showTrialWarning();
    }

    function showTrialWarning() {
        Swal.fire({
            titleText: "Trial Mode",
            text: "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Activate Account",
            denyButtonText: "Continue Trial",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-primary me-2",
                denyButton: "btn btn-outline-secondary",
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    activateSubscription();
                    return true;
                } else {
                    return false;
                }
            })
            .then((res) => {
                if (res) {
                    console.log(res);
                    showRestartNotification();
                }

                return true;
            })
            .catch((err) => {
                console.log(err);
                console.log(err.message);
            });
    }

    function showRestartNotification() {
        Swal.fire({
            titleText: "Restart Required",
            text: "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-light",
            },
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                console.log(err.message);
            });
    }

    function getSelectedEventGroup(validTypes) {
        let evGroup = $eventGroupState[$projectState.selected];
        if (evGroup) {
            if (validTypes.indexOf(evGroup.type) > -1) {
                return evGroup.id;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function validateTc(e) {
        tcOffset = tcLib.tcValidate(
            e.target.value,
            parseFloat(trgFrameRate),
            trgDropFrame === "true" ? true : false,
        );
    }

    function updateProfile() {
        trgProfile = formats.trgProfiles[trgExt][0];
        trgFrameRate = $projectState.frameRate;
        trgDropFrame = $projectState.dropFrame ? "true" : "false";
    }

    async function cancelExport(eventGroupId, eventId) {
        toast.push("Subtitle export was aborted", {
            classes: ["toast-warning"],
        });

        $projectState.selected = eventGroupId;
        $eventGroupState[$projectState.selected].selected = [eventId];
        setTimeout(() => {
            try {
                eventListElement.scrollTo(0, eventId * 230);
            } catch (err) {
                eventListElement = document.getElementById("EventList");
                eventListElement.scrollTo(0, eventId * 230);
            }
        }, 250);

        modalState.hideModal();
    }

    function alertUser(msg) {
        let response = Swal.fire({
            titleText: "Export Pre-Checks",
            text: msg,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Abort",
            denyButtonText: "Ignore",
            cancelButtonText: "Ignore All",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger",
                denyButton: "btn btn-light mx-2",
                cancelButton: "btn btn-outline-secondary",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                return true;
            } else if (result.isDenied) {
                return false;
            } else if (result.isDismissed) {
                ignoreAll = true;
                return false;
            }
        });

        return response;
    }

    function alertUserBoolean(msg) {
        let response = Swal.fire({
            titleText: "Export Pre-Checks",
            text: msg,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger",
                denyButton: "btn btn-light mx-2",
                cancelButton: "btn btn-outline-secondary",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                return true;
            } else {
                return false;
            }
        });

        return response;
    }

    async function preExportCheck(events) {
        if ($projectState.media.info && $projectState.media.info.incode) {
            console.log($projectState.media.info.incode);
            let mediaIncode = $projectState.media.info
                ? $projectState.media.info.incode
                : tcOffset || tcOffset;
            if (tcOffset !== mediaIncode) {
                if (
                    await alertUserBoolean(
                        `Timecode offset (${tcOffset}) does not match the incode of the media (${mediaIncode}).\n Would you like to update the timecode offset to match the video incode?`,
                    )
                ) {
                    tcOffset = mediaIncode;
                    tcOffsetType = "add";
                }
            }
        }

        for (let i = 0; i < events.length; i++) {
            if (!ignoreAll) {
                if (events[i].text) {
                    if (isNaN(events[i].start)) {
                        if (
                            await alertUser(
                                `Event ${i + 1} is missing a start time.\n Would you like to ABORT your export?`,
                            )
                        ) {
                            return {
                                err: true,
                                eventId: i,
                            };
                        }
                    } else if (isNaN(events[i].end)) {
                        if (
                            await alertUser(
                                `Event ${i + 1} is missing an end time.\n Would you like to ABORT your export?`,
                            )
                        ) {
                            return {
                                err: true,
                                eventId: i,
                            };
                        }
                    } else if (events[i].start === events[i].end) {
                        if (
                            await alertUser(
                                `Event ${i + 1} has the same start and end time.\n Would you like to ABORT your export?`,
                            )
                        ) {
                            return {
                                err: true,
                                eventId: i,
                            };
                        }
                    } else if (events[i].start > events[i].end) {
                        if (
                            await alertUser(
                                `Event ${i + 1} has a start time greater than its end time.\n Would you like to ABORT your export?`,
                            )
                        ) {
                            return {
                                err: true,
                                eventId: i,
                            };
                        }
                    } else if (i > 0 && events[i].start < events[i - 1].end) {
                        /* Adding check in case overlap is less than a frame */
                        if (
                            events[i - 1].end - events[i].start <
                            1 / $projectState.frameRate
                        ) {
                            events[i].start = events[i - 1].end;
                        } else if (
                            await alertUser(
                                `Event ${i + 1} overlaps with the previous event.\n Would you like to ABORT your export?`,
                            )
                        ) {
                            return {
                                err: true,
                                eventId: i,
                            };
                        }
                    }
                } else {
                    if (
                        await alertUser(
                            `Event ${i + 1} has no text.\n Would you like to ABORT your export?`,
                        )
                    ) {
                        return {
                            err: true,
                            eventId: i,
                        };
                    }
                }
            }
        }

        return {
            err: false,
        };
    }

    async function exportSubtitles() {
        toast.push("Encoding events to format...", { classes: ["toast-info"] });
        try {
            let options,
                output,
                defaultEncoding =
                    formats.defaultEncodingMap[
                        formats.profileMapping[trgProfile].name
                    ],
                eventGroupIndex,
                fileName,
                fileBlob,
                copyOfEventGroup;

            trgFrameRate = parseFloat(trgFrameRate);
            trgDropFrame = trgDropFrame === "true" ? true : false;

            $eventGroupState = $eventGroupState;
            eventGroupIndex = $eventGroupState.findIndex((group) => {
                return group.id === eventGroup;
            });

            fileName = $eventGroupState[eventGroupIndex].name + "." + trgExt;
            copyOfEventGroup = JSON.parse(
                JSON.stringify($eventGroupState[eventGroupIndex]),
            );

            /* FS Filtering START */
            if (fns === "exclude") {
                copyOfEventGroup.events = copyOfEventGroup.events.filter(
                    (event) => {
                        return !event.forced;
                    },
                );
            } else if (fns === "only") {
                copyOfEventGroup.events = copyOfEventGroup.events.filter(
                    (event) => {
                        return event.forced;
                    },
                );
            }
            /* FS Filtering END */

            /* RTL Formatting */
            if (reverseRtlStartEndOption) {
                copyOfEventGroup.events.forEach((event, index, events) => {
                    let plainText = convertToPlainText(event.text);
                    let correctedText = reverseRtlStartEnd(plainText);
                    events[index].text = convertToHtml(correctedText);
                });
            }
            /* RTL Formatting END */

            copyOfEventGroup = orderByTime(copyOfEventGroup);
            copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);
            let preCheckResult = await preExportCheck(copyOfEventGroup.events);
            if (preCheckResult.err) {
                cancelExport(eventGroupIndex, preCheckResult.eventId);
            } else {
                /* tcOffset */
                if (tcOffset && tcOffset !== "00:00:00:00") {
                    copyOfEventGroup.events.forEach((event, index, events) => {
                        events[index] = offset(
                            event,
                            tcLib.tcToSec(tcOffset, trgFrameRate, trgDropFrame),
                            tcOffsetType,
                        );
                    });
                }

                /* Frame Rate Convert */
                if (dropFrameConvert) {
                    copyOfEventGroup = dropFrameConvert(eventGroup, {
                        frameRate: trgFrameRate,
                        dropFrame: trgDropFrame,
                    });
                }

                if (tcMultiplier && tcMultiplier != 0 && tcMultiplier != 1) {
                    copyOfEventGroup = tcMultiply(
                        copyOfEventGroup,
                        tcMultiplier,
                    );
                }

                /* Translate for Creator */
                let playerWidth =
                    document.getElementById("PlayerWrapper").clientWidth;
                let playerHeight =
                    document.getElementById("PlayerWrapper").clientHeight;
                let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
                let yPadding = $styleState.mode ? 10 : $styleState.yPadding;

                options = new defaults.options({
                    profile: formats.profileMapping[trgProfile].name,
                    formatOptions:
                        formats.profileMapping[trgProfile].options.encode,
                    frameRate: trgFrameRate,
                    dropFrame: trgDropFrame,
                    target_profile: formats.profileMapping[trgProfile].name,
                    source_profile: "closedCaptionProject",
                    window: {
                        width: playerWidth,
                        height: playerHeight,
                        xOffset: (xPadding / 100) * playerWidth,
                        yOffset: (yPadding / 100) * playerHeight,
                    },
                });

                if ($authState.status === "in_trial") {
                    copyOfEventGroup = replaceTrialText(copyOfEventGroup);
                }

                copyOfEventGroup.events.forEach((event, index, events) => {
                    events[index].text = wrapStyledTextWithSpan(event.text);
                });

                output = await encode(copyOfEventGroup, options);

                if (defaultEncoding === "buffer") {
                    if (trgExt === "xlsx") {
                        fileBlob = new Blob([output], {
                            type: "application/octet-stream",
                        });
                    } else {
                        fileBlob = output;
                    }
                } else if (defaultEncoding.toLowerCase() === "hex") {
                    let byteArray = new Uint8Array(
                        output.match(/.{2}/g).map((e) => parseInt(e, 16)),
                    );
                    fileBlob = new Blob([byteArray], {
                        type: "application/octet-stream",
                    });
                } else if (defaultEncoding.toLowerCase() === "utf-16le") {
                    fileBlob = new Blob([output], {
                        type: "text/plain;charset=utf-16le",
                    });
                } else {
                    fileBlob = new Blob([output], {
                        type: "text/plain;charset=" + fileEncoding,
                    });
                }

                saveAs(fileBlob, fileName, {
                    autoBom: true,
                });

                toast.push("Subtitle export finished successfully", {
                    classes: ["toast-success"],
                });

                localStorage.setItem(
                    "cc-subtitle-export-defaults",
                    JSON.stringify({
                        trgExt: trgExt,
                        trgProfile: trgProfile,
                        tcOffset: tcOffset,
                        tcOffsetType: tcOffsetType,
                    }),
                );

                modalState.hideModal();
            }
        } catch (e) {
            console.log(e, e.message);
            toast.push("Subtitle export failed: " + e.message, {
                classes: ["toast-danger"],
            });
        }
    }

    async function advancedSubtitleExport() {
        toast.push("Encoding events to format...", { classes: ["toast-info"] });
        try {
            toast.push("Subtitle export finished successfully", {
                classes: ["toast-success"],
            });

            let options,
                output,
                fileName,
                fileBlob,
                copyOfEventGroups = [],
                eventGroupChannelMappings = {},
                defaultEncoding =
                    formats.defaultEncodingMap[
                        formats.profileMapping[trgProfile].name
                    ];

            trgFrameRate = parseFloat(trgFrameRate);
            trgDropFrame = trgDropFrame === "true" ? true : false;

            let cc1EventGroup = $eventGroupState.find(
                (group) => group.id === cc1EncodeTarget,
            );
            let cc2EventGroup = $eventGroupState.find(
                (group) => group.id === cc2EncodeTarget,
            );
            let cc3EventGroup = $eventGroupState.find(
                (group) => group.id === cc3EncodeTarget,
            );
            let cc4EventGroup = $eventGroupState.find(
                (group) => group.id === cc4EncodeTarget,
            );
            let programAEventGroup = $eventGroupState.find(
                (group) => group.id === programAEncodeTarget,
            );
            let programBEventGroup = $eventGroupState.find(
                (group) => group.id === programBEncodeTarget,
            );
            let programCEventGroup = $eventGroupState.find(
                (group) => group.id === programCEncodeTarget,
            );
            let programDEventGroup = $eventGroupState.find(
                (group) => group.id === programDEncodeTarget,
            );
            let programEEventGroup = $eventGroupState.find(
                (group) => group.id === programEEncodeTarget,
            );
            let programFEventGroup = $eventGroupState.find(
                (group) => group.id === programFEncodeTarget,
            );

            fileName =
                (
                    cc1EventGroup ??
                    cc2EventGroup ??
                    cc3EventGroup ??
                    cc4EventGroup ??
                    programAEventGroup ??
                    programBEventGroup ??
                    programBEventGroup ??
                    programCEventGroup ??
                    programDEventGroup ??
                    programEEventGroup ??
                    programFEventGroup
                ).name +
                "." +
                trgExt;

            if (cc1EventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(cc1EventGroup)),
                );
                eventGroupChannelMappings["cc1"] = copyOfEventGroups.length - 1;
            }

            if (cc2EventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(cc2EventGroup)),
                );
                eventGroupChannelMappings["cc2"] = copyOfEventGroups.length - 1;
            }

            if (cc3EventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(cc3EventGroup)),
                );
                eventGroupChannelMappings["cc3"] = copyOfEventGroups.length - 1;
            }

            if (cc4EventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(cc4EventGroup)),
                );
                eventGroupChannelMappings["cc4"] = copyOfEventGroups.length - 1;
            }

            if (programAEventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(programAEventGroup)),
                );
                eventGroupChannelMappings["programA"] =
                    copyOfEventGroups.length - 1;
            }

            if (programBEventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(programBEventGroup)),
                );
                eventGroupChannelMappings["programB"] =
                    copyOfEventGroups.length - 1;
            }

            if (programCEventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(programCEventGroup)),
                );
                eventGroupChannelMappings["programC"] =
                    copyOfEventGroups.length - 1;
            }

            if (programDEventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(programDEventGroup)),
                );
                eventGroupChannelMappings["programD"] =
                    copyOfEventGroups.length - 1;
            }

            if (programEEventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(programEEventGroup)),
                );
                eventGroupChannelMappings["programE"] =
                    copyOfEventGroups.length - 1;
            }

            if (programFEventGroup) {
                copyOfEventGroups.push(
                    JSON.parse(JSON.stringify(programFEventGroup)),
                );
                eventGroupChannelMappings["programF"] =
                    copyOfEventGroups.length - 1;
            }

            copyOfEventGroups.forEach(async (evg, evgIndex, evgs) => {
                let eventGroupIndex = $eventGroupState.findIndex((group) => {
                    return group.id === evg.id;
                });

                /* FS Filtering START */
                if (fns === "exclude") {
                    evg.events = evg.events.filter((event) => {
                        return !event.forced;
                    });
                } else if (fns === "only") {
                    evg.events = evg.events.filter((event) => {
                        return event.forced;
                    });
                }
                /* FS Filtering END */

                /* RTL Formatting */
                if (reverseRtlStartEndOption) {
                    evg.events.forEach((event, index, events) => {
                        let plainText = convertToPlainText(event.text);
                        let correctedText = reverseRtlStartEnd(plainText);
                        events[index].text = convertToHtml(correctedText);
                    });
                }
                /* RTL Formatting END */

                evgs[evgIndex] = orderByTime(evg);
                evgs[evgIndex] = removeHtmlEntities(evg);
                let preCheckResult = await preExportCheck(evg.events);
                if (preCheckResult.err) {
                    cancelExport(eventGroupIndex, preCheckResult.eventId);
                } else {
                    /* tcOffset */
                    if (tcOffset && tcOffset !== "00:00:00:00") {
                        evg.events.forEach((event, index, events) => {
                            events[index] = offset(
                                event,
                                tcLib.tcToSec(
                                    tcOffset,
                                    trgFrameRate,
                                    trgDropFrame,
                                ),
                                tcOffsetType,
                            );
                        });
                    }

                    /* Frame Rate Convert */
                    if (dropFrameConvert) {
                        evg = dropFrameConvert(eventGroup, {
                            frameRate: trgFrameRate,
                            dropFrame: trgDropFrame,
                        });
                    }

                    if (
                        tcMultiplier &&
                        tcMultiplier != 0 &&
                        tcMultiplier != 1
                    ) {
                        evg = tcMultiply(evg, tcMultiplier);
                    }

                    if ($authState.status === "in_trial") {
                        evg = replaceTrialText(evg);
                    }

                    evg.events.forEach((event, index, events) => {
                        events[index].text = wrapStyledTextWithSpan(event.text);
                    });

                    evgs[evgIndex] = evg;
                }
            });

            /* Translate for Creator */
            let playerWidth =
                document.getElementById("PlayerWrapper").clientWidth;
            let playerHeight =
                document.getElementById("PlayerWrapper").clientHeight;
            let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
            let yPadding = $styleState.mode ? 10 : $styleState.yPadding;

            options = new defaults.options({
                profile: formats.profileMapping[trgProfile].name,
                formatOptions: [
                    ...formats.profileMapping[trgProfile].options.encode,
                    {
                        name: "Event Group Channel Mappings",
                        selected: eventGroupChannelMappings,
                    },
                    {
                        name: "Program Information",
                        selected: {
                            enable: enableProgramInfo,
                            programName: programName,
                            programLengthHours: programLengthHours,
                            programLengthMinutes: programLengthMinutes,
                            basicKeywordGroup: basicKeywordGroup,
                            detailKeywordGroup: detailKeywordGroup,
                        },
                    },
                    {
                        name: "V-Chip Information",
                        selected: {
                            enable: enableVChipInfo,
                            type: vChipType,
                            rating: vChipRating,
                            content: vChipContent,
                        },
                    },
                ],
                frameRate: trgFrameRate,
                dropFrame: trgDropFrame,
                target_profile: formats.profileMapping[trgProfile].name,
                source_profile: "closedCaptionProject",
                window: {
                    width: playerWidth,
                    height: playerHeight,
                    xOffset: (xPadding / 100) * playerWidth,
                    yOffset: (yPadding / 100) * playerHeight,
                },
            });

            output = await encode(copyOfEventGroups, options);
            if (defaultEncoding === "buffer") {
                if (trgExt === "xlsx") {
                    fileBlob = new Blob([output], {
                        type: "application/octet-stream",
                    });
                } else {
                    fileBlob = output;
                }
            } else if (defaultEncoding.toLowerCase() === "hex") {
                let byteArray = new Uint8Array(
                    output.match(/.{2}/g).map((e) => parseInt(e, 16)),
                );
                fileBlob = new Blob([byteArray], {
                    type: "application/octet-stream",
                });
            } else if (defaultEncoding.toLowerCase() === "utf-16le") {
                fileBlob = new Blob([output], {
                    type: "text/plain;charset=utf-16le",
                });
            } else {
                fileBlob = new Blob([output], {
                    type: "text/plain;charset=" + fileEncoding,
                });
            }

            saveAs(fileBlob, fileName, {
                autoBom: true,
            });

            toast.push("Subtitle export finished successfully", {
                classes: ["toast-success"],
            });

            localStorage.setItem(
                "cc-subtitle-export-defaults",
                JSON.stringify({
                    trgExt: trgExt,
                    trgProfile: trgProfile,
                    tcOffset: tcOffset,
                    tcOffsetType: tcOffsetType,
                }),
            );
        } catch (err) {
            toast.push("Subtitle export failed: " + err.message, {
                classes: ["toast-danger"],
            });

            console.log(err);
            console.log(err.message);
        } finally {
            modalState.hideModal();
        }
    }

    const activateSubscription = throttle(
        async () => {
            console.log("Activating subscription");
            let res = await firebase
                .functions()
                .httpsCallable("v8ActivateSubscription")($authState.subId);
            console.log("subscription activation run:", res);
        },
        10000,
        { leading: true },
    );
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'subtitleExport' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="SubtitleExportModal"
>
    <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Subtitle Export</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p class="small text-muted">
                    <i class="bi bi-lightning-fill"></i> Powered by Closed Caption
                    Converter | www.closedcaptionconverter.com
                </p>
                <form
                    on:submit|preventDefault={() =>
                        trgProfile === "Scenarist V1.0 Advanced" ||
                        trgProfile === "MacCaption 608/708 Advanced"
                            ? advancedSubtitleExport()
                            : exportSubtitles()}
                >
                    <div
                        class="mb-3 {trgProfile === 'Scenarist V1.0 Advanced' ||
                        trgProfile === 'MacCaption 608/708 Advanced'
                            ? 'd-none'
                            : ''}"
                    >
                        <label class="form-label" for="EventGroupSelection"
                            >Event Group</label
                        >
                        <select class="form-select" bind:value={eventGroup}>
                            {#each $eventGroupState as eventGroupOption}
                                <option value={eventGroupOption.id}
                                    >{eventGroupOption.name}</option
                                >
                            {/each}
                        </select>
                    </div>
                    <div class="row">
                        <div class="mb-3 col">
                            <label class="form-label" for="targetFileExtension"
                                >File Extension</label
                            >
                            <select
                                class="form-select"
                                bind:value={trgExt}
                                on:change={updateProfile}
                            >
                                {#each formats.trgExt.filter((trgExt) => {
                                    if ($authState.plan === "pro" || $authState.plan === "enterprise") {
                                        return true;
                                    } else if (["scc", "mcc", "stl"].indexOf(trgExt.toLowerCase()) > -1) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }) as ext}
                                    <option>{ext}</option>
                                {/each}
                            </select>
                        </div>
                        <div class="mb-3 col">
                            <label
                                class="form-label"
                                for="exampleFormControlSelect1">Profile</label
                            >
                            <select class="form-select" bind:value={trgProfile}>
                                {#each formats.trgProfiles[trgExt] as profile}
                                    <option>{profile}</option>
                                {/each}
                            </select>
                        </div>
                    </div>
                    <!-- 708 Options -->
                    {#if trgProfile === "MacCaption 608/708 Advanced"}
                        <button
                            type="button"
                            class="btn btn-outline-warning mb-2 dropdown-toggle w-100"
                            on:click={() => {
                                show708Options = !show708Options;
                            }}>708 Options</button
                        >
                        <div class="row">
                            {#if show708Options}
                                <!-- Program A -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label"
                                        for="programAEncodeTarget"
                                        >Program A</label
                                    >
                                    <select
                                        class="form-select {programAEncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={programAEncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <!-- Program B -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label"
                                        for="programBEncodeTarget"
                                        >Program B</label
                                    >
                                    <select
                                        class="form-select {programBEncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={programBEncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <!-- Program C -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label"
                                        for="programCEncodeTarget"
                                        >Program C</label
                                    >
                                    <select
                                        class="form-select {programCEncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={programCEncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <!-- Program D -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label"
                                        for="programDEncodeTarget"
                                        >Program D</label
                                    >
                                    <select
                                        class="form-select {programDEncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={programDEncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <!-- Program E -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label"
                                        for="programEEncodeTarget"
                                        >Program E</label
                                    >
                                    <select
                                        class="form-select {programEEncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={programEEncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <!-- Program F -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label"
                                        for="programFEncodeTarget"
                                        >Program F</label
                                    >
                                    <select
                                        class="form-select {programFEncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={programFEncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                            {/if}
                        </div>
                    {/if}
                    <!-- 608 Options -->
                    {#if trgProfile === "Scenarist V1.0 Advanced" || trgProfile === "MacCaption 608/708 Advanced"}
                        <button
                            type="button"
                            class="btn btn-outline-warning mb-2 dropdown-toggle w-100"
                            on:click={() => {
                                show608Options = !show608Options;
                            }}>608 Options</button
                        >
                        <div class="row">
                            {#if show608Options}
                                <div class="mb-3 col">
                                    <label
                                        class="form-label"
                                        for="cc1EncodeTarget">CC1</label
                                    >
                                    <select
                                        class="form-select {cc1EncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={cc1EncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <div class="mb-3 col">
                                    <label
                                        class="form-label"
                                        for="cc2EncodeTarget">CC2</label
                                    >
                                    <select
                                        class="form-select {cc2EncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={cc2EncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <div class="mb-3 col">
                                    <label
                                        class="form-label"
                                        for="cc3EncodeTarget">CC3</label
                                    >
                                    <select
                                        class="form-select {cc3EncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={cc3EncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                                <div class="mb-3 col">
                                    <label
                                        class="form-label"
                                        for="cc4EncodeTarget">CC4</label
                                    >
                                    <select
                                        class="form-select {cc4EncodeTarget ===
                                        'None'
                                            ? 'bg-light'
                                            : ''}"
                                        bind:value={cc4EncodeTarget}
                                    >
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value={eventGroupOption.id}
                                                >{eventGroupOption.name}</option
                                            >
                                        {/each}
                                        <option>None</option>
                                    </select>
                                </div>
                            {/if}
                        </div>
                        <!-- Content Advisory DATA -->
                        <button
                            type="button"
                            class="btn btn-outline-warning mb-2 dropdown-toggle w-100"
                            on:click={() => {
                                showProgramInfoOptions =
                                    !showProgramInfoOptions;
                            }}>Program Information</button
                        >
                        <div class="row">
                            {#if showProgramInfoOptions}
                                <div class="mb-3 col-12">
                                    <div class="form-check form-switch">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="enableContentAdvisoryInfoSwitch"
                                            bind:checked={enableProgramInfo}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="enableContentAdvisoryInfoSwitch"
                                            >Include Program Information</label
                                        >
                                    </div>
                                </div>
                                {#if enableProgramInfo}
                                    <div class="mb-3 col-6">
                                        <label
                                            class="form-label"
                                            for="programNameTextField"
                                            >Program Name</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="programNameTextField"
                                            bind:value={programName}
                                        />
                                    </div>
                                    <div class="col-6">
                                        <label
                                            class="form-label"
                                            for="programLengthInput"
                                            >Program Length</label
                                        >
                                        <div class="input-group mb-3">
                                            <input
                                                bind:value={programLengthHours}
                                                type="number"
                                                class="form-control"
                                                placeholder="Hours (hh)"
                                                min="00"
                                                max="23"
                                                step="1"
                                                aria-label="Hours"
                                            />
                                            <span class="input-group-text"
                                                >:</span
                                            >
                                            <input
                                                bind:value={programLengthMinutes}
                                                type="number"
                                                class="form-control"
                                                placeholder="Minutes (MM)"
                                                min="00"
                                                max="59"
                                                step="1"
                                                aria-label="Minutes"
                                            />
                                        </div>
                                    </div>
                                    <p class="text-warning lead m-0">
                                        Basic Keyword Group
                                    </p>
                                    <div class="col-12 mb-3">
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="educationCheckbox"
                                                value="education"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="educationCheckbox"
                                                >Education</label
                                            >
                                        </div>
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="entertainmentCheckbox"
                                                value="entertainment"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="entertainmentCheckbox"
                                                >Entertainment</label
                                            >
                                        </div>
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="movieCheckbox"
                                                value="movie"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="movieCheckbox">Movie</label
                                            >
                                        </div>
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="newsCheckbox"
                                                value="news"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="newsCheckbox">News</label
                                            >
                                        </div>
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="religionCheckbox"
                                                value="religion"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="religionCheckbox"
                                                >Religion</label
                                            >
                                        </div>
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="sportsCheckbox"
                                                value="sports"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="sportsCheckbox"
                                                >Sports</label
                                            >
                                        </div>
                                        <div
                                            class="form-check form-check-inline"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                id="otherCheckbox"
                                                value="other"
                                                bind:group={basicKeywordGroup}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="otherCheckbox">Other</label
                                            >
                                        </div>
                                    </div>
                                    <!-- Detail Keyword Group -->
                                    <p class="text-warning lead m-0">
                                        Detail Keyword Group <button
                                            type="button"
                                            class="btn btn-outline-dark btn-sm {detailKeywordGroup.length >
                                            0
                                                ? ''
                                                : 'd-none'}"
                                            style="font-size: 0.7vw;"
                                            on:click={() =>
                                                (detailKeywordGroup = [])}
                                            >Clear All</button
                                        >
                                    </p>
                                    <div class="row mb-3">
                                        {#each contentAdvisoryInformation.detail as keyword}
                                            <div class="col-3">
                                                <div
                                                    class="form-check form-check-inline"
                                                >
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="{keyword}Checkbox"
                                                        value={keyword}
                                                        bind:group={detailKeywordGroup}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="{keyword}Checkbox"
                                                        >{keyword}</label
                                                    >
                                                </div>
                                            </div>
                                        {/each}
                                    </div>
                                {/if}
                            {/if}
                        </div>
                        <!-- Vchip Information-->
                        <button
                            type="button"
                            class="btn btn-outline-warning mb-2 dropdown-toggle w-100"
                            on:click={() => {
                                showVChipOptions = !showVChipOptions;
                            }}>V-Chip Information</button
                        >
                        <div class="row">
                            {#if showVChipOptions}
                                <div class="mb-3 col-12">
                                    <div class="form-check form-switch">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="enableVChipInfoSwitch"
                                            bind:checked={enableVChipInfo}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="enableVChipInfoSwitch"
                                            >Include V-Chip Information</label
                                        >
                                    </div>
                                </div>
                                {#if enableVChipInfo}
                                    <div class="mb-3 col-12">
                                        <label
                                            class="form-label"
                                            for="vchipRatingType">Type</label
                                        >
                                        <select
                                            class="form-select"
                                            bind:value={vChipType}
                                        >
                                            <option
                                                >TV Parental Guideline</option
                                            >
                                            <option>MPAA</option>
                                            <option>Canadian English</option>
                                            <option>Canadian French</option>
                                        </select>
                                    </div>
                                    <div class="mb-3 col-12">
                                        <label
                                            class="form-label"
                                            for="vchipRating">Rating</label
                                        >
                                        <select
                                            class="form-select"
                                            bind:value={vChipRating}
                                        >
                                            {#each vChipRatings.ratings[vChipType] as rating}
                                                <option>{rating}</option>
                                            {/each}
                                        </select>
                                    </div>
                                    {#if vChipType === "TV Parental Guideline"}
                                        <div class="col-12">
                                            <p class="mb-1">
                                                Content Description
                                            </p>
                                        </div>

                                        <div class="mb-3 col-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="violence"
                                                    id="violenceContentCheck"
                                                    bind:group={vChipContent}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="violenceContentCheck"
                                                >
                                                    Violence
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="sex"
                                                    id="sexualContentCheck"
                                                    bind:group={vChipContent}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="sexualContentCheck"
                                                >
                                                    Sexual Situations
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="language"
                                                    id="languageContentCheck"
                                                    bind:group={vChipContent}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="languageContentCheck"
                                                >
                                                    Adult Language
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="dialogue"
                                                    id="dialogueContentCheck"
                                                    bind:group={vChipContent}
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="dialogueContentCheck"
                                                >
                                                    Suggestive Dialogue
                                                </label>
                                            </div>
                                        </div>
                                    {/if}
                                {/if}
                            {/if}
                        </div>
                    {/if}
                    <!-- General Options -->
                    <button
                        type="button"
                        class="btn btn-outline-warning mb-2 dropdown-toggle"
                        on:click={() => {
                            showOptions = !showOptions;
                        }}>More Options</button
                    >
                    {#if showOptions}
                        <div
                            id="advancedOptions"
                            class="p-3 bg-secondary shadow rounded"
                        >
                            <!-- Advanced Options -->
                            <p class="lead text-warning">General Options</p>
                            <!-- Frame Rate + Drop Frame -->
                            {#if formats.profileMapping[trgProfile].timecode !== "ms"}
                                <div class="row">
                                    <div class="mb-3 col-8">
                                        <label
                                            class="form-label"
                                            for="TrgFrameRateSelect"
                                            >Framerate</label
                                        >
                                        <select
                                            id="TrgFrameRateSelect"
                                            class="form-select"
                                            bind:value={trgFrameRate}
                                        >
                                            {#each frameRates.frameRates as frameRate}
                                                <option
                                                    selected={frameRate ==
                                                        trgFrameRate}
                                                    >{frameRate}</option
                                                >
                                            {/each}
                                        </select>
                                    </div>
                                    {#if parseFloat(trgFrameRate) === 29.97 || parseFloat(trgFrameRate) === 59.94}
                                        <div class="mb-3 col-4">
                                            <label
                                                class="form-label"
                                                for="trgDropFrameSelect"
                                                >Dropframe</label
                                            >
                                            <select
                                                id="trgDropFrameSelect"
                                                class="form-select"
                                                bind:value={trgDropFrame}
                                            >
                                                <option>true</option>
                                                <option>false</option>
                                            </select>
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                            <!-- Timecode Offset -->
                            <div class="row">
                                <div class="mb-3 col-8">
                                    <label class="form-label" for="tcOffset"
                                        >TC Offset</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        bind:value={tcOffset}
                                        on:blur={validateTc}
                                        on:focus={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                </div>

                                <div class="mb-3 col-4">
                                    <label class="form-label" for="tcOffsetType"
                                        >Offset Type</label
                                    >
                                    <select
                                        class="form-select"
                                        bind:value={tcOffsetType}
                                    >
                                        <option>add</option>
                                        <option>subtract</option>
                                    </select>
                                </div>
                            </div>
                            <!-- Timecode Multiplier -->
                            <div class="row">
                                <div class="mb-3 col-8">
                                    <label class="form-label" for="tcMultiplier"
                                        >TC Multiplier</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        bind:value={tcMultiplier}
                                        on:focus={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                </div>
                            </div>
                            <!-- File Encoding -->
                            <div class="mb-3">
                                <label class="form-label" for="TrgFileEncoding"
                                    >File Encoding</label
                                >
                                <select
                                    class="form-select"
                                    bind:value={fileEncoding}
                                >
                                    {#each encodings as encodingOption}
                                        <option>{encodingOption}</option>
                                    {/each}
                                </select>
                            </div>
                            <!-- Drop Frame Convert -->
                            <div class="mb-3">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="dropFrameConvert"
                                        bind:checked={dropFrameConvert}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="dropFrameConvert"
                                    >
                                        Drop Frame Convert
                                        <i
                                            class="bi bi-info-circle"
                                            title="This option is valid when converting from a format that uses SMPTE timecode (e.g. SCC, MCC, etc.) to an offset timecode format (e.g. SRT, WebVTT, etc.)"
                                        ></i>
                                    </label>
                                </div>
                            </div>
                            <!-- RTL Reverse Start/End -->
                            <div class="mb-3">
                                <p class="text-warning mb-1">RTL Formatting</p>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="reverseRtlStartEndOptionCheck"
                                        bind:checked={reverseRtlStartEndOption}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="reverseRtlStartEndOptionCheck"
                                    >
                                        Reverse RTL Start / End
                                    </label>
                                </div>
                            </div>
                            <!-- Forced Subtitles -->
                            <div class="mb-3">
                                <p class="text-warning mb-1">
                                    Forced Subtitles
                                </p>
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="fnsOption"
                                        id="includeFns"
                                        bind:group={fns}
                                        value={"include"}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="includeFns">Include FS</label
                                    >
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="fnsOption"
                                        id="excludeFns"
                                        bind:group={fns}
                                        value={"exclude"}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="excludeFns">Exclude FS</label
                                    >
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="fnsOption"
                                        id="onlyFns"
                                        bind:group={fns}
                                        value={"only"}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="onlyFns">FS Only</label
                                    >
                                </div>
                            </div>
                            {#if formats.profileMapping[trgProfile].options && formats.profileMapping[trgProfile].options.encode.length > 0}
                                <div class="row border-top border-secondary">
                                    <p class="col-12 lead text-warning pt-2">
                                        Encoding Options
                                    </p>
                                    {#each formats.profileMapping[trgProfile].options.encode as encodeOption}
                                        <div class="mb-3 col-6">
                                            <label
                                                class="form-label"
                                                for="decodingOption"
                                                >{encodeOption.name}</label
                                            >
                                            {#if encodeOption.type === "textarea"}
                                                <textarea
                                                    class="form-control"
                                                    rows="3"
                                                    bind:value={encodeOption.selected}
                                                ></textarea>
                                            {:else if encodeOption.type === "text-input"}
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    bind:value={encodeOption.selected}
                                                />
                                            {:else if encodeOption.type === "number-input"}
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    bind:value={encodeOption.selected}
                                                />
                                            {:else}
                                                <select
                                                    class="form-select"
                                                    bind:value={encodeOption.selected}
                                                >
                                                    {#each encodeOption.values as valueOption}
                                                        <option
                                                            >{valueOption}</option
                                                        >
                                                    {/each}
                                                </select>
                                            {/if}
                                        </div>
                                    {/each}
                                </div>
                            {/if}
                        </div>
                    {/if}
                </form>
            </div>
            <div class="modal-footer">
                <button
                    class="btn btn-primary"
                    type="button"
                    disabled={!eventGroup}
                    on:click={() =>
                        trgProfile === "Scenarist V1.0 Advanced" ||
                        trgProfile === "MacCaption 608/708 Advanced"
                            ? advancedSubtitleExport()
                            : exportSubtitles()}>Export</button
                >
            </div>
        </div>
    </div>
</div>
