<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';
import { clickOutside } from 'svelte-use-click-outside';
import Swal from 'sweetalert2';
import {
    saveAs
} from 'file-saver';
import {
    unparse
} from 'papaparse';
import {
    v4 as uuidv4
} from "uuid";
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import _Marker from "@app/external/cc-lib/dist/classes/marker.js";
import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
import _Event from "@app/external/cc-lib/dist/classes/event.js";

const hexColors = [
    "#ff66cc", "#00cc00", "#0066ff", "#6600ff", "#ff0066",
    "#6666ff", "#00cc99", "#99ccff", "#ff9966", "#ff33cc",
    "#009900", "#ff9933", "#009999", "#333399", "#999966",
    "#99ccff", "#990033", "#996600", "#ffcc99", "#6699ff"
];

let selectedMarker, showMenu = false;

async function createMarkerList() {
    console.log("Creating new marker list");
    let res = await Swal.fire({
        titleText: 'New Marker List',
        showCancelButton: true,
        confirmButtonText: 'Create',
        input: 'text',
        inputLabel: 'List Name',
        inputPlaceholder: 'Enter a list name here',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            cancelButton: 'btn btn-outline-secondary',
        }
    });

    if (res.isConfirmed && res.value) {
        $markerState.lists = [...$markerState.lists, {
            id: uuidv4(),
            name: res.value,
            color: hexColors[Math.floor(Math.random() * 20)],
            markers: []
        }]

        $markerState.selected = $markerState.lists.length - 1;
    }
}

async function removeMarkerList() {
    if ($markerState.selected === 0) {
        //Shot Change List
        return;
    }

    let res = await Swal.fire({
        titleText: 'Remove Marker List',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        text: 'Are you sure?',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            cancelButton: 'btn btn-outline-secondary',
        }
    });

    if (res.isConfirmed) {
        let markerListToDelete = $markerState.lists[$markerState.selected].id;
        $markerState.selected = 0;
        $markerState.lists = $markerState.lists.filter(list => {
            return list.id !== markerListToDelete;
        });
    }
}

async function addMarkerToList(e) {
    let res = await Swal.fire({
        titleText: 'New Marker',
        showCancelButton: true,
        confirmButtonText: 'Add Marker',
        input: 'textarea',
        inputLabel: 'Comment',
        inputPlaceholder: 'Add a comment here',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            cancelButton: 'btn btn-outline-secondary',
        }
    });

    if (res.isConfirmed) {
        $markerState.lists[$markerState.selected].markers = [...$markerState.lists[$markerState.selected].markers, new _Marker({
            time: player.currentTime || 0,
            comment: res.value || ""
        })]

        //Sort markers in list by time
        $markerState.lists[$markerState.selected].markers.sort((a, b) => {
            return a.time - b.time;
        });
    }

    e.target.blur();
}

async function editMarker(e) {
    let res = await Swal.fire({
        titleText: 'Edit Marker',
        showCancelButton: true,
        confirmButtonText: 'Update Marker',
        input: 'textarea',
        inputLabel: 'Comment',
        inputValue: selectedMarker.comment,
        inputPlaceholder: 'Add a comment here',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            cancelButton: 'btn btn-outline-secondary',
        }
    });

    if (res.isConfirmed) {
        selectedMarker.comment = res.value;
        selectedMarker.time = player ? player.currentTime : 0;

        let markerIndex = $markerState.lists[$markerState.selected].markers.findIndex(marker => {
            return marker.id === selectedMarker.id;
        });

        $markerState.lists[$markerState.selected].markers[markerIndex] = selectedMarker;
        $markerState.lists[$markerState.selected].markers.sort((a, b) => {
            return a.time - b.time;
        });
    }

    e.target.blur();
}

function removeMarkerFromList(e) {
    let markerId = selectedMarker.id;
    selectedMarker = undefined;
    $markerState.lists[$markerState.selected].markers = $markerState.lists[$markerState.selected].markers.filter(marker => {
        return marker.id !== markerId;
    });

    e.target.blur();
}

function exportMarkers() {
    try {
        //Export marker lists as CSV using papaparse and file-saver.js
        let markersToExport = [];
        $markerState.lists.forEach((list, listIndex) => {
            list.markers.forEach((marker, markerIndex) => {
                markersToExport.push({
                    "Id": `${listIndex}-${markerIndex}`,
                    "Time SS": marker.time,
                    "Time SMPTE": tcLib.secToTc(marker.time + $projectState.incode, $projectState.frameRate, $projectState.dropFrame),
                    "Comment": marker.comment,
                    "List": list.name
                });
            });
        });

        console.log(markersToExport);
        let csv = unparse(markersToExport, {
            header: true,
            columns: ["Id", "Time SS", "Time SMPTE", "Comment", "List"]
        });

        let blob = new Blob([csv], {
            type: "text/csv;charset=utf-8"
        });
        //Use Swal to ask user for filename
        Swal.fire({
            titleText: 'Export Markers',
            showCancelButton: true,
            confirmButtonText: 'Export',
            input: 'text',
            inputLabel: 'Filename',
            inputPlaceholder: 'Enter a filename here',
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-primary me-2',
                cancelButton: 'btn btn-outline-secondary',
            }
        }).then(res => {
            if (res.isConfirmed && res.value) {
                saveAs(blob, res.value + ".csv");
            }
        });
    } catch (err) {
        console.log(err, "Error exporting markers");
    } finally {
        showMenu = false;
    }
}

function createEventGroup() {
    try {
        // Create a new Event Group using the marker timings of the selected list
        let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
        let evg = new _EventGroup();
        evg.name = $markerState.lists[$markerState.selected].name;
        evg.maxCps = eventGroupDefaults.maxCps || 9999;
        evg.maxWpm = eventGroupDefaults.maxWpm || 9999;
        evg.maxChars = eventGroupDefaults.maxChars || 9999;
        evg.maxLines = eventGroupDefaults.maxLines || 9999;
        evg.overlap = eventGroupDefaults.overlap;
        evg.illegalChars = eventGroupDefaults.illegalChars;
        
        $markerState.lists[$markerState.selected].markers.forEach((marker, index, markers) => {
            evg.events.push(new _Event({
                start: index === 0 ? 0 : markers[index - 1].time,
                end : marker.time,
                notes: marker.comment
            }));
        });
        
        $eventGroupState = [...$eventGroupState, evg];
        $projectState.selected = $eventGroupState.length - 1;


        toast.push("A new event group has been created using the selected marker list", {classes: ['toast-success']});

        /* Store in History */
        historyState.insert({
            name: "insert Event Group", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err, "Error exporting markers");
    } finally {
        showMenu = false;
    }
}
</script>

<div class="row g-2">
    <div class="col-9">
        <form>
            <select class="form-select form-select-sm" bind:value={$markerState.selected}>
                {#each $markerState.lists as markerList, index (markerList.id)}
                <option value="{index}" style="background-color: {markerList.color}">{markerList.name}</option>
                {/each}
            </select>
            <select class="form-select form-select-sm resize-vertical" size="8" bind:value={selectedMarker} on:change="{() => player ? player.currentTime = selectedMarker.time : null}">
                {#each $markerState.lists[$markerState.selected].markers as marker (marker.id)}
                <option value={marker}>{tcLib.secToTc(marker.time+$projectState.incode, $projectState.frameRate, $projectState.dropFrame)} | {marker.comment}</option>
                {/each}
            </select>
        </form>
    </div>
    <div class="col-3 align-items-end">
        <div class="btn-group btn-group-sm mb-3" role="group">
            <button type="button" class="btn btn-light" title="New Marker List" on:click={createMarkerList}><i class="bi bi-plus-lg"></i></button>
            <button type="button" class="btn btn-light" title="Remove Marker List" disabled="{$markerState.selected === 0}" on:click="{removeMarkerList}"><i class="bi bi-dash-lg"></i></button>
        </div>
        <div class="dropdown float-end overflow-x-visible" use:clickOutside={()=> showMenu = false}>
            <button class="btn btn-light btn-sm" type="button" on:click={() => showMenu = true}>
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <ul class="dropdown-menu {showMenu ? 'show' : ''}">
                <li><a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("markerImport")}><i class="bi bi-file-earmark-ruled"></i> Import...</a></li>
                <li><a class="dropdown-item" href="#!/" on:click={() => exportMarkers()}><i class="bi bi-filetype-csv"></i> Export as CSV</a></li>
                <li><a class="dropdown-item" href="#!/" on:click={() => createEventGroup()}>Create Event Group...</a></li>
            </ul>
        </div>
        <button type="button" class="btn btn-primary w-100 mb-1" on:click={addMarkerToList}>Add Marker</button>
        <button type="button" class="btn btn-outline-dark w-100 mb-1" disabled={!selectedMarker} on:click={editMarker}>Edit Marker</button>
        <button type="button" class="btn btn-danger w-100 mb-1" disabled={!selectedMarker} on:click={removeMarkerFromList}>Remove</button>
    </div>
</div>
<style>
    .dropdown-menu {
        position: absolute;
        right: 0px;
        top: 35px;
    }
</style>