import { encode as _encodeHtml } from "html-entities";
const encodeHtml = _encodeHtml;
export default (function htmlEncodePlainText(text) {
  /* Format Tags */
  text = text.replace(/<em>/g, "__ITALICZOPEN__");
  text = text.replace(/<\/em>/g, "__ITALICZCLOSE__");
  text = text.replace(/<strong>/g, "__BOLDZOPEN__");
  text = text.replace(/<\/strong>/g, "__BOLDZCLOSE__");
  text = text.replace(/<u>/g, "__UNDERLINEZOPEN__");
  text = text.replace(/<\/u>/g, "__UNDERLINEZCLOSE__");
  text = text.replace(/<\/u>/g, "__UNDERLINEZCLOSE__");
  /* Color Tags */
  /* Colors = black, red, green, yellow, blue, magenta, cyan, white*/
  text = text.replace(/<span style='color: black;'>/g, "__BLACKZOPEN__");
  text = text.replace(/<span style='color: red;'>/g, "__REDZOPEN__");
  text = text.replace(/<span style='color: green;'>/g, "__GREENZOPEN__");
  text = text.replace(/<span style='color: yellow;'>/g, "__YELLOWZOPEN__");
  text = text.replace(/<span style='color: blue;'>/g, "__BLUEZOPEN__");
  text = text.replace(/<span style='color: magenta;'>/g, "__MAGENTAZOPEN__");
  text = text.replace(/<span style='color: cyan;'>/g, "__CYANZOPEN__");
  text = text.replace(/<span style='color: white;'>/g, "__WHITEZOPEN__");
  text = text.replace(/<\/span>/g, "__SPANZCLOSE__");
  text = encodeHtml(text);
  text = text.replace(/__ITALICZOPEN__/g, "<em>");
  text = text.replace(/__ITALICZCLOSE__/g, "</em>");
  text = text.replace(/__BOLDZOPEN__/g, "<strong>");
  text = text.replace(/__BOLDZCLOSE__/g, "</strong>");
  text = text.replace(/__UNDERLINEZOPEN__/g, "<u>");
  text = text.replace(/__UNDERLINEZCLOSE__/g, "</u>");
  text = text.replace(/__BLACKZOPEN__/g, "<span style='color: black;'>");
  text = text.replace(/__REDZOPEN__/g, "<span style='color: red;'>");
  text = text.replace(/__GREENZOPEN__/g, "<span style='color: green;'>");
  text = text.replace(/__YELLOWZOPEN__/g, "<span style='color: yellow;'>");
  text = text.replace(/__BLUEZOPEN__/g, "<span style='color: blue;'>");
  text = text.replace(/__MAGENTAZOPEN__/g, "<span style='color: magenta;'>");
  text = text.replace(/__CYANZOPEN__/g, "<span style='color: cyan;'>");
  text = text.replace(/__WHITEZOPEN__/g, "<span style='color: white;'>");
  text = text.replace(/__SPANZCLOSE__/g, "</span>");
  return text;
});