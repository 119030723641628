import _docX from "docx";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _eol from "eol";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _Event from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const removeInvalidEvents = _removeInvalidEvents;
const eol = _eol;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const docX = _docX; //API/Creator/CLI
//import * as docX from "docx"; //CC-Converter-3
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent = new Event(),
      tcFlag = false,
      tc;
    //console.log(input);
    eol.split(input).forEach(textLine => {
      if (!textLine) {
        return;
      }
      textLine = textLine.replace(/(?<=in|out)(?=\d\d)/gi, " ");
      if (textLine.match(/\d\d:\d\d:\d\d:\d\d/)) {
        let textLineParts = textLine.split(/\s/g);
        if (textLineParts[0].toLowerCase() === 'in') {
          if (ccEvent.text) {
            ccEvent.text = convertToHtml(ccEvent.text);
            events.push(ccEvent);
            ccEvent = new Event();
          }
          ccEvent.start = tcLib.tcToSec(textLineParts[1], options.frameRate);
          if (textLineParts.length > 2) {
            ccEvent.notes = textLineParts.slice(2).join(" ");
          }
        } else if (textLineParts[0].toLowerCase() === 'out') {
          ccEvent.end = tcLib.tcToSec(textLineParts[1], options.frameRate);
        }
      } else if (ccEvent.start && ccEvent.end) {
        ccEvent.text += textLine.trim() + "\n";
      }
    });
    return events;
  },
  encode: async function (eventGroup, options) {
    let output = "",
      paragraphs = [],
      children = [];
    eventGroup.events.forEach(event => {
      paragraphs.push({
        text: `In ${tcLib.secToTc(event.start, options.frameRate)}`,
        notes: `${event.notes.replace(/(?:\r\n|\r|\n)/g, ' ').trim()}`
      });
      paragraphs.push({
        text: `Out ${tcLib.secToTc(event.end, options.frameRate)}`
      });
      paragraphs.push({
        text: `${convertToPlainText(event.text)}`,
        indent: {
          left: 360
        }
      });
      paragraphs.push({
        text: ``
      });
    });
    paragraphs.forEach((paragraph, index) => {
      if (paragraph.notes) {
        children.push(new docX.Paragraph({
          children: [new docX.TextRun({
            text: paragraph.text + " "
          }), new docX.TextRun({
            text: paragraph.notes,
            color: "#0000ff"
          })]
        }));
      } else {
        children.push(new docX.Paragraph({
          text: paragraph.text,
          indent: paragraph.indent
        }));
      }
    });
    let doc = new docX.Document({
      creator: "Closed Caption Converter 3",
      sections: [{
        properties: {},
        children: children
      }]
    });
    if (typeof window === 'undefined') {
      output = await docX.Packer.toBuffer(doc);
    } else {
      output = await docX.Packer.toBlob(doc);
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup, options) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      return eventGroup;
    }
  }
};