<script>
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    environment
} from '@app/store/envStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    saveAs
} from 'file-saver';
import Swal from 'sweetalert2'

async function saveProject() {
    try {
        if ($environment.electron) {
            const ipcRenderer = window.ipcRenderer;
            const fs = window.fs;
            if ($projectState.localPath) {
                let projectJson = JSON.parse(JSON.stringify($projectState));
                projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
                projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
                projectJson.markers = JSON.parse(JSON.stringify($markerState));
                projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
                projectJson.style = JSON.parse(JSON.stringify($styleState));
                await fs.writeFile($projectState.localPath, JSON.stringify(projectJson, null, 4));

                toast.push("Save project completed successfully", {classes: ['toast-success']});

            } else {
                let filePath = await ipcRenderer.invoke('openSaveDialog', {
                    title: "Save Project",
                    defaultPath: `${$projectState.name}.ccprj`,
                    properties: ["showOverwriteConfirmation", "createDirectory"]
                });

                console.log(filePath);
                if (filePath.canceled) {
                    throw new Error("Save process cancelled by user");
                }

                $projectState.localPath = filePath.filePath;
                $projectState.name = filePath.filePath.replace(/^.*[\\\/]/, '').replace(/\.ccprj/g, "");

                let projectJson = JSON.parse(JSON.stringify($projectState));
                projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
                projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
                projectJson.markers = JSON.parse(JSON.stringify($markerState));
                projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
                projectJson.style = JSON.parse(JSON.stringify($styleState));
                await fs.writeFile($projectState.localPath, JSON.stringify(projectJson, null, 4));

                toast.push("Save project completed successfully", {classes: ['toast-success']});
            }

            historyState.reset();
        } else {
            let projectJson = JSON.parse(JSON.stringify($projectState));
            projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
            projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
            projectJson.markers = JSON.parse(JSON.stringify($markerState));
            projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
            projectJson.style = JSON.parse(JSON.stringify($styleState));
            let fileBlob = new Blob([JSON.stringify(projectJson, null, 4)], {
                type: "text/plain;charset=utf-8"
            });

            saveAs(fileBlob, $projectState.name + ".ccprj", {
                autoBom: true
            });

            historyState.reset();
        }
    } catch (err) {
        console.log(err, err.message);
        toast.push("Save project completed with error. " + err.message, {classes: ['toast-danger']});
    }
}

async function saveProjectAs() {
    try {
        if ($environment.electron) {
            let filePath = await ipcRenderer.invoke('openSaveDialog', {
                title: "Save Project",
                defaultPath: `${$projectState.name}.ccprj`,
                properties: ["showOverwriteConfirmation", "createDirectory"]
            });

            if (filePath.canceled) {
                throw new Error("Save process cancelled by user");
            }

            $projectState.localPath = filePath.filePath;
            $projectState.name = filePath.filePath.replace(/^.*[\\\/]/, '').replace(/\.ccprj/g, "");

            let projectJson = JSON.parse(JSON.stringify($projectState));
            projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
            projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
            projectJson.markers = JSON.parse(JSON.stringify($markerState));
            projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
            projectJson.style = JSON.parse(JSON.stringify($styleState));

            await fs.writeFile($projectState.localPath, JSON.stringify(projectJson, null, 4));

            toast.push("Save project completed successfully", {classes: ['toast-success']});
            
            historyState.reset();
        } else {
            let projectJson = JSON.parse(JSON.stringify($projectState));
            projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
            projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
            projectJson.markers = JSON.parse(JSON.stringify($markerState));
            projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
            projectJson.style = JSON.parse(JSON.stringify($styleState));
            let fileBlob = new Blob([JSON.stringify(projectJson, null, 4)], {
                type: "text/plain;charset=utf-8"
            });

            Swal.fire({
                titleText: 'Project Name',
                text: 'Please enter a project name:',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Save',
                denyButtonText: 'Cancel',
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                inputLabel: "Project Name",
                inputPlaceholder: $projectState.name,
                input: "text",
                customClass: {
                    confirmButton: 'btn btn-lg btn-primary me-2',
                    denyButton: 'btn btn-lg btn-outline-secondary',
                }
            }).then((result) => {
                //console.log(result);
                if (result.isConfirmed) {
                    let projectName = result.value;
                    if (projectNamed) {
                        $projectState.name = projectName;
                    }
                }

                saveAs(fileBlob, $projectState.name + ".ccprj", {
                    autoBom: true
                });

                historyState.reset();
            });
        }
    } catch (err) {
        console.log(err, err.message);
        toast.push("Save project completed with error. " + err.message, {classes: ['toast-danger']});
    }
}
</script>

<a class="dropdown-item" href="#!/" on:click="{() => $projectState.localPath ? saveProject() : saveProjectAs()}">Save Project</a>
<a class="dropdown-item" href="#!/" on:click="{saveProjectAs}">Save Project As...</a>
