import _convertMaptoSegments from "./alignment/convertMaptoSegments.js";
import _detectMissingDialogue from "./alignment/detectMissingDialogue.js";
import _calculateDrift from "./alignment/calculateDrift.js";
import _autoCorrectTiming from "../eventGroups/autoCorrectTiming.js";
import _fixOverlap from "../eventGroups/fixOverlap.js";
import _removeSdhElements from "./removeSdhElements.js";
import _convertToPlainText from "../quill/convertToPlainText.js";
import _calculateMissingTimecodes from "./alignment/calculateMissingTimecodes.js";
import _removeInvalidEvents from "../eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const calculateMissingTimecodes = _calculateMissingTimecodes;
const convertToPlainText = _convertToPlainText;
const removeSdhElements = _removeSdhElements;
const fixOverlap = _fixOverlap;
const autoCorrectTiming = _autoCorrectTiming;
const calculateDrift = _calculateDrift;
const detectMissingDialogue = _detectMissingDialogue;
const convertMaptoSegments = _convertMaptoSegments;
export default (async function alignCaptionFile(eventGroup, wordMap, frameRate, captionFile = true) {
  try {
    /* Remove invalid events */
    let stage = 0;
    let processing;
    let eventGap = 2; // Gap between known events with times
    let minNumberOfWords = 8; //Words in an Event;
    let lastEventWithTimes;
    let accurateEvents = [];
    console.log("Starting Alignment Process");
    console.log("This alignment job is based on a caption file? " + captionFile);

    // Clean update word map
    console.log(`Cleaning up word map to remove unused properties. Wordmap size is ${wordMap.events.length}`);
    /* Clean up the word map events since we don't really need all the extra data. */
    wordMap.events = wordMap.events.map((ev, evIn) => {
      return {
        index: evIn,
        text: ev.text.toLowerCase().replace(/[.,\/#!$%\^&\*\?;:'{}=\-_`~()]/g, ""),
        start: ev.start,
        end: Math.min(ev.end, ev.start + 0.8),
        //Limit wordMap Endings to less time.
        used: false,
        matches: []
      };
    });
    console.log(`Removing invalid events from event group. Starting number: ${eventGroup.events.length}`);
    eventGroup = removeInvalidEvents(eventGroup);
    console.log(`Number of events after removal: ${eventGroup.events.length}`);

    /* Remove start and end time but save it as properties on the event */
    console.log("Removing Start and end times from events in Event Group");
    /* Remove start and end time */
    eventGroup.events.forEach((event, index, events) => {
      events[index].oStart = captionFile ? event.start : false;
      events[index].oEnd = captionFile ? event.end : false;
      events[index].oDuration = captionFile ? event.end - event.start : false;
      events[index].start = false;
      events[index].end = false;
      events[index].plainText = convertToPlainText(event.text, " ");
      events[index].plainText = removeSdhElements(events[index].plainText);
      //remove punctuation from plainText
      events[index].plainText = events[index].plainText.replace(/[.,\/#!$%\^&\*\?;:{}=\-_`~()]/g, "");
      events[index].plainText = events[index].plainText.toLowerCase().trim();
    });

    //First stage - Look for perfect matches with more than 5 words in the Event... starting at events with more than 10 words.
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    let minWords = 10;
    for (minWords; minWords >= 5; minWords--) {
      console.log(`${stage}: Looking for perfect matches with ${minWords} words in the event.`);
      for (let currentEvent = 0; currentEvent < eventGroup.events.length; currentEvent++) {
        let words = eventGroup.events[currentEvent].plainText.split(" ");
        if (words.length >= minWords && words.length < (minWords === 10 ? 9999 : minWords + 1)) {
          // Find the word in the transcript word map that matches the first word in the event and all subsequent words
          let wordMapMatches = wordMap.events.filter(wordMapWord => {
            return wordMapWord.text === words[0];
          });
          let matches = [];
          for (let currentMatch = 0; currentMatch < wordMapMatches.length; currentMatch++) {
            let possibleMatches = [];
            for (let currentWord = 0; currentWord < words.length; currentWord++) {
              if (wordMap.events[wordMapMatches[currentMatch].index + currentWord] && wordMap.events[wordMapMatches[currentMatch].index + currentWord].text === words[currentWord]) {
                possibleMatches.push(JSON.parse(JSON.stringify(wordMap.events[wordMapMatches[currentMatch].index + currentWord])));
              }
            }
            if (possibleMatches.length === words.length) {
              matches.push(possibleMatches);
            }
          }
          if (matches.length === 1) {
            matches = matches[0];
            eventGroup.events[currentEvent].matches = matches;
            eventGroup.events[currentEvent].start = matches[0].start;
            eventGroup.events[currentEvent].end = matches[matches.length - 1].end;
            eventGroup.events[currentEvent].stage = stage;
            accurateEvents.push(JSON.parse(JSON.stringify(eventGroup.events[currentEvent])));
            //Mark Words from WordMap EVG as used.
            matches.forEach(match => {
              wordMap.events[match.index].used = true;
            });
          }
        }
      }
    }

    //Second Stage - uses first word of event to find matches
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    processing = true;
    eventGap = 2;
    minNumberOfWords = 10;
    while (eventGap < 50 || minNumberOfWords > 0) {
      console.log(`${stage}: Looking for matches with ${minNumberOfWords} words in the event and Event Gap: ${eventGap}`);
      if (!processing) {
        eventGap += 5;
        if (eventGap > 50 && minNumberOfWords > 1) {
          minNumberOfWords--;
          eventGap = 5;
        } else if (eventGap > 50) {
          break;
        }
      } else {
        processing = false;
      }
      lastEventWithTimes = null;
      for (let currentEvent = 0; currentEvent < eventGroup.events.length; currentEvent++) {
        if (!eventGroup.events[currentEvent].end) {
          let nextEventWithTimes,
            lastEventReached,
            ev = currentEvent;
          while (!nextEventWithTimes && !lastEventReached) {
            if (eventGroup.events[ev] && eventGroup.events[ev].start) {
              nextEventWithTimes = ev;
            }
            ev++;
            if (ev > eventGroup.events.length) {
              lastEventReached = true;
            }
          }
          if (lastEventWithTimes && nextEventWithTimes && nextEventWithTimes - lastEventWithTimes < eventGap) {
            let words = eventGroup.events[currentEvent].plainText.split(" ");
            if (words.length >= minNumberOfWords) {
              // Find the word in the transcript word map that matches the first word in the event and all subsequent words
              let wordMapMatches = wordMap.events.filter(wordMapWord => {
                return wordMapWord.text === words[0] && !wordMapWord.used && wordMapWord.start >= eventGroup.events[lastEventWithTimes].end && wordMapWord.end <= eventGroup.events[nextEventWithTimes].start;
              });
              for (let currentMatch = 0; currentMatch < wordMapMatches.length; currentMatch++) {
                let matches = [];
                for (let currentWord = 0; currentWord < words.length; currentWord++) {
                  if (wordMap.events[wordMapMatches[currentMatch].index + currentWord] && wordMap.events[wordMapMatches[currentMatch].index + currentWord].text === words[currentWord] && (matches.length === 0 || matches[matches.length - 1].end + 3 > wordMap.events[wordMapMatches[currentMatch].index + currentWord].start)) {
                    matches.push(JSON.parse(JSON.stringify(wordMap.events[wordMapMatches[currentMatch].index + currentWord])));
                  }
                }
                if (words.length >= 3 && matches.length / words.length > 0.65 || words.length <= 2 && matches.length / words.length >= 0.55) {
                  // console.log("------------------------");
                  // console.log(`Current Event: ${currentEvent}`);
                  // console.log(`Last Event with time: ${lastEventWithTimes}`);
                  // console.log(`Next Event with time: ${nextEventWithTimes}`);
                  // console.log(matches.length/words.length);
                  eventGroup.events[currentEvent].matches = matches;
                  eventGroup.events[currentEvent].start = matches[0].start;
                  eventGroup.events[currentEvent].end = matches[matches.length - 1].end;
                  eventGroup.events[currentEvent].stage = stage;
                  //Mark Words from WordMap EVG as used.
                  matches.forEach(match => {
                    wordMap.events[match.index].used = true;
                  });
                  processing = true;
                  lastEventWithTimes = currentEvent;
                }
              }
            }
          }
        } else {
          lastEventWithTimes = currentEvent;
        }
      }
    }

    //Third Stage - uses second word of event to find matches
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    processing = true;
    eventGap = 2;
    minNumberOfWords = 10; //minimum numbr of words in the event
    while (eventGap < 50 || minNumberOfWords > 0) {
      console.log(`${stage}: Looking for matches with ${minNumberOfWords} words in the event. Event Gap: ${eventGap}`);
      if (!processing) {
        eventGap += 5;
        if (eventGap > 50 && minNumberOfWords > 1) {
          minNumberOfWords--;
          eventGap = 5;
        } else if (eventGap > 50) {
          break;
        }
      } else {
        processing = false;
      }
      let lastEventWithTimes;
      for (let currentEvent = 0; currentEvent < eventGroup.events.length; currentEvent++) {
        if (!eventGroup.events[currentEvent].end) {
          let nextEventWithTimes,
            lastEventReached,
            ev = currentEvent;
          while (!nextEventWithTimes && !lastEventReached) {
            if (eventGroup.events[ev] && eventGroup.events[ev].start) {
              nextEventWithTimes = ev;
            }
            ev++;
            if (ev > eventGroup.events.length) {
              lastEventReached = true;
            }
          }
          if (lastEventWithTimes && nextEventWithTimes && nextEventWithTimes - lastEventWithTimes < eventGap) {
            let words = eventGroup.events[currentEvent].plainText.split(" ");
            if (words.length >= minNumberOfWords) {
              // Find the word in the transcript word map that matches the second word in the event and all subsequent words
              let wordMapMatches = wordMap.events.filter(wordMapWord => {
                return wordMapWord.text === words[1] && !wordMapWord.used && wordMapWord.start >= eventGroup.events[lastEventWithTimes].end && wordMapWord.end <= eventGroup.events[nextEventWithTimes].start;
              });
              for (let currentMatch = 0; currentMatch < wordMapMatches.length; currentMatch++) {
                let matches = [];
                for (let currentWord = 1; currentWord < words.length; currentWord++) {
                  if (wordMap.events[wordMapMatches[currentMatch].index + currentWord - 1] && wordMap.events[wordMapMatches[currentMatch].index + currentWord - 1].text === words[currentWord]) {
                    matches.push(JSON.parse(JSON.stringify(wordMap.events[wordMapMatches[currentMatch].index + currentWord - 1])));
                  }
                }
                if (words.length >= 3 && matches.length / words.length > 0.65 || words.length <= 2 && matches.length / words.length > 0.5) {
                  // console.log("------------------------");
                  // console.log(`Current Event: ${currentEvent}`);
                  // console.log(`Last Event with time: ${lastEventWithTimes}`);
                  // console.log(`Next Event with time: ${nextEventWithTimes}`);
                  // console.log(matches.length/words.length);
                  eventGroup.events[currentEvent].matches = matches;
                  eventGroup.events[currentEvent].start = matches[0].start;
                  eventGroup.events[currentEvent].end = matches[matches.length - 1].end;
                  eventGroup.events[currentEvent].missingStart = true;
                  eventGroup.events[currentEvent].stage = stage;
                  matches.forEach(match => {
                    wordMap.events[match.index].used = true;
                  });
                  processing = true;
                  lastEventWithTimes = currentEvent;
                }
              }
            }
          }
        } else {
          lastEventWithTimes = currentEvent;
        }
      }
    }

    //Forth Stage - uses third word of event to find matches
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    processing = true;
    eventGap = 5;
    minNumberOfWords = 10;
    while (eventGap < 50 || minNumberOfWords > 2) {
      console.log(`${stage}: Looking for matches with ${minNumberOfWords} words in the event and Event Gap: ${eventGap}`);
      if (!processing) {
        eventGap += 5;
        if (eventGap > 50 && minNumberOfWords > 2) {
          minNumberOfWords--;
          eventGap = 5;
        } else if (eventGap > 50) {
          break;
        }
      } else {
        processing = false;
      }
      let lastEventWithTimes;
      for (let currentEvent = 0; currentEvent < eventGroup.events.length; currentEvent++) {
        if (!eventGroup.events[currentEvent].end) {
          let nextEventWithTimes,
            lastEventReached,
            ev = currentEvent;
          while (!nextEventWithTimes && !lastEventReached) {
            if (eventGroup.events[ev] && eventGroup.events[ev].start) {
              nextEventWithTimes = ev;
            }
            ev++;
            if (ev > eventGroup.events.length) {
              lastEventReached = true;
            }
          }
          if (lastEventWithTimes && nextEventWithTimes && nextEventWithTimes - lastEventWithTimes < eventGap) {
            let words = eventGroup.events[currentEvent].plainText.split(" ");
            if (words.length >= minNumberOfWords) {
              // Find the word in the transcript word map that matches the second word in the event and all subsequent words
              let wordMapMatches = wordMap.events.filter(wordMapWord => {
                return wordMapWord.text === words[2] && !wordMapWord.used && wordMapWord.start >= eventGroup.events[lastEventWithTimes].end && wordMapWord.end <= eventGroup.events[nextEventWithTimes].start;
              });
              for (let currentMatch = 0; currentMatch < wordMapMatches.length; currentMatch++) {
                let matches = [];
                for (let currentWord = 2; currentWord < words.length; currentWord++) {
                  if (wordMap.events[wordMapMatches[currentMatch].index + currentWord - 2] && wordMap.events[wordMapMatches[currentMatch].index + currentWord - 2].text === words[currentWord]) {
                    matches.push(JSON.parse(JSON.stringify(wordMap.events[wordMapMatches[currentMatch].index + currentWord - 2])));
                  }
                }
                if (matches.length / words.length > 0.65) {
                  /* console.log("------------------------");
                                         console.log(`Current Event: ${currentEvent}`);
                                         console.log(`Last Event with time: ${lastEventWithTimes}`);
                                         console.log(`Next Event with time: ${nextEventWithTimes}`);
                                         console.log(matches.length/words.length); */
                  eventGroup.events[currentEvent].matches = matches;
                  eventGroup.events[currentEvent].start = matches[0].start;
                  eventGroup.events[currentEvent].end = matches[matches.length - 1].end;
                  eventGroup.events[currentEvent].missingStart = true;
                  eventGroup.events[currentEvent].stage = stage;
                  matches.forEach(match => {
                    wordMap.events[match.index].used = true;
                  });
                  processing = true;
                  lastEventWithTimes = currentEvent;
                }
              }
            }
          }
        } else {
          lastEventWithTimes = currentEvent;
        }
      }
    }

    //Fifth Stage - If an event has a gap of 2 then we can just assume that any leftover words from the word map belong to it.
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    lastEventWithTimes = null;
    console.log(`${stage}: Looking for events between two known events.`);
    for (let currentEvent = 0; currentEvent < eventGroup.events.length; currentEvent++) {
      if (!eventGroup.events[currentEvent].end && eventGroup.events[currentEvent].plainText) {
        let nextEventWithTimes,
          lastEventReached,
          ev = currentEvent;
        while (!nextEventWithTimes && !lastEventReached) {
          if (eventGroup.events[ev] && eventGroup.events[ev].start) {
            nextEventWithTimes = ev;
          }
          ev++;
          if (ev > eventGroup.events.length) {
            lastEventReached = true;
          }
        }
        if (lastEventWithTimes && nextEventWithTimes && nextEventWithTimes - lastEventWithTimes === 2) {
          let matches = [];
          let words = eventGroup.events[currentEvent].plainText.split(" ");
          let lastWordIndex = eventGroup.events[lastEventWithTimes].matches[eventGroup.events[lastEventWithTimes].matches.length - 1].index;
          let nextWordIndex = eventGroup.events[nextEventWithTimes].matches[0].index;

          //console.log("Last Word Index:" + lastWordIndex);
          //console.log("Next Word Index:" + nextWordIndex);

          if (nextWordIndex - lastWordIndex > 2) {
            for (lastWordIndex; lastWordIndex < nextWordIndex - 1; lastWordIndex++) {
              matches.push(JSON.parse(JSON.stringify(wordMap.events[lastWordIndex + 1])));
            }

            //sort matches by index
            matches.sort((a, b) => {
              return a.index - b.index;
            });
            //Add the difference in time between each match
            matches.forEach((m, index) => {
              if (index > 0) {
                matches[index].duration = m.start - matches[index - 1].end;
              } else {
                matches[index].duration = 0;
              }
            });

            //console.log(`BEFORE: There are ${matches.length} matches for this event`, matches);
            matches = matches.filter((m, i) => {
              //if i is 0 then we want to look at the duration of the next two matches. If the next match has a differerence of less than 2 seconds then we want to keep the match. If the difference is greater than 2 seconds then we want to remove the match.
              if (i === 0 && matches.length > 1) {
                return matches[i + 1].duration < 2;
              }

              //if i is 1 and the duration is more than 2, we need to look at the next match and see if the difference is less than 2 seconds. If it is then we want to keep the match. If it is more than 2 seconds then we want to remove the match.
              if (i === 1 && matches.length > 2) {
                return matches[i + 1].duration < 2;
              }

              //if i is more than 0 we need to look at the difference and filter it out if the difference is greater than 2 seconds.
              return m.duration < 2;
            });
            if (matches.length > 0) {
              /*  console.log("------------------------");
               console.log(`Current Event: ${currentEvent}`);
               console.log(`Last Event with time: ${lastEventWithTimes}`);
               console.log(`Next Event with time: ${nextEventWithTimes}`);
               console.log(`There are ${matches.length} matches for this event`, matches);
               console.log(matches.length/words.length); */
              eventGroup.events[currentEvent].matches = matches;
              eventGroup.events[currentEvent].start = matches[0].start;
              eventGroup.events[currentEvent].end = matches[matches.length - 1].end;
              eventGroup.events[currentEvent].guess = true;
              eventGroup.events[currentEvent].stage = stage;
              matches.forEach(match => {
                wordMap.events[match.index].used = true;
              });
              processing = true;
              lastEventWithTimes = currentEvent;
            }
          }
        }
      } else if (eventGroup.events[currentEvent].end) {
        lastEventWithTimes = currentEvent;
      }
    }

    //Sixth Stage - For any leftover events we're going to see what words may apply to it. If we can find a decent match then we will use that as a rough timing. (not looking at SDH elements)
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    lastEventWithTimes = null;
    console.log(`${stage}: Looking for matches for events with no timing`);
    for (let currentEvent = 0; currentEvent < eventGroup.events.length; currentEvent++) {
      if (!eventGroup.events[currentEvent].end && eventGroup.events[currentEvent].plainText) {
        let nextEventWithTimes,
          lastEventReached,
          ev = currentEvent;
        while (!nextEventWithTimes && !lastEventReached) {
          if (eventGroup.events[ev] && eventGroup.events[ev].start) {
            nextEventWithTimes = ev;
          }
          ev++;
          if (ev > eventGroup.events.length) {
            lastEventReached = true;
          }
        }
        let matches = [];
        let possibleMatches = [];
        let lastWordIndex = lastEventWithTimes ? eventGroup.events[lastEventWithTimes].matches[eventGroup.events[lastEventWithTimes].matches.length - 1].index : wordMap.events.findIndex(w => {
          return !w.used;
        });
        let nextWordIndex = nextEventWithTimes ? eventGroup.events[nextEventWithTimes].matches[0].index : wordMap.events.length;

        //console.log("Last Word Index:" + lastWordIndex);
        //console.log("Next Word Index:" + nextWordIndex);

        if (nextWordIndex - lastWordIndex > 2) {
          for (lastWordIndex; lastWordIndex < nextWordIndex - 1; lastWordIndex++) {
            possibleMatches.push(JSON.parse(JSON.stringify(wordMap.events[lastWordIndex + 1])));
          }

          //console.log("------------------------");
          //console.log(`${eventGroup.events[currentEvent].plainText}`);
          //console.log(possibleMatches);

          let words = eventGroup.events[currentEvent].plainText.split(" ");
          //console.log(`There are ${words.length} words in this event`);
          words.forEach(word => {
            let match = possibleMatches.filter(m => {
              return m.text === word;
            });
            if (match.length > 0) {
              //console.log("Possible Match: "+ JSON.stringify(match, null, 4));
              matches.push(match[0]);
            }
          });
          if (matches.length / words.length > 0.3) {
            //Sort matches by index
            matches.sort((a, b) => {
              return a.index - b.index;
            });
            //Add the difference in time between each match
            matches.forEach((m, index) => {
              if (index > 0) {
                matches[index].duration = m.start - matches[index - 1].end;
              } else {
                matches[index].duration = 0;
              }
            });
            //console.log(matches);
            matches = matches.filter((m, i) => {
              //if i is 0 then we want to look at the duration of the next two matches. If the next match has a differerence of less than 2 seconds then we want to keep the match. If the difference is greater than 2 seconds then we want to remove the match.
              if (i === 0 && matches.length > 2) {
                return matches[i + 1].duration < 2;
              }
              if (i === 0 && matches.length === 2) {
                return matches[i + 1].duration < 6;
              }

              //if i is 1 and the duration is more than 2, we need to look at the next match and see if the difference is less than 2 seconds. If it is then we want to keep the match. If it is more than 2 seconds then we want to remove the match.
              if (i === 1 && matches.length > 2) {
                return matches[i + 1].duration < 2;
              }
              if (i === 1 && matches.length === 2) {
                return m.duration < 6;
              }

              //if i is more than 0 we need to look at the difference and filter it out if the difference is greater than 2 seconds.
              return m.duration < 2;
            });
            //console.log(matches);
            if (matches.length > 0) {
              eventGroup.events[currentEvent].matches = matches;
              eventGroup.events[currentEvent].start = matches[0].start;
              eventGroup.events[currentEvent].end = matches[matches.length - 1].end;
              eventGroup.events[currentEvent].guess = true;
              eventGroup.events[currentEvent].stage = stage;
              matches.forEach(match => {
                wordMap.events[match.index].used = true;
              });
              processing = true;
              lastEventWithTimes = currentEvent;
            }
          }
        }
      } else if (eventGroup.events[currentEvent].end) {
        lastEventWithTimes = currentEvent;
      }
    }

    //Sevent Stage - Loop over the word map for unused words that may be the last word in an event based on the timing being less than 0.3 seconds apart from the end of an event.
    stage++;
    console.log(`STAGE NUMBER: ${stage}`);
    wordMap.events.forEach((word, wordIndex, words) => {
      if (!word.used) {
        let possibleEvents = eventGroup.events.filter(ev => {
          // Find events that have a start within 0.3 seconds of the word's end time or an End time within 0.3 seconds from the word's start time.
          return ev.guess && ev.start && ev.start - 0.3 <= word.end && ev.start + 0.3 >= word.end || ev.end && ev.end - 0.3 <= word.start && ev.end + 0.3 >= word.start;
        });
        if (possibleEvents.length > 0) {
          // console.log(`Word: ${word.text} - ${word.start} - ${word.end}`);
          // console.log(possibleEvents);         
          let event = possibleEvents[0];
          if (event.start && event.start - 0.3 <= word.end && event.start + 0.3 >= word.end) {
            event.start = word.start;
            event.matches.unshift(word);
            words[wordIndex].used = true;
          } else if (event.end && event.end - 0.3 <= word.start && event.end + 0.3 >= word.start) {
            event.end = word.end;
            event.matches.push(word);
            words[wordIndex].used = true;
          }
        }
      }
    });
    console.log("DONE STAGES");
    console.log(eventGroup.events.length);
    console.log("Calculating Missing Timecodes");
    eventGroup = await calculateMissingTimecodes(eventGroup);
    console.log(eventGroup.events.length);
    console.log("Fixing Overlap...");
    eventGroup = fixOverlap(eventGroup);
    console.log(eventGroup.events.length);
    console.log("Auto Correcting Timing...");
    eventGroup = autoCorrectTiming(eventGroup, frameRate, 25, 0.83, 9999, 0);
    console.log(eventGroup.events.length);
    console.log("Calculating Drift...");
    let drift = await calculateDrift(accurateEvents);
    let missingDialogueMap = detectMissingDialogue(eventGroup, wordMap);
    let missingDialogueSegments = convertMaptoSegments(missingDialogueMap);
    return {
      eventGroup: eventGroup,
      missingSegments: missingDialogueSegments,
      drift: drift
    };
  } catch (err) {
    console.log(err, err.message);
    return eventGroup;
  }
});