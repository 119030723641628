import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
export default (function orderByTime(eventGroup, frameRate, dropFrame) {
  eventGroup.events.forEach((event, index, events) => {
    if (event.start) {
      let startTc = tcLib.secToTc(event.start, frameRate, dropFrame);
      events[index].start = tcLib.tcToSec(startTc, frameRate, dropFrame);
    }
    if (event.end) {
      let endTc = tcLib.secToTc(event.end, frameRate, dropFrame);
      events[index].end = tcLib.tcToSec(endTc, frameRate, dropFrame);
    }
  });
  return eventGroup;
});