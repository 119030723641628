import _getLongestLine from "../utility/getLongestLine.js";
import _findCenter from "../utility/findCenter.js";
import _convertToPlainText from "../quill/convertToPlainText.js";
const convertToPlainText = _convertToPlainText;
const findCenter = _findCenter;
const getLongestLine = _getLongestLine;
export default {
  replaceColorTags: function (text) {
    return text.replace(/<c\.(\w+)>(.*?)<\/c>/g, '<$1>$2</$1>');
  },
  calcSize: function (event, maxLength = 32) {
    let plainText = convertToPlainText(event.text);
    let longestLine = getLongestLine(plainText);
    let size = parseInt(longestLine.length / maxLength * 100);
    return Math.min(size, 90);
  },
  calcPos: function (event, win, size) {
    let plainText = convertToPlainText(event.text);
    if (event.xPos === "start") {
      return Math.min(parseInt(event.xOffset / win.width * 100), 100);
    } else if (event.xPos === "end") {
      if (event.alignment === "left" || event.alignment === "center") {
        return parseInt(100 - size + event.xOffset / win.width * (100 - size));
      } else {
        return parseInt(90 + event.xOffset / win.width * 90);
      }
    } else {
      return parseInt(50 + event.xOffset / win.width * 100);
    }
  },
  calcLineValue: function (event, numberOfLines, win) {
    let line = 94.66;
    if (event.yPos === "start") {
      line = event.yOffset / win.height * 100;
    } else if (event.yPos === "center") {
      line = findCenter(100, numberOfLines) + event.yOffset / win.height * 100;
    } else {
      line = 100 - event.yOffset / win.height * -100 - numberOfLines * 5.33;
    }
    return Math.min(line, 100);
  },
  webcargoLineValue: function (event, numberOfLines, win) {
    let line = 100;
    if (event.yPos === "start") {
      line = Math.abs((event.yOffset - 104) / win.height * 100);
    } else if (event.yPos === "center") {
      line = findCenter(100, numberOfLines * 5.5) + event.yOffset / win.height * 100;
    } else {
      line = 107.78 - numberOfLines * 5.5 + (event.yOffset + 20) / win.height * 100;
      line = line > 100 ? 95 : line;
    }
    return line.toFixed(2);
  },
  alignmentMap: {
    "left": "left",
    "start": "left",
    "middle": "center",
    "center": "center",
    "right": "right",
    "end": "right"
  },
  colorMapping: {
    "#000000": "black",
    //black
    "#FF0000": "red",
    //red
    "#008000": "lime",
    //lime
    "#FFFF00": "yellow",
    //yellow
    "#0000FF": "blue",
    //blue
    "#FF00FF": "magenta",
    //magenta
    "#00FFFF": "cyan",
    //cyan
    "#FFFFFF": "white" //white
  },
  fontStyleCues: `
STYLE
::cue(.black) {
    color: #000000;
}
::cue(.red) {
    color: #FF0000;
}
::cue(.lime) {
    color: #008000;
}
::cue(.yellow) {
    color: #FFFF00;
}
::cue(.blue) {
    color: #0000FF;
}
::cue(.magenta) {
    color: #FF00FF;
}
::cue(.cyan) {
    color: #00FFFF;
}
::cue(.white) {
    color: #FFFFFF;
}
::cue(.bg_black) {
    background-color: #000000;
}
::cue(.bg_red) {
    background-color: #FF0000;
}
::cue(.bg_lime) {
    background-color: #008000;
}
::cue(.bg_yellow) {
    background-color: #FFFF00;
}
::cue(.bg_blue) {
    background-color: #0000FF;
}
::cue(.bg_magenta) {
    background-color: #FF00FF;
}
::cue(.bg_cyan) {
    background-color: #00FFFF;
}
::cue(.bg_white) {
    background-color: #FFFFFF;
}`
};