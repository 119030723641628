<script>
import {
    historyState
} from '@app/store/historyStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    createEventDispatcher
} from 'svelte';
import { toast } from '@zerodevx/svelte-toast';
/* Amimation */
import {
    slide
} from 'svelte/transition';
import {
    quintOut
} from 'svelte/easing';

import fixOverlap from '@app/external/cc-lib/dist/functions/eventGroups/fixOverlap.js';
import sortByTime from '@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js';
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
import convertToPlainTextCustom from '@app/external/cc-lib/dist/functions/quill/convertToPlainTextCustom.js';
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
import fixRtlText from '@app/external/cc-lib/dist/functions/utility/fixRtlText.js'; 
import reverseRtlStartEnd from '@app/external/cc-lib/dist/functions/utility/reverseRtlStartEnd.js'; 


export let currentState;
const dispatch = createEventDispatcher();
let alignmentMap = {
    left: false,
    right: "right",
    center: "center"
}

let textMenu = false;

function spellNumbers() {
    try {
        let numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            events[index].text =  event.text.replace(/\b\d\b/g, m => numbers[m])
        });

        $eventGroupState[$projectState.selected].selected = [];
        $eventGroupState = $eventGroupState;

        toast.push(`Spelling numbers #0-10 complete`, {classes: ['toast-success']});

        historyState.insert({
            name: "spell numbers", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err) {
        console.log(err);
        toast.push(`Spelling numbers #0-10 failed. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function removeRepeatWords() {
    try {
        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            try {
                let plainText = convertToPlainText(event.text, " ");
                let matches = plainText.match(/(\b\S+\b)(?=\s\1)/gmi);
                if (matches){
                    plainText = plainText.replace(/(\b\S+\b)(?=\s\1)/gmi, "");
                    events[index].text = convertToHtml(plainText);
                }      
            } catch(e){
                console.log(e);
                console.log(index),
                console.log(e.message);
            }
        });

        $eventGroupState[$projectState.selected].selected = [];
        $eventGroupState = $eventGroupState;

        toast.push(`Removing repeat words complete`, {classes: ['toast-success']});

        historyState.insert({
            name: "remove repeat words", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err) {
        console.log(err);
        toast.push(`Removing repeat words failed. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function replaceWithEllipses() {
    try {
        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            events[index].text =  event.text.replace(/\.\.+/gm, "…");
        });

        $eventGroupState[$projectState.selected].selected = [];
        $eventGroupState = $eventGroupState;


        toast.push(`Ellipses replacement complete`, {classes: ['toast-success']});

        historyState.insert({
            name: "ellipses replacement", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err) {
        console.log(err);
        toast.push(`Ellipses replacement failed. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function fixEventOverlap(eventGroup) {
    $eventGroupState[eventGroup] = fixOverlap($eventGroupState[eventGroup]);
    historyState.insert({
        name: "fix overlap", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    toast.push("Fixed event overlap for all events successfully", {classes: ['toast-success']});
}

function orderByTime(eventGroup) {
    $eventGroupState[eventGroup] = sortByTime($eventGroupState[eventGroup]);
    $eventGroupState[$projectState.selected].selected = [];
    historyState.insert({
        name: "ordering", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    toast.push("Events ordered by time successfully", {classes: ['toast-success']});
}

function toggleMenu() {
    dispatch('toggleMenu', {
        menu: 'format'
    });
}

function toggleTextMenu() {
    textMenu = !textMenu;
    toggleMenu();
}

function snapToEventEnds() {
    /* 0.5 seconds is the range we're looking for but it could be tighter... not sure yet. */
    try {
        sortByTime($eventGroupState[$projectState.selected]).events.forEach((event, eventIndex, eventsArray) => {
            if (eventIndex > 0 && event.start <= eventsArray[eventIndex - 1].end + 0.5) {
                eventsArray[eventIndex].start = eventsArray[eventIndex - 1].end;
            }
        });

        $eventGroupState[$projectState.selected] = $eventGroupState[$projectState.selected];

        historyState.insert({
            name: "snap to events", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Snap to event ends finished successfully", {classes: ['toast-success']});

    } catch (err) {
        console.log(err, err.message);
        toast.push("Snap to event ends failed with message: " + err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }

}

function snapToEventStarts() {
    /* 0.5 seconds is the range we're looking for but it could be tighter... not sure yet. */
    try {
        sortByTime($eventGroupState[$projectState.selected]).events.forEach((event, eventIndex, eventsArray) => {
            if (eventsArray[eventIndex + 1] && event.end >= eventsArray[eventIndex + 1].start - 0.5) {
                eventsArray[eventIndex].end = eventsArray[eventIndex + 1].start;
            }
        });

        $eventGroupState[$projectState.selected] = $eventGroupState[$projectState.selected];

        historyState.insert({
            name: "snap to events", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Snap to event start finished successfully", {classes: ['toast-success']});

    } catch (err) {
        console.log(err, err.message);
        toast.push("Snap to event start failed with message: " + err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }

}

function clearFormatting() {
    try {
        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/<em>|<\/em>|<u>|<\/u>|<strong>|<\/strong>/gmi, "");
            try {
                if (count === 0 && quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                    quillEditor.setSelection(0, quillEditor.getLength());
                    $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                    quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                }
            } catch (err) {
                console.log(err.message);
            }
        });

        historyState.insert({
            name: "clear formatting", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Clear formatting completed successfully", {classes: ['toast-success']});
    } catch (err) {
        console.log(err, err.message);
        toast.push(err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function boldEvent() {
    try {
        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(?<=>)\s/g, "");

            if ($eventGroupState[$projectState.selected].events[eventIndex].text.match(/<strong>/)) {
                $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/<strong>|<\/strong>/gmi, "");
            } else {
                $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(?<=>)(?!<|$)/gmi, "<strong>").replace(/(?=<\/p)/gmi, "</strong>");
            }

            try {
                if (count === 0 && quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                    quillEditor.setSelection(0, quillEditor.getLength());
                    $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                    quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                }
            } catch (err) {
                console.log(err.message);
            }
        });

        historyState.insert({
            name: "bold event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Bold event text", {classes: ['toast-success']});
    } catch (err) {
        console.log(err, err.message);
        toast.push(err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function underlineEvent() {
    try {
        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(?<=>)\s/g, "");

            if ($eventGroupState[$projectState.selected].events[eventIndex].text.match(/<u>/)) {
                $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/<u>|<\/u>/gmi, "");
            } else {
                $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(?<=>)(?!<|$)/gmi, "<u>").replace(/(?=<\/p)/gmi, "</u>");
            }
            try {
                if (count === 0 && quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                    quillEditor.setSelection(0, quillEditor.getLength());
                    $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                    quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                }
            } catch (err) {
                console.log(err.message);
            }
        });

        historyState.insert({
            name: "underline event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Underline event text", {classes: ['toast-success']});
    } catch (err) {
        console.log(err, err.message);
        toast.push(err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function italicizeEvent() {
    try {
        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(?<=>)\s/g, "");

            if ($eventGroupState[$projectState.selected].events[eventIndex].text.match(/<em>/)) {
                $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/<em>|<\/em>/gmi, "");
            } else {
                $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(?<=>)(?!<|$)/gmi, "<em>").replace(/(?=<\/p)/gmi, "</em>");
            }

            try {
                if (count === 0 && quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                    quillEditor.setSelection(0, quillEditor.getLength());
                    $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                    quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                }
            } catch (err) {
                console.log(err.message);
            }
        });

        historyState.insert({
            name: "italicize event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Italicize event text", {classes: ['toast-success']});
    } catch (err) {
        console.log(err, err.message);
        toast.push(err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function fixRtlEventText(){
    try {
        if ($eventGroupState[$projectState.selected].selected.length === 0){
            throw new Error("No Events are selected. Please select one or more Events first");
        }

        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            let plainText = convertToPlainText($eventGroupState[$projectState.selected].events[eventIndex].text);
            let correctedText = fixRtlText(plainText);
            $eventGroupState[$projectState.selected].events[eventIndex].text = convertToHtml(correctedText); 
            

            try {
                if (count === 0 && quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                    quillEditor.setSelection(0, quillEditor.getLength());
                    $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                    quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                }
            } catch (err) {
                console.log(err.message);
            }
        });

        historyState.insert({
            name: "Fix RTL Text", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("RTL text has been fixed.", {classes: ['toast-success']});
    } catch (err){
        console.log(err, err.message);
        toast.push(err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
}

function reverseRtlStartAndEnd(toStart){
    try {
        if ($eventGroupState[$projectState.selected].selected.length === 0){
            throw new Error("No Events are selected. Please select one or more Events first");
        }

        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            let plainText = convertToPlainText($eventGroupState[$projectState.selected].events[eventIndex].text);
            let correctedText = reverseRtlStartEnd(plainText, toStart);
            $eventGroupState[$projectState.selected].events[eventIndex].text = convertToHtml(correctedText); 
            

            try {
                if (count === 0 && quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                    quillEditor.setSelection(0, quillEditor.getLength());
                    $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                    quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                }
            } catch (err) {
                console.log(err.message);
            }
        });

        historyState.insert({
            name: "Fix RTL Text", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("RTL text has been fixed.", {classes: ['toast-success']});
    } catch (err){
        console.log(err, err.message);
        toast.push(err.message, {classes: ['toast-danger']});
    } finally {
        toggleMenu();
    }
} 


function convertToUpperCase(){
    //convert the text property of all selected events to uppercase
    if (!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0){
        return;
    }

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
        $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.toUpperCase();
        try {
            if (count === 0 && quillEditor) {
                quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                quillEditor.setSelection(0, quillEditor.getLength());
                $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                quillEditor.setSelection(quillEditor.getLength(), 0);
            }
        } catch (err) {
            console.log(err.message);
        }

        historyState.insert({
            name: "convert to upper case", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    });
}

function convertToLowerCase(){
    if (!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0){
        return;
    }

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
        $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.toLowerCase();
        try {
            if (count === 0 && quillEditor) {
                quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                quillEditor.setSelection(0, quillEditor.getLength());
                $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                quillEditor.setSelection(quillEditor.getLength(), 0);
            }
        } catch (err) {
            console.log(err.message);
        }

        historyState.insert({
            name: "convert to lower case", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    });
}

function convertToTitleCase(){
    if (!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0){
        return;
    }

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
        $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.toLowerCase().replace(/\b\w/g, match => match.toUpperCase());

        try {
            if (count === 0 && quillEditor) {
                quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                quillEditor.setSelection(0, quillEditor.getLength());
                $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                quillEditor.setSelection(quillEditor.getLength(), 0);
            }
        } catch (err) {
            console.log(err.message);
        }

        historyState.insert({
            name: "convert to title case", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    });
}

function convertToSentenceCase(){
    if (!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0){
        return;
    }

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {

        //convert the text property of all selected events to sentence case. For example `<p class="ql-align-center">play dungeons and dragons.</p>` should convert to `<p class="ql-align-center">Play dungeons and dragons.</p>`

        $eventGroupState[$projectState.selected].events[eventIndex].text = convertToHtml(convertToPlainTextCustom($eventGroupState[$projectState.selected].events[eventIndex].text).toLowerCase().replace(/(^\w|([.!?])\s\w)/g, match => match.toUpperCase()));

        try {
            if (count === 0 && quillEditor) {
                quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                quillEditor.setSelection(0, quillEditor.getLength());
                $eventGroupState[$projectState.selected].rtl ? quillEditor.format('direction', 'rtl') : quillEditor.format('direction', false);
                quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                quillEditor.setSelection(quillEditor.getLength(), 0);
            }
        } catch (err) {
            console.log(err.message);
        }

        historyState.insert({
            name: "convert to title case", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    });
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}">
    <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/">Format</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}">
        <!-- Bold -->
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click="{boldEvent}" title="Bold Selected Events"><i class="bi bi-type-bold"></i> Bold</a>
        <!-- Underline-->
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click="{underlineEvent}" title="Underline Selected Events"><i class="bi bi-type-underline"></i> Underline</a>
        <!-- Italics -->
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click="{italicizeEvent}" title="Italicize Selected Events"><i class="bi bi-type-italic"></i> Italics</a>
        <!-- Clear Formatting -->
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click="{clearFormatting}" title="Clear formatting of selected event's text"><i class="bi bi-fonts" style="margin-right:-5%"></i><sub><i class="bi bi-x"></i></sub> Clear Formatting</a>
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("autoFormat")}>Auto Format Custom...</a>
        
        <a class="dropdown-item" href="#!/" on:click="{() => modalState.showModal("snapToShotChanges")}" title="Snap event start and end times to shot changes">Snap to Shot Changes</a>
        <a class="dropdown-item" href="#!/" on:click="{snapToEventStarts}" title="Snap event ends times to the start of the next event when within 0.5 seconds">Snap to Event Start</a>
        <a class="dropdown-item" href="#!/" on:click="{snapToEventEnds}" title="Snap event start times to the end of the previous event when within 0.5 seconds">Snap to Event End</a>
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click="{()=> fixEventOverlap($projectState.selected)}">Fix Event Overlap</a>
        <a class="dropdown-item" href="#!/" on:click="{()=> orderByTime($projectState.selected)}">Order Events by Time</a>
        <hr class="dropdown-divider">
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click={() => modalState.showModal("position")}>Offset Position...</a>
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click={() => modalState.showModal("captionStyle")}>Caption Style...</a>
        <!-- Text Submenu -->
        <hr class="dropdown-divider">
        <a on:click={toggleTextMenu} class="dropdown-item dropdown-toggle" href="#!/">Text</a>
        {#if textMenu}
        <div transition:slide="{{duration: 400, easing: quintOut }}">
            <a on:click={() => spellNumbers()} class="dropdown-item bg-secondary" href="#!/">Spell #1...10</a>
            <a on:click={() => removeRepeatWords()} class="dropdown-item bg-secondary" href="#!/">Remove Repeat Words</a>
            <a on:click={() => replaceWithEllipses()} class="dropdown-item bg-secondary" href="#!/">Replace ..+ with Ellipses</a>
            <hr class="dropdown-divider m-0">
            <a on:click={() => fixRtlEventText()} class="dropdown-item bg-secondary" href="#!/">Fix RTL Text</a>
            <a on:click={() => reverseRtlStartAndEnd(false)} class="dropdown-item bg-secondary" href="#!/">Reverse RTL Start to End</a>
            <a on:click={() => reverseRtlStartAndEnd(true)} class="dropdown-item bg-secondary" href="#!/">Reverse RTL End to Start</a>
            <hr class="dropdown-divider m-0">
            <a on:click={() => convertToUpperCase()} class="dropdown-item bg-secondary" href="#!/">Text to Upper Case</a>
            <a on:click={() => convertToLowerCase()} class="dropdown-item bg-secondary" href="#!/">Text to Lower Case</a>
            <a on:click={() => convertToTitleCase()} class="dropdown-item bg-secondary" href="#!/">Text to Title Case</a>
            <a on:click={() => convertToSentenceCase()} class="dropdown-item bg-secondary" href="#!/">Text to Sentence Case</a>
        </div>
        {/if}
    </div>
</li>

<style>
.bg-secondary:hover {
    background-color: #366597 !important;
}
</style>
