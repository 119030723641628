import _getShortestLine from "../utility/getShortestLine.js";
import _getLongestLine from "../utility/getLongestLine.js";
import _findCenter from "../utility/findCenter.js";
import _eol from "eol";
import _stripTags from "../quill/stripTags.js";
import _convertToPlainText from "../quill/convertToPlainText.js";
import _convertToPlainTextCustom from "../quill/convertToPlainTextCustom.js";
import _quillClasses from "../../dict/quillClasses.js";
import _convertToHtml from "../quill/convertToHtml.js";
import _Event from "../../classes/event.js";
import _vchipLookup from "../../dict/vChipRatings.js";
import _sccLookup from "../../dict/608.js";
const sccLookup = _sccLookup;
const vchipLookup = _vchipLookup;
const Event = _Event;
const convertToHtml = _convertToHtml;
const quillClasses = _quillClasses;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const convertToPlainText = _convertToPlainText;
const stripTags = _stripTags;
const eol = _eol;
const findCenter = _findCenter;
const getLongestLine = _getLongestLine;
const getShortestLine = _getShortestLine;
export default {
  colorMapping: {
    "WHITE": "#FFFFFF",
    "GREEN": "#008000",
    "BLUE": "#0000FF",
    "CYAN": "#00FFFF",
    "RED": "#FF0000",
    "YELLOW": "#FFFF00",
    "MAGENTA": "#FF00FF",
    "BLACK": "#000000",
    "NONE": ""
  },
  Display: class {
    constructor(options = {
      style: "Pop-On",
      start: false,
      end: false,
      lines: []
    }) {
      this.style = options.style || "Pop-On", this.start = options.start || false, this.end = options.end || false, this.lines = options.lines || [];
    }
    insertLine(options = {
      text: "",
      xPos: 1,
      yPos: 1,
      italics: false,
      underline: false,
      bold: false,
      color: "#FFFFFF",
      background: "#000000",
      opacity: 1
    }) {
      this.lines.push({
        text: options.text || "",
        xPos: options.xPos,
        //0-31
        yPos: options.yPos,
        //0-14 
        italics: options.italics,
        underline: options.underline,
        bold: options.bold,
        color: options.color || "#FFFFFF",
        background: options.background || "#000000",
        opacity: options.opacity || 1
      });
    }
  },
  decodeDisplay: function (display, win) {
    let ccEvent = new Event({
      style: display.style,
      start: display.start,
      end: display.end
    });

    //console.log(display);
    let position = this.getPositionFromDisplay(JSON.parse(JSON.stringify(display.lines)), win);
    ccEvent.text = convertToHtml(this.getTextFromDisplay(display.lines), [quillClasses.align[position.alignment]]);
    if (!ccEvent.text) {
      return false;
    }
    ccEvent.alignment = position.alignment;
    ccEvent.xPos = position.xPos;
    ccEvent.yPos = position.yPos;
    ccEvent.xOffset = position.xOffset;
    ccEvent.yOffset = position.yOffset;
    return ccEvent;
  },
  getTextFromDisplay: function (lines) {
    if (lines.length === 0) {
      return false;
    }
    let text = "",
      yPos;
    lines.filter(line => {
      return line.text.trim();
    }).sort((lineA, lineB) => {
      return lineA.yPos - lineB.yPos || lineA.xPos - lineB.xPos;
    }).forEach(line => {
      if (line.yPos === yPos) {
        text += " " + line.text;
      } else {
        text += "\n" + line.text;
      }
      if (line.text.match(/<em>/) && (line.text.match(/<em>/g) || []).length !== (line.text.match(/<\/em>/g) || []).length) {
        text += "</em>";
      }
      if (line.text.match(/<u>/) && (line.text.match(/<u>/g) || []).length !== (line.text.match(/<\/u>/g) || []).length) {
        text += "</u>";
      }
      yPos = line.yPos;
    });
    return text.trim();
  },
  getPositionFromDisplay: function (lines, win) {
    let position = {
        xPos: "center",
        yPos: "end",
        xOffset: 0,
        yOffset: win.height * -0.10,
        alignment: "center"
      },
      details;

    /* Sort */
    lines = lines.filter(line => {
      return line.text;
    }).sort((lineA, lineB) => {
      return lineA.yPos - lineB.yPos || lineA.xPos - lineB.xPos;
    });
    lines = this.organizeLines(lines);
    details = this.getHorzDetails(lines);

    //console.log("--------------");
    // console.log(lines);
    //console.log(details);

    if (details.length === 0) {
      return position;
    }
    position.alignment = this.getAlignmentFromDetails(details);
    let highestYPos = Math.max.apply(Math, lines.map(function (line) {
      return line.yPos;
    }));
    let lowestYPos = Math.min.apply(Math, lines.map(function (line) {
      return line.yPos;
    }));
    let lowestXPos = Math.min.apply(Math, lines.map(function (line) {
      return line.xPos;
    }));
    let highestEnd = Math.max.apply(Math, details.map(function (detail) {
      return detail.end;
    }));

    /* Calculate Y (vertical) Position */
    if (lowestYPos > 10) {
      position.yPos = "end";
      position.yOffset = (18 - (highestYPos + 2)) / -19 * win.height;
    } else if (lowestYPos < 6) {
      position.yPos = "start";
      position.yOffset = (lowestYPos + 2) / 19 * win.height;
    } else {
      position.yPos = "center";
      position.yOffset = (lowestYPos + 2 - findCenter(19, lines.length)) / 19 * win.height;
    }

    /* Calculate X (horizontal) Position */
    if (position.alignment === "left") {
      if (lowestXPos > 13) {
        position.xPos = "end";
        position.xOffset = (40 - (highestEnd + 5)) / 40 * win.width;
      } else {
        position.xPos = "start";
        position.xOffset = (lowestXPos + 4) / 40 * win.width;
      }
    } else if (position.alignment === "center") {
      position.xPos = "center";
      position.xOffset = 0;
    } else {
      if (lowestXPos > 13) {
        position.xPos = "end";
        position.xOffset = (40 - (highestEnd + 5)) / 40 * win.width;
      } else {
        position.xPos = "start";
        position.xOffset = (lowestXPos + 5) / 40 * win.width;
      }
    }

    // console.log("---------------")
    // console.log("Metrics:");
    // console.log(JSON.stringify({
    //     "LowestY" : lowestYPos,
    //     "HighestY" : highestYPos,
    //     "LowestX" : lowestXPos,
    //     "HighestEnd" : highestEnd,
    // },null,4))

    // console.log("Lines:");
    // console.log(lines);

    // console.log("Details:");
    // console.log(details);

    // console.log("Position:")
    // console.log(JSON.stringify(position, 4));
    return position;
  },
  organizeLines: function (lines) {
    let sameLineTest = true,
      yPos;
    while (sameLineTest) {
      sameLineTest = false;
      yPos = undefined;
      lines.forEach((line, index, lines) => {
        lines[index].text = lines[index].text.replace(/<em>|<u>/g, "");
        if (line.yPos === yPos) {
          lines[index - 1].text += " " + line.text.trim();
          lines[index].text = "";
          sameLineTest = true;
        } else {
          yPos = line.yPos;
        }
      });
      lines = lines.filter(line => {
        return line.text;
      });
    }
    return lines;
  },
  getHorzDetails: function (lines) {
    //console.log(lines);
    return lines.map(line => {
      let alignment;
      sccLookup.specialCharsFilter.forEach(specialChar => {
        let regexPattern = new RegExp(`.(?<!>)\\` + specialChar, "g");
        line.text = line.text.replace(regexPattern, specialChar);
        line.text = this.replaceMusicNotes(line.text);
      });

      // console.log(line.text);
      let text = line.text;
      let center = findCenter(32, text.length);
      if (line.xPos >= center - 2.5 && line.xPos <= center + 2.5) {
        alignment = "center";
      } else if (line.xPos > center) {
        alignment = "right";
      } else {
        alignment = "left";
      }
      return {
        text: line.text,
        xPos: line.xPos,
        length: text.length,
        alignment: alignment,
        end: line.xPos + text.length
      };
    });
  },
  getAlignmentFromDetails: function (details) {
    //console.log(details);
    if (details.length === 1) {
      return details[0].alignment;
    }
    let xPosSame = details.every(detail => {
      return detail.xPos === details[0].xPos;
    });
    let endSame = details.every(detail => {
      return detail.end === details[0].end;
    });
    let alignmentCenter = details.every(detail => {
      return detail.alignment === "center";
    });
    if (alignmentCenter) {
      return "center";
    } else if (xPosSame) {
      return "left";
    } else if (endSame) {
      return "right";
    } else {
      return "center";
    }
  },
  getEventDetails: function (event) {
    let eventTextPlainFormat = convertToPlainTextCustom(event.text);
    let eventTextPlain = convertToPlainText(event.text);
    let longestLine = getLongestLine(eventTextPlain);
    let shortestLine = getShortestLine(eventTextPlain);
    let details = {
      text: eventTextPlainFormat,
      lines: eol.split(eventTextPlainFormat),
      plainText: eventTextPlain,
      longestLine: longestLine,
      shortestLine: shortestLine,
      alignment: event.alignment,
      xPos: event.xPos,
      yPos: event.yPos,
      xOffset: event.xOffset,
      yOffset: event.yOffset,
      style: event.style,
      start: event.start,
      end: event.end
    };
    //console.log(details);
    return details;
    /* NOTES:
        xOffset : parseInt((event.xOffset/win.width)*100),
        yOffset : parseInt((event.yOffset/win.height)*100) 
    */
  },
  getCodeByCmd: function (codes, cmd) {
    let sccCode = Object.keys(codes).find(code => codes[code] === cmd);
    if (!sccCode) {
      if (cmd.charCodeAt(0) === 8217) {
        return "a7";
      }
    }
    return sccCode || "80";
  },
  replaceSpecialChars: function (text) {
    return text.replace(/À/g, "AÀ").replace(/Â/g, "AÂ").replace(/Ç/g, "CÇ").replace(/É/g, "EÉ").replace(/È/g, "EÈ").replace(/Ê/g, "EÊ").replace(/Ë/g, "EË").replace(/ë/g, "eë").replace(/Î/g, "IÎ").replace(/Ï/g, "IÏ").replace(/ï/g, "iï").replace(/Ô/g, "OÔ").replace(/Ù/g, "UÙ").replace(/ù/g, "uù").replace(/Û/g, "UÛ").replace(/Ú/g, "UÚ").replace(/Ã/g, "AÃ").replace(/ã/g, "aã").replace(/Í/g, "IÍ").replace(/Ì/g, "IÌ").replace(/ì/g, "iì").replace(/Ò/g, "oÒ").replace(/ò/g, "oò").replace(/Õ/g, "OÕ").replace(/õ/g, "oõ").replace(/Ä/g, "AÄ").replace(/¿/g, "?¿").replace(/ä/g, "aä").replace(/ü/g, "uü").replace(/Ü/g, "UÜ").replace(/Ö/g, "OÖ").replace(/ö/g, "oö").replace(/Å/g, "AÅ").replace(/å/g, "aå").replace(/‘/g, "'‘").replace(/♪/g, "♪♪").replace(/♪♪♪♪/g, "♪♪♪");
  },
  getSccPosition: function (textLine, details, lineIndex, win) {
    let position = {
      posCode: false,
      tabCode: false
    };
    let xPos = this.getXPos(textLine, details, win);
    let yPos = this.getYPos(stripTags(textLine), details, lineIndex, win);
    let tabs = xPos % 4;
    let column = xPos - tabs;

    //console.log("Col:",column,tabs,xPos);
    column = column > 0 ? column - 1 : column;
    column = column.toString().padStart(2, '0');
    position.tabCode = "{TAB0" + tabs + "}";
    position.posCode = "{" + yPos.toString().padStart(2, '0') + "_" + column.toString().padStart(2, '0') + "}";
    //console.log(position);
    return position;
  },
  getXPos: function (textLine, details, win) {
    let xPos = 0;
    let italics = textLine.match(/<em>/g);
    let textLineStripped = stripTags(textLine);
    //console.log("-------")
    //console.log(textLineStripped, italics);
    //console.log(details);
    if (details.xPos === "start") {
      if (details.alignment === "center") {
        xPos = findCenter(details.longestLine.length, textLineStripped.length) + (details.xOffset / win.width * 40 - 4);
      } else if (details.alignment === "right") {
        xPos = details.longestLine.length - textLineStripped.length + (details.xOffset / win.width * 40 - 4) - (italics ? 1 : 0);
      } else {
        /* Left Alignment */
        xPos = parseInt(details.xOffset / win.width * 40 - 5) - (italics ? 1 : 0);
      }
    } else if (details.xPos === "center") {
      //console.log(textLineStripped, italics, textLineStripped.length, details);
      if (details.alignment === "left") {
        xPos = findCenter(32, details.longestLine.length) + details.xOffset / win.width * 40;
      } else if (details.alignment === "right") {
        xPos = findCenter(32, details.longestLine.length) + details.xOffset / win.width * 40 + (details.longestLine.length - textLineStripped.length);
      } else {
        xPos = findCenter(32, textLineStripped.length) + details.xOffset / win.width * 40;
      }
      //console.log(xPos);
    } else {
      /* xPos = end */
      if (details.alignment === "center") {
        xPos = 32 - (details.xOffset / win.width * -40 - 4 + details.longestLine.length) + findCenter(details.longestLine.length, textLineStripped.length);
      } else if (details.alignment === "right") {
        let deltaLineLength = details.longestLine.length - textLineStripped.length;
        //console.log("Delta Line Length",deltaLineLength);
        xPos = 32 - (details.xOffset / win.width * -40 - 4 + details.longestLine.length) + (deltaLineLength - (italics ? 1 : 0));
      } else {
        /* Left Alignment */
        xPos = 32 - (italics ? details.longestLine.length + 1 : details.longestLine.length) + (details.xOffset / win.width * 40 + 4);
      }
    }

    //console.log(xPos,textLineStripped, textLineStripped.length);

    /* Safety check to make sure line doesn't go off screen */
    if (xPos + textLineStripped.length > 32) {
      xPos = 32 - (textLineStripped.length + (italics ? 2 : 0));
    }

    /* Safety check to make sure line doesn't start before screen */
    xPos = Math.max(0, xPos);
    return parseInt(xPos);
  },
  getYPos: function (textLine, details, lineIndex, win) {
    /* Total Height of Picture is 19 boxes (15 + 4 padding) */
    //console.log("--------");
    let yPos = 0,
      offset = Math.round(details.yOffset / win.height * 19);
    //console.log(textLine, details, lineIndex, win, offset);
    if (details.yPos === "start") {
      yPos = lineIndex + offset - 2;
    } else if (details.yPos === "center") {
      yPos = parseInt(findCenter(15, details.lines.length)) + lineIndex;
    } else {
      /* End */
      yPos = 17 + offset - (details.lines.length - lineIndex);
    }

    //console.log("yPos "+yPos, offset);

    /* Check to make sure line isn't past where it should be */
    if (14 - (details.lines.length - lineIndex) < yPos) {
      yPos = 15 - (details.lines.length - lineIndex);
    } else if (lineIndex > yPos) {
      yPos = lineIndex;
    }
    yPos = parseInt(yPos);
    //console.log("yPos "+yPos, offset);
    /* Safety check to make sure line doesn't go off screen */
    if (yPos > 14) {
      yPos = 14;
    }
    yPos = Math.max(0, yPos);
    return yPos;
  },
  getPositionCodes: function (textLine, details, lineIndex, channel, win) {
    //console.log(details, lineIndex);
    let positionCodes = [];
    if (details.style === "Roll-Up 2") {
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP2}"));
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
      let position = this.getSccPosition(textLine, details, lineIndex, win);
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
    } else if (details.style === "Roll-Up 3") {
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP3}"));
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
      let position = this.getSccPosition(textLine, details, lineIndex, win);
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
    } else if (details.style === "Roll-Up 4") {
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP4}"));
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
      let position = this.getSccPosition(textLine, details, lineIndex, win);
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
    } else if (details.style === "Paint-On") {
      if (lineIndex === 0) {
        positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}"));
        positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{PAINT ON}"));
      }
      let position = this.getSccPosition(textLine, details, lineIndex, win);
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
      if (position.tabCode !== "{TAB00}") {
        positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.tabCode));
      }
    } else {
      /* Pop-On */
      //console.log(details);
      if (lineIndex === 0) {
        positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{RESUME LOADING}"));
        //positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}"));
      }
      let position = this.getSccPosition(textLine, details, lineIndex, win);
      // console.log(position,details);
      positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
      if (position.tabCode !== "{TAB00}") {
        positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.tabCode));
      }
    }
    //console.log(positionCodes);
    return positionCodes;
  },
  encodeEvent: function (details, channel = "ch01", win) {
    let cmds = [];
    //console.log("-----");
    //console.log(details);
    details.lines.forEach((line, index) => {
      //line = this.replaceSpecialChars(line);
      let positionCmds = this.getPositionCodes(line, details, index, channel, win);
      //console.log(line, positionCmds, win);
      positionCmds.forEach(cmd => {
        cmds.push(cmd);
      });
      let chars = line.split("");
      while (chars.length > 0) {
        let char = chars.shift();
        if (char === "<") {
          //console.log(chars, cmds);
          if (this.isUnderlineItalicOpenTag(chars)) {
            chars.splice(0, 6);
            cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS_UNDERLINE}"));
          } else if (this.isStrongOpenTag(chars)) {
            chars.splice(0, 7);
            cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS}"));
          } else if (this.isItalicsOpenTag(chars)) {
            /* Remove Whitespace Before Format Tag */
            //console.log(cmds);
            if (cmds.length > 0 && cmds[cmds.length - 1] === this.getCodeByCmd(sccLookup[channel], " ")) {
              cmds.pop();
              cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS}"));
            } else if (cmds.length > 0 && this.getCodeByCmd(sccLookup[channel], sccLookup[channel][cmds[cmds.length - 1]] + "{ITALICS}") != "80") {
              cmds.push(this.getCodeByCmd(sccLookup[channel], sccLookup[channel][cmds.pop()] + "{ITALICS}"));
            } else {
              cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS}"));
            }
            chars.splice(0, 3);
          } else if (this.isUnderlineOpenTag(chars)) {
            /* Remove Whitespace Before Format Tag */
            if (cmds.length > 0 && cmds[cmds.length - 1] === this.getCodeByCmd(sccLookup[channel], " ")) {
              cmds.pop();
            }
            chars.splice(0, 2);
            cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE;UNDERLINE}"));
          } else if (this.isUnderlineItalicCloseTag(chars)) {
            chars.splice(0, 8);
            cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
          } else if (this.isStrongCloseTag(chars)) {
            chars.splice(0, 8);
            cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
          } else if (this.isItalicsCloseTag(chars)) {
            if (chars.length > 4) {
              cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
            }
            chars.splice(0, 4);
          } else if (this.isUnderlineCloseTag(chars)) {
            chars.splice(0, 3);
            cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
          } else {
            cmds.push(this.getCodeByCmd(sccLookup[channel], char));
          }
        } else {
          cmds.push(this.getCodeByCmd(sccLookup[channel], char));
        }
      }
    });
    return cmds;
  },
  formatEncodedCmds: function (encodedCmds) {
    let cmdString = "";
    for (let i = 0; i < encodedCmds.length; i++) {
      if (encodedCmds[i].length === 4) {
        cmdString += " " + encodedCmds[i];
      } else if (encodedCmds[i + 1] && encodedCmds[i].length === 2 && encodedCmds[i + 1].length === 2) {
        cmdString += " " + encodedCmds[i] + encodedCmds[++i];
      } else if (encodedCmds[i].length === 2 && (!encodedCmds[i + 1] || encodedCmds[i + 1].length === 4)) {
        cmdString += " " + encodedCmds[i] + "80";
      }
    }
    return cmdString.trim();
  },
  calculateEncodeTime: function (encodedTextString) {
    let encodeTime = 0;
    encodedTextString.split(" ").forEach(code => {
      if (sccLookup.all[code]) {
        encodeTime += 0.75;
      } else {
        encodeTime += 1;
      }
    });
    return Math.round(encodeTime);
  },
  verifyFormatting: function (event, win) {
    let result = true;
    //console.log("------");

    let plainText = convertToPlainText(event.text);
    //console.log(plainText);

    //let text = event.text.replace(/<em>/g,"i").replace(/<\/em>/g,"").replace(/<u>/g,"u").replace(/<\/u>/g,"").replace(/<b>/g,"b").replace(/<\/b>/g,"");

    eol.split(plainText).forEach(textLine => {
      //console.log(textLine, textLine.length, event.xOffset, xOffset,textLine.length + xOffset + 4);     
      if (textLine.length > 32) {
        result = false;
      }
    });
    return result;
  },
  replaceMusicNotes: function (line) {
    let matches = line.match(/♪+/g);
    if (!matches) {
      return line;
    }
    ;
    matches.forEach(noteMatch => {
      let regexPat = new RegExp(noteMatch);
      let musicNotes = Math.ceil(noteMatch.length / 2);
      line = line.replace(regexPat, "♪".repeat(musicNotes));
    });
    return line;
  },
  duplicateMusicNotes: function (line) {
    //Duplicate the number of musical notes by 2. Subtract 1 if the number of musical notes is > 1. For example, if there are two musical notes we would need to add 3 (instead of 4). But if there is 1 musical note we would need to add 2. 
    let matches = line.match(/♪+/g);
    if (!matches) {
      return line;
    }
    ;
    matches.forEach(noteMatch => {
      let regexPat = new RegExp(noteMatch);
      let musicNotes = noteMatch.length * 2;
      line = line.replace(regexPat, "♪".repeat(musicNotes - (musicNotes > 2 ? 1 : 0)));
    });
    return line;
  },
  isStrongOpenTag: function (chars) {
    return chars.slice(0, 7).join("") === "strong>";
  },
  isItalicsOpenTag: function (chars) {
    return chars.slice(0, 3).join("") === "em>";
  },
  isUnderlineOpenTag: function (chars) {
    return chars.slice(0, 2).join("") === "u>";
  },
  isUnderlineItalicOpenTag: function (chars) {
    return chars.slice(0, 6).join("") === "em><u>" || chars.slice(0, 6).join("") === "u><em>";
  },
  isStrongCloseTag: function (chars) {
    return chars.slice(0, 8).join("") === "/strong>";
  },
  isItalicsCloseTag: function (chars) {
    return chars.slice(0, 4).join("") === "/em>";
  },
  isUnderlineCloseTag: function (chars) {
    return chars.slice(0, 3).join("") === "/u>";
  },
  isUnderlineItalicCloseTag: function (chars) {
    return chars.slice(0, 8).join("") === "/em></u>" || chars.slice(0, 8).join("") === "/u></em>";
  },
  /* XDS Functions */
  encodeProgramName: function (programName) {
    if (programName && programName.length > 0) {
      //only accept the first 32 characters of program name
      let programNameArray = ["01", "83"];
      let name = programName ? programName.substring(0, 32) : "Unknown";
      let chars = name.split("");
      if (chars.length % 2 !== 0) {
        chars.push(" ");
      }
      ;
      chars.forEach(char => {
        programNameArray.push(this.getCodeByCmd(sccLookup["ch01"], char));
      });
      programNameArray.push("8f");
      programNameArray.push(this.calculateChecksum(programNameArray));
      //console.log(programName, programNameArray);
      return programNameArray;
    } else {
      return [];
    }
  },
  encodeProgramLength: function (hours, minutes) {
    if (!isNaN(hours) && !isNaN(minutes)) {
      let programHoursHex = hours ? parseInt(hours).toString(16).padStart(2, '0') : "00";
      let programMinutesHex = minutes ? parseInt(minutes).toString(16).padStart(2, '0') : "00";

      //Add 0x40 to the hex value of the hours and minutes
      let programHours = (parseInt("0x" + programHoursHex) + 0x40).toString(16);
      let programMinutes = (parseInt("0x" + programMinutesHex) + 0x40).toString(16);
      let programLengthArray = ["01", "02", programMinutes, programHours, "4040", "4040", "8f"];
      programLengthArray.push(this.calculateChecksum(programLengthArray));
      return programLengthArray;
    } else {
      return [];
    }
  },
  encodeVChipInfo: function (ratingType, rating, contentDescriptions) {
    if (ratingType && rating) {
      //console.log(ratingType, rating, contentDescriptions)
      let vChipInfo = ["01", "85"];
      let vChipCode = vchipLookup.ratingsMap[ratingType][rating];
      vChipInfo = [...vChipInfo, ...vChipCode, "8f"];
      vChipInfo.push(this.calculateChecksum(vChipInfo));
      //console.log(vChipInfo);
      return vChipInfo;
    } else {
      return [];
    }
  },
  encodeProgramType: function (keywords) {
    if (keywords.length > 0) {
      let programTypeArray = ["01", "04"];
      keywords.forEach(keyword => {
        let programCode = sccLookup.xds.keywordGroupMap[keyword] || "26";
        programTypeArray.push(programCode);
      });
      programTypeArray.push("8f");
      programTypeArray.push(this.calculateChecksum(programTypeArray));
      return programTypeArray;
    } else {
      return [];
    }
  },
  encodeAfdInfo: function (afd) {
    return ["0189"];
  },
  calculateChecksum: function (hexArray) {
    // Convert hexadecimal strings to decimal numbers and sum them up
    const sum = hexArray.reduce((acc, hex) => acc + parseInt(hex, 16), 0);
    // Calculate the adjustment needed for divisibility by 0x80
    const adjustment = 0x80 - sum % 0x80;
    // Calculate the checksum value
    const checksum = adjustment & 0xFF; // Ensure checksum is in the range 0-255

    //Return 2-digit checksum hex value 
    return checksum.toString(16).toUpperCase().padStart(2, "0"); // Convert checksum to hexadecimal string
  },
  // loop over all of the events in each event group and add up the number of events where processed = false;
  getNumberOfEventsToProcess: function (eventGroupChannelMappings) {
    let eventsToProcess = 0;
    Object.keys(eventGroupChannelMappings).forEach(ch => {
      if (!eventGroupChannelMappings[ch]) {
        return;
      }
      eventGroupChannelMappings[ch].events.forEach(event => {
        if (!event.processed) {
          eventsToProcess++;
        }
      });
    });
    return eventsToProcess;
  },
  getEventsToProcess: function (eventGroupChannelMappings, frameCount) {
    let eventsToProcess = [];
    Object.keys(eventGroupChannelMappings).forEach(ch => {
      if (!eventGroupChannelMappings[ch]) {
        return;
      }

      //Find the next event to process (processed === false), and check that the event.encodeStartTime is => frameCount.
      eventGroupChannelMappings[ch].events.find(event => {
        if (!event.processed && event.encodeStartTime <= frameCount) {
          eventsToProcess.push(event);
          return true;
        }
      });
    });
    return eventsToProcess;
  },
  //Loop over the ccChannelStatus array which stores objects for each channel (1-4). Each object has a screen and buffer property that will store the event currently on screen and currently in the buffer. If there is a screen event, check if the event endFrame property === frameCount (if it does match then we need to clear it off the screen). We also need to check if the buffer needs to be displayed. 
  getDisplayOrClearCommands: function (ccChannelStatus, frameCount) {
    let displayCommands = {
      "dtv": {
        "programA": [],
        "programB": [],
        "programC": [],
        "programD": [],
        "programE": [],
        "programF": []
      },
      "atv": {
        "ch01": [],
        "ch02": [],
        "ch03": [],
        "ch04": []
      },
      "scc": []
    };
    ccChannelStatus.forEach((channel, index, channels) => {
      //console.log(channel.id, (channel.buffer ? channel.buffer.startFrame : ''), frameCount);
      if (channel.buffer && channel.buffer.startFrame <= frameCount) {
        //console.log("DISPLAYING BUFFER for channel", channel.id, frameCount);
        if (channel.id.includes("ch0")) {
          displayCommands.scc.push(this.getCodeByCmd(sccLookup[channel.id], "{DISPLAY BUFFER}"));
          displayCommands.scc.push(this.getCodeByCmd(sccLookup[channel.id], "{CLEAR BUFFER}"));
          displayCommands.atv[channel.id].push(this.getCodeByCmd(sccLookup[channel.id], "{DISPLAY BUFFER}"));
          displayCommands.atv[channel.id].push(this.getCodeByCmd(sccLookup[channel.id], "{CLEAR BUFFER}"));
        } else {
          if (channel.screen) {
            //console.log(`Clearing screen for ${channels[index].screen.dtvWindow}`);
            /* Hide Windows */
            displayCommands.dtv[channel.id].push("8A");
            displayCommands.dtv[channel.id].push("FF");
            /* Clear the Window */
            displayCommands.dtv[channel.id].push("88");
            displayCommands.dtv[channel.id].push(channels[index].screen.dtvWindow ? "FE" : "FD");

            //console.log(`Toggle all windows`);
            /* Toggle Windows */
            displayCommands.dtv[channel.id].push("8B");
            displayCommands.dtv[channel.id].push("FF"); //Toggle all windows

            //console.log(`Deleting window for ${channels[index].screen.dtvWindow}`);
            /* Delete the Window */
            displayCommands.dtv[channel.id].push("8C");
            displayCommands.dtv[channel.id].push(channels[index].screen.dtvWindow ? "FE" : "FD");
          } else {
            //console.log(`Toggling window: ${channel.buffer.dtv}`);
            displayCommands.dtv[channel.id].push("8B");
            displayCommands.dtv[channel.id].push("FF");
          }
        }
        channels[index].screen = channel.buffer;
        channels[index].buffer = null;
      } else if (channel.screen && channel.screen.endFrame <= frameCount) {
        //console.log("CLEARING SCREEN for channel", channel.id, frameCount);
        if (channel.id.includes("ch0")) {
          displayCommands.scc.push(this.getCodeByCmd(sccLookup[channel.id], "{CLEAR DISPLAY}"));
          displayCommands.atv[channel.id].push(this.getCodeByCmd(sccLookup[channel.id], "{CLEAR DISPLAY}"));
        } else {
          //console.log(`Clearing screen for ${channels[index].screen.dtvWindow}`)
          /* Hide Windows */
          displayCommands.dtv[channel.id].push("8A");
          displayCommands.dtv[channel.id].push("FF");
          /* Clear the Window */
          displayCommands.dtv[channel.id].push("88");
          displayCommands.dtv[channel.id].push(channels[index].screen.dtvWindow ? "FE" : "FD");
          /* Delete the Window */
          //console.log("Deleting Window");
          displayCommands.dtv[channel.id].push("8c");
          displayCommands.dtv[channel.id].push(channels[index].screen.dtvWindow ? "FE" : "FD");
        }
        channels[index].screen = false;
        //console.log(displayCommands);
      }
    });
    return displayCommands;
  },
  //Check the ccChannelStatus to see if there are events stored in the buffer or screen that need to be shown, or cleared. To do this, loop over the ccChannelStatus array to check if there is a screen or buffer event. If there is a screen event, check to see if the event endFrame property <= frameCount. If it is, return true. We also need to check the buffer event to see if the startFrame property >= frameCount. If it is, return true (meaning we haven't shown it yet).
  eventsToDisplay: function (ccChannelStatus, frameCount) {
    /* console.log("-----------");
    console.log(ccChannelStatus); */
    let displayOrClear = false;
    ccChannelStatus.forEach(channel => {
      if (channel.screen && channel.screen.endFrame >= frameCount) {
        displayOrClear = true;
      }
      if (channel.buffer && channel.buffer.startFrame >= frameCount) {
        displayOrClear = true;
      }
    });
    return displayOrClear;
  }
};