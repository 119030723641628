import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _stripTags from "../functions/quill/stripTags.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
const getFormatOptions = _getFormatOptions;
const stripTags = _stripTags;
const convertToPlainText = _convertToPlainText;
export default {
  decode: function (input, options) {
    throw new Error("Text transcripts are not a supported source profile. Please select a different source profile to continue.");
  },
  encode: function (eventGroup, options) {
    let output = "",
      paragraphs = [],
      sentences = [],
      speaker = false;
    let encodingOptions = getFormatOptions(options.formatOptions);
    eventGroup.events.forEach((event, index, events) => {
      let text = (encodingOptions["Event Prefix"] ? encodingOptions["Event Prefix"].replace("$id", index) : "") + convertToPlainText(event.text, " ") + (encodingOptions["Event Suffix"] ? encodingOptions["Event Suffix"] : "");
      if (index > 0 && encodingOptions["Split On Event Gap"] && event.start >= events[index - 1].end + parseFloat(encodingOptions["Split On Event Gap"])) {
        paragraphs.push(sentences.join(" "));
        sentences = [text];
      } else {
        sentences.push(text);
      }
    });
    if (sentences.length > 0) {
      paragraphs.push(sentences.join(" "));
    }
    output = paragraphs.join("\n\n");

    // console.log(output);
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      eventGroup.events = eventGroup.events.filter(event => {
        return stripTags(event.text).trim() !== "";
      });
      return eventGroup;
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      return eventGroup;
    }
  }
};