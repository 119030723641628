<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    modalState
} from '@app/store/modalStore.js';

let useLocalStorage = $projectState.useLocalStorage;
let projectType = $projectState.type;
let name = $projectState.name;
let aspectRatio = $projectState.media.aspectRatio;
let description = $projectState.description;
let createdOn = new Date($projectState.created).toString();
let xPadding = $styleState.xPadding;
let yPadding = $styleState.yPadding;
let lineSpacing = $styleState.lineSpacing;
let projectDefaults = JSON.parse(localStorage.getItem("cc-project-defaults")) || {};

function saveChanges() {
    $projectState.type = projectType;
    $projectState.name = name;
    $projectState.description = description;
    $projectState.media.aspectRatio = aspectRatio;
    $projectState.useLocalStorage = useLocalStorage;
    $styleState.xPadding = xPadding;
    $styleState.yPadding = yPadding;
    $styleState.lineSpacing = lineSpacing;
    localStorage.setItem("cc-style-defaults", JSON.stringify($styleState));
    localStorage.setItem("cc-project-defaults", JSON.stringify({...projectDefaults, useLocalStorage : useLocalStorage}));

    historyState.insert({
        name: "project update", //action name
        eventGroup: false,
        snapshots: [{
            store: "projectState",
            value: JSON.stringify($projectState)
        }]
    });

    modalState.hideModal();
}
</script>

<form on:submit|preventDefault="{saveChanges}">   
    <div class="mb-3">
        <label class="form-label" for="Project Type">Project Type</label>
        <select class="form-select {$projectState.type === "team" ? 'bg-light' : ''}" bind:value={projectType} disabled="{$projectState.type === "team"}">
            <option>default</option>
            <option>team</option>
            <!-- <option>live</option> -->
        </select>
    </div>
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="useLocalStorageCheck" bind:checked={useLocalStorage}>
        <label class="form-check-label" for="useLocalStorageCheck">Use Local Storage <i class="bi bi-info-circle" title="Use local storage when saving project files"></i></label>
    </div>
    <hr class="bg-secondary mt-4"/>
    <div class="mb-3">
        <label class="form-label" for="Project Name">Project Name</label>
        <input class="form-control" type="text" bind:value={name}>
    </div>
    <div class="mb-3">
        <label class="form-label" for="Project Name">Project Description</label>
        <textarea class="form-control" bind:value={description}></textarea>
    </div>
    <div class="mb-3">
        <label class="form-label" for="Author">Created By</label>
        <input class="form-control bg-light" type="text" disabled readonly value="{$projectState.username}">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Created On Date">Created On</label>
        <input class="form-control bg-light" type="text" disabled readonly value="{createdOn}">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Frame Rate">Frame Rate <i class="bi bi-info-circle" title="The project frame rate can only be changed using the frame rate option under the Timecode menu"></i></label>
        <input class="form-control bg-light" type="text" disabled readonly value="{$projectState.frameRate}">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Aspect Ratio">Aspect Ratio</label>
        <select class="form-select" bind:value={aspectRatio}>
            <option>16:9</option>
            <option>4:3</option>
        </select>
    </div>

    <div class="row">
        <div class="col">
            <div class="mb-3">
                <label class="form-label" for="Line Spacing">Line Spacing (%)</label>
                <input class="form-control" type="number" bind:value={lineSpacing}>
            </div>
        </div>
        <div class="col">
            <div class="mb-3">
                <label class="form-label" for="Display Padding Horizontal">Display Padding Horizontal (%)</label>
                <input class="form-control" type="number" bind:value={xPadding} step="1" min="0" max="25">
            </div>
        </div>
        <div class="col">
            <div class="mb-3">
                <label class="form-label" for="Display Padding Vertical">Display Padding Vertical (%)</label>
                <input class="form-control" type="number" bind:value={yPadding} step="1" min="0" max="25">
            </div>
        </div>
    </div>
    <button class="btn btn-primary float-end" type="button" on:click={saveChanges}>Save Settings</button>
</form>
