export default {
  ratingTypes: ["TV Parental Guideline", "MPAA", "Canadian English", "Canadian French"],
  ratings: {
    "TV Parental Guideline": ["N/A", "TV-Y", "TV-Y7", "TV-G", "TV-PG", "TV-14", "TV-MA"],
    "MPAA": ["G", "PG", "PG-13", "R", "NC-17", "X", "NR"],
    "Canadian English": ["E", "C", "C8+", "G", "PG", "14", "18+"],
    "Canadian French": ["E", "G", "8+", "13+", "16+", "18+"]
  },
  ratingsMap: {
    "TV Parental Guideline": {
      "N/A": [],
      "TV-Y": ["c8", "c1"],
      "TV-Y7": ["c8", "c2"],
      "TV-G": ["c8", "43"],
      "TV-PG": ["68", "7c"],
      "TV-14": ["68", "fd"],
      "TV-MA": ["c8", "6e"]
    },
    "MPAA": {
      "G": ["c1", "40"],
      "PG": ["c2", "40"],
      "PG-13": ["c3", "40"],
      "R": ["c4", "40"],
      "NC-17": ["45", "40"],
      "X": ["46", "40"],
      "NR": ["c7", "40"]
    },
    "Canadian English": {
      "E": [],
      "C": ["58", "c1"],
      "C8+": ["58", "c2"],
      "G": ["58", "43"],
      "PG": ["58", "c4"],
      "14": ["58", "45"],
      "18+": ["58", "46"]
    },
    "Canadian French": {
      "E": [],
      "G": ["f8", "c1"],
      "8+": ["f8", "c2"],
      "13+": ["f8", "43"],
      "16+": ["f8", "c4"],
      "18+": ["f8", "45"]
    }
  }
};