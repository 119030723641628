<script>
    import { explorerContextState } from "@app/store/explorerContextStore.js";
    import { authState } from "@app/store/authStore.js";
    import { lockState } from "@app/store/lockStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { editorState } from "@app/store/editorStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { fontState } from "@app/store/fontStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { playerState } from "@app/store/playerStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { environment } from "@app/store/envStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { onMount } from "svelte";
    import { onDestroy } from "svelte";
    import { tick } from "svelte";
    import { fade } from "svelte/transition";

    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import insertEvent from "@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js";
    import eol from "eol";
    import removeEvent from "@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js";
    import autoFormat from "@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js";
    import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
    import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
    import getWordCount from "@app/external/cc-lib/dist/functions/quill/getWordCount.js";
    import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
    import getLineCount from "@app/external/cc-lib/dist/functions/quill/getLineCount.js";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";

    import { saveAs } from "file-saver";
    import Swal from "sweetalert2";

    let selectedShortcut = {},
        userId,
        teamId,
        teamRef,
        homeRef,
        storageRef,
        teamDriveUploadBasePath,
        myDriveUploadBasePath,
        playbackSpeeds = [
            0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 4.0, 8.0, 16.0,
        ],
        rewindState,
        files;

    if ($environment.online) {
        (userId = firebase.auth().currentUser.uid),
            (teamId = $authState.team ? $authState.team.id : null),
            (homeRef = db
                .collection("users")
                .doc(userId)
                .collection("storage")),
            (teamRef = teamId
                ? db.collection("teams").doc(teamId).collection("storage")
                : null),
            (storageRef = storage.ref()),
            (teamDriveUploadBasePath = "teams/" + teamId + "/storage/"),
            (myDriveUploadBasePath = "users/" + userId + "/projects/");
    }

    const rewind = async function () {
        player.rewind(0.25);
        player.play();
    };

    function removeLastWord(htmlString) {
        // Create a temporary div element
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;

        // Get all the text nodes within the div
        const textNodes = getTextNodes(tempDiv);

        // Find the last word and its parent element
        let lastWord = "";
        for (let i = textNodes.length - 1; i >= 0; i--) {
            const node = textNodes[i];
            const words = node.nodeValue.trim().split(" ");

            if (words.length > 1) {
                lastWord = words.pop();
                node.nodeValue = words.join(" ");
                break;
            } else if (words.length === 1) {
                lastWord = words[0];
                node.parentNode.removeChild(node);
                break;
            }
        }

        // Convert the temporary div back to a string
        const updatedString = tempDiv.innerHTML;

        // Return both the word and the updated string
        return [lastWord, updatedString];
    }

    function getTextNodes(element) {
        const textNodes = [];

        function traverse(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                textNodes.push(node);
            } else {
                for (const childNode of node.childNodes) {
                    traverse(childNode);
                }
            }
        }

        traverse(element);
        return textNodes;
    }

    let shortcutGroups = [
        {
            name: "System",
            shortcuts: [
                {
                    name: "New Project",
                    description: "Open the New Project window",
                    keyCmd: "",

                    action: async function () {
                        modalState.showModal("newProject");
                    },
                },

                {
                    name: "Export File",
                    description: "Open the export menu",
                    keyCmd: "",

                    action: async function () {
                        modalState.showModal("fileExport");
                    },
                },
                {
                    name: "Import File",
                    description: "Open the import file menu",
                    keyCmd: "",

                    action: async function () {
                        modalState.showModal("fileImport");
                    },
                },
                {
                    name: "Undo",
                    description: "Undo previous action",
                    keyCmd: "",

                    action: async function () {
                        historyState.undo();
                        loadSnapshots();
                    },
                },
                {
                    name: "Redo",
                    description: "Redo previous action",
                    keyCmd: "",

                    action: async function () {
                        historyState.redo();
                        loadSnapshots();
                    },
                },
                {
                    name: "Save Project",
                    description: "Save project or open 'Save As' dialogue box",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $projectState.useLocalStorage ||
                            !$environment.online
                        ) {
                            try {
                                if ($environment.electron) {
                                    const fs = window.fs;
                                    const ipcRenderer = window.ipcRenderer;
                                    if ($projectState.localPath) {
                                        let projectJson = JSON.parse(
                                            JSON.stringify($projectState),
                                        );
                                        projectJson.metadata = JSON.parse(
                                            JSON.stringify($metadataState),
                                        );
                                        projectJson.speakers = JSON.parse(
                                            JSON.stringify($speakerState),
                                        );
                                        projectJson.markers = JSON.parse(
                                            JSON.stringify($markerState),
                                        );
                                        projectJson.eventGroups = JSON.parse(
                                            JSON.stringify($eventGroupState),
                                        );
                                        projectJson.style = JSON.parse(
                                            JSON.stringify($styleState),
                                        );
                                        await fs.writeFile(
                                            $projectState.localPath,
                                            JSON.stringify(
                                                projectJson,
                                                null,
                                                4,
                                            ),
                                        );

                                        toast.push(
                                            "Save project completed successfully",
                                            {
                                                classes: ["toast-success"],
                                            },
                                        );
                                    } else {
                                        let filePath = await ipcRenderer.invoke(
                                            "openSaveDialog",
                                            {
                                                title: "Save Project",
                                                defaultPath: `${$projectState.name}.ccprj`,
                                                properties: [
                                                    "showOverwriteConfirmation",
                                                    "createDirectory",
                                                ],
                                            },
                                        );

                                        if (filePath.canceled) {
                                            throw new Error(
                                                "Save process cancelled by user",
                                            );
                                        }

                                        $projectState.localPath =
                                            filePath.filePath;
                                        $projectState.name = filePath.filePath
                                            .replace(/^.*[\\\/]/, "")
                                            .replace(/\.ccprj/g, "");

                                        let projectJson = JSON.parse(
                                            JSON.stringify($projectState),
                                        );
                                        projectJson.metadata = JSON.parse(
                                            JSON.stringify($metadataState),
                                        );
                                        projectJson.speakers = JSON.parse(
                                            JSON.stringify($speakerState),
                                        );
                                        projectJson.markers = JSON.parse(
                                            JSON.stringify($markerState),
                                        );
                                        projectJson.eventGroups = JSON.parse(
                                            JSON.stringify($eventGroupState),
                                        );
                                        projectJson.style = JSON.parse(
                                            JSON.stringify($styleState),
                                        );

                                        await fs.writeFile(
                                            $projectState.localPath,
                                            JSON.stringify(
                                                projectJson,
                                                null,
                                                4,
                                            ),
                                        );

                                        toast.push(
                                            "Save project completed successfully",
                                            {
                                                classes: ["toast-success"],
                                            },
                                        );
                                    }

                                    historyState.reset();
                                } else {
                                    let projectJson = JSON.parse(
                                        JSON.stringify($projectState),
                                    );
                                    projectJson.metadata = JSON.parse(
                                        JSON.stringify($metadataState),
                                    );
                                    projectJson.speakers = JSON.parse(
                                        JSON.stringify($speakerState),
                                    );
                                    projectJson.markers = JSON.parse(
                                        JSON.stringify($markerState),
                                    );
                                    projectJson.eventGroups = JSON.parse(
                                        JSON.stringify($eventGroupState),
                                    );
                                    projectJson.style = JSON.parse(
                                        JSON.stringify($styleState),
                                    );
                                    let fileBlob = new Blob(
                                        [JSON.stringify(projectJson, null, 4)],
                                        {
                                            type: "text/plain;charset=utf-8",
                                        },
                                    );

                                    saveAs(
                                        fileBlob,
                                        $projectState.name + ".ccprj",
                                        {
                                            autoBom: true,
                                        },
                                    );

                                    historyState.reset();
                                }
                            } catch (err) {
                                console.log(err, err.message);
                                toast.push(
                                    "Save project completed with error. " +
                                        err.message,
                                    {
                                        classes: ["toast-danger"],
                                    },
                                );
                            }
                        } else {
                            if (
                                $projectState.folderId === undefined ||
                                $projectState.folderId === false
                            ) {
                                $explorerContextState = "save";
                                modalState.showModal("storageExplorer");
                            } else {
                                if (
                                    $projectState.rootDir === "team" &&
                                    !teamId
                                ) {
                                    teamId = $authState.team
                                        ? $authState.team.id
                                        : null;
                                    teamRef = teamId
                                        ? db
                                              .collection("teams")
                                              .doc(teamId)
                                              .collection("storage")
                                        : null;
                                    teamDriveUploadBasePath =
                                        "teams/" + teamId + "/storage/";
                                }

                                let projectJson = JSON.parse(
                                    JSON.stringify($projectState),
                                );
                                projectJson.metadata = JSON.parse(
                                    JSON.stringify($metadataState),
                                );
                                projectJson.speakers = JSON.parse(
                                    JSON.stringify($speakerState),
                                );
                                projectJson.markers = JSON.parse(
                                    JSON.stringify($markerState),
                                );
                                projectJson.eventGroups = JSON.parse(
                                    JSON.stringify($eventGroupState),
                                );

                                let projectFilePath =
                                    $projectState.rootDir === "team"
                                        ? teamDriveUploadBasePath +
                                          $projectState.id
                                        : myDriveUploadBasePath +
                                          $projectState.id;

                                storageRef
                                    .child(projectFilePath)
                                    .putString(JSON.stringify(projectJson))
                                    .then((snapshot) => {
                                        //console.log("project file saved!", snapshot);
                                        let projectInfo = {
                                            id: $projectState.id,
                                            name: $projectState.name,
                                            type:
                                                $projectState.type === "team"
                                                    ? "team project"
                                                    : "project",
                                            updatedOn:
                                                firebase.firestore.Timestamp.fromDate(
                                                    new Date(),
                                                ),
                                            shared:
                                                $projectState.rootDir === "team"
                                                    ? true
                                                    : false,
                                            locationId: $projectState.folderId,
                                        };

                                        if ($projectState.rootDir === "team") {
                                            return teamRef
                                                .doc(projectInfo.id)
                                                .update(projectInfo);
                                        } else {
                                            return homeRef
                                                .doc(projectInfo.id)
                                                .update(projectInfo);
                                        }
                                    })
                                    .then(() => {
                                        toast.push(
                                            "Project saved successfully",
                                            {
                                                classes: ["toast-success"],
                                            },
                                        );
                                    })
                                    .catch((error) => {
                                        console.log(
                                            "Error saving project: ",
                                            error,
                                        );
                                        toast.push(error.message, {
                                            classes: ["toast-danger"],
                                        });
                                    });
                                historyState.reset();
                            }
                        }
                    },
                },
                {
                    name: "Copy",
                    description: "Copy event or selected text",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                        ) {
                            let textToCopy,
                                range = quillEditor.getSelection();
                            if (
                                range &&
                                range.length !== 0 &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 1
                            ) {
                                textToCopy = quillEditor.getText(
                                    range.index,
                                    range.length,
                                );
                            } else {
                                let eventsToCopy = [];
                                $eventGroupState[
                                    $projectState.selected
                                ].selected
                                    .sort((eventA, eventB) => {
                                        return eventA - eventB;
                                    })
                                    .forEach((eventIndex) => {
                                        eventsToCopy.push(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex],
                                        );
                                    });

                                textToCopy = JSON.stringify(eventsToCopy);
                            }

                            navigator.clipboard.writeText(textToCopy);
                        }
                    },
                },
                {
                    name: "Cut",
                    description: "Cut event or selected text",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                        ) {
                            let textToCopy,
                                range = quillEditor.getSelection();
                            if (
                                range &&
                                range.length !== 0 &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 1
                            ) {
                                textToCopy = quillEditor.getText(
                                    range.index,
                                    range.length,
                                );
                                quillEditor.deleteText(
                                    range.index,
                                    range.length,
                                );
                            } else {
                                let eventsToCut = [];
                                let selectedEvents = $eventGroupState[
                                    $projectState.selected
                                ].selected.map((selectedEvent) => {
                                    return selectedEvent;
                                });

                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [];
                                await tick();

                                selectedEvents
                                    .sort((a, b) => {
                                        return b - a;
                                    })
                                    .forEach((eventIndex) => {
                                        eventsToCut.push(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex],
                                        );
                                        $eventGroupState[
                                            $projectState.selected
                                        ] = removeEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            eventIndex,
                                        );
                                    });

                                textToCopy = JSON.stringify(eventsToCut);
                            }

                            navigator.clipboard.writeText(textToCopy);

                            historyState.insert({
                                name: "cut event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        }
                    },
                },
                {
                    name: "Paste",
                    description: "Paste event or text",
                    keyCmd: "",

                    action: async function () {
                        let findFormInput =
                            document.getElementById("FindInput");
                        let replaceFormInput =
                            document.getElementById("ReplaceInput");
                        let activeElement = document.activeElement;

                        if (activeElement === findFormInput) {
                            navigator.clipboard.readText().then((text) => {
                                findFormInput.value = text;
                            });
                        } else if (activeElement === replaceFormInput) {
                            navigator.clipboard.readText().then((text) => {
                                replaceFormInput.value = text;
                            });
                        } else {
                            navigator.clipboard.readText().then((text) => {
                                try {
                                    let clipboardEvents = JSON.parse(text);
                                    //check if clipboardEvents is actually subtitle events
                                    if (
                                        clipboardEvents.length > 0 &&
                                        clipboardEvents[0].id &&
                                        clipboardEvents[0].style
                                    ) {
                                        let currentTime = player
                                            ? player.currentTime
                                            : 0;
                                        let newCurrentTime = currentTime;
                                        Swal.fire({
                                            title: "Paste Events",
                                            text: "Would you like to paste the event(s) based on the current time?",
                                            icon: "question",
                                            showCancelButton: true,
                                            confirmButtonText: "Yes",
                                            cancelButtonText: "No",
                                        })
                                            .then((sAlertRes) => {
                                                clipboardEvents.forEach(
                                                    (event, count, events) => {
                                                        // Use Sweet Alert to ask the user if they wish to paste events at the current Time. If yes, then paste the events, but update the start and end time of the event so that the start time is the current time and the end time is the current time + the duration of the event.
                                                        if (
                                                            sAlertRes.isConfirmed &&
                                                            currentTime >= 0
                                                        ) {
                                                            let duration =
                                                                event.end -
                                                                event.start;
                                                            let startTime =
                                                                count === 0
                                                                    ? currentTime
                                                                    : currentTime +
                                                                      (event.start -
                                                                          events[0]
                                                                              .end);

                                                            startTime =
                                                                startTime
                                                                    ? startTime
                                                                    : newCurrentTime;
                                                            let endTime =
                                                                startTime +
                                                                duration;
                                                            newCurrentTime =
                                                                endTime;
                                                            $eventGroupState[
                                                                $projectState.selected
                                                            ] = insertEvent(
                                                                $eventGroupState[
                                                                    $projectState
                                                                        .selected
                                                                ],
                                                                {
                                                                    ...event,
                                                                    start: startTime,
                                                                    end: endTime,
                                                                },
                                                                $eventGroupState[
                                                                    $projectState
                                                                        .selected
                                                                ].selected[0] +
                                                                    count +
                                                                    1,
                                                            );
                                                        } else {
                                                            $eventGroupState[
                                                                $projectState.selected
                                                            ] = insertEvent(
                                                                $eventGroupState[
                                                                    $projectState
                                                                        .selected
                                                                ],
                                                                event,
                                                                $eventGroupState[
                                                                    $projectState
                                                                        .selected
                                                                ].selected[0] +
                                                                    count +
                                                                    1,
                                                            );
                                                        }
                                                        //Order events by time
                                                        $eventGroupState[
                                                            $projectState.selected
                                                        ] = orderByTime(
                                                            $eventGroupState[
                                                                $projectState
                                                                    .selected
                                                            ],
                                                        );
                                                    },
                                                );
                                            })
                                            .catch((err) => {
                                                console.log(
                                                    "Error pasting events",
                                                );
                                                console.log(err);
                                                console.log(err.message);
                                            });
                                    } else {
                                        throw new Error(
                                            "No events found in clipboard",
                                        );
                                    }
                                } catch (err) {
                                    let range = quillEditor.getSelection();
                                    if (range && range.length === 0) {
                                        quillEditor.insertText(
                                            range.index,
                                            text,
                                        );
                                    } else if (range) {
                                        quillEditor.deleteText(
                                            range.index,
                                            range.length,
                                        );
                                        quillEditor.insertText(
                                            range.index,
                                            text,
                                        );
                                    }

                                    if (
                                        $editorState.autoFormatText &&
                                        $eventGroupState[$projectState.selected]
                                            .selected.length > 0
                                    ) {
                                        let maxLines = $eventGroupState[
                                                $projectState.selected
                                            ]
                                                ? $eventGroupState[
                                                      $projectState.selected
                                                  ].maxLines
                                                : 2,
                                            maxChars = $eventGroupState[
                                                $projectState.selected
                                            ]
                                                ? $eventGroupState[
                                                      $projectState.selected
                                                  ].maxChars
                                                : 32,
                                            minDuration = 0,
                                            allowOrphanWords = true;

                                        try {
                                            let selectedEvent =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0];
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected = [selectedEvent];

                                            $eventGroupState[
                                                $projectState.selected
                                            ] = autoFormat(
                                                $eventGroupState[
                                                    $projectState.selected
                                                ],
                                                maxLines,
                                                maxChars,
                                                minDuration,
                                                allowOrphanWords,
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected,
                                            );

                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected = [];
                                            setTimeout(() => {
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected = [selectedEvent];
                                            }, 50);
                                        } catch (err) {
                                            console.log(err.message);
                                        }
                                    }
                                }

                                historyState.insert({
                                    name: "paste events", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });

                                $eventGroupState = $eventGroupState;
                            });
                        }
                    },
                },
            ],
        },
        {
            name: "Media Player",
            shortcuts: [
                {
                    name: "Next Marker",
                    description: "Go to next marker",
                    shortName: "goToNextMarker",
                    keyCmd: "",
                    action: async function () {
                        if (
                            $markerState.lists[$markerState.selected] &&
                            $markerState.lists[$markerState.selected].markers
                                .length > 0
                        ) {
                            //Go to the next closest marker based on the player.currentTime.
                            let nextMarker = $markerState.lists[
                                $markerState.selected
                            ].markers.find((marker) => {
                                return marker.time > player.currentTime;
                            });

                            if (nextMarker) {
                                player.currentTime = nextMarker.time;
                            }
                        }
                    },
                },
                {
                    name: "Previous Marker",
                    description: "Go to previous marker",
                    shortName: "goToPrevMarker",
                    keyCmd: "",
                    action: async function () {
                        if (
                            $markerState.lists[$markerState.selected] &&
                            $markerState.lists[$markerState.selected].markers
                                .length > 0
                        ) {
                            //Go to the previous closest marker based on the player.currentTime.
                            let markerListReverseSort = JSON.parse(
                                JSON.stringify(
                                    $markerState.lists[$markerState.selected]
                                        .markers,
                                ),
                            ).sort((mA, mB) => {
                                return mB.time - mA.time;
                            });

                            let prevMarker = markerListReverseSort.find(
                                (marker) => {
                                    return marker.time < player.currentTime;
                                },
                            );

                            if (prevMarker) {
                                player.currentTime = prevMarker.time;
                            }
                        }
                    },
                },
                {
                    name: "Toggle Playback (Play/Pause)",
                    description: "Play or pause video playback",
                    shortName: "playPause",
                    keyCmd: "",

                    action: async function () {
                        if (player.playing) {
                            player.pause();
                        } else {
                            player.play();
                        }
                    },
                },
                {
                    name: "Toggle Fast Forward",
                    description:
                        "Fast Forward through video (foot fedal recommended)",
                    shortName: "fastForward",
                    keyCmd: "",

                    action: async function () {
                        if (player.speed === 1) {
                            player.speed = 8;
                            playerState.updatePlayerSpeed(8);
                            player.play();
                        } else {
                            player.speed = 1;
                            playerState.updatePlayerSpeed(1);
                            player.pause();
                        }
                    },
                },
                {
                    name: "Toggle Rewind",
                    description:
                        "Rewind through video (foot fedal recommended)",
                    shortName: "rewind",
                    keyCmd: "",

                    action: async function () {
                        if (rewindState) {
                            clearInterval(rewindState);
                            rewindState = undefined;
                            player.pause();
                        } else {
                            rewindState = setInterval(rewind, 50);
                        }
                    },
                },
                {
                    name: "Reverse 1 Frame",
                    description: "Go back one frame in the video",
                    shortName: "reverse",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime =
                            $playerState.time - 1 / $projectState.frameRate;
                    },
                },
                {
                    name: "Forward 1 Frame",
                    description: "Go forward one frame in the video",
                    shortName: "forward",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime =
                            $playerState.time + 1 / $projectState.frameRate;
                    },
                },
                {
                    name: "Reverse 5 Frame",
                    description: "Go back five frames in the video",
                    shortName: "reverseFiveFrames",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime =
                            $playerState.time - 5 / $projectState.frameRate;
                    },
                },
                {
                    name: "Forward 5 Frame",
                    description: "Go forward five frames in the video",
                    shortName: "forwardFiveFrames",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime =
                            $playerState.time + 5 / $projectState.frameRate;
                    },
                },
                {
                    name: "Reverse 10 Frame",
                    description: "Go back ten frames in the video",
                    shortName: "reverse",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime =
                            $playerState.time - 10 / $projectState.frameRate;
                    },
                },
                {
                    name: "Forward 10 Frame",
                    description: "Go forward ten frames in the video",
                    shortName: "forward",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime =
                            $playerState.time + 10 / $projectState.frameRate;
                    },
                },
                {
                    name: "Reverse 1 Second",
                    description: "Go back one second in the video",
                    shortName: "reverseOneSec",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime = $playerState.time - 1;
                    },
                },
                {
                    name: "Forward 1 Second",
                    description: "Go forward one second in the video",
                    shortName: "forwardOneSec",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime = $playerState.time + 1;
                    },
                },
                {
                    name: "Reverse 3 Second",
                    description: "Go back three second in the video",
                    shortName: "reverseThreeSec",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime = $playerState.time - 3;
                    },
                },
                {
                    name: "Forward 3 Second",
                    description: "Go forward three second in the video",
                    shortName: "forwardThreeSec",
                    keyCmd: "",

                    action: async function () {
                        player.currentTime = $playerState.time + 3;
                    },
                },
                {
                    name: "Go To Start Of Event",
                    description:
                        "Update playhead to the start of the selected event",
                    shortName: "startOfEvent",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0 &&
                            $eventGroupState[$projectState.selected] &&
                            !isNaN(
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].start,
                            )
                        ) {
                            player.currentTime =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].start;
                        }
                    },
                },
                {
                    name: "Go To End Of Event",
                    description:
                        "Update playhead to the end of the selected event",
                    shortName: "endOfEvent",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0 &&
                            $eventGroupState[$projectState.selected] &&
                            !isNaN(
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].end,
                            )
                        ) {
                            player.currentTime =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].end;
                        }
                    },
                },
                {
                    name: "Increase Playback Speed",
                    description:
                        "Increase the playback speed of the player. Playback rates include 0.25x - 16x",
                    shortName: "increasePlaybackRate",
                    keyCmd: "",

                    action: async function () {
                        try {
                            let index = playbackSpeeds.indexOf(player.speed);
                            player.speed = playbackSpeeds[index + 1] || 16.0;
                            playerState.updatePlayerSpeed(player.speed);
                        } catch (err) {
                            console.warn(err, err.message);
                        }
                    },
                },
                {
                    name: "Decrease Playback Speed",
                    description:
                        "Decrease the playback speed of the player. Playback rates include 0.25x - 16x",
                    shortName: "decreasePlaybackRate",
                    keyCmd: "",

                    action: async function () {
                        try {
                            let index = playbackSpeeds.indexOf(player.speed);
                            player.speed = playbackSpeeds[index - 1] || 0.25;
                            playerState.updatePlayerSpeed(player.speed);
                        } catch (err) {
                            console.warn(err, err.message);
                        }
                    },
                },
                {
                    name: "Toggle Caption Lock",
                    description: "Toggle caption lock on/off",
                    shortName: "toggleCaptionLock",
                    keyCmd: "",

                    action: async function () {
                        try {
                            $lockState.caption = !$lockState.caption;
                        } catch (err) {
                            console.warn(err, err.message);
                        }
                    },
                },
                {
                    name: "Toggle Video Lock",
                    description: "Toggle video lock on/off",
                    shortName: "toggleVideoLock",
                    keyCmd: "",

                    action: async function () {
                        try {
                            $lockState.video = !$lockState.video;
                        } catch (err) {
                            console.warn(err, err.message);
                        }
                    },
                },
                {
                    name: "Toggle Play Preview",
                    description: "Toggle play preview on/off",
                    shortName: "togglePreviewLock",
                    keyCmd: "",

                    action: async function () {
                        try {
                            $lockState.preview = !$lockState.preview;
                        } catch (err) {
                            console.warn(err, err.message);
                        }
                    },
                },
            ],
        },
        {
            name: "Editor",
            shortcuts: [
                {
                    name: "Insert Event (Above)",
                    description:
                        "Insert event above the currently selected event.",
                    shortName: "insertEventBefore",
                    keyCmd: "",
                    action: async function () {
                        if (!$eventGroupState[$projectState.selected]) {
                            return;
                        }

                        let eventOptions;
                        let eventIndex =
                            $eventGroupState[$projectState.selected]
                                .selected[0] ?? 0;
                        let prevEventIndex = eventIndex - 1;
                        let nxtEventIndex = eventIndex + 1;

                        if (
                            $eventGroupState[$projectState.selected].events
                                .length > 0
                        ) {
                            try {
                                let eventTemplate =
                                    $eventGroupState[$projectState.selected]
                                        .selected.length > 0
                                        ? $eventGroupState[
                                              $projectState.selected
                                          ].events[
                                              $eventGroupState[
                                                  $projectState.selected
                                              ].selected[0]
                                          ]
                                        : $eventGroupState[
                                              $projectState.selected
                                          ].events[
                                              $eventGroupState[
                                                  $projectState.selected
                                              ].events.length - 1
                                          ];

                                eventOptions = {
                                    alignment: eventTemplate.alignment,
                                    xPos: eventTemplate.xPos,
                                    yPos: eventTemplate.yPos,
                                    xOffset: eventTemplate.xOffset,
                                    yOffset: eventTemplate.yOffset,
                                    vertical: eventTemplate.vertical,
                                    voice: eventTemplate.voice,
                                    speakingStyle: eventTemplate.speakingStyle,
                                    style: eventTemplate.style,
                                    rate: eventTemplate.rate,
                                    start: $editorState.insertStartOnInsert
                                        ? player.currentTime
                                        : false,
                                };
                            } catch (err) {
                                console.log(err);
                            }
                        } else {
                            eventOptions = {
                                alignment:
                                    ["subtitle", "translation"].indexOf(
                                        $eventGroupState[$projectState.selected]
                                            .type,
                                    ) > -1
                                        ? "center"
                                        : "left",
                                start: $editorState.insertStartOnInsert
                                    ? player.currentTime
                                    : false,
                                voice: $eventGroupState[$projectState.selected]
                                    .ad.voice,
                            };
                        }

                        /* Insert End On Insert Option */
                        if (
                            prevEventIndex > -1 &&
                            $editorState.insertEndOnInsert &&
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                        ) {
                            $eventGroupState[$projectState.selected].events[
                                prevEventIndex
                            ].end = eventOptions.start;
                        }

                        $eventGroupState[$projectState.selected] = insertEvent(
                            $eventGroupState[$projectState.selected],
                            eventOptions,
                            eventIndex,
                        );

                        $eventGroupState[$projectState.selected].selected = [
                            nxtEventIndex,
                        ];

                        historyState.insert({
                            name: "insert event", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    },
                },
                {
                    name: "Insert Event (Below)",
                    description: "Insert new event below the selected event",
                    shortName: "insertEvent",
                    keyCmd: "Tab",
                    action: async function () {
                        if (!$eventGroupState[$projectState.selected]) {
                            return;
                        }

                        let eventOptions;
                        let eventIndex =
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                                ? $eventGroupState[$projectState.selected]
                                      .selected[0] + 1
                                : null;

                        try {
                            let eventTemplate =
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                                    ? $eventGroupState[$projectState.selected]
                                          .events[
                                          $eventGroupState[
                                              $projectState.selected
                                          ].selected[0]
                                      ]
                                    : $eventGroupState[$projectState.selected]
                                          .events[
                                          $eventGroupState[
                                              $projectState.selected
                                          ].events.length - 1
                                      ];

                            if (!eventTemplate) {
                                eventOptions = {
                                    alignment:
                                        ["subtitle", "translation"].indexOf(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].type,
                                        ) > -1
                                            ? "center"
                                            : "left",
                                    start: $editorState.insertStartOnInsert
                                        ? player.currentTime
                                        : false,
                                    voice: $eventGroupState[
                                        $projectState.selected
                                    ].ad.voice,
                                };
                            } else {
                                eventOptions = {
                                    alignment: eventTemplate.alignment,
                                    xPos: eventTemplate.xPos,
                                    yPos: eventTemplate.yPos,
                                    xOffset: eventTemplate.xOffset,
                                    yOffset: eventTemplate.yOffset,
                                    vertical: eventTemplate.vertical,
                                    voice: eventTemplate.voice,
                                    speakingStyle: eventTemplate.speakingStyle,
                                    style: eventTemplate.style,
                                    rate: eventTemplate.rate,
                                    start: $editorState.insertStartOnInsert
                                        ? player.currentTime
                                        : false,
                                };
                            }
                        } catch (err) {
                            console.log(err);
                        }
                        /* Insert End On Insert Option */
                        if (
                            $editorState.insertEndOnInsert &&
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                        ) {
                            $eventGroupState[$projectState.selected].events[
                                $eventGroupState[
                                    $projectState.selected
                                ].selected[0]
                            ].end =
                                eventOptions.start -
                                $editorState.minFrameGap /
                                    $projectState.frameRate;
                        }

                        $eventGroupState[$projectState.selected] = insertEvent(
                            $eventGroupState[$projectState.selected],
                            eventOptions,
                            eventIndex,
                        );
                        $eventGroupState[$projectState.selected].selected =
                            eventIndex !== null
                                ? [eventIndex]
                                : [
                                      $eventGroupState[$projectState.selected]
                                          .events.length - 1,
                                  ];

                        historyState.insert({
                            name: "insert event", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    },
                },
                {
                    name: "Delete Event",
                    description: "Delete all selected Events",
                    shortName: "deleteEvent",
                    keyCmd: "",
                    action: async function () {
                        let eventsToDelete = $eventGroupState[
                            $projectState.selected
                        ].selected.map((eventIndex) => {
                            return eventIndex;
                        });

                        //use Sweetalert to confirm the user wants to delete the events
                        Swal.fire({
                            title: "Are you sure?",
                            text: "Would you like to remove the selected event(s)?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ok",
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [];
                                await tick();

                                eventsToDelete
                                    .sort((a, b) => {
                                        return b - a;
                                    })
                                    .forEach((eventIndex) => {
                                        $eventGroupState[
                                            $projectState.selected
                                        ] = removeEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            eventIndex,
                                        );
                                    });

                                historyState.insert({
                                    name: "delete event(s)", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        });
                    },
                },
                {
                    name: "Go To Event",
                    description:
                        "Open the Go To Event window to navigate to a specific event in the selected Event Group",
                    shortName: "goToEvent",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState.length > 0 &&
                            $eventGroupState[$projectState.selected]
                        ) {
                            modalState.showModal("goToEvent");
                        }
                    },
                },
                {
                    name: "Toggle Edit Mode",
                    description:
                        "Toggling Edit Mode will focus the textarea of the selected Event. This will allow you to quickly edit the text of the Event. Disabling Edit Mode will return the focus to the Event List.",
                    shortName: "toggleEditMode",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $editorState.editing = !$editorState.editing;
                            if ($editorState.editing && quillEditor) {
                                //Check if quillEditor is initialized and focus on it
                                quillEditor.setSelection(
                                    quillEditor.getLength(),
                                    0,
                                );
                            } else if (quillEditor) {
                                //Check if quillEditor is initialized and blur it
                                quillEditor.blur();
                            }
                        } catch (err) {
                            console.log(
                                "Toggle Edit Mode Shortcut Error: ",
                                err.message,
                            );
                        }
                    },
                },
                {
                    name: "Set Incode (Start)",
                    description:
                        "Update the incode of the selected Event based on the current media time",
                    shortName: "markIn",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0
                        ) {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].start =
                                    player.currentTime +
                                    $editorState.minFrameGap /
                                        $projectState.frameRate;

                                if (
                                    $editorState.autoChain &&
                                    $eventGroupState[$projectState.selected]
                                        .events[eventIndex - 1]
                                ) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex - 1].end =
                                        player.currentTime;
                                }
                            });

                            if (
                                $editorState.selectNextEvent &&
                                $eventGroupState[$projectState.selected]
                                    .selected[0] +
                                    1 <
                                    $eventGroupState[$projectState.selected]
                                        .events.length
                            ) {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] + 1,
                                ];
                            }

                            historyState.insert({
                                name: "update timecode", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        }
                    },
                },
                {
                    name: "Set Outcode (End)",
                    description:
                        "Update the outcode of the selected Event based on the current media time",
                    shortName: "markOut",
                    keyCmd: "",

                    action: async function () {
                        $eventGroupState[
                            $projectState.selected
                        ].selected.forEach((eventIndex) => {
                            $eventGroupState[$projectState.selected].events[
                                eventIndex
                            ].end = player.currentTime;
                        });

                        historyState.insert({
                            name: "update timecode", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    },
                },
                {
                    name: "Add 1 Frame To Start",
                    description: "Increase start time by one frame",
                    shortName: "addFrameToStart",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].start += 1 / $projectState.frameRate;

                                if ($lockState.video) {
                                    player.currentTime =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start;
                                }

                                historyState.insert({
                                    name: "add frame", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Add 1 Frame To End",
                    description: "Increase end time by one frame",
                    shortName: "addFrameToEnd",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].end += 1 / $projectState.frameRate;

                                if (
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1
                                    ] &&
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1
                                    ].start <
                                        $eventGroupState[$projectState.selected]
                                            .events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end
                                ) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1
                                    ].start =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end;
                                }

                                if ($lockState.video) {
                                    player.currentTime =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end;
                                }

                                historyState.insert({
                                    name: "add frame", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Subtract 1 Frame From Start",
                    description: "Decrease start time by one frame",
                    shortName: "removeFrameFromStart",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].start = Math.max(
                                    0,
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].start -
                                        1 / $projectState.frameRate,
                                );

                                if (
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1
                                    ] &&
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1
                                    ].end >
                                        $eventGroupState[$projectState.selected]
                                            .events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start
                                ) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1
                                    ].end =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start;
                                }

                                if ($lockState.video) {
                                    player.currentTime =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start;
                                }

                                historyState.insert({
                                    name: "subtract frame", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Subtract 1 Frame From End",
                    description: "Decrease end time by one frame",
                    shortName: "removeFrameFromEnd",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].end = Math.max(
                                    0,
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].end -
                                        1 / $projectState.frameRate,
                                );

                                if ($lockState.video) {
                                    player.currentTime =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end;
                                }

                                historyState.insert({
                                    name: "subtract frame", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Shift Event Backward",
                    description:
                        "Shift the selected event(s) backward by one frame",
                    shortName: "shiftEventBackward",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected.forEach((eventIndex) => {
                                    //Check that the start time of the event is greater than 0
                                    if (
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].start >= 0
                                    ) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].start -=
                                            1 / $projectState.frameRate;
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].end -=
                                            1 / $projectState.frameRate;
                                    }
                                });

                                historyState.insert({
                                    name: "shift timecodes", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Shift Event Forward",
                    description:
                        "Shift the selected event(s) forward by one frame",
                    shortName: "shiftEventForward",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected.forEach((eventIndex) => {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].start +=
                                        1 / $projectState.frameRate;
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].end +=
                                        1 / $projectState.frameRate;
                                });

                                historyState.insert({
                                    name: "shift timecodes", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Offset Timecode",
                    description:
                        "Offset the timecode of the selected event(s) based on the playhead position",
                    shortName: "tcOffset",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                !$eventGroupState[$projectState.selected] ||
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 0
                            ) {
                                return;
                            }

                            /* $eventGroupState[
                                $projectState.selected
                            ].selected.sort(); */

                            let selectedEventCache = [];
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((i) => {
                                if ($eventGroupState[$projectState.selected].events[i]){
                                    selectedEventCache.push($eventGroupState[$projectState.selected].events[i].id);
                                }                                
                            });

                            let offsetSec =
                                player.currentTime -
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].start;

                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                if ($eventGroupState[$projectState.selected].events[eventIndex]){
                                    //Update Start
                                    $eventGroupState[$projectState.selected].events[
                                        eventIndex
                                    ].start += offsetSec;

                                    //Update End
                                    $eventGroupState[$projectState.selected].events[
                                        eventIndex
                                    ].end += offsetSec;
                                }
                            });

                            //Reorder Events in Event List
                            $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);


                            //Reselect the events based on their IDs in the selectedEventCache
                            $eventGroupState[$projectState.selected].selected = selectedEventCache.map(evId =>{
                                return $eventGroupState[$projectState.selected].events.findIndex(ev =>{
                                    return ev.id === evId;
                                });
                            });

                            /* SAVE SNAPSHOT TO HISTORY STORE */
                            historyState.insert({
                                name: "apply offset", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(err.message, {
                                classes: ["toast-danger"],
                            });
                        }
                    },
                },
                {
                    name: "Toggle Forced Narrative Subtitles",
                    description:
                        "Mark selected event(s) as Forced Narrative Subtitles",
                    shortName: "markForcedSubtitle",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (
                                !$eventGroupState[$projectState.selected] ||
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 0
                            ) {
                                return;
                            }

                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].forced =
                                    !$eventGroupState[$projectState.selected]
                                        .events[eventIndex].forced;
                            });

                            /* Store in History */
                            historyState.insert({
                                name: "mark as FNS", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Insert Ellipsis",
                    description: "Insert ellipsis",
                    shortName: "insertEllipsis",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (!quillEditor) {
                                return;
                            }

                            let range = quillEditor.getSelection();
                            if (range && range.length === 0) {
                                quillEditor.insertText(range.index, "…");
                            } else if (range) {
                                quillEditor.deleteText(
                                    range.index,
                                    range.length,
                                );
                                quillEditor.insertText(range.index, "…");
                            }

                            historyState.insert({
                                name: "update text", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Insert ♪",
                    description: "Insert single music note",
                    shortName: "insertMusicNote",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (!quillEditor) {
                                return;
                            }
                            let range = quillEditor.getSelection();
                            if (range && range.length === 0) {
                                quillEditor.insertText(range.index, "♪");
                            } else if (range) {
                                quillEditor.deleteText(
                                    range.index,
                                    range.length,
                                );
                                quillEditor.insertText(range.index, "♪");
                            }

                            historyState.insert({
                                name: "update text", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Insert Surround ♪",
                    description:
                        "Insert surround single music notes to all selected events",
                    shortName: "insertSurroundMusicNotes",
                    keyCmd: "",

                    action: async function () {
                        try {
                            let insertChar = "♪",
                                alignmentMap = {
                                    left: false,
                                    right: "right",
                                    center: "center",
                                };

                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex, count) => {
                                let startIndex =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.indexOf(">") + 1;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].text =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        0,
                                        startIndex,
                                    ) +
                                    insertChar +
                                    " " +
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        startIndex,
                                    );

                                let endIndex =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.lastIndexOf("<");

                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].text =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        0,
                                        endIndex,
                                    ) +
                                    " " +
                                    insertChar +
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        endIndex,
                                    );

                                if (count === 0 && quillEditor) {
                                    quillEditor.clipboard.dangerouslyPasteHTML(
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].text,
                                    );
                                    quillEditor.setSelection(
                                        0,
                                        quillEditor.getLength(),
                                    );
                                    quillEditor.format(
                                        "align",
                                        alignmentMap[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].alignment
                                        ],
                                    );
                                }
                            });

                            historyState.insert({
                                name: "update text", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Insert Surround ♪♪",
                    description: "Insert surround ♪♪ to all selected events",
                    shortName: "insertDblSurroundMusicNotes",
                    keyCmd: "",

                    action: async function () {
                        try {
                            let insertChar = "♪♪",
                                alignmentMap = {
                                    left: false,
                                    right: "right",
                                    center: "center",
                                };

                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex, count) => {
                                let startIndex =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.indexOf(">") + 1;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].text =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        0,
                                        startIndex,
                                    ) +
                                    insertChar +
                                    " " +
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        startIndex,
                                    );

                                let endIndex =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.lastIndexOf("<");

                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].text =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        0,
                                        endIndex,
                                    ) +
                                    " " +
                                    insertChar +
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text.substring(
                                        endIndex,
                                    );

                                if (count === 0 && quillEditor) {
                                    quillEditor.clipboard.dangerouslyPasteHTML(
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].text,
                                    );
                                    quillEditor.setSelection(
                                        0,
                                        quillEditor.getLength(),
                                    );
                                    quillEditor.format(
                                        "align",
                                        alignmentMap[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].alignment
                                        ],
                                    );
                                }
                            });

                            historyState.insert({
                                name: "update text", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Select All Events",
                    description: "Select all events in event group",
                    shortName: "selectAllEvents",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (quillEditor && quillEditor.hasFocus()) {
                                quillEditor.setSelection(
                                    0,
                                    quillEditor.getLength(),
                                );
                            } else {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = $eventGroupState[
                                    $projectState.selected
                                ].events.map((event, count) => {
                                    return count;
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Select All Events From Current",
                    description:
                        "Select all Events in Event Group starting the currently selected event.",
                    shortName: "selectAllEventsFromCurrent",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected]
                                    .selected.length > 0
                            ) {
                                let i =
                                    $eventGroupState[$projectState.selected]
                                        .selected[0];
                                let maxLength =
                                    $eventGroupState[$projectState.selected]
                                        .events.length-1;
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [];
                                for (i; i <= maxLength; i++) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected.push(i);
                                }
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Move Word Up",
                    description: "Move a single word up to the previous line",
                    shortName: "moveWordUp",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (quillEditor) {
                                //Check that the selected event has text
                                let plainText = quillEditor.getText();
                                let format = quillEditor.getFormat();
                                let range = quillEditor.getSelection();

                                if (
                                    !plainText ||
                                    !range ||
                                    plainText.trim().split(" ").length === 1
                                ) {
                                    return;
                                }

                                //Get the current line of the cursor
                                let cursorLine =
                                    plainText
                                        .substring(0, range.index)
                                        .split("\n").length - 1;

                                let textLines = plainText
                                    .split("\n")
                                    .filter((line) => line.trim() !== "");
                                let words = textLines[cursorLine].split(" ");

                                if (cursorLine > 0) {
                                    textLines[cursorLine - 1] +=
                                        " " + words.shift();
                                    textLines[cursorLine] = words.join(" ");
                                } else if (words.length > 0) {
                                    textLines.unshift(words.shift());
                                    textLines[cursorLine + 1] = words.join(" ");
                                }

                                //Remove empty lines
                                textLines = textLines.filter(
                                    (line) => line.trim() !== "",
                                );
                                quillEditor.setText(textLines.join("\n"));
                                Object.keys(format).forEach((key) => {
                                    quillEditor.formatText(
                                        0,
                                        quillEditor.getLength(),
                                        key,
                                        format[key],
                                    );
                                });

                                quillEditor.setSelection(range.index, 0);

                                historyState.insert({
                                    name: "move word", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });

                                return;
                            }
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(err.message, {
                                classes: ["toast-danger"],
                            });
                        }
                    },
                },
                {
                    name: "Move Word Down",
                    description: "Move a single word down to the next line",
                    shortName: "moveWordDown",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (quillEditor) {
                                //Check that the selected event has text
                                let plainText = quillEditor.getText();
                                let format = quillEditor.getFormat();
                                let range = quillEditor.getSelection();

                                if (
                                    !plainText ||
                                    !range ||
                                    plainText.trim().split(" ").length === 1
                                ) {
                                    return;
                                }

                                //Get the current line of the cursor
                                let cursorLine =
                                    plainText
                                        .substring(0, range.index)
                                        .split("\n").length - 1;

                                let textLines = plainText
                                    .split("\n")
                                    .filter((line) => line.trim() !== "");
                                let words = textLines[cursorLine].split(" ");

                                if (cursorLine < textLines.length - 1) {
                                    textLines[cursorLine + 1] =
                                        words.pop() +
                                        " " +
                                        textLines[cursorLine + 1];
                                    textLines[cursorLine] = words.join(" ");
                                } else if (words.length > 0) {
                                    textLines.push(words.pop());
                                    textLines[cursorLine] = words.join(" ");
                                }

                                //Remove empty lines
                                textLines = textLines.filter(
                                    (line) => line.trim() !== "",
                                );
                                quillEditor.setText(textLines.join("\n"));
                                Object.keys(format).forEach((key) => {
                                    quillEditor.formatText(
                                        0,
                                        quillEditor.getLength(),
                                        key,
                                        format[key],
                                    );
                                });

                                quillEditor.setSelection(range.index, 0);
                                historyState.insert({
                                    name: "move word", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });

                                return;
                            }
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(err.message, {
                                classes: ["toast-danger"],
                            });
                        }
                    },
                },
                {
                    name: "Move Word To Previous Event",
                    description:
                        "Shift a single word back to the end of the previous Event",
                    shortName: "shiftWordBack",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (
                                !$eventGroupState[$projectState.selected] ||
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 0 ||
                                $eventGroupState[$projectState.selected]
                                    .selected[0] === 0
                            ) {
                                return;
                            }

                            let eventRemoved = false;
                            let maxChars =
                                $eventGroupState[$projectState.selected]
                                    .maxChars;
                            let html =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].text;
                            let plainText = convertToPlainText(html);

                            //Split text at spaces and line endings to get number of words
                            let numberOfWords = plainText.split(/\s+/g).length;
                            let textLines = eol.split(plainText);
                            let firstLine = textLines[0].split(" ");
                            let firstWord = firstLine[0];
                            // console.log("First Word:", firstWord);
                            // console.log("Text Line Length:", textLines.length);
                            // console.log("First Line Length:", firstLine.length);
                            let deletePadding = firstLine.length === 1 ? 0 : 1;
                            let lastWordFlag =
                                textLines.length === 1 && firstLine.length === 1
                                    ? true
                                    : false;
                            let oStart =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].start;
                            let oEnd =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].end;

                            let oDuration = oEnd - oStart;

                            let prevEventText =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] - 1
                                ].text || "<p></p>";

                            let prevEventTextArray = prevEventText
                                .split(">")
                                .filter((word) => {
                                    return word;
                                });

                            if (
                                prevEventTextArray[
                                    prevEventTextArray.length - 1
                                ].length +
                                    firstWord.length >
                                maxChars + 2
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] - 1
                                ].text +=
                                    `${prevEventTextArray[0]}>${firstWord}</p>`;
                            } else {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] - 1
                                ].text =
                                    prevEventText.substring(
                                        0,
                                        prevEventText.length - 4,
                                    ) +
                                    " " +
                                    firstWord +
                                    "</p>";
                            }

                            /* Remove first word from event */
                            if (quillEditor) {
                                quillEditor.deleteText(
                                    0,
                                    firstWord.length + deletePadding,
                                );
                                if (lastWordFlag) {
                                    $eventGroupState[$projectState.selected] =
                                        removeEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0],
                                        );
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected = [
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1,
                                    ];
                                    eventRemoved = true;
                                }
                            }

                            if (oDuration && oDuration > 0) {
                                let avgDurationOfWord =
                                    oDuration / numberOfWords;
                                if (eventRemoved) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected[0]
                                    ].end += avgDurationOfWord;
                                } else {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected[0]
                                    ].start += avgDurationOfWord;
                                    if (
                                        $eventGroupState[$projectState.selected]
                                            .events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] - 1
                                        ].end > 0
                                    ) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] - 1
                                        ].end += avgDurationOfWord;
                                    }
                                }
                            }

                            historyState.insert({
                                name: "shift word", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Move Word To Next Event",
                    description:
                        "Shift a single word forward to the beginning of the next Event",
                    shortName: "shiftWordForward",
                    keyCmd: "",

                    action: async function () {
                        try {
                            //Return if no event is selected or if the selected event is the last event
                            if (
                                !$eventGroupState[$projectState.selected] ||
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 0 ||
                                $eventGroupState[$projectState.selected]
                                    .selected[0] ===
                                    $eventGroupState[$projectState.selected]
                                        .events.length -
                                        1
                            )
                                return;

                            let eventRemoved = false;
                            let html =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].text;
                            let plainText = convertToPlainText(html);
                            //Split text at spaces and line endings to get number of words
                            let numberOfWords = plainText.split(/\s+/g).length;
                            let lastWordFlag = numberOfWords === 1;
                            let oStart =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].start;
                            let oEnd =
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].end;
                            let oDuration = oEnd - oStart;

                            const [lastWord, updatedString] = removeLastWord(
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ].text,
                            );

                            // console.log("Last Word:", lastWord);
                            // console.log("String without Last Word:", updatedString);

                            if (
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] + 1
                                ].text
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] + 1
                                ].text = $eventGroupState[
                                    $projectState.selected
                                ].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] + 1
                                ].text.replace(
                                    /(<p[^>]*>)/,
                                    "$1" + lastWord + " ",
                                );

                                //Auto Format the next event
                                $eventGroupState[$projectState.selected] =
                                    autoFormat(
                                        $eventGroupState[
                                            $projectState.selected
                                        ],
                                        5,
                                        $eventGroupState[$projectState.selected]
                                            .maxChars,
                                        $eventGroupState[$projectState.selected]
                                            .minDuration,
                                        false,
                                        [
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] + 1,
                                        ],
                                    );
                            } else {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] + 1
                                ].text =
                                    `<p class="ql-align-center">${lastWord}</p>`;
                            }

                            quillEditor.clipboard.dangerouslyPasteHTML(
                                updatedString,
                            );

                            if (lastWordFlag) {
                                $eventGroupState[$projectState.selected] =
                                    removeEvent(
                                        $eventGroupState[
                                            $projectState.selected
                                        ],
                                        $eventGroupState[$projectState.selected]
                                            .selected[0],
                                    );
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [
                                    $eventGroupState[$projectState.selected]
                                        .selected[0],
                                ];
                                eventRemoved = true;
                            }

                            if (oDuration && oDuration > 0) {
                                let avgDurationOfWord =
                                    oDuration / numberOfWords;
                                if (eventRemoved) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected[0]
                                    ].start -= avgDurationOfWord;
                                } else {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected[0]
                                    ].end -= avgDurationOfWord;
                                    if (
                                        $eventGroupState[$projectState.selected]
                                            .events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] + 1
                                        ].end > 0
                                    ) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] + 1
                                        ].start -= avgDurationOfWord;
                                    }
                                }
                            }

                            historyState.insert({
                                name: "shift word", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Move Line Up",
                    description:
                        "Shift line backwards to the end of the previous Event",
                    shortName: "shiftLineBack",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0 ||
                            $eventGroupState[$projectState.selected].selected[0]
                        ) {
                            try {
                                let eventRemoved = false;
                                let html =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].text;
                                let lines = html.split("</p>");
                                let numberOfTotalWords =
                                    html.split(/\s+/g).length;
                                let firstLine = lines.shift();
                                let plainTextLine =
                                    convertToPlainText(firstLine);
                                let numberOfWordsInLine =
                                    plainTextLine.split(/\s+/g).length;
                                let oStart =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].start;
                                let oEnd =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].end;
                                let oDuration = oEnd - oStart;

                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] - 1
                                ].text += firstLine + "</p>";

                                /* Remove first word from event */
                                if (quillEditor) {
                                    quillEditor.deleteText(
                                        0,
                                        plainTextLine.length + 1,
                                    );
                                    if (quillEditor.getLength() === 1) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ] = removeEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0],
                                        );
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected = [
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] - 1,
                                        ];
                                        eventRemoved = true;
                                    }
                                }

                                if (oDuration && oDuration > 0) {
                                    let avgDurationOfLine =
                                        (oDuration / numberOfTotalWords) *
                                        numberOfWordsInLine;
                                    if (eventRemoved) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end += avgDurationOfLine;
                                    } else {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start += avgDurationOfLine;
                                        if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] - 1
                                            ].end > 0
                                        ) {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] - 1
                                            ].end += avgDurationOfLine;
                                        }
                                    }
                                }

                                historyState.insert({
                                    name: "shift line", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            } catch (err) {
                                console.log(err, err.message);
                            }
                        }
                    },
                },
                {
                    name: "Move Line Down",
                    description:
                        "Shift line forward to the beginning of the next Event",
                    shortName: "shiftLineForward",
                    keyCmd: "",

                    action: async function () {
                        if (
                            $eventGroupState[$projectState.selected].selected
                                .length > 0 &&
                            $eventGroupState[$projectState.selected]
                                .selected[0] !==
                                $eventGroupState[$projectState.selected].events
                                    .length -
                                    1
                        ) {
                            try {
                                let eventRemoved = false;
                                let html =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].text;
                                let numberOfTotalWords =
                                    html.split(/\s+/g).length;
                                let lines = html
                                    .split("</p>")
                                    .filter((line) => {
                                        return line;
                                    });
                                let lastLine = lines.pop();
                                let plainTextLine =
                                    convertToPlainText(lastLine);
                                let numberOfWordsInLine =
                                    plainTextLine.split(/\s+/g).length;
                                let oStart =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].start;
                                let oEnd =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].end;
                                let oDuration = oEnd - oStart;

                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] + 1
                                ].text =
                                    lastLine +
                                    "</p>" +
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1
                                    ].text;

                                /* Remove first word from event */
                                if (quillEditor) {
                                    quillEditor.deleteText(
                                        quillEditor.getLength() -
                                            plainTextLine.length -
                                            1,
                                        plainTextLine.length + 1,
                                    );
                                    if (quillEditor.getLength() === 1) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ] = removeEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0],
                                        );
                                    }
                                }

                                if (oDuration && oDuration > 0) {
                                    let avgDurationOfLine =
                                        (oDuration / numberOfTotalWords) *
                                        numberOfWordsInLine;
                                    if (eventRemoved) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end -= avgDurationOfLine;
                                    } else {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].end -= avgDurationOfLine;
                                        if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] + 1
                                            ].end > 0
                                        ) {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] + 1
                                            ].start -= avgDurationOfLine;
                                        }
                                    }
                                }

                                historyState.insert({
                                    name: "shift line", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            } catch (err) {
                                console.log(err, err.message);
                            }
                        }
                    },
                },
                {
                    name: "Split Event",
                    description:
                        "Split the selected event based on the cursor location",
                    shortName: "splitEvent",
                    keyCmd: "",

                    action: async function () {
                        try {
                            let range = quillEditor.getSelection();
                            let length = quillEditor.getLength();
                            if (range && range.index < length - 1) {
                                let newEventEnd;
                                let start =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].start;
                                let end =
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].end;
                                let totalWordCount = getWordCount(
                                    $eventGroupState[$projectState.selected]
                                        .events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0]
                                    ].text,
                                );

                                /* Split the event */
                                if (quillEditor) {
                                    quillEditor.setSelection(
                                        range.index,
                                        quillEditor.getLength(),
                                    );
                                    document.execCommand("cut");
                                    navigator.clipboard
                                        .readText()
                                        .then((text) => {
                                            if (
                                                !isNaN(start) &&
                                                !isNaN(end) &&
                                                end > start
                                            ) {
                                                let duration = end - start;
                                                let wpmAvg =
                                                    duration / totalWordCount;
                                                let event1WordCount =
                                                    getWordCount(
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].events[
                                                            $eventGroupState[
                                                                $projectState
                                                                    .selected
                                                            ].selected[0]
                                                        ].text,
                                                    );
                                                let event2WordCount =
                                                    getWordCount(text);
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].end =
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].start +
                                                    event1WordCount * wpmAvg;
                                                newEventEnd =
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].end +
                                                    event2WordCount * wpmAvg;
                                            }

                                            // console.log(text);
                                            // console.log(convertToHtml(text));
                                            let options = {
                                                rate: $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].rate,
                                                voice: $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].voice,
                                                alignment:
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].alignment,
                                                xPos: $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].xPos,
                                                yPos: $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].yPos,
                                                xOffset:
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].xOffset,
                                                yOffset:
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].yOffset,
                                                vertical:
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].vertical,
                                                style: $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].style,
                                                speakingStyle:
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].speakingStyle,
                                                text: convertToHtml(text),
                                                start: $eventGroupState[
                                                    $projectState.selected
                                                ].events[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].selected[0]
                                                ].end,
                                                end:
                                                    newEventEnd ||
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[
                                                        $eventGroupState[
                                                            $projectState
                                                                .selected
                                                        ].selected[0]
                                                    ].end,
                                            };

                                            $eventGroupState[
                                                $projectState.selected
                                            ] = insertEvent(
                                                $eventGroupState[
                                                    $projectState.selected
                                                ],
                                                options,
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] + 1,
                                            );
                                        });

                                    historyState.insert({
                                        name: "split event", //action name
                                        eventGroup: $projectState.selected,
                                        snapshots: [
                                            {
                                                store: "eventGroupState",
                                                value: JSON.stringify(
                                                    $eventGroupState,
                                                ),
                                            },
                                        ],
                                    });
                                }
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Merge Up",
                    description:
                        "Merge the selected Event with the previous Event.",
                    shortName: "mergeEventUp",
                    keyCmd: "",

                    action: async function () {
                        try {
                            //Merge the selected event with the previous event
                            //Check that there is only one selected event in the event group and it is not the first event.
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 1 &&
                                $eventGroupState[$projectState.selected]
                                    .selected[0] > 0
                            ) {
                                let eventIndex =
                                    $eventGroupState[$projectState.selected]
                                        .selected[0];
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex - 1
                                ].text +=
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text;
                                if (
                                    !isNaN(
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].end,
                                    )
                                ) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex - 1].end =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].end;
                                }
                                //Merge Tags, speakers, and metadata
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex - 1
                                ].tags = [
                                    ...new Set(
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex - 1].tags.concat(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].tags,
                                        ),
                                    ),
                                ];

                                $eventGroupState[$projectState.selected].events[
                                    eventIndex - 1
                                ].speakers = [
                                    ...new Set(
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            eventIndex - 1
                                        ].speakers.concat(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].speakers,
                                        ),
                                    ),
                                ];

                                $eventGroupState[$projectState.selected].events[
                                    eventIndex - 1
                                ].metadata = {
                                    ...$eventGroupState[$projectState.selected]
                                        .events[eventIndex - 1].metadata,
                                    ...$eventGroupState[$projectState.selected]
                                        .events[eventIndex].metadata,
                                };
                                //set rendered to false
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex - 1
                                ].rendered = false;

                                //Select the previous event
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [eventIndex - 1];

                                //Delete the merged event
                                $eventGroupState[$projectState.selected] =
                                    removeEvent(
                                        $eventGroupState[
                                            $projectState.selected
                                        ],
                                        eventIndex,
                                    );

                                //Save to Undo/Redo history
                                historyState.insert({
                                    name: "merge events", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            } else {
                                //notify user we are unable to merge with previous event using toast
                                toast.push(
                                    "Unable to merge with previous event",
                                    {
                                        classes: ["toast-warning"],
                                    },
                                );
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Merge Down",
                    description:
                        "Merge the selected Event with the next Event.",
                    shortName: "mergeEventDown",
                    keyCmd: "",
                    action: async function () {
                        try {
                            //Merge the selected event with the next event
                            //Check that there is only one selected event in the event group and it is not the last event.
                            if (
                                $eventGroupState[$projectState.selected] &&
                                $eventGroupState[$projectState.selected]
                                    .selected.length === 1 &&
                                $eventGroupState[$projectState.selected]
                                    .selected[0] <
                                    $eventGroupState[$projectState.selected]
                                        .events.length -
                                        1
                            ) {
                                let eventIndex =
                                    $eventGroupState[$projectState.selected]
                                        .selected[0];
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].text +=
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex + 1].text;
                                if (
                                    !isNaN(
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex + 1].end,
                                    )
                                ) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].end =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex + 1].end;
                                }
                                //Merge Tags, speakers, and metadata
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].tags = [
                                    ...new Set(
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].tags.concat(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1].tags,
                                        ),
                                    ),
                                ];

                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].speakers = [
                                    ...new Set(
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].speakers.concat(
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1].speakers,
                                        ),
                                    ),
                                ];

                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].metadata = {
                                    ...$eventGroupState[$projectState.selected]
                                        .events[eventIndex].metadata,
                                    ...$eventGroupState[$projectState.selected]
                                        .events[eventIndex + 1].metadata,
                                };
                                //set rendered to false
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].rendered = false;

                                //Select the merged event
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [eventIndex];

                                //Delete the next event
                                $eventGroupState[$projectState.selected] =
                                    removeEvent(
                                        $eventGroupState[
                                            $projectState.selected
                                        ],
                                        eventIndex + 1,
                                    );

                                if (quillEditor) {
                                    quillEditor.clipboard.dangerouslyPasteHTML(
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].text,
                                    );
                                    quillEditor.setSelection(
                                        0,
                                        quillEditor.getLength(),
                                    );
                                    quillEditor.format(
                                        "align",
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].alignment,
                                    );
                                    if ($editorState.editing) {
                                        /* Move cursor to end of event text */
                                        quillEditor.setSelection(
                                            quillEditor.getLength(),
                                            0,
                                        );
                                    } else {
                                        quillEditor.blur();
                                    }
                                }

                                //Save to Undo/Redo history
                                historyState.insert({
                                    name: "merge events", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            } else {
                                //notify user we are unable to merge with next event using toast
                                toast.push("Unable to merge with next event", {
                                    classes: ["toast-warning"],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Chain To Previous Event",
                    description:
                        "Snap the Event incode to the previous Event's outcode",
                    shortName: "snapToEnd",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected]
                                    .selected[0] > 0
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].start =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1
                                    ].end;
                                historyState.insert({
                                    name: "snap to start", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Chain To Next Event",
                    description:
                        "Snap the Event outcode to the next Event's incode",
                    shortName: "snapToStart",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if (
                                $eventGroupState[$projectState.selected]
                                    .selected[0] <
                                $eventGroupState[$projectState.selected].events
                                    .length -
                                    1
                            ) {
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[
                                        $projectState.selected
                                    ].selected[0]
                                ].end =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1
                                    ].start;
                                historyState.insert({
                                    name: "snap to end", //action name
                                    eventGroup: $projectState.selected,
                                    snapshots: [
                                        {
                                            store: "eventGroupState",
                                            value: JSON.stringify(
                                                $eventGroupState,
                                            ),
                                        },
                                    ],
                                });
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Expand",
                    description: "Expand the event text horizontally",
                    shortName: "expand",
                    keyCmd: "",

                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach(
                                (eventIndex, selectedEventIndex) => {
                                    let lineInfo = [];
                                    let longestLineIndex = 0;
                                    let longestLength = 0;
                                    let shorterLine;
                                    let newTextLines = "";
                                    if (
                                        !$eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].text
                                    ) {
                                        return;
                                    }

                                    let textLines = $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text
                                        .split("</p>")
                                        .filter((textLine) => {
                                            return (
                                                textLine.replace(
                                                    /(<([^>]+)>)/gi,
                                                    "",
                                                ).length > 0
                                            );
                                        });

                                    if (textLines.length > 1) {
                                        textLines.forEach((line, index) => {
                                            let cleanHtml = line.replace(
                                                /(<([^>]+)>)/gi,
                                                "",
                                            );

                                            if (
                                                cleanHtml.length > longestLength
                                            ) {
                                                longestLength =
                                                    cleanHtml.length;
                                                longestLineIndex = index;
                                            }
                                            if (cleanHtml.length > 0) {
                                                lineInfo.push({
                                                    index: index,
                                                    html: line,
                                                    clean: cleanHtml,
                                                    length: cleanHtml.length,
                                                });
                                            }
                                        });

                                        if (
                                            lineInfo[longestLineIndex + 1] &&
                                            lineInfo[longestLineIndex - 1]
                                        ) {
                                            if (
                                                lineInfo[longestLineIndex + 1]
                                                    .length <
                                                lineInfo[longestLineIndex - 1]
                                                    .length
                                            ) {
                                                shorterLine =
                                                    lineInfo[
                                                        longestLineIndex + 1
                                                    ].clean.split(" ");
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean +=
                                                    " " + shorterLine.shift();
                                                lineInfo[
                                                    longestLineIndex + 1
                                                ].clean = shorterLine.join(" ");
                                            } else {
                                                shorterLine =
                                                    lineInfo[
                                                        longestLineIndex - 1
                                                    ].clean.split(" ");
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean =
                                                    shorterLine.pop() +
                                                    " " +
                                                    lineInfo[longestLineIndex]
                                                        .clean;
                                                lineInfo[
                                                    longestLineIndex - 1
                                                ].clean = shorterLine.join(" ");
                                            }
                                        } else if (
                                            lineInfo[longestLineIndex + 1]
                                        ) {
                                            shorterLine =
                                                lineInfo[
                                                    longestLineIndex + 1
                                                ].clean.split(" ");
                                            lineInfo[longestLineIndex].clean +=
                                                " " + shorterLine.shift();
                                            lineInfo[
                                                longestLineIndex + 1
                                            ].clean = shorterLine.join(" ");
                                        } else {
                                            shorterLine =
                                                lineInfo[
                                                    longestLineIndex - 1
                                                ].clean.split(" ");
                                            lineInfo[longestLineIndex].clean =
                                                shorterLine.pop() +
                                                " " +
                                                lineInfo[longestLineIndex]
                                                    .clean;
                                            lineInfo[
                                                longestLineIndex - 1
                                            ].clean = shorterLine.join(" ");
                                        }

                                        lineInfo.forEach((line) => {
                                            if (line.clean.length > 0) {
                                                newTextLines +=
                                                    line.html.split(">")[0] +
                                                    ">" +
                                                    line.clean.trim() +
                                                    "</p>";
                                            }
                                        });

                                        if (
                                            selectedEventIndex === 0 &&
                                            quillEditor
                                        ) {
                                            quillEditor.clipboard.dangerouslyPasteHTML(
                                                newTextLines,
                                            );
                                        } else {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].text =
                                                newTextLines;
                                        }
                                    }
                                },
                            );

                            historyState.insert({
                                name: "expand event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Compress",
                    description: "Compress the event text vertically",
                    shortName: "compress",
                    keyCmd: "",

                    action: async function () {
                        try {
                            let alignmentMap = {
                                left: false,
                                right: "right",
                                center: "center",
                            };
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach(
                                (eventIndex, selectedEventIndex) => {
                                    let lineInfo = [];
                                    let longestLineIndex = 0;
                                    let longestLength = 0;
                                    let longerLine;
                                    let newTextLines = "";
                                    if (
                                        !$eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].text
                                    ) {
                                        return;
                                    }
                                    let textLines = $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].text
                                        .split("</p>")
                                        .filter((textLine) => {
                                            return (
                                                textLine.replace(
                                                    /(<([^>]+)>)/gi,
                                                    "",
                                                ).length > 0
                                            );
                                        });

                                    textLines.forEach((line, index) => {
                                        let cleanHtml = line.replace(
                                            /(<([^>]+)>)/gi,
                                            "",
                                        );

                                        if (cleanHtml.length > longestLength) {
                                            longestLength = cleanHtml.length;
                                            longestLineIndex = index;
                                        }
                                        if (cleanHtml.length > 0) {
                                            lineInfo.push({
                                                index: index,
                                                html: line,
                                                clean: cleanHtml,
                                                length: cleanHtml.length,
                                            });
                                        }
                                    });

                                    if (
                                        lineInfo[longestLineIndex].clean.split(
                                            " ",
                                        ).length > 1
                                    ) {
                                        if (
                                            lineInfo[longestLineIndex + 1] &&
                                            lineInfo[longestLineIndex - 1] &&
                                            longestLineIndex !==
                                                lineInfo.length - 1
                                        ) {
                                            if (
                                                lineInfo[longestLineIndex + 1]
                                                    .length -
                                                    2 <
                                                lineInfo[longestLineIndex - 1]
                                                    .length
                                            ) {
                                                longerLine =
                                                    lineInfo[
                                                        longestLineIndex
                                                    ].clean.split(" ");
                                                lineInfo[
                                                    longestLineIndex + 1
                                                ].clean =
                                                    longerLine.pop() +
                                                    " " +
                                                    lineInfo[
                                                        longestLineIndex + 1
                                                    ].clean;
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean = longerLine.join(" ");
                                            } else {
                                                longerLine =
                                                    lineInfo[
                                                        longestLineIndex
                                                    ].clean.split(" ");
                                                lineInfo[
                                                    longestLineIndex - 1
                                                ].clean +=
                                                    " " + longerLine.shift();
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean = longerLine.join(" ");
                                            }
                                        } else if (
                                            lineInfo[longestLineIndex + 1] &&
                                            longestLineIndex !==
                                                lineInfo.length - 1
                                        ) {
                                            longerLine =
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean.split(" ");
                                            lineInfo[
                                                longestLineIndex + 1
                                            ].clean =
                                                longerLine.pop() +
                                                " " +
                                                lineInfo[longestLineIndex + 1]
                                                    .clean;
                                            lineInfo[longestLineIndex].clean =
                                                longerLine.join(" ");
                                        } else if (
                                            lineInfo[longestLineIndex - 1] &&
                                            longestLineIndex !==
                                                lineInfo.length - 1
                                        ) {
                                            longerLine =
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean.split(" ");
                                            lineInfo[
                                                longestLineIndex - 1
                                            ].clean += " " + longerLine.shift();
                                            lineInfo[longestLineIndex].clean =
                                                longerLine.join(" ");
                                        } else {
                                            /* create a new line */
                                            longerLine =
                                                lineInfo[
                                                    longestLineIndex
                                                ].clean.split(" ");

                                            lineInfo.push({
                                                index: longestLineIndex + 1,
                                                html: "<p>",
                                                clean: longerLine.pop(),
                                                length: null,
                                            });

                                            lineInfo[longestLineIndex].clean =
                                                longerLine.join(" ");
                                        }

                                        lineInfo.forEach((line) => {
                                            newTextLines +=
                                                line.html.split(">")[0] +
                                                ">" +
                                                line.clean.trim() +
                                                "</p>";
                                        });

                                        if (
                                            selectedEventIndex === 0 &&
                                            quillEditor
                                        ) {
                                            quillEditor.clipboard.dangerouslyPasteHTML(
                                                newTextLines,
                                            );
                                            quillEditor.setSelection(
                                                0,
                                                quillEditor.getLength(),
                                            );
                                            quillEditor.format(
                                                "align",
                                                alignmentMap[
                                                    $eventGroupState[
                                                        $projectState.selected
                                                    ].events[eventIndex]
                                                        .alignment
                                                ],
                                            );
                                            quillEditor.setSelection(
                                                quillEditor.getLength(),
                                                0,
                                            );
                                        } else {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].text =
                                                newTextLines;
                                        }
                                    }
                                },
                            );

                            historyState.insert({
                                name: "compress event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Auto Format",
                    description:
                        "Auto format the currently selected event based on the Event Group Settings",
                    shortName: "autoFormat",
                    keyCmd: "",
                    action: async function () {
                        if (
                            !$eventGroupState[$projectState.selected] ||
                            $eventGroupState[$projectState.selected].selected
                                .length === 0
                        ) {
                            return;
                        }

                        try {
                            $eventGroupState[$projectState.selected] =
                                autoFormat(
                                    $eventGroupState[$projectState.selected],
                                    $eventGroupState[$projectState.selected]
                                        .maxLines,
                                    $eventGroupState[$projectState.selected]
                                        .maxChars,
                                    $eventGroupState[$projectState.selected]
                                        .minDuration,
                                    true,
                                    $eventGroupState[$projectState.selected]
                                        .selected,
                                );

                            if (quillEditor) {
                                try {
                                    quillEditor.clipboard.dangerouslyPasteHTML(
                                        $eventGroupState[$projectState.selected]
                                            .events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].text,
                                    );
                                } catch (err) {
                                    console.log(err, err.message);
                                }
                            }

                            historyState.insert({
                                name: "auto format", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Order Events by Time",
                    description: "Sort the Event List by start time.",
                    shortName: "orderByTime",
                    keyCmd: "",

                    action: async function () {
                        $eventGroupState[$projectState.selected].selected = [];
                        $eventGroupState[$projectState.selected] = orderByTime(
                            $eventGroupState[$projectState.selected],
                        );
                        historyState.insert({
                            name: "ordering", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });

                        toast.push("Events ordered by time successfully", {
                            classes: ["toast-success"],
                        });
                    },
                },
                {
                    name: "Clear Event Text",
                    description: "Clear the text of the selected Events",
                    shortName: "clearEventText",
                    keyCmd: "",
                    action: async function () {
                        if (
                            !$eventGroupState[$projectState.selected] ||
                            $eventGroupState[$projectState.selected].selected
                                .length === 0
                        ) {
                            return;
                        }

                        //Ask the user if they're sure they want to clear the Event Text using sweetalert
                        let result = await Swal.fire({
                            title: "Are you sure?",
                            text: "This will clear the text of the selected Events.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "Cancel",
                            reverseButtons: true,
                        });

                        if (!result.isConfirmed) {
                            return;
                        }

                        $eventGroupState[
                            $projectState.selected
                        ].selected.forEach((eventIndex, order) => {
                            $eventGroupState[$projectState.selected].events[
                                eventIndex
                            ].text = "";
                            try {
                                if (quillEditor && order === 0) {
                                    quillEditor.setText("");
                                }
                            } catch (err) {
                                console.log(err, err.message);
                            }
                        });

                        historyState.insert({
                            name: "clear text", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    },
                },
                {
                    name: "Clear Timecodes",
                    description: "Clear the timecodes of the selected Events",
                    shortName: "clearTimecodes",
                    keyCmd: "",
                    action: async function () {
                        if (
                            !$eventGroupState[$projectState.selected] ||
                            $eventGroupState[$projectState.selected].selected
                                .length === 0
                        ) {
                            return;
                        }

                        //Ask the user if they're sure they want to clear the Event Text using sweetalert
                        let result = await Swal.fire({
                            title: "Are you sure?",
                            text: "This will clear the timecodes of the selected Events.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "Cancel",
                            reverseButtons: true,
                        });

                        if (!result.isConfirmed) {
                            return;
                        }

                        $eventGroupState[
                            $projectState.selected
                        ].selected.forEach((eventIndex, order) => {
                            $eventGroupState[$projectState.selected].events[
                                eventIndex
                            ].start = false;
                            $eventGroupState[$projectState.selected].events[
                                eventIndex
                            ].end = false;
                        });

                        historyState.insert({
                            name: "clear timecodes", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    },
                },
                {
                    name: "Adjust Duration to Reading Speed",
                    description:
                        "Automatically update the duration of the Event based on the set reading speed (CPS) of the Event Group.",
                    shortName: "adjustDuration",
                    keyCmd: "",
                    action: async function () {
                        if (
                            !$eventGroupState[$projectState.selected] ||
                            $eventGroupState[$projectState.selected].selected
                                .length === 0
                        ) {
                            return;
                        }

                        let cps =
                            $eventGroupState[$projectState.selected].maxCps ||
                            22;
                        let minDuration =
                            $eventGroupState[$projectState.selected]
                                .minDuration || 0.83;
                        let currentTime = player ? player.currentTime : 0;

                        $eventGroupState[$projectState.selected].selected
                            .sort()
                            .forEach((eventIndex) => {
                                try {
                                    let event =
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex];
                                    let text = convertToPlainText(
                                        event.text,
                                        "",
                                    );
                                    let characters = text.length;

                                    if (characters === 0 || cps === 0) {
                                        return;
                                    }

                                    let duration = Math.max(
                                        minDuration,
                                        characters / cps,
                                    );
                                    if (event.start > 0 && event.end > 0) {
                                        //Check if the next event exists, and if updating the end time of the event will cause an overlap
                                        if (
                                            !$eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1]
                                        ) {
                                            //If the next event does not exist, update the end time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        } else if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1].start >=
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start +
                                                duration
                                        ) {
                                            //If the next event exists and there is no overlap, update the end time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        } else if (
                                            !$eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1] &&
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end -
                                                duration >=
                                                0
                                        ) {
                                            //If the previous event does not exist, update the start time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        } else if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1].end <=
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end -
                                                duration
                                        ) {
                                            //If the previous event exists and there is no overlap, update the start time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        } else {
                                            //Just update the end of the Event and deal with the overlap.
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        }
                                    } else if (event.start > 0) {
                                        if (
                                            !$eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1]
                                        ) {
                                            //If the next event does not exist, update the end time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        } else if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1].start >=
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start +
                                                duration
                                        ) {
                                            //If the next event exists and there is no overlap, update the end time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        } else if (
                                            !$eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1]
                                        ) {
                                            //If the previous event does not exist, update the start time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex + 1].start;
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        } else if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1].end <=
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1].start -
                                                duration
                                        ) {
                                            //If the previous event exists and there is no overlap, update the start time of the event
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex + 1].start;
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        } else {
                                            //Just update the end of the Event and deal with the overlap.
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        }
                                    } else if (event.end > 0) {
                                        //End time exists so we base the start time on that
                                        if (
                                            !$eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1]
                                        ) {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        } else if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1].end <=
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end -
                                                duration
                                        ) {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        } else if (
                                            !$eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1]
                                        ) {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex - 1].end;
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        } else if (
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex + 1].start >=
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1].end +
                                                duration
                                        ) {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex - 1].end;
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].end =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].start +
                                                duration;
                                        } else {
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex].start =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex].end -
                                                duration;
                                        }
                                    } else {
                                        if (
                                            currentTime === 0 &&
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1] &&
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[eventIndex - 1].end > 0
                                        ) {
                                            currentTime =
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].events[eventIndex - 1].end;
                                        }

                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].start =
                                            currentTime;
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[eventIndex].end =
                                            currentTime + duration;
                                        currentTime = currentTime + duration;
                                    }
                                } catch (err) {
                                    console.log(err, err.message);
                                }
                            });

                        historyState.insert({
                            name: "adjust duration", //action name
                            eventGroup: $projectState.selected,
                            snapshots: [
                                {
                                    store: "eventGroupState",
                                    value: JSON.stringify($eventGroupState),
                                },
                            ],
                        });
                    },
                },
            ],
        },
        {
            name: "Timeline",
            shortcuts: [
                {
                    name: "Zoom In",
                    description: "Zoom in on the Timeline",
                    shortName: "timelineZoomIn",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (window.ws) {
                                ws.zoom(
                                    Math.min(
                                        1000,
                                        ws.options.minPxPerSec * 1.5,
                                    ),
                                );
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                {
                    name: "Zoom Out",
                    description: "Zoom out on the Timeline",
                    shortName: "timelineZoomOut",
                    keyCmd: "",
                    action: async function () {
                        try {
                            if (window.ws) {
                                ws.zoom(
                                    Math.max(5, ws.options.minPxPerSec / 1.5),
                                );
                            }
                        } catch (err) {
                            console.log(err, err.message);
                        }
                    },
                },
                /* Select Previous Event Closest to playhead (player.currentTime) */
                {
                    name: "Select Previous Event",
                    description: "Selects the previous event closest to the current player time",
                    shortName: "selectPrevEvent",
                    keyCmd: "", // Assign a key command if needed
                    action: async function () {
                        if (!player){
                            return;
                        }

                        //Find the closest event to the current time (player.currentTime) based on the event.end being closest to player.currentTime. There may be multiple Events that are before the current time so we need to find the closest one.
                        let closestEventIndex = -1;
                        let closestEventTime = 0;
                        $eventGroupState[$projectState.selected].events.forEach((event, index) => {
                            if (event.end <= player.currentTime && player.currentTime - event.end < player.currentTime - closestEventTime) {
                                closestEventIndex = index;
                                closestEventTime = event.end;
                            }
                        });

                        if (closestEventIndex >= 0) {
                            $eventGroupState[$projectState.selected].selected = [closestEventIndex];
                            player.currentTime = $eventGroupState[$projectState.selected].events[closestEventIndex].start;
                        }
                    },
                },
                /* Select the next event closest to the playhead */
                {
                    name: "Select Next Event",
                    description: "Selects the next event closest to the current player time",
                    shortName: "selectNextEvent",
                    keyCmd: "", // Assign a key command if needed
                    action: async function () {
                        if (!player){
                            return;
                        }

                        //Find the closest event to the current time (player.currentTime) based on the event.start being closest to player.currentTime. There may be multiple Events that are after the current time so we need to find the closest one.
                        let playerTime = player.currentTime.toFixed(2);
                        $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);
                        let closestEventIndex = $eventGroupState[$projectState.selected].events.findIndex((ev, index) =>{
                            return ev.start && ev.start > playerTime && index !== $eventGroupState[$projectState.selected].selected[0];
                        })

                        if (closestEventIndex >= 0) {
                            $eventGroupState[$projectState.selected].selected = [closestEventIndex];
                            player.currentTime = $eventGroupState[$projectState.selected].events[closestEventIndex].start;
                        }
                    },
                }
            ],
        },
        {
            name: "Audio Description",
            shortcuts: [
                {
                    name: "Render Text-to-Speech",
                    description: "Render Audio Description (DV) text to audio.",
                    shortName: "renderAudio",
                    keyCmd: "",

                    action: async function () {
                        try {
                            if ($authState.ad) {
                                modalState.showModal("renderAudio");
                            } else {
                                throw new Error(
                                    "Audio Description Plugin Not Activated",
                                );
                            }
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to render text-to-speech: " +
                                    err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Trim To Audio Duration",
                    description:
                        "Update the outcode of selected audio descripiton Events to match the duration of the rendered audio.",
                    shortName: "trimToDuration",
                    keyCmd: "",

                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                if (
                                    $eventGroupState[$projectState.selected]
                                        .events[eventIndex].audioFileDuration >
                                        0 &&
                                    $eventGroupState[$projectState.selected]
                                        .events[eventIndex].start >= 0
                                ) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[eventIndex].end =
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex].start +
                                        $eventGroupState[$projectState.selected]
                                            .events[eventIndex]
                                            .audioFileDuration +
                                        0.2;
                                }
                            });

                            $eventGroupState[$projectState.selected] =
                                $eventGroupState[$projectState.selected];

                            historyState.insert({
                                name: "trim to duration", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });

                            toast.push("Event(s) trimmed to audio duration", {
                                classes: ["toast-success"],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Trim to audio duration failed with message: " +
                                    err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
            ],
        },
        {
            name: "Closed Captioning",
            shortcuts: [
                {
                    name: "Position Top Left",
                    description: "Position Events to the top left of the video",
                    shortName: "positionTopLeft",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "start";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "start";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Position Top Center",
                    description:
                        "Position Events to the top center of the video",
                    shortName: "positionTopCenter",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "start";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "center";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Position Top Right",
                    description:
                        "Position Events to the top right of the video",
                    shortName: "positionTopRight",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "start";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "end";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                /* Position Shortcuts */
                {
                    name: "Position Center Left",
                    description:
                        "Position Events to the center left of the video",
                    shortName: "positionCenterLeft",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "center";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "start";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Position Center",
                    description: "Position Events to the center of the video",
                    shortName: "positionCenter",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "center";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "center";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Position Center Right",
                    description:
                        "Position Events to the center right of the video",
                    shortName: "positionCenterRight",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "center";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "end";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                /* Position Shortcuts */
                {
                    name: "Position Bottom Left",
                    description:
                        "Position Events to the bottom left of the video",
                    shortName: "positionBottomLeft",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "end";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "start";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Position Bottom Center",
                    description:
                        "Position Events to the bottom center of the video",
                    shortName: "positionBottomCenter",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "end";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "center";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Position Bottom Right",
                    description:
                        "Position Events to the bottom right of the video",
                    shortName: "positionBottomRight",
                    keyCmd: "",
                    action: async function () {
                        try {
                            $eventGroupState[
                                $projectState.selected
                            ].selected.forEach((eventIndex) => {
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yPos = "end";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xPos = "end";
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].xOffset = 0;
                                $eventGroupState[$projectState.selected].events[
                                    eventIndex
                                ].yOffset = 0;
                            });

                            historyState.insert({
                                name: "position event(s)", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        } catch (err) {
                            console.log(err, err.message);
                            toast.push(
                                "Failed to position events: " + err.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );
                        }
                    },
                },
                {
                    name: "Move Up 1 Line ",
                    description: "Move the Event position up 1 line",
                    shortName: "positionUpLine",
                    keyCmd: "",
                    action: async function () {
                        let playerHeight =
                            document.getElementById(
                                "PlayerWrapper",
                            ).clientHeight;
                        let pxUnits = (playerHeight / 19).toFixed(4); //Number of Pixels per line (19 lines)
                        $eventGroupState[
                            $projectState.selected
                        ].selected.forEach((evIndex) => {
                            try {
                                /* Get current Position */
                                let currentPos;
                                let numberOfTextLines = getLineCount(
                                    $eventGroupState[$projectState.selected]
                                        .events[evIndex].text,
                                );
                                //Get the current position pased on origin (Start, center, end) and the offset.
                                if (
                                    $eventGroupState[$projectState.selected]
                                        .events[evIndex].yPos === "start"
                                ) {
                                    //(pxUnits*2) is the padding or first two lines. plus the yOffset.
                                    currentPos =
                                        pxUnits * 2 +
                                        $eventGroupState[$projectState.selected]
                                            .events[evIndex].yOffset;
                                } else if (
                                    $eventGroupState[$projectState.selected]
                                        .events[evIndex].yPos === "end"
                                ) {
                                    //(pxUnits*17) Places us at the bottom of the screen, and then we subtract the yOffset to figure out how high up we are.
                                    currentPos =
                                        pxUnits * 17 +
                                        $eventGroupState[$projectState.selected]
                                            .events[evIndex].yOffset;
                                } else {
                                    if (numberOfTextLines % 2 === 0) {
                                        currentPos =
                                            pxUnits * 9.5 +
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[evIndex].yOffset;
                                    } else {
                                        currentPos =
                                            pxUnits * 9.5 +
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[evIndex].yOffset +
                                            pxUnits / 2;
                                    }
                                }

                                //round currentPos to the closest multiple of pxUnits
                                currentPos =
                                    Math.round(currentPos / pxUnits) * pxUnits;
                                let currentLine = Math.round(
                                    currentPos / pxUnits,
                                );
                                // console.log(pxUnits, currentPos, currentLine);

                                /* Add pxUnits for one line up */
                                let line = currentLine - 1;
                                // console.log("NEW POS: "+ line);

                                if (line <= 8) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yPos = "start";
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yOffset = parseInt(
                                        Math.max(0, (line - 2) * pxUnits),
                                    );
                                } else if (line >= 13) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yPos = "end";
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yOffset = parseInt(
                                        Math.min(0, (line - 17) * pxUnits),
                                    );
                                } else {
                                    /* console.log(
                                        parseInt((line - 9.5) * pxUnits),
                                        Math.round((line - 9.5) * pxUnits),
                                        (line - 9.5) * pxUnits,
                                    ); */
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yPos = "center";
                                    if (numberOfTextLines % 2 === 0) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[evIndex].yOffset = Math.round(
                                            (line - 9.5) * pxUnits,
                                        );
                                    } else {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[evIndex].yOffset = Math.round(
                                            (line - 9.5) * pxUnits -
                                                pxUnits / 2,
                                        );
                                    }
                                }
                            } catch (err) {
                                console.error("Position line up error");
                                console.error(err.message);
                                console.log(err);
                            }

                            historyState.insert({
                                name: "position line up", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        });
                    },
                },
                {
                    name: "Move Down 1 Line ",
                    description: "Move the Event position down 1 line",
                    shortName: "positionDownLine",
                    keyCmd: "",
                    action: async function () {
                        let playerHeight =
                            document.getElementById(
                                "PlayerWrapper",
                            ).clientHeight;
                        let pxUnits = playerHeight / 19;
                        $eventGroupState[
                            $projectState.selected
                        ].selected.forEach((evIndex) => {
                            try {
                                /* Get current Position */
                                let currentPos;
                                let numberOfTextLines = getLineCount(
                                    $eventGroupState[$projectState.selected]
                                        .events[evIndex].text,
                                );
                                //Get the current position pased on origin (Start, center, end) and the offset.
                                if (
                                    $eventGroupState[$projectState.selected]
                                        .events[evIndex].yPos === "start"
                                ) {
                                    //(pxUnits*2) is the padding or first two lines. plus the yOffset.
                                    currentPos =
                                        pxUnits * 2 +
                                        $eventGroupState[$projectState.selected]
                                            .events[evIndex].yOffset;
                                } else if (
                                    $eventGroupState[$projectState.selected]
                                        .events[evIndex].yPos === "end"
                                ) {
                                    //(pxUnits*17) Places us at the bottom of the screen, and then we subtract the yOffset to figure out how high up we are.
                                    currentPos =
                                        pxUnits * 17 +
                                        $eventGroupState[$projectState.selected]
                                            .events[evIndex].yOffset;
                                } else {
                                    if (numberOfTextLines % 2 === 0) {
                                        currentPos =
                                            pxUnits * 9.5 +
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[evIndex].yOffset;
                                    } else {
                                        currentPos =
                                            pxUnits * 9.5 +
                                            $eventGroupState[
                                                $projectState.selected
                                            ].events[evIndex].yOffset +
                                            pxUnits / 2;
                                    }
                                }

                                //round currentPos to the closest multiple of pxUnits
                                currentPos =
                                    Math.round(currentPos / pxUnits) * pxUnits;
                                let currentLine = Math.round(
                                    currentPos / pxUnits,
                                );
                                // console.log(pxUnits, currentPos, currentLine);

                                /* Add pxUnits for one line up */
                                let line = currentLine + 1;
                                // console.log("NEW POS: "+ line);

                                if (line <= 8) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yPos = "start";
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yOffset = parseInt(
                                        Math.max(0, (line - 2) * pxUnits),
                                    );
                                } else if (line >= 13) {
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yPos = "end";
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yOffset = parseInt(
                                        Math.min(0, (line - 17) * pxUnits),
                                    );
                                } else {
                                    /* console.log(
                                        parseInt((line - 9.5) * pxUnits),
                                        Math.round((line - 9.5) * pxUnits),
                                        (line - 9.5) * pxUnits,
                                    ); */
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[evIndex].yPos = "center";
                                    if (numberOfTextLines % 2 === 0) {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[evIndex].yOffset = Math.round(
                                            (line - 9.5) * pxUnits,
                                        );
                                    } else {
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[evIndex].yOffset = Math.round(
                                            (line - 9.5) * pxUnits -
                                                pxUnits / 2,
                                        );
                                    }
                                }
                            } catch (err) {
                                console.error("Position line down error");
                                console.error(err.message);
                                console.log(err);
                            }

                            historyState.insert({
                                name: "position line down", //action name
                                eventGroup: $projectState.selected,
                                snapshots: [
                                    {
                                        store: "eventGroupState",
                                        value: JSON.stringify($eventGroupState),
                                    },
                                ],
                            });
                        });
                    },
                },
            ],
        },
    ];

    function updateShortcut(e) {
        e.preventDefault();
        e.stopPropagation();

        if ([16, 17, 18, 91, 93, 224].indexOf(e.keyCode) > -1) {
            return;
        }

        let keyCmd = [];
        if (e.shiftKey) {
            keyCmd.push("Shift");
        }

        if (e.ctrlKey) {
            keyCmd.push("Ctrl");
        }

        if (e.altKey) {
            keyCmd.push("Alt");
        }

        if (e.metaKey) {
            keyCmd.push("Meta(CMD)");
        }

        keyCmd.push(e.code || e.key);
        keyCmd = keyCmd.join("+");

        if (keyCmd === "ArrowUp" || keyCmd === "ArrowDown") {
            //Fire Swal event to Alert user that they can't use Arrow Up and Arrow Down because they are reserved
            Swal.fire({
                title: "Error",
                text: "Arrow Up and Arrow Down are reserved and cannot be used as shortcuts",
                icon: "error",
                confirmButtonText: "OK",
            });

            return;
        }

        shortcutGroups.forEach((group) => {
            group.shortcuts.forEach((shortcut) => {
                if (shortcut.keyCmd === keyCmd) {
                    shortcut.keyCmd = "";
                }
            });
        });

        selectedShortcut.keyCmd = keyCmd;
        shortcutGroups = shortcutGroups;
        /* Save update to local storage so we can load them each time the application starts*/
        // console.log(JSON.stringify(shortcutGroups, null, 4));
        saveToLocalStorage();
    }

    function saveToLocalStorage() {
        setTimeout(() => {
            localStorage.setItem(
                "cc-shortcuts",
                JSON.stringify(shortcutGroups),
            );
        }, 25);
    }

    function removeShortcut() {
        selectedShortcut.keyCmd = "";
        saveToLocalStorage();
        shortcutGroups = shortcutGroups;
    }

    function findShortcut(shortcutName, shortcutGroupList) {
        let shortcut, foundShortcut;
        shortcutGroupList.forEach((groupItem) => {
            shortcut = groupItem.shortcuts.find((shortcutItem) => {
                return shortcutItem.name === shortcutName;
            });

            if (shortcut) {
                foundShortcut = shortcut;
            }
        });

        return (
            foundShortcut || {
                keyCmd: "",
            }
        );
    }

    function runShortcut(e) {
        if ($modalState === "") {
            let keyCmd = [];
            if (e.shiftKey) {
                keyCmd.push("Shift");
            }

            if (e.ctrlKey) {
                keyCmd.push("Ctrl");
            }

            if (e.altKey) {
                keyCmd.push("Alt");
            }

            if (e.metaKey) {
                keyCmd.push("Meta(CMD)");
            }

            keyCmd.push(e.code || e.key);
            keyCmd = keyCmd.join("+");
            if (keyCmd) {
                let shortcutFound = shortcutGroups.find((group) => {
                    group.shortcuts.forEach((shortcut) => {
                        if (shortcut.keyCmd === keyCmd) {
                            e.preventDefault();
                            e.stopPropagation();
                            shortcut.action();
                            return true;
                        }
                    });
                });

                //Set up shortcuts for spacebar, and jkl keys
                if (!shortcutFound) {
                    if (
                        keyCmd === "KeyJ" &&
                        !isInputFocused() &&
                        !isContentEditableFocused()
                    ) {
                        //Reverse 1 frame;
                        e.preventDefault();
                        e.stopPropagation();
                        player.currentTime =
                            $playerState.time - 1 / $projectState.frameRate;
                    } else if (
                        (keyCmd === "KeyK" || keyCmd === "Space") &&
                        !isInputFocused() &&
                        !isContentEditableFocused()
                    ) {
                        //Play/Pause
                        if (player.playing) {
                            e.preventDefault();
                            e.stopPropagation();
                            player.pause();
                        } else {
                            player.play();
                        }
                    } else if (
                        keyCmd === "KeyL" &&
                        !isInputFocused() &&
                        !isContentEditableFocused()
                    ) {
                        //Forward 1 frame
                        e.preventDefault();
                        e.stopPropagation();
                        player.currentTime =
                            $playerState.time + 1 / $projectState.frameRate;
                    } else if (keyCmd === "ArrowUp") {
                        if (isContentEditableFocused()) {
                            if (quillEditor.getSelection().index === 0) {
                                e.preventDefault();
                                e.stopPropagation();
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [
                                    Math.max(
                                        0,
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1,
                                    ),
                                ];
                                //if lockstate.video then update the current player time to match the selected events start time
                                if ($lockState.video) {
                                    player.currentTime =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start;
                                }
                            }
                        } else if (!isInputFocused()) {
                            e.preventDefault();
                            e.stopPropagation();
                            $eventGroupState[$projectState.selected].selected =
                                [
                                    Math.max(
                                        0,
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] - 1,
                                    ),
                                ];
                            document
                                .getElementById("EventList")
                                .scrollTo(
                                    0,
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] *
                                        230 -
                                        230,
                                );
                            if (
                                $lockState.video &&
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ]
                            ) {
                                player.currentTime =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected[0]
                                    ].start;
                            }
                        }
                    } else if (keyCmd === "ArrowDown") {
                        //if quillEditor has focus and selection is at end of text, move to next event
                        if (isContentEditableFocused()) {
                            if (
                                quillEditor.getSelection().index + 1 >=
                                quillEditor.getLength()
                            ) {
                                e.preventDefault();
                                e.stopPropagation();

                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [
                                    Math.min(
                                        $eventGroupState[$projectState.selected]
                                            .events.length - 1,
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1,
                                    ),
                                ];

                                if ($lockState.video) {
                                    player.currentTime =
                                        $eventGroupState[
                                            $projectState.selected
                                        ].events[
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0]
                                        ].start;
                                }
                            }
                        } else if (!isInputFocused()) {
                            e.preventDefault();
                            e.stopPropagation();
                            $eventGroupState[$projectState.selected].selected =
                                [
                                    Math.min(
                                        $eventGroupState[$projectState.selected]
                                            .events.length - 1,
                                        $eventGroupState[$projectState.selected]
                                            .selected[0] + 1,
                                    ),
                                ];
                            document
                                .getElementById("EventList")
                                .scrollTo(
                                    0,
                                    $eventGroupState[$projectState.selected]
                                        .selected[0] *
                                        230 -
                                        230,
                                );

                            if (
                                $lockState.video &&
                                $eventGroupState[$projectState.selected].events[
                                    $eventGroupState[$projectState.selected]
                                        .selected[0]
                                ]
                            ) {
                                player.currentTime =
                                    $eventGroupState[
                                        $projectState.selected
                                    ].events[
                                        $eventGroupState[
                                            $projectState.selected
                                        ].selected[0]
                                    ].start;
                            }
                        }
                    } else if ($environment.mac) {
                        if (keyCmd === "Meta(CMD)+KeyF") {
                            e.preventDefault();
                            e.stopPropagation();
                            $uiState.selected = "findAndReplace";
                            $projectState.mode = "edit";

                            setTimeout(() => {
                                try {
                                    document
                                        .getElementById("FindInput")
                                        .focus();
                                } catch (err) {
                                    console.log(err.message);
                                }
                            }, 50);
                        }
                    } else if ($environment.windows || $environment.linux) {
                        if (keyCmd === "Ctrl+KeyF") {
                            e.preventDefault();
                            e.stopPropagation();

                            $uiState.selected = "findAndReplace";
                            $projectState.mode = "edit";
                            setTimeout(() => {
                                try {
                                    document
                                        .getElementById("FindInput")
                                        .focus();
                                } catch (err) {
                                    console.log(err.message);
                                }
                            }, 50);
                        }
                    }
                }
            }
        }
    }

    function isInputFocused() {
        return (
            document.activeElement.tagName === "INPUT" ||
            document.activeElement.tagName === "TEXTAREA" ||
            document.activeElement.tagName === "SELECT"
        );
    }

    function isContentEditableFocused() {
        return document.activeElement.isContentEditable;
    }

    async function loadSnapshots() {
        /* workaround to ensure no components break that rely on selected events */
        let selectedEvents = $eventGroupState[$projectState.selected].selected;
        let currentQuillIndex = quillEditor ? quillEditor.getSelection() : 0;
        let quillEditorLength = quillEditor ? quillEditor.getLength() : 0;

        $eventGroupState[$projectState.selected].selected = [];
        await tick();

        $historyState.actions[$historyState.position - 1].snapshots.forEach(
            async (snapshot) => {
                switch (snapshot.store) {
                    case "eventGroupState":
                        $eventGroupState = JSON.parse(snapshot.value).map(
                            (eventGroup) => {
                                let newEventGroup = new _EventGroup(eventGroup);
                                newEventGroup.id = eventGroup.id;
                                return newEventGroup;
                            },
                        );
                        break;
                    case "metadataState":
                        $metadataState = JSON.parse(snapshot.value);
                        break;
                    case "projectState":
                        $projectState = JSON.parse(snapshot.value);
                        break;
                    case "speakerState":
                        $speakerState = JSON.parse(snapshot.value);
                        break;
                    case "markerState":
                        $markerState = JSON.parse(snapshot.value);
                        break;
                    case "fontState":
                        $fontState = JSON.parse(snapshot.value);
                        break;
                    default:
                        console.log("UNKNOWN STORE NAME");
                        228;
                }
            },
        );

        /* Ensure that we're always looking at the correct event group */
        if (!$eventGroupState[$projectState.selected]) {
            $projectState.selected =
                $historyState.actions[$historyState.position - 1].eventGroup ??
                0;
        }

        if (
            $eventGroupState[$projectState.selected] &&
            $eventGroupState[$projectState.selected].events.length >
                Math.max(...selectedEvents)
        ) {
            $eventGroupState[$projectState.selected].selected = selectedEvents;
            if (quillEditor) {
                currentQuillIndex = currentQuillIndex
                    ? currentQuillIndex.index
                    : 0;
                setTimeout(() => {
                    quillEditor.setSelection(
                        currentQuillIndex -
                            (quillEditorLength - quillEditor.getLength()),
                        0,
                    );
                }, 25);
            }
        } else {
            $eventGroupState[$projectState.selected].selected = [];
        }
    }

    function exportShortcuts() {
        let fileBlob = new Blob([JSON.stringify(shortcutGroups)], {
            type: "text/plain;charset=utf-8",
        });
        saveAs(fileBlob, "shortcuts.json");
    }

    function importShortcuts() {
        if (files && files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                try {
                    let shortcutJson = JSON.parse(e.target.result);
                    shortcutGroups.forEach((group) => {
                        group.shortcuts.forEach((shortcut) => {
                            shortcut.keyCmd = findShortcut(
                                shortcut.name,
                                shortcutJson,
                            ).keyCmd;
                        });
                    });

                    shortcutGroups = shortcutGroups;
                    toast.push("Shortcuts imported successfully", {
                        classes: ["toast-success"],
                    });
                    localStorage.setItem(
                        "cc-shortcuts",
                        JSON.stringify(shortcutGroups),
                    );
                } catch (err) {
                    console.log(err, err.message);
                    toast.push(
                        "Failed importing shortcuts with error: " + err.message,
                        {
                            classes: ["toast-danger"],
                        },
                    );
                }
            };
            reader.readAsText(files[0]);
        }
    }

    /* Create Event Listener */
    onMount(async () => {
        /* Initialize Shortcuts */
        let savedShortcuts = localStorage.getItem("cc-shortcuts");
        if (savedShortcuts) {
            savedShortcuts = JSON.parse(savedShortcuts);
            shortcutGroups.forEach((group) => {
                group.shortcuts.forEach((shortcut) => {
                    shortcut.keyCmd = findShortcut(
                        shortcut.name,
                        savedShortcuts,
                    ).keyCmd;
                });
            });
        }

        //Order shortcuts in each shortcut group by shortcut.name
        shortcutGroups.forEach((group) => {
            group.shortcuts.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        });

        shortcutGroups = shortcutGroups;
        window.addEventListener("keydown", runShortcut);
    });

    /* Destroy Event Listener */
    onDestroy(() => {
        window.removeEventListener("keydown", runShortcut);
    });
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'shortcutKeys' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="ShortcutKeysModal"
>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Shortcut Keys</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                />
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <form>
                            <div class="mb-3">
                                <label
                                    class="form-label"
                                    for="ShortcutListSelect">Shortcut</label
                                >
                                <select
                                    id="ShortcutListSelect"
                                    class="form-select"
                                    size="20"
                                    bind:value={selectedShortcut}
                                    on:change={() =>
                                        document
                                            .getElementById("KeyboardInput")
                                            .focus()}
                                >
                                    {#each shortcutGroups as shortcutGroup}
                                        <optgroup label={shortcutGroup.name}>
                                            {#each shortcutGroup.shortcuts as shortcutOption}
                                                <option value={shortcutOption}
                                                    >{shortcutOption.name}{shortcutOption.keyCmd
                                                        ? " - " +
                                                          shortcutOption.keyCmd
                                                        : ""}</option
                                                >
                                            {/each}
                                        </optgroup>
                                    {/each}
                                </select>
                            </div>
                        </form>
                        <div class="float-end">
                            <button
                                type="button"
                                class="btn btn-light"
                                on:click={exportShortcuts}
                                ><i class="bi bi-download" /> Export</button
                            >
                            <label
                                class="form-label custom-file-upload"
                                for="file-upload"
                            >
                                <i class="bi bi-upload" /> Import
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                accept=".json"
                                bind:files
                                on:change={importShortcuts}
                            />
                        </div>
                    </div>
                    <div class="col">
                        <h5>Description</h5>
                        {#if selectedShortcut.description}
                            <p>{selectedShortcut.description}</p>
                            <label class="form-label" for="Shortcut Key Command"
                                >Key Command</label
                            >
                            <form
                                class="d-flex flex-row align-items-center flex-wrap"
                            >
                                <div class="input-group">
                                    <input
                                        id="KeyboardInput"
                                        class="form-control"
                                        type="text"
                                        on:keydown={updateShortcut}
                                        on:focus={(e) => {
                                            e.target.select();
                                        }}
                                        value={selectedShortcut.keyCmd || ""}
                                    />
                                    <button
                                        class="btn btn-danger text-white"
                                        type="button"
                                        title="Clear Shortcut"
                                        on:click={removeShortcut}
                                    >
                                        <i class="bi bi-x-lg" />
                                    </button>
                                </div>
                            </form>
                        {:else}
                            <p>
                                Please select a shortcut from the menu to start
                            </p>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    input[type="file"] {
        display: none;
    }

    .custom-file-upload {
        cursor: pointer;
        color: #fff;
        background-color: #444;
        border-color: #444;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.9375rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        margin: 0;
    }

    .custom-file-upload:hover {
        color: #fff;
        background-color: #313131;
        border-color: #2b2b2b;
        text-decoration: none;
    }
</style>
