<script>
import {environment} from '@app/store/envStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    tick
} from 'svelte';
import {
    createEventDispatcher
} from 'svelte';
import firebase from '@app/configs/firebase.js';
import {
    replace
} from "svelte-spa-router";
import project from '@app/external/cc-lib/dist/classes/project.js';

export let currentState;
const dispatch = createEventDispatcher();

function toggleMenu() {
    dispatch('toggleMenu', {
        menu: 'help'
    });
}

async function resetApplication() {
    /* Reset Stores */
    if ($eventGroupState[$projectState.selected]){
        $eventGroupState[$projectState.selected].selected = [];
        await tick();
    }
    
    $projectState.selected = false;
    await tick();
    $eventGroupState = [];  
    await tick();  
    $projectState = new project();  
    $metadataState = [];
    $speakerState = [];
    $markerState = {
        selected : 0,
        lists : [
            {
                id : '0',
                name : "Shot Changes",
                color : "#E74C3C",
                markers : []
            }
        ]
    };
    authState.reset();
    historyState.reset();
    localStorage.clear();

    if ($environment.online){
        firebase.auth().signOut();
    }
    
    if ($environment.electron){
        const ipcRenderer = window.ipcRenderer; 
        ipcRenderer.invoke('quit');
    } else {
        replace("/login");
    }
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}">
    <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/">Help</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}">
        <!-- Team Management -->
        {#if ($authState.plan === 'pro' || $authState.plan === 'enterprise') && $environment.online}
            <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("teamManager")}><i class="bi bi-people-fill"></i> Team Management</a>
            <div class="dropdown-divider"></div>
        {/if}
        {#if $environment.online}
        <!-- Subscription Management -->
        <a class="dropdown-item" href="https://closedcaptioncreator.chargebeeportal.com/portal/v2/login" target="_blank" rel="noreferrer">Manage Subscription <i class="bi bi-box-arrow-up-right small"></i></a>        
        <!-- User Guide -->
        <a class="dropdown-item" href="https://docs.google.com/document/d/e/2PACX-1vQjCdy__j5AOQKbQpyoNTRtF28BIhRgkYhWB-3tLZ10bQhWebqR9CH2-Jsd9qCGLp5wa2U89wu9vQFf/pub"
            target="_blank" rel="noreferrer">Online User Guide <i class="bi bi-box-arrow-up-right small"></i></a>
        <a class="dropdown-item" href="https://www.closedcaptioncreator.com/support.html" target="_blank" rel="noreferrer">
            Contact Support <i class="bi bi-box-arrow-up-right small"></i>
        </a>
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("offlineActivation")}>Offline Activation</a>
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("usage")}>Reporting &amp; Usage</a>
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("autoSave")}>Restore Event Groups</a>
        <div class="dropdown-divider"></div>
        {/if}        
        <a on:click={resetApplication} class="dropdown-item" href="#!/">Reset Application</a>
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("tips")}>What's New</a>
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("about")}>About CC Creator</a>
    </div>
</li>
