export default (function replaceStyleTagsFcpXml(text, count) {
  const styleTags = ['b', 'strong', 'i', 'em', 'u'];
  const regex = new RegExp(`<(${styleTags.join('|')})>|</(${styleTags.join('|')})>`, 'gi');
  let result = '';
  let lastIndex = 0;
  let isInStyleTag = false;
  function getRefNumber(tag) {
    return ['b', 'strong'].includes(tag.toLowerCase()) ? '2' : '3';
  }
  function wrapInDefaultStyle(text) {
    return text ? `<text-style ref="ts${count}-1">${text}</text-style>` : '';
  }
  while (true) {
    const match = regex.exec(text);
    if (!match) break;
    const matchedTag = match[1] || match[2];
    const isOpeningTag = !match[0].startsWith('</');

    // Add text before the match, wrapped in default style if not in a style tag
    if (!isInStyleTag) {
      result += wrapInDefaultStyle(text.slice(lastIndex, match.index));
    } else {
      result += text.slice(lastIndex, match.index);
    }
    if (isOpeningTag) {
      // Open new tag
      const refNumber = getRefNumber(matchedTag);
      result += `<text-style ref="ts${count}-${refNumber}">`;
      isInStyleTag = true;
    } else {
      // Close tag
      result += '</text-style>';
      isInStyleTag = false;
    }
    lastIndex = regex.lastIndex;
  }

  // Add any remaining text, wrapped in default style if not in a style tag
  if (!isInStyleTag) {
    result += wrapInDefaultStyle(text.slice(lastIndex));
  } else {
    result += text.slice(lastIndex);
  }
  return result;
});