import _convertToPlainText from "../quill/convertToPlainText.js";
import _getLongestLine from "../utility/getLongestLine.js";
import _stripTags from "../quill/stripTags.js";
import _convertToPlainTextCustom from "../quill/convertToPlainTextCustom.js";
import _findCenter from "../utility/findCenter.js";
import _quillClasses from "../../dict/quillClasses.js";
import _convertToHtml from "../quill/convertToHtml.js";
import _eol from "eol";
import _sccFunc from "../profiles/scenerist.js";
import _mccLookup from "../../dict/708.js";
import _moment from "moment";
import { v1 as _uuidv } from "uuid";
import _hexToBinary from "hex-to-binary";
import _Event from "../../classes/event.js";
const Event = _Event;
const hexToBinary = _hexToBinary;
const uuidv1 = _uuidv;
const moment = _moment;
const mccLookup = _mccLookup;
const sccFunc = _sccFunc;
const eol = _eol;
const convertToHtml = _convertToHtml;
const quillClasses = _quillClasses;
const findCenter = _findCenter;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const stripTags = _stripTags;
const getLongestLine = _getLongestLine;
const convertToPlainText = _convertToPlainText;
export default {
  decodeWindowData: function (window) {
    //console.log(JSON.stringify(window,null,4));
    let ccEvent = new Event();
    let positionInfo = this.getPositionInfo(window);
    ccEvent.start = window.start;
    ccEvent.end = window.end;
    ccEvent.alignment = positionInfo.alignment;
    ccEvent.xPos = positionInfo.xPos;
    ccEvent.xOffset = positionInfo.xOffset.toFixed(2);
    ccEvent.yPos = positionInfo.yPos;
    ccEvent.yOffset = positionInfo.yOffset.toFixed(2);
    ccEvent.text = this.formatText(window.lines, ccEvent.alignment);
    //console.log(ccEvent);
    return ccEvent;
  },
  getPositionInfo: function (window) {
    let xPos,
      yPos,
      xOffset,
      yOffset,
      alignment = "center",
      details;
    details = this.getPosDetails(window.lines);
    alignment = this.getWindowAlignment(details);
    let x = this.getXPos(window.lines, details, alignment);
    let y = this.getYPos(window.lines);
    xPos = x.position;
    xOffset = x.offset;
    yPos = y.position;
    yOffset = y.offset;
    return {
      alignment: alignment,
      xPos: xPos,
      xOffset: xOffset,
      yPos: yPos,
      yOffset: yOffset
    };
  },
  getPosDetails: function (lines) {
    return lines.map(line => {
      let alignment;
      let center = findCenter(32, line.text.length);
      if (line.posX > center - 5 && line.posX < center + 5) {
        alignment = "center";
      } else if (line.posX < center) {
        alignment = "left";
      } else {
        alignment = "right";
      }
      return {
        start: line.posX,
        end: parseFloat(line.posX) + parseFloat(line.text.length / 32 * 100),
        length: line.text.length,
        alignment: alignment
      };
    });
  },
  getWindowAlignment: function (details) {
    let xPosSame = details.every(detail => {
      return detail.start === details[0].start;
    });
    let endSame = details.every(detail => {
      return detail.end === details[0].end;
    });
    let alignmentCenter = details.every(detail => {
      return detail.alignment === "center";
    });
    if (xPosSame) {
      return "left";
    } else if (alignmentCenter) {
      return "center";
    } else if (endSame) {
      return "right";
    } else {
      return "center";
    }
  },
  /* used when decoding */
  getXPos: function (lines, details, alignment) {
    let xInfo = {
      position: "center",
      offset: 0
    };
    let lowestXPos = Math.min.apply(Math, lines.map(function (line) {
      return line.posX;
    }));
    let highestEnd = Math.max.apply(Math, details.map(function (detail) {
      return detail.end;
    }));
    if (alignment === "left") {
      xInfo.position = "start";
      xInfo.offset = lowestXPos;
    } else if (alignment === "center") {
      xInfo.position = "center";
      xInfo.offset = 0;
    } else {
      xInfo.position = "end";
      xInfo.offset = -100 + highestEnd;
    }
    return xInfo;
  },
  /* used when decoding */
  getYPos: function (lines) {
    let yInfo = {
      position: "end",
      offset: 0
    };
    let highestYPos = Math.max.apply(Math, lines.map(function (line) {
      return line.posY;
    }));
    let lowestYPos = Math.min.apply(Math, lines.map(function (line) {
      return line.posY;
    }));
    if (lowestYPos > 60) {
      yInfo.position = "end";
      yInfo.offset = highestYPos - 84.66;
    } else if (highestYPos < 40) {
      yInfo.position = "start";
      yInfo.offset = lowestYPos;
    } else {
      yInfo.position = "center";
      yInfo.offset = lowestYPos - findCenter(100, lines.length * 5.33);
    }
    return yInfo;
  },
  formatText: function (lines, alignment = "center") {
    let prefix = "";
    let suffix = "";
    let text = lines.map(line => {
      prefix += line.bold ? "<strong>" : "";
      prefix += line.italics ? "<em>" : "";
      prefix += line.underline ? "<u>" : "";
      suffix = suffix + (line.underline ? "</u>" : "");
      suffix = suffix + (line.italics ? "</em>" : "");
      suffix = suffix + (line.bold ? "</strong>" : "");
      return prefix + line.text + suffix;
    }).join("\n");
    return convertToHtml(text, [quillClasses.align[alignment]]);
  },
  decodeAncData: function (ancData) {
    let header = this.decodeHeader(ancData.splice(0, 20).join("")),
      ccData = this.decodeCcSection(ancData, header);
    return {
      header: header,
      ccData: ccData
    };
  },
  decodeHeader: function (ancData) {
    return {
      ancDataFlag: ancData.substring(0, 4),
      filler: ancData.substring(4, 6),
      cdp_identifier: ancData.substring(6, 10),
      cdp_length: ancData.substring(10, 12),
      cdp_frame_rate: ancData.substring(12, 13),
      reserved01: ancData.substring(13, 14),
      tcFlag: hexToBinary(ancData.substring(14, 15)).substring(0, 1),
      ccFlag: hexToBinary(ancData.substring(14, 15)).substring(1, 2),
      ccActive: hexToBinary(ancData.substring(15, 16)).substring(3, 4),
      statusData: ancData.substring(14, 16),
      seqCounter: ancData.substring(16, 20)
    };
  },
  decodeCcSection: function (ancData, header) {
    if (header.ccFlag === "1") {
      let ccData_identifier = ancData.splice(0, 2).join("");
      let ccCount = parseInt(hexToBinary(ancData.splice(0, 2).join("")).substring(3), 2);
      let ccPackets = [];
      for (var i = 0; i < ccCount; i++) {
        ccPackets.push(ancData.splice(0, 6).join(""));
      }
      return {
        ccData_identifier: ccData_identifier,
        ccCount: ccCount,
        ccPackets: ccPackets
      };
    } else {
      return {};
    }
  },
  frameRateMapping: {
    29.97: {
      frames: 29.97,
      dropFrame: true,
      mccValue: "30DF",
      hexValue: "4",
      cc_count: 20,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "59",
      footerHexValue: "BB"
    },
    59.94: {
      frames: 59.94,
      dropFrame: true,
      mccValue: "60DF",
      hexValue: "7",
      cc_count: 10,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "3B",
      footerHexValue: "9D"
    },
    23.976: {
      frames: 23.98,
      dropFrame: false,
      mccValue: "24",
      hexValue: "1",
      cc_count: 25,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "68",
      footerHexValue: "CA"
    },
    24: {
      frames: 24,
      dropFrame: false,
      hexValue: "2",
      mccValue: "24",
      cc_count: 25,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "68",
      footerHexValue: "CA"
    },
    25: {
      frames: 25,
      dropFrame: false,
      hexValue: "3",
      mccValue: "25",
      cc_count: 24,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "65",
      footerHexValue: "C7"
    },
    "30DF": {
      frames: 29.97,
      dropFrame: true,
      hexValue: "4",
      mccValue: "30DF",
      cc_count: 20,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "59",
      footerHexValue: "BB"
    },
    30: {
      frames: 30,
      dropFrame: false,
      hexValue: "5",
      mccValue: "30",
      cc_count: 20,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "59",
      footerHexValue: "BB"
    },
    50: {
      frames: 50,
      dropFrame: false,
      hexValue: "6",
      mccValue: "50",
      cc_count: 12,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "41",
      footerHexValue: "A3"
    },
    "60DF": {
      frames: 59.94,
      dropFrame: true,
      hexValue: "7",
      mccValue: "60DF",
      cc_count: 10,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "3B",
      footerHexValue: "9D"
    },
    60: {
      frames: 60,
      dropFrame: false,
      hexValue: "8",
      mccValue: "60",
      cc_count: 10,
      max608Bytes: 4,
      max708Bytes: 36,
      dataCount: "3B",
      footerHexValue: "9D"
    }
  },
  windowStyleMapping: {
    1: "Pop-On",
    2: "Pop-On",
    3: "Pop-On",
    4: "Roll-Up",
    5: "Roll-Up",
    6: "Roll-Up",
    7: "Paint-On"
  },
  alignmentMap: {
    0: "left",
    1: "right",
    2: "center",
    3: "center"
  },
  verticalPositionMap: {
    "start": "00",
    "center": "22",
    "end": "41"
  },
  ccTypeMapping: {
    /* Reference Pg.11 of  ANSI-CTA-708-E-R-2018-Final_pdf.pdf  */
    11111000: "F8" /* Do Nothing - but this is 608 data */,
    11111100: "FC" /* 608 Captions Field 1 (both Bytes) */,
    11111001: "F9" /* Do Nothing - but this is 608 data */,
    11111101: "FD" /* 608 Field 2 Closed Captioning */,
    11111010: "FA" /* Padding Data */,
    11111110: "FE" /* Both are 708 captions */,
    11111011: "FB" /* Padding Bytes */,
    11111111: "FF" /* Start of 708 - first byte is header and second bute is data */
  },
  serviceMapping: {
    "programA": "0010",
    "programB": "0100",
    "programC": "0110",
    "programD": "1000",
    "programE": "1010",
    "programF": "1100"
  },
  replaceShortForms: function (ancStream) {
    let decodedData = "";
    ancStream.split("").forEach(char => {
      if (this.charToByte[char] !== undefined) {
        decodedData += this.charToByte[char];
      } else {
        decodedData += char;
      }
    });
    return decodedData;
  },
  charToByte: {
    G: "FA0000",
    H: "FA0000FA0000",
    I: "FA0000FA0000FA0000",
    J: "FA0000FA0000FA0000FA0000",
    K: "FA0000FA0000FA0000FA0000FA0000",
    L: "FA0000FA0000FA0000FA0000FA0000FA0000",
    M: "FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
    N: "FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
    O: "FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
    P: "FB8080",
    Q: "FC8080",
    R: "FD8080",
    S: "9669",
    T: "6101",
    U: "E10000",
    Z: "00"
  },
  windowStyleMap: {
    "Pop-On": {
      "left": 1,
      "center": 3,
      "right": 3
    },
    "Paint-On": {
      "left": 4,
      "center": 6,
      "right": 6
    },
    "Roll-Up": {
      "left": 4,
      "center": 6,
      "right": 6
    }
  },
  getFrameRateFromFile: function (input) {
    let frameRateFlag = false,
      frameRate,
      fileLine,
      fileLines = eol.split(input);
    while (!frameRateFlag && fileLines.length > 0) {
      fileLine = fileLines.shift();
      if (fileLine.split("=").length === 2 && fileLine.split("=")[0].toLowerCase() === "time code rate") {
        frameRate = this.frameRateMapping[fileLine.split("=")[1].trim()];
        frameRateFlag = true;
      }
    }
    return frameRate;
  },
  generateMccFileHeader: function (mccVersion, frameRate) {
    return this.mccFileHeader(mccVersion) + this.mccFileDescription + this.mccFileUuid + "\n" + this.mccFileCreation + "\n" + this.mccFileDate + "\n" + this.mccFileTime + "\n" + this.mccFileTc(frameRate) + "\n";
  },
  mccFileHeader: function (version) {
    return "File Format=MacCaption_MCC V" + version + "\n\n";
  },
  mccFileDescription: "///////////////////////////////////////////////////////////////////////////////////\n// Computer Prompting and Captioning Company\n// Ancillary Data Packet Transfer File\n//\n// Permission to generate this format is granted provided that\n//   1. This ANC Transfer file format is used on an as-is basis and no warranty is given, and\n//   2. This entire descriptive information text is included in a generated .mcc file.\n//\n// General file format:\n//   HH:MM:SS:FF(tab)[Hexadecimal ANC data in groups of 2 characters]\n//     Hexadecimal data starts with the Ancillary Data Packet DID (Data ID defined in S291M)\n//       and concludes with the Check Sum following the User Data Words.\n//     Each time code line must contain at most one complete ancillary data packet.\n//     To transfer additional ANC Data successive lines may contain identical time code.\n//     Time Code Rate=[24, 25, 30, 30DF, 50, 60, 60DF]\n//\n//   ANC data bytes may be represented by one ASCII character according to the following schema:\n//     G  FAh 00h 00h\n//     H  2 x (FAh 00h 00h)\n//     I  3 x (FAh 00h 00h)\n//     J  4 x (FAh 00h 00h)\n//     K  5 x (FAh 00h 00h)\n//     L  6 x (FAh 00h 00h)\n//     M  7 x (FAh 00h 00h)\n//     N  8 x (FAh 00h 00h)\n//     O  9 x (FAh 00h 00h)\n//     P  FBh 80h 80h\n//     Q  FCh 80h 80h\n//     R  FDh 80h 80h\n//     S  96h 69h\n//     T  61h 01h\n//     U  E1h 00h 00h 00h\n//     Z  00h\n//\n///////////////////////////////////////////////////////////////////////////////////\n\n",
  mccFileUuid: "UUID=" + uuidv1(),
  mccFileCreation: "Creation Program=Closed Caption Converter V3",
  mccFileDate: "Creation Date=" + moment().format("dddd[,] MMMM DD[,] YYYY"),
  mccFileTime: "Creation Time=" + moment().format("hh:mm:ss"),
  mccFileTc: function (frameRate) {
    return "Time Code Rate=" + this.frameRateMapping[frameRate].mccValue;
  },
  packetCounter: {
    count: 0,
    counts: {
      0: "00",
      1: "01",
      2: "10",
      3: "11"
    },
    getCount: function () {
      var data = this.counts[this.count];
      this.count++;
      if (this.count > 3) {
        this.count = 0;
      }
      return data;
    }
  },
  defineWindow: function (event, window, line, posY) {
    // example codes (0): 98,00,41,00,00,1F,11
    // example codes (1): 99,00,41,00,00,1F,11,92,00,01,90,05,00,61,6E,03
    let defineWindowCmd = [];
    let verticalAnchor = Math.floor((posY ? posY : 89) / 100 * 74).toString(16); /* Vertical Anchor is value between 0-74 */
    defineWindowCmd.push(this.getCodeByCmd(mccLookup.cmds, "{DF-" + window + "}"));
    if (event.style === "Pop-On") {
      defineWindowCmd.push("00"); /* visible = false, row lock = false, column lock = false, priority = 0, relative position = true */
    } else {
      defineWindowCmd.push("80"); /* visible = true, row lock = false, column lock = false, priority = 0, relative position = true */
    }
    defineWindowCmd.push(verticalAnchor.padStart(2, '0')); /* Anchor vertical */
    defineWindowCmd.push("00"); /* Anchor Horz */
    defineWindowCmd.push("0" + (line ? line : "0")); /* Anchor Point, Row Count */
    defineWindowCmd.push("1F"); /* Column Count */
    defineWindowCmd.push("11"); /* Window Style + Pen Style */
    return defineWindowCmd;
  },
  setPenLocation: function (columnPercent, lineNumber) {
    // example codes: 92 (SPL),00,01
    let penLocationCmd = [];
    penLocationCmd.push("92");
    penLocationCmd.push("0" + lineNumber);
    penLocationCmd.push(Math.floor(Math.abs(columnPercent) / 100 * 32).toString(16).padStart(2, "0"));
    return penLocationCmd;
  },
  setPenAttributes: function (ccEvent, encodedCmds) {
    // example codes: 90 (SPA),05,00
    let penAttributesCmd = [];
    penAttributesCmd.push("90");
    penAttributesCmd.push("05");
    penAttributesCmd.push("00");
    return penAttributesCmd;
  },
  encodeText: function (text, frameRate = 29.97) {
    let txtCmd = [];
    let endFlag = 1; /* should be based on frame rate as well */
    let self = this;
    text.split("").forEach(function (char, index) {
      txtCmd.push(self.getCodeByCmd(mccLookup.cmds, char));
      if (index === endFlag || text.split("").length - 1 == index) {
        txtCmd.push("03");
        endFlag += 2;
      }
    });

    //console.log(text,txtCmd);
    return txtCmd;
  },
  deleteWindows: function (visibleWindows) {
    let deleteWindowCmd = ["8C"],
      param = [];
    for (let i = 0; i < 8; i++) {
      if (visibleWindows.indexOf(i.toString()) > -1) {
        param.push("0");
      } else {
        param.push("1");
      }
    }
    deleteWindowCmd.push(this.binToHex(param.reverse().join("")).padStart(2, 0));
    return deleteWindowCmd;
  },
  clearWindows: function (visibleWindows) {
    let clearWindowCmd = ["88"],
      param = [];
    for (let i = 0; i < 8; i++) {
      if (visibleWindows.indexOf(i.toString()) > -1) {
        param.push("1");
      } else {
        param.push("0");
      }
    }
    clearWindowCmd.push(this.binToHex(param.reverse().join("")).padStart(2, 0));
    return clearWindowCmd;
  },
  resetWindows: function () {
    return ["8f"];
  },
  carriageReturn: function () {
    return ["0d"];
  },
  toggleWindows: function (selectedWindow) {
    let toggleWindowCmd = ["8B"],
      param = [];
    for (let i = 0; i < 8; i++) {
      if (selectedWindow.indexOf(i.toString()) > -1) {
        param.push("1");
      } else {
        param.push("0");
      }
    }
    toggleWindowCmd.push(this.binToHex(param.reverse().join("")).padStart(2, 0));
    //console.log(toggleWindowCmd);
    return toggleWindowCmd;
  },
  encodeCcsvcInfoSection: function () {
    return "73D2E02020207E3FFFE1656E67C13FFF";
  },
  encodeCcsvcInfoSectionNew: function (service, language) {
    if (!service) {
      return "73F2E02020207E3FFFE1737061C13FFF";
    }
    if (!language) {
      language = "   "; //3 spaces
    }
    //
    /* 
        ccsvcinfo = {
            id : "0x73",
            reserved : "1",
            info_start : "0/1 (yes/no)",
            info_change : "0/1 (yes/no)",
            info_complete : "0/1 (yes/no)",
            count : "0x00",
            data : {
                reserved : "1",
                csv_size : "0x00",
                caption_Service_number : "0x00",
                svc_data_byte_1 : "0x00",
                svc_data_byte_2 : "0x00",
                svc_data_byte_3 : "0x00",
                svc_data_byte_4 : "0x00",
                svc_data_byte_5 : "0x00",
                svc_data_byte_6 : "0x00"
            }
        }
    */
    let ccsvInfo = ["73"];
    let numberOfServices = 2;

    /* if (serviceInfo.line21 && serviceInfo.dtv){
        numberOfServices = 2;
    } else if (serviceInfo.line21 || serviceInfo.dtv){
        numberOfServices = 1;
    } */

    ccsvInfo.push(parseInt("1111" + numberOfServices.toString(2).padStart(4, "0"), 2).toString(16));
    ccsvInfo.push("E02020207E3FFF"); //608 Service

    let svcData = "111";
    let csnData = "11";
    if (service === "programA") {
      svcData += "00001";
      csnData += "000001";
    } else if (service === "programB") {
      svcData += "00010";
      csnData += "000010";
    } else if (service === "programC") {
      svcData += "00011";
      csnData += "000011";
    } else if (service === "programD") {
      svcData += "00100";
      csnData += "000100";
    } else if (service === "programE") {
      svcData += "00101";
      csnData += "000101";
    } else if (service === "programF") {
      svcData += "00110";
      csnData += "000110";
    } else {
      svcData += "00001";
      csnData += "000001";
    }
    svcData = parseInt(svcData, 2).toString(16);
    ccsvInfo.push(svcData);

    //Byte 1 - 6
    //Language (1-3)
    //20 20 20
    //Split language into array and convert each letter to hex, and push to ccsvInfo:
    language.split("").forEach(letter => {
      ccsvInfo.push(letter.charCodeAt(0).toString(16));
    });

    //byte 4 = 608 vs 708
    ccsvInfo.push(parseInt(csnData, 2).toString(16));
    //byte 5 = asy Ready and Wide_aspect_ratio
    ccsvInfo.push("3F");
    //byte 6 = FF (Reserved)
    ccsvInfo.push("FF");
    return ccsvInfo.join("");
  },
  encodeCdpFooter: function (sequenceCounter, packet, frameRate = 29.97) {
    var data = "74";
    data += sequenceCounter;
    var packetDecoded = "";
    packet.substring(6).toUpperCase().split("").forEach(ancChar => {
      if (this.charToByte[ancChar] != undefined) {
        packetDecoded += this.charToByte[ancChar];
      } else {
        packetDecoded += ancChar;
      }
    });
    var checkSum = this.calcChecksum(packetDecoded);
    //console.log(packetDecoded, checkSum);
    data += checkSum;
    data += this.frameRateMapping[frameRate.toString()].footerHexValue;
    return data;
  },
  calcChecksum: function (hexstring) {
    /* hex string is from 9669 for 176 chars (inclusive) */
    var hexArray = hexstring.split("");
    var total = 0;
    for (var i = 0; i < hexArray.length; i++) {
      total += parseInt("0x" + hexArray[i] + hexArray[++i]);
    }
    var binary = total.toString(2);
    /* take the last 8 bits */
    binary = binary.substring(binary.length - 8);
    var lastOne = binary.lastIndexOf("1");
    if (lastOne == -1) {
      return "00";
    }
    binary = binary.split("");
    var checkSum = "";
    var found = false;
    for (var k = 0; k < binary.length; k++) {
      if (k == lastOne) {
        checkSum += binary[k];
        found = true;
      } else if (found) {
        checkSum += binary[k];
      } else {
        if (binary[k] == "0") {
          checkSum += "1";
        } else {
          checkSum += "0";
        }
      }
    }
    checkSum = parseInt(checkSum, 2).toString(16).toUpperCase();
    if (checkSum.length < 2) {
      return "0" + checkSum;
    } else {
      return checkSum;
    }
  },
  getCodeByCmd: function (codes, cmd) {
    let mccCode = Object.keys(codes).find(code => codes[code] === cmd);
    if (!mccCode) {
      if (cmd === "'" || cmd === "’") {
        return "27";
      } else if (cmd.charCodeAt() === 65533) {
        return "27";
      }

      //console.log(cmd, cmd.charCodeAt())
    }
    return mccCode || "20";
  },
  binToHex: function (binaryNumber) {
    return parseInt(binaryNumber, 2).toString(16).toUpperCase();
  },
  encodeCdpHeader: function (frameCount, frameRate) {
    let header = "6101" + this.frameRateMapping[frameRate.toString()].dataCount + "9669";
    header += this.frameRateMapping[frameRate.toString()].dataCount; //cdp_length
    header += this.frameRateMapping[frameRate.toString()].hexValue;
    header += "F"; //Reserved
    header += "7"; //time_code_present to scv_info_start
    header += "F"; //svc_info_change to Reserved;
    header += frameCount.toString(16).padStart(4, 0).slice(-4);
    return header;
  },
  generateCcPackets: function (ccCount, sccData, dtvData) {
    let ccPacketData = {
      prefix: ["72"],
      header: [],
      708: [],
      608: [],
      padding: []
    };
    let endFlag = false;
    let packetInfo;
    let packetCount = 0;
    ccPacketData.prefix.push(parseInt("111" + ccCount.toString(2), 2).toString(16)); //e.g. 72F4

    for (packetCount; packetCount < ccCount; packetCount++) {
      if (packetCount === 0) {
        packetInfo = "FC";
        if (sccData.length > 0) {
          if (sccData[0].length === 4) {
            packetInfo += sccData.shift();
          } else {
            if (sccData[1] && sccData[1].length === 2) {
              packetInfo += sccData.shift();
              packetInfo += sccData.shift();
            } else {
              packetInfo += sccData.shift() + "80";
            }
          }
        } else {
          packetInfo += "8080";
        }
        ccPacketData[608].push(packetInfo);
      } else if (packetCount === 1) {
        packetInfo = "FD8080";
        ccPacketData[608].push(packetInfo);
      } else if (endFlag || packetCount > 10) {
        packetInfo = "FA0000";
        ccPacketData.padding.push(packetInfo);
      } else if (packetCount === 2 && dtvData.length > 0) {
        packetInfo = "FF";
        ccPacketData.header.push(packetInfo);
      } else if (packetCount === 3 && dtvData.length > 0) {
        packetInfo = "FE";
        if (dtvData[0] === "98" || dtvData[0] === "99") {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += "00";
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          packetCount = 9;
          endFlag = true;
        } else if (dtvData[0].toLowerCase() === "8c" || dtvData[0] == "88") {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          endFlag = true;
        } else {
          if (dtvData[0] === "03") {
            packetInfo += dtvData.shift();
            packetInfo += "00";
            ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
            endFlag = true;
          } else if (dtvData[1] && dtvData[1] === "03") {
            packetInfo += dtvData.shift();
            packetInfo += dtvData.shift();
            ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
            endFlag = true;
          } else {
            packetInfo += dtvData.shift();
            packetInfo += dtvData.shift() || "00";
            ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
          }
        }
      } else if (dtvData.length > 0) {
        packetInfo = "FE";
        if (dtvData[0] === "03") {
          packetInfo += dtvData.shift();
          packetInfo += "00";
          endFlag = true;
        } else if (dtvData[1] && dtvData[1] === "03") {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          endFlag = true;
        } else {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift() || "00";
        }
        ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
      } else {
        packetInfo = "FA0000";
        ccPacketData.padding.push(packetInfo);
      }
    }
    //console.log(ccPacketData);
    if (ccPacketData.header.length > 0) {
      let sequenceNumber = this.packetCounter.getCount() + (ccPacketData[708].length + 1).toString(2).padStart(6, "0");
      sequenceNumber = parseInt(sequenceNumber, 2).toString(16).toUpperCase().padStart(2, '0');
      let packetDataSize = "0010" + (ccPacketData[708].length * 2 - 0).toString(2).padStart(4, "0");
      packetDataSize = parseInt(packetDataSize, 2).toString(16).toUpperCase().padStart(2, '0');
      ccPacketData.header = ccPacketData.header[0] + sequenceNumber + packetDataSize;
      return (ccPacketData.prefix.join("") + ccPacketData[608].join("") + ccPacketData.header + ccPacketData[708].join("") + ccPacketData.padding.join("")).toUpperCase();
    } else {
      return (ccPacketData.prefix.join("") + ccPacketData[608].join("") + ccPacketData.padding.join("")).toUpperCase();
    }
  },
  generateCcPacketsNew: function (ccCount, sccData, dtvData, service = "programA") {
    let ccPacketData = {
      prefix: ["72"],
      header: [],
      708: [],
      608: [],
      padding: []
    };

    // console.log("--------------")
    // console.log(dtvData);

    let endFlag = false;
    let packetInfo;
    let packetCount = 0;
    ccPacketData.prefix.push(parseInt("111" + ccCount.toString(2), 2).toString(16)); //e.g. 72F4

    for (packetCount; packetCount < ccCount; packetCount++) {
      if (packetCount === 0) {
        packetInfo = "FC";
        if (sccData && sccData.field01) {
          packetInfo += sccData.field01;
        } else {
          packetInfo += "8080";
        }
        ccPacketData[608].push(packetInfo);
      } else if (packetCount === 1) {
        packetInfo = "FD";
        if (sccData && sccData.field02) {
          packetInfo += sccData.field02;
        } else {
          packetInfo += "8080";
        }
        ccPacketData[608].push(packetInfo);
      } else if (endFlag) {
        packetInfo = "FA0000";
        ccPacketData.padding.push(packetInfo);
      } else if (packetCount === 2) {
        packetInfo = "F88080";
        ccPacketData[608].push(packetInfo);
      } else if (packetCount === 3 && dtvData.length > 0) {
        packetInfo = "FF";
        ccPacketData.header.push(packetInfo);
      } else if (packetCount === 4 && dtvData.length > 0) {
        packetInfo = "FE";
        if (dtvData[0] === "98" || dtvData[0] === "99") {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          packetInfo += "FE";
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          packetCount = 8;
          endFlag = true;
        } else if (dtvData[0] === "8c" || dtvData[0] === "8C" || dtvData[0] == "88") {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          endFlag = true;
        } else {
          if (dtvData[0] === "03") {
            packetInfo += dtvData.shift();
            packetInfo += "00";
            ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
            endFlag = true;
          } else if (dtvData[1] && dtvData[1] === "03") {
            packetInfo += dtvData.shift();
            packetInfo += dtvData.shift();
            ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
            endFlag = true;
          } else {
            packetInfo += dtvData.shift();
            packetInfo += dtvData.shift() || "00";
            ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
          }
        }
      } else if (dtvData.length > 0) {
        packetInfo = "FE";
        if (dtvData[0] === "03") {
          packetInfo += dtvData.shift();
          packetInfo += "00";
          endFlag = true;
        } else if (dtvData[1] && dtvData[1] === "03") {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift();
          endFlag = true;
        } else {
          packetInfo += dtvData.shift();
          packetInfo += dtvData.shift() || "00";
        }
        ccPacketData[708].push(packetInfo.slice(packetInfo.length - 6));
      } else {
        packetInfo = "FA0000";
        ccPacketData.padding.push(packetInfo);
      }
    }
    // console.log(ccPacketData);
    if (ccPacketData.header.length > 0) {
      let sequenceNumber = this.packetCounter.getCount() + (ccPacketData[708].length + 1).toString(2).padStart(6, "0");
      sequenceNumber = parseInt(sequenceNumber, 2).toString(16).toUpperCase().padStart(2, '0');
      //Packet Data size is made up of the service number. 0010 for Service 1, 0100 for service 2.
      let packetDataSize = this.serviceMapping[service] + (ccPacketData[708].length * 2 - 0).toString(2).padStart(4, "0");
      packetDataSize = parseInt(packetDataSize, 2).toString(16).toUpperCase().padStart(2, '0');
      ccPacketData.header = ccPacketData.header[0] + sequenceNumber + packetDataSize;
      return (ccPacketData.prefix.join("") + ccPacketData[608].join("") + ccPacketData.header + ccPacketData[708].join("") + ccPacketData.padding.join("")).toUpperCase();
    } else {
      return (ccPacketData.prefix.join("") + ccPacketData[608].join("") + ccPacketData.padding.join("")).toUpperCase();
    }
  },
  encodeVancData: function (frameCount, frameRate, ccCount, sccData, dtvData) {
    let cdpHeader = this.encodeCdpHeader(frameCount, frameRate);
    let svcInfoSection = this.encodeCcsvcInfoSection();
    let ccDataSection = this.generateCcPackets(ccCount, sccData, dtvData);
    let cdpFooter = this.encodeCdpFooter(cdpHeader.slice(cdpHeader.length - 4), cdpHeader + ccDataSection + svcInfoSection + "74" + cdpHeader.slice(cdpHeader.length - 4), frameRate);
    return (cdpHeader + ccDataSection + svcInfoSection + cdpFooter).toUpperCase();
  },
  encodeVancDataNew: function (frameCount, frameRate, ccCount, channelData, languageChannelMappings) {
    //Take the channel data and loop over each program array and generate a cdp packet. Include the 608 data if it's available. 
    /* 
        e.g ChannelData:
        {
            "dtv": {
                "programA" : ["08", "98", "00", "41", "00", "00", "1F", "11", "92", "00", "01", "90", "05", "00", "61", "6E", "03"],
                "programB" : [],
                "programC" : [],
                "programD" : [],
                "programE" : [],
                "programF" : []
            },
            "atv" : {
                "ch01" : [],
                "ch02" : [],
                "ch03" : [],
                "ch04" : []
            }
        }        
    */
    //console.log(channelData);
    let sccData;
    //Find the first program property where the array is not empty
    let dtvService = Object.keys(channelData.dtv).find(key => channelData.dtv[key].length > 0);
    let dtvData = dtvService ? channelData.dtv[dtvService] : false;

    //Find the first channel property where the array is not empty
    if (channelData.atv.ch01.length > 0 || channelData.atv.ch03.length > 0) {
      sccData = {
        "field01": channelData.atv.ch01.length > 0 ? channelData.atv.ch01.shift() : null,
        "field02": channelData.atv.ch03.length > 0 ? channelData.atv.ch03.shift() : null
      };
    } else if (channelData.atv.ch02.length > 0 || channelData.atv.ch04.length > 0) {
      sccData = {
        "field01": channelData.atv.ch02.length > 0 ? channelData.atv.ch02.shift() : null,
        "field02": channelData.atv.ch04.length > 0 ? channelData.atv.ch04.shift() : null
      };
    }
    if (!dtvData && !sccData) {
      return;
    }
    let cdpHeader = this.encodeCdpHeader(frameCount, frameRate);
    let ccDataSection = this.generateCcPacketsNew(ccCount, sccData, dtvData, dtvService);
    let svcInfoSection = this.encodeCcsvcInfoSectionNew(dtvService, languageChannelMappings[dtvService]);
    let cdpFooter = this.encodeCdpFooter(cdpHeader.slice(cdpHeader.length - 4), cdpHeader + ccDataSection + svcInfoSection + "74" + cdpHeader.slice(cdpHeader.length - 4));
    return (cdpHeader + ccDataSection + svcInfoSection + cdpFooter).toUpperCase();
  },
  encodeWindow: function (event, win) {
    let window = {
      start: event.start,
      end: event.end,
      style: event.style,
      lines: [],
      xPos: 0,
      yPos: 0,
      alignment: event.alignment
    };
    let plainTextCustom = convertToPlainTextCustom(event.text);
    eol.split(plainTextCustom).forEach((line, index, lines) => {
      let xPos = this.calcXPos(event, stripTags(line), win);
      let yPos = this.calcYPos(event, index, lines.length, win);
      window.lines.push({
        text: stripTags(line),
        bold: /<strong>/.test(line),
        italics: /<em>/.test(line),
        underline: /<u>/.test(line),
        posX: xPos,
        posY: yPos
      });
    });
    return window;
  },
  calcXPos: function (event, text, win) {
    let xPos;
    let longestLine = getLongestLine(convertToPlainText(event.text));
    let xOffset = event.xOffset / win.width * 100;
    if (event.xPos === "start") {
      xPos = 0;
    } else if (event.xPos === "center") {
      xPos = findCenter(32, text.length) / 32 * 100;
    } else {
      xPos = (32 - text.length) / 32 * 100;
    }
    return xPos + xOffset;
  },
  calcYPos: function (event, index, totalLines, win) {
    let yPos,
      yOffset = event.yOffset / win.height * 100,
      lOffset = index * 6.66;
    if (event.yPos === "start") {
      yPos = lOffset;
    } else if (event.yPos === "center") {
      yPos = findCenter(15, totalLines) / 15 * 100 + lOffset;
    } else {
      yPos = 100 - (totalLines - index) * 6.66;
    }
    return yPos;
  },
  encodeDtvEvent: function (details, usePrimeWindow, display) {
    let dtvData = [];
    let window = usePrimeWindow ? "0" : "1";

    /* 
        Pop-On Example
        ----------------
        DeleteWindows
        DefineWindow
        SetWindowAttributes (optional)
        Pen Commands and Caption Text
        Clear Windows
        Toggle Windows
        
        Simple Paint-on Style Captions 
        ------------------------------
        Reset (RST)
        DWx (Define Window)
        SWA (Optional)
        Pen Commands & Caption Text
        Simple Roll-up Style Captions 
        ------------------------------
        Delete Windows
        Define Window
        Set Window Attributes (optional)
        Set Pen Location
        CR (Carriage Return) (0x0D)
        Pen Commands & Caption Text
    */

    if (details.style === "Pop-On") {
      dtvData.push(this.getCodeByCmd(mccLookup.cmds, "{DF-" + window + "}"));
      dtvData.push("00"); //0,0, V, RL, CL, Px3
      dtvData.push(this.verticalPositionMap[details.yPos]); //RP, AVx7 //00 = top 22 = center 41 = bottom
      dtvData.push("00"); //AHx8
      dtvData.push("0" + (details.lines.length - 1).toString()); //APx4, RCx4
      dtvData.push("1F"); //0,0, CCx6
      dtvData.push("11"); //0,0, WSx3, PSx3
    } else if (details.style === "Paint-On") {
      dtvData.push("8F");
      dtvData.push(this.getCodeByCmd(mccLookup.cmds, "{DF-" + window + "}"));
      dtvData.push("20"); //0,0, V, RL, CL, Px3 (Visible)
      dtvData.push(this.verticalPositionMap[details.yPos]); //RP, AVx7
      dtvData.push("00"); //AHx8
      dtvData.push("0" + details.lines.length.toString()); //APx4, RCx4
      dtvData.push("1F"); //0,0, CCx6
      dtvData.push("11"); //0,0, WSx3, PSx3
    } else {
      //Roll-Up 2, Roll-Up 3, Roll-Up 4
      dtvData.push("8C");
      dtvData.push(this.getCodeByCmd(mccLookup.cmds, "{DF-" + window + "}"));
      dtvData.push("20"); //0,0, V, RL, CL, Px3 (Visible)
      dtvData.push(this.verticalPositionMap[details.yPos]); //RP, AVx7
      dtvData.push("00"); //AHx8
      dtvData.push("0" + details.lines.length.toString()); //APx4, RCx4
      dtvData.push("1F"); //0,0, CCx6
      dtvData.push("11"); //0,0, WSx3, PSx3
    }

    /* Pen Commands */
    details.lines.forEach((textLine, lineIndex) => {
      if (details.style === "Paint-On" || details.style === "Pop-On") {
        let xPos = sccFunc.getXPos(textLine, details, display);
        let yPos = sccFunc.getYPos(stripTags(textLine), details, lineIndex, display);
        //console.log("XPOS:", xPos-1);
        //console.log("YPOS:", yPos-1);
        /* Pen Position */
        dtvData.push("92");
        dtvData.push(lineIndex.toString().padStart(2, "0"));
        if (lineIndex === 0) {
          dtvData.push("00");
          dtvData.push("90");
        } else if (lineIndex === 1) {
          dtvData.push("00");
          dtvData.push("00");
        } else if (lineIndex === 2) {
          dtvData.push("00");
          dtvData.push("00");
        } else {
          dtvData.push("00");
          dtvData.push("00");
        }

        //dtvData.push(yPos.toString(16).padStart(2, "0"));
        //dtvData.push(xPos.toString(16).padStart(2, "0"));

        /* Pen Attributes */
        //dtvData.push("90");
        //dtvData.push("05"); //ttx4, oox2, ssx2
        //dtvData.push("00"); //i, u, ETx3, FSx3 {No italics, no underline, no edge type, no font style}

        /* Pen Color */
        //dtvData.push("91");
        //dtvData.push("2A"); // FOx2, FRx2, FGx2, FBx2
        //dtvData.push("00"); // box2, brx2, bgx2, bbx2
        //dtvData.push("15"); // 0,0,erx2,egx2,ebx2
      } else {
        //Roll Up
        if (lineIndex === 0) {
          let yPos = sccFunc.getYPos(stripTags(textLine), details, details.lines.length - 1, display);
          dtvData.push("92");
          dtvData.push(yPos.toString(16).padStart(2, "0"));
          dtvData.push("00");
          dtvData.push("0D");
        } else {
          dtvData.push("0D");
        }
        /* Pen Attributes */
        dtvData.push("90");
        dtvData.push("01"); //ttx4, oox2, ssx2
        dtvData.push("00"); //i, u, ETx3, FSx3 {No italics, no underline, no edge type, no font style}

        /* Pen Color */
        dtvData.push("91");
        dtvData.push("3f"); // FOx2, FRx2, FGx2, FBx2
        dtvData.push("00"); // box2, brx2, bgx2, bbx2
        dtvData.push("00"); // 0,0,erx2,egx2,ebx2
      }
      dtvData = dtvData.concat(this.encodeText(stripTags(textLine)));
    });

    //console.log(dtvData);
    return dtvData;
  }
};