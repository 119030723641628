<script>
import {
    v4 as uuidv4
} from 'uuid';
import {
    environment
} from '@app/store/envStore.js';
import {
    uiState
} from "@app/store/uiStore.js";
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
import frameRates from '@app/external/cc-lib/dist/dict/frameRates.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
import {
    onDestroy, onMount
} from "svelte";

import _Event from '@app/external/cc-lib/dist/classes/event.js';
import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import _Project from '@app/external/cc-lib/dist/classes/project.js';
import insertEvent from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';

/* Firebase */
import firebase from '@app/configs/firebase.js';
/* HLS */
import Hls from 'hls.js'

export let projectFilePath;

let progress = 0,
    statusMsg = "Starting import...", 
    audioExtractionComplete = false,
    manifestFileExists = false,
    audioExtractionWorker,
    proxyRtWorker,
    ffmpegRes;

const uid = uuidv4();
const ffmpegPath = require('ffmpeg-static-electron').path;
const os = window.os;
const path = window.path;
const fs = window.fsSync;
const tmpdir = os.tmpdir();
const videoOutputPath = tmpdir + path.sep + uid +".m3u8";
const audioOutputPath = tmpdir + path.sep + uid +".json";

onMount(() => {
    projectFilePath ? importProjectFile() : modalState.hideModal();
});

onDestroy(() => {
    if (audioExtractionWorker) {
        audioExtractionWorker.terminate();
    }
});

function checkFileExistsSync(filePath) {
  try {
    fsSync.accessSync(filePath);
    return true;
  } catch (error) {
    return false;
  }
}

async function getFileMetadata(filePath, ffmpegPath) {
        try {
            const exec = window.exec;
            return await exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
        } catch (err) {
            return err.message;
        }
    }
    
    function getMediaIncode(ffmpegOutput) {
        let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
        let mediaIncode = matches ? matches[0].replace(";", ":") : false;
    
        console.log("Media incode detected: " + mediaIncode);
        return mediaIncode == "null" ? false : mediaIncode;
    }
    
    function getMediaFrameRate(ffmpegOutput) {
        // console.log("GETTING MEDIA FRAME RATE");
        //console.log("FFMPEG OUTPUT:");
        //console.log(ffmpegOutput);
    
        let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
        let mediaFrameRate = matches ? matches[0] : false;
    
        console.log("Media frame rate detected: " + mediaFrameRate);
        let fr = frameRates.frameRateMapping[mediaFrameRate];
        return fr;
    }

    function getMediaDuration(ffmpegOutput){
       /*  console.log("GETTING MEDIA DURATION");
        console.log("FFMPEG OUTPUT:");
        console.log(ffmpegOutput); */
        let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
        let mediaDuration = matches ? matches[0] : false;

        console.log(mediaDuration);
        if (mediaDuration){
            mediaDuration = tcLib.tcMsToSec(mediaDuration);
            console.log("media duration in seconds: " + mediaDuration);
        }

        return mediaDuration;
    }

function importProjectFile(){
    try {
        progress = 10;
        statusMsg = "Reading project file:";
        $uiState.timeline = false;
        console.log(projectFilePath.replace(/"/g,""));
        fs.readFile(projectFilePath.replace(/"/g,""), (err, data) => {
            if (err) throw err;
            let projectJson = JSON.parse(data);
            console.log(projectJson);
            if (projectJson.version !== 3) {
                projectJson = convertProjectToV3(projectJson);
            }

            $eventGroupState = [];
            $metadataState = [];
            $speakerState = [];
            $markerState = {
                selected : 0,
                lists : [
                    {
                        id : '0',
                        name : "Shot Changes",
                        color : "#E74C3C",
                        markers : []
                    }
                ]
            };
            $projectState = new _Project({
                ...projectJson,
                ...{
                    eventGroups: [],
                    metadata: [],
                    speakers: [],
                    folderId: undefined
                }
            });

            $projectState.id = projectJson.id;

            projectJson.eventGroups.forEach((eventGroup, eventGroupIndex) => {
                $eventGroupState = [...$eventGroupState, new _EventGroup({
                    ...eventGroup,
                    events: []
                })];

                $eventGroupState[$eventGroupState.length-1].id = eventGroup.id;

                eventGroup.events.forEach(event => {
                    $eventGroupState[eventGroupIndex] = insertEvent($eventGroupState[eventGroupIndex], event);
                    $eventGroupState[eventGroupIndex].events[$eventGroupState[eventGroupIndex].events.length-1].id = event.id;
                });
            });

            progress = 25;
            statusMsg = "Importing project metadata and tags";

            projectJson.metadata.forEach(metadataInfo => {
                $metadataState = [...$metadataState, metadataInfo];
            });

            projectJson.speakers.forEach(speakerInfo => {
                $speakerState = [...$speakerState, speakerInfo];
            });  
            
            $markerState = projectJson.markers || {
                selected : 0,
                lists : [
                    {
                        id : '0',
                        name : "Shot Changes",
                        color : "#E74C3C",
                        markers : []
                    }
                ]
            };

            /* Import Style Info */
            if (projectJson.style){
                for (const [key, value] of Object.entries(projectJson.style)) {
                    $styleState[key] = value;
                }
            }

            if ($eventGroupState.length > 0){
                $projectState.selected = $eventGroupState.length - 1;
            }

            historyState.reset();
            historyState.insert({
                name: "import project", //action name
                eventGroup: false,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });

            if (projectJson.media.localPath === "") {               
                modalState.showModal('mediaImport');
            } else {
                progress = 50;
                statusMsg = "Importing media...";
                importMedia();
            }
        });
    } catch(err){
        console.log(err,err.msg);
        toast.push( `Project Import Failed. ${err.msg}`, {classes: ['toast-danger']});
        
        modalState.hideModal();
    } 
}

async function importMedia() {
    playerState.updateDuration(false);
    $uiState.timeline = false;
    let source = {}
    switch ($projectState.media.storage) {
        case 'Vimeo':
            source.src = $projectState.media.path;
            source.provider = 'vimeo';
            player.source = {
                type: "video",
                sources: [source]
            }

            audioExtractionComplete = true;
            manifestFileExists = true;
            break;
        case 'YouTube':
            source.src = $projectState.media.path;
            source.provider = 'youtube';
            player.source = {
                type: "video",
                sources: [source]
            }

            audioExtractionComplete = true;
            manifestFileExists = true;
            break;
        case 'HLS Manifest':            
            audioExtractionComplete = true;
            loadHlsStream($projectState.media.path);
            break;
        case 'Proxy RT':
            progress = 75;
            statusMsg = "Generating Proxy Stream...";  
            if (!checkFileExistsSync($projectState.media.localPath)){
                toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                modalState.showModal('mediaImport');
                return;
            }

            $projectState.media.path = videoOutputPath;

            ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
            $projectState.media.info = {
                incode: getMediaIncode(ffmpegRes),
                frameRate: getMediaFrameRate(ffmpegRes),
                duration : getMediaDuration(ffmpegRes)
            };

            audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
            audioExtractionWorker.postMessage({
                inputPath: $projectState.media.localPath,
                outputPath: audioOutputPath,
                ffmpegPath: ffmpegPath,
                duration : $projectState.media.info.duration
            });

            audioExtractionWorker.onmessage = (msg) =>{
                console.log(msg);
                if (msg.data.status === "in_progress") {
                    statusMsg = msg.data.result;
                } else {
                    $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                    $projectState.media.useFallback = false;

                    toast.push(`${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`, {classes: ['toast-`${msg.data.error ? "danger" : "success"}`,']});

                    audioExtractionComplete = true;
                    closeModal();
                }
            }    
            
            proxyRtWorker = new Worker("./build/workers/proxyRt.js");
            proxyRtWorker.postMessage({
                inputPath: $projectState.media.localPath,
                outputPath: videoOutputPath,
                ffmpegPath: ffmpegPath,
                tmpDir: tmpdir,
                pathSep: path.sep,
            });

            proxyRtWorker.onmessage = (msg) => {
                console.log(msg);
                if (msg.data.status_msg) {
                    loadHlsStream(videoOutputPath);
                } else if (msg.data.error) {
                    manifestFileExists = true;
                    toast.push(`${msg.data.error}`, {classes: ['toast-danger']});
                    closeModal();
                }
            };
            
            break;
        case "Cloud Storage":
            /* Cloud Storage*/
            source.src = $projectState.media.path;
            if ($projectState.media.type === "video/quicktime"){
                source.type = "video/mp4"
            } else if ($projectState.media.type === "video/x-matroska"){
                source.type = "video/mkv"
            } else {
                source.type = $projectState.media.type;
            }

            //Get file size of url
            let fileSize = await getFileSize($projectState.media.path);
            if (fileSize){
                const sizeInBytes = parseInt(fileSize);
                const sizeInKilobytes = sizeInBytes / 1024;
                const sizeInMegabytes = sizeInKilobytes / 1024;
                if (sizeInMegabytes < 512){
                    $projectState.media.useFallback = false;
                }
            }

            audioExtractionComplete = true;
            manifestFileExists = true;

            player.source = {
                type: "video",
                sources: [source],
            };
            break;
        default:
            if (!checkFileExistsSync($projectState.media.localPath)){
                toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                modalState.showModal('mediaImport');
                return;
            }
            
            /* Local Storage */
            source.src = $projectState.media.path;
            source.type = $projectState.media.type === "video/quicktime" ?  "video/mp4" : $projectState.media.type;
            progress = 75;
            manifestFileExists = true;
            statusMsg = "Generating audio preview...";
            source.src = $projectState.media.localPath;
            

            audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
            audioExtractionWorker.postMessage({
                inputPath: $projectState.media.localPath,
                outputPath: audioOutputPath,
                ffmpegPath: ffmpegPath,
                duration : $projectState.media.info.duration
            });

        audioExtractionWorker.onmessage = (msg) => {
            console.log(msg);
            if (msg.data.status === "in_progress") {
                statusMsg = msg.data.result;
            } else {
                $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                $projectState.media.useFallback = false;

                player.source = {
                    type: "video",
                    sources: [source],
                };

                toast.push(`${msg.data.error ? msg.data.error : "Media import completed successfully."}`, {classes: [`toast-${msg.data.error ? "danger" : "success"}`]});


                audioExtractionComplete = true;
                closeModal();
            }
        };
    }

    closeModal();
}

function loadHlsStream(mediaPath){
    const hls = new Hls();
    const videoTag = document.querySelector('video');

    hls.loadSource(mediaPath);
    hls.attachMedia(videoTag);        
    
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !'); 
    });        

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log('manifest loaded, found ' + data.levels.length + ' quality level'); 
        manifestFileExists = true; 
        closeModal();
    }); 
}

function closeModal(){
    console.log("Close Modal Called", audioExtractionComplete, manifestFileExists);
    if (audioExtractionComplete && manifestFileExists){
        statusMsg = "Project import complete...";
        progress = 100;

        setTimeout(() => {
            $uiState.timeline = true;
            modalState.hideModal();
        }, 2500);
    }
}

function convertProjectToV3(projectJson) {
    let projectConvert = new _Project({
        type: "default",
        name: projectJson.projectSettings.title,
        description: "Converter By CC Converter",
        created: Date.now(),
        frameRate: parseFloat(projectJson.projectSettings.framerate || 29.97),
        dropFrame: frameRates.dropFrameMap[parseFloat(projectJson.projectSettings.framerate || 29.97)],
        userName: $environment.online ? firebase.auth().currentUser.email : "Offline",
        ownerId: $environment.online ? firebase.auth().currentUser.uid : "Offline"
    });
    let events = [];
    projectJson.captions.forEach(caption => {
        try {
            let start = caption.incodeSec || 0;
            let end = caption.outcodeSec || 0;
            let style = caption.style || "Pop-On";
            let alignment = "center";
            let xPos = "center";
            let yPos = "end";
            let text;
            if (caption.primaryLang[0].captionText) {
                text = convertToHtml(caption.primaryLang.map(line => {
                    let prefix = "",
                        suffix = "";
                    prefix += line.bold ? "<b>" : "";
                    suffix = line.bold ? "<b>" + suffix : suffix;
                    prefix += line.italics ? "<i>" : "";
                    suffix = line.italics ? "<i>" + suffix : suffix;
                    prefix += line.underline ? "<u>" : "";
                    suffix = line.underline ? "<u>" + suffix : suffix;
                    return prefix + line.captionText + suffix;
                }).join("\n"));

                if (caption.primaryLang[0].positionY < 8) {
                    yPos = "start";
                }
            } else {
                text = convertToHtml(caption.subtitleText);
            }

            events.push(new _Event({
                start: start,
                end: end,
                style: style,
                text: text,
                alignment: alignment,
                xPos: xPos,
                yPos: yPos
            }));
        } catch (err) {
            console.log(err, err.message);
        }
    });

    projectConvert.eventGroups = [new _EventGroup({
        name : projectConvert.name,
        type : "subtitle",
        events : events
    })]

    if (projectJson.transcription && projectJson.transcription.text){
        let transcriptionEvent = new _Event({
            text : convertToHtml(projectJson.transcription.text)
        });

        projectConvert.eventGroups.push(new _EventGroup({
            name : projectConvert.name + " (transcript)",
            type : "transcription",
            events : [transcriptionEvent]
        }));
    }

    return projectConvert;
}

async function getFileSize() {
    try {
        const response = await fetch($projectState.media.path, {
            method: "HEAD",
        });
        const fileSize = response.headers.get("content-length");
        return fileSize;
    } catch (err){
        return false;
    }    
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'loadProjectFile' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="LoadProjectFileModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Project File Loader</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p ><i class="bi bi-exclamation-diamond-fill"></i>Import in progress | {statusMsg}</p>
                <div class="progress">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                </div>
            </div>
        </div>
    </div>
</div>
