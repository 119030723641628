import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _blurayFunc from "../functions/profiles/bluray.js";
import _flex from "../dict/flexbox.js";
import _tcLib from "../lib/timecode.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _eol from "eol";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _formatXml from "xml-formatter";
const formatXml = _formatXml;
const removeInvalidEvents = _removeInvalidEvents;
const eol = _eol;
const getFormatOptions = _getFormatOptions;
const tcLib = _tcLib;
const flex = _flex;
const blurayFunc = _blurayFunc;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
export default {
  decode: function () {
    throw new Error("This profile [Blu-ray (XML/PNG)] is not supported for decoding by Closed Caption Converter.");
  },
  encode: async function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let version = 0.93;
    let language = encodingOptions["Language"] || "Unknown";
    let langCode = encodingOptions["Language Code"] || "nul";
    let imageName = encodingOptions["Image Name"] || "DVD";
    let marginPercent = encodingOptions["Margin"] || 10;
    let fontSize = encodingOptions["Font Size"] || 40;
    let width = encodingOptions["Width"] || 1920;
    let height = encodingOptions["Height"] || 1080;
    let fontString = encodingOptions["Font"] || "Arial (sans-serif)";
    let font = blurayFunc.cleanFontName(fontString);
    let {
      xOffset,
      yOffset
    } = options.window;
    let eventsCount = eventGroup.events.length;
    let firstIn = tcLib.secToTc(eventGroup.events[0].start).replace(",", ":");
    let lastOut = tcLib.secToTc(eventGroup.events[eventsCount - 1].end).replace(",", ":");
    let output = [];
    let xml = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    xml += eol.after(`<BDN Version="${version}" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="BD-03-006-0093b BDN File Format.xsd">`);
    xml += eol.after(`<Description>`);
    xml += eol.after(`<Name Title="${language}" Content=""/>`);
    xml += eol.after(`<Language Code="${langCode}"/>`);
    xml += eol.after(`<Events LastEventOutTC="${lastOut}" FirstEventInTC="${firstIn}" ContentInTC="${firstIn}" ContentOutTC="${firstIn}" NumberofEvents="${eventsCount}" Type="Graphic"/>`);
    xml += eol.after(`</Description>`);
    xml += eol.after(`<Events>`);
    eventGroup.events.forEach((event, i) => {
      let forced = event.forced ? true : false; // Conform to boolean
      let start = tcLib.secToTc(event.start);
      let end = tcLib.secToTc(event.end);
      let fileName = imageName + i.toString().padStart(4, "0");
      +".png";
      xml += eol.after(`<Event Forced="${forced}" InTC="${start}" OutTC="${end}">`);
      xml += `<Graphic Width="${width}" Height="${height}" X="${xOffset}" Y="${yOffset}">`;
      xml += fileName;
      xml += eol.after(`</Graphic>`);
      xml += eol.after(`</Event>`);
    });
    xml += eol.after("</Events>");
    xml += eol.after("</BDN>");
    for (var i = 0; i < eventGroup.events.length; i++) {
      let event = eventGroup.events[i];
      let fileName = imageName + i.toString().padStart(4, "0");
      +".png";
      const image = await blurayFunc.genImage(event.text, event.color, height, width, event.xPos, event.yPos, fontSize, font, marginPercent);
      output.push({
        name: `${fileName}.png`,
        content: image,
        base64: true
      });
    }
    const content = {
      name: 'blurayxml.xml',
      content: formatXml(xml)
    };
    output.push(content);
    return await blurayFunc.createZipBuffer(output);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};