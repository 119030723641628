import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _Event2 from "../classes/event.js";
import _eol from "eol";
import _stripTags from "../functions/quill/stripTags.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
const getFormatOptions = _getFormatOptions;
const stripTags = _stripTags;
const eol = _eol;
const _Event = _Event2;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
export default {
  decode: function (input, options) {
    let events = [],
      decodingOptions = getFormatOptions(options.formatOptions),
      ccEvent,
      endEventFlag = false,
      lines = [],
      line = "",
      singleWord = false,
      ccAdded = false,
      maximumChars = decodingOptions["Max Characters Per Line"] ? decodingOptions["Max Characters Per Line"] : 32,
      maxLines = decodingOptions["Max Lines Per Event"] ? decodingOptions["Max Lines Per Event"] : 2,
      splitOnComma = decodingOptions["Split On Comma"] || "no",
      splitOnPunctuation = decodingOptions["Split On Punctuation"] || "no",
      blockImport = decodingOptions["Block Import"] || "no",
      cps = decodingOptions["Reading Rate"] ? decodingOptions["Reading Rate"] : 20;
    let paragraphs = eol.lf(input).split("\n\n");
    while (paragraphs && paragraphs.length > 0) {
      let paragraph = paragraphs.shift();
      if (blockImport === "yes") {
        ccEvent = new _Event();
        ccEvent.text = convertToHtml(paragraph);
        events.push(ccEvent);
        continue;
      }
      paragraph = paragraph.split("\n");
      while (paragraph && paragraph.length > 0) {
        ccAdded = false;
        let words = paragraph.shift().split(" ");
        if (words.length === 1) {
          singleWord = true;
        } else {
          singleWord = false;
        }
        ccEvent = new _Event();
        line = "";
        lines = [];
        while (words.length > 0) {
          let word = words.shift();
          if (line.length + word.length > maximumChars) {
            lines.push(line);
            line = word;
          } else {
            line += " " + word;
          }

          //console.log(lines.length, maxLines);
          if (lines.length === maxLines) {
            ccEvent.text = convertToHtml(lines.join(" "));
            events.push(ccEvent);
            ccAdded = true;
            lines = [];
            ccEvent = new _Event();
          }
          if (splitOnComma && word.includes(",")) {
            endEventFlag = true;
          }
          if (splitOnPunctuation && word.match(/\.|\?|\!/g)) {
            endEventFlag = true;
          }
          if (endEventFlag && (line.length > 0 || lines.length > 0)) {
            if (line.length > 0) {
              lines.push(line);
              line = "";
            }
            ccEvent.text = convertToHtml(lines.join(" "));
            events.push(ccEvent);
            ccAdded = true;
            lines = [];
            ccEvent = new _Event();
            endEventFlag = false;
          }
        }
        if (line.length > 0) {
          lines.push(line);
          ccEvent.text = convertToHtml(lines.join(" "));
          events.push(ccEvent);
        } else if (lines.length > 0) {
          ccEvent.text = convertToHtml(lines.join(" "));
          events.push(ccEvent);
        }
      }
    }
    let currentTime = 0;
    events.forEach((ev, evIndex, evs) => {
      let text = convertToPlainText(ev.text, "");
      let characters = text.length;
      if (characters === 0) {
        return;
      }
      let duration = Math.max(0.5, characters / cps);
      evs[evIndex].start = currentTime;
      evs[evIndex].end = currentTime + duration;
      currentTime += duration;
    });

    // console.log(events);
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "",
      paragraphs = [],
      sentences = [],
      speaker = false;
    let encodingOptions = getFormatOptions(options.formatOptions);
    let encodeFormatting = encodingOptions["Encode Formatting"];
    eventGroup.events.forEach((event, index, events) => {
      let eventText = encodeFormatting ? convertToPlainTextCustom(event.text, " ", false) : convertToPlainText(event.text, " ");
      let text = (encodingOptions["Event Prefix"] ? encodingOptions["Event Prefix"].replace("$id", index) : "") + eventText + (encodingOptions["Event Suffix"] ? encodingOptions["Event Suffix"] : "");
      if (index > 0 && encodingOptions["Split On Event Gap"] && event.start >= events[index - 1].end + parseFloat(encodingOptions["Split On Event Gap"])) {
        paragraphs.push(sentences.join(" "));
        sentences = [text];
      } else {
        sentences.push(text);
      }
    });
    if (sentences.length > 0) {
      paragraphs.push(sentences.join(" "));
    }
    output = paragraphs.join("\n\n");

    // console.log(output);
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      eventGroup.events = eventGroup.events.filter(event => {
        return stripTags(event.text).trim() !== "";
      });
      return eventGroup;
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      return eventGroup;
    }
  }
};