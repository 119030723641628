<script>
    import {
        authState
    } from "@app/store/authStore.js";
    import {
        modalState
    } from "@app/store/modalStore.js";
    import {
        eventGroupState
    } from "@app/store/eventGroupStore.js";
    import {
        projectState
    } from "@app/store/projectStore.js";
    import {
        speakerState
    } from "@app/store/speakerStore.js";
    import {
        uiState
    } from "@app/store/uiStore.js";
    import {
        markerState
    } from '@app/store/markerStore.js';
    import {
        metadataState
    } from "@app/store/metadataStore.js";
    import {
        historyState
    } from "@app/store/historyStore.js";
    import {
        toast } from '@zerodevx/svelte-toast';
    import {
        playerState
    } from "@app/store/playerStore.js";
    import {
        environment
    } from "@app/store/envStore.js";
    import {
        onDestroy
    } from "svelte";
    import {
        fade
    } from "svelte/transition";
    
    import Swal from "sweetalert2";
    import {
        Circle
    } from "svelte-loading-spinners";
    import insertEvent from "@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js";
    import loadMedia from "@app/external/cc-lib/dist/functions/projects/loadMedia.js";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    /* HLS */
    import Hls from "hls.js";
    
    import {
        v4 as uuidv4
    } from "uuid";
    import Project from "@app/external/cc-lib/dist/classes/project.js";
    import EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import supportedLanguages from "@app/external/cc-lib/dist/dict/supportedLanguages.js";
    import voiceLib from "@app/external/cc-lib/dist/providers/textToSpeechAll/voices.js";
    import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    
    let loading = false;
    let audioExtractionComplete = false;
    let manifestFileExists = false;
    let projectDefaults = JSON.parse(localStorage.getItem("cc-project-defaults")) || {};
    let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
    let projectName,
        useLocalStorage = projectDefaults.useLocalStorage,
        projectType = "default",
        incode = "00:00:00:00",
        frameRate = projectDefaults.frameRate || "24",
        dropFrame = projectDefaults.dropFrame || "false",
        ffmpegRes,
        mediaSource = projectDefaults.mediaSource || "Local Storage",
        files = [],
        mediaUrl = "",
        /* Advanced Options */
        aspectRatio = projectDefaults.aspectRatio || "16:9",
        eventGroupType = projectDefaults.eventGroupType || "subtitle",
        displayName = "",
        language = projectDefaults.language || "en-US",
        rtl = projectDefaults.rtl,
        /* Audio Description (AD) Settings */
        gender = "male",
        selectedLanguage = "English - US",
        virtualVoice = voiceLib[selectedLanguage][gender][0],
        languages = supportedLanguages.languages,
        showAdvancedOptions = false,
        mediaProgress = "starting...",
        audioExtractionWorker,
        proxyRtWorker;

    const uid = uuidv4();
    onDestroy(async () => {
        if (audioExtractionWorker) {
            audioExtractionWorker.terminate();
        }
    });

    async function getFileSize() {
    try {
        const response = await fetch($projectState.media.path, {
            method: "HEAD",
        });
        const fileSize = response.headers.get("content-length");
        return fileSize;
    } catch (err){
        return false;
    }    
}

    function validateTc(e) {
        incode = tcLib.tcValidate(e.target.value, parseFloat(frameRate), dropFrame === "false" ? false : true);
    }
    
    async function getFileMetadata(filePath, ffmpegPath) {
        try {
            const exec = window.exec;
            return await exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
        } catch (err) {
            return err.message;
        }
    }
    
    function getMediaIncode(ffmpegOutput) {
        let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
        let mediaIncode = matches ? matches[0].replace(";", ":") : false;
    
        console.log("Media incode detected: " + mediaIncode);
        return mediaIncode == "null" ? false : mediaIncode;
    }
    
    function getMediaFrameRate(ffmpegOutput) {
        // console.log("GETTING MEDIA FRAME RATE");
        //console.log("FFMPEG OUTPUT:");
        //console.log(ffmpegOutput);
    
        let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
        let mediaFrameRate = matches ? matches[0] : false;
    
        console.log("Media frame rate detected: " + mediaFrameRate);
        let fr = frameRates.frameRateMapping[mediaFrameRate];
        return fr;
    }

    function getMediaDuration(ffmpegOutput){
       /*  console.log("GETTING MEDIA DURATION");
        console.log("FFMPEG OUTPUT:");
        console.log(ffmpegOutput); */
        let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
        let mediaDuration = matches ? matches[0] : false;

        console.log(mediaDuration);
        if (mediaDuration){
            mediaDuration = tcLib.tcMsToSec(mediaDuration);
            console.log("media duration in seconds: " + mediaDuration);
        }

        return mediaDuration;
    }
    
    async function createProject() {
        loading = true;
        //User Sweetaler to confirm if user wants to create project
        if (($eventGroupState.length > 0 && $eventGroupState[0].events.length > 1) || $eventGroupState.length > 1) {
            let swalRes = await Swal.fire({
                titleText: "Create Project",
                text: "Creating a new project will clear the current project. Are you sure you want to continue?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes",
                denyButtonText: "No",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger text-white",
                    denyButton: "btn btn-light mx-2",
                    cancelButton: "btn btn-outline-secondary",
                },
            });

            if (swalRes.isDenied) {
                loading = false;
                modalState.hideModal();
                return;
            }
        }

        $uiState.timeline = false;
        $projectState.selected = false;
        /* Reset State */
        $eventGroupState = [];
        $speakerState = [];
        $markerState = {
            selected: 0,
            lists: [{
                id: '0',
                name: "Shot Changes",
                color: "#E74C3C",
                markers: []
            }]
        };
        $metadataState = [];
        playerState.updateDuration(false);
    
        $projectState = new Project({
            type: projectType,
            name: projectName,
            incode: tcLib.tcToSec(incode, parseFloat(frameRate), dropFrame === "false" ? false : true),
            frameRate: parseFloat(frameRate),
            dropFrame: dropFrame === "false" ? false : true,
            username: $environment.online ? firebase.auth().currentUser.email : "Unknown",
            ownerId: $environment.online ? firebase.auth().currentUser.uid : "Unknown",
            useLocalStorage: useLocalStorage,
        });
    
        console.log("Project FrameRate and drop Frame: " + $projectState.frameRate + " " + $projectState.dropFrame);
    
        $eventGroupState = [
            new EventGroup({
                type: eventGroupType,
                name: displayName,
                language: language,
                rtl: rtl,
                overlap: eventGroupDefaults.overlap,
                maxCps: eventGroupDefaults.maxCps || 25,
                maxWpm: eventGroupDefaults.maxWpm || 9999,
                maxChars: eventGroupDefaults.maxChars || 32,
                maxLines: eventGroupDefaults.maxLines || 2,
                minDuration: eventGroupDefaults.minDuration || 0.5,
                maxDuration: eventGroupDefaults.maxDuration || 12,
                illegalChars: eventGroupDefaults.illegalChars ?? false,
                ad: {
                    voice: virtualVoice,
                    gender: gender,
                    language: selectedLanguage,
                },
            }),
        ];
    
        $projectState.selected = 0;
    
        $eventGroupState[$projectState.selected] = insertEvent($eventGroupState[$projectState.selected]);

        $projectState = loadMedia($projectState, {
            projectName: projectName,
            storage: mediaSource,
            mediaUrl: mediaUrl,
            files: files,
            incode: tcLib.tcToSec(incode, parseFloat(frameRate), dropFrame === "false" ? false : true),
            frameRate: parseFloat(frameRate),
            dropFrame: dropFrame === "false" ? false : true,
            aspectRatio: aspectRatio,
        });
    
        /* Load Media Into Player */
        let source = {
            src: $projectState.media.path,
        };
    
        switch (mediaSource) {
            case "Vimeo":                
                source.src = $projectState.media.path;
                source.provider = "vimeo";
                player.source = {
                    type: "video",
                    sources: [source],
                };
                break;
            case "YouTube":
                source.src = $projectState.media.path;
                source.provider = "youtube";
                player.source = {
                    type: "video",
                    sources: [source],
                };
                break;
            case "HLS Manifest":
                loadHlsStream($projectState.media.path);
                break;
            case "Proxy RT":
                const ffmpegPath = require("ffmpeg-static-electron").path;
                const os = window.os;
                const path = window.path;
                const tmpdir = os.tmpdir();
                const videoOutputPath = tmpdir + path.sep + uid + ".m3u8";
                const audioOutputPath = tmpdir + path.sep + uid + ".json";
    
                $projectState.media.path = videoOutputPath;

                ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                $projectState.media.info = {
                    incode: getMediaIncode(ffmpegRes),
                    frameRate: getMediaFrameRate(ffmpegRes),
                    duration : getMediaDuration(ffmpegRes)
                };

                /* -------------auto detect frame rate start------------- */
                if ($projectState.media.info.frameRate && $projectState.media.info.frameRate != frameRate) {
                    if (await alertUserBoolean(`Would you like to update the Project frame rate to match the frame rate found in the media (${$projectState.media.info.frameRate})?`)) {
                        frameRate = $projectState.media.info.frameRate;
                        dropFrame = $projectState.media.info.frameRate === 29.97 || $projectState.media.info.frameRate === 59.94 ? true : false;
    
                        $projectState.frameRate = frameRate;
                        $projectState.dropFrame = dropFrame;
                    }
                }
                /* -------------auto detect frame rate end------------- */
    
                /* -------------auto detect incode start------------- */
                if ($projectState.media.info.incode && $projectState.media.info.incode != incode) {
                    if (await alertUserBoolean(`Would you like to update the Project incode to match the incode found in the media (${$projectState.media.info.incode})?`)) {
                        incode = $projectState.media.info.incode;
                        $projectState.incode = tcLib.tcToSec(incode, $projectState.frameRate, $projectState.dropFrame);
                    }
                }
                /* -------------auto detect incode end------------- */
    
                audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
                audioExtractionWorker.postMessage({
                    inputPath: $projectState.media.localPath,
                    outputPath: audioOutputPath,
                    ffmpegPath: ffmpegPath,
                    duration : $projectState.media.info.duration
                });
    
                audioExtractionWorker.onmessage = (msg) => {
                    console.log(msg);
                    if (msg.data.status === "in_progress") {
                        mediaProgress = msg.data.result;
                    } else {
                        $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                        $projectState.media.useFallback = false;
                        toast.push(`${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`, {classes: ['toast-`${msg.data.error ? "danger" : "success"}`,']});
    
                        audioExtractionComplete = true;
                        closeModal();
                    }
                };
    
                proxyRtWorker = new Worker("./build/workers/proxyRt.js");
                proxyRtWorker.postMessage({
                    inputPath: $projectState.media.localPath,
                    outputPath: videoOutputPath,
                    ffmpegPath: ffmpegPath,
                    tmpDir: tmpdir,
                    pathSep: path.sep,
                });
    
                proxyRtWorker.onmessage = (msg) => {
                    console.log(msg);
                    if (msg.data.status_msg) {
                        manifestFileExists = true;
                        loadHlsStream(videoOutputPath);
                    } else if (msg.data.error) {
                        toast.push(`${msg.data.error}`, {classes: ['toast-danger']});
                    }
                };
    
                break;
            case "Cloud Storage":
                /* Cloud Storage*/
                source.src = $projectState.media.path;
                if ($projectState.media.type === "video/quicktime"){
                    source.type = "video/mp4"
                } else if ($projectState.media.type === "video/x-matroska"){
                    source.type = "video/mkv"
                } else {
                    source.type = $projectState.media.type;
                }

                //Get file size of url
                let fileSize = await getFileSize($projectState.media.path);
                if (fileSize){
                    const sizeInBytes = parseInt(fileSize);
                    const sizeInKilobytes = sizeInBytes / 1024;
                    const sizeInMegabytes = sizeInKilobytes / 1024;
                    if (sizeInMegabytes < 512){
                        $projectState.media.useFallback = false;
                    }
                }

                player.source = {
                    type: "video",
                    sources: [source],
                };
                break;
            default:
                /* Local Storage */
                source.src = $projectState.media.path;
                source.type = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type;
    
                if ($environment.electron) {
                    const ffmpegPath = require("ffmpeg-static-electron").path;
                    const os = window.os;
                    const path = window.path;
                    const audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    //console.log(ffmpegPath);
                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    console.log("-----FILE INFO-----");
                    console.log(ffmpegRes);
                    console.log("-------------------");
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };
                    /* Auto detect frame rate */
                    if ($projectState.media.info.frameRate && $projectState.media.info.frameRate != frameRate) {
                        if (await alertUserBoolean(`Would you like to update the Project frame rate to match the frame rate found in the media (${$projectState.media.info.frameRate})?`)) {
                            frameRate = $projectState.media.info.frameRate;
                            dropFrame = $projectState.media.info.frameRate === 29.97 || $projectState.media.info.frameRate === 59.94 ? true : false;
    
                            $projectState.frameRate = frameRate;
                            $projectState.dropFrame = dropFrame;
                        }
                    }
                    /* -------------auto detect frame rate------------- */
    
                    /* Auto detect incode */
                    if ($projectState.media.info.incode && $projectState.media.info.incode != incode) {
                        if (await alertUserBoolean(`Would you like to update the Project incode to match the incode found in the media (${$projectState.media.info.incode})?`)) {
                            incode = $projectState.media.info.incode;
                            $projectState.incode = tcLib.tcToSec(incode, $projectState.frameRate, $projectState.dropFrame);
                        }
                    }
                    /* -------------auto detect incode------------- */
    
                    audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                        duration : $projectState.media.info.duration
                    });
    
                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            mediaProgress = msg.data.result;
                        } else {
                            $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                            $projectState.media.useFallback = false;
                            player.source = {
                                type: "video",
                                sources: [source],
                            };
                            toast.push(`${msg.data.error ? msg.data.error : "Media import completed successfully."}`, {classes: [`toast-${msg.data.error ? "danger" : "success"}`]});
    
                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };
                } else {
                    if (files[0].size < 524288000) { // 500MB (524288000 bytes)
                        $projectState.media.useFallback = false;                        
                    }
                    
                    player.source = {
                        type: "video",
                        sources: [source],
                    };
                }
        }
    
        localStorage.setItem(
            "cc-project-defaults",
            JSON.stringify({
                eventGroupType: eventGroupType,
                projectType: projectType,
                incode: incode,
                frameRate: frameRate.toString(),
                dropFrame: dropFrame ? "true" : "false",
                mediaSource: mediaSource,
                aspectRatio: aspectRatio,
                useLocalStorage: useLocalStorage,
            })
        );
    
        localStorage.setItem(
            "cc-ad-defaults-new",
            JSON.stringify({
                voice: virtualVoice,
                gender: gender,
                language: selectedLanguage,
            })
        );
    
        historyState.reset();
        historyState.insert({
            name: "create project", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    
        closeModal();
    }
    
function loadHlsStream(mediaPath){
    const hls = new Hls();
    const videoTag = document.querySelector('video');

    hls.loadSource(mediaPath);
    hls.attachMedia(videoTag);        
    
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !'); 
    });        

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log('manifest loaded, found ' + data.levels.length + ' quality level'); 
        manifestFileExists = true; 
        closeModal();
    }); 
}
    
    function toggleAdvancedOptions() {
        showAdvancedOptions = !showAdvancedOptions;
    }
    
    function closeModal() {
        console.log("Close Modal Called", audioExtractionComplete, manifestFileExists);
        if (mediaSource === "Vimeo" || mediaSource === "YouTube" || mediaSource === "Cloud Storage") {
            console.log("YOUTUBE AND VIMEO AND CLOUD STORAGE");
            setTimeout(() => {
                $uiState.timeline = true;
                modalState.hideModal();
            }, 2500);
        }

        if (manifestFileExists && mediaSource === "HLS Manifest") {
            $uiState.timeline = true;
            modalState.hideModal();
        }

        if ((audioExtractionComplete || !$environment.electron) && mediaSource === "Local Storage") {
            setTimeout(() => {
                $uiState.timeline = true;
                modalState.hideModal();
            }, 2500);
        }

        if (audioExtractionComplete && manifestFileExists && mediaSource === "Proxy RT") {
            $uiState.timeline = true;
            modalState.hideModal();
        }

        return;
    }
    
    function alertUserBoolean(msg) {
        let response = Swal.fire({
            titleText: "Create Project",
            text: msg,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger text-white",
                denyButton: "btn btn-light mx-2",
                cancelButton: "btn btn-outline-secondary",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                return true;
            } else {
                return false;
            }
        });
    
        return response;
    }
    
    
    $: if (["23.976", "24", "25", "30", "60"].indexOf(frameRate) > -1) {
        dropFrame = "false";
    }
    </script>
    
    <div transition:fade={{ duration: 100 }} class="modal {$modalState === 'newProject' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="NewProjectModal">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">New Project</h4>
                    <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
                </div>
                <div class="modal-body">
                    <form on:submit|preventDefault={createProject} id="NewProjectForm">
                        <div class="mb-3">
                            <label class="form-label" for="projectTypeInput">Project Type</label>
                            <select id="projectTypeInput" class="form-select" bind:value={projectType}>
                                <option value="default">Default</option>
                                {#if $authState.team && $authState.team.id}
                                <option value="team">Team</option>
                                {/if}
                                <!-- <option value="live">Live</option> -->
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="projectNameInput">Project Name</label>
                            <input id="projectNameInput" class="form-control" type="text" placeholder="Project Name" bind:value={projectName} required />
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="mediaSourceSelect">Media Source</label>
                            <select id="mediaSourceSelect" class="form-select" bind:value={mediaSource}>
                                <option value="Local Storage">Local Storage</option>
                                {#if $environment.online}
                                    <option value="YouTube">YouTube</option>
                                    <option value="Vimeo">Vimeo</option>
                                    <option value="Cloud Storage">Cloud Storage</option>
                                    <option value="HLS Manifest">HLS Manifest</option>
                                {/if}
                                {#if $environment.electron}
                                <option value="Proxy RT">Proxy RT</option>
                                {/if}
                            </select>
                        </div>
                        <div class="mb-3">
                            {#if mediaSource === "Local Storage"}
                            <p class="mb-2">
                                <span class="badge bg-danger">Required</span> Media Location
                            </p>
                            <input type="file" class="form-control" accept="video/*,audio/*" bind:files />                       
                            {:else if mediaSource === "HLS Manifest"}
                            <label class="form-label" for="HlsManifestUrl">HLS Manifest URL</label>
                            <input bind:value={mediaUrl} type="url" class="form-control" id="HlsManifestUrl" placeholder="Manifest URL" />
                            {:else if mediaSource === "Proxy RT"}
                            <p class="mb-2">Media Location</p>
                            <input type="file" class="form-control" accept=".mxf, .ixf, .aaf, .imf, .gxf, .mov, .mp4, .mpeg, .mpeg2, .mpg, .avi, .mkv, .wav, .mp3, .flac" bind:files />
                            {:else if mediaSource === "YouTube"}
                            <label class="form-label" for="YouTubeSourceUrl">YouTube Page URL</label>
                            <input bind:value={mediaUrl} type="url" class="form-control" id="YouTubeSourceUrl" placeholder="Video URL" />
                            {:else if mediaSource === "Vimeo"}
                            <label class="form-label" for="VimeoSourceUrl">Vimeo Page URL</label>
                            <input bind:value={mediaUrl} type="url" class="form-control" id="VimeoSourceUrl" placeholder="Video URL" />
                            {:else}
                            <label class="form-label" for="CloudSourceUrl">Cloud Storage URL</label>
                            <input bind:value={mediaUrl} type="url" class="form-control" id="CloudSourceUrl" placeholder="Cloud URL" />
                            {/if}
                        </div>
                        <div>
                            <a href="#!/" class="mb-2 dropdown-toggle" on:click={toggleAdvancedOptions}>View More Options</a>
                            {#if showAdvancedOptions}
                            <div id="advancedOptionsWrapper" class="mt-2" transition:fade={{ duration: 200 }}>
                                <div class="mb-3">
                                    <label class="form-label" for="group type">Group Type</label>
                                    <select class="form-select" bind:value={eventGroupType}>
                                        <option value="subtitle">Subtitle</option>
                                        {#if $authState.ad}
                                        <option value="audio description">Audio Description</option>
                                        {/if}
                                        <option value="transcription">Transcription</option>
                                    </select>
                                </div>
                                <!-- Name -->
                                <div class="mb-3">
                                    <label class="form-label" for="display name">Display Name</label>
                                    <input class="form-control" required placeholder="Display Name" type="text" bind:value={displayName} />
                                </div>
                                <!-- Language -->
                                <div class="mb-3">
                                    <label class="form-label" for="Language">Language</label>
                                    <select class="form-select" bind:value={language}>
                                        {#each languages as languageOption, index (index)}
                                        <option>{languageOption}</option>
                                        {/each}
                                    </select>
                                </div>
                                <div class="form-check form-switch mb-2">
                                    <input type="checkbox" class="form-check-input" id="rtlSwitch" bind:checked={rtl} />
                                    <label class="form-check-label" for="rtlSwitch">Right-To-Left</label>
                                </div>
                                <div class="mb-3">
                                    <label for="incodeInput" class="form-label">Project Incode</label>
                                    <input
                                        placeholder="00:00:00:00"
                                        type="text"
                                        class="form-control"
                                        id="incodeInput"
                                        bind:value={incode}
                                        on:blur={validateTc}
                                        on:keyup={(e) => e.key === "Enter" && validateTc(e)}
                                    on:focus={(e) => {
                                    e.target.select();
                                    }} />
                            </div>
                            <div class="row">
                                <div class="mb-3 col">
                                    <label class="form-label" for="frameRateSelector">Frame Rate</label>
                                    <select id="frameRateSelector" class="form-select" bind:value={frameRate}>
                                        <option value="23.976">23.976</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="29.97">29.97</option>
                                        <option value="30">30</option>
                                        <option value="59.94">59.94</option>
                                        <option value="60">60</option>
                                    </select>
                                </div>
                                {#if ["29.97", "59.94"].indexOf(frameRate) > -1}
                                <div class="mb-3 col">
                                    <label class="form-label" for="dropFrameSelector">Drop Frame</label>
                                    <select id="dropFrameSelector" class="form-select" bind:value={dropFrame}>
                                        <option value="true">true</option>
                                        <option value="false">false</option>
                                    </select>
                                </div>
                                {/if}
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="Aspect Ratio">Aspect Ratio</label>
                                <select class="form-select" bind:value={aspectRatio}>
                                    <option>16:9</option>
                                    <option>4:3</option>
                                </select>
                            </div>
                        </div>
                        {/if}
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    {#if loading}
                    <p class="text-muted small">Scanning Media | {mediaProgress}</p>
                    <Circle size="30" color="#1eb4b2" unit="px" duration="1s" />
                    {/if}
                    <button on:click={createProject} class="btn btn-primary ms-2" type="button" disabled={loading || (mediaSource === "Local Storage" && files.length === 0) || (mediaSource === "Proxy RT" && files.length === 0) || (mediaSource !== "Local Storage" && mediaSource !== "Proxy RT" && mediaUrl.length === 0) || (!$authState.team && projectType === "team")}> Create Project</button>
                </div>
            </div>
        </div>
    </div>
    