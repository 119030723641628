<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from "@app/store/historyStore.js";

function assignSpeakerVoice(speaker) {
    if ($eventGroupState[$projectState.selected].selected.length > 0 && $eventGroupState[$projectState.selected]) {
        $eventGroupState[$projectState.selected].selected.forEach(eventId => {
            if ($eventGroupState[$projectState.selected].events[eventId]) {
                $eventGroupState[$projectState.selected].events[eventId].speakers = [...$eventGroupState[$projectState.selected].events[eventId].speakers, speaker].filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.place === value.place && t.name === value.name
                    ))
                );
            }
        });

        toast.push("Event speaker updated successfully", {classes: ['toast-success']});

        historyState.insert({
            name: "speaker update", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    }
}

function assignRecipientVoice(speaker) {
    if ($eventGroupState[$projectState.selected].selected.length > 0 && $eventGroupState[$projectState.selected]) {
        $eventGroupState[$projectState.selected].selected.forEach(eventId => {
            if ($eventGroupState[$projectState.selected].events[eventId]) {
                $eventGroupState[$projectState.selected].events[eventId].recipients = [...$eventGroupState[$projectState.selected].events[eventId].recipients, speaker].filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.place === value.place && t.name === value.name
                    ))
                );
            }
        });

        toast.push("Event speaker updated successfully", {classes: ['toast-success']});

        historyState.insert({
            name: "speaker update", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    }
}
</script>

<div id='SpeakerWrapper' class="rounded">
    <div class="row p-3">
        <div id="SpeakerList" class="col-11 resize-vertical">
            <div class="row g-2">
                <!-- <div class="col-2 p-2">
                    <button type="button" class="btn btn-outline-dark w-100">
                        <h1><i class="bi bi-person-circle"></i></h1>
                        <p class="text-muted small m-0 text-capitalize text-truncate">Manual</p>
                    </button>
                </div>    -->
                {#each $speakerState as speaker}
                <div class="col-3">
                    <div class="speakerCard p-2 text-center bg-light rounded">
                        <h1 style="color:{speaker.colour}; font-size: 2vw; margin:0;"><i class="bi bi-person-fill"></i></h1>
                        <p style="color:{speaker.colour}" class="small m-0 text-capitalize text-truncate" title='{speaker.name}'>{speaker.name}</p>
                        <div class="btn-group btn-group-sm" role="group">
                            <button type="button" class="btn btn-secondary text-custom-medium" title="Add Speaker" on:click="{assignSpeakerVoice(speaker)}"><i class="bi bi-plus"></i> SP</button>
                            <button type="button" class="btn btn-secondary text-custom-medium" title="Add Recipient" on:click="{assignRecipientVoice(speaker)}"><i class="bi bi-plus"></i> RE</button>
                        </div>                        
                    </div>
                </div>
                {:else}
                <p class="m-3 text-muted">No speakers found</p>
                {/each}

            </div>
        </div>
        <div class="col-1 p-2">
            <button type="button" class="btn btn-light btn-sm h-100 float-end" on:click={() => modalState.showModal("speakerManager")}>
                <i class="bi bi-three-dots"></i>
            </button>
        </div>
    </div>
</div>

<style>
#SpeakerWrapper {
    background-color: rgba(255, 255, 255, 0.03);
}

.speakerCard .btn-group {
    display : none;
}

.speakerCard:hover .btn-group {
    display : block;
}
</style>
