import _dialogueListFunc from "../functions/profiles/dialogueList.js";
import _Papa from "papaparse";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const Papa = _Papa;
const dialogueListFunc = _dialogueListFunc;
export default {
  decode: async function (input, options) {
    throw new Error("Closed Caption Converter does not support reading CSV Dialogue Lists.");
  },
  encode: async function (eventGroup, options) {
    let exportTitlePage, exportTitle, exportMetadata, exportEventId, exportSpeakers, exportTags, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration;
    let encodingOptions = getFormatOptions(options.formatOptions);
    if (encodingOptions["Export Title Page"]) {
      exportTitlePage = encodingOptions["Export Title Page"];
    }
    if (encodingOptions["Title"]) {
      exportTitle = encodingOptions["Title"];
    }
    if (encodingOptions["Export Metadata"]) {
      exportMetadata = encodingOptions["Export Metadata"];
    }
    if (encodingOptions["Export Event Id"]) {
      exportEventId = encodingOptions["Export Event Id"];
    }
    if (encodingOptions["Export Speakers"]) {
      exportSpeakers = encodingOptions["Export Speakers"];
    }
    if (encodingOptions["Export Tags"]) {
      exportTags = encodingOptions["Export Tags"];
    }
    if (encodingOptions["Export Notes"]) {
      exportNotes = encodingOptions["Export Notes"];
    }
    if (encodingOptions["Export Notes"]) {
      exportReply = encodingOptions["Export Reply"];
    }
    if (encodingOptions["Timecode Format"]) {
      tcFormat = encodingOptions["Timecode Format"];
    }
    if (encodingOptions["Export Start Time"]) {
      exportStartTime = encodingOptions["Export Start Time"];
    }
    if (encodingOptions["Export End Time"]) {
      exportEndTime = encodingOptions["Export End Time"];
    }
    if (encodingOptions["Export Duration"]) {
      exportDuration = encodingOptions["Export Duration"];
    }
    let formattedEvents = dialogueListFunc.formatEventsForExport(eventGroup, exportEventId, exportSpeakers, exportTags, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration, options.frameRate, options.dropFrame);
    let output = Papa.unparse(JSON.stringify(formattedEvents));
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};