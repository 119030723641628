import _nearestColorObj from "nearest-color";
const nearestColorObj = _nearestColorObj;
const colors = {
  "black": "#000000",
  "red": "#ff0000",
  "green": "#00ff00",
  "yellow": "#ffff00",
  "blue": "#0000ff",
  "magenta": "#ff00ff",
  "cyan": "#00ffff",
  "white": "#ffffff"
};
/* Takes plaintext with no formatting */
export default (function getNearestColor(color) {
  // console.log(color);
  let nearestColor = nearestColorObj.from(colors);
  //remove & from color
  color = color.replace(/&/g, '');
  return nearestColor(color);
});