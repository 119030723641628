<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
export let eventIndex;

const tagColorMapping = {
    Character: "info",
    Location: "light",
    Organization: "warning",
    Phrase: "success",
    Other: "secondary"
}

function removeTag(tag) {
    $eventGroupState[$projectState.selected].events[eventIndex].tags = $eventGroupState[$projectState.selected].events[eventIndex].tags.filter(tg => {
        return tg.id !== tag.id;
    });

    historyState.insert({
        name: "remove tag", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });
}
</script>

<div class="col-6 d-flex flex-row-reverse">
    <div class="tagWrapper">
        {#each $eventGroupState[$projectState.selected].events[eventIndex].tags as tag}
        <span class="badge bg-{tagColorMapping[tag.type]} {tagColorMapping[tag.type] === 'light' ? 'text-dark' : ''} m-1 text-custom-small" title="{tag.definition}">
            <a href="#!/" class="text-dark" on:click="{() => removeTag(tag)}"><i class="bi bi-x"></i></a>
            {tag.term}
        </span>
        {:else}
        <span class="text-custom-small text-muted">No Tags(s) Assigned</span>
        {/each}
    </div>
</div>

<style>
.tagWrapper {
    overflow-x: auto;
    white-space: nowrap;
}
</style>
