<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import tcRound from "@app/external/cc-lib/dist/functions/eventGroups/tcRound.js";

let frames = 2, minFrameGap = 0, maxFrameGap = 6, shotChangeAware = true, processing = false;

function insertBlankFrames() {
    try {
        processing = true;

        toast.push("Inserting blank frames...", {
            classes: ["toast-info"]
        });

        let frameGapSec = (frames/$projectState.frameRate);
        let minFrameGapSec = minFrameGap/$projectState.frameRate;
        let maxFrameGapSec = maxFrameGap/$projectState.frameRate;

        $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);
        $eventGroupState[$projectState.selected] = tcRound($eventGroupState[$projectState.selected], $projectState.frameRate, $projectState.dropFrame);

        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            if (index === events.length-1){
                return;
            }

            if (!event.end || event.end < 0){
                return;
            }

            let eventFrameGap = events[index+1].start - event.end;
            if (eventFrameGap <= maxFrameGapSec && eventFrameGap >= minFrameGapSec){
                //console.log("Event Frame Gap: ",eventFrameGap);
                if (shotChangeAware){
                    //console.log("Shot Change Aware is true");
                    let marker = $markerState.lists[0].markers.find(mark =>{
                        return parseFloat(mark.time).toFixed(5) === parseFloat(events[index].end).toFixed(5) || parseFloat(mark.time).toFixed(5) === parseFloat(events[index+1].start).toFixed(5);
                    });

                    //console.log("Marker Found:", marker);

                    if (marker){
                        if (parseFloat(marker.time).toFixed(5) === parseFloat(events[index+1].start).toFixed(5)){
                            if (events[index+1].start-frameGapSec <= event.start){
                                events[index+1].start = event.end + frameGapSec;
                            } else {
                                events[index].end = events[index+1].start-frameGapSec;
                            }
                        } else {
                            if (events[index].end+frameGapSec >= events[index+1].end){
                                events[index].end = events[index+1].start-frameGapSec;
                            } else {
                                events[index+1].start = event.end + frameGapSec;
                            }
                        }
                    } else {
                        //console.log("No marker found:");
                        if (events[index+1].start-frameGapSec <= event.start){
                            events[index+1].start = event.end + frameGapSec;
                        } else {
                            events[index].end = events[index+1].start-frameGapSec;
                        }
                    }
                } else {
                    if (events[index+1].start-frameGapSec <= event.start){
                        events[index+1].start = event.end + frameGapSec;
                    } else {
                        events[index].end = events[index+1].start-frameGapSec;
                    }
                }
            }
        });

        $eventGroupState[$projectState.selected] = $eventGroupState[$projectState.selected];

        historyState.insert({
            name: "insert frame gap", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Inserting blank frames finished successfully", {
            classes: ["toast-success"]
        });
    } catch (err) {
        console.error(err, err.message);
        toast.push("Inserting blank frames failed", {
            classes: ["toast-danger"]
        });
    } finally {
        modalState.hideModal();
    }
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'blankFrames' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="BlankFramesModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Insert Blank Frames</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{insertBlankFrames}">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label" for="framesInput">Frames to Insert</label>
                            <input class="form-control" type="number" min="0" step="1" placeholder="framesInput" bind:value="{frames}">
                        </div>
                        <div class="col my-3">
                            <label class="form-label" for="minFrameGap">Minimum Frame Gap</label>
                            <input class="form-control" type="number" min="0" step="1" placeholder="minFrameGap" bind:value="{minFrameGap}">
                        </div>
                        <div class="col my-3">
                            <label class="form-label" for="maxFrameGap">Maximum Frame Gap</label>
                            <input class="form-control" type="number" min="0" step="1" placeholder="maxFrameGap" bind:value="{maxFrameGap}">
                        </div>
                        <div class="mb-3 col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="shotChangeAwareChecked" bind:checked={shotChangeAware}>
                                <label class="form-check-label" for="shotChangeAwareChecked">
                                  Shot Change Aware <i class="bi bi-info-circle" title="Frame gap will be calculated using shot change markers. For example, if two Events start and end on the same shot change, the Event that starts on the shot change will not be altered. Only the Event ending on the shot change will have its outcode adjusted."></i>
                                </label>
                              </div>
                        </div>
                    </div>
                    <button class="btn btn-primary float-end" type="button" on:click="{insertBlankFrames}" disabled={processing}>Apply</button>
                </form>
            </div>
        </div>
    </div>
</div>
