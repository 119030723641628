import _docX from "docx";
import _dialogueListFunc from "../functions/profiles/dialogueList.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const dialogueListFunc = _dialogueListFunc;
const docX = _docX; //API/Creator/CLI
// import * as docX from "docx"; //CC-Converter-3
export default {
  decode: async function (input, options) {
    throw new Error("Closed Caption Converter does not support reading Word Dialogue Lists.");
  },
  encode: async function (eventGroup, options) {
    let output, exportTitlePage, exportTitle, exportMetadata, exportEventId, exportSpeakers, exportTags, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration;
    let encodingOptions = getFormatOptions(options.formatOptions);
    if (encodingOptions["Export Title Page"]) {
      exportTitlePage = encodingOptions["Export Title Page"];
    }
    if (encodingOptions["Title"]) {
      exportTitle = encodingOptions["Title"];
    }
    if (encodingOptions["Export Metadata"]) {
      exportMetadata = encodingOptions["Export Metadata"];
    }
    if (encodingOptions["Export Event Id"]) {
      exportEventId = encodingOptions["Export Event Id"];
    }
    if (encodingOptions["Export Speakers"]) {
      exportSpeakers = encodingOptions["Export Speakers"];
    }
    if (encodingOptions["Export Tags"]) {
      exportTags = encodingOptions["Export Tags"];
    }
    if (encodingOptions["Export Notes"]) {
      exportNotes = encodingOptions["Export Notes"];
    }
    if (encodingOptions["Export Reply"]) {
      exportReply = encodingOptions["Export Reply"];
    }
    if (encodingOptions["Timecode Format"]) {
      tcFormat = encodingOptions["Timecode Format"];
    }
    if (encodingOptions["Export Start Time"]) {
      exportStartTime = encodingOptions["Export Start Time"];
    }
    if (encodingOptions["Export End Time"]) {
      exportEndTime = encodingOptions["Export End Time"];
    }
    if (encodingOptions["Export Duration"]) {
      exportDuration = encodingOptions["Export Duration"];
    }
    let formattedEvents = dialogueListFunc.formatEventsForExport(eventGroup, exportEventId, exportSpeakers, false, exportTags, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration, options.frameRate, options.dropFrame);
    if (formattedEvents.length < 1) {
      throw new Error("No Events To Encode");
    }

    //console.log(JSON.stringify(formattedEvents, null, 2));

    let sections = [];
    if (exportTitlePage) {
      sections.push({
        children: [new docX.Paragraph({
          text: "As-Broadcast Dialogue Script",
          heading: docX.HeadingLevel.HEADING_4,
          alignment: docX.AlignmentType.CENTER
        }), new docX.Paragraph({
          text: exportTitle || "Untitled",
          heading: docX.HeadingLevel.TITLE,
          alignment: docX.AlignmentType.CENTER
        })]
      });
    }

    /* Build Metadata Section if Required */
    if (exportMetadata && eventGroup.metadata.length > 0) {
      let metadataTableRows = [];
      metadataTableRows.push(new docX.TableRow({
        children: [new docX.TableCell({
          children: [new docX.Paragraph("Metadata Property")],
          width: {
            size: 5000,
            type: docX.WidthType.DXA
          }
        }), new docX.TableCell({
          children: [new docX.Paragraph("Metadata Value")],
          width: {
            size: 5000,
            type: docX.WidthType.DXA
          }
        })],
        cantSplit: true,
        tableHeader: true
      }));
      eventGroup.metadata.forEach(m => {
        metadataTableRows.push(new docX.TableRow({
          children: [new docX.TableCell({
            children: [new docX.Paragraph(m.prop)]
          }), new docX.TableCell({
            children: [new docX.Paragraph(m.value)]
          })],
          cantSplit: true
        }));
      });
      let metadataTable = new docX.Table({
        rows: metadataTableRows
      });
      sections.push({
        children: [metadataTable]
      });
    }

    /* Start building the event table. */
    let eventTableRows = [];
    let headTableCells = [];
    /* Figure out what headers to add: */
    if (exportEventId === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Shot")]
      }));
    }
    if (exportStartTime === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Start")]
      }));
    }
    if (exportEndTime === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("End")]
      }));
    }
    if (exportDuration === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Duration")]
      }));
    }
    if (exportSpeakers === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Source")]
      }));
    }

    /* Dialogue required */
    headTableCells.push(new docX.TableCell({
      children: [new docX.Paragraph("Dialogue")]
    }));
    if (exportTags === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Tags")]
      }));
    }
    if (exportNotes === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Notes")]
      }));
    }
    if (exportReply === "yes") {
      headTableCells.push(new docX.TableCell({
        children: [new docX.Paragraph("Reply")]
      }));
    }
    eventTableRows.push(new docX.TableRow({
      children: headTableCells,
      cantSplit: true,
      tableHeader: true
    }));
    formattedEvents.forEach((ev, index) => {
      let eventTableCells = [];
      if (exportEventId === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev.Shot.toString().padStart(2, "0"))]
        }));
      }
      if (exportStartTime === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev.Start)]
        }));
      }
      if (exportEndTime === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev.End)]
        }));
      }
      if (exportDuration === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev.Duration)]
        }));
      }
      if (exportSpeakers === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev.Speaker)]
        }));
      }
      eventTableCells.push(new docX.TableCell({
        width: {
          size: 100,
          type: docX.WidthType.PERCENTAGE
        },
        children: ev.Script.split("\n").map(line => {
          return new docX.Paragraph(line);
        })
      }));
      if (exportTags === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev.Tags)]
        }));
      }
      if (exportNotes === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev["On Screen Note"])]
        }));
      }
      if (exportReply === "yes") {
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(ev["QC Note"])]
        }));
      }
      eventTableRows.push(new docX.TableRow({
        children: eventTableCells,
        cantSplit: true,
        tableHeader: false
      }));
    });
    let eventTable = new docX.Table({
      rows: eventTableRows
    });
    sections.push({
      children: [eventTable]
    });
    const doc = new docX.Document({
      creator: "Closed Caption Converter 3",
      description: "As-Broadcast Dialogue Script",
      title: exportTitle || "Untitled",
      sections: sections
    });
    if (typeof window === 'undefined') {
      output = await docX.Packer.toBuffer(doc);
    } else {
      output = await docX.Packer.toBlob(doc);
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};