<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    speakerState
} from "@app/store/speakerStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import {
    fade
} from "svelte/transition";
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
import quillClasses from '@app/external/cc-lib/dist/dict/quillClasses.js';

let speakerDefaults = JSON.parse(localStorage.getItem("cc-speaker-defaults")) || {};
let prefixChar = speakerDefaults.prefixChar || "",
    suffixChar = speakerDefaults.suffixChar || "",
    upperCaseAll = speakerDefaults.upperCaseAll,
    insertInline = speakerDefaults.insertInline,
    speakerName = "_USE_EVENT_SPEAKER_OPTION_",
    alignmentMap = {
        left: false,
        right: "right",
        center: "center"
    };

function insertSpeakerName() {
    if (!speakerName) {
        return;
    }

    let insertText = `${prefixChar}${upperCaseAll ? speakerName.toUpperCase() : speakerName}${suffixChar}`;

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
        if (speakerName === "_USE_EVENT_SPEAKER_OPTION_") {
            if (!$eventGroupState[$projectState.selected].events[eventIndex].speakers[0]) {
                return;
            } else {
                insertText = `${prefixChar}${upperCaseAll ? $eventGroupState[$projectState.selected].events[eventIndex].speakers[0].name.toUpperCase() : $eventGroupState[$projectState.selected].events[eventIndex].speakers[0].name}${suffixChar}`;
            }
        }

        if (insertInline){
            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(<p\b[^>]*>)/, '$1' + insertText);
        } else {
            $eventGroupState[$projectState.selected].events[eventIndex].text = convertToHtml(insertText, [quillClasses.align[$eventGroupState[$projectState.selected].events[eventIndex].alignment]]) + $eventGroupState[$projectState.selected].events[eventIndex].text;
        }
        

        if (count === 0 && quillEditor) {
            quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
            quillEditor.setSelection(0, quillEditor.getLength());
            quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
        }
    });

    historyState.insert({
        name: "insert speaker name", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    localStorage.setItem("cc-speaker-defaults", JSON.stringify({
        prefixChar: prefixChar,
        suffixChar: suffixChar,
        upperCaseAll: upperCaseAll,
        insertInline: insertInline
    }));

    modalState.hideModal();
}
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'insertSpeaker' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="InsertSpeakerModal"
    >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Insert Speaker Name</h4>
                <button
                    type="button"
                    class="btn-close" aria-label="Close"
                    on:click={modalState.hideModal}
                    ></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label class="form-label" for="SpeakerNameSelect">Speaker Name</label>
                        <select class="form-select" id="SpeakerNameSelect" bind:value={speakerName}>
                            <option value="_USE_EVENT_SPEAKER_OPTION_">*Assigned Speaker*</option>
                            {#each $speakerState as speaker (speaker.id)}
                            <option>{speaker.name}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label class="form-label" for="prefixCharacter">Prefix Character</label>
                            <input type="text" class="form-control" id="prefixCharacter" bind:value={prefixChar}>
                        </div>
                        <div class="col">
                            <label class="form-label" for="suffixCharacter">Suffix Character</label>
                            <input type="text" class="form-control" id="suffixCharacter" bind:value={suffixChar}>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col form-check">
                            <input type="checkbox" class="form-check-input" id="upperCaseAllName" bind:checked={upperCaseAll}>
                            <label class="form-check-label" for="upperCaseAllName">Uppercase All</label>
                        </div>
                        <div class="col form-check">
                            <input type="checkbox" class="form-check-input" id="insertInlineCheck" bind:checked={insertInline}>
                            <label class="form-check-label" for="insertInlineCheck">Insert Inline</label>
                        </div>
                    </div>
                    <button class="btn btn-outline-dark float-end ms-2" type="button"
                        on:click={() => modalState.showModal("speakerManager")}>Manage Speakers</button>
                    <button
                        class="btn btn-primary float-end"
                        type="button" disabled={!speakerName}
                        on:click={insertSpeakerName}>Insert Speaker</button
                        >
                        </form>
                        </div>
                        </div>
                        </div>
                        </div>
