import _docX from "docx";
import _dialogueListFunc from "../functions/profiles/dialogueList.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const dialogueListFunc = _dialogueListFunc;
const docX = _docX; //API/Creator/CLI
// import * as docX from "docx"; //CC-Converter-3
export default {
  decode: async function (input, options) {
    throw new Error("Closed Caption Converter does not support reading Word Dialogue Lists.");
  },
  encode: async function (eventGroup, options) {
    let output, exportSpeakers, exportRecipients, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration;
    let encodingOptions = getFormatOptions(options.formatOptions);
    if (encodingOptions["Export Speakers"]) {
      exportSpeakers = encodingOptions["Export Speakers"];
    }
    if (encodingOptions["Export Recipients"]) {
      exportRecipients = encodingOptions["Export Recipients"];
    }
    if (encodingOptions["Export Notes"]) {
      exportNotes = encodingOptions["Export Notes"];
    }
    if (encodingOptions["Export Reply"]) {
      exportReply = encodingOptions["Export Reply"];
    }
    if (encodingOptions["Timecode Format"]) {
      tcFormat = encodingOptions["Timecode Format"];
    }
    if (encodingOptions["Export Start Time"]) {
      exportStartTime = encodingOptions["Export Start Time"];
    }
    if (encodingOptions["Export End Time"]) {
      exportEndTime = encodingOptions["Export End Time"];
    }
    if (encodingOptions["Export Duration"]) {
      exportDuration = encodingOptions["Export Duration"];
    }
    let formattedEvents = dialogueListFunc.formatEventsForExport(eventGroup, false, exportSpeakers, exportRecipients, false, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration, options.frameRate, options.dropFrame);
    if (formattedEvents.length < 1) {
      throw new Error("No Events To Encode");
    }

    // console.log(JSON.stringify(formattedEvents, null, 2));

    let sections = [];

    /* Start building the event table. */
    let eventTableRows = [];
    formattedEvents.forEach((ev, index) => {
      let eventTableCells = [];
      if (exportStartTime === "yes" || exportEndTime === "yes" || exportDuration === "yes") {
        let tcChildren = [];
        if (exportStartTime === "yes") {
          tcChildren.push(new docX.Paragraph(ev.Start));
        }
        if (exportEndTime === "yes") {
          tcChildren.push(new docX.Paragraph(ev.End));
        }
        if (exportDuration === "yes") {
          tcChildren.push(new docX.Paragraph(`(${ev.Duration})`));
        }
        eventTableCells.push(new docX.TableCell({
          children: tcChildren
        }));
      }
      if (exportSpeakers === "yes" || exportRecipients === "yes") {
        let speakerParagraphText = "";
        if (exportSpeakers && exportRecipients) {
          if (ev.Speaker && ev.Recipient) {
            speakerParagraphText = `${ev.Speaker} TO: ${ev.Recipient}`;
          } else if (ev.Speaker) {
            speakerParagraphText = ev.Speaker;
          } else if (ev.Recipient) {
            speakerParagraphText = ev.Recipient;
          }
        } else if (exportSpeakers) {
          speakerParagraphText = ev.Speaker;
        } else if (exportRecipients) {
          speakerParagraphText = ev.Recipient;
        }
        eventTableCells.push(new docX.TableCell({
          children: [new docX.Paragraph(speakerParagraphText)]
        }));
      }
      let scriptParagraphs = ev.Script.split("\n").map(line => {
        return new docX.Paragraph(line);
      });
      if (exportNotes === "yes" && ev["On Screen Note"]) {
        scriptParagraphs.push(new docX.Paragraph({
          text: ev["On Screen Note"],
          spacing: {
            before: 200
          },
          indent: {
            left: 400
          }
        }));
      }
      if (exportReply === "yes" && ev["QC Note"]) {
        scriptParagraphs.push(new docX.Paragraph({
          text: ev["QC Note"],
          spacing: {
            before: 200
          },
          indent: {
            left: 400
          }
        }));
      }
      eventTableCells.push(new docX.TableCell({
        width: {
          size: 100,
          type: docX.WidthType.PERCENTAGE
        },
        children: scriptParagraphs
      }));
      eventTableRows.push(new docX.TableRow({
        children: eventTableCells,
        cantSplit: true,
        tableHeader: false
      }));
    });
    let eventTable = new docX.Table({
      rows: eventTableRows
    });
    sections.push({
      children: [eventTable]
    });
    const doc = new docX.Document({
      creator: "Closed Caption Converter 3",
      description: "Dialogue List Custom 01",
      title: "Dialogue List Custom 01",
      sections: sections
    });
    if (typeof window === 'undefined') {
      output = await docX.Packer.toBuffer(doc);
    } else {
      output = await docX.Packer.toBlob(doc);
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};