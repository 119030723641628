import _htmlEncodePlainText from "../functions/utility/htmlEncodePlainText.js";
import _flexbox from "../dict/flexbox.js";
import _autoFormatSimple from "../functions/utility/autoFormatSimple.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _sccFunc from "../functions/profiles/scenerist.js";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const ttmlFunc = _ttmlFunc;
const sccFunc = _sccFunc;
const XMLParser = _XMLParser;
const autoFormatSimple = _autoFormatSimple;
const flexbox = _flexbox;
const htmlEncodePlainText = _htmlEncodePlainText;
export default {
  decode: function (input, options) {
    let events = [],
      paragraphs = [],
      frameRate,
      frameRateMultiplier,
      regions,
      region,
      aspectRatio,
      fontSize,
      defaultBackgroundColor,
      defaultColor,
      rowVal,
      colVal;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    try {
      let fileJson = xmlToJson.parse(input);
      frameRate = fileJson.tt["@_ttp:frameRate"] || options.frameRate;
      frameRateMultiplier = fileJson.tt["@_ttp:frameRateMultiplier"];
      aspectRatio = fileJson.tt["@_ittp:aspectRatio"] || "4 3";
      try {
        fontSize = fileJson.tt.head.styling.style["@_tts:fontSize"];
        defaultBackgroundColor = fileJson.tt.head.styling.style["@_tts:backgroundColor"];
        defaultColor = fileJson.tt.head.styling.style["@_tts:color"];
      } catch (err) {
        fontSize = "80%";
        defaultBackgroundColor = "#000000";
        defaultColor = "#FFFFFF";
      }
      fontSize = fontSize ? parseInt(fontSize.replace("%", "")) : 80;
      rowVal = 1 / 15 * 100;
      colVal = 1 / 32 * 100;
      frameRate = parseFloat(frameRate);
      if (frameRateMultiplier === "1000 1001") {
        if (frameRate === 30) {
          frameRate = 29.97;
        } else if (frameRate === 24) {
          frameRate = 23.976;
        } else if (frameRate === 60) {
          frameRate = 59.94;
        }
      }

      /* Put the region data into an array */
      if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
        if (Array.isArray(fileJson.tt.head.layout.region)) {
          regions = fileJson.tt.head.layout.region;
        } else {
          regions = [fileJson.tt.head.layout.region];
        }
      }
      if (Array.isArray(fileJson.tt.body.div)) {
        fileJson.tt.body.div.forEach(divSection => {
          if (divSection.p.length > 0) {
            divSection.p.forEach(paragraph => {
              paragraphs.push(paragraph);
            });
          } else {
            paragraphs.push(divSection.p);
          }
        });
      } else {
        paragraphs = fileJson.tt.body.div.p;
      }

      /* Debug paragraph creation */
      //console.log(paragraphs);
      paragraphs.forEach(paragraph => {
        if (paragraph["#text"]) {
          if (paragraph["@_region"]) {
            region = regions.find(regionEl => {
              return regionEl["@_xml:id"] === paragraph["@_region"];
            });
          } else {
            region = false;
          }
          events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, frameRate, options.window));
        }
      });
      return events;
    } catch (e) {
      console.log(e);
      console.log("Failed to decode TTML file:", e.message);
      return e;
    }
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let tcFormat = encodingOptions["Timecode Format"] || "smpte";
    let lineHeight = encodingOptions["Line Height"] || 5.33;
    let output = `<?xml version="1.0" encoding="utf-8"?>`;
    output += `<tt xml:lang="${encodingOptions["Language Code"] || "en"}" xmlns="http://www.w3.org/2006/04/ttaf1" xmlns:tts="http://www.w3.org/2006/04/ttaf1#styling">`;
    output += `<head>`;
    output += `<styling>`;
    output += `<style id="defaultCaption" tts:fontStyle="normal"/>`;
    output += `</styling>`;
    output += `</head>`;
    output += `<body style="defaultCaption" id="thebody">`;
    output += `<div xml:lang="${encodingOptions["Language Code"] || "en"}">`;
    eventGroup.events.forEach(event => {
      let plainTextCustom = convertToPlainTextCustom(event.text);
      let plainText = convertToPlainText(event.text);
      let start = tcFormat === "smpte" ? tcLib.secToTcClock(event.start, options.frameRate) : tcLib.secToTcMs(event.start);
      let end = tcFormat === "smpte" ? tcLib.secToTcClock(event.end, options.frameRate) : tcLib.secToTcMs(event.end);
      let alignment = flexbox.alignmentNormalize[event.alignment];
      let region = "pop1";
      let style = "basic";
      let extents = ttmlFunc.multiLine.calcExtents(plainText, 80, 6);
      let origins = ttmlFunc.multiLine.calcOrigin(plainText, event.xPos, event.xOffset, event.yPos, event.yOffset, 80, options.window);
      let text = htmlEncodePlainText(plainTextCustom);
      let ccCol = origins.split(" ")[0].replace("%", '') / 100 * 32,
        ccRow = origins.split(" ")[1].replace("%", '') / 100 * 15;
      output += `<p region='${region}' style='${style}' begin='${start}' end='${end}' tts:origin='${origins}' tts:extent='${extents}' tts:textAlign='${alignment || 'center'}' tts:overflow="visible" tts:wrapOption="noWrap">`;
      output += `<metadata ccrow="${parseInt(ccRow)}" cccol="${parseInt(ccCol)}"/>`;
      output += ttmlFunc.multiLine.convertToTtml(text);
      output += `</p>`;
    });
    output += `</div>`;
    output += `</body>`;
    output += `</tt>`;

    /* Removed xmlFormat due to issues with apple devices - shouldn't impact anything else */
    //return xmlFormat(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup, options) {
      let encodingOptions = getFormatOptions(options.formatOptions);
      if (encodingOptions["608 Format"] && encodingOptions["608 Format"].toLowerCase() === "yes") {
        eventGroup.events.forEach((event, index, events) => {
          if (!sccFunc.verifyFormatting(event, options.window)) {
            events[index].text = autoFormatSimple(event.text);
          }
        });
      }
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};