import _convertToHtml from "../functions/quill/convertToHtml.js";
import _htmlEncodePlainText from "../functions/utility/htmlEncodePlainText.js";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _xmlFormat from "xml-formatter";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _Event2 from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const _Event = _Event2;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const xmlFormat = _xmlFormat; //Encode
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
const htmlEncodePlainText = _htmlEncodePlainText;
const convertToHtml = _convertToHtml;
export default {
  decode: function (input, options) {
    let events = [],
      yPos,
      start,
      end;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(options);
    // console.log(JSON.stringify(fileJson, null, 4));

    fileJson.tt.body.div.p.forEach(paragraph => {
      start = tcLib.tcMsToSec(paragraph["@_begin"].replace(".", ","));
      end = tcLib.tcMsToSec(paragraph["@_end"].replace(".", ","));
      if (paragraph["@_region"] === "top") {
        yPos = "start";
      } else {
        yPos = "end";
      }
      events.push(new _Event({
        start: start,
        end: end,
        text: convertToHtml(ttmlFunc.multiLine.formatText(paragraph["#text"].replace(/<br \/>|<br\/>|<br>/gim, "\n"))),
        yPos: yPos
      }));
    });

    // console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let output = `<?xml version="1.0" encoding="utf-8"?>
<tt xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:itts="http://www.w3.org/ns/ttml/profile/imsc1#styling" xmlns:ittp="http://www.w3.org/ns/ttml/profile/imsc1#parameter" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ittm="http://www.w3.org/ns/ttml/profile/imsc1#metadata" xmlns:ebutts="urn:ebu:tt:style" xml:lang="${encodingOptions["Language Code"] || "en"}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:timeBase="${encodingOptions["Timebase"] || 'media'}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" xmlns="http://www.w3.org/ns/ttml">`;
    output += `<head>
    <metadata>
      <ttm:title>${encodingOptions["Title"] ?? ""}</ttm:title>
      <ttm:copyright>${encodingOptions["Copyright"] ?? ""}</ttm:copyright>
      <ttm:desc>${encodingOptions["Description"] ?? "TTML IMSC 1"}</ttm:desc>
    </metadata>
    <styling>
      <style xml:id="font1.center" tts:backgroundColor="transparent" tts:color="white" tts:fontFamily="Arial" tts:fontSize="80%" tts:textAlign="center" />
    </styling>
    <layout>
      <region xml:id="bottom" tts:displayAlign="after" tts:origin="10% 10%" tts:extent="80% 80%" />
      <region xml:id="top" tts:displayAlign="before" tts:origin="10% 10%" tts:extent="80% 80%" />
    </layout>
  </head>`;
    output += `<body>
    <div>`;

    /* EVENTS */
    eventGroup.events.forEach((event, index, events) => {
      //create start and end time in format hh:mm:ss.mm
      let start = tcLib.secToTcMs(event.start).replace(",", ".").slice(0, -1);
      let end = tcLib.secToTcMs(event.end).replace(",", ".").slice(0, -1);
      let region = event.yPos === "start" ? "top" : "bottom"; //default region is "bottom

      //console.log(event.text);
      let plainTextCustom = convertToPlainTextCustom(event.text);
      let text = ttmlFunc.multiLine.convertToTtml(htmlEncodePlainText(plainTextCustom));
      // console.log(text);
      output += eol.after(`<p begin="${start}" end="${end}" region="${region}" style="font1.center">${text}</p>`);
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += `</tt>`;
    return xmlFormat(eol.auto(output));
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};