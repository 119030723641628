import _getLineCount from "../functions/quill/getLineCount.js";
import _vttFunc from "../functions/profiles/webVtt.js";
import _flexbox from "../dict/flexbox.js";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const flexbox = _flexbox;
const vttFunc = _vttFunc;
const getLineCount = _getLineCount;
export default {
  decode: function (input, options) {
    let events = [];
    let subtitleBlocks = input.split("\n\n");
    subtitleBlocks.forEach(subtitleBlock => {
      let tcFlag = false,
        ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.1
        });
      let blockLines = subtitleBlock.split("\n").filter(blockLine => {
        return blockLine.trim();
      });
      if (blockLines.length > 1) {
        blockLines.forEach(blockLine => {
          if (!tcFlag && blockLine.split(" --> ").length > 1) {
            tcFlag = true;
            let pattern = /(\d{2}:\d{2}),(\d{3})/g;
            blockLine = blockLine.replace(pattern, "$1.$2");
            let lineInfo = blockLine.split(/ |,/);
            let tcIn = "00:" + lineInfo[0];
            let tcOut = "00:" + lineInfo[2];
            ccEvent.start = tcLib.tcMsToSec(tcIn.substring(tcIn.length - 12, tcIn.length));
            ccEvent.end = tcLib.tcMsToSec(tcOut.substring(tcOut.length - 12, tcOut.length));

            /* Check to see if the last event has the same timecode as the current event. If so... copy the text from the last event to the current event and remove it from the events list */
            if (events.length > 0 && (ccEvent.start === events[events.length - 1].start || ccEvent.end === events[events.length - 1].end)) {
              ccEvent.text = events[events.length - 1].text;
              events.pop();
            }

            /* Check for extra metadata */
            if (lineInfo.length > 3) {
              lineInfo.forEach(info => {
                if (/align:/g.test(info)) {
                  let alignmentMetadata = info.split(":")[1].replace(",", "");
                  ccEvent.alignment = flexbox.alignmentNormalize[alignmentMetadata] || "center";
                  ccEvent.xPos = flexbox.positionNormalize[alignmentMetadata] || "center";
                } else if (/position:/g.test(info)) {
                  let positionMetadata = info.split(":")[1].split(",")[0].replace(/%|,/gim, "");
                  if (!isNaN(parseInt(positionMetadata))) {
                    let pos = parseInt(positionMetadata);
                    if (pos < 35) {
                      ccEvent.xPos = "start";
                    } else if (pos < 65) {
                      ccEvent.xPos = "center";
                    } else {
                      ccEvent.xPos = "end";
                    }
                  }
                } else if (/line:/g.test(info)) {
                  let vPosition = info.split(":")[1].replace(/,/gim, "");
                  //Check if vPosition has a % sign
                  if (vPosition >= -16 && vPosition <= 16) {
                    vPosition = parseInt(vPosition);
                    //Set yPos and yOffset based on vPosition. When vPosition is positive it's an offset from the top of the screen (start), when negative it's an offset from the bottom of the screen (end). When vPosition is 0 it's center.
                    if (vPosition >= 0) {
                      if (vPosition >= 0 && vPosition <= 5) {
                        ccEvent.yPos = "start";
                        ccEvent.yOffset = options.window.height * (vPosition / 16);
                      } else if (vPosition > 5 && vPosition <= 9) {
                        ccEvent.yPos = "center";
                        //calculate the position offset from the center of the screen
                        ccEvent.yOffset = options.window.height * ((vPosition - 8) / 16);
                      } else {
                        ccEvent.yPos = "end";
                        //calculate the position offset from the bottom of the screen
                        ccEvent.yOffset = options.window.height * ((vPosition - 16) / 16);
                      }
                    } else {
                      if (vPosition >= -6) {
                        ccEvent.yPos = "end";
                        //calculate the position offset from the bottom of the screen
                        ccEvent.yOffset = options.window.height * ((vPosition - 1) / 16);
                      } else if (vPosition > -10) {
                        ccEvent.yPos = "center";
                        //calculate the position offset from the center of the screen
                        ccEvent.yOffset = options.window.height * ((vPosition + 8) / 16);
                      } else {
                        ccEvent.yPos = "start";
                        //calculate the position offset from the top of the screen
                        ccEvent.yOffset = options.window.height * ((vPosition + 16) / 16);
                      }
                    }
                    if (ccEvent.yOffset === 0 && ccEvent.yPos === "start") {
                      ccEvent.yOffset = options.window.height * 0.1;
                    } else if (ccEvent.yOffset === 0 && ccEvent.yPos === "end") {
                      ccEvent.yOffset = options.window.height * -0.1;
                    }
                  } else {
                    vPosition = vPosition.replace(/%/gim, "");
                    if (!isNaN(vPosition) && vPosition < 40) {
                      ccEvent.yPos = "start";
                      ccEvent.yOffset = options.window.height * (vPosition / 100);
                    } else if (!isNaN(vPosition) && vPosition < 60) {
                      ccEvent.yPos = "center";
                    } else {
                      ccEvent.yPos = "end";
                      ccEvent.yOffset = parseInt(vPosition);
                    }
                  }
                }
              });
            }
          } else if (tcFlag) {
            ccEvent.text += blockLine + "\n";
          }
        });
      }
      if (tcFlag) {
        //console.log("----------------");
        //console.log("Before:" + ccEvent.text);
        ccEvent.text = vttFunc.replaceColorTags(ccEvent.text).trim().replace(/<(?!\/?(em|i|strong|u|b|white|yellow|green|black|red|blue|cyan|magenta)\b)[^>]+>/gi, "");
        ccEvent.text = convertToHtml(ccEvent.text.trim());
        //console.log("After:" + ccEvent.text);
        // console.log(ccEvent);
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions),
      fontStyleCues = false,
      overrideFontColor = false,
      overrideBackgroundColor = false,
      htmlTags = false,
      encodeId = false,
      fontColor = "white",
      backgroundColor = "black",
      maxLength = 32;
    if (encodingOptions["Encode Id"] && encodingOptions["Encode Id"] !== "false") {
      encodeId = encodingOptions["Encode Id"];
    }
    if (encodingOptions["Use HTML Tags"] && encodingOptions["Use HTML Tags"] !== "false") {
      htmlTags = encodingOptions["Use HTML Tags"];
    }
    if (encodingOptions["Override Font Color"] && encodingOptions["Override Font Color"] !== "false") {
      overrideFontColor = encodingOptions["Override Font Color"];
    }
    if (encodingOptions["Override Background Color"] && encodingOptions["Override Background Color"] !== "false") {
      overrideBackgroundColor = encodingOptions["Override Background Color"];
    }
    if (encodingOptions["Font Color"]) {
      fontColor = encodingOptions["Font Color"];
    }
    if (encodingOptions["Background Color"]) {
      backgroundColor = encodingOptions["Background Color"];
    }
    if (encodingOptions["Max Characters Per Line"]) {
      maxLength = encodingOptions["Max Characters Per Line"] || 32;
    }
    let output = "WEBVTT\n\n";
    if (encodingOptions["Encode Font Styles"] && encodingOptions["Encode Font Styles"] !== "false") {
      fontStyleCues = true;
      output += vttFunc.fontStyleCues + "\n\n";
    }
    if (encodingOptions["Encode Metadata Notes"] && encodingOptions["Metadata Notes"]) {
      output += "NOTE\n";
      output += encodingOptions["Metadata Notes"] + "\n\n";
    }
    eventGroup.events.forEach(function (event, eventIndex) {
      let eventText;
      let plainText = convertToPlainText(event.text);
      let numberOfLines = eol.split(plainText).length;
      if (encodeId) {
        output += `${eventIndex + 1}` + "\n";
      }
      output += tcLib.secToTcMs(event.start).replace(",", ".") + " --> " + tcLib.secToTcMs(event.end).replace(",", ".");
      if (encodingOptions["Encode Position"] && encodingOptions["Encode Position"] != "false") {
        let line = vttFunc.calcLineValue(event, numberOfLines, options.window);
        let size = vttFunc.calcSize(event, maxLength);
        let position = vttFunc.calcPos(event, options.window, size);
        let alignment = "center";
        if (event.alignment === "left") {
          alignment = "right";
        } else if (event.alignment === "right") {
          alignment = "left";
        }

        /* WebCargo Code Start */
        // encodingOptions["Position Template"] = encodingOptions["Position Template"] || "Custom01";
        /* WebCargo Code End */
        switch (encodingOptions["Position Template"]) {
          case "YouTube":
            /* eg. line:50% align:middle size:35% | line = y position, align = x position (left, middle, right)*/
            output += ` line:${line.toFixed(2)}% align:${alignment} size:${size}%`;
            break;
          case "MUFI":
            /* eg. line:78% position:50% align:middle*/
            output += ` line:${line.toFixed(2)}% position:50% align:${alignment === "center" ? "middle" : alignment}`;
            break;
          case "Standard":
            /* Align - font alignment, Position - Horizontal offset, Line - Vertical Offset */
            output += ` align:${event.alignment} position:${position}% line:${line}%`;
            break;
          case "Custom01":
            line = vttFunc.webcargoLineValue(event, numberOfLines, options.window);
            output += " align:" + event.alignment + " line:" + line + "%";
            break;
          case "Custom02":
            //Discovery:
            output += ` align:${event.alignment} line:${line.toFixed(2)}%`;
            break;
          case "Custom03":
            //Line without %:
            output += ` align:${event.alignment} line:${event.yPos === "start" ? 0 : 0 - numberOfLines}`;
            break;
          case "Custom04":
            //Line without %:
            output += ` line:${event.yPos === "start" ? 0 : 0 - numberOfLines}`;
            break;
          default:
            //Discovery:
            //output += ` align:${event.alignment} line:${line.toFixed(2)}%`;
            //Real Default:
            output += ` align:${event.alignment} position:${position.toFixed(2)}% line:${line.toFixed(2)}% size:${size.toFixed(2)}%`;
        }
      }
      if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] !== "false") {
        eventText = convertToPlainTextCustom(event.text, "\n", false, "b", "i", "u");
      } else {
        eventText = convertToPlainTextCustom(event.text, "\n", true);
      }
      eventText.split("\n").filter(lineText => {
        return lineText;
      }).forEach(line => {
        if (fontStyleCues && (overrideFontColor || overrideBackgroundColor)) {
          output += htmlTags ? "\n<font" : "\n<c";
          if (overrideFontColor) {
            output += htmlTags ? ` color="${fontColor}"` : "." + fontColor;
          }
          if (overrideBackgroundColor) {
            output += htmlTags ? ` background-color="${backgroundColor}"` : ".bg_" + backgroundColor;
          }
          output += ">" + line + (htmlTags ? "</font>" : "</c>");
        } else if (fontStyleCues && event.color !== "#FFFFFF" && vttFunc.colorMapping[event.color]) {
          output += "\n<c." + vttFunc.colorMapping[event.color] + ">";
          output += ">" + line + "</c>";
        } else {
          output += "\n" + line;
        }
      });
      output += "\n\n";
    });
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      /* All */
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input).trim().replace(/'(\n){3,}'/gim, "\n\n");
    }
  },
  postProcess: {
    encode: function (output) {
      return output.replace(new RegExp("(\n){3,}", "gim"), "\n\n");
    },
    decode: function (eventGroup, options) {
      eventGroup.events.forEach((event, index, events) => {
        if (event.yOffset > 0 && event.yPos === "end") {
          let numberOfLines = getLineCount(event.text);
          events[index].yOffset = options.window.height * -((100 - (event.yOffset + 5.33 * numberOfLines)) / 100);
        }
      });
      return eventGroup;
    }
  }
};