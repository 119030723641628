<script>
import {environment} from '@app/store/envStore.js';
import {authState} from '@app/store/authStore.js';
import {
    uiState
} from '@app/store/uiStore.js';
import { toast } from '@zerodevx/svelte-toast';

let search = $uiState.quickTools.search,
    spellCheck = $uiState.quickTools.spellCheck,
    timing = $uiState.quickTools.timing,
    qc = $uiState.quickTools.qc,
    templates = $uiState.quickTools.templates,
    voices = $uiState.quickTools.voices,
    speakers = $uiState.quickTools.speakers,
    tags = $uiState.quickTools.tags,
    markers = $uiState.quickTools.markers,
    videoFilters = $uiState.quickTools.videoFilters,
    notes = $uiState.quickTools.notes;

function updateUiSettings(){
    $uiState.quickTools = {
        search : search,
        spellCheck : spellCheck,
        timing : timing,
        qc : qc,
        templates : templates,
        voices : voices,
        speakers : speakers,
        tags : tags,
        notes : notes,
        markers : markers,
        videoFilters : videoFilters
    }

    localStorage.setItem("cc-ui-settings", JSON.stringify($uiState));

    toast.push("Settings have been saved successfully.", {classes: ['toast-success']});
}

</script>

<h5 >QuickTools Settings</h5>
<p class="small text-muted">Customize the visible tools found in the QuickTools drawer located below the media player.</p>
<form on:submit|preventDefault="{updateUiSettings}">
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="searchCheck" bind:checked={search}>
        <label class="form-check-label" for="searchCheck">Search & Replace</label>
    </div>
    {#if $environment.online}
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="spellCheckCheck" bind:checked={spellCheck}>
        <label class="form-check-label" for="spellCheckCheck">Spell Check</label>
    </div>
    {/if}
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="timingCheck" bind:checked={timing}>
        <label class="form-check-label" for="timingCheck">Timing & Sync</label>
    </div>
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="qcCheck" bind:checked={qc}>
        <label class="form-check-label" for="qcCheck">QC & Review</label>
    </div>
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="templatesCheck" bind:checked={templates}>
        <label class="form-check-label" for="templatesCheck">Event Templates</label>
    </div>
    {#if $authState.ad && $environment.online}
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="voicesCheck" bind:checked={voices}>
        <label class="form-check-label" for="voicesCheck">Voices</label>
    </div>
    {/if}
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="speakersCheck" bind:checked={speakers}>
        <label class="form-check-label" for="speakersCheck">Speakers</label>
    </div>  
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="tagsCheck" bind:checked={tags}>
        <label class="form-check-label" for="tagsCheck">Tags</label>
    </div>   
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="markersCheck" bind:checked={markers}>
        <label class="form-check-label" for="markersCheck">Markers</label>
    </div>   
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="videoFiltersCheck" bind:checked={videoFilters}>
        <label class="form-check-label" for="videoFiltersCheck">Video Filters</label>
    </div> 
    <div class="mb-3 form-check form-switch">
        <input type="checkbox" class="form-check-input" id="notesCheck" bind:checked={notes}>
        <label class="form-check-label" for="notesCheck">Notes</label>
    </div>  
    <button class="btn btn-primary" type="button" on:click={updateUiSettings}>Save Changes</button>
</form>
