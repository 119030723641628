import _hexToBinary from "hex-to-binary";
import _scenerist from "./scenerist.js";
import _sccFunc from "../functions/profiles/scenerist.js";
import _sccLookup from "../dict/608.js";
import _mccLookup from "../dict/708.js";
import _mccFunc from "../functions/profiles/macCaption.js";
import _autoFormatSimple from "../functions/utility/autoFormatSimple.js";
import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const eol = _eol;
const autoFormatSimple = _autoFormatSimple;
const mccFunc = _mccFunc;
const mccLookup = _mccLookup;
const sccLookup = _sccLookup;
const sccFunc = _sccFunc;
const scenerist = _scenerist;
const hexToBinary = _hexToBinary;
export default {
  decode: function (input, options) {
    let events = [],
      buffer = [],
      //Where we store windows before decoding them at the end
      fileLines,
      frameRate,
      ccCmds,
      ancData,
      tc,
      decodingOptions = getFormatOptions(options.formatOptions),
      channel,
      cdp,
      sccFile = "Scenarist_SCC V1.0",
      prevCmdWasDisplayBuffer = false,
      skipByte = false,
      bytesSkipped,
      bytesToSkip,
      useExternal,
      decodedCmd,
      params,
      currentWindow = false,
      windows = [{
        id: 0,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 1,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 2,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 3,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 4,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 5,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 6,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 7,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }];
    frameRate = mccFunc.getFrameRateFromFile(input);
    //console.log(frameRate);
    channel = decodingOptions["Channel"] || "ch01";
    fileLines = eol.split(input).filter(fileLine => {
      return /^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g.test(fileLine.split("\t")[0]);
    });
    fileLines.forEach(fileLine => {
      tc = fileLine.split("\t")[0];
      ancData = mccFunc.replaceShortForms(fileLine.split("\t")[1]);
      cdp = mccFunc.decodeAncData(ancData.split(""));
      if (["ch01", "ch02", "ch03", "ch04"].indexOf(channel) > -1) {
        cdp.ccData.ccPackets.forEach(ccPacket => {
          if ((channel === "ch01" || channel === "ch02") && ccPacket.substring(0, 2) === "FC" && ccPacket.substring(2) !== "8080") {
            if (sccLookup[channel][ccPacket.substring(2).toLowerCase()] === "{DISPLAY BUFFER}") {
              if (!prevCmdWasDisplayBuffer) {
                sccFile += "\n\n" + tc + "\t" + ccPacket.substring(2).toLowerCase();
                prevCmdWasDisplayBuffer = true;
              }
            } else if (sccLookup[channel][ccPacket.substring(2).toLowerCase()] !== undefined || sccLookup[channel][ccPacket.substring(2, 4).toLowerCase()] !== undefined && sccLookup[channel][ccPacket.substring(4).toLowerCase()] !== undefined) {
              sccFile += "\n\n" + tc + "\t" + ccPacket.substring(2).toLowerCase();
              prevCmdWasDisplayBuffer = false;
            }
          } else if ((channel === "ch03" || channel === "ch04") && ccPacket.substring(0, 2) === "FD" && ccPacket.substring(2) !== "8080") {
            if (sccLookup[channel][ccPacket.substring(2).toLowerCase()] === "{DISPLAY BUFFER}") {
              if (!prevCmdWasDisplayBuffer) {
                sccFile += "\n\n" + tc + "\t" + ccPacket.substring(2).toLowerCase();
                prevCmdWasDisplayBuffer = true;
              }
            } else if (sccLookup[channel][ccPacket.substring(2).toLowerCase()] !== undefined || sccLookup[channel][ccPacket.substring(2, 4).toLowerCase()] !== undefined && sccLookup[channel][ccPacket.substring(4).toLowerCase()] !== undefined) {
              sccFile += "\n\n" + tc + "\t" + ccPacket.substring(2).toLowerCase();
              prevCmdWasDisplayBuffer = false;
            }
          }
        });
      } else {
        /* Standard defaults to 708 */
        let currentCmdBuffer = [];
        cdp.ccData.ccPackets.forEach(ccPacket => {
          if (ccPacket.substring(0, 2) === "FE") {
            /* Both Bytes are good */
            ccCmds = ccPacket.match(/.{1,2}/g);
            ccCmds.shift(); // remove FE
          } else if (ccPacket.substring(0, 2) === "FF") {
            /* First Byte is header and second byte is good */
            // ccCmds = ccPacket.match(/.{1,2}/g);
            // console.log(tc + "\t" + ccCmds);
            // ccCmds.shift(); // remove FF
            // ccCmds.shift(); // remove unwanted byte
          }
          if (ccCmds && ccCmds.length > 0) {
            currentCmdBuffer = currentCmdBuffer.concat(ccCmds);
          }
          ccCmds = [];
        });
        if (currentCmdBuffer.length > 0) {
          //console.log(tc + "\t" + currentCmdBuffer);
        }
        while (currentCmdBuffer.length > 0) {
          let cmd = currentCmdBuffer.shift();
          if (skipByte) {
            bytesSkipped++;
            if (bytesSkipped === bytesToSkip) {
              skipByte = false;
              bytesToSkip = 0;
            }
          } else {
            if (useExternal) {
              decodedCmd = mccLookup.cmds.EXT[cmd.toLowerCase()];
              useExternal = false;
            } else {
              decodedCmd = mccLookup.cmds[cmd.toLowerCase()];
            }

            //console.log(tc.toString() +" "+ decodedCmd + "(" + cmd.toLowerCase() + ")");
            if (/{SKIP-\d}/.test(decodedCmd)) {
              //console.log("Skipping bits: " + decodedCmd + "(" + cmd.toLowerCase() + ")");
              skipByte = true;
              bytesToSkip = parseInt(decodedCmd.match(/\d+/)[0]);
            } else if (/{P16}/.test(decodedCmd)) {
              /* WIP */
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              params = currentCmdBuffer.splice(0, 2);
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text += "'";
            } else if (/{CW-\d}/.test(decodedCmd)) {
              currentWindow = parseInt(decodedCmd.match(/\d+/)[0]);
              //console.log("Selecting window " + currentWindow + " at " + tc);
            } else if (/{DF-\d}/.test(decodedCmd)) {
              currentWindow = parseInt(decodedCmd.match(/\d+/)[0]);
              //console.log("Defining window " + currentWindow + " at " + tc);

              params = currentCmdBuffer.splice(0, 6);
              //console.log("Params: " + params);

              windows[currentWindow].params = params.join(", ");
              windows[currentWindow].visible = parseInt(hexToBinary(params[0])[2]) ? true : false;
              windows[currentWindow].rowLock = hexToBinary(params[0])[3];
              windows[currentWindow].columnLock = hexToBinary(params[0])[4];
              windows[currentWindow].priority = hexToBinary(params[0]).substring(5);
              windows[currentWindow].relativePos = hexToBinary(params[1])[0] ? false : true;
              windows[currentWindow].anchorVert = hexToBinary(params[1]).substring(1);
              windows[currentWindow].anchorHorz = hexToBinary(params[2]);
              windows[currentWindow].anchorPoint = hexToBinary(params[3]).substring(0, 4);
              windows[currentWindow].rowCount = hexToBinary(params[3]).substring(4);
              windows[currentWindow].columnCount = hexToBinary(params[4]).substring(2);
              windows[currentWindow].styleId = hexToBinary(params[5]).substring(2, 5);
              windows[currentWindow].penStyleId = hexToBinary(params[5]).substring(5);
              windows[currentWindow].defined = true;

              //console.log("Row Lock: " + windows[currentWindow].rowLock);
              //console.log("Column Lock: " + windows[currentWindow].columnLock);
              //console.log("Priority: " + parseInt(windows[currentWindow].priority, 2));
              //console.log("Relative Pos: " + windows[currentWindow].relativePos);
              //console.log("Anchor Vert: " + parseInt(windows[currentWindow].anchorVert, 2));
              //console.log("Anchor Horz: " + parseInt(windows[currentWindow].anchorHorz, 2));
              //console.log("Anchor Point: " + parseInt(windows[currentWindow].anchorPoint, 2));
              //console.log("Row Count: " + parseInt(windows[currentWindow].rowCount, 2));
              //console.log("Column Count: " + parseInt(windows[currentWindow].columnCount, 2));
              //console.log("Style Id: " + parseInt(windows[currentWindow].styleId, 2));
              //console.log("Pen Style Id: " + parseInt(windows[currentWindow].penStyleId, 2));
              if (windows[currentWindow].visible) {
                windows[currentWindow].start = tcLib.tcToSec(tc, frameRate.frames);
              }
              if (windows[currentWindow].relativePos) {
                windows[currentWindow].verticalOffset = parseInt(windows[currentWindow].anchorVert, 2);
              } else {
                windows[currentWindow].verticalOffset = parseInt(windows[currentWindow].anchorVert, 2) / 75 * 100;
              }
              if (windows[currentWindow].relativePos) {
                windows[currentWindow].horizontalOffset = parseInt(windows[currentWindow].anchorHorz, 2);
              } else {
                windows[currentWindow].horizontalOffset = parseInt(windows[currentWindow].anchorHorz, 2) / 209 * 100;
              }
              windows[currentWindow].style = mccFunc.windowStyleMapping[parseInt(windows[currentWindow].styleId, 2)] || "Pop-On";
            } else if (decodedCmd === "{NUL}") {
              /* SKIP FOR NOW */
            } else if (decodedCmd === "{ETX}") {
              //console.log("ETX");
              /* End of Text */
              /* No more Commands to follow */
              /* This requirement aids decoders in processing
              text sequences when text spans multiple service blocks. */
            } else if (decodedCmd === "{BS}") {
              /* Remove last line */
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text = "";
            } else if (decodedCmd === "{FF}") {
              /* Erases all text */
              /* Equivalent to Clear window and set pen location to 0,0 */
              windows[currentWindow].lines = [{
                text: "",
                posX: 0,
                // Percent %
                posY: 0,
                // Percent %
                bold: false,
                italics: false,
                underline: false
              }];
            } else if (decodedCmd === "{CR}") {
              /* Create new caption line */
              windows[currentWindow].lines.push({
                text: "",
                posX: 0,
                // Percent %
                posY: 0,
                // Percent %
                bold: false,
                italics: false,
                underline: false
              });
            } else if (decodedCmd === "{HCR}") {
              /* Erase row of text and move to start of line */
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text = "";
            } else if (decodedCmd === "{EXT}") {
              useExternal = true;
            } else if (decodedCmd === "{CLW}") {
              /* Clear Current Window */
              params = currentCmdBuffer.splice(0, 1);
              /* Clears just the text = from the caption event. 
              Read binary right to left (00010011 - Clear 0, 1 and window 4) */
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  /* Clear Window */
                  //console.log("Clearing window " + index + " at " + tc);
                  //console.log(windows[index]);
                  if (windows[index].visible && windows[index].end === null) {
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                  }
                  windows[index].lines = [];
                  windows[index].end = null;
                }
              });
            } else if (decodedCmd === "{DSW}") {
              /* Display windows */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  //console.log("Displaying window "+index);
                  //console.log("Displaying window " + index + " at " + tc);
                  windows[index].start = tcLib.tcToSec(tc, frameRate.frames);
                  windows[index].visible = true;
                }
              });
            } else if (decodedCmd === "{HDW}") {
              /* Hide current window */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  if (windows[index].visible) {
                    //console.log("Hiding window " + index + " at " + tc);
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                    windows[index].visible = false;
                    windows[index].end = null;
                  }
                }
              });
            } else if (decodedCmd === "{TGW}") {
              /* Toggle Windows */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  //console.log("Toggle display of window "+index);
                  if (windows[index].visible && windows[index].defined) {
                    //console.log("Toggling off window " + index + " at " + tc);
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                  } else if (windows[index].defined && windows[index].lines.length > 0) {
                    //console.log("Toggling on window " + index + " at " + tc);
                    windows[index].start = tcLib.tcToSec(tc, frameRate.frames);
                  }
                  windows[index].visible = !windows[index].visible;
                }
              });
            } else if (decodedCmd === "{DLW}") {
              /* Delete windows */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1" && windows[index].defined) {
                  //console.log("Deleting Window " + index + " at " + tc);
                  if (windows[index].visible) {
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                  }
                  windows[index] = {
                    id: index,
                    params: null,
                    defined: false,
                    priority: null,
                    anchorPoint: null,
                    relativePos: null,
                    verticalOffset: null,
                    horizontalOffset: null,
                    anchorVert: null,
                    anchorHorz: null,
                    rowCount: null,
                    columnCount: null,
                    columnLock: null,
                    rowLock: null,
                    visible: false,
                    styleId: 1,
                    style: "Pop-On",
                    penStyleId: 1,
                    spa: false,
                    spc: false,
                    spl: false,
                    lines: [],
                    start: null,
                    end: null
                  };
                }
              });
            } else if (decodedCmd === "{DLY}") {
              /* Delay in 1/10 seconds */
              params = currentCmdBuffer.splice(0, 1);
            } else if (decodedCmd === "{DLC}") {/* Delay Cancel */
            } else if (decodedCmd === "{RST}") {
              /* Reset Channel Service */
              /* Clears all windows and everything */
              windows = [{
                id: 0,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 1,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 2,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 3,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 4,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 5,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 6,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 7,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }];
            } else if (decodedCmd === "{SPA}") {
              /* Set Pen Attributes */
              //console.log("Setting Pen Attributes at " + tc);
              params = currentCmdBuffer.splice(0, 2);
              //console.log(params);
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].italics = parseInt(hexToBinary(params[1])[0]) ? true : false;
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].underline = parseInt(hexToBinary(params[1])[1]) ? true : false;
              windows[currentWindow].spa = true;
            } else if (decodedCmd === "{SPC}") {
              /* Set Pen Color */
              //console.log("Setting Pen Color of Window " + currentWindow + " at " + tc);
              params = currentCmdBuffer.splice(0, 3);
              //console.log(params);
              windows[currentWindow].spc = true;
            } else if (decodedCmd === "{SPL}") {
              /* Set Pen Location */
              params = currentCmdBuffer.splice(0, 2);
              //console.log("Setting Pen Location of Window " + currentWindow + " at " + tc);
              //console.log(params);
              let rowValue = parseInt("0x" + params[0]);
              let columnValue = parseInt("0x" + params[1]);
              windows[currentWindow].spl = true;
              windows[currentWindow].lines.push({
                text: "",
                posX: columnValue / 31 * 100 + windows[currentWindow].horizontalOffset,
                // Percent %
                posY: rowValue / 14 * 100 + windows[currentWindow].verticalOffset,
                // Percent %
                bold: false,
                italics: false,
                underline: false
              });

              //console.log(windows[currentWindow].lines)
            } else if (decodedCmd === "{SWA}") {
              /* Set Windows Attributes */
              params = currentCmdBuffer.splice(0, 4);
              //console.log("Setting Window Attributes at " + tc);
              //console.log(params);
              windows[currentWindow].alignment = mccFunc.alignmentMap[parseInt(hexToBinary(params[2]).substring(6))];

              //console.log("Alignment: "+windows[currentWindow].alignment);
            } else {
              /* text */
              if (currentWindow !== false) {
                if (windows[currentWindow].lines.length === 0) {
                  windows[currentWindow].lines.push({
                    text: "",
                    posX: 0,
                    // Percent %
                    posY: 75,
                    // Percent %
                    bold: false,
                    italics: false,
                    underline: false
                  });
                }
                //console.log(decodedCmd);
                windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text += decodedCmd;
              }
            }
          }
        }
      }
    });
    if (["ch01", "ch02", "ch03", "ch04"].indexOf(channel) > -1) {
      // console.log(sccFile);
      events = scenerist.decode(sccFile, {
        frameRate: frameRate.frames,
        dropFrame: frameRate.dropFrame,
        window: options.window
      });
      let eventGroup = scenerist.postProcess.decode({
        events: events
      }, options);
      events = eventGroup.events;
    } else {
      buffer.forEach(window => {
        events.push(mccFunc.decodeWindowData(window));
      });
    }
    return events;
  },
  encode: function (eventGroups, options) {
    /* Encodings can be tested using MXFWrapper: 
        C:\Users\natha\Documents\mxfwrap.exe -2011 -f -fr=30000/1001 -hp=8192 -is -ii2 -u "C:\Users\natha\Documents\bars.mxf&C:\Users\natha\Documents\macEncode.mcc" "C:\Users\natha\Documents\test.mxf" 
    */
    let output = "",
      clock,
      channels = [],
      encodingOptions = getFormatOptions(options.formatOptions),
      mccVersion = "2.0",
      eventGroupChannelMappings,
      languageChannelMappings,
      vChipCmds,
      contentAdvisoryCmds,
      programNameCmds,
      programLengthCmds,
      vancChannelData,
      vancData = "",
      writingVancData = false,
      ccCount = mccFunc.frameRateMapping[options.frameRate.toString()].cc_count;

    /* console.log(JSON.stringify(eventGroups, null, 4));
    console.log(JSON.stringify(options, null, 4)); */

    //The ccChannelStatus manages the status of each channel so that we know when clear/and start commands need to be sent/updated.
    let ccChannelStatus = [{
      id: "ch01",
      screen: false,
      buffer: false
    }, {
      id: "ch02",
      screen: false,
      buffer: false
    }, {
      id: "ch03",
      screen: false,
      buffer: false
    }, {
      id: "ch04",
      screen: false,
      buffer: false
    }, {
      id: "programA",
      screen: false,
      buffer: false
    }, {
      id: "programB",
      screen: false,
      buffer: false
    }, {
      id: "programC",
      screen: false,
      buffer: false
    }, {
      id: "programD",
      screen: false,
      buffer: false
    }, {
      id: "programE",
      screen: false,
      buffer: false
    }, {
      id: "programF",
      screen: false,
      buffer: false
    }];
    if (encodingOptions["MCC Version"]) {
      mccVersion = encodingOptions["MCC Version"];
    }
    if (encodingOptions["Program Information"] && encodingOptions["Program Information"].enable) {
      if (encodingOptions["Program Information"].programName) {
        //Program Name & maybe description later on
        programNameCmds = sccFunc.encodeProgramName(encodingOptions["Program Information"].programName);
        programNameCmds = sccFunc.formatEncodedCmds(programNameCmds);
        /* Program Length */
        programLengthCmds = sccFunc.encodeProgramLength(encodingOptions["Program Information"].programLengthHours, encodingOptions["Program Information"].programLengthMinutes);
        programLengthCmds = sccFunc.formatEncodedCmds(programLengthCmds);
        /* Keyword Groups */
        contentAdvisoryCmds = sccFunc.encodeProgramType([encodingOptions["Program Information"].basicKeywordGroup, ...encodingOptions["Program Information"].detailKeywordGroup]);
        contentAdvisoryCmds = sccFunc.formatEncodedCmds(contentAdvisoryCmds);
        console.log("Program Name Commands:", programNameCmds);
        console.log("Porprogram Length Commands:", programLengthCmds);
        console.log("Content Advisory Commands:", contentAdvisoryCmds);
      }
    }
    if (encodingOptions["V-Chip Information"] && encodingOptions["V-Chip Information"].enable) {
      vChipCmds = sccFunc.encodeVChipInfo(encodingOptions["V-Chip Information"].type, encodingOptions["V-Chip Information"].rating, encodingOptions["V-Chip Information"].content);
      vChipCmds = sccFunc.formatEncodedCmds(vChipCmds);
      // console.log("VChip Commands:", vChipCmds);
    }
    if (encodingOptions["Event Group Channel Mappings"]) {
      eventGroupChannelMappings = encodingOptions["Event Group Channel Mappings"];
      // console.log(eventGroupChannelMappings)   

      eventGroupChannelMappings = {
        "cc1": !isNaN(parseInt(eventGroupChannelMappings["cc1"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc1"]])) : undefined,
        "cc2": !isNaN(parseInt(eventGroupChannelMappings["cc2"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc2"]])) : undefined,
        "cc3": !isNaN(parseInt(eventGroupChannelMappings["cc3"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc3"]])) : undefined,
        "cc4": !isNaN(parseInt(eventGroupChannelMappings["cc4"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc4"]])) : undefined,
        "programA": !isNaN(parseInt(eventGroupChannelMappings["programA"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programA"]])) : undefined,
        "programB": !isNaN(parseInt(eventGroupChannelMappings["programB"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programB"]])) : undefined,
        "programC": !isNaN(parseInt(eventGroupChannelMappings["programC"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programC"]])) : undefined,
        "programD": !isNaN(parseInt(eventGroupChannelMappings["programD"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programD"]])) : undefined,
        "programE": !isNaN(parseInt(eventGroupChannelMappings["programE"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programE"]])) : undefined,
        "programF": !isNaN(parseInt(eventGroupChannelMappings["programF"])) ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programF"]])) : undefined
      };
    } else {
      eventGroupChannelMappings = {
        "cc1": eventGroups[0],
        "programA": eventGroups[0]
      };
    }
    if (encodingOptions["Language Channel Mappings"]) {
      languageChannelMappings = encodingOptions["Language Channel Mappings"];
    } else {
      languageChannelMappings = {
        "programA": "eng",
        "programB": "eng",
        "programC": "eng",
        "programD": "eng",
        "programE": "eng",
        "programF": "eng"
      };
    }
    if (options.incode) {
      try {
        clock = tcLib.createTc(options.incode, options.frameRate, options.dropFrame);
      } catch (err) {
        throw new Error(err.message);
      }
    } else {
      clock = tcLib.createTc(tcLib.secToTc(eventGroups[0].events[0].start, options.frameRate), options.frameRate, options.dropFrame);
      try {
        clock.subtract(300);
      } catch (e) {
        clock = tcLib.createTc("00:00:00:01", options.frameRate, options.dropFrame);
      }
    }
    Object.keys(eventGroupChannelMappings).forEach(ch => {
      if (!eventGroupChannelMappings[ch]) {
        return;
      }
      let channel = ch.replace("cc", "ch0");
      channels.push(channel);
      eventGroupChannelMappings[ch].events.forEach((event, index, events) => {
        events[index].processed = false;
        events[index].channel = channel;
        events[index].dtvWindow = index % 2 === 0 ? 0 : 1;
        events[index].startFrame = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].endFrame = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].eventDetails = sccFunc.getEventDetails(event, options.window);
        events[index].encodedText = sccFunc.encodeEvent(events[index].eventDetails, ch.includes("ch0") ? channel : "ch01", options.window);
        events[index].encoded708Text = mccFunc.encodeDtvEvent(events[index].eventDetails, index % 2 === 0, options.window);
        events[index].encodedTextString = sccFunc.formatEncodedCmds(events[index].encodedText);
        events[index].encodeTime = sccFunc.calculateEncodeTime(events[index].encodedTextString);
        events[index].encodeStartTime = event.style === "Pop-On" ? events[index].startFrame - (events[index].encodeTime + 120) : events[index].startFrame;
      });
    });
    //console.log(JSON.stringify(eventGroupChannelMappings, null, 4));
    output = mccFunc.generateMccFileHeader(mccVersion, options.frameRate);
    vancChannelData = {
      "dtv": {
        "programA": [],
        "programB": [],
        "programC": [],
        "programD": [],
        "programE": [],
        "programF": []
      },
      "atv": {
        "ch01": [],
        "ch02": [],
        "ch03": [],
        "ch04": []
      },
      "scc": []
    };
    channels.forEach(channel => {
      if (channel.includes("ch0")) {
        vancChannelData.atv[channel].push(sccFunc.getCodeByCmd(sccLookup[channel], "{RESUME LOADING}"));
        vancChannelData.atv[channel].push(sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}"));
      } else {
        vancChannelData.dtv[channel].push("8C");
        vancChannelData.dtv[channel].push("FF");
      }
    });
    writingVancData = true;
    while (writingVancData) {
      vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
      if (!vancData) {
        writingVancData = false;
      } else {
        output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
        clock.add(1);
      }
    }
    let eventsToProcess,
      loops = 0,
      processing = true;
    while (processing && loops < 1000000) {
      loops++;
      //console.log("Checking for events to display or clear at "+clock.toString());
      vancChannelData = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);

      //console.log("Display or Clear Commands", vancChannelData);
      writingVancData = true;
      while (writingVancData) {
        vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
        if (!vancData) {
          writingVancData = false;
        } else {
          output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
          clock.add(1);
        }
      }
      eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, clock.frameCount);
      //console.log("Events to Process at clock: "+clock.toString(), eventsToProcess.length, eventsToProcess.map(ev => ev.text).join());
      if (eventsToProcess.length > 0) {
        vancChannelData = {
          "dtv": {
            "programA": [],
            "programB": [],
            "programC": [],
            "programD": [],
            "programE": [],
            "programF": []
          },
          "atv": {
            "ch01": [],
            "ch02": [],
            "ch03": [],
            "ch04": []
          },
          "scc": []
        };
        let increaseClock = true;
        eventsToProcess.forEach((event, index, events) => {
          let channelIndex = ccChannelStatus.findIndex(ccChannel => ccChannel.id === event.channel);
          //console.log(channelIndex);
          if (event.style === "Pop-On" && ccChannelStatus[channelIndex].buffer) {
            //console.log("EVENTS IN BUFFER. CAN'T PROCESS");
            return;
          } else {
            increaseClock = false;
            //console.log("Writing event to channel: "+event.channel+" at "+clock.toString());
          }
          if (event.channel.includes('program')) {
            vancChannelData.dtv[event.channel].push(...event.encoded708Text);
          } else {
            vancChannelData.atv[event.channel].push(...event.encodedTextString.split(" "));
          }
          if (event.style === "Pop-On") {
            ccChannelStatus[channelIndex].buffer = event;
          } else {
            ccChannelStatus[channelIndex].screen = event;
          }
          events[index].processed = true;
        });
        writingVancData = true;
        while (writingVancData) {
          vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
          if (!vancData) {
            writingVancData = false;
          } else {
            output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
            clock.add(1);
          }
        }
        if (increaseClock) {
          clock.add(1);
        }
      } else {
        /* insert XDS Data */
        if (vChipCmds) {
          //First check to see if there are any Events to process or display:
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + vChipCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            vancChannelData = {
              "dtv": {
                "programA": [],
                "programB": [],
                "programC": [],
                "programD": [],
                "programE": [],
                "programF": []
              },
              "atv": {
                "ch01": [],
                "ch02": [],
                "ch03": vChipCmds.split(" "),
                "ch04": []
              },
              "scc": []
            };
            writingVancData = true;
            while (writingVancData) {
              vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
              if (!vancData) {
                writingVancData = false;
              } else {
                output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
                clock.add(1);
              }
            }
          }
        }
        if (contentAdvisoryCmds) {
          //First check to see if there are any Events to process or display:
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + contentAdvisoryCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            vancChannelData = {
              "dtv": {
                "programA": [],
                "programB": [],
                "programC": [],
                "programD": [],
                "programE": [],
                "programF": []
              },
              "atv": {
                "ch01": [],
                "ch02": [],
                "ch03": contentAdvisoryCmds.split(" "),
                "ch04": []
              },
              "scc": []
            };
            writingVancData = true;
            while (writingVancData) {
              vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
              if (!vancData) {
                writingVancData = false;
              } else {
                output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
                clock.add(1);
              }
            }
          }
        }
        if (programNameCmds) {
          //First check to see if there are any Events to process or display:
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programNameCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            vancChannelData = {
              "dtv": {
                "programA": [],
                "programB": [],
                "programC": [],
                "programD": [],
                "programE": [],
                "programF": []
              },
              "atv": {
                "ch01": [],
                "ch02": [],
                "ch03": programNameCmds.split(" "),
                "ch04": []
              },
              "scc": []
            };
            writingVancData = true;
            while (writingVancData) {
              vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
              if (!vancData) {
                writingVancData = false;
              } else {
                output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
                clock.add(1);
              }
            }
          }
        }
        if (programLengthCmds) {
          //First check to see if there are any Events to process or display:
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programLengthCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            vancChannelData = {
              "dtv": {
                "programA": [],
                "programB": [],
                "programC": [],
                "programD": [],
                "programE": [],
                "programF": []
              },
              "atv": {
                "ch01": [],
                "ch02": [],
                "ch03": programLengthCmds.split(" "),
                "ch04": []
              },
              "scc": []
            };
            writingVancData = true;
            while (writingVancData) {
              vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
              if (!vancData) {
                writingVancData = false;
              } else {
                output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
                clock.add(1);
              }
            }
          }
        }
        vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData = {
          "dtv": {
            "programA": [],
            "programB": [],
            "programC": [],
            "programD": [],
            "programE": [],
            "programF": []
          },
          "atv": {
            "ch01": ["8080"],
            "ch02": [],
            "ch03": [],
            "ch04": []
          },
          "scc": []
        }, languageChannelMappings);
        output += "\n" + clock.toString().replace(";", ":") + "\t" + vancData;
        clock.add(1);
      }

      /* Figure out if we need to close the loop */
      let eventsToStillProcess = sccFunc.getNumberOfEventsToProcess(eventGroupChannelMappings);
      if (eventsToStillProcess === 0) {
        if (sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
          processing = true;
        } else {
          processing = false;
        }
      }
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup, options) {
      eventGroup.events.forEach((event, index, events) => {
        if (!sccFunc.verifyFormatting(event, options.window)) {
          /* Debug */
          //console.log("--------------");
          //console.log(event.text);
          events[index].text = autoFormatSimple(event.text);
          events[index].xPos = "center";
          events[index].yPos = "end";
          events[index].xOffset = options.window.xOffset;
          events[index].yOffset = options.window.yOffset;
        }
      });
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      /* 
          We decoded the SCC using the 32x15 grid but now we need to center that grid over the window. There is a fixed offset of 10% (x) and 10% (y). This is due to the caption area being 90% of the frames width and 90% of the frames height. 
      */

      let xOffset = parseInt(options.window.width * 0.10);
      let yOffset = parseInt(options.window.height * 0.10);
      // console.log("XOffset", xOffset);
      // console.log("YOffset", yOffset);
      // console.log(JSON.stringify(eventGroup.events, null, 5));
      eventGroup.events.forEach((event, index, events) => {
        if (event.xPos === "start") {
          events[index].xOffset += xOffset;
        } else if (event.xPos === "end") {
          events[index].xOffset -= xOffset;
        }
        if (event.yPos === "start") {
          events[index].yOffset += yOffset;
        } else if (event.yPos === "end") {
          events[index].yOffset -= yOffset;
        }
      });
      return eventGroup;
    }
  }
};