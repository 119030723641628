import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class Event {
  constructor(options = {
    start: false,
    end: false,
    duration: false,
    confidence: false,
    rate: 1,
    //Playback rate for audio description
    xPos: "center",
    //start, center, end
    yPos: "end",
    //start, center, end
    xOffset: 0,
    //px
    yOffset: 0,
    //px
    vertical: false,
    alignment: "center",
    //left, center, right
    original: "",
    //Original Language for translation
    text: "",
    style: "Pop-On",
    //Pop-On, Paint-On, Roll-Up 2, Roll-Up 3, Roll-Up 4
    tags: [],
    speakers: [],
    recipients: [],
    voice: {
      "provider": "google",
      "name": "en-US-Wavenet-A",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    },
    speakingStyle: "default",
    voiceStability: 50,
    // %
    voiceSimilarity: 50,
    // %
    extended: false,
    rendered: true,
    audioFile: "",
    waveformFile: "",
    audioFileDuration: false,
    notes: "",
    notesCheck: false,
    reply: "",
    replyCheck: false,
    approved: undefined,
    forced: false,
    background: "#000000",
    color: "#FFFFFF",
    colors: 0,
    fontSize: false,
    metadata: []
  }) {
    this.id = uuidv4(), this.start = options.start, this.end = options.end, this.duration = options.duration, this.confidence = options.confidence, this.rate = options.rate || 1, this.xPos = options.xPos || "center", this.yPos = options.yPos || "end", this.xOffset = options.xOffset || 0, this.yOffset = options.yOffset || 0, this.vertical = options.vertical, this.alignment = options.alignment || "center", this.original = options.original || "", this.text = options.text || "", this.style = options.style || "Pop-On", this.tags = options.tags || [], this.speakers = options.speakers || [], this.recipients = options.recipients || [], this.voice = options.voice || {
      "provider": "google",
      "name": "en-US-Wavenet-A",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, this.speakingStyle = options.speakingStyle || "default", this.voiceStability = options.voiceStability || 50, this.voiceSimilarity = options.voiceSimilarity || 50, this.extended = options.extended, this.rendered = options.rendered || true, this.audioFile = options.audioFile || "", this.waveformFile = options.waveformFile || "", this.audioFileDuration = options.audioFileDuration, this.notes = options.notes || "", this.reply = options.reply || "", this.notesCheck = options.notesCheck ?? false, this.replyCheck = options.replyCheck ?? false, this.approved = options.approved, this.forced = options.forced, this.background = options.background || "#000000", this.color = options.color || "#ffffff", this.colors = options.colors || 0, this.fontSize = options.fontSize, this.metadata = options.metadata || [];
  }
});