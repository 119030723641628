<script>
	import { modalState } from "@app/store/modalStore.js";
	import { projectState } from "@app/store/projectStore.js";
	import { historyState } from "@app/store/historyStore.js";
	import { authState } from "@app/store/authStore.js";
	import { toast } from "@zerodevx/svelte-toast";
	import { speakerState } from "@app/store/speakerStore.js";
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import { styleState } from "@app/store/styleStore.js";
	import { onDestroy } from "svelte";
	import { fade } from "svelte/transition";
	import Papa from "papaparse";
	import Swal from "sweetalert2";
	import { BarLoader, Circle } from "svelte-loading-spinners";

	/* CC LIB */
	import _Event from "@app/external/cc-lib/dist/classes/event.js";
	import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
	import _Speaker from "@app/external/cc-lib/dist/classes/speaker.js";
	import decode from "@app/external/cc-lib/dist/functions/decode.js";
	import encodeHtmlEntities from "@app/external/cc-lib/dist/functions/quill/encodeHtmlEntities.js";
	import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
	import encode from "@app/external/cc-lib/dist/functions/encode.js";
	import formats from "@app/external/cc-lib/dist/lib/formats.js";
	import alignCaptionFile from "@app/external/cc-lib/dist/functions/special/alignCaptionFile.js";
	import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
	import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
	/* Firebase */
	import firebase from "@app/configs/firebase.js";
	import "firebase/compat/functions";
	import db from "@app/configs/firestore.js";
	import storage from "@app/configs/storage.js";

	import { saveAs } from "file-saver";

	let jobs = [],
		selectedJob,
		userId = firebase.auth().currentUser.uid,
		email = firebase.auth().currentUser.email,
		teamId = $authState.team ? $authState.team.id : null,
		dbRef = $authState.team
			? db.collection("teams").doc(teamId).collection("jobs")
			: db.collection("users").doc(userId).collection("jobs"),
		storageRef = storage.ref(),
		storagePath = $authState.team
			? `teams/${teamId}/transcripts/`
			: `users/${userId}/transcripts/`,
		checkingStatus = false,
		deletingFile = false,
		loading = false,
		showOptions = false,
		showDownloadOptions = false,
		autoRefresh = true,
		dashboardDefaults = JSON.parse(localStorage.getItem("cc-transcription-dashboard-defaults")) || {},
		adDefaults =
			JSON.parse(localStorage.getItem("cc-ad-defaults-new")) || {},
		eventGroupDefaults =
			JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};

	let maxChars = eventGroupDefaults.maxChars || 32;
	let maxLines = eventGroupDefaults.maxLines || 2;

	let dateRange = dashboardDefaults.dateRange || "week",
		startDate = dashboardDefaults.startDate,
		endDate = dashboardDefaults.endDate,
		statusFilter = "All",
		pending = false,
		gettingJobs = getTranscriptJobs();

	let autoRefreshInterval = setInterval(function () {
		if (!loading && !pending) {
			gettingJobs = getTranscriptJobs();
		}
	}, 10000);

	onDestroy(() => {
		if (autoRefreshInterval) {
			clearInterval(autoRefreshInterval);
		}
	});

	function disableAutoRefresh() {
		clearInterval(autoRefreshInterval);
		autoRefreshInterval = undefined;
		autoRefresh = false;
	}

	function enableAutoRefresh() {
		if (autoRefreshInterval) {
			clearInterval(autoRefreshInterval);
		}

		autoRefreshInterval = setInterval(function () {
			if (!loading && !pending) {
				gettingJobs = getTranscriptJobs();
			}
		}, 10000);

		autoRefresh = true;
	}

	function getDateRange() {
		let start, end;
		switch (dateRange) {
			case "custom":
				start = new Date(startDate);
				end = new Date(endDate);
				break;
			case "week":
				// code block
				start = new Date();
				end = new Date();
				start.setHours(start.getHours() - 168);
				break;
			case "month":
				// code block
				start = new Date();
				end = new Date();
				start.setMonth(start.getMonth() - 1);
				break;
			case "year":
				// code block
				start = new Date();
				end = new Date();
				start.setFullYear(start.getFullYear() - 1);
				break;
			default:
				start = new Date();
				end = new Date();
				start.setHours(start.getHours() - 24);
		}

		return {
			start: start,
			end: end,
		};
	}

	function getTranscriptJobs() {
		if (pending === true) {
			return;
		}
		pending = true;
		let queryDateRange = getDateRange();
		//console.log(queryDateRange);

		selectedJob = undefined;
		jobs = [];

		return dbRef
			.where("createdOn", ">=", queryDateRange.start)
			.where("createdOn", "<=", queryDateRange.end)
			.where("type", "==", "transcription")
			.where(
				"status",
				"in",
				statusFilter === "All"
					? [
							"Submitted",
							"Passed",
							"Failed",
							"Submitted",
							"In Progress",
						]
					: [statusFilter],
			)
			.orderBy("createdOn", "desc")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					//console.log(doc.data());
					if (!doc.data().archived) {
						jobs = [...jobs, doc.data()];
					}
				});

				if (allJobsComplete(jobs)) {
					disableAutoRefresh();
				}
			})
			.catch((error) => {
				console.log("Error getting documents: ", error);
				toast.push("Error getting transcripts", {
					classes: ["toast-danger"],
				});
			})
			.finally(() => {
				pending = false;
			});
	}

	function allJobsComplete(jobs) {
		let inProgressJob = jobs.findIndex((job) => {
			return job.progress !== 100;
		});

		if (inProgressJob > -1) {
			return false;
		} else {
			return true;
		}
	}

	function updateSpeakerStore(evg) {
		evg.events.forEach((event, index, events) => {
			if (event.speakers && event.speakers.length > 0) {
				let speakerIndex = $speakerState.findIndex((sp) => {
					return sp.name == "Speaker" + event.speakers[0];
				});

				if (speakerIndex === -1) {
					let newSpeaker = new _Speaker({
						name: "Speaker" + event.speakers[0],
					});
					$speakerState = [...$speakerState, newSpeaker];
					events[index].speakers = [newSpeaker];
				} else {
					events[index].speakers = [$speakerState[speakerIndex]];
				}
			}
		});
	}

	async function getTranscriptFile() {
		let url = await storageRef
			.child(storagePath + selectedJob.id + ".json")
			.getDownloadURL();
		let res = await fetch(url);
		let resJson = await res.json();
		return resJson;
	}

	async function importTranscript(type) {
		try {
			disableAutoRefresh();
			loading = true;

			toast.push("Loading transcript... please wait...", {
				classes: ["toast-info"],
			});

			let resJson = await getTranscriptFile();
			let srcProfile = selectedJob.config.provider;
			let playerWidth =
				document.getElementById("PlayerWrapper").clientWidth;
			let playerHeight =
				document.getElementById("PlayerWrapper").clientHeight;
			let options = new defaults.options({
				profile: formats.profileMapping[srcProfile].name,
				formatOptions: [
					{
						name: "Import Type",
						type: "list",
						values: ["subtitle", "transcription"],
						selected: "subtitle",
					},
					{
						name: "Max Characters",
						type: "number-input",
						values: "",
						selected: maxChars,
					},
					{
						name: "Max Lines",
						type: "number-input",
						values: "",
						selected: maxLines,
					},
				],
				frameRate: $projectState.frameRate,
				dropFrame: $projectState.dropFrame,
				window: {
					width: playerWidth,
					height: playerHeight,
					xOffset: ($styleState.xPadding / 100) * playerWidth,
					yOffset: ($styleState.yPadding / 100) * playerHeight,
				},
			});

			let transcriptEventGroup = await decode(
				JSON.stringify(resJson),
				options,
			);
			transcriptEventGroup.events = transcriptEventGroup.events.map(
				(event) => {
					event.text = encodeHtmlEntities(event.text);
					return event;
				},
			);
			transcriptEventGroup.type = "subtitle";
			transcriptEventGroup.name = `AI ${type} 0${$eventGroupState.length + 1}`;
			transcriptEventGroup.maxCps = eventGroupDefaults.maxCps || 9999;
			transcriptEventGroup.maxWpm = eventGroupDefaults.maxWpm || 9999;
			transcriptEventGroup.maxChars = eventGroupDefaults.maxChars || 9999;
			transcriptEventGroup.maxLines = eventGroupDefaults.maxLines || 9999;
			transcriptEventGroup.overlap = eventGroupDefaults.overlap;
			transcriptEventGroup.illegalChars = eventGroupDefaults.illegalChars;
			$eventGroupState = [...$eventGroupState, transcriptEventGroup];
			$projectState.selected = $eventGroupState.length - 1;
			updateSpeakerStore(transcriptEventGroup);

			historyState.insert({
				name: "import transcript", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});

			toast.push("Transcript import successful", {
				classes: ["toast-success"],
			});
			/* Save default transcript dashboard settings to local storage */
			localStorage.setItem("cc-transcription-dashboard-defaults", JSON.stringify({
				dateRange : dateRange,
				startDate : startDate,
				endDate  : endDate		
			}));
		} catch (err) {
			console.error(err);
			console.error(err.message);

			toast.push(err.message, { classes: ["toast-danger"] });
		} finally {
			modalState.hideModal();
		}
	}

	async function downloadSubtitle(profile) {
		try {
			console.log("Downloading subtitle file");
			disableAutoRefresh();
			loading = true;

			if (!profile) {
				/* if the profile is not found we need to use sweet alert to ask the user to select a profile from a list of profiles (e.g. Subtitles, or transcript) */
				let trgProfiles = formats.getTrgProfiles();
				let inputList = {};
				trgProfiles.forEach((profile) => {
					inputList[profile] = formats.getProfileTitleByName(profile);
				});

				let swalRes = await Swal.fire({
					title: "Select Profile",
					text: `Please select a profile to download the subtitle file in.`,
					input: "select",
					inputOptions: inputList,
					showCancelButton: true,
					confirmButtonText: "Download",
					cancelButtonText: "Cancel",
				});

				if (swalRes.isConfirmed) {
					profile = swalRes.value;
				} else {
					return;
				}
			}

			toast.push("Downloading subtitle file... please wait...", {
				classes: ["toast-info"],
			});

			let resJson = await getTranscriptFile();
			if (profile === "raw") {
				let jsonBlob = new Blob([resJson], {
					type: "text/plain;charset=utf-8",
				});

				saveAs(jsonBlob, selectedJob.id + ".json");
				return;
			}

			let transcriptEventGroup;
			let srcProfile = selectedJob.config.provider;
			let playerWidth =
				document.getElementById("PlayerWrapper").clientWidth;
			let playerHeight =
				document.getElementById("PlayerWrapper").clientHeight;
			let srcOptions = new defaults.options({
				profile: formats.profileMapping[srcProfile].name,
				formatOptions: [
					{
						name: "Import Type",
						type: "list",
						values: ["subtitle", "transcription"],
						selected: "subtitle",
					},
					{
						name: "Max Characters",
						type: "number-input",
						values: "",
						selected: maxChars,
					},
					{
						name: "Max Lines",
						type: "number-input",
						values: "",
						selected: maxLines,
					},
				],
				frameRate: $projectState.frameRate,
				dropFrame: $projectState.dropFrame,
				window: {
					width: playerWidth,
					height: playerHeight,
					xOffset: ($styleState.xPadding / 100) * playerWidth,
					yOffset: ($styleState.yPadding / 100) * playerHeight,
				},
			});

			transcriptEventGroup = await decode(
				JSON.stringify(resJson),
				srcOptions,
			);

			if ($authState.status === "in_trial") {
				transcriptEventGroup = replaceTrialText(transcriptEventGroup);
			}

			transcriptEventGroup.events = transcriptEventGroup.events.map(
				(event) => {
					event.text = encodeHtmlEntities(event.text);
					return event;
				},
			);

			let trgOptions = new defaults.options({
				profile: profile,
				frameRate: $projectState.frameRate,
				dropFrame: $projectState.dropFrame,
				window: {
					width: playerWidth,
					height: playerHeight,
					xOffset: ($styleState.xPadding / 100) * playerWidth,
					yOffset: ($styleState.yPadding / 100) * playerHeight,
				},
			});

			if (profile === "transcriptDocX") {
				profile = "dialogueListWord";
				trgOptions.profile = profile;
				trgOptions.encoding_options = [
					{
						name: "Export Title Page",
						type: "list",
						values: ["yes", "no"],
						selected: "no",
					},
					{
						name: "Title",
						type: "text-input",
						values: "",
						selected: "DEFAULT TITLE",
					},
					{
						name: "Export Metadata",
						type: "list",
						values: ["yes", "no"],
						selected: "no",
					},
					{
						name: "Export Event Id",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
					{
						name: "Export Speakers",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
					{
						name: "Export Tags",
						type: "list",
						values: ["yes", "no"],
						selected: "no",
					},
					{
						name: "Export Notes",
						type: "list",
						values: ["yes", "no"],
						selected: "no",
					},
					{
						name: "Export Reply",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
					{
						name: "Timecode Format",
						type: "list",
						values: ["SMPTE", "Clock"],
						selected: "SMPTE",
					},
					{
						name: "Export Start Time",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
					{
						name: "Export End Time",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
					{
						name: "Export Duration",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
				];
			} else if (profile === "transcriptTxt") {
				profile = "textTranscript";
				trgOptions.profile = profile;
				trgOptions.encoding_options = [
					{
						name: "Split On New Speaker",
						type: "list",
						values: ["yes", "no"],
						selected: "yes",
					},
					{
						name: "Split On Event Gap",
						type: "number-input",
						values: "",
						selected: 3,
					},
					{
						name: "Event Prefix",
						type: "text-input",
						values: "",
						selected: "",
					},
					{
						name: "Event Suffix",
						type: "text-input",
						values: "",
						selected: "",
					},
				];
			}

			let output = await encode(transcriptEventGroup, trgOptions);
			let defaultEncoding = formats.defaultEncodingMap[profile];
			let trgExt = formats.defaultExtMap[profile];
			let fileName = selectedJob.projectName + "." + trgExt;
			let fileBlob;

			if (defaultEncoding === "buffer") {
				if (trgExt === "xlsx") {
					fileBlob = new Blob([output], {
						type: "application/octet-stream",
					});
				} else {
					fileBlob = output;
				}
			} else if (defaultEncoding.toLowerCase() === "hex") {
				let byteArray = new Uint8Array(
					output.match(/.{2}/g).map((e) => parseInt(e, 16)),
				);
				fileBlob = new Blob([byteArray], {
					type: "application/octet-stream",
				});
			} else if (defaultEncoding.toLowerCase() === "utf-16le") {
				fileBlob = new Blob([output], {
					type: "text/plain;charset=utf-16le",
				});
			} else {
				fileBlob = new Blob([output], {
					type: "text/plain;charset=" + defaultEncoding,
				});
			}

			saveAs(fileBlob, fileName, {
				autoBom: true,
			});

			toast.push("Subtitle file downloaded successfully", {
				classes: ["toast-success"],
			});
		} catch (err) {
			console.error(err);
			console.error(err.message);

			toast.push(err.message, { classes: ["toast-danger"] });
		} finally {
			modalState.hideModal();
		}
	}

	async function automaticSync() {
		disableAutoRefresh();

		toast.push("Calculating timecodes of events... please wait...", {
			classes: ["toast-info"],
		});

		if (loading) {
			return;
		}

		loading = true;

		storageRef
			.child(storagePath + selectedJob.id + ".json")
			.getDownloadURL()
			.then((url) => {
				return fetch(url);
			})
			.then((res) => {
				return res.json();
			})
			.then(async (resJson) => {
				let srcProfile = selectedJob.config.provider;
				let options = new defaults.options({
					profile: formats.profileMapping[srcProfile].name,
					formatOptions: [
						{
							name: "Import Type",
							type: "list",
							values: ["subtitle", "transcription"],
							selected: "word map",
						},
						{
							name: "Max Characters",
							type: "number-input",
							values: "",
							selected: 32,
						},
						{
							name: "Max Lines",
							type: "number-input",
							values: "",
							selected: 3,
						},
					],
					frameRate: $projectState.frameRate,
					dropFrame: $projectState.dropFrame,
					window: {
						width: 720,
						height: 480,
						xOffset: 0,
						yOffset: 0,
					},
				});

				let wordMap = await decode(JSON.stringify(resJson), options);
				wordMap.events = wordMap.events.map((event) => {
					event.text = encodeHtmlEntities(event.text);
					return event;
				});
				let eventGroup = JSON.parse(
					JSON.stringify($eventGroupState[$projectState.selected]),
				);

				if (eventGroup.events.length === 0) {
					return;
				}

				//Calculate missing timecodes so that they don't get filtered out by the alignCaptionFile function.
				eventGroup.events.forEach((event, i, events) => {
					events[i].start = i + 0.25;
					events[i].end = i + 0.75;
				});

				let alignmentRes = await alignCaptionFile(
					eventGroup,
					wordMap,
					$projectState.frameRate,
					false,
				);
				//console.log(alignmentRes);

				$eventGroupState[$projectState.selected].events =
					alignmentRes.eventGroup.events;

				if (alignmentRes.missingSegments.length > 0) {
					//Alert user of missing dialogue segments, and ask if they would like a new event group created with the missing dialogue.
					let swalRes = await Swal.fire({
						title: "Missing Dialogue",
						text: `The Alignment Process has detected missing dialogue. Would you like to create a new event group with the missing dialogue Events?`,
						icon: "warning",
						showCancelButton: true,
						confirmButtonText: "Yes",
						cancelButtonText: "No",
					});

					//If user selects yes, create a new event group with the missing dialogue segments and add it to the event group state.
					if (swalRes.isConfirmed) {
						let newEventGroup = new _EventGroup({
							name:
								"Missing Dialogue 0" + $eventGroupState.length,
							type: "subtitle",
							maxCps: eventGroupDefaults.maxCps || 9999,
							maxWpm: eventGroupDefaults.maxWpm || 9999,
							maxChars: eventGroupDefaults.maxChars || 9999,
							maxLines: eventGroupDefaults.maxLines || 9999,
							illegalChars: eventGroupDefaults.illegalChars,
						});

						alignmentRes.missingSegments.forEach((segment) => {
							newEventGroup.events.push(
								new _Event({
									start: segment.start,
									end: segment.end,
									text: convertToHtml(segment.text),
								}),
							);
						});

						$eventGroupState = [...$eventGroupState, newEventGroup];
					}
				}

				historyState.insert({
					name: "automatic sync", //action name
					eventGroup: $projectState.selected,
					snapshots: [
						{
							store: "eventGroupState",
							value: JSON.stringify($eventGroupState),
						},
					],
				});

				toast.push("Timing applied successfully", {
					classes: ["toast-success"],
				});

				modalState.hideModal();
			})
			.catch((error) => {
				console.log(error, error.message);
				loading = false;

				toast.push(error.message, { classes: ["toast-danger"] });
			});
	}

	async function createAdTemplate() {
		disableAutoRefresh();
		toast.push("Creating AD template... please wait...", {
			classes: ["toast-info"],
		});

		if (loading) {
			return;
		}

		loading = true;

		let type = "transcription";

		storageRef
			.child(storagePath + selectedJob.id + ".json")
			.getDownloadURL()
			.then((url) => {
				return fetch(url);
			})
			.then((res) => {
				return res.json();
			})
			.then(async (resJson) => {
				let srcProfile = selectedJob.config.provider;
				let options = new defaults.options({
					profile: formats.profileMapping[srcProfile].name,
					formatOptions: [
						{
							name: "Import Type",
							type: "list",
							values: ["subtitle", "transcription"],
							selected: type,
						},
						{
							name: "Max Characters",
							type: "number-input",
							values: "",
							selected: maxChars,
						},
						{
							name: "Max Lines",
							type: "number-input",
							values: "",
							selected: maxLines,
						},
					],
					frameRate: $projectState.frameRate,
					dropFrame: $projectState.dropFrame,
					window: {
						width: 720,
						height: 480,
						xOffset: 0,
						yOffset: 0,
					},
				});
				//console.log(options);
				let adEventGroupTemplate = new _EventGroup({
					name: "AD Template 0" + $eventGroupState.length,
					type: "audio description",
					maxCps: eventGroupDefaults.maxCps || 9999,
					maxWpm: eventGroupDefaults.maxWpm || 9999,
					maxChars: eventGroupDefaults.maxChars || 9999,
					maxLines: eventGroupDefaults.maxLines || 9999,
					illegalChars: eventGroupDefaults.illegalChars,
					language: "en-US",
					ad: {
						gender: adDefaults.gender || "male",
						language: adDefaults.language || "English - US",
						voice: adDefaults.voice || {
							provider: "google",
							name: "en-US-Wavenet-A",
							styles: ["default"],
							gender: "male",
							language: "en-US",
						},
					}, //AD Settings
					rtl: false /* Right To Left Support */,
				});
				let transcriptEventGroup = await decode(
					JSON.stringify(resJson),
					options,
				);
				transcriptEventGroup.events = transcriptEventGroup.events.map(
					(event) => {
						event.text = encodeHtmlEntities(event.text);
						return event;
					},
				);
				let start = 0;
				let end = 0;
				transcriptEventGroup.events.forEach((event) => {
					end = event.start;
					if (end - start > 1) {
						adEventGroupTemplate.events.push(
							new _Event({
								start: start,
								end: end,
								alignment: "left",
								voice: adEventGroupTemplate.ad.voice,
							}),
						);
					}
					start = event.end;
				});

				/* Create event for end of project */
				if (selectedJob.duration > start) {
					adEventGroupTemplate.events.push(
						new _Event({
							start: start,
							end: selectedJob.duration,
							alignment: "left",
							voice: adEventGroupTemplate.ad.voice,
						}),
					);
				}

				$eventGroupState = [...$eventGroupState, adEventGroupTemplate];
				$projectState.selected = $eventGroupState.length - 1;

				toast.push("AD template created successfully", {
					classes: ["toast-success"],
				});
				modalState.hideModal();
			})
			.catch((error) => {
				console.log(error, error.message);
				loading = false;

				toast.push(error.message, { classes: ["toast-danger"] });
			});
	}
	function archiveJob() {
		deletingFile = true;
		dbRef
			.doc(selectedJob.id)
			.update({
				archived: true,
			})
			.then(() => {
				toast.push("Job archived successfully", {
					classes: ["toast-success"],
				});
				gettingJobs = getTranscriptJobs();
			})
			.catch((e) => {
				toast.push("Error archiving job. " + e.message, {
					classes: ["toast-danger"],
				});
				console.log(e, e.message);
			})
			.finally(() => {
				deletingFile = false;
			});
	}
	async function deleteTranscript() {
		deletingFile = true;

		//Confirm deletion using Swal
		let swalRes = await Swal.fire({
			title: "Delete Transcript",
			text: `Are you sure you want to delete the transcript file for ${selectedJob.projectName}?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});

		if (!swalRes.isConfirmed) {
			deletingFile = false;
			return;
		}

		firebase
			.functions()
			.httpsCallable("v8DeleteTranscript")(selectedJob)
			.then(() => {
				toast.push("Transcript file deleted successfully", {
					classes: ["toast-success"],
				});
				gettingJobs = getTranscriptJobs();
			})
			.catch((e) => {
				toast.push("Error deleting transcript file. " + e.message, {
					classes: ["toast-danger"],
				});
				console.log(e, e.message);
			})
			.finally(() => {
				deletingFile = false;
			});
	}

	function exportCsv() {
		toast.push("Exporting results... please wait...", {
			classes: ["toast-info"],
		});

		let csv = Papa.unparse(
			JSON.stringify(
				jobs.map((job) => {
					return {
						id: job.id,
						projectId: job.projectId,
						projectName: job.projectName,
						progress: job.progress,
						createdOn: job.createdOn.toDate(),
						completedOn: job.completedOn
							? job.completedOn.toDate()
							: "",
						updatedOn: job.updatedOn.toDate(),
						status: job.status,
						statusMsg: job.statusMsg,
						type: job.type,
						duration: job.duration,
						cost: job.cost,
						provider: job.config.provider || "",
						language: job.config.language,
					};
				}),
			),
		);

		let fileBlob = new Blob([csv], {
			type: "text/csv;charset=utf-8",
		});

		saveAs(fileBlob, "report_transcript_jobs.csv", {
			autoBom: true,
		});
	}
</script>

<div
	transition:fade={{ duration: 100 }}
	class="modal {$modalState === 'aiTranscriptImport' ? 'show d-block' : ''}"
	role="dialog"
	tabindex="-1"
	id="AiTranscriptImportModal"
>
	<div class="modal-dialog modal-xl modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">AI Transcript Import</h4>
				<button
					type="button"
					class="btn-close"
					aria-label="Close"
					on:click={modalState.hideModal}
				></button>
			</div>
			<div class="modal-body">
				<div class="btn-group float-end mb-2" role="group">
					<button
						type="button"
						class="btn btn-outline-dark"
						on:click={exportCsv}
						disabled={jobs.length === 0}>Export as CSV</button
					>
				</div>
				<!-- Search Form -->
				<form>
					<div class="row g-1">
						<div class="col-2">
							<select
								class="form-select me-2 mb-2"
								bind:value={dateRange}
							>
								<option value="today">24 Hours</option>
								<option value="week">Past Week</option>
								<option value="month">Past Month</option>
								<option value="year">Past Year</option>
								<option value="custom">Custom</option>
							</select>
						</div>
						{#if dateRange === "custom"}
							<div class="col-2">
								<input
									class="form-control me-2 mb-2"
									type="date"
									bind:value={startDate}
								/>
							</div>
							<div class="col-2">
								<input
									class="form-control me-2 mb-2"
									type="date"
									bind:value={endDate}
								/>
							</div>
						{/if}
						<div class="col-2">
							<select
								class="form-select me-2 mb-2"
								bind:value={statusFilter}
							>
								<option value="All">All</option>
								<option value="Submitted">Submitted</option>
								<option value="In Progress">In Progress</option>
								<option value="Passed">Passed</option>
								<option value="Failed">Failed</option>
							</select>
						</div>
						<div class="col">
							<div class="btn-group" role="group">
								<button
									type="button"
									class="btn btn-light"
									disabled={dateRange === "custom" &&
										(!startDate || !endDate)}
									on:click={() => {
										gettingJobs = getTranscriptJobs();
									}}>Refresh</button
								>
								<button
									type="button"
									class="btn {autoRefresh
										? 'btn-primary'
										: 'btn-light'}"
									on:click={() => {
										autoRefresh
											? disableAutoRefresh()
											: enableAutoRefresh();
									}}
									><i
										class="bi {autoRefresh
											? 'bi-check-circle-fill'
											: 'bi bi-circle'}"
									></i> Auto Refresh</button
								>
							</div>
						</div>
						{#if dateRange === "custom" && (!startDate || !endDate)}
							<p class="text-danger small pt-2 me-2">
								Please provide a start and end date for custom
								queries.
							</p>
						{/if}
						{#if loading}
							<div class="col">
								<Circle
									size="30"
									color="#1eb4b2"
									unit="px"
									duration="1s"
								></Circle>
							</div>
						{/if}
					</div>
				</form>
				<!-- Results Table -->
				<div
					id="TranscriptTableDashboard"
					class="table-responsive rounded shadow border border-secondary"
				>
					<table class="table table-hover table-sm">
						<thead class="bg-dark text-dark">
							<tr>
								<th class="text-truncate">Project Name</th>
								<th class="text-truncate">Remaining Time</th>
								<th class="text-truncate">Progress</th>
								<th>Status</th>
								<th class="text-truncate">Status Message</th>

								<th class="text-truncate">Media Duration</th>
								<th class="text-truncate">Job Duration</th>
								<th>Provider</th>
								<th>Language</th>
								<th class="text-truncate">File Present</th>
								<th class="w-25">Job Id</th>
								<th class="text-truncate">Submitted By</th>
								<th>Created On</th>
								<th>Completed On</th>
							</tr>
						</thead>
						<tbody>
							{#await gettingJobs}
								<div
									style="padding: 10%; position:relative; left:105%;"
								>
									<BarLoader
										size="160"
										color="#1eb4b2"
										unit="px"
										duration="3s"
									></BarLoader>
								</div>
							{:then}
								{#each jobs as job (job.id)}
									<tr
										on:click={() => {
											selectedJob = job;
										}}
										class={selectedJob &&
										job.id === selectedJob.id
											? "table-warning"
											: ""}
									>
										<td
											class="text-truncate"
											title={job.projectName}
											>{job.projectName}</td
										>

										<td
											class="text-truncate {job.duration &&
											job.status === 'In Progress'
												? 'text-primary'
												: ''}"
											>{job.duration &&
											job.status === "In Progress"
												? new Date(
														(job.duration / 2 +
															job.createdOn
																.seconds -
															Math.round(
																Date.now() /
																	1000,
															)) *
															1000,
													)
														.toISOString()
														.substr(11, 8)
												: "-"}</td
										>

										<td class="align-middle">
											<div
												class="progress"
												style="width:150px"
											>
												<div
													class="progress-bar {job.progress <
													100
														? 'progress-bar-animated'
														: ''} {job.status ===
													'Failed'
														? 'bg-danger'
														: ''} {job.status ===
													'Passed'
														? 'bg-primary'
														: ''} progress-bar-striped"
													role="progressbar"
													aria-valuemin="0"
													aria-valuemax="100"
													style="width: {job.duration &&
													job.status === 'In Progress'
														? Math.min(
																99,
																(
																	((Math.round(
																		Date.now() /
																			1000,
																	) -
																		job
																			.createdOn
																			.seconds) /
																		(job.duration /
																			2)) *
																	100
																).toFixed(0),
															)
														: job.progress}%"
												>
													{job.duration &&
													job.status === "In Progress"
														? Math.min(
																99,
																(
																	((Math.round(
																		Date.now() /
																			1000,
																	) -
																		job
																			.createdOn
																			.seconds) /
																		(job.duration /
																			2)) *
																	100
																).toFixed(0),
															)
														: job.progress}%
												</div>
											</div>
										</td>

										<td
											title={job.status}
											class="text-truncate {job.status ===
											'Passed'
												? 'text-primary'
												: ''} {job.status === 'Failed'
												? 'text-danger'
												: ''}">{job.status}</td
										>
										<td
											title={job.statusMsg}
											class="text-truncate"
											>{job.statusMsg}</td
										>

										<td
											class="text-truncate"
											title={job.duration
												? new Date(job.duration * 1000)
														.toISOString()
														.substr(11, 8)
												: ""}
											>{job.duration
												? new Date(job.duration * 1000)
														.toISOString()
														.substr(11, 8)
												: ""}</td
										>
										<td
											class="text-truncate"
											title={job.completedOn
												? new Date(
														(job.completedOn
															.seconds -
															job.createdOn
																.seconds) *
															1000,
													)
														.toISOString()
														.substr(11, 8)
												: ""}
											>{job.completedOn
												? new Date(
														(job.completedOn
															.seconds -
															job.createdOn
																.seconds) *
															1000,
													)
														.toISOString()
														.substr(11, 8)
												: ""}</td
										>
										<td
											class="text-truncate"
											title={job.config.provider}
											>{job.config.provider}</td
										>
										<td title={job.config.language}
											>{job.config.language}</td
										>

										<td title={job.statusMsg}
											><i
												class="bi {!job.deleted &&
												job.status === 'Passed'
													? 'bi-check-lg text-primary'
													: 'bi-x-lg text-light'}"
											></i></td
										>
										<td
											class="text-truncate small"
											title={job.id}>{job.id}</td
										>
										<td
											class="text-truncate"
											title={job.submittedBy || email}
											>{job.submittedBy || email}</td
										>
										<td
											class="text-truncate"
											title={job.createdOn.toDate()}
											>{job.createdOn
												.toDate()
												.toString()
												.substring(0, 24)}</td
										>
										<td
											class="text-truncate"
											title={job.completedOn
												? job.completedOn.toDate()
												: ""}
											>{job.completedOn
												? job.completedOn
														.toDate()
														.toString()
														.substring(0, 24)
												: ""}</td
										>
									</tr>
								{:else}
									<p
										class="text-light text-center m-5 text-truncate"
									>
										No results to display
									</p>
								{/each}
							{/await}
						</tbody>
					</table>
				</div>
				{#if selectedJob}
					<div
						class="btn-group float-end"
						role="group"
						aria-label="Button group with nested dropdown"
					>						
						<!-- Download -->
						<div class="dropdown">
							<button
								id="downloadOptions"
								type="button"
								class="btn btn-outline-primary rounded-end-0 dropdown-toggle"
								on:click={() => {
									showDownloadOptions = !showDownloadOptions;
								}}
								disabled={loading ||
									deletingFile ||
									selectedJob.deleted ||
									selectedJob.status !== "Passed"}
							>
								<i class="bi bi-download"></i> Download
							</button>
							<ul
								class="dropdown-menu {showDownloadOptions
									? 'show'
									: ''}"
								aria-labelledby="moreOptions"
							>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() =>
											downloadSubtitle("subRip")}
										>SubRip (.srt)</a
									>
								</li>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() =>
											downloadSubtitle("webVtt")}
										>WebVtt (.vtt)</a
									>
								</li>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() =>
											downloadSubtitle("lmsTranscript")}
										>DocX (.docX)</a
									>
								</li>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() =>
											downloadSubtitle("textTranscript")}
										>Text (.txt)</a
									>
								</li>
								{#if $authState.status !== "in_trial"}
									<li>
										<a
											class="dropdown-item"
											href="#!/"
											on:click={() =>
												downloadSubtitle("raw")}
											>JSON (.json)</a
										>
									</li>
								{/if}
								{#if $authState.plan === "pro" || $authState.plan === "enterprise"}
									<li>
										<a
											class="dropdown-item"
											href="#!/"
											on:click={() =>
												downloadSubtitle("scenerist")}
											>Scenerist 1.0 (.scc)</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											href="#!/"
											on:click={() =>
												downloadSubtitle("macCaption")}
											>MacCaption (.mcc)</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											href="#!/"
											on:click={() =>
												downloadSubtitle("ebuStl")}
											>EBU STL (.stl)</a
										>
									</li>
								{/if}
								<li><hr class="dropdown-divider" /></li>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() =>
											downloadSubtitle("transcriptDocX")}
										>DocX Transcript</a
									>
								</li>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() =>
											downloadSubtitle("transcriptTxt")}
										>Text Transcript</a
									>
								</li>
								<li><hr class="dropdown-divider" /></li>
								<li>
									<a
										class="dropdown-item"
										href="#!/"
										on:click={() => downloadSubtitle(false)}
										>More...</a
									>
								</li>
							</ul>
						</div>
						<!-- Edit Subtitles -->
						<button
							type="button"
							class="btn btn-primary rounded-start-0"
							on:click={() => importTranscript("subtitle")}
							disabled={loading ||
								deletingFile ||
								selectedJob.deleted ||
								selectedJob.status !== "Passed"}
						>
							<i class="bi bi-pencil-square"></i> Edit Subtitles
						</button>
					</div>
					<div class="btn-group" role="group">
						<!-- More Options -->
						<div class="dropdown ms-2">
							<button
								id="moreOptions"
								type="button"
								class="btn btn-secondary text-white dropdown-toggle"
								on:click={() => {
									showOptions = !showOptions;
								}}
								disabled={loading ||
									deletingFile ||
									selectedJob.deleted ||
									selectedJob.status !== "Passed"}
							>
								More Options...
							</button>
							<ul
								class="dropdown-menu {showOptions
									? 'show'
									: ''}"
								aria-labelledby="moreOptions"
							>
								{#if $authState.ad}
									<li>
										<a
											class="dropdown-item"
											href="#!/"
											on:click={createAdTemplate}
											>Create AD Template</a
										>
									</li>
								{/if}
								{#if $authState.plan === "pro" || $authState.plan === "enterprise"}
									<li>
										<a
											class="dropdown-item"
											href="#!/"
											on:click={automaticSync}
											>Apply Automatic Sync</a
										>
									</li>
								{/if}
								<li><hr class="dropdown-divider"></li>
								{#if selectedJob.status !== "Passed"}
									<li>
										<a
											title="Archive Job"
											class="dropdown-item"
											href="#!/"
											disabled={deletingFile}
											on:click={archiveJob}
											>Archive Job</a
										>
									</li>
								{/if}
								{#if !selectedJob.deleted && selectedJob.status === "Passed"}
									<li>
										<a
											title="Delete Transcript File"
											class="dropdown-item text-danger"
											href="#!/"
											disabled={deletingFile}
											on:click={deleteTranscript}
											><i class="bi bi-trash"></i> Transcript</a
										>
									</li>
								{/if}
							</ul>
						</div>
						<!-- More Options End -->
					</div>
				{/if}
			</div>
		</div>
	</div>
</div>

<style>
	#TranscriptTableDashboard {
		max-height: 500px;
		margin-bottom: 2%;
	}
</style>
