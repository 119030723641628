import _balanceText from "../utility/balanceText.js";
import _convertToHtml from "../quill/convertToHtml.js";
import _Event from "../../classes/event.js";
const Event = _Event;
const convertToHtml = _convertToHtml;
const balanceText = _balanceText;
export default (function autoFormat(eventGroup, maxLines, maxChars, minDuration, allowOrphanWords, selectedEvents = null) {
  let events = [];
  eventGroup.events.forEach((ccEvent, count) => {
    if (!selectedEvents || selectedEvents.indexOf(count) > -1) {
      //console.log(`Working on event ${count}`);
      let textLines = balanceText(ccEvent.text, maxChars, maxLines);
      let numberOfEvents = Math.ceil(textLines.length / maxLines);
      //console.log(`There are ${numberOfEvents} events required for formatting of text ${textLines}`);
      let avgDuration = Math.max(minDuration, (ccEvent.end - ccEvent.start) / numberOfEvents);
      ccEvent.text = textLines.splice(0, maxLines).join("\n");
      if (!allowOrphanWords && textLines.length === 1 && textLines[0].split(" ").length === 1) {
        ccEvent.text += " " + textLines[0];
        numberOfEvents = 1;
      }
      ccEvent.text = convertToHtml(ccEvent.text);
      if (numberOfEvents > 1) {
        ccEvent.end = ccEvent.start + avgDuration;
        events.push(ccEvent);
        while (textLines.length > 0) {
          let t = textLines.splice(0, maxLines).join("\n");
          if (!allowOrphanWords && textLines.length === 1 && textLines[0].split(" ").length === 1) {
            t += " " + textLines[0];
            textLines = [];
          }
          events.push(new Event({
            ...ccEvent,
            text: convertToHtml(t),
            start: events[events.length - 1].end,
            end: events[events.length - 1].end + avgDuration
          }));
        }
      } else {
        events.push(ccEvent);
      }
    } else {
      events.push(ccEvent);
    }
  });
  eventGroup.events = events;
  return eventGroup;
});