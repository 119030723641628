import _quillClasses from "../dict/quillClasses.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _flexbox from "../dict/flexbox.js";
import _htmlEncodePlainText from "../functions/utility/htmlEncodePlainText.js";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _dlpFunc from "../functions/profiles/dlpCinema.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import { decode as _decodeHtml } from "html-entities";
import { v4 as _uuidv } from "uuid";
import _eol from "eol";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const eol = _eol;
const uuidv4 = _uuidv;
const decodeHtml = _decodeHtml; //Encode
const XMLParser = _XMLParser; //Decode
const dlpFunc = _dlpFunc;
const convertToHtml = _convertToHtml;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const htmlEncodePlainText = _htmlEncodePlainText;
const flexbox = _flexbox;
const getFormatOptions = _getFormatOptions;
const quillClasses = _quillClasses;
export default {
  decode: function (input, options) {
    let events = [];
    const xmlToJson = new XMLParser({
      stopNodes: ["*.Text"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);
    fileJson.DCSubtitle.Font.Subtitle.forEach(event => {
      //console.log(event);
      let ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.10
      });
      ccEvent.start = dlpFunc.calcTc(event["@_TimeIn"]);
      ccEvent.end = dlpFunc.calcTc(event["@_TimeOut"]);
      if (event["Text"] && Array.isArray(event["Text"])) {
        event["Text"].forEach(textLine => {
          ccEvent.text += decodeHtml(textLine["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + "\n";
          ccEvent.text = ccEvent.text.replace(/ +/gm, " ").replace(/^ /gm, "");
          //console.log(ccEvent.text);
        });
        ccEvent.alignment = event["Text"][0]["@_HAlign"] ? event["Text"][0]["@_HAlign"] : "center";
        ccEvent.xPos = event["Text"][0]["@_HAlign"] ? flexbox.alignmentMap[event["Text"][0]["@_HAlign"]] : "center";
        ccEvent.yPos = event["Text"][0]["@_VAlign"] ? flexbox.alignmentMap[event["Text"][0]["@_VAlign"]] : "end";
      } else if (event["Text"]) {
        ccEvent.text = decodeHtml(event["Text"]["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>");
        ccEvent.alignment = event["Text"]["@_HAlign"] ? event["Text"]["@_HAlign"] : "center";
        ccEvent.xPos = event["Text"]["@_HAlign"] ? flexbox.alignmentMap[event["Text"]["@_HAlign"]] : "center";
        ccEvent.yPos = event["Text"]["@_VAlign"] ? flexbox.alignmentMap[event["Text"]["@_VAlign"]] : "end";
      } else if (event["Font"]) {
        let prefix = "",
          suffix = "";
        if (event["Font"]['@_Bold'] && event["Font"]['@_Bold'] === 'yes') {
          prefix = "<b>";
          suffix = "</b>";
        }
        if (event["Font"]['@_Italic'] && event["Font"]['@_Italic'] === 'yes') {
          prefix += "<i>";
          suffix = "</i>" + suffix;
        }
        if (event["Font"]['@_Underline'] && event["Font"]['@_Underline'] === 'yes') {
          prefix += "<u>";
          suffix = "</u>" + suffix;
        }
        if (Array.isArray(event["Font"]["Text"])) {
          event["Font"]["Text"].forEach(textLine => {
            ccEvent.text += prefix + decodeHtml(textLine["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + suffix + "\n";
          });
          ccEvent.alignment = event["Font"]["Text"][0]["@_HAlign"] ? event["Font"]["Text"][0]["@_HAlign"] : "center";
          ccEvent.xPos = event["Font"]["Text"][0]["@_HAlign"] ? flexbox.alignmentMap[event["Font"]["Text"][0]["@_HAlign"]] : "center";
          ccEvent.yPos = event["Font"]["Text"][0]["@_VAlign"] ? flexbox.alignmentMap[event["Font"]["Text"][0]["@_VAlign"]] : "end";
        } else if (event["Font"]["Text"]) {
          ccEvent.text = prefix + decodeHtml(event["Font"]["Text"]["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + suffix;
          ccEvent.alignment = event["Font"]["Text"]["@_HAlign"] ? event["Font"]["Text"]["@_HAlign"] : "center";
          ccEvent.xPos = event["Font"]["Text"]["@_HAlign"] ? flexbox.alignmentMap[event["Font"]["Text"]["@_HAlign"]] : "center";
          ccEvent.yPos = event["Font"]["Text"]["@_VAlign"] ? flexbox.alignmentMap[event["Font"]["Text"]["@_VAlign"]] : "end";
        }
      }
      ccEvent.text = convertToHtml(ccEvent.text, [quillClasses.align[ccEvent.alignment]]);
      if (ccEvent.yPos === "start") {
        ccEvent.yOffset = options.window.height * 0.10;
      }
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let fadeUpTime = encodingOptions["Fade Up Time"];
    let fadeDownTime = encodingOptions["Fade Down Time"];
    let verticalStart = encodingOptions["Vertical Position Start"];
    let verticalSpacing = encodingOptions["Vertical Spacing"] || 6;
    if (fadeUpTime === undefined) {
      fadeUpTime = 20;
    }
    if (fadeDownTime === undefined) {
      fadeDownTime = 20;
    }
    if (verticalStart === undefined) {
      verticalStart = 10;
    } else {
      verticalStart = parseFloat(verticalStart);
    }
    verticalSpacing = parseFloat(verticalSpacing);
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<!-- *** Created by Closed Caption Converter 3 *** -->`);
    output += eol.after(`<DCSubtitle Version="1.0">`);
    output += eol.after(`<SubtitleID>${uuidv4()}</SubtitleID>`);
    output += eol.after(`<MovieTitle>${encodingOptions["Title"] || ''}</MovieTitle>`);
    output += eol.after(`<ReelNumber>${encodingOptions["Reel Number"] || '1'}</ReelNumber>`);
    output += eol.after(`<Language>${encodingOptions["Language Code"] || 'en'}</Language>`);
    output += eol.after(`<LoadFont Id="Arial" URI="arial.ttf"/>`);
    output += eol.after(`<Font Id="Arial" Color="FFFFFFFF" Effect="border" EffectColor="FF000000" Size="42" Weight="normal">`);
    eventGroup.events.forEach((event, id) => {
      let start = tcLib.secToTicksSec(event.start);
      let end = tcLib.secToTicksSec(event.end);
      let text = htmlEncodePlainText(convertToPlainTextCustom(event.text));
      //console.log(text+"\n");
      output += eol.after(`<Subtitle SpotNumber="${(id + 1).toString().padStart(4, "0")}" TimeIn="${start}" TimeOut="${end}" FadeUpTime="${fadeUpTime}" FadeDownTime="${fadeDownTime}">`);
      text.split("\n").forEach((line, count, lines) => {
        let vAlign = flexbox.flexMap.vertical[event.yPos];
        let hAlign = flexbox.flexMap.horizontal[event.xPos];
        let hPos = "0.0";
        let vPos;
        if (event.yPos === "start") {
          vPos = count * verticalSpacing + verticalStart;
        } else if (event.yPos === "center") {
          vPos = count * verticalSpacing + verticalStart;
        } else {
          vPos = lines.length === 1 ? verticalStart : (lines.length - 1) * verticalSpacing + verticalStart - count * verticalSpacing;
        }
        output += eol.after(`<Text HAlign="${hAlign}" HPosition="${hPos}" VAlign="${vAlign}" VPosition="${vPos.toFixed(1)}">${line.replace(/<em>|<strong>|<u>/gmi, '<Font Italic="yes">').replace(/<\/em>|<\/strong>|<\/u>/gmi, "</Font>")}</Text>`);
      });
      output += eol.after(`</Subtitle>`);
    });
    output += eol.after("</Font>");
    output += eol.after("</DCSubtitle>");
    return output;
    //return xmlFormat(output);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};