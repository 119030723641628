import _calcTimecodesByCps from "../calcTimecodesByCps.js";
const calcTimecodesByCps = _calcTimecodesByCps;
export default (async function calculateMissingTimecodes(eventGroup) {
  let processing = true;
  let driftAmount, timecodeValues;
  while (processing) {
    processing = false;
    for (let i = 0; i < eventGroup.events.length; i++) {
      if (!eventGroup.events[i].end) {
        eventGroup.events[i].approved = false;
        if (i === 0) {
          //first event
          if (!eventGroup.events[i + 1].start) {
            processing = true;
          } else if (eventGroup.events[i + 1].oStart && eventGroup.events[i].oEnd) {
            driftAmount = eventGroup.events[i + 1].oStart - eventGroup.events[i].oEnd;
            eventGroup.events[i].end = eventGroup.events[i + 1].start - driftAmount;
            eventGroup.events[i].start = eventGroup.events[i].end - eventGroup.events[i].oDuration;
          } else {
            console.log("Calculating timecodes using CPS");
            timecodeValues = calcTimecodesByCps(eventGroup.events[i], 22, false, eventGroup.events[i + 1].start);
            eventGroup.events[i].start = timecodeValues.start;
            eventGroup.events[i].end = timecodeValues.end;
          }
        } else if (i === eventGroup.events.length - 1) {
          //last event
          if (!eventGroup.events[i - 1].end) {
            processing = true;
          } else if (eventGroup.events[i].oStart && eventGroup.events[i - 1].oEnd) {
            driftAmount = eventGroup.events[i].oStart - eventGroup.events[i - 1].oEnd;
            eventGroup.events[i].start = eventGroup.events[i - 1].end + driftAmount;
            eventGroup.events[i].end = eventGroup.events[i].start + eventGroup.events[i].oDuration;
          } else {
            console.log("Calculating timecodes using CPS");
            timecodeValues = calcTimecodesByCps(eventGroup.events[i], 22, eventGroup.events[i - 1].end, false);
            eventGroup.events[i].start = timecodeValues.start;
            eventGroup.events[i].end = timecodeValues.end;
          }
        } else {
          //middle event
          if (eventGroup.events[i].oStart && eventGroup.events[i].oEnd) {
            //Caption File Mode
            let lastEventGap = eventGroup.events[i - 1].end ? eventGroup.events[i].oStart - eventGroup.events[i - 1].oEnd : false;
            let nextEventGap = eventGroup.events[i + 1].start ? eventGroup.events[i + 1].oStart - eventGroup.events[i].oEnd : false;
            if (!lastEventGap && nextEventGap || nextEventGap < lastEventGap && nextEventGap) {
              driftAmount = eventGroup.events[i + 1].oStart - eventGroup.events[i].oEnd;
              eventGroup.events[i].end = eventGroup.events[i + 1].start - driftAmount;
              eventGroup.events[i].start = eventGroup.events[i].end - eventGroup.events[i].oDuration;
            } else if (lastEventGap) {
              driftAmount = eventGroup.events[i].oStart - eventGroup.events[i - 1].oEnd;
              eventGroup.events[i].start = eventGroup.events[i - 1].end + driftAmount;
              eventGroup.events[i].end = eventGroup.events[i].start + eventGroup.events[i].oDuration;
            } else {
              processing = true;
            }
          } else {
            console.log("Calculating timecodes using CPS");
            let startTimeOfNextEvent = eventGroup.events[i + 1] ? eventGroup.events[i + 1].start : false;
            let endTimeOfLastEvent = eventGroup.events[i - 1] ? eventGroup.events[i - 1].end : false;
            if (startTimeOfNextEvent && endTimeOfLastEvent) {
              timecodeValues = calcTimecodesByCps(eventGroup.events[i], 22, endTimeOfLastEvent, startTimeOfNextEvent);
              eventGroup.events[i].start = timecodeValues.start;
              eventGroup.events[i].end = timecodeValues.end;
            } else if (startTimeOfNextEvent) {
              timecodeValues = calcTimecodesByCps(eventGroup.events[i], 22, false, startTimeOfNextEvent);
              eventGroup.events[i].start = timecodeValues.start;
              eventGroup.events[i].end = timecodeValues.end;
            } else if (endTimeOfLastEvent) {
              timecodeValues = calcTimecodesByCps(eventGroup.events[i], 22, endTimeOfLastEvent, false);
              eventGroup.events[i].start = timecodeValues.start;
              eventGroup.events[i].end = timecodeValues.end;
            } else {
              processing = true;
            }
          }
        }
      }
    }
    ;
  }
  return eventGroup;
});