<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
export let eventIndex;

function removeSpeaker(speaker){
$eventGroupState[$projectState.selected].events[eventIndex].recipients = $eventGroupState[$projectState.selected].events[eventIndex].recipients.filter(sp =>{
    return sp.id !== speaker.id;
});

historyState.insert({
    name: "remove speaker", //action name
    eventGroup: $projectState.selected,
    snapshots: [{
        store: "eventGroupState",
        value: JSON.stringify($eventGroupState),
    }, ],
}); 
}
</script>

<div class="col-4 border-start border-end">
    <div class="speakerWrapper">
        {#each $eventGroupState[$projectState.selected].events[eventIndex].recipients || [] as speaker}
            <span class="badge m-1 text-custom-small" style="background-color: {speaker.colour}">
                <a href="#!/" class="text-dark" on:click="{() => removeSpeaker(speaker)}"><i class="bi bi-x"></i></a>
                {speaker.name}</span>
        {:else}
        <span class="text-custom-small text-muted">No Recipient(s) Assigned</span>
        {/each}
    </div>
</div>

<style>
.speakerWrapper {
    overflow-x: auto;
    white-space: nowrap;
}
</style>
