export default [{
  "displayName": "English (US)",
  "languageCode": "en_US"
}, {
  "displayName": "English (UK)",
  "languageCode": "en_GB"
}, {
  "displayName": "English (CA)",
  "languageCode": "en_CA"
}, {
  "displayName": "English (AU)",
  "languageCode": "en_AU"
}, {
  "displayName": "Spanish (Spain)",
  "languageCode": "es_ES"
}, {
  "displayName": "Spanish (Mexico)",
  "languageCode": "es_MX"
}, {
  "displayName": "Spanish (Argentina)",
  "languageCode": "es_AR"
}, {
  "displayName": "Spanish (Colombia)",
  "languageCode": "es_CO"
}, {
  "displayName": "French",
  "languageCode": "fr_FR"
}, {
  "displayName": "German",
  "languageCode": "de"
}, {
  "displayName": "Danish (Denmark)",
  "languageCode": "da_DK"
}, {
  "displayName": "Italian",
  "languageCode": "it_IT"
}, {
  "displayName": "Polist (Poland)",
  "languageCode": "pl_PL"
}, {
  "displayName": "Portuguese (Brazil)",
  "languageCode": "pt_BR"
}, {
  "displayName": "Portuguese (Portugal)",
  "languageCode": "pt_PT"
}, {
  "displayName": "Dutch",
  "languageCode": "nl_NL"
}, {
  "displayName": "Russian",
  "languageCode": "ru_RU"
}];