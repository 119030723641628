<script>
import {
    fade
} from "svelte/transition";
import {
    modalState
} from "@app/store/modalStore.js";
import { toast } from '@zerodevx/svelte-toast';
import {authState} from '@app/store/authStore.js';
import {
    BarLoader
} from 'svelte-loading-spinners';
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

import {v4 as uuidv4} from 'uuid';

let email = firebase.auth().currentUser.email,
    uid = firebase.auth().currentUser.uid,
    selectedUser = {},
    users = [],
    dbWork;
function sortUsers(){
    console.log(users);
    users.sort((a, b) => {
        if (a.firstName){
            if (b.firstName){
                return a.firstName.localeCompare(b.firstName, undefined, {sensitivity: 'base'})
            } else {
                return a.firstName.localeCompare(b.email, undefined, {sensitivity: 'base'})
            }
        } else {
            if (b.firstName){
                return a.email.localeCompare(b.firstName, undefined, {sensitivity: 'base'})
            } else {
                return a.email.localeCompare(b.email, undefined, {sensitivity: 'base'})
            }
        }        
    });
}
async function createTeam(){
    try {
        dbWork = await firebase.functions().httpsCallable('v8CreateTeam')({
            id : uuidv4(),
            owner : email,
            ownerId : uid,
            members : [email],
        });

        console.log("Create Team Data", dbWork.data);
        authState.updateTeam(dbWork.data.team);
        authState.updateUser(dbWork.data.users[0]);

        users = dbWork.data.users;
        sortUsers();
        toast.push("New team created successfully", {classes: ['toast-success']});
    } catch(err){
        console.log("Failed to create team");
        console.error(err,err.message);
        toast.push(`Error creating new team. ${err.message}`, {classes: ['toast-danger']});
    }    
}

async function createUser(){
    users = [...users, {
        id : uuidv4(),
        firstName : "",
        lastName : "",
        email : "",
        admin : false,
        disable : false,
        role : "editor",
        new : true
    }];
    
    selectedUser = users[users.length-1];

    toast.push("New user created", {classes: ['toast-success']});
}

async function removeUser(){
    if (!selectedUser.id){
        return;
    }

    try { 
        let res = await firebase.functions().httpsCallable('v8RemoveUser')({
            tId : $authState.team.id,
            uId : selectedUser.id,
            email : selectedUser.email
        });

        authState.updateTeam({
            ...$authState.team,
            members : $authState.team.members.filter(member =>{
                return member !== selectedUser.email;
            })
        });

        console.log($authState);

        toast.push("User was successfully removed", {classes: ['toast-success']});

    } catch(err){
        console.log(err,err.message);
        toast.push(`Faild to remove user from team. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        return setup();
    }
}

async function saveUser(){
    try {
        if (!selectedUser.email){
            throw new Error("Email is required.");
        }

        let existingUserCheck = users.filter(user => {
            return user.email === selectedUser.email;
        });

        if (existingUserCheck.length > 1){
            throw new Error("Email already exists in user group.");
        }

        dbWork = await firebase.functions().httpsCallable('v8AddOrUpdateUser')({
            tId : $authState.team.id,
            uId : selectedUser.id,
            firstName : selectedUser.firstName,
            lastName : selectedUser.lastName,
            email : selectedUser.email,
            admin : selectedUser.admin,
            disable : selectedUser.disable,
            role : "editor" 
        });

        authState.updateTeam({
            ...$authState.team,
            members : [...$authState.team.members, selectedUser.email]
        });

        console.log($authState);
        
        toast.push("User record saved successfully", {classes: ['toast-success']});
    } catch(err){
        toast.push(`Error saving user. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        return setup();
    }
}

async function setup(){
    selectedUser = {};
    let getUsersRes = await firebase.functions().httpsCallable('v8GetUsers')($authState.team.id);
    /* Debug */
    //console.log("User List", getUsersRes.data);
    users = getUsersRes.data;
    sortUsers();
}

if ($authState.user && $authState.user.admin){
    dbWork = setup();
}
</script>
<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'teamManager' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="TeamManagerModal">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Team Manager</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                />
            </div>
            <div class="modal-body">
                {#if !$authState.team}
                    {#await dbWork}
                        <div class="d-flex justify-content-center align-items-center p-3">
                            <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                        </div>       
                    {:then}
                        <div class="d-flex justify-content-center align-items-center p-3">
                            <div>
                                <p>Please create a new team to begin.</p>
                                <button type="button" class="btn btn-primary w-100" on:click={() => {dbWork = createTeam()}}>Create Team</button>
                            </div>                        
                        </div>
                    {/await}
                {:else if $authState.user.admin}
                    <div class="row">
                        <div class="col-4">
                            <h4>Users
                                <span class="badge rounded-pill bg-secondary d-xl-flex justify-content-xl-end align-items-xl-center float-end fs-6">{users.length} user(s)</span>
                            </h4>
                            {#await dbWork}
                                <div class="d-flex justify-content-center align-items-center p-3">
                                    <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                                </div>       
                            {:then}
                                <select class="w-100" size="15" bind:value={selectedUser}>
                                    {#each users as user}
                                        <option class="{user.disable ? 'text-muted' : ''} {user.admin ? 'text-info' : ''}" value={user}>{user.firstName ? user.firstName + " " + user.lastName : user.email}</option>
                                    {/each}
                                </select>
                                <div class="d-xl-flex justify-content-xl-end py-2">
                                    <div class="btn-group" role="group">
                                        <button title="Add New User" class="btn btn-primary" type="button" on:click="{() => {dbWork = createUser()}}">
                                            <i class="bi bi-plus-lg"></i> New User
                                        </button>
                                        <button title="Remove User" class="btn btn-light" type="button" on:click="{() => {dbWork = removeUser()}}">
                                            <i class="bi bi-dash-lg"></i> Delete User
                                        </button>
                                    </div>
                                </div>
                            {/await}
                        </div>
                        <div class="col">
                            <div class="bg-secondary border rounded border-secondary shadow p-3">
                                <form>
                                    <div class="mb-3">
                                        <label class="form-label" for="UserFastNameInput">First Name</label>
                                        <input id="UserFastNameInput" class="form-control {!selectedUser.id ? 'text-muted bg-light' : ''}" type="text" placeholder="First Name" disabled={!selectedUser.id} bind:value={selectedUser.firstName}/>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label" for="UserLastNameInput">Last Name</label>
                                        <input id="UserLastNameInput" class="form-control {!selectedUser.id ? 'text-muted bg-light' : ''}" type="text" placeholder="Last Name" disabled={!selectedUser.id} bind:value={selectedUser.lastName}/></div>
                                    <div class="mb-3">
                                        <label class="form-label" for="UserEmailInput">Email</label>
                                        <input id="UserEmailInput" class="form-control {!selectedUser.id || !selectedUser.new ? 'text-muted bg-light' : ''}" type="email" placeholder="Email" disabled={!selectedUser.id || !selectedUser.new} bind:value={selectedUser.email}/>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <div class="form-check form-switch col-4">
                                                <input class="form-check-input" type="checkbox" id="adminCheck" disabled={!selectedUser.id} bind:checked={selectedUser.admin}>
                                                <label class="form-check-label" for="adminCheck">Admin</label>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-check form-switch col-4">
                                                <input class="form-check-input" type="checkbox" id="UseDisableCheck" disabled={!selectedUser.id} bind:checked={selectedUser.disable}/>
                                                <label class="form-check-label" for="UseDisableCheck">Disable</label>
                                            </div>
                                        </div>
                                    </div>
                                    {#await dbWork}
                                        <div class="d-flex justify-content-center align-items-center p-3">
                                            <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                                        </div>       
                                    {:then}
                                    <div class="d-xl-flex justify-content-xl-end">
                                        <div class="btn-group" role="group">
                                            <button class="btn btn-primary" type="button" disabled={!selectedUser.id} on:click="{() => {dbWork = saveUser()}}">Save User</button>
                                            <button class="btn btn-outline-dark" type="button" disabled={!selectedUser.id} on:click={() => {selectedUser = {}; dbWork = setup()}}>Cancel</button>
                                        </div>
                                    </div>
                                    {/await}
                                </form>
                            </div>
                        </div>
                    </div>
                {:else}
                    <div class="d-flex justify-content-center align-items-center p-3">
                        <div>
                            <p class="m-0">You do not have permission to manage your team.</p>
                            <p>Please contact your system administrator for help.</p>
                            <button type="button" class="btn btn-light w-100" on:click={modalState.hideModal}><i class="bi bi-x-lg"></i> Close</button>
                        </div>                        
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>