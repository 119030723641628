<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    uiState
} from '@app/store/uiStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    externalDataState
} from "@app/store/externalDataStore.js";
import {
    environment
} from '@app/store/envStore.js';
import FileMenu from './menus/FileMenu.svelte';
import EditMenu from './menus/EditMenu.svelte';
import FormatMenu from './menus/FormatMenu.svelte';
import TimecodeMenu from './menus/TimecodeMenu.svelte';
import InsertMenu from './menus/InsertMenu.svelte';
import AiToolsMenu from './menus/AiToolsMenu.svelte';
import WorkspacesMenu from './menus/WorkspacesMenu.svelte';
import TeamMenu from './menus/TeamMenu.svelte';
import ThemeMenu from './menus/ThemeMenu.svelte';
import HelpMenu from './menus/HelpMenu.svelte';
import CliMenu from './menus/CliMenu.svelte';
import { clickOutside } from 'svelte-use-click-outside';
let cachedMenu;
let menuState = {
    file: false,
    edit: false,
    format: false,
    timecode: false,
    workspaces : false,
    theme : false,
    insert: false,
    aiTools: false,
    team: false,
    help: false,
    drastic: false
};

function updateMenu(event) {
    closeMenus();
    setTimeout(() => {
        menuState[event.detail.menu] = !menuState[event.detail.menu];
        cachedMenu = event.detail.menu;
    }, 0);
}

function closeMenus() {
    if (cachedMenu) {
        menuState = {
            file: false,
            edit: false,
            format: false,
            timecode: false,
            insert: false,
            aiTools: false,
            team: false,
            help: false,
            theme: false,
            workspaces : false,
            cli: false
        };

        cachedMenu = undefined;
    }
}
</script>

<nav aria-label="Primary Toolbar" class="navbar navbar-expand bg-light shadow" style="height: 3vh;" use:clickOutside={closeMenus}>
    <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navcol-1">
            <ul class="nav navbar-nav">
                <FileMenu currentState={menuState.file} on:toggleMenu={updateMenu}></FileMenu>
                <EditMenu currentState={menuState.edit} on:toggleMenu={updateMenu}></EditMenu>
                <FormatMenu currentState={menuState.format} on:toggleMenu={updateMenu}></FormatMenu>
                <TimecodeMenu currentState={menuState.timecode} on:toggleMenu={updateMenu}></TimecodeMenu>
                <InsertMenu currentState={menuState.insert} on:toggleMenu={updateMenu}></InsertMenu>
                <AiToolsMenu currentState={menuState.aiTools} on:toggleMenu={updateMenu}></AiToolsMenu>
                <WorkspacesMenu currentState={menuState.workspaces} on:toggleMenu={updateMenu}></WorkspacesMenu>
                {#if $environment.electron}
                <CliMenu currentState={menuState.cli} on:toggleMenu={updateMenu}></CliMenu>
                {/if}
                <HelpMenu currentState={menuState.help} on:toggleMenu={updateMenu}></HelpMenu>
            </ul>
            <ul class="nav navbar-nav ms-auto">
                <div class="vr align-self-center"></div>
                <!-- <li class="nav-item" title="Show/Hide KNP">
                    <a class="nav-link {$uiState.timeline ? 'text-primary' : 'text-muted'}" href="#!/" on:click={() => $uiState.timeline = !$uiState.timeline}><i class="bi bi-journal-bookmark"></i></a>
                </li> -->
                <ThemeMenu currentState={menuState.theme} on:toggleMenu={updateMenu}></ThemeMenu> 
                <li class="nav-item" title="Show/Hide Timeline">
                    <a class="nav-link {$uiState.timeline ? 'text-primary' : 'text-muted'}" href="#!/" on:click={() => $uiState.timeline = !$uiState.timeline}><i class="bi bi-input-cursor"></i></a>
                </li>
                

                {#if $projectState.type === "team"}
                <div class="vr align-self-center"></div>
                <TeamMenu></TeamMenu>
                {/if}

                {#if $externalDataState.platform === "Iconik"}
                <div class="vr align-self-center"></div>
                <li class="nav-item" title="Publish work to Iconik">
                    <a class="nav-link fw-bold" style="color: #00E1BE !important;" href="#!/" on:click={() => modalState.showModal("iconikPublish")}><img width="15px" src="./assets/img/iconik-icon.ico" alt="iconik logo"> Publish</a>
                </li>
                {:else if $externalDataState.platform === "Work Order"}
                <div class="vr align-self-center"></div>
                <li class="nav-item" title="Publish work order">
                    <a class="nav-link fw-bold" href="#!/" on:click={() => modalState.showModal("workOrderPublish")}><i class="bi bi-file-arrow-up"></i> Publish</a>
                </li>
                {:else if $environment.online}
                <div class="vr align-self-center"></div>
                <li class="nav-item" title="Live Support Chat">
                    <a class="nav-link" href="https://chatting.page/qaabxezhqhnaqf53cf3ylbzryxamrn8o" target="_blank" rel="noreferrer"><i class="bi bi-life-preserver"></i></a>
                </li>
                {/if}                              
            </ul>
        </div>
    </div>
</nav>
