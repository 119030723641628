<script>
    import { editorState } from "@app/store/editorStore.js";
    import { toast } from "@zerodevx/svelte-toast";

    function updateEditorSettings() {
        localStorage.setItem(
            "cc-editor-defaults",
            JSON.stringify($editorState),
        );
        toast.push("Settings have been saved successfully.", {
            classes: ["toast-success"],
        });
    }
</script>

<h5>Editor Settings</h5>
<p class="small text-muted">
    Note: The following settings may only apply when utilizing shortcut keys to
    perform the action.
</p>
<form on:submit|preventDefault={updateEditorSettings()}>
    <!-- Transcription -->
    <div class="row">
        <p class="lead text-warning">Transcription</p>
        <div class="col-4">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="insertStartCheck"
                    bind:checked={$editorState.insertStartOnInsert}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="insertStartCheck"
                    >Insert Start TC On Insert <i
                        class="bi bi-info-circle"
                        title="When inserting a new event, the start timecode will automatically be set based on the video timecode."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="insertEndCheck"
                    bind:checked={$editorState.insertEndOnInsert}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="insertEndCheck"
                    >Insert End TC On Insert <i
                        class="bi bi-info-circle"
                        title="When a new event is inserted, the end timecode of the previous event will be set based on the video timecode."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="autoLineBreakCheck"
                    bind:checked={$editorState.autoLineBreak}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="autoLineBreakCheck"
                    >Automatically Insert Line Breaks <i
                        class="bi bi-info-circle"
                        title="The editor will automatically insert line breaks based on the maximum characters per line found in the Event Group error settings."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <!-- Auto Insert Event -->
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="autoInsertEventCheck"
                    bind:checked={$editorState.autoInsertEvent}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="autoInsertEventCheck"
                    >Automatically Insert New Event <i
                        class="bi bi-info-circle"
                        title="The editor will automatically insert a new event based on the maximum number of lines found in the Event Group error settings."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <!-- Pause While Typing -->
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="pauseWhileTypingCheck"
                    bind:checked={$editorState.pauseWhileTyping}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="pauseWhileTypingCheck"
                    >Pause While Typing <i
                        class="bi bi-info-circle"
                        title="Automatically pause/resume playback when transcribing."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3">
                <label class="form-label" for="pauseWhileTypeDuration"
                    >Pause Duration (ms)</label
                >
                <input
                    class="form-control"
                    type="number"
                    min="1"
                    step="1"
                    bind:value={$editorState.pauseWhileTypeDuration}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
            </div>
        </div>
    </div>
    <!-- Timing & Sync -->
    <div class="row">
        <p class="lead text-warning">Timing & Sync</p>
        <div class="col-4">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="selectNextEventCheck"
                    bind:checked={$editorState.selectNextEvent}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="selectNextEventCheck"
                    >Auto Select Next Event On Start Time Update <i
                        class="bi bi-info-circle"
                        title="Automatically select the next event when the start timecode is updated."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="autoChainCheck"
                    bind:checked={$editorState.autoChain}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="autoChainCheck"
                    >Auto Chain Events On Start Time Update <i
                        class="bi bi-info-circle"
                        title="The end time of the previous event will automatically update to match the start time of the selected event."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3">
                <label class="form-label" for="minFrameGap"
                    >Min. Frame Gap</label
                >
                <input
                    class="form-control"
                    type="number"
                    min="0"
                    step="1"
                    bind:value={$editorState.minFrameGap}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
            </div>
        </div>
    </div>
    <!-- Timeline -->
    <div class="row">
        <p class="lead text-warning">Timeline</p>
        <div class="col-4">
            <!-- Snap To Shot Changes -->
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="snapToShotChangesCheck"
                    bind:checked={$editorState.snapToShotChanges}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="snapToShotChangesCheck"
                    >Snap to Shot Changes <i
                        class="bi bi-info-circle"
                        title="Automatically snap to shot changes when updating Timeline Events."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <!-- Draw Events -->
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="drawEventsCheck"
                    bind:checked={$editorState.drawEvents}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="drawEventsCheck"
                    >Click-n-Drag to Create Events <i
                        class="bi bi-info-circle"
                        title="Create new Events by click-n-dragging on the Timeline."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <!-- Timeline Overlap Protection -->
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="timelineOverlapProtectionCheck"
                    bind:checked={$editorState.timelineOverlapProtection}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="timelineOverlapProtectionCheck"
                    >Overlap Protection <i
                        class="bi bi-info-circle"
                        title="Prevent events from overlapping in the Timeline."
                    ></i></label
                >
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3">
                <label class="form-label" for="timelineFontSize"
                    >Font Size (px)</label
                >
                <input
                    class="form-control w-50"
                    type="number"
                    min="1"
                    step="1"
                    bind:value={$editorState.timelineFontSize}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
            </div>
        </div>
        <div class="col-4">
            <div class="mb-3">
                <label class="form-label" for="timelineFontColor"
                    >Font Color</label
                >
                <input
                    class="form-control w-50"
                    type="color"
                    bind:value={$editorState.timelineFontColor}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
            </div>
        </div>
    </div>
    <!-- Paste Settings -->
     <div class="row">
        <p class="lead text-warning">Paste Settings</p>
        <div class="col-4">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="AutoFormatTextCheck"
                    bind:checked={$editorState.autoFormatText}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />

                <label class="form-check-label" for="AutoFormatTextCheck"
                    >Auto-Format Text</label
                >
            </div>
        </div>
     </div>
    <!-- Audio Description -->
    <div class="row">
        <p class="lead text-warning">Audio Description</p>
        <div class="col-4">
            <!-- Auto Trim Event -->
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="autoTrimEventCheck"
                    bind:checked={$editorState.autoTrimEvent}
                    on:change={() => {
                        updateEditorSettings();
                    }}
                />
                <label class="form-check-label" for="autoTrimEventCheck"
                    >Auto Trim Event Time <i
                        class="bi bi-info-circle"
                        title="Automatically adjust the outcode of Audio Description Events when virtual audio rendering completes."
                    ></i></label
                >
            </div>
        </div>
    </div>
</form>
