import _convertToPlainText from "../quill/convertToPlainText.js";
import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
const convertToPlainText = _convertToPlainText;
export default {
  formatEventsForExport: function (eventGroup, exportEventId, exportSpeakers, exportRecipients, exportTags, exportNotes, exportReply, tcFormat, exportStartTime, exportEndTime, exportDuration, frameRate, dropFrame) {
    let formattedEvents;
    formattedEvents = eventGroup.events.map((event, index) => {
      let e = {};
      try {
        if (exportEventId === "yes") {
          e.Shot = index + 1;
        }
        if (exportStartTime === "yes") {
          if (tcFormat === "SMPTE") {
            e.Start = tcLib.secToTc(event.start, frameRate, dropFrame);
          } else {
            e.Start = tcLib.secToTcMs(event.start);
          }
        }
        if (exportEndTime === "yes") {
          if (tcFormat === "SMPTE") {
            e.End = tcLib.secToTc(event.end, frameRate, dropFrame);
          } else {
            e.End = tcLib.secToTcMs(event.end);
          }
        }
        if (exportDuration === "yes") {
          if (tcFormat === "SMPTE") {
            e.Duration = tcLib.secToTc(event.end - event.start, frameRate, dropFrame);
          } else {
            e.Duration = tcLib.secToTcMs(event.end - event.start);
          }
        }
        if (exportSpeakers === "yes") {
          e.Speaker = event.speakers.map(s => {
            return s.name;
          }).join();
        }
        if (exportRecipients === "yes") {
          e.Recipient = event.recipients.map(r => {
            return r.name;
          }).join();
        }
        e.Script = convertToPlainText(event.text);
        if (exportTags === "yes") {
          e.Tags = event.tags.map(t => {
            return t.term;
          }).join();
        }
        if (exportNotes === "yes") {
          e["On Screen Note"] = event.notes;
        }
        if (exportReply === "yes") {
          e["QC Note"] = event.reply;
        }
      } catch (err) {
        console.log(err, err.message);
        e = undefined;
      }
      return e;
    });
    return formattedEvents.filter(e => {
      return e;
    });
  }
};