import _eol from "eol";
import _getLineCount from "../quill/getLineCount.js";
import _extractStyleAttributes from "../quill/extractStyleAttributes.js";
import _convertToPlainTextCustom from "../quill/convertToPlainTextCustom.js";
import _tcLib from "../../lib/timecode.js";
import _cptable from "codepage";
import _hex2ascii from "hex2ascii";
const hex2ascii = _hex2ascii;
const cptable = _cptable;
const tcLib = _tcLib;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const extractStyleAttributes = _extractStyleAttributes;
const getLineCount = _getLineCount;
const eol = _eol;
export default {
  languageCodeMapping: {
    "00": "Unknown/not applicable",
    "16": "Lappish",
    "01": "Albanian",
    "17": "Latin",
    "02": "Breton",
    "18": "Latvian",
    "03": "Catalan",
    "19": "Luxembourgian",
    "04": "Croatian",
    "1A": "Lithuanian",
    "05": "Welsh",
    "1B": "Hungarian",
    "06": "Czech",
    "1C": "Maltese",
    "07": "Danish",
    "1D": "Dutch",
    "08": "German",
    "1E": "Norwegian",
    "09": "English",
    "1F": "Occitan",
    "0A": "Spanish",
    "20": "Polish",
    "0B": "Esperanto",
    "21": "Portugese",
    "0C": "Estonian",
    "22": "Romanian",
    "0D": "Basque",
    "23": "Romansh",
    "0E": "Faroese",
    "24": "Serbian",
    "0F": "French",
    "25": "Slovak",
    "10": "Frisian",
    "26": "Slovenian",
    "11": "Irish",
    "27": "Finnish",
    "12": "Gaelic",
    "28": "Swedish",
    "13": "Galician",
    "29": "Turkish",
    "14": "Icelandic",
    "2A": "Flemish",
    "15": "Italian",
    "2B": "Wallon",
    "7F": "Amharic",
    "69": "Japanese",
    "53": "Shona",
    "7E": "Arabic",
    "68": "Kannada",
    "52": "Sinhalese",
    "7D": "Armenian",
    "67": "Kazakh",
    "51": "Somali",
    "7C": "Assamese",
    "66": "Khmer",
    "50": "Sranan Tongo",
    "7B": "Azerbaijani",
    "65": "Korean",
    "4F": "Swahili",
    "7A": "Bambora",
    "64": "Laotian",
    "4E": "Tadzhik",
    "79": "Bielorussian",
    "63": "Macedonian",
    "4D": "Tamil",
    "78": "Bengali",
    "62": "Malagasay",
    "4C": "Tatar",
    "77": "Bulgarian",
    "61": "Malaysian",
    "4B": "Telugu",
    "76": "Burmese",
    "60": "Moldavian",
    "4A": "Thai",
    "75": "Chinese",
    "5F": "Marathi",
    "49": "Ukrainian",
    "74": "Churash",
    "5E": "Ndebele",
    "48": "Urdu",
    "73": "Dari",
    "5D": "Nepali",
    "47": "Uzbek",
    "72": "Fulani",
    "5C": "Oriya",
    "46": "Vietnamese",
    "71": "Georgian",
    "5B": "Papamiento",
    "45": "Zulu",
    "70": "Greek",
    "5A": "Persian",
    "6F": "Gujurati",
    "59": "Punjabi",
    "6E": "Gurani",
    "58": "Pushtu",
    "6D": "Hausa",
    "57": "Quechua",
    "6C": "Hebrew",
    "56": "Russian",
    "6B": "Hindi",
    "55": "Ruthenian",
    "6A": "Indonesian",
    "54": "Serbo-croat"
  },
  codePageMapping: {
    "United States": 437,
    "Multilingual": 850,
    "Portuguese": 860,
    "Canada-French": 863,
    "Nordic": 865,
    "Latin": 20269,
    "Cyrillic": 28595,
    "Arabic": 28596,
    "Greek": 28597,
    "Hebrew": 28598
  },
  charCodeTblMap: {
    "3030": "Latin",
    "3031": "Cyrillic",
    "3032": "Arabic",
    "3033": "Greek",
    "3034": "Hebrew",
    "Latin": "3030",
    "Cyrillic": "3031",
    "Arabic": "3032",
    "Greek": "3033",
    "Hebrew": "3034",
    "United States": "343337",
    "Multilingual": "383530",
    "Portuguese": "383630",
    "Canada-French": "383633",
    "Nordic": "383635"
  },
  frameRateMapping: {
    "25": "53544c32352e3031",
    "29.97": "53544c33302e3031",
    "53544c32352e3031": 25,
    "53544c33302e3031": 29.97
  },
  dscMapping: {
    "Undefined": "20",
    "Open subtitling": "30",
    "Level-1 teletext": "31",
    "Level-2 teletext": "32"
  },
  controlCodeMapping: {
    "80": "<i>",
    "81": "</i>",
    "82": "<u>",
    "83": "</u>",
    "84": "<b>",
    "85": "</b>"
  },
  textJustificationMapping: {
    "00": "start",
    "01": "start",
    "02": "center",
    "03": "end"
  },
  textJustificationReverseMapping: {
    "left": "01",
    "center": "02",
    "right": "03"
  },
  cntrlCodes: {
    "00": "black",
    "01": "red",
    "02": "green",
    "03": "yellow",
    "04": "blue",
    "05": "magenta",
    "06": "cyan",
    "07": "white"
  },
  cntrlCodeMapping: {
    "black": "00",
    "red": "01",
    "green": "02",
    "yellow": "03",
    "blue": "04",
    "magenta": "05",
    "cyan": "06",
    "white": "07"
  },
  // Decode from Hex
  decodeChar: function (charCode, codePage) {
    if (codePage == "20269" && charCode == "23") {
      return "#";
    } else {
      return cptable[codePage].dec[parseInt(charCode, 16)];
    }
  },
  // Encode to Hex
  encodeChar: function (char, codePage) {
    try {
      //console.log(char);
      if (this.getKeyByValue(this.specialChars, char)) {
        return this.getKeyByValue(this.specialChars, char);
      }
      if (char === "#") {
        return "23";
      } else {
        let encodedChar = cptable[codePage].enc[char];
        if (encodedChar) {
          return encodedChar.toString(16);
        } else {
          return "23";
        }
      }
    } catch (e) {
      return "23";
    }
  },
  reverseHexBytes: function (num) {
    let hex = num.toString(16).padStart(4, '0');
    let chunks = hex.match(/.{1,2}/g);
    return chunks.reverse().join('');
  },
  asciiToHex: function (str) {
    let encodedText = str.toString().split("").map(char => {
      return char.charCodeAt(0).toString(16);
    }).join("");
    return encodedText;
  },
  encodeText: function (text, codePage, encodingOptions = null) {
    if (text.length === 0) {
      return "";
    }
    let encodedText = "",
      self = this;
    text = convertToPlainTextCustom(text, "\n", false, "strong", "em", "u", true);
    // console.log(text);
    let colorFlag = false;
    text.split("\n").forEach((textLine, index) => {
      if (index > 0) {
        if (encodingOptions["Display Standard Code"] && ["Level-1 teletext", "Level-2 teletext"].indexOf(encodingOptions["Display Standard Code"]) > -1) {
          encodedText += "0a0a";
        }

        //Insert two line breaks if double height characters are enabled
        if (encodingOptions["Double Height Characters"] && encodingOptions["Double Height Characters"] === "yes") {
          encodedText += "8a8a";
        } else {
          encodedText += "8a";
        }
        if (encodingOptions && encodingOptions["Display Standard Code"] && ["Level-1 teletext", "Level-2 teletext"].indexOf(encodingOptions["Display Standard Code"]) > -1 && encodingOptions["Double Height Characters"] && encodingOptions["Double Height Characters"] === "yes") {
          encodedText += "0d";
        }
        if (encodingOptions["Display Standard Code"] && ["Level-1 teletext", "Level-2 teletext"].indexOf(encodingOptions["Display Standard Code"]) > -1) {
          encodedText += "0b0b";
        }
      }
      let characters = textLine.split("");
      let charLength = characters.length;
      while (characters.length > 0) {
        let char = characters.shift();
        if (char === "<") {
          if (characters.slice(0, 8).join("") === "/strong>") {
            encodedText += "85";
            characters.splice(0, 8);
          } else if (characters.slice(0, 4).join("") === "/em>") {
            encodedText += "81";
            characters.splice(0, 4);
          } else if (characters.slice(0, 3).join("") === "/u>") {
            encodedText += "83";
            characters.splice(0, 3);
          } else if (characters.slice(0, 6).join("") === "/span>") {
            characters.splice(0, 6);
            if (characters.length > 0 && characters[0] !== "<") {
              encodedText += "00";
            }
          } else if (characters.slice(0, 7).join("") === "strong>") {
            encodedText += "84";
            characters.splice(0, 7);
          } else if (characters.slice(0, 3).join("") === "em>") {
            encodedText += "80";
            characters.splice(0, 3);
          } else if (characters.slice(0, 2).join("") === "u>") {
            encodedText += "82";
            characters.splice(0, 2);
          } else if (characters.slice(0, 4).join("") === "span") {
            let spanClose = characters.findIndex(spanChar => {
              return spanChar === ">";
            });
            if (spanClose > -1) {
              let spanInfo = extractStyleAttributes("<" + characters.splice(0, spanClose + 1).join(""));
              if (spanInfo.color) {
                encodedText += self.cntrlCodeMapping[spanInfo.color];
                colorFlag = true;
              }
            }
          } else {
            /* treat it as a normal character I guess */
            encodedText += self.encodeChar(char, codePage);
          }
        } else if (this.accentReverseMapping[char]) {
          encodedText += this.accentReverseMapping[char];
        } else {
          if (characters.length === charLength - 1 && colorFlag) {
            encodedText += "07";
            colorFlag = false;
          }
          encodedText += self.encodeChar(char, codePage);
        }
      }
    });
    return encodedText;
  },
  decodeGsiBlock: function (gsiBytes) {
    return {
      cpn: hex2ascii(gsiBytes.substring(0, 6)),
      frameRate: this.frameRateMapping[gsiBytes.substring(6, 22)],
      dsc: gsiBytes.substring(22, 24),
      alphabet: this.charCodeTblMap[gsiBytes.substring(24, 28)],
      program: hex2ascii(gsiBytes.substring(32, 96)).trim(),
      episode: hex2ascii(gsiBytes.substring(96, 160)).trim(),
      ttiBlocks: parseInt(hex2ascii(gsiBytes.substring(476, 486))),
      subtitles: parseInt(hex2ascii(gsiBytes.substring(476, 486))),
      subtitleGroups: parseInt(hex2ascii(gsiBytes.substring(486, 496))),
      maxCharPerLine: hex2ascii(gsiBytes.substring(502, 506)),
      maxLines: hex2ascii(gsiBytes.substring(506, 510)),
      som: hex2ascii(gsiBytes.substring(512, 528)),
      firstInCue: hex2ascii(gsiBytes.substring(528, 544)),
      publisher: hex2ascii(gsiBytes.substring(554, 618)).trim(),
      editor: hex2ascii(gsiBytes.substring(618, 650)).trim()
    };
  },
  decodeTtiBlock: function (ttiBytes) {
    return {
      subtitleGroup: parseInt(ttiBytes.substring(0, 2), 16),
      subtitleId: ttiBytes.substring(2, 6),
      extBlock: parseInt(ttiBytes.substring(6, 8), 16),
      cumStatus: ttiBytes.substring(8, 10),
      incode: ttiBytes.substring(10, 18),
      outcode: ttiBytes.substring(18, 26),
      yPos: parseInt(ttiBytes.substring(26, 28), 16),
      xPos: this.textJustificationMapping[ttiBytes.substring(28, 30)],
      commentFlag: ttiBytes.substring(30, 32),
      text: ttiBytes.substring(32)
    };
  },
  calcMnr: function (encodingOptions) {
    let displayStandardCode = encodingOptions["Display Standard Code"] || "Open subtitling";
    let mnr = encodingOptions["Max Number of Rows"] || (displayStandardCode === "Open subtitling" ? "14" : "24");
    if (["Level-1 teletext", "Level-2 teletext"].indexOf(displayStandardCode) > -1) {
      mnr = "23";
    } else if (mnr > 99) {
      mnr = "99";
    } else if (!mnr) {
      return "15";
    }
    return mnr;
  },
  encodeGsiBlock: function (events, encodingOptions, options) {
    let displayStandardCode = encodingOptions["Display Standard Code"] || "Open subtitling";
    let codePage = this.codePageMapping[encodingOptions["GSI Character Set"]] || 850; //default to multilanguage
    let characterCodeTable = encodingOptions["Character Code Table"] || "Latin";
    let gsiCharacterSet = encodingOptions["GSI Character Set"] || "Multilingual";
    let language = encodingOptions["Language Code"] || "English";
    let languageCode = this.getKeyByValue(this.languageCodeMapping, language) || "00";
    let countryOfOrigin = encodingOptions["Country of Origin"] || "Canada";
    let countryCode = this.getKeyByValue(this.countryCodes, countryOfOrigin) || "CAN";
    let lc = "" + languageCode.charCodeAt(0).toString(16) + languageCode.charCodeAt(1).toString(16);
    //SOM
    let som = encodingOptions["SOM"] || options.incode || "00:00:00:00";

    //First in Cue
    let startOfFirstEvent = events[0].start;
    let incodeSec = tcLib.tcToSec(som, options.frameRate);
    let firstInCue = startOfFirstEvent + incodeSec;

    //Max Number of Characters/Row
    let mnc = encodingOptions["Max Number of Characters Per Row"] || "32";

    //Max number of Rows: Teletext is fixed at 23 and open subtitling is variable between 0-99 but defaults to 14 (same as SCC at 15)
    let mnr = this.calcMnr(encodingOptions);
    return {
      cpn: this.charCodeTblMap[gsiCharacterSet],
      dfc: this.frameRateMapping[options.frameRate.toString()] || "53544c32352e3031",
      dsc: this.dscMapping[displayStandardCode] || "30",
      cct: this.charCodeTblMap[characterCodeTable],
      lc: lc,
      opt: this.encodeText(encodingOptions["Program Title"] ? encodingOptions["Program Title"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      oet: this.encodeText(encodingOptions["Episode Title"] ? encodingOptions["Episode Title"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      tpt: this.encodeText(encodingOptions["Translated Program Title"] ? encodingOptions["Translated Program Title"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      tet: this.encodeText(encodingOptions["Translated Episode Title"] ? encodingOptions["Translated Episode Title"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      tn: this.encodeText(encodingOptions["Translator's Name"] ? encodingOptions["Translator's Name"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      tcd: this.encodeText(encodingOptions["Translator's Contact Details"] ? encodingOptions["Translator's Contact Details"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      slr: this.encodeText(encodingOptions["Subtitle List Ref Code"] ? encodingOptions["Subtitle List Ref Code"].substring(0, 16) : "", codePage).padEnd(32, "20"),
      cd: this.encodeText(this.getCurrentDate(), codePage),
      rd: this.encodeText(this.getCurrentDate(), codePage),
      rn: "3031",
      tnb: this.encodeText(events.length, codePage).padStart(10, "30"),
      tns: this.encodeText(events.length, codePage).padStart(10, "30"),
      tng: "303031",
      mnc: this.asciiToHex(mnc).slice(-4).padStart(4, "30"),
      mnr: this.asciiToHex(mnr).slice(-4).padStart(4, "30"),
      tcs: "31",
      tcp: this.encodeText(som.replace(/:|;/g, ""), codePage),
      tcf: this.encodeHexTcFromSec(firstInCue, options.frameRate, codePage),
      tnd: "31",
      dsn: "31",
      co: this.encodeText(countryCode, codePage),
      pub: this.encodeText(encodingOptions["Publisher"] ? encodingOptions["Publisher"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      en: this.encodeText(encodingOptions["Editor's Name"] ? encodingOptions["Editor's Name"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      ecd: this.encodeText(encodingOptions["Editor's Contact Details"] ? encodingOptions["Editor's Contact Details"].substring(0, 32) : "", codePage).padEnd(64, "20"),
      concat: function () {
        return (this.cpn + this.dfc + this.dsc + this.cct + this.lc + this.opt + this.oet + this.tpt + this.tet + this.tn + this.tcd + this.slr + this.cd + this.rd + this.rn + this.tnb + this.tns + this.tng + this.mnc + this.mnr + this.tcs + this.tcp + this.tcf + this.tnd + this.dsn + this.co + this.pub + this.en + this.ecd).padEnd(2048, "20").toLowerCase();
      }
    };
  },
  encodeTtiBlock: function (event, index, encodingOptions, options) {
    let codePage = this.codePageMapping[encodingOptions["Character Code Table"]] || 20269;
    let encodedText = this.encodeText(event.text, codePage, encodingOptions);
    if (encodingOptions["Display Standard Code"] && ["Level-1 teletext", "Level-2 teletext"].indexOf(encodingOptions["Display Standard Code"]) > -1) {
      if (encodingOptions["Double Height Characters"] && encodingOptions["Double Height Characters"] === "yes") {
        encodedText = "0d0b0b" + encodedText + "0a0a";
      } else {
        encodedText = "0b0b" + encodedText + "0a0a";
      }
    } else {
      encodedText = "20" + encodedText;
    }
    return {
      sgn: "00",
      sn: this.reverseHexBytes(index),
      ebn: "FF",
      cs: "00",
      tci: this.encodeTc(event.start, options.frameRate),
      tco: this.encodeTc(event.end, options.frameRate),
      vp: this.encodeVerticalPosition(event.text, event.yPos, event.yOffset, encodingOptions, options).toString(16).padStart(2, "0"),
      jc: this.encodeHorizontalPosition(event) || "02",
      cf: "00",
      tf: encodedText,
      concat: function () {
        return (this.sgn + this.sn + this.ebn + this.cs + this.tci + this.tco + this.vp + this.jc + this.cf + this.tf).padEnd(256, "8f").toLowerCase();
      }
    };
  },
  decodeTc: function (hexTc) {
    let hours = "00" + parseInt(hexTc.substring(0, 2), 16);
    let minutes = "00" + parseInt(hexTc.substring(2, 4), 16);
    let seconds = "00" + parseInt(hexTc.substring(4, 6), 16);
    let frames = "00" + parseInt(hexTc.substring(6, 8), 16);
    return `${hours.slice(-2)}:${minutes.slice(-2)}:${seconds.slice(-2)}:${frames.slice(-2)}`;
  },
  /* Used in TTI Blocks */
  encodeTc: function (sec, frameRate) {
    let hex = "";
    let tc = tcLib.secToTc(sec, frameRate).replace(/;/g, ":");
    tc.split(":").forEach(tcVal => {
      hex += ("00" + parseInt(tcVal).toString(16)).slice(-2);
    });
    return hex;
  },
  /* Used in GSI encoding */
  encodeHexTcFromSec: function (sec, frameRate, codePage) {
    let tc = tcLib.secToTc(sec, frameRate).replace(/:|;/g, "");
    return this.encodeText(tc, codePage);
  },
  encodeHorizontalPosition: function (event) {
    //Calculate the horizontal position based on the xPos and alignment of the Event.
    let text = convertToPlainTextCustom(event.text, "\n", true);
    let textLines = eol.split(text);
    if (textLines.length === 0) {
      return "02";
    } else {
      if (event.xPos === "start") {
        return this.textJustificationReverseMapping["left"];
      } else if (event.xPos === "end") {
        return this.textJustificationReverseMapping["right"];
      } else {
        return this.textJustificationReverseMapping["center"];
      }
    }
  },
  encodeVerticalPosition: function (text, yPos, yOffset, encodingOptions, options) {
    //Calcualte the vertical position of the Subtitle text based on the number of text lines, yPosition (start, center, end), yOffset, and the max number of rows allowed. We need to calculate the total offset position as a percentage of the total window height. We can then calculate the position based on the total number of rows. For example, if the subtitle block is position 23% from the top of the screen and the subtitle block is 14 rows high, then the subtitle block should start at row ~3.        
    let mnr = parseInt(this.calcMnr(encodingOptions));
    let totalWindowHeight = options.window.height - options.window.yOffset * 2;
    let pxUnits = (totalWindowHeight / mnr).toFixed(4);
    let currentPos;
    let currentLine;
    let totalTextLines = getLineCount(text);
    let doubleHeight = encodingOptions["Double Height Characters"] && encodingOptions["Double Height Characters"] === "yes";
    totalTextLines = doubleHeight ? totalTextLines * 2 : totalTextLines;

    // console.log("-----------");
    // console.log(convertToPlainTextCustom(text));

    try {
      //Get the current position pased on origin (Start, center, end) and the offset. 
      if (yPos === "start") {
        //(pxUnits*2) is the padding or first two lines. plus the yOffset.
        currentPos = yOffset - options.window.yOffset;
        currentPos = Math.round(currentPos / pxUnits) * pxUnits;
        currentLine = Math.round(currentPos / pxUnits);
      } else if (yPos === "end") {
        //(pxUnits*17) Places us at the bottom of the screen, and then we subtract the yOffset to figure out how high up we are.
        currentPos = yOffset + options.window.yOffset;
        currentPos = Math.round(currentPos / pxUnits) * pxUnits;
        currentLine = mnr + Math.round(currentPos / pxUnits) - (totalTextLines - 1);
      } else {
        currentPos = Math.round(yOffset / pxUnits) * pxUnits;
        //console.log("Current Position: "+currentPos);
        currentLine = mnr / 2 + Math.round(currentPos / pxUnits);
      }
      currentLine = parseInt(currentLine);
      // console.log("Before Error Checking: "+ currentLine);

      //Error Checking
      if (encodingOptions["Display Standard Code"] && ["Level-1 teletext", "Level-2 teletext"].indexOf(encodingOptions["Display Standard Code"]) > -1) {
        if (currentLine < 1) {
          currentLine = 1;
        } else if (currentLine + totalTextLines > 24) {
          currentLine = 24 - totalTextLines;
        }
      } else {
        if (currentLine < 0) {
          currentLine = 0;
        } else if (currentLine + totalTextLines > mnr + 1) {
          currentLine = mnr - (totalTextLines + 1);
        }
      }

      //console.log("After Error Checking: "+currentLine);

      return currentLine;
    } catch (err) {
      return parseInt(1).toString(16).padStart(2, "0");
    }
  },
  positionToHex: function (position) {
    // console.log(position);
    let y = Math.floor(position.split("_")[0] / 15 * 23);
    return ccFunc.pad("00", 23 - y.toString(16), true);
  },
  getCurrentDate: function () {
    let x = new Date();
    let y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    d.length == 1 && (d = "0" + d);
    m.length == 1 && (m = "0" + m);
    let yyyymmdd = y + m + d;
    return yyyymmdd.substring(2);
  },
  getKeyByValue: function (obj, value) {
    return Object.keys(obj).find(code => obj[code] === value);
  },
  countryCodes: {
    "BHR": "Bahrain",
    "ABW": "Aruba",
    "BHS": "Bahamas",
    "AFG": "Afghanistan",
    "BLZ": "Belize",
    "AGO": "Angola",
    "BMU": "Bermuda",
    "AIA": "Anguilla",
    "BOL": "Bolivia",
    "ALB": "Albania",
    "BRA": "Brazil",
    "AND": "Andorra",
    "BRB": "Barbados",
    "ANT": "Netherlands Antilles",
    "BRN": "Brunei Darussalam",
    "ARE": "United Arab Emirates",
    "BTN": "Bhutan",
    "ARG": "Argentina",
    "BUR": "Burma",
    "ASM": "American Samoa",
    "BVT": "Bouvet Island",
    "ATA": "Antarctica",
    "BWA": "Botswana",
    "ATF": "French Southern Territories",
    "BYS": "Byelorussian SSR",
    "ATG": "Antigua and Barbuda",
    "CAF": "Central African Republic",
    "CAN": "Canada",
    "AUS": "Australia",
    "CCK": "Cocos (Keeling) Islands",
    "AUT": "Austria",
    "CHE": "Switzerland",
    "BDI": "Burundi",
    "CHL": "Chile",
    "BEL": "Belgium",
    "CHN": "China",
    "BEN": "Benin",
    "CIV": "Côte d'Ivoire",
    "BFA": "Burkina Faso",
    "CMR": "Cameroon",
    "BGD": "Bangladesh",
    "COG": "Congo",
    "BGR": "Bulgaria",
    "COK": "Cook Islands",
    "COL": "Colombia",
    "GUF": "French Guinea",
    "COM": "Comoros",
    "GUM": "Guam",
    "CPV": "Cape Verde",
    "GUY": "Guyana",
    "CRI": "Costa Rica",
    "HKG": "Hong Kong",
    "CSK": "Czechoslovakia",
    "HMD": "Heard and Mc Donald Islands",
    "HND": "Honduras",
    "CUB": "Cuba",
    "HTI": "Haiti",
    "CXR": "Christmas Island",
    "HUN": "Hungary",
    "CYM": "Cayman Islands",
    "CYP": "Cyprus",
    "IDN": "Indonesia",
    "DDR": "German Democratic Republic",
    "IDN": "India",
    "DEU": "Germany, Federal Republic of",
    "IOT": "British Indian Ocean Territory",
    "DJI": "Djibouti",
    "IRL": "Ireland",
    "DMA": "Dominica",
    "IRN": "Iran",
    "DNK": "Denmark",
    "IRQ": "Iraq",
    "DOM": "Dominican Republic",
    "ISL": "Iceland",
    "DZA": "Algeria",
    "ISR": "Israel",
    "ECU": "Ecuador",
    "ITA": "Italy",
    "EGY": "Egypt",
    "JAM": "Jamaica",
    "ESH": "Western Sahara",
    "JOR": "Jordan",
    "ESP": "Spain",
    "JPN": "Japan",
    "ETH": "Ethiopia",
    "FIN": "Finland",
    "KEN": "Kenya",
    "FJI": "Fiji",
    "DHM": "Kampuchea, Democratic",
    "FLK": "Falkland Islands (Malvinas)",
    "KIR": "Kiribati",
    "FRA": "France",
    "KNA": "Saint Kitts and Nevis",
    "FRO": "Faroe Islands",
    "KOR": "Korea, Republic of",
    "FSM": "Micronesia",
    "KWT": "Kuwait",
    "GAB": "Gabon",
    "LAO": "Lao, People's Democratic Republic of",
    "GBR": "United Kingdom",
    "LBN": "Lebanon",
    "GHA": "Ghana",
    "LBR": "Liberia",
    "GIB": "Gibraltar",
    "LBY": "Libyan Arab Jamahiriya",
    "GIN": "Guinea",
    "LCA": "Saint Lucia",
    "GLP": "Guadeloupe",
    "LIE": "Liechtenstein",
    "GMB": "Gambia",
    "LKA": "Sri Lanka",
    "GNB": "Guinea-Bissau",
    "LSO": "Lesotho",
    "GNQ": "Equatorial Guinea",
    "LUX": "Luxembourg",
    "GRC": "Greece",
    "MAC": "Macau",
    "GRD": "Grenada",
    "MAR": "Morocco",
    "GRL": "Greenland",
    "MCO": "Monaco",
    "GTM": "Guatemala",
    "MDG": "Madagascar",
    "MDV": "Maldives",
    "PRK": "Korea, Democratic People's Republic of",
    "MEX": "Mexico",
    "PRT": "Portugal",
    "MHL": "Marshall Islands",
    "PRY": "Paraguay",
    "MLI": "Mali",
    "PYF": "French Polynesia",
    "MLT": "Malta",
    "QAT": "Qatar",
    "MNG": "Mongolia",
    "MNP": "Nothern Mariana Islands",
    "REU": "Réunion",
    "MOZ": "Mozambique",
    "ROM": "Romania",
    "MRT": "Mauritania",
    "RWA": "Rwanda",
    "MSR": "Montserrat",
    "SAU": "Saudi Arabia",
    "MTQ": "Martinique",
    "SDN": "Sudan",
    "MUS": "Mauritius",
    "SEN": "Senegal",
    "MWI": "Malawi",
    "SGP": "Singapore",
    "MYS": "Malaysia",
    "SHN": "St. Helena",
    "NAM": "Namibia",
    "SJM": "Svalbard and Jan Mayen Islands",
    "NCL": "New Caledonia",
    "SLB": "Solomon Islands",
    "NER": "Niger",
    "SLE": "Sierra Leone",
    "NFK": "Norfolk Island",
    "SLV": "El Salvador",
    "NGA": "Nigeria",
    "SMR": "San Marino",
    "NIC": "Nicaragua",
    "SOM": "Somalia",
    "NIU": "Niue",
    "SPM": "St. Pierre and Miquelon",
    "NLD": "Netherlands",
    "STP": "Sao Tome and Principé",
    "NOR": "Norway",
    "SUN": "USSR",
    "NPL": "Nepal",
    "SUR": "Surinam",
    "NRU": "Nauru",
    "SWE": "Sweden",
    "NTZ": "Neutral Zone",
    "SWZ": "Swaziland",
    "NZL": "New Zealand",
    "SYC": "Seychelles",
    "OMN": "Oman",
    "SYR": "Syrian Arab Republic",
    "OOO": "escape code, see note 3",
    "TCA": "Turks and Caicos Islands",
    "PAK": "Pakistan",
    "TCD": "Chad",
    "PAN": "Panama",
    "TGO": "Togo",
    "THA": "Thailand",
    "PCN": "Pitcairn",
    "TKL": "Tokelau",
    "PER": "Peru",
    "TMP": "East Timor",
    "PHL": "Philippines",
    "TON": "Tonga",
    "PLW": "Palau",
    "TTO": "Trinidad and Tobago",
    "PNG": "Papua New Guinea",
    "TUN": "Tunisia",
    "POL": "Poland",
    "TUR": "Turkey",
    "PRI": "Puerto Rico",
    "TUV": "Tuvalu",
    "TWN": "Taiwan, Province of China",
    "VUT": "Vanuatu",
    "TZA": "Tanzania, United Republic of",
    "UGA": "Uganda",
    "WLF": "Wallis and Futuna Islands",
    "UKR": "Ukrainian SSR",
    "WSM": "Samoa",
    "UMI": "United States Minor Outlying Islands",
    "URY": "Uruguay",
    "YEM": "Yemen",
    "USA": "United States",
    "YMD": "Yemen, Democratic",
    "VAT": "Vatican City State (Holy See)",
    "YUG": "Yugoslavia",
    "VCT": "Saint Vincent and the Grenadines",
    "ZAF": "South Africa",
    "VEN": "Venezuela",
    "ZAR": "Zaire",
    "VGB": "Virgin Islands (British)",
    "ZMB": "Zambia",
    "VNM": "Viet Nam"
  },
  specialChars: {
    "d3": "©",
    "d4": "™",
    "d5": "♪",
    "e0": "Ω",
    "e1": "Æ",
    "e2": "Ð",
    "e3": "ª",
    "e4": "Ħ",
    "e6": "Ĳ",
    "e7": "Ŀ",
    "e8": "Ł",
    "e9": "Ø",
    "ea": "Œ",
    "eb": "º",
    "ec": "Þ",
    "ed": "Ŧ",
    "ee": "Ŋ",
    "ef": "ŉ",
    "f0": "ĸ",
    "f1": "æ",
    "f2": "đ",
    "f3": "ð",
    "f4": "ħ",
    "f5": "ı",
    "f6": "ĳ",
    "f7": "ŀ",
    "f8": "ł",
    "f9": "ø",
    "fa": "œ",
    "fb": "ß",
    "fc": "þ",
    "fd": "ŧ",
    "fe": "ŋ"
  },
  accentMapping: {
    /* A */
    "41": {
      /* C1 */
      193: "À",
      /* C2 */
      194: "Á",
      /* C3 */
      195: "Â",
      /* C4 */
      196: "Ã",
      /* C5 */
      197: "Ā",
      /* C6 */
      198: "Ă",
      /* C7 */
      199: "A",
      /* C8 */
      200: "Ä",
      /* CA */
      202: "Å",
      /* CE */
      206: "Ą"
    },
    /* C */
    "43": {
      /* C1 */
      193: "C",
      /* C2 */
      194: "Ć",
      /* C3 */
      195: "Ĉ",
      /* C4 */
      196: "C",
      /* C5 */
      197: "C",
      /* C6 */
      198: "C",
      /* C7 */
      199: "Ċ",
      /* C8 */
      200: "C",
      /* CB */
      203: "Ç",
      /* CF */
      207: "Č"
    },
    /* D */
    "44": {
      /* CF */207: "Ď"
    },
    /* E */
    "45": {
      /* C1 */
      193: "È",
      /* C2 */
      194: "É",
      /* C3 */
      195: "Ê",
      /* C4 */
      196: "E",
      /* C5 */
      197: "Ē",
      /* C6 */
      198: "E",
      /* C7 */
      199: "Ė",
      /* C8 */
      200: "Ë",
      /* CE */
      206: "Ę",
      /* CF */
      207: "Ě"
    },
    /* G */
    "47": {
      /* C1 */
      193: "G",
      /* C2 */
      194: "G",
      /* C3 */
      195: "Ĝ",
      /* C4 */
      196: "G",
      /* C5 */
      197: "G",
      /* C6 */
      198: "Ğ",
      /* C7 */
      199: "Ġ",
      /* C8 */
      200: "G",
      /* CB */
      203: "Ģ"
    },
    /* H */
    "48": {
      /* C1 */
      193: "H",
      /* C2 */
      194: "H",
      /* C3 */
      195: "Ĥ",
      /* C4 */
      196: "H",
      /* C5 */
      197: "H",
      /* C6 */
      198: "H",
      /* C7 */
      199: "H",
      /* C8 */
      200: "H"
    },
    /* I */
    "49": {
      /* C1 */
      193: "Ì",
      /* C2 */
      194: "Í",
      /* C3 */
      195: "Î",
      /* C4 */
      196: "Ĩ",
      /* C5 */
      197: "Ī",
      /* C6 */
      198: "I",
      /* C7 */
      199: "İ",
      /* C8 */
      200: "Ï",
      /* CE */
      206: "Į"
    },
    /* J */
    "4a": {
      /* C1 */
      193: "J",
      /* C2 */
      194: "J",
      /* C3 */
      195: "Ĵ",
      /* C4 */
      196: "J",
      /* C5 */
      197: "J",
      /* C6 */
      198: "J",
      /* C7 */
      199: "J",
      /* C8 */
      200: "J"
    },
    /* K */
    "4b": {
      /* CB */203: "Ķ"
    },
    /* L */
    "4c": {
      /* C1 */
      193: "L",
      /* C2 */
      194: "Ĺ",
      /* C3 */
      195: "L",
      /* C4 */
      196: "L",
      /* C5 */
      197: "L",
      /* C6 */
      198: "L",
      /* C7 */
      199: "L",
      /* C8 */
      200: "L",
      /* CB */
      203: "Ļ",
      /* CF */
      207: "Ľ"
    },
    /* N */
    "4e": {
      /* C1 */
      193: "N",
      /* C2 */
      194: "Ń",
      /* C3 */
      195: "N",
      /* C4 */
      196: "Ñ",
      /* C5 */
      197: "N",
      /* C6 */
      198: "N",
      /* C7 */
      199: "N",
      /* C8 */
      200: "N",
      /* CB */
      203: "Ņ",
      /* CF */
      207: "Ň"
    },
    /* O */
    "4f": {
      /* C1 */
      193: "Ò",
      /* C2 */
      194: "Ó",
      /* C3 */
      195: "Ô",
      /* C4 */
      196: "Õ",
      /* C5 */
      197: "Ō",
      /* C6 */
      198: "O",
      /* C7 */
      199: "O",
      /* C8 */
      200: "Ö",
      /* CD */
      205: "Ő"
    },
    /* R */
    "52": {
      /* C1 */
      193: "R",
      /* C2 */
      194: "Ŕ",
      /* C3 */
      195: "R",
      /* C4 */
      196: "R",
      /* C5 */
      197: "R",
      /* C6 */
      198: "R",
      /* C7 */
      199: "R",
      /* C8 */
      200: "R",
      /* CB */
      203: "Ŗ",
      /* CF */
      207: "Ř"
    },
    /* S */
    "53": {
      /* C1 */
      193: "S",
      /* C2 */
      194: "Ś",
      /* C3 */
      195: "Ŝ",
      /* C4 */
      196: "S",
      /* C5 */
      197: "S",
      /* C6 */
      198: "S",
      /* C7 */
      199: "S",
      /* C8 */
      200: "S",
      /* CB */
      203: "Ş",
      /* CF */
      207: "Š"
    },
    /* T */
    "54": {
      /* CB */203: "Ţ",
      /* CF */
      207: "Ť"
    },
    /* U */
    "55": {
      /* C1 */
      193: "Ù",
      /* C2 */
      194: "Ú",
      /* C3 */
      195: "Û",
      /* C4 */
      196: "Ũ",
      /* C5 */
      197: "Ū",
      /* C6 */
      198: "Ŭ",
      /* C7 */
      199: "U",
      /* C8 */
      200: "Ü",
      /* CA */
      202: "Ů",
      /* CD */
      205: "Ű",
      /* CE */
      206: "Ų"
    },
    /* W */
    "57": {
      /* C1 */
      193: "W",
      /* C2 */
      194: "W",
      /* C3 */
      195: "Ŵ",
      /* C4 */
      196: "W",
      /* C5 */
      197: "W",
      /* C6 */
      198: "W",
      /* C7 */
      199: "W",
      /* C8 */
      200: "W"
    },
    /* Y */
    "59": {
      /* C1 */
      193: "Y",
      /* C2 */
      194: "Ý",
      /* C3 */
      195: "Ŷ",
      /* C4 */
      196: "Y",
      /* C5 */
      197: "Y",
      /* C6 */
      198: "Y",
      /* C7 */
      199: "Y",
      /* C8 */
      200: "Ÿ"
    },
    /* Z */
    "5a": {
      /* C1 */
      193: "Z",
      /* C2 */
      194: "Ź",
      /* C3 */
      195: "Z",
      /* C4 */
      196: "Z",
      /* C5 */
      197: "Z",
      /* C6 */
      198: "Z",
      /* C7 */
      199: "Ż",
      /* C8 */
      200: "Z",
      /* CF */
      207: "Ž"
    },
    /* a */
    "61": {
      /* C1 */
      193: "à",
      /* C2 */
      194: "á",
      /* C3 */
      195: "â",
      /* C4 */
      196: "ã",
      /* C5 */
      197: "ā",
      /* C6 */
      198: "ă",
      /* C7 */
      199: "a",
      /* C8 */
      200: "ä",
      /* CA */
      202: "å",
      /* CE */
      206: "ą"
    },
    /* c */
    "63": {
      /* C1 */
      193: "c",
      /* C2 */
      194: "ć",
      /* C3 */
      195: "ĉ",
      /* C4 */
      196: "c",
      /* C5 */
      197: "c",
      /* C6 */
      198: "c",
      /* C7 */
      199: "ċ",
      /* C8 */
      200: "c",
      /* CB */
      203: "ç",
      /* CF */
      207: "č"
    },
    /* d */
    "64": {
      /* CF */207: "ď"
    },
    /* e */
    "65": {
      /* C1 */
      193: "è",
      /* C2 */
      194: "é",
      /* C3 */
      195: "ê",
      /* C4 */
      196: "e",
      /* C5 */
      197: "ē",
      /* C6 */
      198: "e",
      /* C7 */
      199: "ė",
      /* C8 */
      200: "ë",
      /* CE */
      206: "ę",
      /* CF */
      207: "ě"
    },
    /* g */
    "67": {
      /* C1 */
      193: "g",
      /* C2 */
      194: "ģ",
      /* C3 */
      195: "ĝ",
      /* C4 */
      196: "g",
      /* C5 */
      197: "g",
      /* C6 */
      198: "ğ",
      /* C7 */
      199: "ġ",
      /* C8 */
      200: "g"
    },
    /* h */
    "68": {
      /* C1 */
      193: "h",
      /* C2 */
      194: "h",
      /* C3 */
      195: "ĥ",
      /* C4 */
      196: "h",
      /* C5 */
      197: "h",
      /* C6 */
      198: "h",
      /* C7 */
      199: "h",
      /* C8 */
      200: "h"
    },
    /* i */
    "69": {
      /* C1 */
      193: "ì",
      /* C2 */
      194: "í",
      /* C3 */
      195: "î",
      /* C4 */
      196: "ĩ",
      /* C5 */
      197: "ī",
      /* C6 */
      198: "i",
      /* C7 */
      199: "ı",
      /* C8 */
      200: "ï",
      /* CE */
      206: "į"
    },
    /* j */
    "6a": {
      /* C1 */
      193: "j",
      /* C2 */
      194: "j",
      /* C3 */
      195: "ĵ",
      /* C4 */
      196: "j",
      /* C5 */
      197: "j",
      /* C6 */
      198: "j",
      /* C7 */
      199: "j",
      /* C8 */
      200: "j"
    },
    /* k */
    "6b": {
      /* CB */203: "ķ"
    },
    /* l */
    "6c": {
      /* C1 */
      193: "l",
      /* C2 */
      194: "ĺ",
      /* C3 */
      195: "l",
      /* C4 */
      196: "l",
      /* C5 */
      197: "l",
      /* C6 */
      198: "l",
      /* C7 */
      199: "l",
      /* C8 */
      200: "l",
      /* CB */
      203: "ļ",
      /* CF */
      207: "ľ"
    },
    /* n */
    "6e": {
      /* C1 */
      193: "n",
      /* C2 */
      194: "ń",
      /* C3 */
      195: "n",
      /* C4 */
      196: "ñ",
      /* C5 */
      197: "n",
      /* C6 */
      198: "n",
      /* C7 */
      199: "n",
      /* C8 */
      200: "n",
      /* CB */
      203: "ņ",
      /* CF */
      207: "ň"
    },
    /* o */
    "6f": {
      /* C1 */
      193: "ò",
      /* C2 */
      194: "ó",
      /* C3 */
      195: "ô",
      /* C4 */
      196: "õ",
      /* C5 */
      197: "ō",
      /* C6 */
      198: "o",
      /* C7 */
      199: "o",
      /* C8 */
      200: "ö",
      /* CD */
      205: "ő"
    },
    /* r */
    "72": {
      /* C1 */
      193: "r",
      /* C2 */
      194: "ŕ",
      /* C3 */
      195: "r",
      /* C4 */
      196: "r",
      /* C5 */
      197: "r",
      /* C6 */
      198: "r",
      /* C7 */
      199: "r",
      /* C8 */
      200: "r",
      /* CB */
      203: "ŗ",
      /* CF */
      207: "ř"
    },
    /* s */
    "73": {
      /* C1 */
      193: "s",
      /* C2 */
      194: "ś",
      /* C3 */
      195: "ŝ",
      /* C4 */
      196: "s",
      /* C5 */
      197: "s",
      /* C6 */
      198: "s",
      /* C7 */
      199: "s",
      /* C8 */
      200: "s",
      /* CB */
      203: "ş",
      /* CF */
      207: "š"
    },
    /* t */
    "74": {
      /* CB */203: "ţ",
      /* CF */
      207: "ť"
    },
    /* u */
    "75": {
      /* C1 */
      193: "ù",
      /* C2 */
      194: "ú",
      /* C3 */
      195: "û",
      /* C4 */
      196: "ũ",
      /* C5 */
      197: "ū",
      /* C6 */
      198: "ŭ",
      /* C7 */
      199: "u",
      /* C8 */
      200: "ü",
      /* CA */
      202: "ů",
      /* CD */
      205: "ű",
      /* CE */
      206: "ų"
    },
    /* w */
    "77": {
      /* C1 */
      193: "w",
      /* C2 */
      194: "w",
      /* C3 */
      195: "ŵ",
      /* C4 */
      196: "w",
      /* C5 */
      197: "w",
      /* C6 */
      198: "w",
      /* C7 */
      199: "w",
      /* C8 */
      200: "w"
    },
    /* y */
    "79": {
      /* C1 */
      193: "y",
      /* C2 */
      194: "ý",
      /* C3 */
      195: "ŷ",
      /* C4 */
      196: "y",
      /* C5 */
      197: "y",
      /* C6 */
      198: "y",
      /* C7 */
      199: "y",
      /* C8 */
      200: "ÿ"
    },
    /* z */
    "7a": {
      /* C1 */
      193: "z",
      /* C2 */
      194: "ź",
      /* C3 */
      195: "z",
      /* C4 */
      196: "z",
      /* C5 */
      197: "z",
      /* C6 */
      198: "z",
      /* C7 */
      199: "ż",
      /* C8 */
      200: "z",
      /* CF */
      207: "ž"
    }
  },
  accentReverseMapping: {
    "À": "c141",
    "Á": "c241",
    "Â": "c341",
    "Ã": "c441",
    "Ā": "c541",
    "Ă": "c641",
    "Ä": "c841",
    "Å": "ca41",
    "Ą": "cb41",
    "Ć": "c243",
    "Ĉ": "c343",
    "Ċ": "c743",
    "Ç": "cb43",
    "Č": "cf43",
    "Ď": "cf44",
    "È": "c145",
    "É": "c245",
    "Ê": "c345",
    "Ē": "c545",
    "Ė": "c745",
    "Ë": "c845",
    "Ę": "cb45",
    "Ě": "cf45",
    "Ĝ": "c347",
    "Ğ": "cf47",
    "Ġ": "c747",
    "Ģ": "cb47",
    "Ĥ": "c348",
    "Ì": "c149",
    "Í": "c249",
    "Î": "c349",
    "Ĩ": "c449",
    "Ī": "c549",
    "İ": "c749",
    "Ï": "c849",
    "Į": "ce49",
    "Ĵ": "c34a",
    "Ķ": "cb4b",
    "Ĺ": "c24c",
    "Ļ": "cb4c",
    "Ľ": "c24c",
    "Ń": "c24e",
    "Ñ": "c44e",
    "Ņ": "cb4e",
    "Ň": "c64e",
    "Ò": "c14f",
    "Ó": "c24f",
    "Ô": "c34f",
    "Õ": "c44f",
    "Ō": "c54f",
    "Ö": "c84f",
    "Ő": "cd4f",
    "Ŕ": "c252",
    "Ŗ": "cb52",
    "Ř": "c652",
    "Ś": "c253",
    "Ŝ": "c353",
    "Ş": "cb53",
    "Š": "c653",
    "Ť": "c654",
    "Ù": "c155",
    "Ú": "c255",
    "Û": "c355",
    "Ũ": "c455",
    "Ū": "c555",
    "Ŭ": "c655",
    "Ü": "c855",
    "Ů": "ca55",
    "Ű": "cd55",
    "Ų": "ce55",
    "Ŵ": "c357",
    "Ý": "c229",
    "Ŷ": "c359",
    "Ÿ": "c859",
    "Ź": "c25a",
    "Ż": "c75a",
    "Ž": "c65a",
    "à": "c161",
    "á": "c261",
    "â": "c361",
    "ã": "c461",
    "ā": "c561",
    "ă": "c661",
    "ä": "c861",
    "å": "ca61",
    "ą": "cb61",
    "ć": "c263",
    "ĉ": "c363",
    "ċ": "c763",
    "ç": "cb63",
    "č": "cf63",
    "ď": "cf64",
    "è": "c165",
    "é": "c265",
    "ê": "c365",
    "ē": "c565",
    "ė": "c765",
    "ë": "c865",
    "ę": "cb65",
    "ě": "cf65",
    "ģ": "c367",
    "ĝ": "cf67",
    "ğ": "c767",
    "ġ": "cb67",
    "ĥ": "c368",
    "ì": "c169",
    "í": "c269",
    "î": "c369",
    "ĩ": "c469",
    "ī": "c569",
    "ï": "c869",
    "į": "ce69",
    "ĵ": "c36a",
    "ķ": "cb6b",
    "ĺ": "c26c",
    "ļ": "cb6c",
    "ľ": "c26c",
    "ń": "c26e",
    "ñ": "c46e",
    "ņ": "cb6e",
    "ň": "c66e",
    "ò": "c16f",
    "ó": "c26f",
    "ô": "c36f",
    "õ": "c46f",
    "ō": "c56f",
    "ö": "c86f",
    "ő": "cd6f",
    "ŕ": "c272",
    "ŗ": "cb72",
    "ř": "c672",
    "ś": "c273",
    "ŝ": "c373",
    "ş": "cb73",
    "š": "c673",
    "ţ": "c674",
    "ť": "c674",
    "ù": "c175",
    "ú": "c275",
    "û": "c375",
    "ũ": "c475",
    "ū": "c575",
    "ŭ": "c675",
    "ü": "c875",
    "ů": "ca75",
    "ű": "cd75",
    "ų": "ce75",
    "ŵ": "c377",
    "ý": "c279",
    "ŷ": "c379",
    "ÿ": "c879",
    "ź": "c27a",
    "ż": "c77a",
    "ž": "c67a"
  }
};