<script>
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import openAiLanguages from "@app/external/cc-lib/dist/providers/openAi/languages.js";
import {
    Circle
} from 'svelte-loading-spinners'
import diff from "generic-diff";
/* Firebase */
import firebase from "@app/configs/firebase.js";
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';

let lang = localStorage.getItem("cc-spellcheck-language-v2") || "English (United States)",
    eventQueue = [],
    totalEvents = 0,
    correctedEvents = [],
    progress = 0,
    previewText = "",
    loading = false,
    selectedCorrection,
    supportedLanguages = [],
    eventListElement = document.getElementById("EventList");

//Get object properties as array
Object.keys(openAiLanguages.source).forEach((key) => {
    supportedLanguages.push(openAiLanguages.source[key]);
});

async function startSpellCheck() {
    try {
        loading = true;
        correctedEvents = [];
        selectedCorrection = undefined;
        console.log("Starting Spell Check");
        toast.push("Starting spell check process... please wait.", {classes: ['toast-info']});

        if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events.length > 0) {
            $eventGroupState[$projectState.selected].selected = [];
            $eventGroupState[$projectState.selected].events = $eventGroupState[$projectState.selected].events.filter((event) => {
                return event.text.replace(/(<([^>]+)>)/gi, "") !== "";
            });

            eventQueue = JSON.parse(
                JSON.stringify(
                    $eventGroupState[$projectState.selected].events.map((ev) => {
                        return {
                            id: ev.id,
                            text: ev.text
                        };
                    })
                )
            );

            totalEvents = eventQueue.length;

            spellCheckEvents();
        }
    } catch (err) {
        console.log(err, err.message);
        loading = false;
        correctedEvents = [];
        selectedCorrection = undefined;
        toast.push("There was an error starting spell check " + err.message, {classes: ['toast-danger']});
    }
}

function spellCheckEvents() {
    if (eventQueue.length > 0) {
        try {
            progress = parseInt(((totalEvents - eventQueue.length) / totalEvents) * 100);

            let spellCheckPromises = [];
            let eventsToProcess = eventQueue.splice(0, 100);
            while (eventsToProcess.length > 0) {
                spellCheckPromises.push(firebase.functions().httpsCallable("v8SpellCheckerV2")({
                    groupId: $eventGroupState[$projectState.selected].id,
                    events: JSON.stringify(eventsToProcess.splice(0, 20)),
                    language: lang,
                }))
            }

            Promise.allSettled(spellCheckPromises).then((results) => {
                results.forEach(res => {
                    console.log("promise response: ", res);
                    if (res.status === "rejected") {
                        return;
                    }

                    let spellCheckResponse = res.value.data.response;
                    if (spellCheckResponse) {
                        let corrections = JSON.parse(JSON.parse(spellCheckResponse));
                        console.log(corrections);
                        corrections.forEach((correction) => {
                            let eventMatch = $eventGroupState[$projectState.selected].events.find((ev) => {
                                return ev.id === correction.id;
                            });

                            if (eventMatch) {
                                if (eventMatch.text !== correction.text) {
                                    correctedEvents = [...correctedEvents, correction];
                                }
                            }
                        });
                    }
                });

                spellCheckEvents();
            });
        } catch (err) {
            console.log(err, err.message);
            loading = false;
            correctedEvents = [];
            selectedCorrection = undefined;
            toast.push("There was an error during spell check " + err.message, {classes: ['toast-danger']});
        }
    } else {
        finalizeSpellCheck();
    }
}

async function finalizeSpellCheck() {
    //console.log(correctedEvents);
    console.log("Ending Spell Check");
    loading = false;
    toast.push("Spell check process completed successfully", {classes: ['toast-success']});
}

function acceptAllChanges() {
    correctedEvents.forEach((correction) => {
        let eventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => {
            return event.id === correction.id;
        });

        $eventGroupState[$projectState.selected].events[eventIndex].text = correction.text;
    });

    selectedCorrection = undefined;
    correctedEvents = [];

    $eventGroupState[$projectState.selected].selected = [];
}

function acceptChange() {
    let eventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => {
        return event.id === selectedCorrection.id;
    });

    $eventGroupState[$projectState.selected].events[eventIndex].text = selectedCorrection.text;
    historyState.insert({
        name: "correct event", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });
    ignoreChange();
}

function ignoreChange() {
    let eventIndex = correctedEvents.findIndex((event) => {
        return event.id === selectedCorrection.id;
    });

    correctedEvents = correctedEvents.filter((correction) => {
        return correction.id !== selectedCorrection.id;
    });

    if (correctedEvents.length > 0) {
        selectedCorrection = correctedEvents[eventIndex] ? correctedEvents[eventIndex] : correctedEvents[0];

        let selectedEventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => {
            return event.id === selectedCorrection.id;
        });

        if (selectedEventIndex > -1) {
            if (!eventListElement) {
                eventListElement = document.getElementById("EventList");
            }
            
            try {
                eventListElement.scrollTo(0, selectedEventIndex * 230);
            } catch (err) {
                eventListElement = document.getElementById("EventList");
                eventListElement.scrollTo(0, selectedEventIndex * 230);
            }
            
            $eventGroupState[$projectState.selected].selected = [selectedEventIndex];
        } else {
            $eventGroupState[$projectState.selected].selected = [];
        }
    } else {
        selectedCorrection = undefined;
        $eventGroupState[$projectState.selected].selected = [];
    }
}

function updateDefaultDictionary() {
    localStorage.setItem("cc-spellcheck-language-v2", lang);
}

function selectEvent() {
    if (selectedCorrection && selectedCorrection.id) {
        let eventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => {
            return event.id === selectedCorrection.id;
        });

        if (!eventListElement) {
            eventListElement = document.getElementById("EventList");
        }

        eventListElement.scrollTo(0, eventIndex * 230);
        $eventGroupState[$projectState.selected].selected = [eventIndex];
    }
}

function diffChanges(selectedCorrection) {
    try {
        if (!selectedCorrection) {
            previewText = "";
            return;
        }
        let eventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => {
            return event.id === selectedCorrection.id;
        });

        let changes = diff($eventGroupState[$projectState.selected].events[eventIndex].text, selectedCorrection.text);

        return changes
            .map(function(edit) {
                if (edit.added) {
                    return '<span class="text-primary fw-bold">' + edit.items.join("") + "</span>";
                } else if (edit.removed) {
                    return '<span class="text-decoration-line-through text-danger">' + edit.items.join("") + "</span>";
                } else {
                    return edit.items.join("");
                }
            })
            .join("");
    } catch (err) {
        console.log(err, err.message);
    }
}

$: previewText = diffChanges(selectedCorrection);
</script>

<div class="row">
    <div class="col-12">
        <form>
            <div class="row g-2">
                <div class="mb-2 col-6">
                    <label for="OriginalText" class="form-label">Corrections</label>
                    <select class="form-select form-select-sm resize-vertical" size="5" bind:value={selectedCorrection} on:change={selectEvent}>
                        {#each correctedEvents as correction}
                        <option value={correction}>{convertToPlainText(correction.text)}</option>
                        {/each}
                    </select>
                </div>
                <div class="mb-2 col-6">
                    <label for="OriginalText" class="form-label">Preview</label>
                    <div id="HtmlTextPreview" class="bg-light resize-vertical p-1 px-2 rounded textPreview">
                        {@html previewText ? previewText : ""}
                    </div>
                </div>
            </div>
            <div class="row g-2">
                <div class="col-6">
                    <select class="form-select form-select-sm" disabled={loading} aria-label="Language Selection" bind:value={lang} on:change={updateDefaultDictionary}>
                        {#each supportedLanguages as languageOption}
                            <option>{languageOption}</option>
                            {/each}
                    </select>
                </div>

                <div class="col-6">
                    <button type="button" class="btn btn-info text-white btn-sm" title="Start the spell check process. Errors will appear as they are found. Correct errors while scanning is in progress." disabled={loading} on:click={() => startSpellCheck()}>
                        {#if loading}
                        <div class="float-start p-1">
                            <Circle size="12" color="#ffffff" unit="px" duration="1s"></Circle>
                        </div>
                        Working ({progress}%)
                        {:else}
                        <i class="bi bi-play-fill" /> Start
                        {/if}
                    </button>

                    <div class="btn-group btn-group-sm float-end" role="group">
                        <button type="button" class="btn btn-primary" on:click={acceptChange} title="Accept Change" disabled={!selectedCorrection}>Accept</button>
                        <button type="button" class="btn btn-outline-dark" on:click={ignoreChange} title="Skip/Ignore" disabled={!selectedCorrection}>Ignore</button>
                        <button type="button" class="btn btn-outline-primary ms-2" on:click={acceptAllChanges} title="Accept All" disabled={correctedEvents.length === 0}><i class="bi bi-check2-all" /></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<style>
#HtmlTextPreview {
    height: 10vh;
    overflow-y: auto;
}
</style>
