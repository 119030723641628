import _Papa from "papaparse";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _stripTags from "../functions/quill/stripTags.js";
import _Tag from "../classes/tag.js";
import _Speaker from "../classes/speaker.js";
import _Event from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const Speaker = _Speaker;
const Tag = _Tag;
const stripTags = _stripTags;
const convertToHtml = _convertToHtml;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const Papa = _Papa;
export default {
  decode: async function (input, options) {
    let events = [],
      decodingOptions = getFormatOptions(options.formatOptions),
      startTime,
      endTime,
      existingSpeakers = [],
      existingTags = [];

    //Get which column the user selected for caption data
    const startIndex = decodingOptions['Start Time'] && decodingOptions['Start Time'].toLowerCase() !== "none" ? decodingOptions['Start Time'].charCodeAt(0) - 65 : false;
    const endIndex = decodingOptions['End Time'] && decodingOptions['End Time'].toLowerCase() !== "none" ? decodingOptions['End Time'].charCodeAt(0) - 65 : false;
    const dialogIndex = decodingOptions['Text'] && decodingOptions['Text'].toLowerCase() !== "none" ? decodingOptions['Text'].charCodeAt(0) - 65 : false;
    const originalTextIndex = decodingOptions['Original Text'] && decodingOptions['Original Text'].toLowerCase() !== "none" ? decodingOptions['Original Text'].charCodeAt(0) - 65 : false;
    const notesIndex = decodingOptions['Notes'] && decodingOptions['Notes'].toLowerCase() !== "none" ? decodingOptions['Notes'].charCodeAt(0) - 65 : false;
    const replyIndex = decodingOptions['Reply'] && decodingOptions['Reply'].toLowerCase() !== "none" ? decodingOptions['Reply'].charCodeAt(0) - 65 : false;
    const speakersIndex = decodingOptions['Speakers'] && decodingOptions['Speakers'].toLowerCase() !== "none" ? decodingOptions['Speakers'].charCodeAt(0) - 65 : false;
    const approvalIndex = decodingOptions['Approved'] && decodingOptions['Approved'].toLowerCase() !== "none" ? decodingOptions['Approved'].charCodeAt(0) - 65 : false;
    const tagsIndex = decodingOptions['Tags'] && decodingOptions['Tags'].toLowerCase() !== "none" ? decodingOptions['Tags'].charCodeAt(0) - 65 : false;

    //Converts csv string data to json objects
    let parser = Papa.parse(input, {
      skipEmptyLines: true
    });

    // console.log(JSON.stringify(parser.data));

    for (let i = 0; i < parser.data.length; i++) {
      try {
        let ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.10
        });
        switch (decodingOptions['Start Time Format']) {
          case 'SMPTE Timecode (HH:MM:SS:FF)':
            startTime = tcLib.tcToSec(parser.data[i][startIndex].replace(/\.|\,|\;/g, ":"), options.frameRate);
            break;
          case 'MS Timecode (HH:MM:SS.MSS)':
            startTime = tcLib.tcMsToSec(parser.data[i][startIndex].replace(',', '.'));
            break;
          case 'Seconds (SS)':
            startTime = parseFloat(parser.data[i][startIndex]);
            break;
          default:
            startTime = tcLib.tcToSec(parser.data[i][startIndex] + ":00", options.frameRate);
        }
        switch (decodingOptions['End Time Format']) {
          case 'SMPTE Timecode (HH:MM:SS:FF)':
            endTime = tcLib.tcToSec(parser.data[i][endIndex].replace(/\.|\,|\;/g, ":"), options.frameRate);
            break;
          case 'MS Timecode (HH:MM:SS.MSS)':
            endTime = tcLib.tcMsToSec(parser.data[i][endIndex].replace(',', '.'));
            break;
          case 'Seconds (SS)':
            endTime = parseFloat(parser.data[i][endIndex]);
            break;
          default:
            endTime = tcLib.tcToSec(parser.data[i][endIndex] + ":00", options.frameRate);
        }
        ccEvent.start = startTime;
        ccEvent.end = endTime;
        ccEvent.text = convertToHtml(stripTags(parser.data[i][dialogIndex]));
        if (originalTextIndex !== false) {
          ccEvent.original = convertToHtml(stripTags(parser.data[i][originalTextIndex]));
        }
        if (notesIndex !== false) {
          ccEvent.notes = parser.data[i][notesIndex];
        }
        if (replyIndex !== false) {
          ccEvent.reply = parser.data[i][replyIndex];
        }
        if (speakersIndex !== false) {
          ccEvent.speakers = parser.data[i][speakersIndex].split(',').map(speaker => {
            //Check if Speaker exists already by name otherwise create a new one. Store the new speaker in existingSpeakers and return the speaker.
            if (!existingSpeakers.find(existingSpeaker => existingSpeaker.name === speaker.trim())) {
              existingSpeakers.push(new Speaker({
                name: speaker.trim()
              }));
              return existingSpeakers[existingSpeakers.length - 1];
            } else {
              return existingSpeakers.find(existingSpeaker => existingSpeaker.name === speaker.trim());
            }
          });
        }
        if (approvalIndex !== false) {
          ccEvent.approved = parser.data[i][approvalIndex] === decodingOptions['Approval Passed Value'];
        }
        if (tagsIndex !== false) {
          ccEvent.tags = parser.data[i][tagsIndex].split(',').map(tag => {
            //Check if the tag exists already by name otherwise create a new one. Store the new tag in existingTags and return the tag.
            if (!existingTags.find(existingTag => existingTag.term === tag.trim())) {
              existingTags.push(new Tag({
                term: tag.trim()
              }));
              return existingTags[existingTags.length - 1];
            } else {
              return existingTags.find(existingTag => existingTag.term === tag.trim());
            }
          });
        }
        events.push(ccEvent);
      } catch (err) {
        console.log(err.message);
      }
    }
    return events;
  },
  encode: async function (eventGroup, options) {
    let output = Papa.unparse(JSON.stringify(eventGroup.events.map(event => {
      return {
        id: event.id,
        start: event.start,
        end: event.end,
        startSmpte: tcLib.secToTc(Math.max(0, event.start), options.frameRate),
        endSmpte: tcLib.secToTc(Math.max(0, event.end), options.frameRate),
        notes: event.notes,
        reply: event.reply,
        approved: event.approved ? "Yes" : "No",
        speakers: event.speakers ? event.speakers.map(speaker => speaker.name).join(',') : "",
        recipients: event.recipients ? event.recipients.map(recipient => recipient.name).join(',') : "",
        tags: event.tags ? event.tags.map(tag => tag.term).join(',') : "",
        frameRate: options.frameRate,
        dropFrame: options.dropFrame,
        xPos: event.xPos,
        yPos: event.yPos,
        xOffset: event.xOffset,
        yOffset: event.yOffset
      };
    })));
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};