<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {environment} from '@app/store/envStore.js';
import {authState} from '@app/store/authStore.js';
import {
    fade
} from 'svelte/transition';
import General from './options/General.svelte';
import Editor from './options/Editor.svelte';
import Metadata from './options/Metadata.svelte';
import Fonts from './options/Fonts.svelte';
import CliTools from './options/CliTools.svelte';
import QuickTools from './options/QuickTools.svelte';
import Notifications from './options/Notifications.svelte';
import Integrations from './options/Integrations.svelte';
import Adr from './options/Adr.svelte';
import FootPedal from './options/FootPedal.svelte';
import AutomaticBackup from './options/AutomaticBackup.svelte';

let selectedOption = "general";
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'options' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="OptionsModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Options</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}>
                    
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3">
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'general' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'general'}}>General</a>
                            </li>
                            {#if $environment.online}
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'notifications' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'notifications'}}>Notifications</a>
                            </li>
                            {/if}
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'editor' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'editor'}}>Editor</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'quickTools' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'quickTools'}}>QuickTools</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'metadata' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'metadata'}}>Project Metadata</a>
                            </li>           
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'fonts' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'fonts'}}>Custom Fonts</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'footPedal' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'footPedal'}}>Foot Pedal Setup</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'automaticBackup' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'automaticBackup'}}>Automatic Backup</a>
                            </li>
                            {#if $authState.plan === 'pro' || $authState.plan === 'enterprise'}
                                {#if $environment.online}
                                <li class="nav-item">
                                    <a class="nav-link {selectedOption === 'integrations' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'integrations'}}>Integrations <span class="badge bg-secondary">BETA</span></a>
                                </li>
                                {/if}       
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'adr' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'adr'}}>ADR Setup <span class="badge bg-secondary">BETA</span></a>
                            </li>       
                            {/if}              
                            {#if $environment.electron}
                            <li class="nav-item">
                                <a class="nav-link {selectedOption === 'cliTools' ? 'active' : ''}" href="#!/" on:click={()=>{selectedOption = 'cliTools'}}>CLI Tools</a>
                            </li>
                            {/if}
                        </ul>
                    </div>
                    <div class="col-9">
                        {#if selectedOption === "general"}
                        <General></General>
                        {:else if selectedOption === "editor"}
                        <Editor></Editor>
                        {:else if selectedOption === "metadata"}
                        <Metadata></Metadata>       
                        {:else if selectedOption === "notifications"}
                        <Notifications></Notifications>  
                        {:else if selectedOption === "integrations" && ($authState.plan === 'pro' || $authState.plan === 'enterprise')}
                        <Integrations></Integrations>    
                        {:else if selectedOption === "adr" && ($authState.plan === 'pro' || $authState.plan === 'enterprise')}
                        <Adr></Adr>    
                        {:else if selectedOption === "quickTools"}
                        <QuickTools></QuickTools>
                        {:else if selectedOption === "footPedal"}
                        <FootPedal></FootPedal>
                        {:else if selectedOption === "automaticBackup"}
                        <AutomaticBackup></AutomaticBackup>
                        {:else if selectedOption === "cliTools" && $environment.electron}
                        <CliTools></CliTools>
                        {:else}
                        <Fonts></Fonts>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
.modal-body {
    min-height: 85vh;
}
</style>
