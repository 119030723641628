export default (function reverseRtlStartEnd(text, toStart) {
  let textLines = text.split("\n");
  textLines.forEach((textLine, index, lines) => {
    lines[index] = applyRtlFormattingWithPunctuation(textLine, toStart);
  });
  return textLines.join("\n");
});
function applyRtlFormattingWithPunctuation(text, toStart) {
  // List of punctuation characters to be moved to the start
  let result;
  const punctuation = [',', '.', '!', '?', ':', ';', '…', "♪", "♫"];

  // Convert the string into an array of characters
  const characters = text.split('');

  // Initialize arrays to store characters and punctuation
  const newCharacters = [];
  const punctuationCharacters = [];

  // Iterate through the characters and separate punctuation
  for (const char of characters) {
    if (punctuation.includes(char)) {
      punctuationCharacters.unshift(char); // Move punctuation to the start
    } else {
      newCharacters.push(char);
    }
  }

  // Combine punctuation and characters arrays, and join into a string
  if (toStart) {
    result = newCharacters.concat(punctuationCharacters).join('');
  } else {
    result = punctuationCharacters.concat(newCharacters).join('');
  }
  return result;
}