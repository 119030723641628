import _convertToPlainText from "../quill/convertToPlainText.js";
const convertToPlainText = _convertToPlainText;
export default (function calcTimecodesByCps(event, maxCps = 22, startTime = false, endTime = false) {
  let tc = {
    start: startTime,
    end: endTime
  };

  //Calculate the duration of the event based on the number of characters in event.text and the maxCps.
  //Event.text is html so we need to convert to plaintext
  let text = convertToPlainText(event.text, " ");
  let duration = text.length / maxCps;
  //console.log("text: ", text);
  //console.log("duration: ", duration);
  if (startTime) {
    //console.log("USING START TIME", startTime);
    tc.start = startTime;
    tc.end = startTime + duration;
    if (endTime && tc.end > endTime) {
      tc.end = endTime;
    }
  } else if (endTime) {
    //console.log("USING END TIME", endTime);
    tc.end = endTime;
    tc.start = endTime - duration;
    if (startTime && tc.start < startTime) {
      tc.start = startTime;
    }
  } else {
    //console.log("USING DURATION with no start time");
    tc.start = 0;
    tc.end = duration;
  }
  return tc;
});