import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _htmlEncodePlainText from "../functions/utility/htmlEncodePlainText.js";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import _ittFunc from "../functions/profiles/iTunesTimedText.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _xmlFormat from "xml-formatter";
import _tcLib from "../lib/timecode.js";
import _tcOffset from "../functions/events/tcOffset.js";
import _orderByTime from "../functions/eventGroups/orderByTime.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _frameRatesDict from "../dict/frameRates.js";
import _eol from "eol";
const eol = _eol;
const frameRatesDict = _frameRatesDict;
const removeInvalidEvents = _removeInvalidEvents;
const orderByTime = _orderByTime;
const tcOffset = _tcOffset;
const tcLib = _tcLib;
const xmlFormat = _xmlFormat; //Encode
const XMLParser = _XMLParser;
const ittFunc = _ittFunc;
const ttmlFunc = _ttmlFunc;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const htmlEncodePlainText = _htmlEncodePlainText;
const getFormatOptions = _getFormatOptions;
export default {
  decode: function (input, options) {
    let events = [],
      tcSource = "clock";
    let decodingOptions = getFormatOptions(options.formatOptions);
    if (decodingOptions["Timecode Source"]) {
      tcSource = decodingOptions["Timecode Source"].toLowerCase();
    }
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(fileJson);
    let frameRate = fileJson.tt['@_ttp:frameRate'] || options.frameRate;
    let frameRateMultiplier = fileJson.tt['@_ttp:frameRateMultiplier'];
    let dropFrame = fileJson.tt['@_ttp:dropMode'];
    if (frameRateMultiplier === "999 1000" || frameRateMultiplier === "1000 1001") {
      if (frameRate == 24) {
        frameRate = 23.976;
      } else if (frameRate == 30) {
        frameRate = 29.97;
      }
    }
    frameRate = parseFloat(frameRate);
    if (dropFrame === undefined) {
      dropFrame = frameRatesDict.dropFrameMap[frameRate];
    } else if (dropFrame === "nonDrop") {
      dropFrame = false;
    }
    if (Array.isArray(fileJson.tt.body.div)) {
      fileJson.tt.body.div.forEach(captionBlock => {
        //console.log(captionBlock);
        events = [...events, ...ittFunc.decodeCaptionBlock(captionBlock, frameRate, dropFrame, tcSource)];
      });
    } else {
      //console.log(fileJson.tt.body.div);
      let captionBlock = fileJson.tt.body.div;
      events = [...events, ...ittFunc.decodeCaptionBlock(captionBlock, frameRate, dropFrame, tcSource)];
    }
    return events;
  },
  encode: function (eventGroup, options) {
    let tcSource = "clock";
    let frameRate = ittFunc.frameRateMap[options.frameRate];
    let frameRateMultiplier = ittFunc.frameRateMultiplierMap[options.frameRate];
    let encodingOptions = getFormatOptions(options.formatOptions);
    let languageCode = "en-US";
    if (encodingOptions["Language Code"]) {
      languageCode = encodingOptions["Language Code"] || "en-US";
    }
    if (encodingOptions["Timecode Source"]) {
      tcSource = encodingOptions["Timecode Source"].toLowerCase();
    }
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.w3.org/ns/ttml" xmlns:tt="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xml:lang="${languageCode}" ttp:timeBase="smpte" ttp:frameRate="${frameRate}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:dropMode="${options.dropFrame ? "dropNTSC" : 'nonDrop'}">`);
    output += eol.after(`\t<head>
        <styling>
            <style xml:id="normal" tts:fontFamily="sansSerif" tts:fontWeight="normal" tts:fontStyle="normal" tts:color="white" tts:fontSize="100%"/>
            <style xml:id="bold" tts:fontFamily="sansSerif" tts:fontWeight="bold" tts:fontStyle="normal" tts:color="white" tts:fontSize="100%"/>
            <style xml:id="italic" tts:fontFamily="sansSerif" tts:fontWeight="normal" tts:fontStyle="italic" tts:color="white" tts:fontSize="100%"/>
            <style xml:id="underline" tts:fontFamily="sansSerif" tts:fontWeight="normal" tts:textDecoration='underline' tts:color="white" tts:fontSize="100%"/>
        </styling>
        <layout>
            <region xml:id="top" tts:displayAlign="before" tts:origin="0% 0%" tts:extent="100% 15%" tts:textAlign="center"/>
            <region xml:id="bottom" tts:displayAlign="after" tts:origin="0% 85%" tts:extent="100% 15%" tts:textAlign="center"/>
        </layout>
    </head>`);
    output += eol.after(`\t<body style="normal" region="bottom">`);
    eventGroup = orderByTime(eventGroup);
    let tcStart = tcSource === 'media' ? tcLib.secToTc(eventGroup.events[0].start, options.frameRate) : tcLib.secToTcClock(eventGroup.events[0].start, options.frameRate);
    let hours = tcStart.split(":")[0];
    let offsetAmount = 0;
    if (hours !== "00") {
      output += eol.after(`\t\t<div begin="${hours}:00:00:00">`);
      offsetAmount = tcSource === 'media' ? tcLib.secToTc(hours + ":00:00:00", options.frameRate) : tcLib.secToTcClock(hours + ":00:00:00", options.frameRate);
      eventGroup.events.forEach((event, index, events) => {
        events[index] = tcOffset(event, offsetAmount, "subtract");
      });
    } else {
      output += eol.after(`\t\t<div begin="00:00:00:00">`);
    }
    eventGroup.events.forEach(event => {
      // console.log("---------------")
      //console.log(event.start,event.end, options.frameRate, options.dropFrame);
      let startTc = tcSource === 'media' ? tcLib.secToTc(event.start, options.frameRate) : tcLib.secToTcClock(event.start, options.frameRate);
      let endTc = tcSource === 'media' ? tcLib.secToTc(event.end, options.frameRate) : tcLib.secToTcClock(event.end, options.frameRate);
      //console.log(startTc, endTc);
      // console.log(event.text);
      // console.log(ittFunc.convertToItt(event.text));
      let region = event.yPos === 'start' ? "top" : "bottom";

      // console.log(event.text);
      let plainTextCustom = convertToPlainTextCustom(event.text);

      // console.log(plainTextCustom);
      let text = ttmlFunc.multiLine.convertToTtml(htmlEncodePlainText(plainTextCustom));
      // console.log("----------")
      // console.log(text);

      output += `\t\t\t<p begin="${startTc}" end="${endTc}" region="${region}">${text}</p>\n`;
    });
    output += `\t\t</div>
    </body>
</tt>`;
    return eol.auto(output);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      /* All */
      return eol.lf(input.trim()).replace(/'(\n){3,}'/gim, "\n\n");
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};