<script>
    import { v4 as uuidv4 } from "uuid";
    import { onMount } from "svelte";
    import { onDestroy } from "svelte";
    import { environment } from "@app/store/envStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { explorerContextState } from "@app/store/explorerContextStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { authState } from "@app/store/authStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Project from "@app/external/cc-lib/dist/classes/project.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import Swal from "sweetalert2";
    import { fade } from "svelte/transition";
    import { BarLoader } from "svelte-loading-spinners";
    import { Circle } from "svelte-loading-spinners";
    import insertEvent from "@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js";
    import refreshId from "@app/external/cc-lib/dist/functions/utility/refreshId.js";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    import "firebase/functions";
    import Hls from "hls.js";

    /* 
Data Structures:
    Folder Record
        {
            id : "262-626-426-262462",
            name : "John",
            type : "folder",
            createdOn : firebase.firestore.Timestamp.fromDate(new Date("December 10, 1815")),
            updatedOn : firebase.firestore.Timestamp.fromDate(new Date("December 10, 1815")),
            locationId : null,
            star : false,
        }

*/

    let recordPath = [],
        records = [],
        selectedRecord,
        locationId = null,
        ffmpegRes,
        rootDir = "personal", //personal or team
        userId = firebase.auth().currentUser.uid,
        email = firebase.auth().currentUser.email,
        teamId = $authState.team ? $authState.team.id : null,
        homeRef = db.collection("users").doc(userId).collection("storage"),
        teamRef = teamId
            ? db.collection("teams").doc(teamId).collection("storage")
            : null,
        storageRef = storage.ref(),
        statusMsg = "",
        myDriveUploadBasePath = "users/" + userId + "/projects/",
        teamDriveUploadBasePath = "teams/" + teamId + "/storage/",
        loading = false,
        importing = false,
        /* Move Variables */
        moveInProgress = false,
        recordToMove,
        gettingRecords,
        audioExtractionComplete = false,
        manifestFileExists = false,
        audioExtractionWorker,
        proxyRtWorker,
        ffmpegPath,
        videoOutputPath,
        audioOutputPath;

    const uid = uuidv4();

    //console.log(userId);
    onMount(() => {
        gettingRecords = getRecords();
    });

    onDestroy(() => {
        if (audioExtractionWorker) {
            audioExtractionWorker.terminate();
        }
    });

    function checkFileExistsSync(filePath) {
        try {
            fsSync.accessSync(filePath);
            return true;
        } catch (error) {
            return false;
        }
    }

    async function getFileMetadata(filePath, ffmpegPath) {
        try {
            const exec = window.exec;
            return await exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
        } catch (err) {
            return err.message;
        }
    }
    
    function getMediaIncode(ffmpegOutput) {
        let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
        let mediaIncode = matches ? matches[0].replace(";", ":") : false;
    
        console.log("Media incode detected: " + mediaIncode);
        return mediaIncode == "null" ? false : mediaIncode;
    }
    
    function getMediaFrameRate(ffmpegOutput) {
        // console.log("GETTING MEDIA FRAME RATE");
        //console.log("FFMPEG OUTPUT:");
        //console.log(ffmpegOutput);
    
        let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
        let mediaFrameRate = matches ? matches[0] : false;
    
        console.log("Media frame rate detected: " + mediaFrameRate);
        let fr = frameRates.frameRateMapping[mediaFrameRate];
        return fr;
    }

    function getMediaDuration(ffmpegOutput){
       /*  console.log("GETTING MEDIA DURATION");
        console.log("FFMPEG OUTPUT:");
        console.log(ffmpegOutput); */
        let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
        let mediaDuration = matches ? matches[0] : false;

        console.log(mediaDuration);
        if (mediaDuration){
            mediaDuration = tcLib.tcMsToSec(mediaDuration);
            console.log("media duration in seconds: " + mediaDuration);
        }

        return mediaDuration;
    }

    function openRecord(record) {
        if (record.type === "folder") {
            selectedRecord = undefined;
            locationId = record.id;
            recordPath = locationId ? [...recordPath, record] : [];
            //console.log("Getting Records");
            gettingRecords = getRecords();
        } else if (record.type === "project") {
            $explorerContextState === "save"
                ? overwriteProjectRecord()
                : openProject();
        }
    }

    function selectRecord(record) {
        selectedRecord = record;
    }

    function refreshRecords() {
        gettingRecords = getRecords();
    }

    function getRecords(reset = false) {
        if (loading) {
            return;
        }

        selectedRecord = undefined;
        records = [];
        loading = true;

        if (reset) {
            locationId = null;
            recordPath = [];
        }

        if (rootDir === "team" && !teamId) {
            teamId = $authState.team ? $authState.team.id : null;
            teamRef = teamId
                ? db.collection("teams").doc(teamId).collection("storage")
                : null;
            teamDriveUploadBasePath = "teams/" + teamId + "/storage/";
        }

        return (rootDir === "team" ? teamRef : homeRef)
            .where("locationId", "==", locationId)
            .orderBy("type")
            .orderBy("name", "asc")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log(doc.data());
                    //console.log(doc.id, " => ", doc.data());
                    records = [...records, doc.data()];
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            })
            .finally(() => {
                loading = false;
            });
    }

    function createFolderRecord() {
        if (loading) {
            return;
        }

        selectedRecord = undefined;
        loading = true;
        Swal.fire({
            titleText: "New Folder",
            text: "Please enter a folder name:",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Create",
            denyButtonText: "Cancel",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            inputLabel: "Folder Name",
            inputPlaceholder: "untitled",
            input: "text",
            customClass: {
                confirmButton: "btn btn-lg btn-primary me-2",
                denyButton: "btn btn-lg btn-outline-secondary",
            },
        }).then((result) => {
            //console.log(result);
            if (result.isConfirmed) {
                let folderName = result.value;
                if (folderName) {
                    let folderInfo = {
                        id: uuidv4(),
                        name: folderName,
                        type: "folder",
                        createdOn: firebase.firestore.Timestamp.fromDate(
                            new Date(),
                        ),
                        updatedOn: firebase.firestore.Timestamp.fromDate(
                            new Date(),
                        ),
                        locationId: locationId,
                        shared: rootDir === "team" ? true : false,
                        star: false,
                    };

                    if (rootDir === "team") {
                        teamRef
                            .doc(folderInfo.id)
                            .set(folderInfo)
                            .then(() => {
                                //console.log("Folder Saved successfull");
                                records = [folderInfo, ...records];
                            })
                            .catch((error) => {
                                console.log("Error saving folder: ", error);
                                toast.push(error.message, {
                                    classes: ["toast-danger"],
                                });
                            })
                            .finally(() => {
                                loading = false;
                            });
                    } else {
                        homeRef
                            .doc(folderInfo.id)
                            .set(folderInfo)
                            .then(() => {
                                //console.log("Folder Saved successfull");
                                records = [folderInfo, ...records];
                            })
                            .catch((error) => {
                                console.log("Error saving folder: ", error);
                                toast.push(error.message, {
                                    classes: ["toast-danger"],
                                });
                            })
                            .finally(() => {
                                loading = false;
                            });
                    }
                }
            } else {
                loading = false;
            }
        });
    }

    function deleteRecord() {
        if (loading) {
            return;
        }
        loading = true;
        Swal.fire({
            title: "Are you sure you want to delete this record?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-outline-secondary",
            },
        })
            .then((res) => {
                if (res.isConfirmed) {
                    return (rootDir === "team" ? teamRef : homeRef)
                        .doc(selectedRecord.id)
                        .delete();
                } else {
                    throw new Error("User cancelled delete action");
                }
            })
            .then(() => {
                if (
                    selectedRecord.type === "project" ||
                    selectedRecord.type === "team project"
                ) {
                    let projectFilePath =
                        rootDir === "team"
                            ? teamDriveUploadBasePath + selectedRecord.id
                            : myDriveUploadBasePath + selectedRecord.id;
                    if (rootDir === "team") {
                        return firebase
                            .functions()
                            .httpsCallable("v8DeleteProjectFile")(
                            projectFilePath,
                        );
                    } else {
                        return storageRef.child(projectFilePath).delete();
                    }
                } else {
                    return true;
                }
            })
            .then(() => {
                console.log("Project File Deleted");
                toast.push(selectedRecord.name + " was deleted successfully", {
                    classes: ["toast-success"],
                });
                console.log("Getting Records:");
                setTimeout(() => {
                    gettingRecords = getRecords();
                }, 1000);
            })
            .catch((error) => {
                console.log("Error updating record: ", error);
                toast.push(
                    selectedRecord.name + " failed to delete. " + error.message,
                    {
                        classes: ["toast-danger"],
                    },
                );

                gettingRecords = getRecords();
            })
            .finally(() => {
                loading = false;
            });
    }

    function saveProjectRecord(id) {
        if (loading) {
            return;
        }
        loading = true;
        selectedRecord = undefined;
        Swal.fire({
            titleText: "File Name",
            text: "Please enter a file name:",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Save",
            denyButtonText: "Cancel",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            input: "text",
            inputValue: $projectState.name,
            customClass: {
                confirmButton: "btn btn-lg btn-primary me-2",
                denyButton: "btn btn-lg btn-outline-secondary",
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    //Rename the project name
                    $projectState.name = result.value;
                    //Refresh the id so it's like a save as action or overwrite action
                    $projectState = refreshId($projectState, id);
                    let projectFilePath =
                        rootDir === "team"
                            ? teamDriveUploadBasePath + $projectState.id
                            : myDriveUploadBasePath + $projectState.id;
                    let uploadProjectRef = storageRef.child(projectFilePath);
                    let projectJson = JSON.parse(JSON.stringify($projectState));
                    projectJson.metadata = JSON.parse(
                        JSON.stringify($metadataState),
                    );
                    projectJson.speakers = JSON.parse(
                        JSON.stringify($speakerState),
                    );
                    projectJson.markers = JSON.parse(
                        JSON.stringify($markerState),
                    );
                    projectJson.eventGroups = JSON.parse(
                        JSON.stringify($eventGroupState),
                    );
                    projectJson.style = JSON.parse(JSON.stringify($styleState));
                    return uploadProjectRef.putString(
                        JSON.stringify(projectJson),
                    );
                } else {
                    throw new Error("Saving project was cancelled");
                }
            })
            .then((snapshot) => {
                //console.log("project file saved!", snapshot);
                let projectInfo = {
                    id: $projectState.id,
                    name: $projectState.name,
                    type:
                        $projectState.type === "team"
                            ? "team project"
                            : "project",
                    owner: email,
                    createdOn: firebase.firestore.Timestamp.fromDate(
                        new Date(),
                    ),
                    updatedOn: firebase.firestore.Timestamp.fromDate(
                        new Date(),
                    ),
                    locationId: locationId,
                    shared: rootDir === "team" ? true : false,
                    star: false,
                };
                return (rootDir === "team" ? teamRef : homeRef)
                    .doc(projectInfo.id)
                    .set(projectInfo);
            })
            .then(() => {
                //console.log("Saving Project Record...");
                $projectState.folderId = locationId;
                $projectState.rootDir = rootDir;
                toast.push("Project saved successfully", {
                    classes: ["toast-success"],
                });

                historyState.reset();
                modalState.hideModal();
            })
            .catch((error) => {
                console.log("Error saving project: ", error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            })
            .finally(() => {
                loading = false;
            });
    }

    function startRecordMove() {
        moveInProgress = true;
        recordToMove = selectedRecord;
        selectedRecord = null;
        openRecord({
            id: null,
            type: "folder",
        });
    }

    function completeRecordMove() {
        if (loading) {
            return;
        }
        loading = true;
        (rootDir === "team" ? teamRef : homeRef)
            .doc(recordToMove.id)
            .update({
                locationId: locationId,
            })
            .then(() => {
                cancelRecordMove();
            })
            .catch((error) => {
                console.log("Error updating record: ", error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            })
            .finally(() => {
                loading = false;
            });
    }

    function cancelRecordMove() {
        moveInProgress = false;
        selectedRecord = undefined;
        recordToMove = undefined;
        gettingRecords = getRecords();
    }

    /* Star and Unstar Record */
    function starRecord() {
        if (loading) {
            return;
        }
        loading = true;
        selectedRecord.star = !selectedRecord.star;
        (rootDir === "team" ? teamRef : homeRef)
            .doc(selectedRecord.id)
            .update({
                star: selectedRecord.star,
            })
            .catch((error) => {
                console.log("Error updating record: ", error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            })
            .finally(() => {
                loading = false;
            });
    }

    function openProject() {
        if (loading) {
            console.log("ALREADY LOADING");
            return;
        }

        try {
            console.log("OPENING PROJECT");
            loading = true;
            importing = true;
            toast.push("Loading project in progress...", {
                classes: ["toast-info"],
            });

            let projectFilePath =
                rootDir === "team"
                    ? teamDriveUploadBasePath + selectedRecord.id
                    : myDriveUploadBasePath + selectedRecord.id;

            (rootDir === "team"
                ? firebase.functions().httpsCallable("v8GetDownloadLink")(
                      projectFilePath,
                  )
                : storageRef.child(projectFilePath).getDownloadURL()
            )
                .then((url) => {
                    if (rootDir === "team") {
                        return fetch(url.data);
                    } else {
                        return fetch(url);
                    }
                })
                .then((res) => {
                    return res.json();
                })
                .then((resJson) => {
                    //console.log(resJson);
                    //console.log(selectedRecord);
                    console.log("PROJECT JSON DOWNLOADED. Creating Project...");

                    $eventGroupState = [];
                    $metadataState = [];
                    $speakerState = [];
                    $markerState = {
                        selected: 0,
                        lists: [
                            {
                                id: "0",
                                name: "Shot Changes",
                                color: "#E74C3C",
                                markers: [],
                            },
                        ],
                    };

                    $projectState = new _Project({
                        ...resJson,
                        ...{
                            eventGroups: [],
                            metadata: [],
                            speakers: [],
                            folderId: selectedRecord.locationId,
                            rootDir: rootDir,
                        },
                    });

                    $projectState = refreshId($projectState, selectedRecord.id);

                    console.log("Importing Event Groups and Events...");
                    resJson.eventGroups.forEach(
                        (eventGroup, eventGroupIndex) => {
                            $eventGroupState = [
                                ...$eventGroupState,
                                new _EventGroup({
                                    ...eventGroup,
                                    events: [],
                                }),
                            ];

                            $eventGroupState[$eventGroupState.length - 1].id =
                                eventGroup.id;
                            $eventGroupState[
                                $eventGroupState.length - 1
                            ].extId = eventGroup.extId;

                            eventGroup.events.forEach((event) => {
                                $eventGroupState[eventGroupIndex] = insertEvent(
                                    $eventGroupState[eventGroupIndex],
                                    event,
                                );
                                $eventGroupState[eventGroupIndex].events[
                                    $eventGroupState[eventGroupIndex].events
                                        .length - 1
                                ].id = event.id;
                            });
                        },
                    );

                    resJson.metadata.forEach((metadataInfo) => {
                        $metadataState = [...$metadataState, metadataInfo];
                    });

                    resJson.speakers.forEach((speakerInfo) => {
                        $speakerState = [...$speakerState, speakerInfo];
                    });

                    $markerState = resJson.markers || {
                        selected: 0,
                        lists: [
                            {
                                id: "0",
                                name: "Shot Changes",
                                color: "#E74C3C",
                                markers: [],
                            },
                        ],
                    };

                    for (const [key, value] of Object.entries(resJson.style)) {
                        $styleState[key] = value;
                    }

                    historyState.reset();
                    historyState.insert({
                        name: "import project", //action name
                        eventGroup: false,
                        snapshots: [
                            {
                                store: "eventGroupState",
                                value: JSON.stringify($eventGroupState),
                            },
                        ],
                    });

                    //console.log(resJson.media)
                    if (
                        ((resJson.media.storage === "Local Storage" ||
                            resJson.media.storage === "Proxy RT") &&
                            !$environment.electron) ||
                        resJson.media.localPath === ""
                    ) {
                        console.log("SHOW MEDIA IMPORT PANEL");
                        modalState.showModal("mediaImport");
                    } else {
                        console.log("Import Media Automatically");
                        importMedia();
                    }
                })
                .catch((error) => {
                    console.log(error, error.message);
                    loading = false;
                    toast.push(error.message, {
                        classes: ["toast-danger"],
                    });
                });
        } catch (error) {
            console.log(error);
            loading = false;
        }
    }

    async function importMedia() {
        try {
            loading = true;
            importing = true;
            playerState.updateDuration(false);
            $uiState.timeline = false;
            let source = {};
            switch ($projectState.media.storage) {
                case "Vimeo":
                    source.src = $projectState.media.path;
                    source.provider = "vimeo";
                    player.source = {
                        type: "video",
                        sources: [source],
                    };

                    audioExtractionComplete = true;
                    manifestFileExists = true;
                    break;
                case "YouTube":
                    source.src = $projectState.media.path;
                    source.provider = "youtube";
                    player.source = {
                        type: "video",
                        sources: [source],
                    };

                    audioExtractionComplete = true;
                    manifestFileExists = true;
                    break;
                case "HLS Manifest":
                    audioExtractionComplete = true;
                    loadHlsStream($projectState.media.path);
                    break;
                case "Proxy RT":
                    if (!checkFileExistsSync($projectState.media.localPath)){
                        toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                        modalState.showModal('mediaImport');
                        return;
                    }

                    ffmpegPath = require("ffmpeg-static-electron").path;

                    videoOutputPath = os.tmpdir() + path.sep + uid + ".m3u8";
                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    $projectState.media.path = videoOutputPath;

                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };

                    audioExtractionWorker = new Worker(
                        "./build/workers/audioExtraction.js",
                    );
                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                    });

                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            console.log(msg.data.result);
                        } else {
                            $projectState.media.peaksPath = msg.data.error
                                ? ""
                                : audioOutputPath;
                            $projectState.media.useFallback = false;

                            toast.push(
                                `${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`,
                                {
                                    classes: [
                                        'toast-`${msg.data.error ? "danger" : "success"}`,',
                                    ],
                                },
                            );

                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };

                    proxyRtWorker = new Worker("./build/workers/proxyRt.js");
                    proxyRtWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: videoOutputPath,
                        ffmpegPath: ffmpegPath,
                        tmpDir: os.tmpdir(),
                        pathSep: path.sep,
                    });

                    proxyRtWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status_msg) {
                            loadHlsStream(videoOutputPath);
                        } else if (msg.data.error) {
                            manifestFileExists = true;
                            toast.push(`${msg.data.error}`, {
                                classes: ["toast-danger"],
                            });
                            closeModal();
                        }
                    };

                    break;
                case "Cloud Storage":
                    /* Cloud Storage*/
                    source.src = $projectState.media.path;
                    if ($projectState.media.type === "video/quicktime") {
                        source.type = "video/mp4";
                    } else if (
                        $projectState.media.type === "video/x-matroska"
                    ) {
                        source.type = "video/mkv";
                    } else {
                        source.type = $projectState.media.type;
                    }

                    //Get file size of url
                    let fileSize = await getFileSize($projectState.media.path);
                    if (fileSize) {
                        const sizeInBytes = parseInt(fileSize);
                        const sizeInKilobytes = sizeInBytes / 1024;
                        const sizeInMegabytes = sizeInKilobytes / 1024;
                        if (sizeInMegabytes < 512) {
                            $projectState.media.useFallback = false;
                        }
                    }

                    audioExtractionComplete = true;
                    manifestFileExists = true;

                    player.source = {
                        type: "video",
                        sources: [source],
                    };

                    break;
                default:
                    /* Local Storage */
                    if (!checkFileExistsSync($projectState.media.localPath)){
                        toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                        modalState.showModal('mediaImport');
                        return;
                    }
                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    ffmpegPath = require("ffmpeg-static-electron").path;

                    source.src = $projectState.media.path;
                    source.type =
                        $projectState.media.type === "video/quicktime"
                            ? "video/mp4"
                            : $projectState.media.type;
                    manifestFileExists = true;

                    source.src = $projectState.media.localPath;

                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };

                    audioExtractionWorker = new Worker(
                        "./build/workers/audioExtraction.js",
                    );

                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                        duration: $projectState.media.info.duration,
                    });

                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            statusMsg = msg.data.result;
                        } else {
                            $projectState.media.peaksPath = msg.data.error
                                ? ""
                                : audioOutputPath;
                            $projectState.media.useFallback = false;

                            player.source = {
                                type: "video",
                                sources: [source],
                            };

                            toast.push(
                                `${msg.data.error ? msg.data.error : "Media import completed successfully."}`,
                                {
                                    classes: [
                                        `toast-${msg.data.error ? "danger" : "success"}`,
                                    ],
                                },
                            );

                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };
            }

            closeModal();
        } catch (err) {
            toast.push("Error - Media import failed", {
                classes: ["toast-danger"],
            });

            importing = false;
        }
    }

    function loadHlsStream(mediaPath) {
        const hls = new Hls();
        const videoTag = document.querySelector("video");

        hls.loadSource(mediaPath);
        hls.attachMedia(videoTag);

        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            console.log("video and hls.js are now bound together !");
        });

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            console.log(
                "manifest loaded, found " +
                    data.levels.length +
                    " quality level",
            );
            manifestFileExists = true;
            closeModal();
        });
    }

    function closeModal() {
        console.log(
            "Close Modal Called",
            audioExtractionComplete,
            manifestFileExists,
        );
        if (audioExtractionComplete && manifestFileExists) {
            statusMsg = "Project import complete...";

            setTimeout(() => {
                $uiState.timeline = true;
                loading = false;
                modalState.hideModal();
            }, 2500);
        }
    }

    function overwriteProjectRecord() {
        let id = selectedRecord.id;
        if (loading) {
            return;
        }

        loading = true;
        selectedRecord = undefined;
        // Use sweetalert to ask user to confirm they wish to overwrite the selected record
        Swal.fire({
            titleText: "Overwrite Project",
            text: "Are you sure you want to overwrite this project?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Overwrite",
            denyButtonText: "Cancel",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-lg btn-primary me-2",
                denyButton: "btn btn-lg btn-outline-secondary",
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    //Refresh the id so it's like a save as action or overwrite action
                    $projectState = refreshId($projectState, id);
                    let projectFilePath =
                        rootDir === "team"
                            ? teamDriveUploadBasePath + $projectState.id
                            : myDriveUploadBasePath + $projectState.id;
                    let uploadProjectRef = storageRef.child(projectFilePath);
                    let projectJson = JSON.parse(JSON.stringify($projectState));
                    projectJson.metadata = JSON.parse(
                        JSON.stringify($metadataState),
                    );
                    projectJson.speakers = JSON.parse(
                        JSON.stringify($speakerState),
                    );
                    projectJson.markers = JSON.parse(
                        JSON.stringify($markerState),
                    );
                    projectJson.eventGroups = JSON.parse(
                        JSON.stringify($eventGroupState),
                    );
                    projectJson.style = JSON.parse(JSON.stringify($styleState));
                    return uploadProjectRef.putString(
                        JSON.stringify(projectJson),
                    );
                } else {
                    throw new Error("Saving project was cancelled");
                }
            })
            .then((snapshot) => {
                console.log("project file saved!", snapshot);
                let projectInfo = {
                    id: $projectState.id,
                    name: $projectState.name,
                    type:
                        $projectState.type === "team"
                            ? "team project"
                            : "project",
                    owner: email,
                    createdOn: firebase.firestore.Timestamp.fromDate(
                        new Date(),
                    ),
                    updatedOn: firebase.firestore.Timestamp.fromDate(
                        new Date(),
                    ),
                    locationId: locationId,
                    shared: rootDir === "team" ? true : false,
                    star: false,
                };

                return (rootDir === "team" ? teamRef : homeRef)
                    .doc(projectInfo.id)
                    .set(projectInfo);
            })
            .then(() => {
                //console.log("Saving Project Record...");
                $projectState.folderId = locationId;
                $projectState.rootDir = rootDir;
                toast.push("Project saved successfully", {
                    classes: ["toast-success"],
                });

                historyState.reset();
                modalState.hideModal();
            })
            .catch((error) => {
                console.log("Error saving project: ", error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            })
            .finally(() => {
                loading = false;
            });
    }

    function goBackToRecord(index) {
        if (index > -1) {
            locationId = recordPath[index].id;
            gettingRecords = getRecords();
            recordPath.splice(index + 1);
            recordPath = recordPath;
            selectedRecord = undefined;
        } else {
            openRecord({
                id: null,
                type: "folder",
            });
        }
    }

    function updateRootDir(dir) {
        rootDir = dir;
        gettingRecords = getRecords(true);
    }

    function replaceFilename(path, newFilename) {
        // Find the last occurrence of the path separator, checking for both types
        const lastIndexSlash = path.lastIndexOf("/");
        const lastIndexBackslash = path.lastIndexOf("\\");
        const lastIndex = Math.max(lastIndexSlash, lastIndexBackslash);

        // Extract the directory path and append the new filename
        const newPath = path.substring(0, lastIndex + 1) + newFilename;

        return newPath;
    }

    async function getFileSize() {
        try {
            const response = await fetch($projectState.media.path, {
                method: "HEAD",
            });
            const fileSize = response.headers.get("content-length");
            return fileSize;
        } catch (err){
            return false;
        }    
    }
</script>

k
<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'storageExplorer' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-capitalize">
                    {$explorerContextState} Project
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    {#if !moveInProgress}
                        <div class="col-2 p-4">
                            <h5><i class="bi bi-hdd"></i> Storage</h5>
                            <hr />
                            <ul class="nav nav-pills flex-column">
                                <li class="nav-item">
                                    <a
                                        class="nav-link {rootDir === 'personal'
                                            ? 'active'
                                            : ''}"
                                        href="#!/"
                                        on:click={() =>
                                            updateRootDir("personal")}
                                        >My Drive</a
                                    >
                                </li>
                                {#if $authState.team}
                                    <li class="nav-item">
                                        <a
                                            class="nav-link {rootDir === 'team'
                                                ? 'active'
                                                : ''}"
                                            href="#!/"
                                            on:click={() =>
                                                updateRootDir("team")}
                                            >Team Drive</a
                                        >
                                    </li>
                                {/if}
                            </ul>
                            {#if selectedRecord}
                                <hr />
                                <p class="text-muted mb-0 text-truncated text-custom-small">{selectedRecord.id}</p>
                                <p class="fw-bold">
                                    <i
                                        class="bi {selectedRecord.type ===
                                        'folder'
                                            ? 'bi-folder-fill text-warning'
                                            : ''} {selectedRecord.type ===
                                        'project'
                                            ? 'bi-file-earmark-play-fill text-info'
                                            : ''} {selectedRecord.type ===
                                        'team project'
                                            ? 'bi-people-fill text-info'
                                            : ''}"
                                    ></i>
                                    {selectedRecord.name}
                                </p>
                               
                                <p class="text-muted mb-1 text-truncate">
                                    Owner: {selectedRecord.owner ?? email}
                                </p>
                                <p class="text-muted mb-1">
                                    Created On: {selectedRecord.createdOn
                                        .toDate()
                                        .toLocaleDateString()}
                                </p>
                                <p class="text-muted mb-1">
                                    Last Updated: {selectedRecord.updatedOn
                                        .toDate()
                                        .toLocaleDateString()}
                                </p>
                            {/if}
                        </div>
                    {/if}
                    <div class="col">
                        <div
                            class="m-3 rounded-lg shadow border border-secondary"
                        >
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-outline-dark m-3"
                                    title="Refresh"
                                    on:click={refreshRecords}
                                    ><i class="bi bi-arrow-repeat"></i></button
                                >
                                {#if moveInProgress}
                                    <span
                                        class="text-danger fw-bold font-italic"
                                        >Move record to... {recordPath[
                                            recordPath.length - 1
                                        ]
                                            ? recordPath[recordPath.length - 1]
                                                  .name
                                            : "Home"}</span
                                    >
                                    <div class="btn-group btn-group-sm ms-2">
                                        <button
                                            type="button"
                                            class="btn btn-info text-white"
                                            title="Refresh"
                                            on:click={completeRecordMove}
                                            >Yes</button
                                        >
                                        <button
                                            type="button"
                                            class="btn btn-light"
                                            title="Refresh"
                                            on:click={cancelRecordMove}
                                            >Cancel</button
                                        >
                                    </div>
                                {/if}
                                <div class="btn-group float-end p-3">
                                    <button
                                        type="button"
                                        class="btn btn-outline-primary"
                                        title="New Folder Record"
                                        on:click={createFolderRecord}
                                        ><i class="bi bi-folder-plus"
                                        ></i></button
                                    >
                                    {#if selectedRecord}
                                        <button
                                            type="button"
                                            class="btn btn-outline-danger"
                                            title="Delete Record"
                                            transition:fade={{ duration: 100 }}
                                            on:click={deleteRecord}
                                            ><i class="bi bi-trash"></i></button
                                        >
                                        <!-- Move Record -->
                                        {#if !moveInProgress}
                                            <button
                                                type="button"
                                                class="btn btn-outline-dark"
                                                title="Move Record"
                                                transition:fade={{
                                                    duration: 100,
                                                }}
                                                on:click={startRecordMove}
                                                ><i class="bi bi-arrow-down-up"
                                                ></i></button
                                            >
                                        {/if}
                                        <button
                                            type="button"
                                            class="btn btn-outline-warning me-3"
                                            title="Star/Unstar Record"
                                            transition:fade={{ duration: 100 }}
                                            on:click={starRecord}
                                            ><i
                                                class="bi bi-star{selectedRecord &&
                                                selectedRecord.star
                                                    ? '-fill'
                                                    : ''}"
                                            ></i></button
                                        >

                                        <button
                                            type="button"
                                            class="btn btn-light"
                                            title="De-Select Record"
                                            transition:fade={{ duration: 100 }}
                                            on:click={() => {
                                                selectedRecord = undefined;
                                            }}
                                            ><i class="bi bi-x-circle"></i> De-select</button
                                        >
                                    {/if}
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover table-light">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            {#if rootDir === "team"}
                                                <th>Owner</th>
                                            {/if}
                                            <th>Created On</th>
                                            <th>Last Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {#await gettingRecords}
                                            <div style="margin: 5% 35%">
                                                <BarLoader
                                                    size="160"
                                                    color="#1eb4b2"
                                                    unit="px"
                                                    duration="3s"
                                                ></BarLoader>
                                            </div>
                                        {:then}
                                            {#if recordPath.length > 0}
                                                <tr
                                                    on:dblclick={() =>
                                                        goBackToRecord(
                                                            recordPath.length -
                                                                2,
                                                        )}
                                                    title="Double click to go back one folder"
                                                >
                                                    <td
                                                        ><i
                                                            class="bi bi-three-dots"
                                                        ></i></td
                                                    >
                                                </tr>
                                            {/if}
                                            {#each records as record (record.id)}
                                                {#if !recordToMove || record.id !== recordToMove.id}
                                                    <tr
                                                        transition:fade={{
                                                            duration: 100,
                                                        }}
                                                        on:dblclick={openRecord(
                                                            record,
                                                        )}
                                                        on:click={selectRecord(
                                                            record,
                                                        )}
                                                        class={selectedRecord &&
                                                        record.id ===
                                                            selectedRecord.id
                                                            ? "table-warning"
                                                            : ""}
                                                    >
                                                        <td
                                                            class="text-truncate {record.type ===
                                                            'folder'
                                                                ? 'fw-bold'
                                                                : ''}"
                                                            title={record.name}
                                                        >
                                                            <i
                                                                class="bi {record.type ===
                                                                'folder'
                                                                    ? 'bi-folder-fill text-warning'
                                                                    : ''} {record.type ===
                                                                'project'
                                                                    ? 'bi-file-earmark-play-fill text-info'
                                                                    : ''} {record.type ===
                                                                'team project'
                                                                    ? 'bi-people-fill text-info'
                                                                    : ''}"
                                                            ></i>
                                                            {record.name}
                                                            {#if record.star}
                                                                <i
                                                                    class="bi bi-star-fill text-secondary"
                                                                ></i>
                                                            {/if}
                                                        </td>
                                                        <td>{record.type}</td>
                                                        {#if rootDir === "team"}
                                                            <td
                                                                class="text-truncate"
                                                                title={record.owner ??
                                                                    ""}
                                                                >{record.owner ??
                                                                    ""}</td
                                                            >
                                                        {/if}
                                                        <td
                                                            class="text-truncate"
                                                            title={record.createdOn.toDate()}
                                                            >{record.createdOn.toDate()}</td
                                                        >
                                                        <td
                                                            class="text-truncate"
                                                            title={record.updatedOn.toDate()}
                                                            >{record.updatedOn.toDate()}</td
                                                        >
                                                    </tr>
                                                {/if}
                                            {/each}
                                        {/await}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-inline">
                <div class="btn-group ps-3">
                    <button
                        type="button"
                        class="btn btn-link ps-0 pe-0"
                        on:click={() =>
                            openRecord({ id: null, type: "folder" })}
                        ><i class="bi bi-house"></i>
                        {rootDir === "team" ? "Team Drive" : "My Drive"} /</button
                    >
                    {#each recordPath as record, index}
                        <button
                            type="button"
                            class="btn btn-link ps-0 pe-0"
                            on:click={() => goBackToRecord(index)}
                            >{record.name} /</button
                        >
                    {/each}
                </div>
                {#if !moveInProgress}
                    <div class="btn-group float-end me-3">
                        {#if selectedRecord && (selectedRecord.type === "project" || selectedRecord.type === "team project") && $explorerContextState === "save"}
                            <button
                                type="button"
                                class="btn btn-danger text-white"
                                disabled={loading}
                                on:click={overwriteProjectRecord}
                                ><i class="bi bi-save-fill"></i> Overwrite</button
                            >
                        {:else if $explorerContextState === "save"}
                            <button
                                type="button"
                                class="btn btn-primary"
                                disabled={loading}
                                on:click={() => saveProjectRecord(null)}
                                ><i class="bi bi-save"></i> Save Project</button
                            >
                        {/if}

                        {#if selectedRecord && (selectedRecord.type === "project" || selectedRecord.type === "team project") && $explorerContextState === "load"}
                            {#if importing}
                                <p class="text-muted small m-2 mb-0">
                                    {statusMsg || "Loading project..."}
                                </p>
                                <div class="m-1">
                                    <Circle
                                        size="30"
                                        color="#1eb4b2"
                                        unit="px"
                                        duration="1s"
                                    ></Circle>
                                </div>
                            {/if}
                            <button
                                type="button"
                                class="btn btn-primary ms-4"
                                disabled={loading || importing}
                                on:click={openProject}
                                ><i class="bi bi-download"></i> Open Project</button
                            >
                        {/if}
                        {#if selectedRecord && selectedRecord.type === "folder"}
                            <button
                                type="button"
                                class="btn btn-light"
                                disabled={loading}
                                on:click={openRecord(selectedRecord)}
                                ><i class="bi bi-folder2-open"></i> Open Folder</button
                            >
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
    table {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 60vh;
    }

    thead {
        flex: 0 0 auto;
    }

    tbody {
        flex: 1 1 auto;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
    }

    tr {
        width: 100%;
        display: table;
        table-layout: fixed;
    }
</style>
