<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    externalDataState
} from "@app/store/externalDataStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    fade
} from "svelte/transition";
import ISO6391 from 'iso-639-1';
import Swal from 'sweetalert2'

import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";

/* Firebase */
import firebase from '@app/configs/firebase.js';
import storage from '@app/configs/storage.js';

let languages = ISO6391.getAllCodes();
let uploadBasePath = "users/" + firebase.auth().currentUser.uid + "/iconik/";
let eventListElement = document.getElementById("EventList");
let iconikStorages = $externalDataState.storages || [],
    iconikStorage = iconikStorages[0].id, 
    profile = "webVtt",
    publishing = false,
    ignoreAll = false,
    progress = 0,    
    statusMsg = "Starting publish workflow";

async function updateStatus(p, m) {
    progress = p;
    statusMsg = m;
}

async function cancelExport(eventGroupId, eventId) {
    toast.push("Subtitle export was aborted", {classes: ['toast-warning']});

    $projectState.selected = eventGroupId;
    $eventGroupState[$projectState.selected].selected = [eventId];
    setTimeout(() => {
        try {
            eventListElement.scrollTo(0, eventId * 230);
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, eventId * 230);
        }
    }, 250);

    modalState.hideModal();
}

function alertUser(msg) {
    let response = Swal.fire({
        titleText: "Export Pre-Checks",
        text: msg,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Abort',
        denyButtonText: 'Ignore',
        cancelButtonText: 'Ignore All',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-danger',
            denyButton: 'btn btn-light mx-2',
            cancelButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
            return false;
        } else if (result.isDismissed) {
            ignoreAll = true;
            return false;
        }
    });

    return response;
}

async function preExportCheck(events) {
    for (let i = 0; i < events.length; i++) {
        if (!ignoreAll) {
            if (events[i].text) {
                if (isNaN(events[i].start)) {
                    if (await alertUser(`Event ${i+1} is missing a start time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (isNaN(events[i].end)) {
                    if (await alertUser(`Event ${i+1} is missing an end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start === events[i].end) {
                    if (await alertUser(`Event ${i+1} has the same start and end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start > events[i].end) {
                    if (await alertUser(`Event ${i+1} has a start time greater than its end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (i > 0 && events[i].start < events[i - 1].end) {
                    /* Adding check in case overlap is less than a frame */
                    if (events[i - 1].end - events[i].start < 1 / $projectState.frameRate) {
                        events[i].start = events[i - 1].end;
                    } else if (await alertUser(`Event ${i+1} overlaps with the previous event.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                }
            } else {
                if (await alertUser(`Event ${i+1} has no text.\n Would you like to ABORT your export?`)) {
                    return {
                        err: true,
                        eventId: i
                    }
                };
            }
        }

    }

    return {
        err: false
    }
}

async function publish() {
    try {
        publishing = true;
        /* Translate for Creator */
        let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
        let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
        let selectedStorage = iconikStorages.find(s => {
            return s.id === iconikStorage;
        });

        console.log(selectedStorage);
        let storageMethod = selectedStorage.method;

        updateStatus(10, "Creating Project");
        let projectJson = JSON.parse(JSON.stringify($projectState));
        projectJson.eventGroups = [];
        for (let i = 0; i<$eventGroupState.length; i++){

            let copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[i]));
            copyOfEventGroup = orderByTime(copyOfEventGroup);
            copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);
            if (copyOfEventGroup.publish){
                let preCheckResult = await preExportCheck(copyOfEventGroup.events);
                if (preCheckResult.err) {
                    cancelExport(i, preCheckResult.eventId)
                } else {
                    projectJson.eventGroups.push(copyOfEventGroup);
                }
                
                if (!copyOfEventGroup.iconikOverwrite){
                    copyOfEventGroup.extId = "";
                }
            }            
        }

        updateStatus(30, "Saving Project");
        let storageRef = storage.ref();
        await storageRef.child(uploadBasePath + $projectState.id).putString(JSON.stringify(projectJson));
        updateStatus(50, "Creating Publish Job");
        let publishRes = await firebase.functions().httpsCallable("v8IconikPublish")({
            storageId: iconikStorage,
            projectId: $projectState.id,
            storageMethod: storageMethod || "GCS",
            filePath: uploadBasePath + $projectState.id,
            assetId: $externalDataState.assetId,
            profile: profile,
            window: {
                "height": playerHeight,
                "width": playerWidth,
                "xOffset": playerWidth * 0.1,
                "yOffset": playerHeight * 0.1
            }
        });

        console.log(publishRes);

        if (publishRes.data && publishRes.data.result !== "passed") {
            throw new Error("Publish Failed." + publishRes.data.message);
        }

        updateStatus(75, "Cleaning Up");

        toast.push("Project was published successfully", {classes: ['toast-success']});
        updateStatus(100, "Done");

        await Swal.fire({
            titleText: "Publish Successfull",
            text: "Publishing was successful. Please verify the asset in Iconik before exiting.",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',            
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-primary'                
            }
        });

        modalState.hideModal();
    } catch (err) {
        console.log(err, err.message);
        toast.push("Project failed to publish with error: " + err.message, {classes: ['toast-danger']});
    } finally {
        publishing = false;
        statusMsg = "";
        progress = 0;
    }
}

function togglePublishAll() {
    $eventGroupState.forEach((evg, index, eventGroups) => {
        eventGroups[index].publish = !evg.publish;
    });

    $eventGroupState = $eventGroupState;
}

function toggleClosedCaptionAll() {
    $eventGroupState.forEach((evg, index, eventGroups) => {
        eventGroups[index].cc = !evg.cc;
    });

    $eventGroupState = $eventGroupState;
}

function toggleOverwriteAll(){
    $eventGroupState.forEach((evg, index, eventGroups) => {
        eventGroups[index].iconikOverwrite = !evg.iconikOverwrite;
    });

    $eventGroupState = $eventGroupState;
}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'iconikPublish' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="LoadExternalProjectModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><img width="23px" src="./assets/img/iconik-icon.ico" alt="iconik icon"> Iconik Publish</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6 mb-3">
                        {#if iconikStorages.length > 0}
                        <div>
                            <label class="form-label" for="iconikStorageselect">Storage Location</label>
                            <select class="form-select" disabled="{publishing}" bind:value="{iconikStorage}">
                                {#each iconikStorages as storageOption (storageOption.id)}
                                <option value={storageOption.id}>{storageOption.name}</option>
                                {/each}
                            </select>
                        </div>
                        {:else}
                        <p class="text-danger small">ERROR: Unable to publish | Access to storage denied.</p>
                        {/if}
                    </div>
                    <div class="col-6 mb-3">
                        <div>
                            <label class="form-label" for="profileSelect">Subtitle Format</label>
                            <select class="form-select" disabled="{publishing}" bind:value="{profile}">
                                <option>webVtt</option>
                                <option>subRip</option>
                                <option>scenerist</option>
                                <option>ebuStl</option>                          
                            </select>
                        </div>
                    </div>
                    <div class="col-12 mb-2 d-flex align-items-end justify-content-end">
                        <div class="btn-group btn-group-sm" role="group">
                            <button type="button" disabled="{publishing}" class="btn btn-outline-primary" title="Publish All" on:click="{() => togglePublishAll()}"><i class="bi bi-check"></i> Publish All</button>
                            <button type="button" disabled="{publishing}" class="btn btn-outline-primary" title="Mark All AS CC"  on:click="{() => toggleClosedCaptionAll()}"><i class="bi bi-check"></i> CC All</button>
                            <button type="button" disabled="{publishing}" class="btn btn-outline-primary" title="Overwrite ALL"  on:click="{() => toggleOverwriteAll()}"><i class="bi bi-check"></i> Overwrite All</button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive" style="max-height: 500px;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Event Group</th>
                                <th scope="col">Language Code</th>
                                <th scope="col"><i class="bi bi-badge-cc"></i></th>
                                <th scope="col">Publish</th>
                                <th scope="col">Overwrite</th>
                            </tr>
                        </thead>
                        <tbody>
                            {#each $eventGroupState as eventGroupOption}
                            <tr>
                                <th>{eventGroupOption.name}</th>
                                <td>
                                    <select disabled="{publishing}" class="form-select" bind:value={eventGroupOption.language}>
                                        {#each languages as languageOption, index(index)}
                                        <option>{languageOption}</option>
                                        {/each}
                                    </select>
                                </td>
                                <td>
                                    <input class="form-check-input" disabled="{publishing}" type="checkbox" bind:checked={eventGroupOption.cc}>
                                </td>
                                <td>
                                    <input class="form-check-input" disabled="{publishing}" type="checkbox" bind:checked={eventGroupOption.publish}>
                                </td>
                                <td>
                                    <input class="form-check-input" disabled="{publishing}" type="checkbox" bind:checked={eventGroupOption.iconikOverwrite}>
                                </td>
                            </tr>
                            {/each}
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="modal-footer">
                {#if publishing}
                <div class="progress w-25" role="progressbar" aria-label="Animated striped example" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: {progress}%"></div>
                </div>
                <p class="text-center small text-muted">{statusMsg} ({progress}%)</p>
                {/if}
                <button type="button" class="btn btn-primary" disabled="{publishing}" on:click={() => publish()}>Publish Now</button>
                <button type="button" class="btn btn-light" disabled="{publishing}" on:click={modalState.hideModal}>Cancel</button>
            </div>
        </div>
    </div>
</div>
