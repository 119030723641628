import _subtractIncode from "./special/subtractIncode.js";
import _applyWindowOffsets from "./special/applyWindowOffsets.js";
import _defaults from "../lib/defaults.js";
import _decoder from "../profiles/index.js";
import _EventGroup from "../classes/eventGroup.js";
const EventGroup = _EventGroup;
const decoder = _decoder;
const defaults = _defaults;
const applyWindowOffsets = _applyWindowOffsets;
const subtractIncode = _subtractIncode;
export default (async function (input, options = new defaults.options()) {
  if (decoder[options.profile]) {
    let eventGroup = new EventGroup();
    /* Pre-Process Decode */
    input = decoder[options.profile].preProcess.decode(input, options);
    /* Decode */
    eventGroup.events = await decoder[options.profile].decode(input, options);
    /* Post-Process Decode */
    eventGroup = decoder[options.profile].postProcess.decode(eventGroup, options);
    /* Remove incode from start/end times */
    eventGroup = subtractIncode(eventGroup, options);
    /* Apply Window Offset and Return Event Group */
    eventGroup = applyWindowOffsets(eventGroup, options.window, "subtract");
    //Return Event Group
    return eventGroup;
  } else {
    throw new Error(`Source profile [${options.profile}] does not exist. Please ensure name matches a supported decoding profile. Profile names are case-sensitive.`);
  }
});