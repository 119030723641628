import _ssaFunc from "../functions/profiles/subStationAlpha.js";
import _getNearestColor from "../functions/utility/getNearestColor.js";
import _eol from "eol";
import _replaceUnderlineTags from "../functions/quill/replaceUnderlineTags.js";
import _replaceItalicTags from "../functions/quill/replaceItalicTags.js";
import _replaceBoldTags from "../functions/quill/replaceBoldTags.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const replaceBoldTags = _replaceBoldTags;
const replaceItalicTags = _replaceItalicTags;
const replaceUnderlineTags = _replaceUnderlineTags;
const eol = _eol;
const getNearestColor = _getNearestColor;
const ssaFunc = _ssaFunc;
export default {
  decode: function (input, options) {
    let events = [],
      styleMap = {};
    let decodingOptions = getFormatOptions(options.formatOptions);
    let tcPattern = new RegExp(/\d{1}:\d{2}:\d{2}.\d{1,2}/g);
    let stylePattern = new RegExp(/^Style: /gi);
    const fileLines = eol.split(input).filter(fileLine => {
      return fileLine;
    });
    let playResX = input.match(/PlayResX:\s*(\d+)/)[1];
    let playResY = input.match(/PlayResY:\s*(\d+)/)[1];

    //console.log(playResX, playResY);

    let excludedStyleTypes = decodingOptions["Excluded Style Types"] ? decodingOptions["Excluded Style Types"].split(",") : [];
    fileLines.forEach(fileLine => {
      let matches = fileLine.match(tcPattern);
      let styleMatches = fileLine.match(stylePattern);
      if (matches && matches.length >= 2) {
        let parts = fileLine.split(",");
        let styleName = parts[3].trim();
        styleName = styleName ? styleName.toLowerCase() : "";
        let style = styleMap[styleName];
        //console.log("-------------");
        // console.log("Style: ", styleName, style);

        parts.splice(0, 9);
        let ccEvent = new Event({
          xOffset: 0,
          yOffset: 0
        });
        ccEvent.start = tcLib.centisecToSeconds(matches[0]);
        ccEvent.end = tcLib.centisecToSeconds(matches[1]);
        let text = parts.join(",");
        //console.log(`Before: ${text}`);

        if (excludedStyleTypes.includes(styleName)) {
          return;
        }
        if (style && style.alignment) {
          if (ssaFunc.alignmentMap[style.alignment]) {
            ccEvent.xPos = ssaFunc.alignmentMap[style.alignment].xPos;
            ccEvent.yPos = ssaFunc.alignmentMap[style.alignment].yPos;
            ccEvent.alignment = ssaFunc.alignmentMap[style.alignment].alignment;
          }
        }

        //Apply styling based on style map
        if (style && style.underline != 0) {
          text = `{\\u1}` + text.split(/\\N/g).join(`{\\u0}\\N{\\u1}`) + `{\\u0}`;
        }
        if (style && style.italic != 0) {
          text = `{\\i1}` + text.split(/\\N/g).join(`{\\i0}\\N{\\i1}`) + `{\\i0}`;
        }
        if (style && style.bold != 0) {
          text = `{\\b1}` + text.split(/\\N/g).join(`{\\b0}\\N{\\b1}`) + `{\\b0}`;
        }
        if (style && style.primaryColor) {
          let nearestColor = getNearestColor("#" + style.primaryColor.slice(-6));
          if (nearestColor.name !== "white") {
            let bgrColor = ssaFunc.convertRGBtoBGR(nearestColor.value.replace("#", ""));
            text = `{\\c&H${bgrColor}&}` + text.split(/\\N/g).join(`{\\c&H${bgrColor}&}\\N{\\c&H${bgrColor}&}`) + `{\\c&H${bgrColor}&}`;
          }
        }

        //console.log(`During: ${text}`);

        // let subtitlePosition = ssaFunc.getPositionFromSubtitle(text);
        // ccEvent.xPos = subtitlePosition.x;
        // ccEvent.yPos = subtitlePosition.y;

        // if (ccEvent.yPos === "end"){
        //     ccEvent.yOffset = options.window.height*-0.10;
        // } else if (ccEvent.yPos === "start"){
        //     ccEvent.yOffset = options.window.height*0.10;
        // }

        text = ssaFunc.parseCmdCodes(ccEvent, text, options, playResX, playResY);
        /* 
        if (ccEvent.yPos === "end"){
            ccEvent.yOffset = options.window.height*-0.10;
        } else if (ccEvent.yPos === "start"){
            ccEvent.yOffset = options.window.height*0.10;
        } */

        //console.log(`After: ${text}`);
        //console.log("Position: ", ccEvent.xPos, ccEvent.yPos, ccEvent.yOffset, ccEvent.xOffset, ccEvent.alignment);
        //console.log("Timing:", ccEvent.start, ccEvent.end);

        ccEvent.text = convertToHtml(text);
        events.push(ccEvent);
      } else if (styleMatches) {
        styleMap = ssaFunc.parseStyles(fileLine, styleMap);
      }
    });

    // console.log(JSON.stringify(events,null,4));
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    // console.log(JSON.stringify(encodingOptions, null, 4));

    let output = "",
      videoWidth = encodingOptions['Video Width'] || 1280,
      videoHeight = encodingOptions['Video Height'] || 720,
      borderStyle = encodingOptions['Border Style'] || "3",
      fontFamily = encodingOptions['Font Family'] || "Arial",
      fontSize = encodingOptions['Font Size'] || 24,
      fontColor = encodingOptions['Font Color'] || "#FFFFFF",
      opacity = encodingOptions['Opacity'] || 100,
      spacing = encodingOptions['Letter Spacing'] || "0",
      shadow = encodingOptions['Shadow Size'] || "0",
      shadowColor = encodingOptions['Shadow Color'] || "#000000",
      shadowOpacity = encodingOptions['Shadow Opacity'] || 100,
      outline = encodingOptions['Outline Size'] || 1,
      olineColor = encodingOptions['Outline Color'] || "#000000",
      outlineOpacity = encodingOptions['Outline Opacity'] || 100,
      scaleX = encodingOptions['ScaleX'] || 100,
      scaleY = encodingOptions['ScaleY'] || 100;
    let videoHeightOffsetPx = videoHeight * 0.10;
    let primaryColor = ssaFunc.encodeColor(fontColor, opacity),
      secondaryColor = ssaFunc.encodeColor(fontColor, opacity),
      outlineColor = ssaFunc.encodeColor(olineColor, outlineOpacity),
      backgroundColor = ssaFunc.encodeColor(shadowColor, shadowOpacity);
    output += eol.after("[Script Info]");
    output += eol.after("; Script generated by Closed Caption Converter | www.closedcaptionconverter.com");
    output += eol.after("ScriptType: v4.00+");
    output += eol.after("Collisions: Normal");
    output += eol.after("PlayDepth: 0");
    output += String(eol.auto);
    output += eol.after("[V4+ Styles]");
    output += eol.after("Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding");
    output += eol.after(`Style: Default, Arial, 24,&H00B4FCFC,&H00B4FCFC,&H00000008,&H80000008,0,0,0,0,100,100,0.00,0.00,1,1.00,2.00,2,0,0,0,0`);
    output += eol.after(`Style: TopLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},7,0,0,0,0`);
    output += eol.after(`Style: TopCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},8,0,0,0,0`);
    output += eol.after(`Style: TopRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},9,0,0,0,0`);
    output += eol.after(`Style: CenterLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},4,0,0,0,0`);
    output += eol.after(`Style: CenterCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},5,0,0,0,0`);
    output += eol.after(`Style: CenterRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},6,0,0,0,0`);
    output += eol.after(`Style: BottomLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},1,0,0,0,0`);
    output += eol.after(`Style: BottomCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},2,0,0,0,0`);
    output += eol.after(`Style: BottomRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},3,0,0,0,0`);
    output += String(eol.auto);
    output += eol.after("[Events]");
    output += eol.after("Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text");
    eventGroup.events.forEach((event, index, events) => {
      let eventStyle = ssaFunc.styleMap[event.yPos][event.xPos];
      let startTime = tcLib.secToTc(event.start, options.frameRate);
      let endTime = tcLib.secToTc(event.end, options.frameRate);
      let verticalOffset = Math.abs(event.yOffset / options.window.height);
      let verticalOffsetPx = parseInt(255 * verticalOffset);

      //console.log(event.yOffset, options.window.height, verticalOffset, videoHeight, videoHeightOffsetPx, verticalOffsetPx);

      startTime = startTime.substring(0, 8) + "." + parseInt(startTime.substring(9) / options.frameRate * 100).toString().padStart(2, "0");
      endTime = endTime.substring(0, 8) + "." + parseInt(endTime.substring(9) / options.frameRate * 100).toString().padStart(2, "0");
      //console.log(options.window,videoHeight,videoWidth,event.yOffset,event.yOffset/options.window.yOffset);
      let marginL = Math.abs(event.xOffset / 2).toFixed(2);
      let marginR = Math.abs(event.xOffset / 2).toFixed(2);
      let marginV = verticalOffsetPx.toFixed(2);

      /* if ((event.yPos === 'start' && event.yOffset < 0) || (event.yPos === 'end' && event.yOffset > 0)){
          marginV = Math.abs((event.yOffset/options.window.height)*285) - 15;
      } */

      marginV = parseFloat(marginV).toFixed(2);
      //console.log(`X Offset of ${event.xOffset} is now set to ${marginL}`);
      //console.log(`Y Offset of ${event.yOffset} is now set to ${marginV}`);

      if (marginV < 0) {
        marginV = 0;
      }
      if (marginL < 0) {
        marginL = 0;
      }
      if (marginR < 0) {
        marginR = 0;
      }
      events[index].text = replaceBoldTags(event.text, "{\\b1}", "{\\b0}");
      events[index].text = replaceItalicTags(event.text, "{\\i1}", "{\\i0}");
      events[index].text = replaceUnderlineTags(event.text, "{\\u1}", "{\\u0}");
      events[index].text = convertToPlainText(event.text);
      let text = ssaFunc.formatText(events[index].text);
      let speaker = event.speaker && event.speaker.name ? event.speaker.name : "";
      output += eol.after(`Dialogue: 0,${startTime},${endTime},${eventStyle},${speaker},${events[index].xPos === 'start' ? marginL : ''},${events[index].xPos === 'end' ? marginR : ''},${marginV},,${text}`);
    });
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};