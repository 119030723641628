<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    authState
} from '@app/store/authStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from "@app/store/historyStore.js";
import {
    fade
} from "svelte/transition";

import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
import _Event from "@app/external/cc-lib/dist/classes/event.js";

let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};

async function compareEventGroup() {
    $projectState.compare = $projectState.selected;
    modalState.hideModal();
}

function clearSecondaryTrack() {
    $projectState.compare = false;
    wsRegions.clearRegions();
    modalState.hideModal();
}

async function duplicateEventGroup() {
    let duplicate = JSON.parse(JSON.stringify($eventGroupState[$projectState.selected]));
    duplicate.name = duplicate.name + " (Copy)";
    $eventGroupState = [...$eventGroupState, new _EventGroup(duplicate)];
    $projectState.selected = $eventGroupState.length - 1;

    historyState.insert({
        name: "duplicate event group", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });

    toast.push("Event group has been duplicated.", {
        classes: ["toast-success"]
    });
    toast.push("Event group has been duplicated.", {classes: ['toast-success']});

    modalState.hideModal();
}

async function deleteEventGroup() {
    let eventGroupToDelete = $projectState.selected;
    $projectState.selected = false;

    $eventGroupState = $eventGroupState.filter((group, index) => {
        return index !== eventGroupToDelete;
    });

    if ($eventGroupState.length > 0) {
        $projectState.selected = 0;
    }

    historyState.insert({
        name: "remove event group", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });

    toast.push("Event group has been removed.", {classes: ['toast-success']});

    modalState.hideModal();
}

async function convertToSubtitles() {
    let subtitleEventGroup = JSON.parse(JSON.stringify($eventGroupState[$projectState.selected]));
    subtitleEventGroup.type = "subtitle";
    subtitleEventGroup.maxCps = eventGroupDefaults.maxCps || 9999;
    subtitleEventGroup.maxWpm = eventGroupDefaults.maxWpm || 9999;
    subtitleEventGroup.maxChars = eventGroupDefaults.maxChars || 9999;
    subtitleEventGroup.maxLines = eventGroupDefaults.maxLines || 9999;
    subtitleEventGroup.events.forEach((event, index, events) => {
        events[index].alignment = "center";
    });
    $eventGroupState = [...$eventGroupState, new _EventGroup(subtitleEventGroup)];
    $projectState.selected = $eventGroupState.length - 1;

    historyState.insert({
        name: "convert to subtitles", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });

    toast.push("Event group has been converted to subtitles.", {classes: ['toast-success']});

    modalState.hideModal();
}

async function createAdTemplate() {
    try {
        toast.push("Creating AD template... please wait...", {classes: ['toast-info']});

    let adDefaults = JSON.parse(localStorage.getItem("cc-ad-defaults-new")) || {};
    let adEventGroupTemplate = new _EventGroup({
        name: "AD Template 0" + $eventGroupState.length,
        type: "audio description",
        maxCps: eventGroupDefaults.maxCps || 9999,
        maxWpm: eventGroupDefaults.maxWpm || 9999,
        maxChars: eventGroupDefaults.maxChars || 9999,
        maxLines: eventGroupDefaults.maxLines || 9999,
        illegalChars: eventGroupDefaults.illegalChars,
        language: "en-US",
        ad: {
            gender: adDefaults.gender || "male",
            language: adDefaults.language || "English - US",
            voice: adDefaults.voice || {
                "provider": "google",
                "name": "en-US-Wavenet-A",
                "styles": ["default"],
                "gender": "male",
                "language": "en-US"
            }
        }, //AD Settings
        rtl: false /* Right To Left Support */
    });

    let start = 0;
    let end = 0;
    $eventGroupState[$projectState.selected].events.forEach(event => {
        end = event.start;
        if (end - start > 1) {
            adEventGroupTemplate.events.push(new _Event({
                start: start,
                end: end,
                alignment: "left",
                voice: adEventGroupTemplate.ad.voice
            }));
        }
        start = event.end;
    });

    /* Create event for end of project */
    if ($playerState.duration && $playerState.duration > start) {
        adEventGroupTemplate.events.push(new _Event({
            start: start,
            end: $playerState.duration,
            alignment: "left",
            voice: adEventGroupTemplate.ad.voice
        }));
    }

    $eventGroupState = [...$eventGroupState, adEventGroupTemplate];
    $projectState.selected = $eventGroupState.length - 1;

    historyState.insert({
        name: "create AD Template", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });

    toast.push(`AD Template Created Successful`, {classes: ['toast-success']});
    
    } catch (err){
        console.log(err, err.message);
        toast.push(`AD Template creation failed. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        modalState.hideModal();
    }
    
}

function copyDialogueFromTrack(){
    try {
        // Loop over the events in the selected Event Group. For each event in the selected event group, look for any events in the compare event group where the start time is between the start and end time of the selected event. If there is a match, copy the dialogue/text from the compare event to the selected event.
        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            let compareEvents = $eventGroupState[$projectState.compare].events.filter(compareEvent => {
                return compareEvent.start >= event.start && compareEvent.start <= event.end;
            });

            if (compareEvents.length > 0) {
                events[index].text = "";
                compareEvents.forEach(compareEvent => {
                    events[index].text += compareEvent.text;
                });
            }
        }); 

        historyState.insert({
        name: "copy dialogue", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
        }, ],
    });
        
        toast.push(`Dialogue copied from Track 2`, {classes: ['toast-success']});


    } catch(err){
        console.info("Error copying dialogue from track", err.message);
        console.log(err);
    } finally {
        $eventGroupState = $eventGroupState;
        modalState.hideModal();
    }
}

function mergeText(){
    try {
        //Copy the selected Event Group and merge the original and text properties together.
        let mergedEventGroup = JSON.parse(JSON.stringify($eventGroupState[$projectState.selected]));
        mergedEventGroup.id = crypto.randomUUID();
        mergedEventGroup.type = "subtitle";
        mergedEventGroup.name = mergedEventGroup.name + " (Merged)";
        mergedEventGroup.linkedGroup = false;
        mergedEventGroup.events.forEach((event, index, events) => {
            events[index].text = event.original + " " + event.text;
            events[index].original = ""; 
        });

        $eventGroupState = [...$eventGroupState, mergedEventGroup];
        $projectState.selected = $eventGroupState.length - 1;    
    } catch (err){
        console.log(err);
    } finally {
        modalState.hideModal();
    }
    
}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'eventGroupOptions' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="EventGroupOptionsModal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
                <h5 class="modal-title">Event Group Options</h5>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <div class="btn-group-vertical btn-group-lg w-100" role="group" aria-label="Vertical button group">
                    <button type="button" class="btn btn-light" on:click={() => duplicateEventGroup()}>Duplicate</button>
                    <button type="button" class="btn btn-light" on:click={() => modalState.showModal("mergeEventGroup")}>Merge Groups...</button>
                    <button type="button" class="btn btn-light" on:click={() => modalState.showModal("segmentEventGroup")}>Segment...</button>
                    {#if $projectState.compare !== $projectState.selected}
                        <button type="button" class="btn btn-light" on:click={() => compareEventGroup()}>Set As Track 2</button>
                    {:else}
                        <button type="button" class="btn btn-light" on:click={() => clearSecondaryTrack()}>Hide Track 2</button>
                    {/if}
                    <button type="button" class="btn btn-light" on:click={() => modalState.showModal("eventGroupMetadata")}>Edit Metadata</button>
                    <button type="button" class="btn btn-light" on:click={() => modalState.showModal("editEventGroup")}>Settings</button>
                    {#if $authState.ad}
                    <button type="button" class="btn btn-light" on:click={() =>  createAdTemplate()}>Create AD Template</button>
                    {/if}
                    {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "transcription"}
                    <button type="button" class="btn btn-light" on:click={() => convertToSubtitles()}>Convert To Subtitles...</button>
                    {/if}
                    {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "translation"}
                    <button type="button" class="btn btn-light" on:click={() => modalState.showModal("syncChanges")}>Sync Changes From Linked Group...</button>
                    <button type="button" class="btn btn-light" on:click={() => mergeText()}>Merge Text</button>
                    {/if}
                    {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.compare] && $eventGroupState[$projectState.selected] !== $eventGroupState[$projectState.compare]}
                        <button type="button" class="btn btn-light" on:click={() => copyDialogueFromTrack()}>Copy Dialogue From Track 2</button>
                    {/if}
                    <button type="button" class="btn btn-danger text-white" on:click={() => deleteEventGroup()}><i class="bi bi-trash"></i> Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>
