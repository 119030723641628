// cc-creator, cc-converter
import * as htmlToImage from "html-to-image";
import zipper from "jszip";

// cc-cli
// const htmlToImage = require("node-html-to-image");
// const zipper = require("adm-zip");

const clientImage = async (html, height, width, fontSize) => {
  let newHtml = document.createElement("html");
  newHtml.innerHTML = html;
  newHtml.style = `width: ${width}px;height: ${height}px;`;
  return await htmlToImage.toBlob(newHtml, {
    backgroundColor: "transparent",
    height,
    width,
    type: "image/png"
  }).then(function (dataUrl) {
    return dataUrl;
  });
};
const serverImage = async (html, height, width) => {
  return htmlToImage({
    html,
    encoding: 'buffer',
    transparent: true,
    width,
    height
  });
};
const clientZipBuffer = async files => {
  const zip = new zipper();
  files.forEach(file => {
    zip.file(file.name, file.content);
  });
  const image = await zip.generateAsync({
    type: "blob"
  });
  return image;
};
const serverZipBuffer = async files => {
  let zip = new zipper();
  files.forEach(file => {
    zip.addFile(file.name, file.content);
  });
  return zip.toBuffer();
};
export default {
  cleanFontName: function (fontName) {
    // in "Arial (sans-serif)"
    // out "Arial, sans-serif"

    const regex = /^(.*)\((.*)\)$/i;
    const res = regex.exec(fontName);
    const result = res.map(item => item.includes(" ") ? `"${item.trim()}"` : item.trim());
    const _ = result.shift();
    return result.join(", ");
  },
  createZipBuffer: function (files) {
    if (typeof window !== 'undefined') {
      return clientZipBuffer(files);
    } else {
      return serverZipBuffer(files);
    }
  },
  genImage: async (text, color, height, width, valign, align, fontSize, font, margin) => {
    let html;
    if (typeof window !== 'undefined') {
      html = `
      <head>
        <style>
          * {padding:0;margin:0;}
          p {font-size:${fontSize}px;font-family:${font}}
        </style>
      </head>
      <body style="font-style:Arial;height:${height}px;width:${width}px;">
        <div style="color:${color};background-color:transparent;display:flex;width:100%;height:100%;justify-content:${align};align-items:${valign};flex-direction:column;">
          <span style="margin:${margin / 2}%;text-align:center;">
            ${text}
          </span>
        </div>
      </body>
      `;
      return clientImage(html, height, width, fontSize);
    } else {
      html = `
      <head>
        <style>
          * {padding:0;margin:0;}
          p {font-family:${font}}
        </style>
      </head>
      <body style="font-style:Arial;height:${height}px;width:${width}px;padding:${margin}%;">
        <div style="color:${color};background-color:transparent;font-size:${fontSize}px;display:flex;width:100%;height:100%;justify-content:${align};align-items:${valign};flex-direction:column;">
          ${text}
        </div>
      </body>
      `;
      return serverImage(html, height, width);
    }
  }
};