import _getNearestColor from "../../functions/utility/getNearestColor.js";
const getNearestColor = _getNearestColor;
export default {
  checkValueInRegexArray: function (value, regexArray) {
    return regexArray.some(pattern => {
      const regex = new RegExp(pattern);
      return regex.test(value);
    });
  },
  decToHex: function (value) {
    return Math.floor(value / 100 * 255).toString(16).padStart(2, "0");
  },
  convertRGBtoBGR: function (rgbColor) {
    return rgbColor.substring(4) + rgbColor.substring(2, 4) + rgbColor.substring(0, 2);
  },
  convertBGRtoRGB: function (bgrColor) {
    return bgrColor.substring(4) + bgrColor.substring(2, 4) + bgrColor.substring(0, 2);
  },
  encodeColor: function (hexColor, opacityValue) {
    let alpha = this.decToHex(100 - opacityValue);
    hexColor = hexColor.replace("#", "");
    let bgr = this.convertRGBtoBGR(hexColor);
    return `&H${alpha.toUpperCase()}${bgr.toUpperCase()}`;
  },
  encodeOutline: function (paddingValue) {
    return paddingValue;
  },
  calcLeftOffset: function (offset) {
    return offset;
  },
  calcVertOffset: function (offset) {
    return offset;
  },
  formatText: function (text) {
    return text.replace(/\r?\n/g, "\\N");
  },
  getPositionFromSubtitle: function (subtitleText) {
    // Extract the alignment code from the subtitleText using regular expression
    const match = subtitleText.match(/\\an\d/);
    if (match) {
      const alignmentCode = match[0];
      switch (alignmentCode) {
        case "\\an7":
          return {
            x: 'start',
            y: 'start'
          };
        case "\\an8":
          return {
            x: 'center',
            y: 'start'
          };
        case "\\an9":
          return {
            x: 'end',
            y: 'start'
          };
        case "\\an4":
          return {
            x: 'start',
            y: 'center'
          };
        case "\\an5":
          return {
            x: 'center',
            y: 'center'
          };
        case "\\an6":
          return {
            x: 'end',
            y: 'center'
          };
        case "\\an1":
          return {
            x: 'start',
            y: 'end'
          };
        /* case "\\an2":
            return "Bottom center"; */
        case "\\an3":
          return {
            x: 'end',
            y: 'end'
          };
        default:
          return {
            x: 'center',
            y: 'end'
          };
      }
    } else {
      return {
        x: 'center',
        y: 'end'
      };
    }
  },
  sortCmdCodes: function (cmds) {
    const endElements = ['b1', 'c1', 'u1'];
    return cmds.sort((a, b) => {
      const aIndex = endElements.indexOf(a);
      const bIndex = endElements.indexOf(b);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return -1;
      if (bIndex === -1) return 1;
      return aIndex - bIndex;
    });
  },
  parseCmdCodes: function (ccEvent, text, options, playResX, playResY) {
    let styleRegex = /\{.+?\}/g;
    let whiteOpen = false,
      blackOpen = false,
      redOpen = false,
      yellowOpen = false,
      greenOpen = false,
      blueOpen = false,
      magentaOpen = false,
      cyanOpen = false,
      boldOpen = false,
      italicOpen = false,
      underlineOpen = false;
    text = text.replace(styleRegex, matches => {
      //console.log(matches);
      let replaceString = "";
      matches = matches.replace(/\{|\}/g, "").split('\\');
      //console.log(matches)
      this.sortCmdCodes(matches).forEach(m => {
        if (!m) {
          return;
        }

        //console.log("MATCH: "+m);

        if (m === 'b1') {
          if (boldOpen) {
            replaceString += "</b>";
          }
          replaceString += "<b>";
          boldOpen = true;
        } else if (m === 'b0') {
          if (boldOpen) {
            replaceString += "</b>";
            boldOpen = false;
          }
        } else if (m === 'i1') {
          if (italicOpen) {
            replaceString += "</i>";
          }
          replaceString += "<i>";
          italicOpen = true;
        } else if (m === 'i0') {
          if (italicOpen) {
            italicOpen = false;
            replaceString += "</i>";
          }
        } else if (m === 'u1') {
          if (underlineOpen) {
            replaceString += "</u>";
          }
          replaceString += "<u>";
          underlineOpen = true;
        } else if (m === 'u0') {
          if (underlineOpen) {
            underlineOpen = false;
            replaceString += "</u>";
          }
        } else if (m.match(/fs\d+/)) {
          let fontSize = m.match(/\d+/)[0];
          ccEvent.fontSize = fontSize + "px";
        } //check if m is a position code that matches (anX) where X is the position coordinate 
        else if (m.match(/an\d/)) {
          //Get the position coordinate from the match (it should be the last character)
          let positionCoordinate = m.charAt(m.length - 1);
          if (this.alignmentMap[positionCoordinate]) {
            ccEvent.xPos = this.alignmentMap[positionCoordinate].xPos;
            ccEvent.yPos = this.alignmentMap[positionCoordinate].yPos;
            ccEvent.alignment = this.alignmentMap[positionCoordinate].alignment;
          }

          /* if (ccEvent.yPos === "end"){
              ccEvent.yOffset = options.window.height*-0.10;
          } else if (ccEvent.yPos === "start"){
              ccEvent.yOffset = options.window.height*0.10;
          } */
        } //Check if m is a color code that matches (c&H[0-9A-F]{6}&) where [0-9A-F]{6} is the color code
        else if (m.match(/c&H[0-9A-F]{6}&/)) {
          //Check to see if first character of m is 1 or c
          if (m.charAt(0) === '1' || m.charAt(0) === 'c') {
            //console.log(m);
            //Extract the color code from the match
            let hexColor;
            let colorCode = m.match(/c&H[0-9A-F]{6}&/)[0];
            //Extract the hex color from the color code
            if (colorCode.length === 10) {
              hexColor = colorCode.substring(3, 9);
            } else {
              hexColor = colorCode.substring(5, 11);
            }
            //Encode the color code
            //console.log("HEX COLOR: "+hexColor);
            let rgbValue = this.convertBGRtoRGB(hexColor);
            //console.log("RGB VaLUE: ",rgbValue);
            let nearestColor = getNearestColor("#" + rgbValue);
            //console.log("NEAREST COLOR: ",nearestColor);
            if (whiteOpen || blackOpen || redOpen || yellowOpen || greenOpen || blueOpen || magentaOpen || cyanOpen) {
              if (boldOpen) {
                replaceString += "</b>";
                boldOpen = false;
              }
              if (italicOpen) {
                replaceString += "</i>";
                italicOpen = false;
              }
              if (underlineOpen) {
                replaceString += "</u>";
                underlineOpen = false;
              }
              if (whiteOpen) {
                replaceString += "</white>";
                whiteOpen = false;
              }
              if (blackOpen) {
                replaceString += `</black>`;
                blackOpen = false;
              }
              if (redOpen) {
                replaceString += `</red>`;
                redOpen = false;
              }
              if (yellowOpen) {
                replaceString += `</yellow>`;
                yellowOpen = false;
              }
              if (greenOpen) {
                replaceString += `</green>`;
                greenOpen = false;
              }
              if (blueOpen) {
                replaceString += `</blue>`;
                blueOpen = false;
              }
              if (magentaOpen) {
                replaceString += `</magenta>`;
                magentaOpen = false;
              }
              if (cyanOpen) {
                replaceString += `</cyan>`;
                cyanOpen = false;
              }
            }
            replaceString += `<${nearestColor.name}>`;
            blackOpen = nearestColor.name === "black";
            whiteOpen = nearestColor.name === "white";
            redOpen = nearestColor.name === "red";
            yellowOpen = nearestColor.name === "yellow";
            greenOpen = nearestColor.name === "green";
            blueOpen = nearestColor.name === "blue";
            magentaOpen = nearestColor.name === "magenta";
            cyanOpen = nearestColor.name === "cyan";
          }
        } //Check if it's a position code that matches pos(X,Y) where X and Y are the position coordinates 
        else if (m.match(/pos\(\d+(?:\.\d+)?,\d+(?:\.\d+)?\)/)) {
          // console.log("Position Match: ",m);
          //Get x and y position values from the match. X will be the first digit, and y will be the second digit (x,y);
          let positionX = parseFloat(m.match(/(?<=\().+(?=\))/g)[0].split(",")[0]);
          let positionY = parseFloat(m.match(/(?<=\().+(?=\))/g)[0].split(",")[1]);
          let xPosPercent = positionX / playResX;
          let yPosPercent = positionY / playResY;
          if (xPosPercent < 0.3) {
            ccEvent.xPos = "start";
          } else if (xPosPercent > 0.7) {
            ccEvent.xPos = "end";
          } else {
            ccEvent.xPos = "center";
          }
          if (yPosPercent < 0.3) {
            ccEvent.yPos = "start";
          } else if (yPosPercent > 0.7) {
            ccEvent.yPos = "end";
          } else {
            ccEvent.yPos = "center";
          }

          // ccEvent.xOffset = (options.window.width * xPosPercent); 
          // ccEvent.yOffset = (options.window.height * yPosPercent);

          // console.log(ccEvent.xOffset, ccEvent.yOffset);
        }
      });
      return replaceString;
    });
    text = text.replace(/{[^}]*}/g, "").replace(/\\N/g, "\n");
    if (boldOpen) {
      text += "</b>";
    }
    if (italicOpen) {
      text += "</i>";
    }
    if (underlineOpen) {
      text += "</u>";
    }
    if (blackOpen) {
      text += "</black>";
    }
    if (whiteOpen) {
      text += "</white>";
    }
    if (redOpen) {
      text += "</red>";
    }
    if (yellowOpen) {
      text += "</yellow>";
    }
    if (greenOpen) {
      text += "</green>";
    }
    if (blueOpen) {
      text += "</blue>";
    }
    if (magentaOpen) {
      text += "</magenta>";
    }
    if (cyanOpen) {
      text += "</cyan>";
    }
    return text;
  },
  /* Decode */
  parseStyles: function (fileLine, styles = {}) {
    // Extract style information from comma-seperated value list:
    // e.g. Style: Default,Trebuchet MS,22,&H00FFFFFF,&H000000FF,&H00000000,&H00000000,-1,0,0,0,100,100,0,0,1,1,1,2,0002,0002,0025,1 
    //The style name will be the first value in the list, followed by the Fontname,Fontsize,PrimaryColour,SecondaryColour,OutlineColour,BackColour,Bold,Italic,Underline,Strikeout,ScaleX,ScaleY,Spacing,Angle,BorderStyle,Outline,Shadow,Alignment,MarginL,MarginR,MarginV,Encoding.
    const styleInfo = fileLine.split("Style: ")[1].split(",");
    // console.log(styleInfo);
    styles[styleInfo[0].toLowerCase()] = {
      fontName: styleInfo[1],
      fontSize: styleInfo[2],
      primaryColor: styleInfo[3].replace(/\&/g, ""),
      secondaryColor: styleInfo[4].replace(/\&/g, ""),
      outlineColor: styleInfo[5],
      backColor: styleInfo[6],
      bold: styleInfo[7],
      italic: styleInfo[8],
      underline: styleInfo[9],
      strikeout: styleInfo[10],
      scaleX: styleInfo[11],
      scaleY: styleInfo[12],
      spacing: styleInfo[13],
      angle: styleInfo[14],
      borderStyle: styleInfo[15],
      outline: styleInfo[16],
      shadow: styleInfo[17],
      alignment: styleInfo[18],
      marginL: styleInfo[19],
      marginR: styleInfo[20],
      marginV: styleInfo[21],
      encoding: styleInfo[22]
    };
    return styles;
  },
  /* Encode */
  styleMap: {
    /* Vert */
    start: {
      /* Horz */
      start: "TopLeft",
      end: "TopRight",
      center: "TopCenter"
    },
    end: {
      start: "BottomLeft",
      end: "BottomRight",
      center: "BottomCenter"
    },
    center: {
      start: "CenterLeft",
      end: "CenterRight",
      center: "CenterCenter"
    }
  },
  alignmentMap: {
    1: {
      xPos: "start",
      yPos: "end",
      alignment: "left"
    },
    2: {
      xPos: "center",
      yPos: "end",
      alignment: "center"
    },
    3: {
      xPos: "end",
      yPos: "end",
      alignment: "right"
    },
    4: {
      xPos: "start",
      yPos: "center",
      alignment: "left"
    },
    5: {
      xPos: "center",
      yPos: "center",
      alignment: "center"
    },
    6: {
      xPos: "end",
      yPos: "center",
      alignment: "right"
    },
    7: {
      xPos: "start",
      yPos: "start",
      alignment: "left"
    },
    8: {
      xPos: "center",
      yPos: "start",
      alignment: "center"
    },
    9: {
      xPos: "end",
      yPos: "start",
      alignment: "right"
    }
  }
};