import _hexToBinary from "hex-to-binary";
import _scenerist from "./scenerist.js";
import _sccFunc from "../functions/profiles/scenerist.js";
import _sccLookup from "../dict/608.js";
import _mccLookup from "../dict/708.js";
import _mccFunc from "../functions/profiles/macCaption.js";
import _autoFormatSimple from "../functions/utility/autoFormatSimple.js";
import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const eol = _eol;
const autoFormatSimple = _autoFormatSimple;
const mccFunc = _mccFunc;
const mccLookup = _mccLookup;
const sccLookup = _sccLookup;
const sccFunc = _sccFunc;
const scenerist = _scenerist;
const hexToBinary = _hexToBinary;
export default {
  decode: function (input, options) {
    let events = [],
      buffer = [],
      //Where we store windows before decoding them at the end
      fileLines,
      frameRate,
      ccCmds,
      ancData,
      tc,
      decodingOptions = getFormatOptions(options.formatOptions),
      standard,
      cdp,
      sccFile = "Scenarist_SCC V1.0",
      prevCmdWasDisplayBuffer = false,
      skipByte = false,
      bytesSkipped,
      bytesToSkip,
      useExternal,
      decodedCmd,
      params,
      currentWindow = false,
      windows = [{
        id: 0,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 1,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 2,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 3,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 4,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 5,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 6,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }, {
        id: 7,
        params: null,
        defined: false,
        priority: null,
        anchorPoint: null,
        relativePos: null,
        verticalOffset: null,
        horizontalOffset: null,
        anchorVert: null,
        anchorHorz: null,
        rowCount: null,
        columnCount: null,
        columnLock: null,
        rowLock: null,
        visible: false,
        styleId: 1,
        style: "Pop-On",
        penStyleId: 1,
        spa: false,
        spc: false,
        spl: false,
        lines: [],
        start: null,
        end: null
      }];
    frameRate = mccFunc.getFrameRateFromFile(input);
    //console.log(frameRate);
    standard = decodingOptions["Caption Standard"] || "608";
    fileLines = eol.split(input).filter(fileLine => {
      return /^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g.test(fileLine.split("\t")[0]);
    });
    fileLines.forEach(fileLine => {
      tc = fileLine.split("\t")[0];
      ancData = mccFunc.replaceShortForms(fileLine.split("\t")[1]);
      cdp = mccFunc.decodeAncData(ancData.split(""));
      if (standard === "608") {
        cdp.ccData.ccPackets.forEach(ccPacket => {
          if (ccPacket.substring(0, 2) === "FC" && ccPacket.substring(2) !== "8080") {
            if (ccPacket.substring(2).toLowerCase() === "942f") {
              if (!prevCmdWasDisplayBuffer) {
                sccFile += "\n\n" + tc + "\t" + ccPacket.substring(2).toLowerCase();
                prevCmdWasDisplayBuffer = true;
              }
            } else {
              sccFile += "\n\n" + tc + "\t" + ccPacket.substring(2).toLowerCase();
              prevCmdWasDisplayBuffer = false;
            }
          }
        });
      } else {
        /* Standard defaults to 708 */
        let currentCmdBuffer = [];
        cdp.ccData.ccPackets.forEach(ccPacket => {
          if (ccPacket.substring(0, 2) === "FE") {
            /* Both Bytes are good */
            ccCmds = ccPacket.match(/.{1,2}/g);
            ccCmds.shift(); // remove FE
          } else if (ccPacket.substring(0, 2) === "FF") {
            /* First Byte is header and second byte is good */
            // ccCmds = ccPacket.match(/.{1,2}/g);
            // console.log(tc + "\t" + ccCmds);
            // ccCmds.shift(); // remove FF
            // ccCmds.shift(); // remove unwanted byte
          }
          if (ccCmds && ccCmds.length > 0) {
            currentCmdBuffer = currentCmdBuffer.concat(ccCmds);
          }
          ccCmds = [];
        });
        if (currentCmdBuffer.length > 0) {
          //console.log(tc + "\t" + currentCmdBuffer);
        }
        while (currentCmdBuffer.length > 0) {
          let cmd = currentCmdBuffer.shift();
          if (skipByte) {
            bytesSkipped++;
            if (bytesSkipped === bytesToSkip) {
              skipByte = false;
              bytesToSkip = 0;
            }
          } else {
            if (useExternal) {
              decodedCmd = mccLookup.cmds.EXT[cmd.toLowerCase()];
              useExternal = false;
            } else {
              decodedCmd = mccLookup.cmds[cmd.toLowerCase()];
            }

            //console.log(tc.toString() +" "+ decodedCmd + "(" + cmd.toLowerCase() + ")");
            if (/{SKIP-\d}/.test(decodedCmd)) {
              //console.log("Skipping bits: " + decodedCmd + "(" + cmd.toLowerCase() + ")");
              skipByte = true;
              bytesToSkip = parseInt(decodedCmd.match(/\d+/)[0]);
            } else if (/{P16}/.test(decodedCmd)) {
              /* WIP */
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              params = currentCmdBuffer.splice(0, 2);
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text += "'";
            } else if (/{CW-\d}/.test(decodedCmd)) {
              currentWindow = parseInt(decodedCmd.match(/\d+/)[0]);
              //console.log("Selecting window " + currentWindow + " at " + tc);
            } else if (/{DF-\d}/.test(decodedCmd)) {
              currentWindow = parseInt(decodedCmd.match(/\d+/)[0]);
              //console.log("Defining window " + currentWindow + " at " + tc);

              params = currentCmdBuffer.splice(0, 6);
              //console.log("Params: " + params);

              windows[currentWindow].params = params.join(", ");
              windows[currentWindow].visible = parseInt(hexToBinary(params[0])[2]) ? true : false;
              windows[currentWindow].rowLock = hexToBinary(params[0])[3];
              windows[currentWindow].columnLock = hexToBinary(params[0])[4];
              windows[currentWindow].priority = hexToBinary(params[0]).substring(5);
              windows[currentWindow].relativePos = hexToBinary(params[1])[0] ? false : true;
              windows[currentWindow].anchorVert = hexToBinary(params[1]).substring(1);
              windows[currentWindow].anchorHorz = hexToBinary(params[2]);
              windows[currentWindow].anchorPoint = hexToBinary(params[3]).substring(0, 4);
              windows[currentWindow].rowCount = hexToBinary(params[3]).substring(4);
              windows[currentWindow].columnCount = hexToBinary(params[4]).substring(2);
              windows[currentWindow].styleId = hexToBinary(params[5]).substring(2, 5);
              windows[currentWindow].penStyleId = hexToBinary(params[5]).substring(5);
              windows[currentWindow].defined = true;

              //console.log("Row Lock: " + windows[currentWindow].rowLock);
              //console.log("Column Lock: " + windows[currentWindow].columnLock);
              //console.log("Priority: " + parseInt(windows[currentWindow].priority, 2));
              //console.log("Relative Pos: " + windows[currentWindow].relativePos);
              //console.log("Anchor Vert: " + parseInt(windows[currentWindow].anchorVert, 2));
              //console.log("Anchor Horz: " + parseInt(windows[currentWindow].anchorHorz, 2));
              //console.log("Anchor Point: " + parseInt(windows[currentWindow].anchorPoint, 2));
              //console.log("Row Count: " + parseInt(windows[currentWindow].rowCount, 2));
              //console.log("Column Count: " + parseInt(windows[currentWindow].columnCount, 2));
              //console.log("Style Id: " + parseInt(windows[currentWindow].styleId, 2));
              //console.log("Pen Style Id: " + parseInt(windows[currentWindow].penStyleId, 2));
              if (windows[currentWindow].visible) {
                windows[currentWindow].start = tcLib.tcToSec(tc, frameRate.frames);
              }
              if (windows[currentWindow].relativePos) {
                windows[currentWindow].verticalOffset = parseInt(windows[currentWindow].anchorVert, 2);
              } else {
                windows[currentWindow].verticalOffset = parseInt(windows[currentWindow].anchorVert, 2) / 75 * 100;
              }
              if (windows[currentWindow].relativePos) {
                windows[currentWindow].horizontalOffset = parseInt(windows[currentWindow].anchorHorz, 2);
              } else {
                windows[currentWindow].horizontalOffset = parseInt(windows[currentWindow].anchorHorz, 2) / 209 * 100;
              }
              windows[currentWindow].style = mccFunc.windowStyleMapping[parseInt(windows[currentWindow].styleId, 2)] || "Pop-On";
            } else if (decodedCmd === "{NUL}") {
              /* SKIP FOR NOW */
            } else if (decodedCmd === "{ETX}") {
              //console.log("ETX");
              /* End of Text */
              /* No more Commands to follow */
              /* This requirement aids decoders in processing
              text sequences when text spans multiple service blocks. */
            } else if (decodedCmd === "{BS}") {
              /* Remove last line */
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text = "";
            } else if (decodedCmd === "{FF}") {
              /* Erases all text */
              /* Equivalent to Clear window and set pen location to 0,0 */
              windows[currentWindow].lines = [{
                text: "",
                posX: 0,
                // Percent %
                posY: 0,
                // Percent %
                bold: false,
                italics: false,
                underline: false
              }];
            } else if (decodedCmd === "{CR}") {
              /* Create new caption line */
              windows[currentWindow].lines.push({
                text: "",
                posX: 0,
                // Percent %
                posY: 0,
                // Percent %
                bold: false,
                italics: false,
                underline: false
              });
            } else if (decodedCmd === "{HCR}") {
              /* Erase row of text and move to start of line */
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text = "";
            } else if (decodedCmd === "{EXT}") {
              useExternal = true;
            } else if (decodedCmd === "{CLW}") {
              /* Clear Current Window */
              params = currentCmdBuffer.splice(0, 1);
              /* Clears just the text = from the caption event. 
              Read binary right to left (00010011 - Clear 0, 1 and window 4) */
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  /* Clear Window */
                  //console.log("Clearing window " + index + " at " + tc);
                  //console.log(windows[index]);
                  if (windows[index].visible && windows[index].end === null) {
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                  }
                  windows[index].lines = [];
                  windows[index].end = null;
                }
              });
            } else if (decodedCmd === "{DSW}") {
              /* Display windows */

              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  //console.log("Displaying window "+index);
                  //console.log("Displaying window " + index + " at " + tc);
                  windows[index].start = tcLib.tcToSec(tc, frameRate.frames);
                  windows[index].visible = true;
                }
              });
            } else if (decodedCmd === "{HDW}") {
              /* Hide current window */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  if (windows[index].visible) {
                    //console.log("Hiding window " + index + " at " + tc);
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                    windows[index].visible = false;
                    windows[index].end = null;
                  }
                }
              });
            } else if (decodedCmd === "{TGW}") {
              /* Toggle Windows */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1") {
                  //console.log("Toggle display of window "+index);
                  if (windows[index].visible && windows[index].defined) {
                    //console.log("Toggling off window " + index + " at " + tc);
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                  } else if (windows[index].defined && windows[index].lines.length > 0) {
                    //console.log("Toggling on window " + index + " at " + tc);
                    windows[index].start = tcLib.tcToSec(tc, frameRate.frames);
                  }
                  windows[index].visible = !windows[index].visible;
                }
              });
            } else if (decodedCmd === "{DLW}") {
              /* Delete windows */
              params = currentCmdBuffer.splice(0, 1);
              hexToBinary(params[0]).split('').reverse().forEach(function (win, index) {
                if (win === "1" && windows[index].defined) {
                  //console.log("Deleting Window " + index + " at " + tc);
                  if (windows[index].visible) {
                    windows[index].end = tcLib.tcToSec(tc, frameRate.frames);
                    buffer.push(JSON.parse(JSON.stringify(windows[index])));
                  }
                  windows[index] = {
                    id: index,
                    params: null,
                    defined: false,
                    priority: null,
                    anchorPoint: null,
                    relativePos: null,
                    verticalOffset: null,
                    horizontalOffset: null,
                    anchorVert: null,
                    anchorHorz: null,
                    rowCount: null,
                    columnCount: null,
                    columnLock: null,
                    rowLock: null,
                    visible: false,
                    styleId: 1,
                    style: "Pop-On",
                    penStyleId: 1,
                    spa: false,
                    spc: false,
                    spl: false,
                    lines: [],
                    start: null,
                    end: null
                  };
                }
              });
            } else if (decodedCmd === "{DLY}") {
              /* Delay in 1/10 seconds */
              params = currentCmdBuffer.splice(0, 1);
            } else if (decodedCmd === "{DLC}") {/* Delay Cancel */
            } else if (decodedCmd === "{RST}") {
              /* Reset Channel Service */
              /* Clears all windows and everything */
              windows = [{
                id: 0,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 1,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 2,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 3,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 4,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 5,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 6,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }, {
                id: 7,
                params: null,
                defined: false,
                priority: null,
                anchorPoint: null,
                relativePos: null,
                verticalOffset: null,
                horizontalOffset: null,
                anchorVert: null,
                anchorHorz: null,
                rowCount: null,
                columnCount: null,
                columnLock: null,
                rowLock: null,
                visible: false,
                styleId: 1,
                style: "Pop-On",
                penStyleId: 1,
                spa: false,
                spc: false,
                spl: false,
                lines: [],
                start: null,
                end: null
              }];
            } else if (decodedCmd === "{SPA}") {
              /* Set Pen Attributes */
              //console.log("Setting Pen Attributes at " + tc);
              params = currentCmdBuffer.splice(0, 2);
              //console.log(params);
              if (windows[currentWindow].lines.length === 0) {
                windows[currentWindow].lines.push({
                  text: "",
                  posX: 0,
                  // Percent %
                  posY: 0,
                  // Percent %
                  bold: false,
                  italics: false,
                  underline: false
                });
              }
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].italics = parseInt(hexToBinary(params[1])[0]) ? true : false;
              windows[currentWindow].lines[windows[currentWindow].lines.length - 1].underline = parseInt(hexToBinary(params[1])[1]) ? true : false;
              windows[currentWindow].spa = true;
            } else if (decodedCmd === "{SPC}") {
              /* Set Pen Color */
              //console.log("Setting Pen Color of Window " + currentWindow + " at " + tc);
              params = currentCmdBuffer.splice(0, 3);
              //console.log(params);
              windows[currentWindow].spc = true;
            } else if (decodedCmd === "{SPL}") {
              /* Set Pen Location */
              params = currentCmdBuffer.splice(0, 2);
              //console.log("Setting Pen Location of Window " + currentWindow + " at " + tc);
              //console.log(params);
              let rowValue = parseInt("0x" + params[0]);
              let columnValue = parseInt("0x" + params[1]);
              windows[currentWindow].spl = true;
              windows[currentWindow].lines.push({
                text: "",
                posX: columnValue / 31 * 100 + windows[currentWindow].horizontalOffset,
                // Percent %
                posY: rowValue / 14 * 100 + windows[currentWindow].verticalOffset,
                // Percent %
                bold: false,
                italics: false,
                underline: false
              });

              //console.log(windows[currentWindow].lines)
            } else if (decodedCmd === "{SWA}") {
              /* Set Windows Attributes */
              params = currentCmdBuffer.splice(0, 4);
              //console.log("Setting Window Attributes at " + tc);
              //console.log(params);
              windows[currentWindow].alignment = mccFunc.alignmentMap[parseInt(hexToBinary(params[2]).substring(6))];

              //console.log("Alignment: "+windows[currentWindow].alignment);
            } else {
              /* text */
              if (currentWindow !== false) {
                if (windows[currentWindow].lines.length === 0) {
                  windows[currentWindow].lines.push({
                    text: "",
                    posX: 0,
                    // Percent %
                    posY: 75,
                    // Percent %
                    bold: false,
                    italics: false,
                    underline: false
                  });
                }
                //console.log(decodedCmd);
                windows[currentWindow].lines[windows[currentWindow].lines.length - 1].text += decodedCmd;
              }
            }
          }
        }
      }
    });
    if (standard === "608") {
      events = scenerist.decode(sccFile, {
        frameRate: frameRate.frames,
        dropFrame: frameRate.dropFrame,
        window: options.window
      });
      let eventGroup = scenerist.postProcess.decode({
        events: events
      }, options);
      events = eventGroup.events;
    } else {
      buffer.forEach(window => {
        events.push(mccFunc.decodeWindowData(window));
      });
    }
    return events;
  },
  encode: function (eventGroup, options) {
    /* Encodings can be tested using MXFWrapper: 
        C:\Users\natha\Documents\mxfwrap.exe -2011 -f -fr=30000/1001 -hp=8192 -is -ii2 -u "C:\Users\natha\Documents\bars.mxf&C:\Users\natha\Documents\macEncode.mcc" "C:\Users\natha\Documents\test.mxf" 
    */
    let output = "",
      clock = tcLib.createTc(tcLib.secToTc(eventGroup.events[0].start, options.frameRate).substring(0, 2) + ":00:00:01", options.frameRate, options.dropFrame),
      channel = "ch01",
      encodingOptions = getFormatOptions(options.formatOptions),
      mccVersion = "2.0",
      eventDetails,
      window,
      sccData,
      //608 data
      dtvData,
      //708 data
      selectedWindow = "0",
      visibleWindows = [],
      xdsData = "",
      ccCount = mccFunc.frameRateMapping[options.frameRate.toString()].cc_count;

    /* Select Channel = require( encoding options if set */
    if (encodingOptions["Channel"]) {
      channel = encodingOptions["Channel"].toLowerCase();
    }
    if (encodingOptions["MCC Version"]) {
      mccVersion = encodingOptions["MCC Version"];
    }
    if (encodingOptions["Include Content Advisory Information"] && encodingOptions["Include Content Advisory Information"] === "yes") {
      xdsData = mccFunc.generateXdsData(encodingOptions);
    }
    output = mccFunc.generateMccFileHeader(mccVersion, options.frameRate);

    /* For each caption event we need to figure out when it is displayed and cleared off the screen, we then need to encode it into the buffer/screen and then wait to play that event.*/

    eventGroup.events.forEach(function (event, index, events) {
      let dupEvent = JSON.parse(JSON.stringify(event));
      /* We need to make sure we duplicate any special characters for 608 */
      sccLookup.specialChars.forEach(specialChar => {
        let regexPattern = new RegExp(`\\` + specialChar, "g");
        dupEvent.text = dupEvent.text.replace(regexPattern, sccLookup.specialCharacterReplacement[specialChar] + specialChar);
      });
      if (event.style === 'Pop-On') {
        eventDetails = sccFunc.getEventDetails(dupEvent);
        sccData = sccFunc.encodeEvent(eventDetails, channel, options.window);
        dtvData = mccFunc.deleteWindows(visibleWindows);
        window = mccFunc.encodeWindow(event, options.window);
        //console.log(window);
        window.lines.forEach(function (line, index, lines) {
          dtvData = dtvData.concat(mccFunc.defineWindow(window, selectedWindow, index, lines[0].posY));
          dtvData = dtvData.concat(mccFunc.setPenLocation(line.posX, index));
          dtvData = dtvData.concat(mccFunc.setPenAttributes(window));
          dtvData = dtvData.concat(mccFunc.encodeText(line.text));
        });
        //console.log(dtvData);
        while (sccData.length > 0) {
          output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
          clock.add(1);
        }
      }
      if (visibleWindows.length > 0) {
        let endTc = tcLib.createTc(tcLib.secToTc(events[index - 1].end, options.frameRate), options.frameRate, options.dropFrame);
        while (clock.frameCount < endTc.frameCount) {
          sccData = ["8080"];
          dtvData = [];
          while (sccData.length > 0) {
            output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
            clock.add(1);
          }
        }
        sccData = [sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}")];
        if (event.style === "Pop-On") {
          dtvData = mccFunc.clearWindows(visibleWindows);
          dtvData = dtvData.concat(mccFunc.deleteWindows([selectedWindow]));
        } else if (event.style === "Roll-Up") {
          dtvData = mccFunc.deleteWindows([]);
        } else if (event.style === "Paint-On") {
          dtvData = mccFunc.resetWindows();
        }
        while (dtvData.length > 0) {
          output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
          clock.add(1);
        }
        visibleWindows = [];
      }

      /* Wait until start time of event */
      let startTc = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame);
      while (clock.frameCount < startTc.frameCount) {
        sccData = ["8080"];
        dtvData = [];
        while (sccData.length > 0) {
          output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
          clock.add(1);
        }
      }
      if (event.style === "Pop-On") {
        sccData = [sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}")];
        sccData.push(sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}"));
        dtvData = mccFunc.deleteWindows([selectedWindow]);
        dtvData = mccFunc.toggleWindows([selectedWindow]);
        visibleWindows = [selectedWindow];
        if (selectedWindow === "0") {
          selectedWindow = "1";
        } else {
          selectedWindow = "0";
        }
      } else if (event.style === "Roll-Up") {
        eventDetails = sccFunc.getEventDetails(dupEvent);
        sccData = sccFunc.encodeEvent(eventDetails, channel, options.window);
        dtvData = mccFunc.defineWindow(event, selectedWindow);
        window = mccFunc.encodeWindow(event, options.window);
        window.lines.forEach(function (line, index) {
          dtvData = dtvData.concat(mccFunc.setPenLocation( /* event.posX */0, index));
          dtvData = dtvData.concat(mccFunc.carriageReturn());
          dtvData = dtvData.concat(mccFunc.setPenAttributes(window));
          dtvData = dtvData.concat(mccFunc.encodeText(line.text));
        });
        visibleWindows = [0];
      } else if (event.style === "Paint-On") {
        eventDetails = sccFunc.getEventDetails(dupEvent);
        sccData = sccFunc.encodeEvent(eventDetails, channel, options.window);
        dtvData = mccFunc.defineWindow(event, selectedWindow);
        window = mccFunc.encodeWindow(event, options.window);
        window.lines.forEach(function (line, index) {
          dtvData = dtvData.concat(mccFunc.setPenAttributes(window));
          dtvData = dtvData.concat(mccFunc.encodeText(line.text));
        });
        visibleWindows = [0];
      }
      while (sccData.length > 0) {
        output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
        clock.add(1);
      }
    });
    if (visibleWindows.length > 0) {
      let event = eventGroup.events[eventGroup.events.length - 1];
      let endTc = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame);
      while (clock.frameCount < endTc.frameCount) {
        sccData = ["8080"];
        dtvData = [];
        while (sccData.length > 0) {
          output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
          clock.add(1);
        }
      }
      sccData = [sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}"), sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR Buffer}"), sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}"), sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR Buffer}")];
      if (event.style === "Pop-On") {
        dtvData = mccFunc.clearWindows(visibleWindows);
      } else if (event.style === "Roll-Up") {
        dtvData = mccFunc.deleteWindows([]);
      } else if (event.style === "Paint-On") {
        dtvData = mccFunc.resetWindows();
      }
      while (sccData.length > 0) {
        output += "\n" + clock.toString().replace(";", ":") + "\t" + mccFunc.encodeVancData(clock.frameCount, options.frameRate, ccCount, sccData, dtvData);
        clock.add(1);
      }
      visibleWindows = [];
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup, options) {
      eventGroup.events.forEach((event, index, events) => {
        if (!sccFunc.verifyFormatting(event, options.window)) {
          /* Debug */
          //console.log("--------------");
          //console.log(event.text);
          events[index].text = autoFormatSimple(event.text);
          events[index].xPos = "center";
          events[index].yPos = "end";
          events[index].xOffset = options.window.xOffset;
          events[index].yOffset = options.window.yOffset;
        }
      });
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      /* 
          We decoded the SCC using the 32x15 grid but now we need to center that grid over the window. There is a fixed offset of 10% (x) and 10% (y). This is due to the caption area being 90% of the frames width and 90% of the frames height. 
      */

      let xOffset = parseInt(options.window.width * 0.10);
      let yOffset = parseInt(options.window.height * 0.10);
      // console.log("XOffset", xOffset);
      // console.log("YOffset", yOffset);
      // console.log(JSON.stringify(eventGroup.events, null, 5));
      eventGroup.events.forEach((event, index, events) => {
        if (event.xPos === "start") {
          events[index].xOffset += xOffset;
        } else if (event.xPos === "end") {
          events[index].xOffset -= xOffset;
        }
        if (event.yPos === "start") {
          events[index].yOffset += yOffset;
        } else if (event.yPos === "end") {
          events[index].yOffset -= yOffset;
        }
      });
      return eventGroup;
    }
  }
};