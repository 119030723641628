<script>
import {
    environment
} from '@app/store/envStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import {
    v4 as uuidv4
} from 'uuid';

import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';

/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import storage from '@app/configs/storage.js';

/* Language Settings */
import assemblyAiLanguages from "@app/external/cc-lib/dist/providers/assemblyAi/languages.js";
import deepgramLanguages from "@app/external/cc-lib/dist/providers/deepgram/languages.js";
import revAiLanguages from "@app/external/cc-lib/dist/providers/revAi/languages.js";
import googleLanguages from "@app/external/cc-lib/dist/providers/googleSpeechToText/languages.js";
import speechmaticsLanguages from "@app/external/cc-lib/dist/providers/speechmatics/languages.js";
import voiceGainLanguages from "@app/external/cc-lib/dist/providers/voicegain/languages.js";

let mediaDuration;
let jobInfo;

let transcriptionDefaults =
    JSON.parse(localStorage.getItem("cc-transcription-defaults")) || {};

let provider = transcriptionDefaults.provider || "Speechmatics",
    language = transcriptionDefaults.language || "en",
    interactionType = 'PROFESSIONALLY_PRODUCED',
    speakers = 2,
    uploadTask,
    uploading = false,
    progress = 0,
    userId = firebase.auth().currentUser.uid,
    userEmail = firebase.auth().currentUser.email,
    teamId = $authState.team ? $authState.team.id : null,
    homeRef = $authState.team ? db.collection("teams").doc(teamId).collection("jobs") : db.collection("users").doc(userId).collection("jobs"),
    storageRef = storage.ref(),
    uploadBasePath = $authState.team ? "teams/" + teamId + "/watch/video/" : "users/" + userId + "/uploads/",    
    audioUploadBasePath = $authState.team ? "teams/" + teamId + "/watch/audio/" : "users/" + userId + "/audio/",
    input,
    output;

function extractAudio() {
    try {
        const os = window.os;
        const path = window.path;
        const ffmpegPath = require('ffmpeg-static-electron').path;
        const ffmpeg = require('fluent-ffmpeg');
        console.log("EXTRACTING AUDIO");
        uploading = true;
        progress = 0;
        input = $projectState.media.localPath;
        output = os.tmpdir() + path.sep +  $projectState.id + ".wav";
        
        toast.push("Audio extraction in progress...", {
            classes: ["toast-info"]
        });
        
        ffmpeg.setFfmpegPath(ffmpegPath.replace('app.asar', 'app.asar.unpacked'));

        ffmpeg(input)
            .audioCodec("pcm_mulaw")
            .audioChannels(1)
            .audioFrequency(44100)
            .output(output)
            .on('start', function(commandLine) {
                console.log('Spawned Ffmpeg with command: ' + commandLine);
            })
            .on('codecData', function(data) {
                console.log(data.duration);
                mediaDuration = data.duration;
                console.log('Input is ' + data.audio + ' audio ' +
                    'with ' + data.video + ' video');
            })
            .on('progress', function(progress) {
                /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
                console.log('Scanning: ' + progress.timemark + ' done');
            })
            .on('end', function() {
                toast.push("Audio extraction complete. Uploading...", {
                    classes: ["toast-success"]
                });

                submitJob();
            })
            .on('error', function(err, stdout, stderr) {
                console.log(err, err.message);
                toast.push("Failed to extract audio from source video. " + err.message, {
                    classes: ["toast-danger"]
                });
            })
            .run();
    } catch (err) {
        console.log(err, err.message);
    }
}

async function submitJob() {
    /* 
        1. Register the job 
        2. Upload File
        3. If Failure - update job and clean up.
    */
   console.log("Submitting Automatic Transcription Job");

    localStorage.setItem(
        "cc-transcription-defaults",
        JSON.stringify({
            provider: provider,
            language: language
        })
    );

    let jobRegistration = false;
    uploading = true;
    progress = 5;

    jobInfo = {
        id: uuidv4(),
        mediaSource : $projectState.media.storage,
        projectId: $projectState.id,
        projectName: $projectState.name,
        submittedBy : userEmail,
        userId : userId,
        extId: null,
        progress: 0,
        duration: mediaDuration ? tcLib.tcMsToSec(mediaDuration) : 0,
        cost: 0,
        type: "transcription",
        statusMsg: "Awaiting Media",
        status: "Submitted",
        createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
        updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
        completedOn: null,
        deleted: false,
        config: {
            provider: provider,
            language: language,
            speakers: speakers,
            interactionType: interactionType
        }
    }

    if ($projectState.media.storage === "Cloud Storage"){
        homeRef.doc(jobInfo.id).set(jobInfo).then(() => {
            console.log("Transcribe job saved successfully", jobInfo);
            jobRegistration = true;
            firebase.functions().httpsCallable("v8SubmitCloudTranscribeJob")({
                jobId : jobInfo.id,
                mediaUrl : $projectState.media.path
            });

            toast.push("Transcription job was submitted successfully", {
                classes: ["toast-success"]
            });

            modalState.showModal('aiTranscriptImport');
        }).catch((error) => {
            console.log("Error submitting transcription job: ", error);

            toast.push("Transcription Error: " + error.message, {
                classes: ["toast-danger"]
            });

            /* Update Job Record */
            if (jobRegistration) {
                homeRef.doc(jobInfo.id).update({
                    progress: 100,
                    statusMsg: "Failed submission process. " + error.message,
                    status: "Failed",
                    completedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
            }

            uploading = false;
            progress = 0;
        });
    } else if ($projectState.media.storage === "YouTube"){
        homeRef.doc(jobInfo.id).set(jobInfo).then(() => {
            console.log("Transcribe job saved successfully", jobInfo);
            jobRegistration = true;
            firebase.functions().httpsCallable("v8SubmitYouTubeTranscribeJob")({
                jobId : jobInfo.id,
                mediaUrl : $projectState.media.path
            });

            toast.push("Transcription job was submitted successfully", {
                classes: ["toast-success"]
            });

            modalState.showModal('aiTranscriptImport');
        }).catch((error) => {
            console.log("Error submitting transcription job: ", error);

            toast.push("Transcription Error: " + error.message, {
                classes: ["toast-danger"]
            });

            /* Update Job Record */
            if (jobRegistration) {
                homeRef.doc(jobInfo.id).update({
                    progress: 100,
                    statusMsg: "Failed submission process. " + error.message,
                    status: "Failed",
                    completedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
            }

            uploading = false;
            progress = 0;
        });
    } else if ($projectState.media.storage === "Local Storage" || $projectState.media.storage === "Proxy RT") {
        homeRef.doc(jobInfo.id).set(jobInfo).then(() => {
            console.log("Transcribe job saved successfully", jobInfo);
            jobRegistration = true;
            if ($environment.electron) {
                console.log("Fetching local output from audio extraction");
                return fetch(output);
            } else {
                return fetch($projectState.media.path);
            }
        }).then(fileResponse => {
            //console.log("File Response:",fileResponse);
            return fileResponse.arrayBuffer();
        }).then(byteArrayFileRes => {
            //console.log(byteArrayFileRes);
            uploadTask = storageRef.child(($environment.electron ? audioUploadBasePath : uploadBasePath) + jobInfo.id).put(byteArrayFileRes);
            uploadTask.on('state_changed', (snapshot) => {
                    //console.log(snapshot);
                    progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
                },
                (error) => {
                    console.log(error, error.code);

                    toast.push("Transcription Error: " + error.message, {
                        classes: ["toast-danger"]
                    });

                    /* Update Job Record */
                    if (jobRegistration) {
                        homeRef.doc(jobInfo.id).update({
                            progress: 100,
                            statusMsg: error.code,
                            status: "Failed",
                            completedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                            updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                        });
                    }

                    uploading = false;
                    progress = 0;
                    modalState.hideModal();
                },
                () => {
                    // Handle successful uploads on complete
                    //console.log("TRANSFER COMPLETE");
                    uploading = false;
                    progress = 100;

                    toast.push("Transcription job was submitted successfully", {
                        classes: ["toast-success"]
                    });

                    modalState.showModal('aiTranscriptImport');
                }
            );
        }).catch((error) => {
            console.log("Error submitting transcription job: ", error);

            toast.push("Transcription Error: " + error.message, {
                classes: ["toast-danger"]
            });

            /* Update Job Record */
            if (jobRegistration) {
                homeRef.doc(jobInfo.id).update({
                    progress: 100,
                    statusMsg: "Failed submission process. " + error.message,
                    status: "Failed",
                    completedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
            }

            uploading = false;
            progress = 0;
        });
    } else {
        toast.push("Automatic transcription failed. File must be accessible via Local Storage, Proxy RT, or Cloud URL. Vimeo and YouTube links are not supported.", {
            classes: ["toast-danger"]
        });
    }
}

function cancelJob() {
    if (uploadTask) {
        uploadTask.cancel();
    }
}

function resetLanguages() {
    if (provider === "Google Speech-to-Text") {
        language = "en-US"
    } else {
        language = 'en'
    }
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'automaticTranscription' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AutomaticTranscription">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Automatic Transcription</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label class="form-label" for="Service provider">Service Provider</label>
                        <select class="form-select" bind:value={provider} on:change="{resetLanguages}">
                            <option>Speechmatics</option>  
                            <option>Assembly AI</option>                            
                            {#if $projectState.media.storage === "Local Storage" || $projectState.media.storage === "Proxy RT" || $projectState.media.storage === "YouTube"}
                                <option>Google Speech-to-Text</option>
                            {/if}  
                            <option>Deepgram</option>   
                            <option>Voicegain</option>  
                            <option>Rev AI</option>                                           
                        </select>
                    </div>

                    {#if provider === "Assembly AI"}
                    <div class="mb-3">
                        <label class="form-label" for="source language">Source Language</label>
                        <select class="form-select" disabled="{uploading}" bind:value="{language}">
                            {#each Object.entries(assemblyAiLanguages) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>

                    {:else if provider === "Google Speech-to-Text"}

                    <div class="mb-3">
                        <label class="form-label" for="source language">Source Language</label>
                        <select class="form-select" disabled="{uploading}" bind:value="{language}">
                            {#each Object.entries(googleLanguages) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>

                    {:else if provider === "Deepgram"}

                    <div class="mb-3">
                        <label class="form-label" for="source language">Source Language</label>
                        <select class="form-select" disabled="{uploading}" bind:value="{language}">
                            {#each Object.entries(deepgramLanguages) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>

                    {:else if provider === "Rev AI"}
                    <div class="mb-3">
                        <label class="form-label" for="source language">Source Language</label>
                        <select class="form-select" disabled="{uploading}" bind:value="{language}">
                            {#each Object.entries(revAiLanguages) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>

                    {:else if provider === "Speechmatics"}
                    <div class="mb-3">
                        <label class="form-label" for="source language">Source Language</label>
                        <select class="form-select" disabled="{uploading}" bind:value="{language}">
                            {#each Object.entries(speechmaticsLanguages) as languageOption}
                            <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>

                    {:else if provider === "Voicegain"}
                    <div class="mb-3">
                        <label class="form-label" for="source language">Source Language</label>
                        <select class="form-select" disabled="{uploading}" bind:value="{language}">
                            {#each Object.entries(voiceGainLanguages) as languageOption}
                                <option value={languageOption[0]}>{languageOption[1]}</option>
                            {/each}
                        </select>
                    </div>
                    {/if}
                </form>
                <p class="small text-muted">Note: Automatic transcription processes at approximately 3-4x realtime. For example, a 1 hour file will process in 15-20 minutes.</p>
                {#if uploading}
                <div class="progress float-start mt-2 w-75" >
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">Uploading - {progress}%</div>
                </div>
                {/if}
                <button class="btn btn-light float-end ms-2" type="button" disabled="{!uploading}" title="Cancel file upload" on:click="{cancelJob}">Cancel</button>
                <button class="btn btn-primary float-end" type="button" disabled="{uploading}" on:click="{() => $environment.electron && ($projectState.media.storage === "Local Storage" || $projectState.media.storage === "Proxy RT") ? extractAudio() : submitJob()}">Submit Job</button>
                {#if uploading}
                <p class="text-warning small"><i class="bi bi-exclamation-diamond-fill"></i> Please leave this window open until your upload completes</p>
                {/if}
            </div>
        </div>
    </div>
</div>
