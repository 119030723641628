<script>
import {onMount} from 'svelte';
import {
    authState
} from '@app/store/authStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    fade
} from 'svelte/transition';
import {
    BarLoader
} from 'svelte-loading-spinners';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';
import db from '@app/configs/firestore.js';
import {v4 as uuidv4} from 'uuid';
import Swal from 'sweetalert2'

let teamId = $authState.team.id;
let dbRef = db.collection("teams").doc(teamId).collection("projects").doc($projectState.teamId).collection("snapshots");

onMount(async () => {
    let snapshotRes = await dbRef.get();
    if (snapshotRes.empty){
        /* Alert user using sweetalert that the project does not have any commits. We recommend syncing changes first before sharing the project with team members. */
        Swal.fire({
            title: 'No Commits',
            text: "This project does not have any commits (yet).You can still create a Share for your team, but you will need to sync any Event Groups before they become available.",
            icon: 'warning',
            confirmButtonText: 'OK',
            allowOutsideClick : false,
            allowEscapeKey : false,
            buttonsStyling : false,
            customClass: {
                confirmButton: 'btn btn-primary',
            }
        })
    }
});

/* 
Share Code:
{
    id : uuid,
    name : "display name",
    members : [*member emails*],
    groups : [*event group uuid*],
    access : "editor", //editor, viewer, reviewer
    createdOn: creationDate,
    projectTeamId : *projectTeamId*
}

*/

let userId = firebase.auth().currentUser.uid, email = firebase.auth().currentUser.email;
let shareCodes = [], 
    selectedShareCode = {
        members : [],
        groups : []
    }, 
    dbWork;

async function createShareCode(){
    shareCodes = [
        ...shareCodes,
        {
            id : uuidv4(),
            name : "Untitled",
            members : [],
            groups : [],
            ownerId :  userId,
            ownerEmail : email,
            access : "editor", //editor, viewer, reviewer
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            teamId : $authState.team.id,
            projectTeamId : $projectState.teamId,
            new : true
        }
    ]

    selectedShareCode = shareCodes[shareCodes.length-1];
    toast.push("New share created", {classes: ['toast-success']});
}

async function saveShareCode(){
    try {
        if (!selectedShareCode.name){
            throw new Error("Share name cannot be empty.");
        }

        if (selectedShareCode.members.length === 0){
            throw new Error("Share must include at least one team member.");
        }

        if (selectedShareCode.groups.length === 0){
            throw new Error("Share must include at least one event group.");
        }
        
        selectedShareCode.new = undefined;
        dbWork = await firebase.functions().httpsCallable('v8CreateShare')(selectedShareCode);
        toast.push("Share link saved successfully", {classes: ['toast-success']});
    } catch(err){
        console.log(err,err.message);
        toast.push(`Error saving share. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        return setup();
    }
    
}

async function removeShareCode(){
    if (!selectedShareCode.id){
        return;
    }

    try { 
        dbWork = await firebase.functions().httpsCallable('v8RemoveShare')({
            id : selectedShareCode.id,
            teamId : selectedShareCode.teamId,
            projectTeamId : selectedShareCode.projectTeamId
        });

        toast.push("Share was successfully removed", {classes: ['toast-success']});

    } catch(err){
        console.log(err,err.message);
        toast.push(`Faild to remove project share. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        return setup();
    }
}

function copyShareCode(){
    if (!selectedShareCode.id){
        toast.push(`No share selected`, {classes: ['toast-danger']});
        return;
    }

    navigator.clipboard.writeText(`https://app.closedcaptioncreator.com/share?id=${selectedShareCode.id}`);

    toast.push(`Share code copied`, {classes: ['toast-success']});
}

async function setup(){
    selectedShareCode = {
        groups : [],
        members : []
    };
    
    let getShareCodes = await firebase.functions().httpsCallable('v8GetProjectShares')({
        projectTeamId : $projectState.teamId,
        teamId : $authState.team.id,
    });

    shareCodes = getShareCodes.data;
    selectedShareCode = shareCodes[0] || selectedShareCode;
}


if ($authState.team && $authState.team.members.length > 1){
    dbWork = setup();
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'teamShare' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TeamShare">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Share Project</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <div class="modal-body">
                    {#if $authState.team && $authState.team.members.length > 1}
                        {#if shareCodes.length === 0}
                            <p class="text-warning fw-bold">Create a new Share to begin.</p>
                        {/if}
                        <div class="row">
                            <div class="col-4">
                                <h4>Shares<span class="badge rounded-pill bg-secondary d-xl-flex justify-content-xl-end align-items-xl-center float-end fs-6">{shareCodes.length} Links</span></h4>
                                {#await dbWork}
                                    <div class="d-flex justify-content-center align-items-center p-3">
                                        <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                                    </div>       
                                {:then}
                                <select class="w-100" size="{Math.min(shareCodes.length + 3, 15)}" bind:value={selectedShareCode}>
                                    {#each shareCodes as code}
                                        <option value={code}>{code.name}</option>
                                    {/each}
                                </select>
                                <div class="d-xl-flex justify-content-xl-end py-2">
                                    <div class="btn-group" role="group">

                                        <button class="btn btn-primary" type="button" on:click="{() => createShareCode()}">
                                            <i class="bi bi-plus-lg"></i> New Share
                                        </button>

                                        <button class="btn btn-light" disabled={!selectedShareCode.id} type="button" on:click="{() => dbWork = removeShareCode()}">
                                            <i class="bi bi-trash"></i> Delete Share
                                        </button>
                                    </div>
                                </div>
                                {:catch}
                                    <p class="text-danger">There was an issue loading share codes. Please closed and reopen this window.</p>
                                {/await}
                            </div>
                            <div class="col">
                                <div class="bg-secondary border rounded border-secondary shadow p-3">
                                    <form>
                                        <div class="mb-3">
                                            <label class="form-label" for="Share Name">Share Name</label>
                                            <input class="form-control" type="text" placeholder="Share Name" disabled={!selectedShareCode.new} bind:value="{selectedShareCode.name}">
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label" for="Team Members">Team Members <i class="bi bi-info-circle"></i></label>
                                            <button class="btn btn-outline-dark btn-sm m-2" type="button" disabled={!selectedShareCode.new} on:click={() => {selectedShareCode.members = [...$authState.team.members]}}>Select All</button>
                                            
                                            <select class="form-select" multiple size="{Math.min( $authState.team.members.length + 3, 10)}" bind:value={selectedShareCode.members} disabled={!selectedShareCode.new}>
                                                {#each $authState.team.members as member}
                                                    <option value={member}>{member}</option>
                                                {/each}
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label" for="Event Groups To Share">Event Groups <i class="bi bi-info-circle"></i></label>
                                            <button class="btn btn-outline-dark btn-sm m-2" type="button" disabled={!selectedShareCode.new} on:click="{() => {selectedShareCode.groups = $eventGroupState.map((evg) => {return evg.id})}}">
                                                Select All
                                            </button>
                                            <select class="form-select" multiple size="{Math.min( $eventGroupState.length + 3, 10)}" bind:value={selectedShareCode.groups} disabled={!selectedShareCode.new}>
                                                {#each $eventGroupState as eventGroup}
                                                    <option value={eventGroup.id}>{eventGroup.name}</option>
                                                {/each}
                                            </select>
                                        </div>
                                        {#await dbWork}
                                            <div class="d-flex justify-content-center align-items-center p-3">
                                                <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                                            </div>       
                                        {:then}
                                        <div class="d-flex justify-content-end">
                                            {#if !selectedShareCode.new}
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control" placeholder="Share Code" disabled value="{selectedShareCode.id || ''}">
                                                <button class="btn btn-info text-white" type="button" disabled="{!selectedShareCode.id || selectedShareCode.new}" on:click="{() => copyShareCode()}"><i class="bi bi-link-45deg"></i> Copy Link
                                                </button>
                                              </div>
                                            {:else}
                                            <div class="btn-group" role="group">
                                                <button class="btn btn-primary" type="button" on:click={() => {dbWork = saveShareCode()}} disabled={!selectedShareCode.new}>Save</button>
                                                <button class="btn btn-outline-dark" type="button" on:click={() => {dbWork = setup()}} disabled={!selectedShareCode.new}>Cancel</button>
                                            </div>
                                            {/if}
                                        </div>
                                        {/await}
                                    </form>
                                </div>
                            </div>
                        </div>
                    {:else}
                        <div class="d-flex justify-content-center align-items-center p-3">
                            <div>
                                <p>Please create a new team to begin.</p>
                                <button type="button" class="btn btn-primary w-100" on:click={() => {modalState.showModal('teamManager')}}>Manage Team</button>
                            </div>                        
                        </div>                        
                    {/if}
                </div>
            </div>
        </div>
    </div>
</div>
