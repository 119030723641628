<script>
    import { uiState } from "@app/store/uiStore.js";
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { fade } from "svelte/transition";
    import { projectState } from "@app/store/projectStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { onMount } from "svelte";

    /* Firebase */
    import firebase from "@app/configs/firebase.js";

    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Project from "@app/external/cc-lib/dist/classes/project.js";
    import loadMedia from "@app/external/cc-lib/dist/functions/projects/loadMedia.js";

    let progress = 0,
        statusMsg = "Connecting to Iconik...",
        mediaUrl;

    async function updateStatus(statusInfo) {
        progress = statusInfo.progress;
        statusMsg = statusInfo.msg;
    }

    async function importMedia() {
        $uiState.timeline = false;
        playerState.updateDuration(false);
        $projectState = loadMedia($projectState, {
            projectName: $projectState.name,
            storage: "Cloud Storage",
            mediaUrl: mediaUrl,
            frameRate: $projectState.frameRate,
            dropFrame: $projectState.dropFrame,
            aspectRatio: "16:9",
        });

        let source = {
            src: $projectState.media.path,
        };
        /* Cloud Storage*/
        source.src = $projectState.media.path;
        if ($projectState.media.type === "video/quicktime") {
            source.type = "video/mp4";
        } else if ($projectState.media.type === "video/x-matroska") {
            source.type = "video/mkv";
        } else {
            source.type = $projectState.media.type;
        }

        //Get file size of url
        let fileSize = await getFileSize($projectState.media.path);
        if (fileSize) {
            const sizeInBytes = parseInt(fileSize);
            const sizeInKilobytes = sizeInBytes / 1024;
            const sizeInMegabytes = sizeInKilobytes / 1024;
            if (sizeInMegabytes < 512) {
                $projectState.media.useFallback = false;
            }
        }

        player.source = {
            type: "video",
            sources: [source],
        };

        manifestFileExists = true;
        audioExtractionComplete = true;

        closeModal();
    }

    async function getFileSize() {
        try {
            const response = await fetch($projectState.media.path, {
                method: "HEAD",
            });
            const fileSize = response.headers.get("content-length");
            return fileSize;
        } catch (err) {
            return false;
        }
    }

    onMount(async () => {
        try {
            console.log("Getting proxy information for "+ $externalDataState.assetId);

            await updateStatus({
                progress: 10,
                msg: "Getting proxy information...",
            });

            $uiState.timeline = false;
            playerState.updateDuration(false);

            let proxyInfo = await firebase.functions().httpsCallable("v8IconikGetProxy")($externalDataState.assetId);

            // console.log(proxyInfo);

            await updateStatus({
                progress: 45,
                msg: "Reloading proxy... please wait a moment.",
            });

            mediaUrl = proxyInfo.data.objects[0].url;

            $externalDataState.mediaUrl = mediaUrl;

            await updateStatus({
                progress: 50,
                msg: "Importing media from URL...",
            });

            $projectState = loadMedia($projectState, {
                projectName: $projectState.name,
                storage: $projectState.media.storage,
                mediaUrl: mediaUrl,
                incode: 0,
                frameRate: $projectState.frameRate,
                dropFrame: $projectState.dropFrame,
                aspectRatio: "16:9"
            });

            let source = {};
            source.src = $projectState.media.path;
            source.type =
                $projectState.media.type === "video/quicktime"
                    ? "video/mp4"
                    : $projectState.media.type;

            let fileSize = await getFileSize($projectState.media.path);
            if (fileSize) {
                const sizeInBytes = parseInt(fileSize);
                const sizeInKilobytes = sizeInBytes / 1024;
                const sizeInMegabytes = sizeInKilobytes / 1024;
                if (sizeInMegabytes < 512) {
                    $projectState.media.useFallback = false;
                }
            }

            player.source = {
                type: "video",
                sources: [source],
            };

            await updateStatus({
                progress: 100,
                msg: "Proxy import completed.",
            });

            toast.push(`Project import completed successfully`, {
                classes: ["toast-success"],
            });

            setTimeout(() => {
                $uiState.timeline = true;
                modalState.hideModal();
            }, 2500);
        } catch (err) {
            console.log(err);
            console.log(err.message);
            toast.push(`External Project Import Failed. ${err.message}`, {
                classes: ["toast-danger"],
            });
            modalState.hideModal();
        }
    });
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'iconikGetProxy' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="LoadExternalProjectModal"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Iconik Proxy Import</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                />
            </div>
            <div class="modal-body">
                <p><i class="bi bi-exclamation-diamond-fill" /> {statusMsg}</p>
                <div class="progress">
                    <div
                        class="progress-bar bg-primary progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style="width: {progress}%;"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        {progress}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
