import _htmlEncodePlainText from "../functions/utility/htmlEncodePlainText.js";
import _xmlFormat from "xml-formatter";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const ttmlFunc = _ttmlFunc;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const eol = _eol;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const xmlFormat = _xmlFormat; //Encode
const htmlEncodePlainText = _htmlEncodePlainText;
export default {
  decode: function (input, options) {
    throw new Error("This profile [HBO SMPTE TTML] is not supported for decoding by Closed Caption Converter. Please use SMPTE TTML (smpteTtml)");
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xml:lang="${encodingOptions["Language Code"] || "en"}"
        xmlns="http://www.w3.org/2006/10/ttaf1"
        xmlns:tts="http://www.w3.org/2006/10/ttaf1#styling"
        xmlns:ttm="http://www.w3.org/2006/10/ttaf1#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>`);
    output += eol.after(`<ttm:desc>SMPTE 2052</ttm:desc>`);
    output += eol.after(`<ttm:copyright>Copyright 2020-present Closed Caption Converter</ttm:copyright>`);
    output += eol.after(`<smpte:information
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        mode="Preserved" m608:channel="CC1" m608:programName="Closed Caption Converter" m608:captionService="F1C1CC"
       />`);
    output += eol.after(`</metadata>`);
    output += eol.after(`<styling>
<style xml:id='basic' tts:color='${encodingOptions["Font Color"] || "white"}' tts:fontFamily='${encodingOptions["Font Family"] || "monospace"}' tts:lineHeight='${encodingOptions["Line Height"] || "125"}%' tts:fontSize='${encodingOptions["Font Size"] || "8"}%' tts:fontWeight='${encodingOptions["Font Weight"] || "bold"}' tts:textOutline='${encodingOptions["Outline Color"] || "black"} ${encodingOptions["Outline Vertical Size"] || "5"}% ${encodingOptions["Outline Horizontal Size"] || "3"}%'/>
</styling>`);
    output += eol.after(`<layout>
<region xml:id='pop1' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='pop2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='pop3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='pop4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='paint' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='rollup2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='rollup3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
<region xml:id='rollup4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
</layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div>`);

    /* EVENTS GO HERE */
    eventGroup.events.forEach(event => {
      let start = encodingOptions["Timecode Format"] === "ms" ? tcLib.secToTcMs(event.start) : tcLib.secToTcClock(event.start, options.frameRate);
      let end = encodingOptions["Timecode Format"] === "ms" ? tcLib.secToTcMs(event.end) : tcLib.secToTcClock(event.end, options.frameRate);
      let plainTextCustom = convertToPlainTextCustom(event.text);
      eol.split(plainTextCustom).forEach((textLine, index, textLines) => {
        let region = `pop${index + 1}`;
        let text = htmlEncodePlainText(textLine);
        let ttml = ttmlFunc.multiLine.convertToTtml(text);
        let origins = ttmlFunc.singleLine.calcOrigin(event, textLine, index, textLines.length, 80, options.window);
        output += eol.after(`<p region='${region}' style='basic' begin='${start}' end='${end}' tts:origin='${origins}'><span tts:backgroundColor='transparent' tts:color='${encodingOptions["Font Color"] || "white"}'>${ttml}</span></p>`);
      });
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += eol.after(`</tt>`);

    //return xmlFormat(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};