<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    authState
} from '@app/store/authStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    onDestroy
} from 'svelte';
import {
    fade
} from 'svelte/transition';
import Papa from 'papaparse';
import Swal from "sweetalert2";
import {
    BarLoader,
    Circle
} from 'svelte-loading-spinners'

/* CC LIB */
import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import autoFormat from '@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js';
import fixOverlap from '@app/external/cc-lib/dist/functions/eventGroups/fixOverlap.js';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';
import db from '@app/configs/firestore.js';
import storage from '@app/configs/storage.js';

import {
    saveAs
} from 'file-saver';

let jobs = [],
    selectedJob,
    userId = firebase.auth().currentUser.uid,
    email = firebase.auth().currentUser.email,
    teamId = $authState.team ? $authState.team.id : null,
    dbRef = $authState.team ? db.collection("teams").doc(teamId).collection("jobs") : db.collection("users").doc(userId).collection("jobs"),
    storageRef = storage.ref(),
    storagePath = $authState.team ? `teams/${teamId}/translations/` : `users/${userId}/translations/`,
    deletingFile = false,
    loading = false,
    autoRefresh = true;

let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {}
let maxChars = eventGroupDefaults.maxChars || 32;
let maxLines = eventGroupDefaults.maxLines || 3;
let minDuration = 0.5;
let allowOrphanWords = false;

let dateRange = "week",
    startDate,
    endDate,
    statusFilter = "All",
    pending = false,
    gettingJobs = getTranslationJobs();

let autoRefreshInterval = setInterval(function () {
    if (!loading && !pending){
        gettingJobs = getTranslationJobs();
    }
}, 10000);

onDestroy(() => {
    if (autoRefreshInterval){
        clearInterval(autoRefreshInterval);
    }    
});

function disableAutoRefresh(){
    clearInterval(autoRefreshInterval);
    autoRefreshInterval = undefined;
    autoRefresh = false;
}

function enableAutoRefresh(){
    if (autoRefreshInterval){
        clearInterval(autoRefreshInterval);
    }

    autoRefreshInterval = setInterval(function () {
        if (!loading && !pending){            
            gettingJobs = getTranslationJobs();
        }
    }, 10000);

    autoRefresh = true;
}

function getDateRange() {
    let start, end;
    switch (dateRange) {
        case "custom":
            start = new Date(startDate);
            end = new Date(endDate);
            break;
        case "week":
            // code block
            start = new Date();
            end = new Date();
            start.setHours(start.getHours() - 168);
            break;
        case "month":
            // code block
            start = new Date();
            end = new Date();
            start.setMonth(start.getMonth() - 1);
            break;
        case "year":
            // code block
            start = new Date();
            end = new Date();
            start.setFullYear(start.getFullYear() - 1);
            break;
        default:
            start = new Date();
            end = new Date();
            start.setHours(start.getHours() - 24);
    }

    return {
        start: start,
        end: end
    }

}

function getTranslationJobs() {
    if (pending === true){
        return;
    }
    pending = true;
    let queryDateRange = getDateRange();
    //console.log(queryDateRange);

    selectedJob = undefined;
    jobs = [];

    return dbRef.where('createdOn', ">=", queryDateRange.start)
        .where('createdOn', "<=", queryDateRange.end)
        .where('type', "==", "translation")
        .where('status', "in", statusFilter === 'All' ? ["Submitted", "Passed", "Failed", "Submitted", "In Progress"] : [statusFilter])
        .orderBy('createdOn', 'desc')
        .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (!doc.data().archived){
                    jobs = [...jobs, doc.data()];
                }
            });

            if(allJobsComplete(jobs)){
                disableAutoRefresh();
            }
        }).catch((error) => {
            console.log("Error getting documents: ", error);
            toast.push(`Error getting translations: ${error.message}`, {
                classes: ["toast-danger"]
            });
        }).finally(() => {
            pending = false;
        });
}

function allJobsComplete(jobs){
    let inProgressJob = jobs.findIndex(job =>{
        return job.progress !== 100;
    });

    if (inProgressJob > -1){
        return false;
    } else {
        return true;
    }
}

function archiveJob(){
    deletingFile = true;
    dbRef.doc(selectedJob.id).update({
        archived: true
    }).then(() => {
        toast.push("Job archived successfully", {
            classes: ["toast-success"]
        });
        gettingJobs = getTranslationJobs();
    }).catch((e) => {
        toast.push(`Error archiving job: ${e.message}`, {
            classes: ["toast-danger"]
        });
        console.log(e, e.message);
    }).finally(() => {
        deletingFile = false;
    });
}

async function importTranslation() {
    disableAutoRefresh();
    toast.push("Loading translation... please wait...", {
        classes: ["toast-info"]
    });

    loading = true;

    storageRef.child(storagePath + selectedJob.id + ".json").getDownloadURL().then((url) => {
        return fetch(url);
    }).then(res => {
        return res.json();
    }).then(async (resJson) => {
        // console.log(JSON.stringify(resJson));
        resJson.forEach((evg, index) => {
            /* Check if the Event Group exists in the project already */
            
            let indexOfEvg = $eventGroupState.findIndex(eventGroup => {
                return eventGroup.id === evg.id;
            });

            let newEvg =  new _EventGroup(evg);
            newEvg.id = evg.id;

            if (indexOfEvg === -1){
                if (index > 0){
                    for (let i = 0; i < 2; i++){
                        newEvg = autoFormat(newEvg, Math.max(3, maxLines), maxChars, minDuration, allowOrphanWords, false);
                    }

                    newEvg = fixOverlap(newEvg);
                }
                
                $eventGroupState = [...$eventGroupState, newEvg];
            }            
        })
        
        
        $projectState.selected = $eventGroupState.length - 1;

        historyState.insert({
            name: "import translation", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Translation imported successfully", {
            classes: ["toast-success"]
        });
        modalState.hideModal();
    }).catch(error => {
        console.log(error, error.message);
        loading = false;
        toast.push("Failed to Load Translation", {
            classes: ["toast-danger"]
        });
    });
}

async function deleteTranslation() {

    deletingFile = true;
    //User SweetAlert to confirm deletion
    let swalRes = await Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this translation file? This action cannot be undone.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
    });

    if (!swalRes.isConfirmed){
        deletingFile = false;
        return;
    }

    firebase.functions().httpsCallable('v8DeleteTranslation')(selectedJob).then(() => {
        deletingFile = false;
        toast.push("Translation file deleted successfully", {
            classes: ["toast-success"]
        });
        
        gettingJobs = getTranslationJobs();
    }).catch(e => {
        toast.push(`Error deleting translation file: ${e.message}`, {
            classes: ["toast-danger"]
        });

        deletingFile = false;
        console.log(e, e.message);
    });
}

function exportCsv() {
    //console.log(JSON.stringify(jobs))
    toast.push("Exporting results...", {
        classes: ["toast-info"]
    });

    let csv = Papa.unparse(
        JSON.stringify(
            jobs.map(job =>{
                return {
                    id: job.id,
                    projectId: job.projectId,
                    projectName: job.projectName,
                    progress: job.progress,
                    createdOn: job.createdOn.toDate(),
                    completedOn: job.completedOn ? job.completedOn.toDate() : '',
                    updatedOn: job.updatedOn.toDate(),
                    status: job.status,
                    statusMsg: job.statusMsg,
                    type: job.type,
                    characters: job.chars,
                    cost: job.cost,
                    provider: job.config.provider || "",
                    srcLanguage : job.config.srcLanguage,
                    trgLanguage : job.config.trgLanguage
                }
            })
        )
    );

    let fileBlob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
    });

    saveAs(fileBlob, "report_translation_jobs.csv", {
        autoBom: true
    });
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'aiTranslationImport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="aiTranslationImportModal">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Translation Import</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <div class="btn-group float-end mb-2" role="group">
                    <button type="button" class="btn btn-outline-dark" on:click="{exportCsv}" disabled="{jobs.length === 0}">Export as CSV</button>
                </div>
                <!-- Search Form -->
                <form>
                    <div class="row g-1">
                        <div class="col-2">
                            <select class="form-select me-2 mb-2" bind:value="{dateRange}">
                                <option value="today">24 Hours</option>
                                <option value="week">Past Week</option>
                                <option value="month">Past Month</option>
                                <option value="year">Past Year</option>
                                <option value="custom">Custom</option>
                            </select>
                        </div>
                        {#if dateRange === 'custom'}
                        <div class="col-2">
                            <input class="form-control me-2 mb-2" type="date" bind:value="{startDate}"/>
                        </div>
                        <div class="col-2">
                            <input class="form-control me-2 mb-2" type="date" bind:value="{endDate}"/>
                        </div>
                        {/if}
                        <div class="col-2">
                            <select class="form-select me-2 mb-2" bind:value="{statusFilter}">
                                <option value="All">All</option>
                                <option value="Submitted">Submitted</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                            </select>
                        </div>
                        <div class="col">
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-light" disabled="{dateRange === 'custom' && (!startDate || !endDate)}"  on:click={() => {gettingJobs = getTranslationJobs()}}>Refresh</button>
                                <button type="button" class="btn {autoRefresh ? 'btn-primary' : 'btn-light'}" on:click="{() => {autoRefresh ? disableAutoRefresh() : enableAutoRefresh() }}"><i class="bi {autoRefresh ? 'bi-check-circle-fill' : 'bi bi-circle'}"></i> Auto Refresh</button>                                
                            </div>
                                                        
                        </div>                       
                        {#if dateRange === 'custom' && (!startDate || !endDate)}
                        <p class="text-danger small pt-2 me-2">Please provide a start and end date for custom queries.</p>
                        {/if}
                        {#if loading}
                        <div class="col">
                            <Circle size="30" color="#1eb4b2" unit="px" duration="1s"></Circle>
                        </div>
                        {/if}
                    </div>
                </form>
                <!-- Results Table -->
                <div id="TranslationTableDashboard" class="table-responsive rounded shadow border border-secondary">
                    <table class="table table-hover table-sm">
                        <thead class="bg-dark text-dark">
                            <tr>
                                <th class="text-truncate">Project Name</th>
                                <th class="text-truncate">Progress</th>
                                <th>Status</th>
                                <th class="text-truncate">Status Message</th>

                                <th class="text-truncate">Characters</th>
                                <th>Provider</th>
                                <th class="text-truncate">Source Language</th>
                                <th class="text-truncate">Target Language</th>
                                <th class="text-truncate">File Present</th>
                                <th class="w-25">Job Id</th>
                                <th class="text-truncate">Submitted By</th>
                                <th>Created On</th>
                                <th>Completed On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {#await gettingJobs}
                            <div style="padding: 10%; position:relative; left:105%;">
                                <BarLoader size="160" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                            </div>
                            {:then}
                            {#each jobs as job (job.id)}
                            <tr on:click="{() => {selectedJob = job}}" class="{selectedJob && job.id === selectedJob.id ? 'table-warning' : ''}">
                                <!-- Project Name -->
                                <td class="text-truncate" title="{job.projectName}">{job.projectName}</td>

                                <!-- Progress -->
                                <td class="align-middle">
                                    <div class="progress" style="width:150px">
                                        <div class="progress-bar {job.progress < 100 ? 'progress-bar-animated' : ''} {job.status === 'Failed' ? 'bg-danger' : ''} {job.status === 'Passed' ? 'bg-primary' : ''} progress-bar-striped" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: {
                                            job.duration && job.status === 'In Progress' ? Math.min(99,(((Math.round(Date.now() / 1000)-job.createdOn.seconds)/(job.duration/2)) * 100).toFixed(0)) : job.progress
                                            }%">{
                                            job.duration && job.status === 'In Progress' ? Math.min(99,(((Math.round(Date.now() / 1000)-job.createdOn.seconds)/(job.duration/2)) * 100).toFixed(0)) : job.progress
                                            }%
                                        </div>
                                    </div>
                                </td>

                                <!-- Status -->
                                <td title="{job.status}" class="text-truncate {job.status === 'Passed' ? 'text-primary' : ''} {job.status === 'Failed' ? 'text-danger' : ''}">{job.status}</td>
                                <!-- Status Msg -->                                
                                <td title="{job.statusMsg}" class='text-truncate'>{job.statusMsg}</td>

                                <!-- Characters -->
                                <td title="{job.chars.toLocaleString('en-US')}" class='text-truncate'>{job.chars.toLocaleString('en-US')}</td>
                                <!-- Provider -->
                                <td class="text-truncate" title="{job.config.provider}">{job.config.provider}</td>
                                <!-- Source Language -->
                                <td  title="{job.config.srcLanguage}">{job.config.srcLanguage}</td>
                                <!-- Target Language -->
                                <td  title="{job.config.trgLanguage}">{job.config.trgLanguage}</td>
                                <!-- File Present -->
                                <td title="{job.statusMsg}"><i class="bi {!job.deleted && job.status === 'Passed' && job.config.linkedGroup ? 'bi-check-lg text-primary' : 'bi-x-lg text-light'}"></i></td>
                                <!-- Job Id -->
                                <td class="text-truncate small" title="{job.id}">{job.id}</td>
                                <!-- Submitted By -->
                                <td class="text-truncate" title="{job.submittedBy || email}">{job.submittedBy || email}</td>
                                <!-- Created On -->
                                <td class="text-truncate" title="{job.createdOn.toDate()}">{job.createdOn.toDate().toString().substring(0,24)}</td>
                                <!-- Completed On -->
                                <td class="text-truncate" title="{job.completedOn ? job.completedOn.toDate() : ""}">{job.completedOn ? job.completedOn.toDate().toString().substring(0,24) : ""}</td>
                            </tr>
                            {:else}
                            <p class="text-light text-center m-5 text-truncate">No results to display</p>
                            {/each}
                            {/await}

                        </tbody>
                    </table>
                </div>
                {#if selectedJob}
                    <div class="btn-group  float-end" role="group" aria-label="Button group with nested dropdown">
                        <button type="button" class="btn btn-primary" on:click="{() => importTranslation("translation")}" disabled="{loading || deletingFile || selectedJob.deleted || selectedJob.status !== 'Passed'}">
                            <i class="bi bi-translate"></i> Import Translation
                        </button>
                    </div>
                    <div class="btn-group" role="group">
                        {#if selectedJob.status !== "Passed"}
							<button title="Archive Job" type="button" class="btn btn-info text-white" disabled={deletingFile} on:click={archiveJob}><i class="bi bi-archive"></i> Archive Job</button>
						{/if}
                        {#if !selectedJob.deleted && selectedJob.status === 'Passed' && selectedJob.config.linkedGroup}
                        <button type="button" class="btn btn-danger text-white" disabled="{deletingFile}" on:click="{deleteTranslation}"><i class="bi bi-trash"></i> Delete</button>
                        {/if}
                    </div>                    
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
#TranslationTableDashboard {
    max-height: 500px;
    margin-bottom: 2%;
}
</style>
